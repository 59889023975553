/**
 * @version 1.0
 * @copyright 2017 Operis
 * @Author Brian Kjerulf, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpPopupDemo', [
    'ui.bootstrap',
    'fr.operis.moteurV4.directives.OpAutoFocus'])
    .controller('fr.operis.moteurV4.composants.OpPopupDemoControleur',
    ['$scope', '$modalInstance', 'typeDemo', 'titreDemo', 'donneesDemo',
        function (scope, modalInstance, typeDemo, titreDemo, donneesDemo) {
            scope.demo = {
                titre: titreDemo,
                type: typeDemo,
                donnees: donneesDemo,
                nodeTemplate: function (data) {
                    // Titre noeud
                    var refNoeud = (!angular.isNullOrUndefined(data.reference) && (data.reference.trim() !== '')) ? '[' + data.reference.trim() + ']' : '[?]';
                    var titreNoeud = '';
                    if (!angular.isNullOrUndefined(data.titre) && (data.titre.trim() !== '')) {
                        titreNoeud = '<span>' + refNoeud + ' ' + data.titre.trim() + '</span>';
                    } else {
                        titreNoeud = '<span>' + refNoeud + ' ?</span>';
                    }

                    // Texte noeud
                    var texteNoeud = '';
                    if (!angular.isNullOrUndefined(data.libelle) && (data.libelle.trim() !== '')) {
                        texteNoeud = '<span>' + data.libelle.trim() + '</span>';
                    }

                    if (!angular.isNullOrUndefined(data.description) && (data.description.trim() !== '')) {
                        if (texteNoeud !== '') {
                            texteNoeud += '<br>';
                        }

                        texteNoeud += '<span>' + data.description.trim() + '</span>';
                    }

                    return '<div class="title">&nbsp;' + titreNoeud + '</div>' +
                        '<div class="content">' + texteNoeud + '</div>';
                 }
            };

            scope.ok = function () {
                modalInstance.close();
            };

            scope.annuler = function () {
                modalInstance.dismiss();
            };
        }
    ])
    .service('fr.operis.moteurV4.composants.OpPopupDemo', ['$modal', function (modal) {
        this.creer = function(typeDemo, titreDemo, aBoutonOK, aBoutonNO, libBoutonOK, libBoutonNO){
            var boutonNO = (angular.isNullOrUndefined(aBoutonNO)) ? true : aBoutonNO;
            var boutonOK = (angular.isNullOrUndefined(aBoutonNO)) ? false : aBoutonOK;

            var libOK = "OK";
            if (!angular.isNullOrUndefined(libBoutonOK)) {
                libOK = libBoutonOK;
            }

            var libNO = "Annuler";
            if (!angular.isNullOrUndefined(libBoutonNO)) {
                libNO = libBoutonNO;
            }

            function getDonnees (type) {
                var donnees = {};

                if (type === "orgchart") {
                    donnees = {
                        'reference': "id_100011",
                        'titre': "Pôle sportif des Maradas",
                        'libelle': "6 passage du Lycée",
                        'description': "1 stade de foot, 1 salle sports collectifs, 1 gymnase",
                        'className': "bati",
                        'children': [
                            {
                                'reference': "id_100012",
                                'titre': "Le stade des Maradas",
                                'libelle': "Espace extérieur autre que vert / Terrain synthétique",
                                'description': "",
                                'className': "terrain",
                                'children': [
                                    {
                                        'reference': "id_100017",
                                        'titre': "Piste de course n°1",
                                        'libelle': "Equipement sportif / Dispositif de franchissement",
                                        'description': "",
                                        'className': "piste"
                                    },
                                    {
                                        'reference': "id_100018",
                                        'titre': "Pelouse centrale",
                                        'libelle': "Equipement sportif / Dispositif de franchissement",
                                        'description': "",
                                        'className': "pelouse"
                                    }
                                ]
                            },
                            {
                                'reference': "id_100013",
                                'titre': "Complexe des Maradas",
                                'libelle': "Bâti / Salle/ Bâti léger",
                                'description': "",
                                'className': "bati"
                            },
                            {
                                'reference': "id_100014",
                                'titre': "Gymnase des Maradas",
                                'libelle': "Bâti / Salle / Bâti léger",
                                'description': "",
                                'className': "bati",
                                'children': [
                                    {
                                        'reference': "id_100015",
                                        'titre': "Vestiaire femmes n°1",
                                        'libelle': "Bâti / Local",
                                        'description': "",
                                        'className': "local"
                                    },
                                    {
                                        'reference': "id_100016",
                                        'titre': "Gradins",
                                        'libelle': "Equipement sportif / Gradins",
                                        'description': "",
                                        'className': "equipement"
                                    }
                                ]
                            }
                        ]
                    };
                }

                return donnees;
            }

            return modal.open({
                template: '<div class="modal-header">' +
                                '<h4 class="modal-title">{{demo.titre}}</h4>' +
                          '</div>' +
                          '<div class="modal-body">'+
                                '<!-- Démonstration OrgChart -->'+
                                '<div style="width: 100%;">'+
                                    //'<div class="row" style="overflow-x: hidden;">'+
                                    '<div class="row" style="overflow-x: auto; white-space: nowrap;">'+
                                        '<op-org-chart class="orgchart" ng-if="demo.type === \'orgchart\'"></op-org-chart>'+
                                    '</div>'+
                                '</div>'+
                                '<form>'+
                                    '<div class="modal-footer">'+
                                        '<button ng-if=" '+ boutonOK +'" class="btn btn-primary" type="button" ng-click="ok()">' + libOK + '</button>'+
                                        '<button ng-if=" '+ boutonNO +'" class="btn btn-warning" type="button" ng-click="annuler()">' + libNO + '</button>'+
                                    '</div>'+
                                '</form>'+
                            '</div>',
                controller: 'fr.operis.moteurV4.composants.OpPopupDemoControleur',
                size: 'lg',
                resolve: {
                    typeDemo: function () {
                        return typeDemo;
                    },
                    titreDemo: function () {
                        return titreDemo;
                    },
                    donneesDemo: function () {
                        return getDonnees(typeDemo);
                    }
                }
            });
        }
    }])
    .directive('opOrgChart', ['$ocLazyLoad', function ($ocLazyLoad) {
        return {
            restrict: 'E',
            scope: true,
            link: function (scope, element, attrs) {
                $ocLazyLoad.load({
                    serie: true, files: ['lib/jquery.min.js', 'lib/jquery.orgchart.min.js', 'css/jquery.orgchart.min.css']
                }).then(function () {
                    angular.element(document).ready(function () {
                        $(element).orgchart({
                            'data': scope.demo.donnees,
                            'nodeTemplate': scope.demo.nodeTemplate,
                            'depth': 999,
                            'draggable': true,
                            'pan': true,
                            'zoom': true
                        });
                    });
                });
            }
        }
    }]);