/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Config', [])
    .constant('fr.operis.moteurV4.utils.Config.cookie.CGU', "clientCGU")
    .constant('fr.operis.moteurV4.utils.Config.cookie.LOGOUT_STATE', "logoutState")
    .service('fr.operis.moteurV4.utils.Config'
    , [ function () {
            var that = this;

            this.$config = {
              urlStreetView:"https://maps.googleapis.com/maps/api/streetview",
              urlItineraire:"https://www.google.fr/maps/dir/Ma Position/",
              libApplication: {
                  "gnau":"Guichet numérique des autorisations d'urbanisme"
              },
              portail: {
                display: true,
                only: false,
                auto: false,
                creaCompte: true
              }
            };
        }
    ]
);
