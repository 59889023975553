/**
 * Created by Laurent Dorie on 18/01/17.
 */
"use strict";
angular.module('fr.operis.moteurV4.composants.popup.OpPopupSuppressionFiche',[])
    .controller('fr.operis.moteurV4.composants.popup.OpPopupSuppressionFicheCtrl',
        ['$scope', 'action', 'moduleNom', 'selection', 'descriptionSelection',
            'fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.composants.OpPopupAlerte',
            'fr.operis.moteurV4.utils.ModuleInfos',
            'fr.operis.moteurV4.utils.RequeteVerificationSuppressionFiche',
            'fr.operis.moteurV4.utils.RequetePreSuppressionFiche',
			'fr.operis.moteurV4.utils.RequetePostSuppressionFiche',
            '$window',
            '$q',
            function ($scope, action, moduleNom, selection, descriptionSelection,
                        serveur,
                        opPopupAlerte,
                        moduleInfos,
                        requeteVerificationSuppressionFiche,
                        requetePreSuppressionFiche,
						requetePostSuppressionFiche,
                        $window,$q) {
                $scope.$selectionOrigine = selection;
                $scope.$selectionDescription = descriptionSelection;
                $scope.$moduleNom = moduleNom;
                $scope.ecranNomSuppression = action.ECRANNOM.valeur;
                $scope.$ecranVerificationSuppression = requeteVerificationSuppressionFiche.getEcranVerification(moduleNom);

                // Suppresion des éléments sélectionnés
                $scope.valider = function () {
                    var popupConfirmation = opPopupAlerte.creer('warning',"Les éléments sélectionnés seront définitivement supprimés. Etes-vous sûr de vouloir continuer ?", true, true);
                        popupConfirmation.result.then(function () {
                        // Récupération de l'identifiant
                        var colClePrimaire = moduleInfos.getInfo($scope.$moduleNom, "MOD_ENTITE");
                        if (angular.isNullOrUndefined( colClePrimaire))
                            return $window.alert("Problème sur l'identification de clef primaire");


                        var resultatsAttendus = 0;
                        for ( var indice = $scope.$selection.length - 1; indice >= 0;indice--){
                            ++resultatsAttendus;
                            $scope.supprimerElement($scope.$selection[indice],colClePrimaire)
                                .finally(function () {
                                    if (--resultatsAttendus === 0) {
                                        // Nettoyage selection des données
                                        for ( var i = $scope.$selectionDonnees.length - 1; i >= 0;i--){
                                            if ( $scope.$selectionDonnees[i]["statut"] == "SUCCES" )
                                                $scope.$selectionDonnees.splice(i,1);
                                        }

                                        // Nettoyage selection de la selection
                                        for ( var i = $scope.$selection.length - 1; i >= 0;i--){
                                            if ( $scope.$selection[i]["statut"] == "SUCCES" )
                                                $scope.$selection.splice(i,1);
                                        }
                                    }
                                })
                        }
                    });
                };

                // Fonction de suppression d'un élément
                $scope.supprimerElement = function (element, colClePrimaire){
                    var deferred = $q.defer();

                    // Définition de l'identifiant de suppression
                    if ( angular.isNullOrUndefined ( element[colClePrimaire])){
                        deferred.reject();
                        opPopupAlerte.creer('error', "Problème sur l'identifiant de la sélection", true, false);
                        return deferred.promise;
                    }
                    var identifiant = {};
                    identifiant[colClePrimaire] = element[colClePrimaire].valeur;

                    // Vérification de validité de l'identifiant
                    requetePreSuppressionFiche.execution($scope,moduleNom, identifiant)
                        .then(function(resultat){
                            var retourPreSupp = resultat; // Mantis Operia 6574/6432 Récupérer élément(s) retourné par traitement de pré suppression
							serveur.suppression($scope.$moduleNom, $scope,$scope.ecranNomSuppression,identifiant)
                                .then(function (resultat) {
                                    // Mantis Operia 6574/6432 Traitement poste suppression le cas échéant
                                    if (!angular.isNullOrUndefined(retourPreSupp) && (retourPreSupp !== "")) {
                                        requetePostSuppressionFiche.execution($scope, moduleNom, identifiant, retourPreSupp, action.ID_ACTION.valeur);
                                    }
									
                                    element["statut"] = "SUCCES";
                                    deferred.resolve(resultat);
                                },function(erreur){
                                    element["statut"] = "ERREUR";
                                    deferred.reject(erreur);
                                });
                        },function(erreur){
                            element["statut"] = "ERREUR";
                            $scope.$erreurs.push(erreur);
                            deferred.reject(erreur);
                        });

                    return deferred.promise;
                }

            }
        ]);

