/**
 * @name opPlanning
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 * @description
 *   Directive OpPlannng : Gestion planning sous forme d'agenda/timeline (implémentation ui-calendar) et grille
 *
 * @attr {String} op-contexte-planning Contexte du planning
 * @attr {String} op-nom-planning Nom à utililiser pour identifier le planning
 * @attr {String} op-type-planning Type du planning (tache ou occupation)
 * @attr {String} op-nom-module Nom du module appelant
 * @attr {String} op-action-code Code action appelant
 * @attr {Array} op-selection Sélection en cours dans l'onglet appelant
 * @attr {function} on-fin-chargement Fonction (du contrôleur appelant) pour la gestion de fin de chargement
 * @attr {function} on-update-actions Fonction (du contrôleur appelant) pour la gestion du statut des actions
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPlanning', [
    'fr.operis.moteurV4.composants.planning.OpPopupAjoutOccupation',
    'fr.operis.moteurV4.composants.planning.OpPopupAjoutTache',
    'fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheDetaillee',
    'fr.operis.moteurV4.composants.planning.OpPopupAvancementTache',
    'fr.operis.moteurV4.composants.planning.OpPopupAfficherRessources',
    'fr.operis.moteurV4.composants.planning.OpPopupDetailEvenement',
    'fr.operis.moteurV4.composants.planning.OpPopupRecurrence'])
    .directive('opPlanning', ['$ocLazyLoad', '$injector', '$compile', '$q', '$modal', '$interval', '$window', '$timeout',
        'fr.operis.moteurV4.communication.OperisWSServer', 'fr.operis.moteurV4.utils.ReferenceInterne',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.Planning', 'fr.operis.moteurV4.utils.Representation',
        'fr.operis.moteurV4.utils.RequetePostSauvegardeFiche',
        'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE',
        'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1',
        'fr.operis.moteurV4.composants.OpPopupAlerte',
        function ($ocLazyLoad, $injector, $compile, $q, $modal, $interval, $window, $timeout, serveur, referenceInterne,
                  conversion, utilsPlanning, representationService, requetePostSauvegardeFiche,
                  SEPARATEUR_INTERNE, SEPARATEUR_NIVEAU_1, opPopupAlerte) {
            return {
                restrict: 'E',
                scope: {
                    opContextePlanning: '=',
                    opNomPlanning: '=',
                    opTypePlanning: '=',
                    opNomModule: '=',
                    opActionCode: '=',
                    opSelection: '=',
                    opDonneesRecherche: '=',
                    finChargement: '&onFinChargement',
                    updateActions: '&onUpdateActions'
                },
                controller: ['$scope', function (scope) {
                    //scope.uiGridConstants = uiGridConstants;

                    scope.$on("OperisOnglet_FermerPlanningEvt", function (event, args) {
                        // Gestion avant fermêture du planning
                        if (!angular.isNullOrUndefined(args) && !angular.isNullOrUndefined(args.sauvegarde)) {
                            scope.fermerPlanning(args.sauvegarde);
                        } else {
                            scope.fermerPlanning(false);
                        }
                    });

                    scope.$on("OperisOnglet_SauvegarderPlanningEvt", function (event, args) {
                        if (!angular.isNullOrUndefined(args.nomPlanning) && (args.nomPlanning === scope.opNomPlanning)) {
                            if (scope.opTypePlanning !== "recherche") {
                                // Sauvegarder le planning
                                scope.sauvegardePlanning().then(function () {
                                    scope.planningModifie = false;
                                    scope.actualiserOpPlanningDepuisCache(false);
                                });
                            }
                        }
                    });

                    scope.$on("OperisOnglet_RechargerPlanningEvt", function (event, args) {
                        // Actualisation du calendrier suite clique bouton recharger
                        if (scope.opTypePlanning === args.typePlanning) {
                            if (angular.isNullOrUndefined(args.confirmerAction) || !args.confirmerAction) {
                                if (angular.isNullOrUndefined(scope.demanderRechargement)) {
                                    scope.demanderRechargement = 1;
                                } else {
                                    scope.demanderRechargement = scope.demanderRechargement + 1;
                                }
                            } else {
                                if (!angular.isNullOrUndefined(args.nomPlanning) && (scope.opNomPlanning === args.nomPlanning)) {
                                    // Confirmation avant actualisation
                                    var msg = "Les modifications non enregistrées seront perdues.<br><br>Continuer ?";
                                    scope.confirmationAvantAction(msg).then(function () {
                                        if (angular.isNullOrUndefined(scope.demanderRechargement)) {
                                            scope.demanderRechargement = 1;
                                        } else {
                                            scope.demanderRechargement = scope.demanderRechargement + 1;
                                        }
                                    });
                                }
                            }
                        }
                    });

                    scope.$on("OperisOnglet_ActualiserPlanningSectionDroiteEvt", function (event, args) {
                        // Actualisation du calendrier (mode recherche)
                        if (scope.opTypePlanning === "recherche") {
                            if (angular.isNullOrUndefined(scope.demanderRechargement)) {
                                scope.demanderRechargement = 1;
                            } else {
                                scope.demanderRechargement = scope.demanderRechargement + 1;
                            }
                        }
                    });

                    scope.$on("OperisOnglet_ActualiserPlanningEvt", function (event, args) {
                        // Actualisation du calendrier (mode saisie)
                        if (scope.opTypePlanning !== "recherche") {
                            if (angular.isNullOrUndefined(args.confirmerAction) || !args.confirmerAction) {
                                if (angular.isNullOrUndefined(scope.demanderRechargement)) {
                                    scope.demanderRechargement = 1;
                                } else {
                                    scope.demanderRechargement = scope.demanderRechargement + 1;
                                }
                            } else {
                                if (!angular.isNullOrUndefined(args.nomPlanning) && (scope.opNomPlanning === args.nomPlanning)) {
                                    // Confirmation avant actualisation
                                    var msg = "Les modifications non enregistrées seront perdues.<br><br>Continuer ?";
                                    scope.confirmationAvantAction(msg).then(function () {
                                        if (angular.isNullOrUndefined(scope.demanderRechargement)) {
                                            scope.demanderRechargement = 1;
                                        } else {
                                            scope.demanderRechargement = scope.demanderRechargement + 1;
                                        }
                                    });
                                }
                            }
                        }
                    });

                    scope.$on("OperisOnglet_AffichageGrillePlanningEvt", function (event, args) {
                        if (!angular.isNullOrUndefined(args.nomPlanning) && (args.nomPlanning === scope.opNomPlanning)) {
                            scope.affichageGrille();
                        }
                    });

                    scope.$on("OperisOnglet_AffichageTimelinePlanningEvt", function (event, args) {
                        if (!angular.isNullOrUndefined(args.nomPlanning) && (args.nomPlanning === scope.opNomPlanning)) {
                            scope.affichageTimeline();
                        }
                    });

                    scope.$on("OperisOnglet_AffichageAgendaPlanningEvt", function (event, args) {
                        if (!angular.isNullOrUndefined(args.nomPlanning) && (args.nomPlanning === scope.opNomPlanning)) {
                            scope.affichageAgenda();
                        }
                    });

                    scope.$on("OperisOnglet_ActionPlanningEvt", function (event, args) {
                        if (!angular.isNullOrUndefined(args.nomPlanning) && (args.nomPlanning === scope.opNomPlanning)) {
                            if (scope.opTypePlanning !== "recherche") {
                                if (scope.opNomModule === "Intervention") {
                                    if (args.actionCode === "CREERTACHESIMPLE") {
                                        // Création simple d'une tâche
                                        scope.ajoutTache(args.actionCode);
                                    } else if (args.actionCode === "CREERTACHEDETAILLEE") {
                                        // Création détaillée d'une tâche
                                        scope.ajoutTacheDetaillee(args.actionCode);
                                    } else if (args.actionCode === "MODIFIERTACHE") {
                                        // Modification d'une tâche
                                        scope.modifierTache(args.actionCode);
                                    } else if (args.actionCode === "SUPPRIMERTACHE") {
                                        // Suppression d'une tâche
                                        scope.supprimerElements(args.actionCode);
                                    } else if (args.actionCode === "AVANCEMENTTACHE") {
                                        // Gérer l'avancement d'une tâche
                                        scope.avancementTache(args.actionCode);
                                    } else if (args.actionCode === "REACTIVERTACHE") {
                                        // Réactivation d'une tâche
                                        scope.reactiverElements(args.actionCode);
                                    } else if (args.actionCode === "GERERPERIODICITE") {
                                        // Gérer la périodicité
                                        scope.gererPeriodicite(args.actionCode);
                                    } else {
                                        // Action inconnue ou pas encore implémentée
                                        alert('Action "' + args.actionLibelle + '" non implémentée.');
                                    }
                                } else if (scope.opNomModule === "Contact") {
                                    if (args.actionCode === "CREEROCCSIMPLE") {
                                        // Création simple d'une occupation
                                        scope.ajoutOccupation(args.actionCode);
                                    } else if (args.actionCode === "MODIFIEROCC") {
                                        // Création simple d'une occupation
                                        scope.modifierOccupation(args.actionCode);
                                    } else if (args.actionCode === "SUPPRIMEROCC") {
                                        // Suppression des occupations
                                        scope.supprimerElements(args.actionCode);
                                    }
                                } else {
                                    // Action inconnue ou pas encore implémentée
                                    alert('Action "' + args.actionLibelle + '" non implémentée.');
                                }
                            }
                        }
                    });
                }],
                link: function (scope, element, attrs) {
                    $ocLazyLoad.load({
                        serie: true, files: [
                            'lib/jquery.min.js',
                            'lib/jquery-ui.min.js',
                            'lib/angular-dragdrop.min.js',
                            'lib/moment.min.js',
                            'lib/moment-fr.min.js',
                            'lib/calendar.min.js',
                            'lib/fullcalendar.min.js',
                            'lib/fullcalendar-fr.min.js',
                            'css/fullcalendar.min.css',
                            'lib/scheduler.min.js',
                            'css/scheduler.min.css',
                            //'lib/contextMenu.min.js',
                            //'css/contextMenu.min.css',
                            'lib/ui-grid.min.js',
                            'css/ui-grid.min.css'
                        ]
                    })
                        .then(function () {
                            var uiCalendarConfig = $injector.get('uiCalendarConfig');

                            /**
                             * Nom à utliser pour identifier le calendrier
                             */
                            if (angular.isNullOrUndefined(scope.opNomPlanning)) {
                                scope.opNomPlanning = "opPlanning";
                            }

                            /**
                             * Flag pour indiquer lque le planning a été modifié
                             * @type {boolean} planningModifie
                             */
                            scope.planningModifie = false;

                            /**
                             * Id de l'élément entête
                             * @type {number} idEntete
                             */
                            scope.idEntete = null;

                            /**
                             * Référence de l'élément entête
                             * @type {string} refEntete
                             */
                            scope.refEntete = null;

                            /**
                             * Adresse de l'élément entête
                             * @type {string} refEntete
                             */
                            scope.adresseEntete = null;

                            /**
                             * Objet de l'élément entête
                             * @type {string} refEntete
                             */
                            scope.objetEntete = null;

                            /**
                             * Entête un élément du type modèle ?
                             * @type {number} estModeleEntete
                             */
                            scope.estModeleEntete = null;

                            /**
                             * Numéro de référence de la serié de récurrence
                             * @type {number} refSerieRecurrence
                             */
                            scope.refSerieRecurrence = -1;

                            /**
                             * Date de début prévue de l'élément entête
                             * @type {Date} debutEntete
                             */
                            scope.debutEntete = null;

                            /**
                             * Tableau des données à manipuler par le planning
                             * @type {Array}
                             */
                            scope.bufDonnees = [];

                            /**
                             * Tableau des données récurrentes à manipuler par le planning
                             * @type {Array}
                             */
                            scope.bufDonneesRecurrence = [];

                            /**
                             * Tableau des données formatté pour l'affichage en mode grille
                             * @type {Array}
                             */
                            scope.dataGrillePlanning = [];

                            /**
                             * Tableaux des ressources du planning
                             * @type {Array} eventRessources
                             */
                            scope.eventRessources = [];

                            /**
                             * Tableaux des évènements du planning
                             * @type {Array} eventSources
                             */
                            scope.eventSources = [];

                            /**
                             * Tableaux des id des évènements déjà ajoutés au planning en lecture seule
                             * @type {Array} listeIdLectureSeule
                             */
                            scope.listeIdLectureSeule = [];

                            /**
                             * Tableaux des id des ressources dont des évènements ont déjà été ajoutés au planning en lecture seule
                             * @type {Array} listeIdResLectureSeule
                             */
                            scope.listeIdResLectureSeule = [];

                            /**
                             * Numéros d'éléments par évènement
                             * @type {Array} numeroElemParEvenement
                             */
                            scope.numeroElemParEvenement = [];

                            /**
                             * Tableaux des évènements disponibles pour ajout au planning
                             * @type {Array} eventsDisponibles
                             */
                            scope.eventDisponibles = [];

                            /**
                             * Tableaux des évènements supprimés du planning
                             * @type {Array} eventSupprimes
                             */
                            scope.eventSupprimes = [];

                            /**
                             * Tableaux des évènements récurrents supprimés du planning
                             * @type {Array} eventRecurrentSupprimes
                             */
                            scope.eventRecurrentSupprimes = [];

                            /**
                             * Tableaux des lignes des évènements récurrents supprimés du planning
                             * @type {Array} lignesRecurrentSupprimees
                             */
                            scope.lignesRecurrentSupprimees = [];

                            /**
                             * Tableaux des groupes dont le statut des lignes a été modifiés
                             * @type {Array} groupesStatutsLignesModifies
                             */
                            scope.groupesStatutsLignesModifies = [];

                            /**
                             * Nombre d'événements sélectionnés
                             * @type {number} nbreEventSelectionnes
                             */
                            scope.nbreEventSelectionnes = 0;

                            /**
                             * Flag pour indiquer que l'événement sélectionné est en lectuer seule
                             * @type {boolean} selectionEnLectureSeule
                             */
                            scope.nbreEventLectureSeuleSelectionnes = 0;

                            /**
                             * Flag pour indiquer que le planning est en lecture seule
                             * @type {boolean} planningLectureSeule
                             */
                            scope.planningLectureSeule = false;

                            /**
                             * Flag utilisé dans la gestion du drag/drop et sélection des événements
                             * @type {boolean} dragged
                             */
                            scope.dragged = false;

                            /**
                             * Compteur utilisé lors de l'ajout des éléments au planning
                             * @type {number} idGroupe
                             */
                            scope.idGroupe = 0;

                            /**
                             * Compteur utilisé pour construction des id évènements uniques
                             * @type {number} nbreLignes
                             */
                            scope.nbreLignes = 0;

                            /**
                             * Mode d'affichage active : grille ou planning
                             * @type {Object} modeAffichage
                             */
                            scope.modeAffichage = {grille: false, planning: true, agenda: false};

                            /**
                             * Liste des ressources
                             * @type {Array}
                             */
                            scope.listeRessources = [];

                            /**
                             * Liste des ressources affectées au planning
                             * @type {Array}
                             */
                            scope.listeRessourcesAffectees = [];
							scope.listeLibRessourcesAffectees = [];

                            /**
                             * Libellé de la ressource sélectionnée
                             * @type {string}
                             */
                            scope.libRessourceSelect = "";

                            /**
                             * Filtre de ressources utilisé lors de la modification de l'affichage des ressource
                             * @type {Object}
                             */
                            scope.filtreRessources = {
                                typeRessource: "Individu",
                                nomEquipe: "",
                                libRessource: "",
                                competence: "",
                                typeMateriel: "",
                                listeTypeRessource: [{lib: 'Equipe'},{lib: 'Individu'},{lib: 'Matériel'}],
                                listeCompetences: [],
                                listeTypesMateriels: [],
                                groupes: []
                            };

                            /**
                             * Périodes d'affichage gérées
                             * @type {string}
                             */
                            scope.dureeEnCours = "";
                            scope.idxDureeDefaut = "";
                            scope.dureePeriodes = ["1 semaine","2 semaines","3 semaines","4 semaines","5 semaines",
                                "6 semaines","7 semaines","8 semaines","9 semaines","10 semaines","11 semaines",
                                "12 semaines","1 mois","2 mois","3 mois","4 mois","5 mois","6 mois","7 mois",
                                "8 mois","9 mois","10 mois","11 mois","1 an","2 ans","3 ans","4 ans","5 ans","6 ans"];

                            /**
                             * Trier objets par une propriété donnée
                             * @param {String} property Pripriété cible pour le tri
                             * @returns {Function}
                             */
                            var dynamicSort = function (property) {
                                var sortOrder = 1;
                                if(property[0] === "-") {
                                    sortOrder = -1;
                                    property = property.substr(1);
                                }
                                return function (a,b) {
                                    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                                    return result * sortOrder;
                                }
                            };

                            /**
                             * Date par défaut initiale du ui-calendar en fonction des données à afficher
                             * @returns Date cible
                             */
                            var getCalendarDefaultDate = function () {
                                if ((scope.opNomModule !== "Contact") && !angular.isNullOrUndefined(scope.bufDonnees) && (scope.bufDonnees.length > 0)) {
                                    var minDate = null;
                                    for (var i = 0; i < scope.bufDonnees.length; i++) {
                                        if (angular.isNullOrUndefined(minDate) &&
                                            !angular.isNullOrUndefined(scope.bufDonnees[i].debut)) {
                                            minDate = moment(scope.bufDonnees[i].debut)
                                        } else if (!angular.isNullOrUndefined(minDate) &&
                                                   !angular.isNullOrUndefined(scope.bufDonnees[i].debut) &&
                                                   (moment(scope.bufDonnees[i].debut) < minDate)) {
                                                minDate = moment(scope.bufDonnees[i].debut);
                                        }
                                    }

                                    if (angular.isNullOrUndefined(minDate)) {
                                        minDate = moment();
                                    }

                                    return minDate;
                                } else {
                                    return moment();
                                }
                            };

                            /**
                             * Intitulé calendrier à partir de lélément appellant
                             * @returns {string}
                             */
                            var getLibSelection = function () {
                                var ref = (!angular.isNullOrUndefined(scope.bufDonnees) && (scope.bufDonnees.length > 0)) ? scope.bufDonnees[0].refEntete : "";
                                var contexte = (!angular.isNullOrUndefined(scope.opContextePlanning)) ? scope.opContextePlanning : "";

                                var lib = "";
                                if ((scope.opTypePlanning !== "contact") && (scope.opTypePlanning !== "recherche")) {
                                    if (ref.trim() !== "") {
                                        lib = ref;
                                    } else if (contexte.trim() !== "") {
                                        lib = contexte;
                                    }
                                }

                                return lib.trim();
                            };

                            /**
                             * Récupérer l'id de la ressource sélectionnée
                             * @returns {Number}
                             */
                            var getIdRessourceSelect = function () {
                                var id = -1;

                                for (var i = 0; i < scope.listeRessources.length; i++) {
                                    if (scope.listeRessources[i].selectionne) {
                                        id = scope.listeRessources[i].id;
                                        break;
                                    }
                                }

                                return id;
                            };

                            /**
                             * Récupérer le libellé de la ressource sélectionnée
                             * @returns {string}
                             */
                            var getLibRessourceSelect = function () {
                                var lib = "";

                                for (var i = 0; i < scope.listeRessources.length; i++) {
                                    if (scope.listeRessources[i].selectionne) {
                                        lib = scope.listeRessources[i].libRessource;
                                        break;
                                    }
                                }

                                return lib;
                            };

                            /**
                             * Marquer une ressource comme sélectionnée
                             * @param {string} libRessource Libellé de la ressource à sélectionner
                             */
                            var setRessourceSelect = function (libRessource) {
                                for (var i = 0; i < scope.listeRessources.length; i++) {
                                    scope.listeRessources[i].selectionne = (scope.listeRessources[i].libRessource === libRessource);
                                }
                            };

                            /**
                             * Liste des ressources
                             * @param {Object} donnees Source de données
                             * @returns {Array}
                             */
                            var getListeRessources = function  (donnees) {
                                var ressources = [];
                                var i, j, libRessource, idRessource, statutRessource, ressource, ajoutRessource;
                                var typeRessource, equipe, competence, libEquipeNullOuVide;

                                // Construire la liste des ressources
                                for (i = 0; i < donnees.length; i++) {
                                    // Parcourrir la table des ressources
                                    if ((!angular.isNullOrUndefined(donnees[i].ID_RESSOURCE)) &&
                                        (!angular.isNullOrUndefined(donnees[i].LIB_RESSOURCE))) {
                                        idRessource = donnees[i].ID_RESSOURCE.valeur;
                                        statutRessource = donnees[i].STATUT_RESSOURCE.valeur;
                                        typeRessource = donnees[i].TYPE_RESSOURCE.valeur;
                                        libRessource = donnees[i].LIB_RESSOURCE.valeur;
                                        libEquipeNullOuVide = (donnees[i].TYPE_RESSOURCE.valeur === "Matériel") ? "" : "Pas d'équipe";
                                        equipe = (!angular.isNullOrUndefined(donnees[i].EQUIPE.valeur) && (donnees[i].EQUIPE.valeur !== "")) ? donnees[i].EQUIPE.valeur : libEquipeNullOuVide;
                                        competence = donnees[i].COMPETENCE.valeur;

                                        // Pas d'ajout en doubon
                                        ajoutRessource = true;

                                        for (j = 0; j < ressources.length; j++) {
                                            if ((ressources[j].id == idRessource) && (ressources[j].libRessource == libRessource)) {
                                                ajoutRessource = false;
                                                break;
                                            }
                                        }

                                        // Ajout à la liste des ressources
                                        if (ajoutRessource) {
                                            ressource = {
                                                id: idRessource,
                                                statutRessource: statutRessource,
                                                typeRessource: typeRessource.trim(),
                                                libRessource: libRessource.trim(),
                                                equipe: equipe.trim(),
                                                competence: competence.trim(),
                                                selectionne: false
                                            };

                                            ressources.push(ressource);
                                        }
                                    }
                                }

                                // Mantis 6167 Le cas échéant compléter la liste des ressources avec la sélection (recherche) en cours
                                if (scope.opTypePlanning === "contact") {
                                    var listeIdOpSelection = [];
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                    for (var k = 0; k < scope.opSelection.length; k++) {
                                        if (!angular.isNullOrUndefined(scope.opSelection[k][paramPlanning.colonnesLigne.idEntete]) &&
                                            !angular.isNullOrUndefined(scope.opSelection[k][paramPlanning.colonnesLigne.idEntete].valeur)) {
                                            idRessource = scope.opSelection[k][paramPlanning.colonnesLigne.idEntete].valeur;

                                            if (!angular.isNullOrUndefined(scope.opSelection[k].COT_GRIL_LIBELLE) &&
                                                !angular.isNullOrUndefined(scope.opSelection[k].COT_GRIL_LIBELLE.valeur)) {
                                                libRessource = scope.opSelection[k].COT_GRIL_LIBELLE.valeur.split("(")[0].trim();
                                            } else {
                                                libRessource = "";
                                            }

                                            ajoutRessource = true;
                                            for (j = 0; j < ressources.length; j++) {
                                                if ((ressources[j].id == idRessource) &&
                                                    (ressources[j].libRessource == libRessource)) {
                                                    ajoutRessource = false;
                                                    break;
                                                }
                                            }

                                            if (ajoutRessource) {
                                                ressource = {
                                                    id: idRessource,
                                                    statutRessource: null,
                                                    typeRessource: "Intervenant",
                                                    libRessource: libRessource.trim(),
                                                    equipe: null,
                                                    competence: null,
                                                    selectionne: false
                                                };

                                                ressources.push(ressource);
                                            }
                                        }
                                    }


                                }

                                return ressources.sort(dynamicSort("libRessource"));
                            };

                            /**
                             * Liste des compétences
                             * @param donnees Source de données
                             */
                            var getListeCompetences = function (donnees) {
                                var competences = [];
                                var competence, idCompetence, libCompetence, ajoutCompetence;

                                // Construire la liste des compétences
                                for (var i = 0; i < donnees.length; i++) {
                                    idCompetence = donnees[i].ID_COT_COMPETENCE.valeur;
                                    libCompetence = donnees[i].COT_COMP_LIB.valeur;

                                    // Pas d'ajout en doubon
                                    ajoutCompetence = true;

                                    for (var j = 0; j < competences.length; j++) {
                                        if (competences[j].libCompetence === libCompetence) {
                                            ajoutCompetence = false;
                                            break;
                                        }
                                    }

                                    // Ajout à la liste des compétences
                                    if (ajoutCompetence) {
                                        competence = {
                                            id: idCompetence,
                                            libCompetence: libCompetence.trim()
                                        };

                                        competences.push(competence);
                                    }
                                }

                                return competences.sort(dynamicSort("libCompetence"));
                            };

                            /**
                             * Liste des types de matériels
                             * @param donnees Source de données
                             */
                            var getListeTypesMateriels = function (donnees) {
                                var typesMateriels = [];
                                var typeMateriel, idTypeMateriel, codeTypeMateriel, libTypeMateriel, ajoutTypeMateriel;

                                // Construire la liste des matériels
                                for (var i = 0; i < donnees.length; i++) {
                                    idTypeMateriel = donnees[i].ID_PAT_NATURE.valeur;
                                    codeTypeMateriel = donnees[i].PAT_NAT_CODE.valeur;
                                    libTypeMateriel = donnees[i].PAT_NAT_LIB.valeur;

                                    // Pas d'ajout en doubon
                                    ajoutTypeMateriel = true;

                                    for (var j = 0; j < typesMateriels.length; j++) {
                                        if (typesMateriels[j].libelle === libTypeMateriel) {
                                            ajoutTypeMateriel = false;
                                            break;
                                        }
                                    }

                                    // Ajout à la liste des matériels
                                    if (ajoutTypeMateriel) {
                                        typeMateriel = {
                                            id: idTypeMateriel,
                                            codeMateriel: codeTypeMateriel,
                                            libMateriel: libTypeMateriel.trim()
                                        };

                                        typesMateriels.push(typeMateriel);
                                    }
                                }

                                return typesMateriels.sort(dynamicSort("libMateriel"));
                            };

                            /**
                             * Groupes des ressources
                             * @param ressources Liste des ressources à traiter
                             */
                            var getGroupesRessources = function (ressources) {
                                var groupes = [];
                                var groupe, libGroupe, ajoutGroupe, typeRessource, libEquipeNullOuVide;

                                // Construire la liste des compétences
                                for (var i = 0; i < ressources.length; i++) {
                                    typeRessource = ressources[i].typeRessource;
                                    libEquipeNullOuVide = (typeRessource === "Matériel") ? "" : "Pas d'équipe";
                                    libGroupe = (!angular.isNullOrUndefined(ressources[i].equipe) && (ressources[i].equipe !== "")) ? ressources[i].equipe : libEquipeNullOuVide;

                                    // Pas d'ajout en doubon
                                    ajoutGroupe = true;

                                    for (var j = 0; j < groupes.length; j++) {
                                        if ((groupes[j].typeRessource != 'Equipe') && (groupes[j].libGroupe == libGroupe)) {
                                            ajoutGroupe = false;
                                            break;
                                        }
                                    }

                                    // Ajout à la liste des compétences
                                    if (ajoutGroupe) {
                                        groupe = {
                                            typeRessource: typeRessource,
                                            libGroupe: libGroupe.trim()
                                        };

                                        groupes.push(groupe);
                                    }
                                }

                                return groupes.sort(dynamicSort("libGroupe"));
                            };

                            /**
                             * Liste des ressources déjà affectées au planning
                             * @param {Array} listeRessources Liste des ressources à contrôler
                             * @returns {Array}
                             */
                            var getListeRessourcesAffectees = function  (listeRessources) {
                                var ressourcesAffectees = [];
                                var typeRessource = "";
                                var listeIdOpSelection = [];

                                // Mantis 6167 Identifier les ressources sélectionnées dans la recherche lors de la lancement du planning si planning des indisponibilités
                                if (scope.opTypePlanning === "contact") {
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                    for (var k = 0; k < scope.opSelection.length; k++) {
                                        if (!angular.isNullOrUndefined(scope.opSelection[k][paramPlanning.colonnesLigne.idEntete]) &&
                                            !angular.isNullOrUndefined(scope.opSelection[k][paramPlanning.colonnesLigne.idEntete].valeur)) {
                                            listeIdOpSelection.push(scope.opSelection[k][paramPlanning.colonnesLigne.idEntete].valeur);
                                        }
                                    }
                                }


                                for (var i = 0; i < listeRessources.length; i++) {
                                    // Mantis 6167 Ajouter ressources sélectionnées dans la recherche lors de la lancement du planning si planning des indisponibilités
                                    var idxSelection = (scope.opTypePlanning === "contact") ? listeIdOpSelection.indexOf(listeRessources[i].id) : -1;
                                    if (idxSelection > -1) {
                                        // Ajouter la ressource à la liste des ressources disponible pour saisie si pas déjà présente
                                        var dejaPresente = false;
                                        for (var l = 0; l < ressourcesAffectees.length; l++) {
                                            dejaPresente = (ressourcesAffectees[l].id === listeIdOpSelection[idxSelection].id);
                                            if (dejaPresente) {
                                                break;
                                            }
                                        }

                                        if (!dejaPresente) {
                                            ressourcesAffectees.push(listeRessources[i]);
                                        }
                                    } else {
                                        for (var j = 0; j < scope.bufDonnees.length; j++) {
                                            typeRessource = ((listeRessources[i].typeRessource === "Individu") || (listeRessources[i].typeRessource === "Equipe")) ? "Intervenant" : listeRessources[i].typeRessource;

                                            if ((typeRessource === scope.bufDonnees[j].typeRessource) &&
                                                (listeRessources[i].id === scope.bufDonnees[j].idRessource)) {
                                                ressourcesAffectees.push(listeRessources[i]);
                                                break;
                                            }
                                        }
                                    }
                                }

                                for (i = 0; i < ressourcesAffectees.length; i++) {
                                    ressourcesAffectees[i].existeEvent = false;

                                    for (j = 0; j < scope.bufDonnees.length; j++) {
                                        typeRessource = ((listeRessources[i].typeRessource === "Individu") || (listeRessources[i].typeRessource === "Equipe")) ? "Intervenant" : listeRessources[i].typeRessource;

                                        if ((typeRessource === scope.bufDonnees[j].typeRessource) &&
                                            (ressourcesAffectees[i].id === scope.bufDonnees[j].idRessource) &&
                                            !angular.isNullOrUndefined(scope.bufDonnees[j].idGroupe)) {
                                            ressourcesAffectees[i].existeEvent = true;
                                            break;
                                        }
                                    }
                                }

                                return ressourcesAffectees.sort(dynamicSort("libRessource"));
                            };

                            /**
                             * Construire cle de sélection en fonction du type de planning
                             * @returns {string}
                             */
                            var getCleSelection = function () {
                                if (scope.opTypePlanning === "recherche") {
                                    return "";
                                } else if ((scope.opNomModule === "Intervention") && (scope.opTypePlanning === "tache")) {
                                    return "ID_INTERVENTION" + SEPARATEUR_NIVEAU_1 + scope.opSelection[0].ID_INTERVENTION.valeur.toString();
                                } else if ((scope.opNomModule === "Intervention") && (scope.opTypePlanning === "recurrence")) {
                                    return "ID_INTERVENTION" + SEPARATEUR_NIVEAU_1 + scope.opSelection[0].ID_INTERVENTION.valeur.toString();
                                } else {
                                    return "";
                                }
                            };

                            /**
                             * Récup du numéro d'élémént pour un événement donné
                             * @param {number} idEvent Id de l'événement cible
                             * @returns {number}
                             */
                            var getNumeroElemParEvenement = function (idEvent) {
                                var numero = 0;
                                for (var i = 0; i < scope.numeroElemParEvenement.length; i++) {
                                    if (scope.numeroElemParEvenement[i].id === idEvent) {
                                        numero = scope.numeroElemParEvenement[i].numero;
                                        break;
                                    }
                                }

                                return numero;
                            };

                            /**
                             * Renseigner le numéro d'élément pour un événement donné
                             * @param {number} idEvent Id de l'événement à traiter
                             * @param {number} numero Nouveau numéro à attribuer
                             * @returns {*}
                             */
                            var setNumeroElementParEvenement = function (idEvent, numero) {
                                var deferred = $q.defer();

                                if (scope.numeroElemParEvenement.length === 0) {
                                    scope.numeroElemParEvenement.push({id: idEvent, numero: numero});
                                } else {
									for (var i = 0; i < scope.numeroElemParEvenement.length; i++) {
										if (scope.numeroElemParEvenement[i].id === idEvent) {
											scope.numeroElemParEvenement[i].numero = numero;
											break;
										}
									}
								}

                                deferred.resolve();

                                return deferred.promise;
                            };

                            /**
                             * Init tableau des numéros d'éléménts par événement
                             * @returns {Array}
                             */
                            var initNumeroElemParEvenement = function () {
                                var numeroElemParEvenement = [];

                                // Id des événements
                                var listeIdEvent = [];
                                for (var i = 0; i < scope.bufDonnees.length; i++) {
                                    if (listeIdEvent.indexOf(scope.bufDonnees[i].idEntete) === -1) {
                                        listeIdEvent.push(scope.bufDonnees[i].idEntete);
                                    }
                                }

                                for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                    if (listeIdEvent.indexOf(scope.bufDonneesRecurrence[i].idEntete) === -1) {
                                        listeIdEvent.push(scope.bufDonneesRecurrence[i].idEntete);
                                    }
                                }

                                // Calcul du nombre d'éléments par événement
                                for (i = 0; i < listeIdEvent.length; i++) {
                                    var numero = 0;
                                    for (var j = 0; j < scope.bufDonnees.length; j++) {
                                        if ((scope.bufDonnees[j].idEntete === listeIdEvent[i]) &&
                                            !angular.isNullOrUndefined(scope.bufDonnees[j].numeroInterneLigne) &&
                                            (numero < scope.bufDonnees[j].numeroInterneLigne)) {
                                            numero = scope.bufDonnees[j].numeroInterneLigne;
                                        }
                                    }

                                    for (j = 0; j < scope.bufDonneesRecurrence.length; j++) {
                                        if ((scope.bufDonneesRecurrence[j].idEntete === listeIdEvent[i]) &&
                                            !angular.isNullOrUndefined(scope.bufDonneesRecurrence[j].numeroInterneLigne) &&
                                            (numero < scope.bufDonneesRecurrence[j].numeroInterneLigne)) {
                                            numero = scope.bufDonneesRecurrence[j].numeroInterneLigne;
                                        }
                                    }

                                    numeroElemParEvenement.push({id: listeIdEvent[i], numero: numero});
                                }

                                return numeroElemParEvenement;
                            };

                            /**
                             * Récupérer le nombre d'occurences avant la date limite de récurrence
                             * @param {Date} dateReference Date de première occurence
                             * @param {string} typeRecurrence Type de récurrence
                             * @param {number} intervalle Intervalle de récurrence
                             * @param {Date} dateLimite Date limite de récurrence
                             * @returns {number}
                             */
                            var getNbreRecurrences = function (dateReference, typeRecurrence, intervalle, dateLimite) {
                                var nbreOccurences = 0;
                                var d = moment(dateReference);
                                var l = moment(dateLimite);

                                if (!angular.isNullOrUndefined(intervalle) && (intervalle > 0)) {
                                    if (typeRecurrence === "Jour") {
                                        d.add(intervalle, 'days');
                                        while (moment(d).isSame(l, 'day') || moment(d).isBefore(l, 'day')) {
                                            nbreOccurences++;
                                            d.add(intervalle, 'days');
                                        }
                                    } else if (typeRecurrence === "Semaine") {
                                        d.add(intervalle, 'weeks');
                                        while (moment(d).isSame(l, 'day') || moment(d).isBefore(l, 'day')) {
                                            nbreOccurences++;
                                            d.add(intervalle, 'weeks');
                                        }
                                    } else if (typeRecurrence === "Mois") {
                                        d.add(intervalle, 'months');
                                        while (moment(d).isSame(l, 'day') || moment(d).isBefore(l, 'day')) {
                                            nbreOccurences++;
                                            d.add(intervalle, 'months');
                                        }
                                    } else if (typeRecurrence === "Année") {
                                        d.add(intervalle, 'years');
                                        while (moment(d).isSame(l, 'day') || moment(d).isBefore(l, 'day')) {
                                            nbreOccurences++;
                                            d.add(intervalle, 'years');
                                        }
                                    }
                                }

                                return nbreOccurences;
                            };

                            /**
                             * Récupérer le nombre de jours avant la prochaine récurrence d'une événement
                             * @param {Date} dateReference Date de première occurence
                             * @param {string} typeRecurrence Type de récurrence
                             * @param {number} intervalle Intervalle de récurrence
                             * @param {number} occurence Numéro d'occurence dans la série
                             * @param {number} jourCible Jour/mois cible de l'occurence
                             * @returns {number}
                             */
                            var getDeltaEnJours = function (dateReference, typeRecurrence, intervalle, occurence, jourCible) {
                                var nbreJours = 0;
                                var d = moment(dateReference);

                                // TODO : Prendre en compte propriété jours ouvrés ?
                                // TODO : Prendre en compte propriété jours fériés ?
                                if (!angular.isNullOrUndefined(intervalle) && (intervalle > 0) &&
                                    !angular.isNullOrUndefined(occurence) && (occurence > 0)) {
                                    var nbre = occurence*intervalle;

                                    if (typeRecurrence === "Jour") {
                                        d.add(nbre, 'days');
                                        nbreJours = d.diff(moment(dateReference), 'days');
                                    } else if (typeRecurrence === "Semaine") {
                                        d.add(nbre, 'weeks');

                                        if (jourCible !== 0) {
                                            d.isoWeekday(jourCible); // Jour de la semaine
                                        }

                                        nbreJours = d.diff(moment(dateReference), 'days');
                                    } else if (typeRecurrence === "Mois") {
                                        d.add(nbre, 'months');

                                        if (jourCible !== 0) {
                                            d.date(jourCible); // Jour du mois
                                        }

                                        nbreJours = d.diff(moment(dateReference), 'days');
                                    } else if (typeRecurrence === "Année") {
                                        d.add(nbre, 'years');
                                        d.month(jourCible); // Mois
                                        nbreJours = d.diff(moment(dateReference), 'days');
                                    }
                                }

                                return nbreJours;
                            };

                            /**
                             * Trouver la vue calendrier en fonction du nom de duration d'affichage
                             * @param {string} duree Nom de la duration d'affichage
                             * @returns {string}
                             */
                            var getNomVue = function (duree) {
                                var vues = ["timelineWeek","timelineTwoWeek","timelineThreeWeek",
                                    "timelineFourWeek","timelineFiveWeek","timelineSixWeek","timelineSevenWeek",
                                    "timelineEightWeek","timelineNineWeek","timelineTenWeek","timelineElevenWeek",
                                    "timelineTwelveWeek","timelineMonth","timelineTwoMonth","timelineThreeMonth",
                                    "timelineFourMonth","timelineFiveMonth","timelineSixMonth","timelineSevenMonth",
                                    "timelineEightMonth","timelineNineMonth","timelineTenMonth","timelineElevenMonth",
                                    "timelineYear","timelineTwoYear","timelineThreeYear","timelineFourYear",
                                    "timelineFiveYear","timelineSixYear"
                                ];

                                return vues[scope.dureePeriodes.indexOf(duree)];
                            };

                            /**
                             * Trouver la duration d'affichage en fonction du nom de la vue calendrier
                             * @param {string} vue Nom de la vue calendrier
                             * @returns {string}
                             */
                            var getNomDuration = function (vue) {
                                var vues = ["timelineWeek","timelineTwoWeek","timelineThreeWeek",
                                    "timelineFourWeek","timelineFiveWeek","timelineSixWeek","timelineSevenWeek",
                                    "timelineEightWeek","timelineNineWeek","timelineTenWeek","timelineElevenWeek",
                                    "timelineTwelveWeek","timelineMonth","timelineTwoMonth","timelineThreeMonth",
                                    "timelineFourMonth","timelineFiveMonth","timelineSixMonth","timelineSevenMonth",
                                    "timelineEightMonth","timelineNineMonth","timelineTenMonth","timelineElevenMonth",
                                    "timelineYear","timelineTwoYear","timelineThreeYear","timelineFourYear",
                                    "timelineFiveYear","timelineSixYear"
                                ];

                                if (vue === "month") {
                                    return scope.dureePeriodes[vues.indexOf("timelineMonth")];
                                } else {
                                    return scope.dureePeriodes[vues.indexOf(vue)];
                                }
                            };

                            /**
                             * Gestionnaire click ouvrir/fermer groupe de ressources
                             */
                            var clickExpander = function () {
                                if (angular.element(this).next(".fc-cell-text")[0].textContent === 'Intervention') {
                                    scope.nbreClickRessourceExpander++;
                                }
                            };

                            /**
                             * Configuration pour uiCalendar
                             * @type {Object} uiConfig
                             */
                            scope.uiConfig = {
                                calendar: {
                                    lang: 'fr',
									firstDay: 1, // Premier jour de la semaine = lundi
                                    //schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
                                    schedulerLicenseKey: '0986972628-fcs-1478774926',
                                    height: 'parent', //'auto',
                                    //contentHeight: '100%', //450,                                    
									//aspectRatio: 1.8,
                                    editable: true,
                                    droppable: false, //true,
                                    eventReceive: function (event) {
                                        // Ressource qui a reçu le drag-drop dans le calendrier
                                        var ressource  = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getResourceById', event.resourceId);
                                        var i;
                                        var doublon = false;
                                        var ressourceAutorisee = (scope.opTypePlanning !== "contact") ? true : (ressource.idRes === event.element.idRessource);

                                        if (scope.opTypePlanning !== "contact") {
                                            // Pas d'ajout en doublon
                                            for (i = 0; i < scope.bufDonnees.length; i++) {
                                                // Evénement déjà présent pour cette ressource ?
                                                if ((scope.bufDonnees[i].idRessource === ressource.idRes) &&
                                                    (scope.bufDonnees[i].libRessource === ressource.libRes) &&
                                                    (scope.bufDonnees[i].idGroupe === event.element.idGroupe)) {
                                                    doublon = true;
                                                    break;
                                                }
                                            }
                                        }

                                        // Ajout autorisé ?
                                        var ajoutAutorise = (!doublon && ressourceAutorisee);

                                        // Entête d'événement terminée ?
                                        var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                        if (ajoutAutorise && !angular.isNullOrUndefined(event.element.statutEntete)) {
                                            if (paramPlanning.statutsLecutreSeule.indexOf(event.element.statutEntete) !== -1) {
                                                ajoutAutorise = false;
                                            }
                                        }

                                        // Ligne d'événement terminée ?
                                        if (ajoutAutorise && !angular.isNullOrUndefined(event.element.statutLigne)) {
                                            if (paramPlanning.statutsLecutreSeule.indexOf(event.element.statutLigne) !== -1) {
                                                ajoutAutorise = false;
                                            }
                                        }

                                        if (ajoutAutorise) {
                                            // Ajouter l'événement au cache de données
                                            var dateDebut = event.start;
                                            var dateFin = event.end;
                                            if ((scope.opTypePlanning === "contact")) {
                                                scope.idGroupe = scope.idGroupe + 1;
                                            } else if (!angular.isNullOrUndefined(event.element.debut)) {
                                                dateDebut = event.element.debut;
                                                dateFin = event.element.fin;
                                            }

                                            var donneesLigne = {
                                                idEntete: event.element.idEntete,
                                                refEntete: event.element.refEntete,
                                                adresseEntete: event.element.adresseEntete,
                                                typeRessource: ressource.typeRes,
                                                idRessource: ressource.idRes,
                                                libRessource: ressource.libRes,
                                                idLigne: (scope.opTypePlanning !== "contact") ? event.element.idLigne : null,
                                                idGroupe: (scope.opTypePlanning !== "contact") ? event.element.idGroupe : scope.idGroupe,
                                                refLigne: event.element.refLigne,
                                                libLigne: event.element.libLigne,
                                                descLigne: event.element.descLigne,
                                                debut: dateDebut,
                                                fin: dateFin,
                                                debutReel: (scope.opTypePlanning !== "contact") ? event.element.debutReel : dateDebut,
                                                finReelle: (scope.opTypePlanning !== "contact") ? event.element.finReelle : dateFin,
                                                //heureDebut: event.element.heureDebut,
                                                //heureFin: event.element.heureFin,
                                                dureeJours: event.element.dureeJours,
                                                dureeHeures: event.element.dureeHeures,
                                                avancement: (scope.opTypePlanning !== "contact") ? event.element.avancement : null,
                                                visible: true,
                                                ligneVide: false,
                                                selectionne: ((scope.opTypePlanning !== "contact") && !angular.isNullOrUndefined(event.element.selectionne)) ? event.element.selectionne : false,
                                                estAjout: (scope.opTypePlanning !== "contact") ? event.element.estAjout : true,
                                                modifie: true
                                            };

                                            // Ligne de ressource déjà présente sans événement ?
                                            var idxLigne = -1;
                                            for (i = 0; i < scope.bufDonnees.length; i++) {
                                                if ((scope.bufDonnees[i].typeRessource === ressource.typeRes) &&
                                                    (scope.bufDonnees[i].idRessource === ressource.idRes) &&
                                                    (scope.bufDonnees[i].libRessource === ressource.libRes) &&
                                                    (scope.bufDonnees[i].ligneVide)) {
                                                    idxLigne = i;
                                                    break;
                                                }
                                            }

                                            if (idxLigne !== -1) {
                                                // Maj ligne du bufDonnees
                                                scope.bufDonnees[idxLigne] = donneesLigne;
                                            } else {
                                                // Ajout élément au bufDonnees
                                                scope.bufDonnees.push(donneesLigne);
                                            }

                                            // Le cas échéant, retirer ligne événement sans ressource
                                            idxLigne = -1;
                                            for (i = 0; i < scope.bufDonnees.length; i++) {
                                                if ((scope.bufDonnees[i].idGroupe === event.element.idGroupe) &&
                                                    angular.isNullOrUndefined(scope.bufDonnees[i].idRessource)) {
                                                    idxLigne = i;
                                                    break;
                                                }
                                            }

                                            if (idxLigne !== -1) {
                                                scope.bufDonnees.splice(idxLigne, 1);
                                            }

                                            // Marquer les lignes du groupe comme modifiées
                                            for (i = 0; i < scope.bufDonnees.length; i++) {
                                                if (scope.bufDonnees[i].idGroupe === event.element.idGroupe) {
                                                    scope.bufDonnees[i].modifie = true;
                                                }
                                            }

                                            scope.planningModifie = true;
                                        }

                                        // Maj la barre des événements disponibles (prise en compte changement éventuel de date de début d'événement)
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(false);
                                    },
                                    weekNumbers: true,
                                    header: {
                                        left: '',
                                        center: 'title',
                                        right: ''
                                    },
                                    views: {
                                        timelineWeek: {
                                            buttonText: 'S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1}
                                        },
                                        timelineTwoWeek: {
                                            type: 'timeline',
                                            buttonText: '2S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 2}
                                        },
                                        timelineThreeWeek: {
                                            type: 'timeline',
                                            buttonText: '3S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 3}
                                        },
                                        timelineFourWeek: {
                                            type: 'timeline',
                                            buttonText: '4S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 4}
                                        },
                                        timelineFiveWeek: {
                                            type: 'timeline',
                                            buttonText: '5S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 5}
                                        },
                                        timelineSixWeek: {
                                            type: 'timeline',
                                            buttonText: '6S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 6}
                                        },
                                        timelineSevenWeek: {
                                            type: 'timeline',
                                            buttonText: '7S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 7}
                                        },
                                        timelineEightWeek: {
                                            type: 'timeline',
                                            buttonText: '8S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 8}
                                        },
                                        timelineNineWeek: {
                                            type: 'timeline',
                                            buttonText: '9S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 9}
                                        },
                                        timelineTenWeek: {
                                            type: 'timeline',
                                            buttonText: '10S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 10}
                                        },
                                        timelineElevenWeek: {
                                            type: 'timeline',
                                            buttonText: '11S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 11}
                                        },
                                        timelineTwelveWeek: {
                                            type: 'timeline',
                                            buttonText: '12S',
                                            titleFormat: 'DD MMMM YY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'dd DD'    // Jour du mois (Lu 01, Ma 02, Me 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {weeks: 12}
                                        },
                                        timelineMonth: {
                                            buttonText: 'M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'DD'    // Jour du mois (01, 02, 03, ...)
                                            ]
                                        },
                                        timelineTwoMonth: {
                                            type: 'timeline',
                                            buttonText: '2M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                '[S]WW [(]MMMM[)]',   // Numéro de semaine et mois : S01 (janvier), S02 (janvier), S03 (janvier) ...
                                                'DD'    // Jour du mois (01, 02, 03, ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {months: 2}
                                        },
                                        timelineThreeMonth: {
                                            type: 'timeline',
                                            buttonText: '3M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 1},
                                            duration: {months: 3}
                                        },
                                        timelineFourMonth: {
                                            type: 'timeline',
                                            buttonText: '4M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 4}
                                        },
                                        timelineFiveMonth: {
                                            type: 'timeline',
                                            buttonText: '5M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 5}
                                        },
                                        timelineSixMonth: {
                                            type: 'timeline',
                                            buttonText: '6M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 6}
                                        },
                                        timelineSevenMonth: {
                                            type: 'timeline',
                                            buttonText: '7M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 7}
                                        },
                                        timelineEightMonth: {
                                            type: 'timeline',
                                            buttonText: '8M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 8}
                                        },
                                        timelineNineMonth: {
                                            type: 'timeline',
                                            buttonText: '9M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 9}
                                        },
                                        timelineTenMonth: {
                                            type: 'timeline',
                                            buttonText: '10M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 10}
                                        },
                                        timelineElevenMonth: {
                                            type: 'timeline',
                                            buttonText: '11M',
                                            titleFormat: 'MMMM YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM', // Mois (janvier, février, ...)
                                                '[S]WW',   // Numéro de semaine (S01, S02, S03 ...)
                                            ],
                                            slotDuration: {days: 7},
                                            duration: {months: 11}
                                        },
                                        timelineYear: {
                                            buttonText: 'A',
                                            titleFormat: 'YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'MMMM' // Mois (janvier, février, ...)
                                            ],
                                            slotDuration: {months: 1}
                                        },
                                        timelineTwoYear: {
                                            type: 'timeline',
                                            buttonText: '2A',
                                            titleFormat: 'YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'YYYY', // Année
                                                'MMMM' // Mois (janvier, février, ...)
                                            ],
                                            slotDuration: {months: 1},
                                            duration: {months: 24}
                                        },
                                        timelineThreeYear: {
                                            type: 'timeline',
                                            buttonText: '3A',
                                            titleFormat: 'YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'YYYY', // Année
                                                'MMMM' // Mois (janvier, février, ...)
                                            ],
                                            slotDuration: {months: 1},
                                            duration: {months: 36}
                                        },
                                        timelineFourYear: {
                                            type: 'timeline',
                                            buttonText: '4A',
                                            titleFormat: 'YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'YYYY', // Année
                                                'MMMM' // Mois (janvier, février, ...)
                                            ],
                                            slotDuration: {months: 1},
                                            duration: {months: 48}
                                        },
                                        timelineFiveYear: {
                                            type: 'timeline',
                                            buttonText: '5A',
                                            titleFormat: 'YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'YYYY', // Année
                                                'MMMM' // Mois (janvier, février, ...)
                                            ],
                                            slotDuration: {months: 1},
                                            duration: {months: 60}
                                        },
                                        timelineSixYear: {
                                            type: 'timeline',
                                            buttonText: '6A',
                                            titleFormat: 'YYYY',
                                            //resourceLabelText: getLibSelection(),
                                            resourceAreaWidth: '15%',
                                            slotLabelFormat: [
                                                'YYYY', // Année
                                                'MMMM' // Mois (janvier, février, ...)
                                            ],
                                            slotDuration: {months: 1},
                                            duration: {months: 72}
                                        },
                                        month: {
                                            buttonText: 'Agenda',
                                            titleFormat: (getLibSelection() === "")?'MMMM YYYY':'MMMM YYYY[ [' + getLibSelection() + ']]'
                                        }
                                    },
                                    eventClick: function (event, jsEvent, view) {
                                        if (scope.opTypePlanning === "recherche") {
                                            // TODO Mantis 6217 Gérer dropdown ?
                                            /*if (scope.opNomModule === "Intervention") {
                                                $(this).find(jsEvent.target).contextMenu('open', jsEvent);
                                            } else {*/
                                                if (scope.opNomModule === "Contact") {
                                                    // Gestion sélection module Contact via l'objet resource
                                                    var r = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getResourceById', event.resourceId);
                                                    event.selectionne = (!event.selectionne);
                                                    if (event.selectionne) {
                                                        r.eventClassName = 'op-event-selectionne';
                                                    } else {
                                                        r.eventClassName = '';
                                                    }
                                                } else {
                                                    // Gestion sélection des autres modules via l'objet event
                                                    var clientEvents = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('clientEvents', function (evt) {
                                                        var ignorer = (evt.lectureSeule && (scope.listeIdLectureSeule.indexOf(evt.id) !== -1));
                                                        return (!ignorer && (evt.idEntete === event.idEntete) && (evt.idRessource === event.idRessource)) ? true : false;
                                                    });

                                                    for (var i = 0; i < clientEvents.length; i++) {
                                                        var eventArrierPlan = (!angular.isNullOrUndefined(clientEvents[i].rendering)) ? (clientEvents[i].rendering == "background") : false;
                                                        if (!eventArrierPlan && (clientEvents[i].idEntete === event.idEntete)) {
                                                            clientEvents[i].majStatutSelection = true;
                                                            clientEvents[i].selectionne = (!clientEvents[i].selectionne);

                                                            if (clientEvents[i].selectionne) {
                                                                clientEvents[i].className = 'op-event-selectionne';
                                                            } else {
                                                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                                if (clientEvents[i].maitre) {
                                                                    if (!angular.isNullOrUndefined(clientEvents[i].statutEntete) &&
                                                                        (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutEntete) !== -1)) {
                                                                        clientEvents[i].className = 'op-event-maitre-statut-entete';
                                                                    } else {
                                                                        clientEvents[i].className = 'op-event-maitre';
                                                                    }
                                                                } else {
                                                                    if (clientEvents[i].lectureSeule && (scope.listeIdLectureSeule.indexOf(clientEvents[i].id) !== -1)) {
                                                                        clientEvents[i].className = 'op-event-lecture-seule';
                                                                    } else if (!angular.isNullOrUndefined(clientEvents[i].statutEntete) &&
                                                                        (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutEntete) !== -1)) {
                                                                        clientEvents[i].className = 'op-event-statut-entete';
                                                                    } else if (!angular.isNullOrUndefined(clientEvents[i].statutLigne) &&
                                                                        (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutLigne) !== -1)) {
                                                                        clientEvents[i].className = 'op-event-statut-ligne';
                                                                    } else {
                                                                        // Bootstrap css btn-primary
                                                                        clientEvents[i].className = 'op-event-defaut';
                                                                    }
                                                                }
                                                            }

                                                            // Redessiner l'évenement
                                                            uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('updateEvent', clientEvents[i]);															
                                                        }
                                                    }
                                                }

                                                // Modifier la sélection de la recherche
                                                gererSelection(event.idEntete, event.selectionne);
                                            //}
                                        } else {
                                            // Afficher les détails de l'événement (et permettre la suppression)
                                            var ressource = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getResourceById', event.resourceId);
                                            var popupDetail = $modal.open({
                                                templateUrl: 'moteur/composants/planning/OpPopupDetailEvenement.html',
                                                controller: 'fr.operis.moteurV4.composants.planning.OpPopupDetailEvenementControleur',
                                                size: 'sm',
                                                resolve: {
                                                    typePlanning: function () {
                                                        return scope.opTypePlanning;
                                                    },
                                                    titre: function () {
                                                        var t = "";
                                                        if (scope.opTypePlanning === "contact") {
                                                            t = ressource.libRes;
                                                        } else {
                                                            if (scope.listeIdLectureSeule.indexOf(event.id) === -1) {
                                                                if (!angular.isNullOrUndefined(scope.opContextePlanning)) {
                                                                    t = scope.opContextePlanning;
                                                                } else {
                                                                    t = event.refEntete.trim();
                                                                }
                                                            } else {
                                                                t = event.refEntete.trim();
                                                            }
                                                        }

                                                        return t;
                                                    },
                                                    ressource: function () {
                                                        return ressource;
                                                    },
                                                    evenement: function () {
                                                        return event;
                                                    }
                                                }
                                            });

                                            popupDetail.result.then(function (retour) {
                                                if (!angular.isNullOrUndefined(retour) && (retour === "supprimer")) {
                                                    // Suppression de l'événement
                                                    supprimerEvenement(event, ressource);
                                                }
                                            })
                                        }
                                    },
                                    viewRender: function (view, element) {
                                        var i, j, niveauRegroupement, retirerEvenement, calEvenement;

                                        // Ajout gestionnaire suite clique pour ouvrir/fermer un groupe de ressources
                                        element.find(".fc-expander").off('click', clickExpander);
                                        element.find(".fc-expander").on('click', clickExpander);
										
                                        // Traitements particulière pour les vues trimestre et supérieure
                                        if ((view.name == 'timelineThreeMonth') || (view.name == 'timelineSixMonth') || (view.name == 'timelineYear')) {
                                            // Pas de coloriage des jours du weekend
                                            element.find(".fc-sat, .fc-sun").css("background-color", "#fff");

                                            // Ne pas afficher les jours fériés
                                            for (i = 0; i < scope.eventSources.length; i++) {
                                                if (scope.eventSources[i].joursFeries) {
                                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('removeEventSource', scope.eventSources[i]);
                                                    scope.eventSources[i].wasRemoved = true;
                                                    break;
                                                }
                                            }
                                        } else {
                                            element.find(".fc-sat, .fc-sun").css("background-color", "lightgrey");

                                            // Si besoin, rétablir l'affichage des jours fériés
                                            for (i = 0; i < scope.eventSources.length; i++) {
                                                if (scope.eventSources[i].joursFeries) {
                                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('addEventSource', scope.eventSources[i]);
                                                    scope.eventSources[i].wasRemoved = false;
                                                    break;
                                                }
                                            }
                                        }

                                        // Forcer date début d'affichage si vue trimestre
                                        var debutInterval = moment(view.intervalStart).month();

                                        if (view.name == 'timelineThreeMonth') {
                                            var nbreMois = 0;

                                            // Attention, les mois vont de 0 à 11 dans Moment.js
                                            if ((debutInterval > 0) && (debutInterval <= 2)) {
                                                nbreMois = 0 - debutInterval;
                                            } else if ((debutInterval > 3) && (debutInterval <= 5)) {
                                                nbreMois = 0 - (debutInterval - 3);
                                            } else if ((debutInterval > 6) && (debutInterval <= 8)) {
                                                nbreMois = 0 - (debutInterval - 6);
                                            } else if ((debutInterval > 9) && (debutInterval <= 11)) {
                                                nbreMois = 0 - (debutInterval - 9);
                                            }

                                            if (nbreMois != 0) {
                                                uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('incrementDate', {months: nbreMois});
                                            }
                                        }
                                    },
                                    eventRender: function (event, element, view) {
                                        scope.$evalAsync(function () {
                                            // Affichage tooltips
                                            /*var eventDuration = "Du " + moment(event.start).format("DD/MM/YYYY HH:mm");
                                            if (moment(event.end).isValid()) {
                                                eventDuration += " au " + moment(event.end).format("DD/MM/YYYY HH:mm");
                                            } else {
                                                eventDuration += " au " + moment(event.start).format("DD/MM/YYYY HH:mm");
                                            }*/
                                            var eventDuration = "";
                                            if (!angular.isNullOrUndefined(event.fin)) {
                                                if (moment(event.fin).isValid()) {
                                                    eventDuration = "Du " + moment(event.debut).format("DD/MM/YYYY HH:mm");
                                                    eventDuration += " au " + moment(event.fin).format("DD/MM/YYYY HH:mm");
                                                } else {
                                                    eventDuration = moment(event.debut).format("DD/MM/YYYY HH:mm");
                                                }
                                            } else {
                                                eventDuration = moment(event.debut).format("DD/MM/YYYY HH:mm");
                                            }
											eventDuration = eventDuration.replace(/ 00:00/g, "");

                                            var eventContexte = "";
                                            if (scope.opTypePlanning !== "contact") {
                                                if ((scope.opTypePlanning !== "recherche") &&
                                                    (scope.listeIdLectureSeule.indexOf(event.id) === -1) &&
                                                    (!angular.isNullOrUndefined(scope.opContextePlanning))) {
                                                    eventContexte = scope.opContextePlanning;
                                                } else {
                                                    eventContexte = (!angular.isNullOrUndefined(event.refEntete)) ? event.refEntete.trim() : "";
                                                }
                                            }

                                            var eventTitle = "";
                                            if (!event.maitre) {
                                                if (!angular.isNullOrUndefined(event.refInterneLigne) && (event.refInterneLigne.trim() !== "")) {
                                                    if (eventContexte !== "") {
                                                        eventContexte = event.refInterneLigne.trim() + " (" + eventContexte + ")";
                                                    } else {
                                                        eventContexte = event.refInterneLigne.trim();
                                                    }
                                                }

                                                if ((event.refLigne !== "") || (event.libLigne !== "")) {
                                                    if (!angular.isNullOrUndefined(event.refLigne)) {
                                                        eventTitle += event.refLigne.trim();
                                                    }

                                                    if (!angular.isNullOrUndefined(event.libLigne)) {
                                                        eventTitle += " " + event.libLigne.trim();
                                                    }

                                                    if (eventTitle !== "") {
                                                        eventTitle = eventTitle.trim() + "\n";
                                                    }
                                                }
                                            }

                                            if (eventContexte !== "") {
                                                eventContexte += "\n";
                                            }

                                            if ((eventTitle === "") && (!angular.isNullOrUndefined(event.title)) && (event.title != "")) {
                                                eventTitle = event.title + "\n";
                                            }

                                            var libRessource = "";
                                            for (var i = 0; i < scope.uiConfig.calendar.resources.length; i++) {
                                                if (scope.uiConfig.calendar.resources[i].id == event.resourceId) {
                                                    libRessource = scope.uiConfig.calendar.resources[i].f1;
                                                    break;
                                                }
                                            }

                                            if (!event.jourFerie) {
                                                if (event.maitre) {
                                                    var adrEventMaitre = "";
                                                    var adrLignesMaitre = (!angular.isNullOrUndefined(event.adresseEntete) && (event.adresseEntete.trim() !== "")) ? event.adresseEntete.split(/\\n/) : [];
                                                    for (var idxLigneAdrMaitre = 0; idxLigneAdrMaitre < adrLignesMaitre.length; idxLigneAdrMaitre++) {
                                                        if (adrLignesMaitre[idxLigneAdrMaitre].trim() !== "") {
                                                            adrEventMaitre += "\n" + adrLignesMaitre[idxLigneAdrMaitre].trim();
                                                        }
                                                    }

                                                    element.attr('title', eventTitle + eventDuration + adrEventMaitre);
                                                } else {
                                                    var descEvent = (!angular.isNullOrUndefined(event.descLigne) && (event.descLigne.trim() !== "")) ? "\n" + event.descLigne.trim() : "";

                                                    // Adresse
                                                    var adrEvent = "";
                                                    var adrLignes = (!angular.isNullOrUndefined(event.adresseEntete) && (event.adresseEntete.trim() !== "")) ? event.adresseEntete.split(/\\n/) : [];
                                                    for (var idxLigneAdr = 0; idxLigneAdr < adrLignes.length; idxLigneAdr++) {
                                                        if (adrLignes[idxLigneAdr].trim() !== "") {
                                                            adrEvent += "\n" + adrLignes[idxLigneAdr].trim();
                                                        }
                                                    }

                                                    if (libRessource.trim() != "") {
                                                        element.attr('title', eventContexte + eventTitle + eventDuration + adrEvent + "\n" + libRessource.trim() + descEvent);
                                                    } else {
                                                        element.attr('title', eventContexte + eventTitle + eventDuration + adrEvent + descEvent);
                                                    }
                                                }
                                            }

                                            // Afficher titleAgenda pour les évènements en vue agenda (month)
                                            if (view.name == 'month') {
                                                element.find('.fc-title').text(event.titleAgenda);
                                            } else {
                                                element.find('.fc-title').text(event.titleDefault);
                                            }

                                            // TODO Mantis 6217 : Ajouter menu contextuel pour ouvrir planning des tâches si planning de recherche ?
                                            /*if ((!event.jourFerie) &&
                                                (scope.opTypePlanning === "recherche") &&
                                                (scope.opNomModule === "Intervention")) {
                                                element.find('.fc-content').contextMenu(scope.getEventContexteMenu());
                                                element.find('.fc-content').attr('event-id-entete', event.idEntete);
                                                element.find('.fc-content').attr('event-selectionne', event.selectionne);
                                                element.find('.fc-title').contextMenu(scope.getEventContexteMenu());
                                                element.find('.fc-title').attr('event-id-entete', event.idEntete);
                                                element.find('.fc-title').attr('event-selectionne', event.selectionne);
                                            }*/

                                            $compile(element)(scope);
                                        });
                                    },
                                    eventAfterAllRender: function (view) {
                                        // Maj dates début dans la barre de navigation
                                        if (view.name == 'month') {
                                            // Affichage agenda
                                            scope.dateDebutPeriode = moment(view.intervalStart).toDate();
                                        } else {
                                            // Affichage timeline
                                            scope.dateDebutPeriode = moment(view.start).toDate();

                                            // Groupe 'Intervention' fermé par défaut si mode recherche
                                            if (scope.opTypePlanning === "recherche") {
                                                if (angular.isNullOrUndefined(scope.nbreClickRessourceExpander)) {
                                                    scope.nbreClickRessourceExpander = 0;
                                                }

                                                if (scope.nbreClickRessourceExpander === 0) {
                                                    for (var i = 0; i < view.rowHierarchy.children.length; i++) {
                                                        var thisRow = view.rowHierarchy.children[i];
                                                        if (thisRow.groupValue === 'Intervention') {
                                                            thisRow.collapse();
                                                        }
                                                    }
                                                }
                                            }											
                                        }
                                    },
                                    eventDragStart: function (event, jsEvent, ui, view) {
                                        // Mémorizer ressourceId actuel
                                        event.depuisResourceId = event.resourceId;
                                        event.depuisStart = event.start;
                                    },
                                    eventDrop: function (event, delta, revertFunc) {
                                        var view = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getView');

                                        if (view.name === "month") {
                                            revertFunc();  // Pas de modification en mode agenda
                                        } else if (scope.opTypePlanning === "recherche") {
                                            revertFunc();  // Pas de modification en mode recherche
                                        } else if (!event.editable || event.lectureSeule) {
                                            revertFunc();  // Pas de modification si événement en lecture seule
                                        } else {
                                            var ressource = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getResourceById', event.resourceId);
                                            var interdireDrop = false;

                                            if (!angular.isNullOrUndefined(ressource)) {
                                                if (event.resourceId !== event.depuisResourceId) {
                                                    // Drop sur ligne de ressource "mâtre" ?
                                                    if (scope.opTypePlanning === "recurrence") {
                                                        if (scope.opActionCode === "GERERRECURRENCE") {
                                                            interdireDrop = (event.resourceId === "Intervention-" + scope.idEntete.toString());
                                                        }
                                                    } else if (scope.opTypePlanning === "contact") {
                                                        // Pas de changement de ressource si planning contacts
                                                        interdireDrop = true;
                                                    }

                                                    if (!interdireDrop) {
                                                        // Evénement déjà présent pour la nouvelle ressource ?
                                                        for (var i = 0; i < scope.bufDonnees.length; i++) {
                                                            if ((scope.bufDonnees[i].typeRessource === ressource.typeRes) &&
                                                                (scope.bufDonnees[i].idRessource === ressource.idRes) &&
                                                                (scope.bufDonnees[i].libRessource === ressource.libRes) &&
                                                                (scope.bufDonnees[i].idGroupe === event.idGroupe)) {
                                                                interdireDrop = true;
                                                                break;
                                                            }
                                                        }
                                                    }

                                                    if (!interdireDrop) {
                                                        // Evénement déjà présent pour la nouvelle ressource récurrente ?
                                                        for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                                            if ((scope.bufDonneesRecurrence[i].typeRessource === ressource.typeRes) &&
                                                                (scope.bufDonneesRecurrence[i].idRessource === ressource.idRes) &&
                                                                (scope.bufDonneesRecurrence[i].libRessource === ressource.libRes) &&
                                                                (scope.bufDonneesRecurrence[i].idGroupe === event.idGroupe)) {
                                                                interdireDrop = true;
                                                                break;
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    if (scope.opTypePlanning === "contact") {
                                                        var idRessourceSelect = getIdRessourceSelect();
                                                        interdireDrop = (ressource.idRes !== idRessourceSelect);
                                                    }
                                                }

                                                if (interdireDrop) {
                                                    revertFunc(); // Drag/drop interdit
                                                } else {
                                                    // Basculer données en cas de modification de ressource
                                                    var depuisRessource  = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getResourceById', event.depuisResourceId);
                                                    for (i = 0; i < scope.bufDonnees.length; i++) {
                                                        if ((event.idGroupe === scope.bufDonnees[i].idGroupe) &&
                                                            (depuisRessource.typeRes === scope.bufDonnees[i].typeRessource) &&
                                                            (depuisRessource.idRes === scope.bufDonnees[i].idRessource) &&
                                                            (depuisRessource.libRes === scope.bufDonnees[i].libRessource)){
                                                            scope.bufDonnees[i].typeRessource = ressource.typeRes;
                                                            scope.bufDonnees[i].idRessource = ressource.idRes;
                                                            scope.bufDonnees[i].libRessource = ressource.libRes;
                                                            break;
                                                        }
                                                    }

                                                    for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                                        if ((event.idGroupe === scope.bufDonneesRecurrence[i].idGroupe) &&
                                                            (depuisRessource.typeRes === scope.bufDonneesRecurrence[i].typeRessource) &&
                                                            (depuisRessource.idRes === scope.bufDonneesRecurrence[i].idRessource) &&
                                                            (depuisRessource.libRes === scope.bufDonneesRecurrence[i].libRessource)){
                                                            scope.bufDonneesRecurrence[i].typeRessource = ressource.typeRes;
                                                            scope.bufDonneesRecurrence[i].idRessource = ressource.idRes;
                                                            scope.bufDonneesRecurrence[i].libRessource = ressource.libRes;
                                                            break;
                                                        }
                                                    }

                                                    // Evénement déplacé de combien jours ?
                                                    var nbreJours = event.start.diff(event.depuisStart, 'days');

                                                    // Mettre à jour les dates de l'événement puis actualiser le calendrier
                                                    modifierDuration(event, delta, nbreJours);
                                                }
                                            }
                                        }
                                    },
                                    eventResizeStart: function (event, jsEvent, ui, view) {
                                        event.resizeStart = event.start;
                                        event.resizeEnd = (angular.isNullOrUndefined(event.end)) ? event.fin : event.end;
                                    },
                                    eventResize: function (event, delta, revertFunc) {
                                        var view = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getView');

                                        if (view.name === "month") {
                                            revertFunc();  // Pas de modification en mode agenda
                                        } else if (scope.opTypePlanning === "recherche") {
                                            revertFunc();  // Pas de modification en mode recherche
                                        } else if (event.maitre) {
                                            revertFunc();  // Pas de modification des événements des lignes de ressource maîtres
                                        } else if (!event.editable || event.lectureSeule) {
                                            revertFunc();  // Pas de modification si événement en lecture seule
                                        } else {
                                            var interdireResize = false;
                                            // Modification événement d'une ligne de ressource "mâtre" ?
                                            if (scope.opTypePlanning === "recurrence") {
                                                if (scope.opActionCode === "GERERRECURRENCE") {
                                                    interdireResize = (event.resourceId === "Intervention-" + scope.idEntete.toString());
                                                }
                                            }

                                            if (!interdireResize && (scope.opTypePlanning === "contact")) {
                                                // Pas de modification si pas la ressource sélectionnée
                                                var ressource = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getResourceById', event.resourceId);
                                                var idRessourceSelect = getIdRessourceSelect();
                                                interdireResize = (ressource.idRes !== idRessourceSelect);
                                            }

                                            if (interdireResize) {
                                                revertFunc(); // Modification interdite
                                            } else {
                                                modifierDuration(event, delta, 0); // Mettre à jour les dates de l'événement puis actualiser le calendrier
                                            }
                                        }
                                    }
                                }
                            };

                            /**
                             * Initialiser le calendrier
                             */
                            var initCalendrier = function () {
                                var deferred = $q.defer();

                                // Vue de défaut
                                if (angular.isNullOrUndefined(scope.dureeEnCours) || (scope.dureeEnCours === "")) {
                                    scope.uiConfig.calendar.defaultView = "timelineTwoWeek";
                                    scope.dureeEnCours = getNomDuration("timelineTwoWeek");
                                    scope.idxDureeDefaut = scope.dureePeriodes.indexOf(scope.dureeEnCours);
                                }

                                // Date de défaut
                                if ((!angular.isNullOrUndefined(scope.reinitDefaultDate) && scope.reinitDefaultDate) ||
                                    angular.isNullOrUndefined(scope.dateDebutPeriode)) {
                                    scope.uiConfig.calendar.defaultDate = getCalendarDefaultDate();
                                    scope.dateDebutPeriode = scope.uiConfig.calendar.defaultDate.toDate();
                                    scope.reinitDefaultDate = false;
                                } /*else if (!angular.isNullOrUndefined(scope.debutEntete)) {
                                    scope.uiConfig.calendar.defaultDate = moment(scope.debutEntete);
                                }*/ else {
                                    scope.uiConfig.calendar.defaultDate = moment(scope.dateDebutPeriode);
                                }

                                // Colonnes des ressources
                                scope.uiConfig.calendar.resourceColumns = [{labelText: getLibSelection(), field: "f1"}];

                                // Affecter le tableau des ressources
                                scope.uiConfig.calendar.resourceGroupField = "typeRes";
                                scope.uiConfig.calendar.resources = scope.eventRessources;

                                // Activation drag/drop ?
                                scope.uiConfig.calendar.droppable = (!scope.modeAffichage.agenda && (scope.eventRessources.length > 0));

                                // Ajout de la fonction d'ouverture du datepicker
                                if (angular.isNullOrUndefined(scope.open)) {
                                    scope.open = function ($event, opened) {
                                        $event.preventDefault();
                                        $event.stopPropagation();
                                        if (typeof(scope.datepicker) === 'undefined') {
                                            scope.datepicker = {};
                                        }
                                        scope.datepicker[opened] = true;
                                    };
                                }

                                // Construction du template
                                if (angular.isNullOrUndefined(scope.template)) {
                                    scope.template = '' +
                                        '<!-- Barre des événements disponible pour ajout -->' +
                                        '<div class="barre-evenements-planning form-inline" ng-show="opTypePlanning === \'contact\'">' +
                                            '<div class="form-group">' +
                                                '<div class="input-group" col-md-2>' +
                                                    '<select class="form-control input-sm" title="Intervenant" id="selectRessource" ng-model="ressourceSelect" ng-options="libRessource for libRessource in listeLibRessourcesAffectees" ng-change="changeRessourceSelect(ressourceSelect)"></select>' +
                                                '</div>' +
                                                '<span>&nbsp;</span>' +
                                                '<span ng-repeat="event in eventDisponibles" data-drag="true" data-jqyoui-options="{revert: \'invalid\'}" jqyoui-draggable="{onStart: \'startDrag\'}" data-event="{{event.data}}" ng-if="event.libelle !== \'\'"><label class="btn btn-xs" ng-class="{\'operis-btn-desactive-planning\': (!event.selectionne && event.lectureSeule), \'btn-info\': event.selectionne, \'btn-primary\':(!event.lectureSeule && !event.selectionne)}" ng-click="clickEvenement(event)" title="{{event.titre}}">{{event.libelle}}</label>&nbsp;</span>' +
                                            '</div>' +
                                        '</div>' +
                                        '<div class="barre-evenements-planning" ng-show="opTypePlanning === \'tache\'">' +
                                            '<span ng-repeat="event in eventDisponibles" data-drag="true" data-jqyoui-options="{revert: \'invalid\'}" jqyoui-draggable="{onStart: \'startDrag\'}" data-event="{{event.data}}" ng-if="event.libelle !== \'\'"><label class="btn btn-xs" ng-class="{\'operis-btn-desactive-planning\': (!event.selectionne && event.lectureSeule), \'btn-info\': event.selectionne, \'btn-danger\':(!event.lectureSeule && !event.selectionne && !event.affectee && !event.planifiee), \'btn-success\':(!event.lectureSeule && !event.selectionne && event.affectee && !event.planifiee), \'btn-warning\':(!event.lectureSeule && !event.selectionne && !event.affectee && event.planifiee), \'btn-primary\':(!event.lectureSeule && !event.selectionne && event.affectee && event.planifiee)}" ng-click="clickEvenement(event)" title="{{event.titre}}">{{event.libelle}}</label>&nbsp;</span>' +
                                        '</div>' +
                                        '<!-- Barre de navigation -->' +
                                        '<div class="barre-navigation-planning" ng-show="modeAffichage.planning">' +
                                            '<form class="form-inline">' +
                                                '<div class="form-group">' +
                                                    '<div class="btn-group" ng-if="((opTypePlanning === \'tache\') || (opTypePlanning === \'contact\'))">' +
                                                        '<label class="btn btn-default" ng-click="afficherIntervenants()" title="Afficher les intervenants"><i class="fa fa-users"></i></label>' +
                                                        '<label class="btn btn-default" ng-click="afficherMateriels()" title="Afficher les matériels" ng-if="opTypePlanning === \'tache\'"><i class="fa fa-wrench"></i></label>' +
                                                    '</div>' +
                                                    '<span>&nbsp;</span>' +
                                                    '<div class="input-group col-md-3 col-sm-3">' +
                                                        '<input type="text" class="form-control op-datepicker-navbar" title="Date cible" id="datepickerDebut" name="datepickerDebut" ng-model="dateDebutPeriode" ' +
                                                        'datepicker-popup="dd/MM/yyyy" is-open="datepicker[\'datepickerDebut\']" datepicker-options="{\'starting-day\':1}" close-text="Fermer" clear-text="Initisaliser" current-text="Aujourd\'hui"/>' +
                                                        '<span class="input-group-btn">' +
                                                            '<button type="button" class="btn btn-default" ng-click="open($event,\'datepickerDebut\')"><i class="glyphicon glyphicon-calendar"></i></button>' +
                                                        '</span>' +
                                                    '</div>' +
                                                    '<span>&nbsp;</span>' +
                                                    '<div class="input-group col-md-3 col-sm-3">' +
                                                        '<select class="form-control" title="Durée d\'affichage" id="selectDuration" ng-model="dureeEnCours" ng-init="dureeEnCours = dureePeriodes[' + scope.idxDureeDefaut + ']">' +
                                                        '<option ng-repeat="duree in dureePeriodes">{{duree}}</option>' +
                                                        '</select>' +
                                                    '</div>' +
                                                    '<span>&nbsp;</span>' +
                                                    '<div class="btn-group">' +
                                                        '<label class="btn btn-default" ng-click="aujourdhui()" title="Aller à la date du jour"><i class="fa fa-clock-o"></i></label>' +
                                                        '<label class="btn btn-default" ng-click="versDateDebutEntete()" title="Aller au début"><i class="fa fa-arrow-circle-o-down"></i></label>' +
                                                        '<label class="btn btn-default" ng-click="precedent()" title="Période précédente"><i class="fa fa-chevron-left"></i></label>' +
                                                        '<label class="btn btn-default" ng-click="suivant()" title="Période suivante"><i class="fa fa-chevron-right"></i></label>' +
                                                    '</div>' +
                                                    '<span>&nbsp;</span>' +
                                                    '<div class="btn-group" ng-if="opTypePlanning === \'recherche\'">'+
                                                        '<label class="btn btn-default" ng-click="affichageTimeline()" title="Affichage échéancier"><i class="fa fa-align-left"></i></label>' +
                                                        '<label class="btn btn-default" ng-click="affichageAgenda()" title="Affichage calendrier"><i class="fa fa-calendar-o"></i></label>' +
                                                        //'<label class="btn btn-default" ng-click="affichageGrille()" title="Affichage grille"><i class="fa fa-list-alt"></i></label>' +
														'<label class="btn btn-default" ng-click="imprimerPlanning()" title="Imprimer le planning"><i class="fa fa-print"></i></label>' + // Mantis Operia 6174 : Impression du planning
                                                    '</div>'+
                                                '</div>' +
                                            '</form>' +
                                        '</div>' +
                                        '<!-- OpPlanning -->' +
                                        '<div id="calendar-container-{{opNomPlanning}}" class="op-planning-calendar-container">' +
                                            '<div class="container-fluid">' +
                                                '<!-- Aucune tâche et planning intervention récurrente -->' +
                                                '<alert type="danger" ng-if="(opTypePlanning === \'recurrence\') && !interventionMaitreValide"><i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;Aucune tâche trouvée pour l\'intervention récurrente.</alert>' +
                                                '<!-- Vue grille -->' +
                                                '<alert type="info" ng-if="(opTypePlanning === \'tache\') && modeAffichage.grille && (dataGrillePlanning.length === 0)"><i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;Aucune tâche trouvée.</alert>' +
                                                '<alert type="info" ng-if="(opTypePlanning !== \'tache\') && (opTypePlanning !== \'recurrence\') && modeAffichage.grille && (dataGrillePlanning.length === 0)"><i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;Aucun événement trouvé.</alert>' +
                                                '<div id="grillePlanning" ui-grid="gridOptions" ng-style="{height: (gridOptions.data.length*30)+65+\'px\'} " ng-cloak ng-if="modeAffichage.grille && (gridOptions.data.length > 0)" ui-grid-auto-resize ui-grid-resize-columns ui-grid-selection></div>' +
                                            '</div>' +                                            
											'<!-- Vue planning -->' +
                                            //'<div class="calendar tooltip-calendar" ui-calendar="uiConfig.calendar" ng-model="eventSources" calendar="{{opNomPlanning}}" ng-if="modeAffichage.planning"></div>' +
                                            '<div class="calendar" ui-calendar="uiConfig.calendar" ng-model="eventSources" calendar="{{opNomPlanning}}" ng-if="modeAffichage.planning"></div>' +
                                        '</div>';

                                    var el = $compile(scope.template)(scope);
                                    element.replaceWith(el);
                                }

                                deferred.resolve();

                                return deferred.promise;
                            };

                            /**
                             * Initialiser tableau de données pour l'affichage grille
                             * @param donnees {Object} Tableau des événements en entrée
                             */
                            var construireDonneesGrillePlanning = function (donnees) {
                                var deferred = $q.defer();

                                //TODO : Gérer événements récurrents ?
                                scope.dataGrillePlanning = [];

                                for (var i = 0; i < donnees.length; i++) {
                                    if (donnees[i].visible && !donnees[i].ligneVide && (donnees[i].libRessource !== "")) {
                                        var bufLigne = {};

                                        bufLigne.idGroupe = donnees[i].idGroupe;
                                        bufLigne.refLigne = donnees[i].refLigne;
                                        bufLigne.libLigne = donnees[i].libLigne;
                                        bufLigne.descLigne = donnees[i].descLigne;
                                        bufLigne.debut = moment(donnees[i].debut).isValid() ? moment(donnees[i].debut).format("DD/MM/YYYY HH:mm") : "";
                                        bufLigne.fin = moment(donnees[i].fin).isValid() ? moment(donnees[i].fin).format("DD/MM/YYYY HH:mm") : "";
                                        //bufLigne.debutReel = donnees[i].debutReel;
                                        //bufLigne.finReelle = donnees[i].finReelle;
                                        //bufLigne.heureDebut = donnees[i].heureDebut;
                                        //bufLigne.heureFin = donnees[i].heureFin;
                                        //bufLigne.dureeJours = donnees[i].dureeJours;
                                        //bufLigne.dureeHeures = donnees[i].dureeHeures;
                                        //bufLigne.avancement = donnees[i].avancement;
                                        bufLigne.libRessource = donnees[i].libRessource;
                                        //bufLigne.qteRessource = donnees[i].qteRessource;
                                        bufLigne.typeRessource = donnees[i].typeRessource;

                                        scope.dataGrillePlanning.push(bufLigne);
                                    }
                                }

                                var displayNameLibLigne = "Evénement";
                                if ((scope.opTypePlanning === "tache") || (scope.opTypePlanning === "recurrence")) {
                                    displayNameLibLigne = "Tâche";
                                }

                                scope.gridOptions = {
                                    enableRowHeaderSelection: false,
                                    enableFullRowSelection: false, // Sélection des lignes via la barre des événements (et non pas par un click sur une ligne de la grille)
                                    enableSelectAll: true,
                                    multiSelect: true,
                                    columnDefs: [
                                        {name: 'idGroupe', displayName: 'Id', field: 'idGroupe', visible: false},
                                        {name: 'refLigne', displayName: 'Référence', field: 'refLigne', visible: true},
                                        {name: 'libLigne', displayName: displayNameLibLigne, field: 'libLigne', visible: true},
                                        {name: 'descLigne', displayName: 'Description', field: 'descLigne', visible: true},
                                        {name: 'debut', displayName: 'Début', field: 'debut', visible: true},
                                        {name: 'fin', displayName: 'Fin', field: 'fin', visible: true},
                                        {name: 'libRessource', displayName: 'Ressource', field: 'libRessource', visible: true},
                                        {name: 'typeRessource', displayName: 'Type ressource', field: 'typeRessource', visible: true}
                                    ],
                                    onRegisterApi: function(gridApi) {
                                        scope.gridApi = gridApi;
                                    },
                                    data: scope.dataGrillePlanning
                                };

                                deferred.resolve(scope.dataGrillePlanning);

                                return deferred.promise;
                            };

                            /**
                             * Ajouter une sources d'événements en lecture seule au planning
                             * @param donnees {Object} Tableau des événements en entrée
                             */
                            var ajoutEventSourceLectureSeule = function (donnees) {
                                var deferred = $q.defer();

                                var bufEvent = [];
                                var idEvent, resourceId, libEvent, libEventAgenda;

                                // TODO : Gérer affichage lignes "maîtres" ?

                                for (var i = 0; i < donnees.length; i++) {
                                    var dateDebutValide = (!angular.isNullOrUndefined(donnees[i].debut) && moment(donnees[i].debut).isValid());
                                    if (!angular.isNullOrUndefined(donnees[i].idRessource) &&
                                        donnees[i].visible && !donnees[i].ligneVide && dateDebutValide) {

                                        // Id de l'événement
                                        idEvent = donnees[i].typeRessource + "-" + donnees[i].idRessource.toString() + "-" + donnees[i].idGroupe.toString();

                                        if (scope.listeIdLectureSeule.indexOf(idEvent) === -1) {
                                            // Lien avec tableau de resources
                                            resourceId = donnees[i].typeRessource + "-" + donnees[i].idRessource.toString();

                                            // Libellé pour l'affichage timeline
                                            libEvent = donnees[i].libLigne;

                                            // Libellé pour l'affichage agenda
                                            libEventAgenda = libEvent + " " + donnees[i].libRessource;

                                            bufEvent.push({
                                                id: idEvent,
                                                resourceId: resourceId, // Lien avec tableau de resources
                                                start: moment(donnees[i].debut),
                                                debut: donnees[i].debut,
                                                title: libEvent,
                                                titleDefault: libEvent,
                                                titleAgenda: libEventAgenda,
                                                refEntete: donnees[i].refEntete,
                                                adresseEntete: donnees[i].adresseEntete,
                                                refInterneLigne: donnees[i].refInterneLigne,
                                                refLigne: donnees[i].refLigne,
                                                libLigne: donnees[i].libLigne,
                                                descLigne: donnees[i].descLigne,
                                                maitre: false, // Evénement pas sur une ligne de ressource "maître"
                                                recurrente: false,
                                                idEntete: donnees[i].idEntete,
                                                idGroupe: donnees[i].idGroupe, // Id pour regroupement des éléments
                                                lectureSeule: true
                                            });

                                            var idx = bufEvent.length - 1;

                                            // Date de fin si renseignée
                                            if (!angular.isNullOrUndefined(donnees[i].fin)) {
                                                // Rappel : event.end est le moment juste après l'événement se termine. Si la dernière journée entière d'un événement est jeudi alors le fin exclusive de l'événement sera vendredi 00:00:00 !
                                                bufEvent[idx].end = moment(donnees[i].fin).add(1, 'days');
                                                bufEvent[idx].fin = donnees[i].fin;
                                            }

                                            // Réprésentation
                                            bufEvent[idx].backgroundColor = "#bbbb77";
                                            bufEvent[idx].borderColor = "#bbbb77";

                                            // Inscrire l'événement dans la liste des événements déjà ajoutés en lecture seule
                                            scope.listeIdLectureSeule.push(idEvent);
                                        }
                                    }
                                }

                                if (bufEvent.length > 0) {
                                    var eventSource = {
                                        events: bufEvent,
                                        allDayDefault: true,  // pas d'affichage des heures par défaut
                                        editable: false, // lecture seule
                                        estLectureSeule: true
                                    };

                                    //uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('addEventSource', eventSource);
                                    deferred.resolve(eventSource);
                                } else {
                                    deferred.reject(); // Pas d'événement à ajouter
                                }

                                return deferred.promise;
                            };

                            /**
                             * Initialiser les évènements du calendrier
                             * @param donnees {Object} Tableau des événements en entrée
                             * @param donneesRecurrence {Object} Tableau des événements récurrents en entrée
                             */
                            var initEventSources = function (donnees, donneesRecurrence) {
                                var deferred = $q.defer();

                                var bufEvent = [];
                                var idEvent, resourceId, libEvent, libEventAgenda;
                                var deb, fin, tmpFin, idxEvt, dr, idEntetePrec, refEntetePrec, statutEntetePrec, adresseEntetePrec;
                                var bufSourceEvent = [];
                                var listeIdSelect = null;

                                // Evénements principaux
                                if (!angular.isNullOrUndefined(donnees)) {
                                    bufSourceEvent.push({data: donnees, maitre: false, recurrente: false});
                                }

                                // Evénements récrrents
                                if (!angular.isNullOrUndefined(donneesRecurrence)) {
                                    bufSourceEvent.push({data: donneesRecurrence, maitre: false, recurrente: true});
                                }

                                // Evénements "maîtres"
                                if (scope.opTypePlanning === "recherche") {
                                    if (scope.opNomModule === "Intervention") {
                                        if (!angular.isNullOrUndefined(donnees) && (donnees.length > 0)) {
                                            dr = [];
                                            for (var idxEntete = 0; idxEntete < donnees.length; idxEntete++) {
                                                if ((donnees[idxEntete].typeRessource !== "Matériau") &&
                                                    !angular.isNullOrUndefined(donnees[idxEntete].idRessource) &&
                                                    donnees[idxEntete].visible && !donnees[idxEntete].ligneVide) {
                                                    dr.push(donnees[idxEntete]);
                                                }
                                            }
                                            dr.sort(dynamicSort("idEntete"));
                                            idEntetePrec = 0;
                                            refEntetePrec = "";
                                            for (idxEntete = 0; idxEntete < dr.length; idxEntete++) {
                                                if (idxEntete === 0) {
                                                    // Identifiant de l'intervention
                                                    idEntetePrec = dr[idxEntete].idEntete;
                                                    statutEntetePrec = dr[idxEntete].statutEntete;
                                                    refEntetePrec = dr[idxEntete].refEntete;
                                                    adresseEntetePrec = dr[idxEntete].adresseEntete;

                                                    // Init début et fin d'intervention
                                                    deb = moment(dr[idxEntete].debut);
                                                    fin = (angular.isNullOrUndefined(dr[idxEntete].fin)) ? moment(dr[idxEntete].debut) : moment(dr[idxEntete].fin);
                                                    if (angular.isNullOrUndefined(dr[idxEntete].fin)) {
                                                        fin.add(1, 'days'); // Si date de fin non renseignée alors fin = début + 1 jour
                                                    }
                                                } else if (dr[idxEntete].idEntete !== idEntetePrec) {
                                                    // Ajout source d'événement suite changement d'intervention
                                                    bufSourceEvent.push({
                                                        data: [{
                                                            idEntete: idEntetePrec,
                                                            statutEntete: statutEntetePrec,
                                                            refEntete: refEntetePrec,
                                                            adresseEntete: adresseEntetePrec,
                                                            typeRessource: "Intervention",
                                                            idRessource: idEntetePrec,
                                                            libRessource: refEntetePrec,
                                                            idLigne: null,
                                                            statutLigne: null,
                                                            idGroupe: idEntetePrec,
                                                            refLigne: refEntetePrec,
                                                            libLigne: refEntetePrec,
                                                            descLigne: null,
                                                            debut: deb,
                                                            fin: fin,
                                                            visible: true,
                                                            ligneVide: false,
                                                            selectionne: false,
                                                            estAjout: false,
                                                            modifie: false
                                                        }],
                                                        maitre: true,
                                                        recurrente: true
                                                    });

                                                    // Réinit début et fin d'intervention
                                                    deb = moment(dr[idxEntete].debut);
                                                    fin = (angular.isNullOrUndefined(dr[idxEntete].fin)) ? moment(dr[idxEntete].debut) : moment(dr[idxEntete].fin);
                                                    if (angular.isNullOrUndefined(dr[idxEntete].fin)) {
                                                        fin.add(1, 'days'); // Si date de fin non renseignée alors fin = début + 1 jour
                                                    }
                                                }

                                                // Tester date de début/fin de chaque ligne
                                                if (moment(dr[idxEntete].debut).isBefore(deb)) {
                                                    deb = moment(dr[idxEntete].debut);
                                                }

                                                if (angular.isNullOrUndefined(dr[idxEntete].fin)) {
                                                    tmpFin = moment(dr[idxEntete].debut);
                                                    tmpFin.add(1, 'days'); // Date de fin non renseingée --> tester par rapport au début + 1 jour
                                                    if (tmpFin.isAfter(fin)) {
                                                        fin = tmpFin;
                                                    }
                                                } else {
                                                    if (moment(dr[idxEntete].fin).isAfter(fin)) {
                                                        fin = moment(dr[idxEntete].fin);
                                                    }
                                                }

                                                // Mémorisez idEntete et refEntete de la ligne traitée
                                                idEntetePrec = dr[idxEntete].idEntete;
                                                statutEntetePrec = dr[idxEntete].statutEntete;
                                                refEntetePrec = dr[idxEntete].refEntete;
                                                adresseEntetePrec = dr[idxEntete].adresseEntete;
                                            }

                                            // Ajout source d'événement de la dernière occurence
                                            bufSourceEvent.push({
                                                data: [{
                                                    idEntete: idEntetePrec,
                                                    statutEntete: statutEntetePrec,
                                                    refEntete: refEntetePrec,
                                                    adresseEntete: adresseEntetePrec,
                                                    typeRessource: "Intervention",
                                                    idRessource: idEntetePrec,
                                                    libRessource: refEntetePrec,
                                                    idLigne: null,
                                                    statutLigne: null,
                                                    idGroupe: idEntetePrec,
                                                    refLigne: refEntetePrec,
                                                    libLigne: refEntetePrec,
                                                    descLigne: null,
                                                    debut: deb,
                                                    fin: fin,
                                                    visible: true,
                                                    ligneVide: false,
                                                    selectionne: false,
                                                    estAjout: false,
                                                    modifie: false
                                                }],
                                                maitre: true,
                                                recurrente: true
                                            });
                                        }
                                    }
                                } else if (scope.opTypePlanning === "recurrence") {
                                    if (scope.opActionCode === "GERERRECURRENCE") {
                                        if (!angular.isNullOrUndefined(donnees) && (donnees.length > 0)) {
                                            // Intervention de référence
                                            // Se positionner sur la première ligne valide
                                            var trouve = false;
                                            for (var idxPremierEvt = 0; idxPremierEvt < donnees.length; idxPremierEvt++) {
                                                var debValide = (!angular.isNullOrUndefined(donnees[idxPremierEvt].debut) && moment(donnees[idxPremierEvt].debut).isValid());
                                                if ((donnees[idxPremierEvt].typeRessource !== "Matériau") && !angular.isNullOrUndefined(donnees[idxPremierEvt].idRessource) &&
                                                    donnees[idxPremierEvt].visible && !donnees[idxPremierEvt].ligneVide
                                                    && debValide) {
                                                    trouve = true;
                                                    break;
                                                }
                                            }

                                            // Maj statut des actions
                                            var libRessourceSelect = getLibRessourceSelect();
                                            var statut = {
                                                nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                                nbreSelect: scope.nbreEventSelectionnes,
                                                planningModifie: scope.planningModifie,
                                                lectureSeule: (scope.planningLectureSeule || !trouve),
                                                libRessourceSelect: libRessourceSelect
                                            };
                                            scope.updateActions()(statut);

                                            // Mantis 6158 : Avertissement si planning récurrence et l'intervention maître n'a pas de tâche
                                            scope.interventionMaitreValide = false;

                                            if (trouve) {
                                                // Début intervention
                                                deb = moment(donnees[idxPremierEvt].debut);
                                                for (idxEvt = idxPremierEvt; idxEvt < donnees.length; idxEvt++) {
                                                    if (moment(donnees[idxEvt].debut).isBefore(deb)) {
                                                        deb = moment(donnees[idxEvt].debut);
                                                    }
                                                }

                                                // Fin intervention
                                                fin = (angular.isNullOrUndefined(donnees[idxPremierEvt].fin)) ? moment(donnees[idxPremierEvt].debut) : moment(donnees[idxPremierEvt].fin);
                                                if (angular.isNullOrUndefined(donnees[idxPremierEvt].fin)) {
                                                    fin.add(1, 'days'); // Si date de fin non renseignée alors fin = début + 1 jour
                                                }

                                                for (idxEvt = idxPremierEvt + 1; idxEvt < donnees.length; idxEvt++) {
                                                    if (donnees[idxEvt].typeRessource !== "Matériau") {
                                                        if (angular.isNullOrUndefined(donnees[idxEvt].fin)) {
                                                            tmpFin = moment(donnees[idxEvt].debut);
                                                            tmpFin.add(1, 'days'); // Date de fin non renseingée --> tester par rapport au début + 1 jour
                                                            if (tmpFin.isAfter(fin)) {
                                                                fin = tmpFin;
                                                            }
                                                        } else {
                                                            if (moment(donnees[idxEvt].fin).isAfter(fin)) {
                                                                fin = moment(donnees[idxEvt].fin);
                                                            }
                                                        }
                                                    }
                                                }

                                                // Mantis 6158 : L'intervention maître a au moins une tâche
                                                scope.interventionMaitreValide = true;

                                                // Ajout source d'événement
                                                bufSourceEvent.push({
                                                    data: [{
                                                        idEntete: scope.idEntete,
                                                        statutEntete: donnees[idxPremierEvt].statutEntete,
                                                        refEntete: scope.refEntete,
                                                        adresseEntete: scope.adresseEntete,
                                                        typeRessource: "Intervention",
                                                        idRessource: scope.idEntete,
                                                        libRessource: scope.refEntete,
                                                        idLigne: null,
                                                        statutLigne: null,
                                                        idGroupe: scope.idEntete,
                                                        refLigne: scope.refEntete,
                                                        libLigne: scope.refEntete,
                                                        descLigne: null,
                                                        debut: deb,
                                                        fin: fin,
                                                        visible: true,
                                                        ligneVide: false,
                                                        selectionne: false,
                                                        estAjout: false,
                                                        modifie: false
                                                    }],
                                                    maitre: true,
                                                    recurrente: false
                                                });
                                            }
                                        }

                                        // Interventions récurrentes
                                        if (!angular.isNullOrUndefined(donneesRecurrence) && (donneesRecurrence.length > 0)) {
                                            dr = [];
                                            for (var idxRecurrence = 0; idxRecurrence < donneesRecurrence.length; idxRecurrence++) {
                                                if ((donneesRecurrence[idxRecurrence].typeRessource !== "Matériau") &&
                                                    !angular.isNullOrUndefined(donneesRecurrence[idxRecurrence].idRessource) &&
                                                    donneesRecurrence[idxRecurrence].visible && !donneesRecurrence[idxRecurrence].ligneVide) {
                                                    dr.push(donneesRecurrence[idxRecurrence]);
                                                }
                                            }
                                            dr.sort(dynamicSort("idEntete"));
                                            idEntetePrec = 0;
                                            refEntetePrec = "";
                                            for (idxRecurrence = 0; idxRecurrence < dr.length; idxRecurrence++) {
                                                if (idxRecurrence === 0) {
                                                    // Identifiant de l'intervention
                                                    idEntetePrec = dr[idxRecurrence].idEntete;
                                                    statutEntetePrec = dr[idxRecurrence].statutEntete;
                                                    refEntetePrec = dr[idxRecurrence].refEntete;
                                                    adresseEntetePrec = dr[idxRecurrence].adresseEntete;

                                                    // Init début et fin d'intervention
                                                    deb = moment(dr[idxRecurrence].debut);
                                                    fin = (angular.isNullOrUndefined(dr[idxRecurrence].fin)) ? moment(dr[idxRecurrence].debut) : moment(dr[idxRecurrence].fin);
                                                    if (angular.isNullOrUndefined(dr[idxRecurrence].fin)) {
                                                        fin.add(1, 'days'); // Si date de fin non renseignée alors fin = début + 1 jour
                                                    }
                                                } else if (dr[idxRecurrence].idEntete !== idEntetePrec) {
                                                    // Ajout source d'événement suite changement d'intervention
                                                    bufSourceEvent.push({
                                                        data: [{
                                                            idEntete: idEntetePrec,
                                                            statutEntete: statutEntetePrec,
                                                            refEntete: refEntetePrec,
                                                            adresseEntete: adresseEntetePrec,
                                                            typeRessource: "Intervention",
                                                            idRessource: scope.idEntete,
                                                            libRessource: scope.refEntete,
                                                            idLigne: null,
                                                            statutLigne: null,
                                                            idGroupe: idEntetePrec,
                                                            refLigne: refEntetePrec,
                                                            libLigne: refEntetePrec,
                                                            descLigne: null,
                                                            debut: deb,
                                                            fin: fin,
                                                            visible: true,
                                                            ligneVide: false,
                                                            selectionne: false,
                                                            estAjout: false,
                                                            modifie: false
                                                        }],
                                                        maitre: true,
                                                        recurrente: true
                                                    });

                                                    // Réinit début et fin d'intervention
                                                    deb = moment(dr[idxRecurrence].debut);
                                                    fin = (angular.isNullOrUndefined(dr[idxRecurrence].fin)) ? moment(dr[idxRecurrence].debut) : moment(dr[idxRecurrence].fin);
                                                    if (angular.isNullOrUndefined(dr[idxRecurrence].fin)) {
                                                        fin.add(1, 'days'); // Si date de fin non renseignée alors fin = début + 1 jour
                                                    }
                                                }

                                                // Tester date de début/fin de chaque ligne
                                                if (moment(dr[idxRecurrence].debut).isBefore(deb)) {
                                                    deb = moment(dr[idxRecurrence].debut);
                                                }

                                                if (angular.isNullOrUndefined(dr[idxRecurrence].fin)) {
                                                    tmpFin = moment(dr[idxRecurrence].debut);
                                                    tmpFin.add(1, 'days'); // Date de fin non renseingée --> tester par rapport au début + 1 jour
                                                    if  (tmpFin.isAfter(fin)) {
                                                        fin = tmpFin;
                                                    }
                                                } else {
                                                    if  (moment(dr[idxRecurrence].fin).isAfter(fin)) {
                                                        fin = moment(dr[idxRecurrence].fin);
                                                    }
                                                }

                                                // Mémorisez idEntete et refEntete de la ligne traitée
                                                idEntetePrec = dr[idxRecurrence].idEntete;
                                                statutEntetePrec = dr[idxRecurrence].statutEntete;
                                                refEntetePrec = dr[idxRecurrence].refEntete;
                                                adresseEntetePrec = dr[idxRecurrence].adresseEntete;
                                            }

                                            // Ajout source d'événement de la dernière occurence
                                            bufSourceEvent.push({
                                                data: [{
                                                    idEntete: idEntetePrec,
                                                    statutEntete: statutEntetePrec,
                                                    refEntete: refEntetePrec,
                                                    adresseEntete: adresseEntetePrec,
                                                    typeRessource: "Intervention",
                                                    idRessource: scope.idEntete,
                                                    libRessource: scope.refEntete,
                                                    idLigne: null,
                                                    statutLigne: null,
                                                    idGroupe: idEntetePrec,
                                                    refLigne: refEntetePrec,
                                                    libLigne: refEntetePrec,
                                                    descLigne: null,
                                                    debut: deb,
                                                    fin: fin,
                                                    visible: true,
                                                    ligneVide: false,
                                                    selectionne: false,
                                                    estAjout: false,
                                                    modifie: false
                                                }],
                                                maitre: true,
                                                recurrente: true
                                            });

                                        }
                                    }
                                }

                                scope.nbreLignes = 0;

                                // Identifiant de la ressource sélectionnée
                                var idRessourceSelect = getIdRessourceSelect();

                                for (var s = 0; s < bufSourceEvent.length; s++) {
                                    var d = bufSourceEvent[s].data;
                                    for (var i = 0; i < d.length; i++) {
                                        var dateDebutValide = (!angular.isNullOrUndefined(d[i].debut) && moment(d[i].debut).isValid());
                                        if ((d[i].typeRessource !== "Matériau") && !angular.isNullOrUndefined(d[i].idRessource) &&
                                            d[i].visible && !d[i].ligneVide && dateDebutValide) {
                                            scope.nbreLignes = scope.nbreLignes + 1;

                                            if (angular.isNullOrUndefined(d[i].idLigne)) {
                                                idEvent = d[i].typeRessource + "-" + d[i].idRessource.toString() + "-9999-" + d[i].idGroupe.toString() + "-" + scope.nbreLignes.toString();
                                            } else {
                                                idEvent = d[i].typeRessource + "-" + d[i].idRessource.toString() + "-" + d[i].idLigne.toString() + "-" + d[i].idGroupe.toString() + "-" + scope.nbreLignes.toString();
                                            }

                                            // Lien avec tableau de resources
                                            resourceId = d[i].typeRessource + "-" + d[i].idRessource.toString();

                                            // Libellé pour l'affichage timeline
                                            libEvent = d[i].libLigne;
                                            if (!bufSourceEvent[s].maitre && (scope.opTypePlanning !== "contact")) {
                                                for (var j = 0; j < scope.eventDisponibles.length; j++) {
                                                    if (scope.eventDisponibles[j].idGroupe === d[i].idGroupe) {
                                                        libEvent = scope.eventDisponibles[j].libelle;
                                                    }
                                                }
                                            }

                                            // Gérer numéro d'occurance dans les libellés des événements récurrents
                                            if (!bufSourceEvent[s].maitre && (bufSourceEvent[s].recurrente) && (d[i].idEntete < 0)) {
                                                var num = 0 - d[i].idEntete;
                                                libEvent += " (" + num.toString() + ")";
                                            }

                                            // Libellé pour l'affichage agenda
                                            libEventAgenda = libEvent;
                                            if (!bufSourceEvent[s].maitre) {
                                                libEventAgenda += " " + d[i].libRessource;
                                            }

                                            bufEvent.push({
                                                id: idEvent,
                                                resourceId: resourceId, // Lien avec tableau de resources
                                                start: moment(d[i].debut),
                                                debut: d[i].debut,
                                                title: libEvent,
                                                titleDefault: libEvent,
                                                titleAgenda: libEventAgenda,
                                                refEntete: d[i].refEntete,
                                                adresseEntete: d[i].adresseEntete,
                                                refInterneLigne: d[i].refInterneLigne,
                                                refLigne: d[i].refLigne,
                                                libLigne: d[i].libLigne,
                                                descLigne: d[i].descLigne,
                                                maitre: bufSourceEvent[s].maitre, // Evénement sur ligne de ressource "maître"
                                                recurrente: bufSourceEvent[s].recurrente,
                                                idEntete: d[i].idEntete,
                                                idGroupe: d[i].idGroupe, // Id pour regroupement des éléments
                                                nbreLigne: scope.nbreLignes, // Index ligne
                                                selectionne: d[i].selectionne,
                                                statutEntete: d[i].statutEntete,
                                                statutLigne: d[i].statutLigne
                                            });

                                            var idx = bufEvent.length - 1;

                                            // Gestion particulier en mode recherche
                                            var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                            if (scope.opTypePlanning === "recherche") {
                                                // Pas d'édition en mode recherche
                                                bufEvent[idx].editable = false;
                                                bufEvent[idx].lectureSeule = true;

                                                // Gérer la sélection en cours
                                                if (angular.isNullOrUndefined(listeIdSelect)) {
                                                    listeIdSelect = [];
                                                    if ((scope.opNomModule === "Contact") || (scope.opNomModule === "Intervention")) {
                                                        for (var k = 0; k < scope.opSelection.length; k++) {
                                                            if (!angular.isNullOrUndefined(scope.opSelection[k][paramPlanning.colonnesEntete.idEntete].valeur) &&
                                                                listeIdSelect.indexOf(scope.opSelection[k][paramPlanning.colonnesEntete.idEntete].valeur) === -1) {
                                                                listeIdSelect.push(scope.opSelection[k][paramPlanning.colonnesEntete.idEntete].valeur);
                                                            }
                                                        }
                                                    }
                                                }

                                                bufEvent[idx].selectionne = (listeIdSelect.indexOf(bufEvent[idx].idEntete) !== -1);
                                            }

                                            // Statut de l'événement
                                            if (bufEvent[idx].maitre) {
                                                if (!angular.isNullOrUndefined(bufEvent[idx].statutEntete)) {
                                                    if (paramPlanning.statutsLecutreSeule.indexOf(bufEvent[idx].statutEntete) !== -1) {
                                                        bufEvent[idx].editable = false;
                                                        bufEvent[idx].lectureSeule = true;
                                                    }
                                                }
                                            } else {
                                                var autoriseEdition = true;

                                                if (!angular.isNullOrUndefined(bufEvent[idx].statutEntete)) {
                                                    if (paramPlanning.statutsLecutreSeule.indexOf(bufEvent[idx].statutEntete) !== -1) {
                                                        autoriseEdition = false;
                                                    }
                                                }

                                                if (autoriseEdition && !angular.isNullOrUndefined(bufEvent[idx].statutLigne)) {
                                                    if (paramPlanning.statutsLecutreSeule.indexOf(bufEvent[idx].statutLigne) !== -1) {
                                                        autoriseEdition = false;
                                                    }
                                                }

                                                if (!autoriseEdition) {
                                                    // TODO : Autoriser édition si planning occupation et pas la ressource en cours de sélection ?
                                                    bufEvent[idx].editable = false;
                                                    bufEvent[idx].lectureSeule = true;
                                                }
                                            }

                                            // Date de fin si renseignée
                                            if (!angular.isNullOrUndefined(d[i].fin)) {
                                                // Rappel : event.end est le moment juste après l'événement se termine. Si la dernière journée entière d'un événement est jeudi alors le fin exclusive de l'événement sera vendredi 00:00:00 !
                                                bufEvent[idx].end = moment(d[i].fin).add(1, 'days');
                                                bufEvent[idx].fin = d[i].fin;
                                            }

                                            // Réprésentation
                                            if (bufEvent[idx].selectionne) { //if (d[i].selectionne) {
                                                // Evénement sélectionné (botstrap css btn-info)
                                                bufEvent[idx].backgroundColor = "#5bc0de";
                                                bufEvent[idx].borderColor = "#46b8da";
                                            } else {
                                                if (bufEvent[idx].maitre) {
                                                    if (!angular.isNullOrUndefined(bufEvent[idx].statutEntete) &&
                                                        (paramPlanning.statutsLecutreSeule.indexOf(bufEvent[idx].statutEntete) !== -1)) {
                                                        bufEvent[idx].backgroundColor = "#527a7a";
                                                        bufEvent[idx].borderColor = "#527a7a";
                                                    } else {
                                                        bufEvent[idx].backgroundColor = "#339966";
                                                        bufEvent[idx].borderColor = "#339966";
                                                    }
                                                } else {
                                                    if (!angular.isNullOrUndefined(bufEvent[idx].statutEntete) &&
                                                        (paramPlanning.statutsLecutreSeule.indexOf(bufEvent[idx].statutEntete) !== -1)) {
                                                        bufEvent[idx].backgroundColor = "#75a3a3";
                                                        bufEvent[idx].borderColor = "#75a3a3";
                                                    } else if (!angular.isNullOrUndefined(bufEvent[idx].statutLigne) &&
                                                        (paramPlanning.statutsLecutreSeule.indexOf(bufEvent[idx].statutLigne) !== -1)) {
                                                        bufEvent[idx].backgroundColor = "#75a3a3";
                                                        bufEvent[idx].borderColor = "#75a3a3";
                                                    } else if (scope.opTypePlanning === "contact") {
                                                        // Gestion affichage en cas de planning d'occupation
                                                        if ((idRessourceSelect !== -1) && (d[i].idRessource !== idRessourceSelect)) {
                                                            bufEvent[idx].backgroundColor = "#75a3a3";
                                                            bufEvent[idx].borderColor = "#75a3a3";
                                                        } else {
                                                            // Bootstrap css btn-primary
                                                            bufEvent[idx].backgroundColor = "#337ab7";
                                                            bufEvent[idx].borderColor = "#2e6da4";
                                                        }
                                                    } else {
                                                        // Bootstrap css btn-primary
                                                        bufEvent[idx].backgroundColor = "#337ab7";
                                                        bufEvent[idx].borderColor = "#2e6da4";
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                // Conserver les événements en lecture seule
                                var eventsLectureSeule = [];
                                if (scope.opTypePlanning !== "recherche") {
                                    for (i = scope.eventSources.length - 1; i >= 0; i--) {
                                        if (!angular.isNullOrUndefined(scope.eventSources[i].editable) && !scope.eventSources[i].editable) {
                                            eventsLectureSeule.push(scope.eventSources[i]);
                                        }
                                    }
                                } else if ((scope.opTypePlanning === "recherche") && ((scope.opNomModule === "Contact") || (scope.opNomModule === "Intervention"))) {
                                    for (i = scope.eventSources.length - 1; i >= 0; i--) {
                                        if (!angular.isNullOrUndefined(scope.eventSources[i].estLectureSeule) && scope.eventSources[i].estLectureSeule) {
                                            eventsLectureSeule.push(scope.eventSources[i]);
                                        }
                                    }
                                }

                                // Retirer les événements avant de les récréer
                                for (i = scope.eventSources.length - 1; i >= 0; i--) {
                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('removeEventSource', scope.eventSources[i]);
                                    scope.eventSources.pop();
                                }

                                // Evénements en lecture/écriture
                                scope.eventSources.push({
                                    events: bufEvent,
                                    allDayDefault: true, // pas d'affichage des heures par défaut
                                    editable: (scope.opTypePlanning === "recherche") ? false : true // lecture/écriture
                                });

                                // Evénements en lecture seule
                                if ((scope.opTypePlanning !== "recherche") ||
                                    ((scope.opTypePlanning === "recherche") && ((scope.opNomModule === "Contact") || (scope.opNomModule === "Intervention")))) {
                                    for (i = 0; i < eventsLectureSeule.length; i++) {
                                        scope.eventSources.push(eventsLectureSeule[i]);
                                    }
                                }

                                // Jours fériés
                                representationService.getJoursFeries().then(function (data) {
                                    scope.eventSources.push(data);
                                    deferred.resolve(scope.eventSources);
                                }, function (erreur) {
                                    console.log("Jours fériés non chargés.");
                                    deferred.reject(erreur);
                                });

                                return deferred.promise;
                            };

                            /**
                             * Construire tableaux des ressources et événements pour alimenter le calendrier
                             * @param donnees {Object} Tableau des événements en entrée
                             * @param donneesRecurrence {Object} Tableau des événements récurrents en entrée
                             */
                            var construireEventSources = function (donnees, donneesRecurrence) {
                                var deferred = $q.defer();

                                // Ressources
                                var bufRes = [];

                                // Ressources "mâtires"
                                if (scope.opTypePlanning === "recurrence") {
                                    if (scope.opActionCode === "GERERRECURRENCE") {
                                        // Interventions
                                        bufRes.push({
                                            id: "Intervention-" + scope.idEntete.toString(),
                                            f1: getLibSelection(),
                                            typeRes: "Intervention", //parentId: "Intervention",
                                            idRes: scope.idEntete,
                                            libRes: scope.refEntete,
                                            libTri: "A" + scope.refEntete
                                        });
                                    }
                                } else if (scope.opTypePlanning === "recherche") {
                                    var resTraitee = [];
                                    if (scope.opNomModule === "Intervention") {
                                        for (var r = 0; r < donnees.length; r++) {
                                            if (resTraitee.indexOf(donnees[r].idEntete) === -1) {
                                                // Interventions
                                                bufRes.push({
                                                    id: "Intervention-" + donnees[r].idEntete.toString(),
                                                    f1: donnees[r].refEntete,
                                                    typeRes: "Intervention", //parentId: "Intervention",
                                                    idRes: donnees[r].idEntete,
                                                    libRes: donnees[r].refEntete,
                                                    libTri: "A" + donnees[r].refEntete
                                                });

                                                resTraitee.push(donnees[r].idEntete);
                                            }
                                        }
                                    }
                                }

                                // Autres ressources
                                for (var i = 0; i < donnees.length; i++) {
                                    if ((donnees[i].typeRessource !== "Matériau") &&
                                        !angular.isNullOrUndefined(donnees[i].idRessource) &&
                                        donnees[i].visible) {
                                        var idRes = donnees[i].typeRessource + "-" + donnees[i].idRessource.toString();
                                        bufRes.push({
                                            id: idRes,
                                            f1: donnees[i].libRessource,
                                            typeRes: donnees[i].typeRessource, //parentId: donnees[i].typeRessource,
                                            idRes: donnees[i].idRessource,
                                            libRes: donnees[i].libRessource,
                                            libTri: donnees[i].typeRessource + donnees[i].libRessource
                                        });
                                    }
                                }

                                scope.eventRessources = bufRes.sort(dynamicSort("libTri"));

                                if (scope.opTypePlanning === "contact") {
                                    // Mettre à jour la liste des ressources disponibles pour sélection
                                    scope.listeRessourcesAffectees = getListeRessourcesAffectees(scope.listeRessources);
									
                                    scope.listeLibRessourcesAffectees = [];
                                    for (i = 0; i < scope.listeRessourcesAffectees.length; i++) {
                                        scope.listeLibRessourcesAffectees.push(scope.listeRessourcesAffectees[i].libRessource);
                                    }

                                    var idx = -1;
                                    if (!angular.isNullOrUndefined(scope.libRessourceSelect) && (scope.libRessourceSelect !== "")) {
                                        for (i = 0; i < scope.listeRessourcesAffectees.length; i++) {
                                            if (scope.listeRessourcesAffectees[i].libRessource === scope.libRessourceSelect) {
                                                idx = i;
                                            }
                                        }
                                    }

                                    if (idx !== -1) {
                                        scope.libRessourceSelect = "";
                                    }
                                }

                                // Evénements
                                initEventSources(donnees, donneesRecurrence).then(function () {
                                    // Init compteur idGroupe utilisé lors de l'ajout des éléments
                                    if (scope.idGroupe === 0) {
                                        for (i = 0; i < donnees.length; i++) {
                                            if (donnees[i].idGroupe > scope.idGroupe)
                                                scope.idGroupe = donnees[i].idGroupe + 1;
                                        }

                                        if (!angular.isNullOrUndefined(donneesRecurrence)) {
                                            for (i = 0; i < donneesRecurrence.length; i++) {
                                                if (donneesRecurrence[i].idGroupe > scope.idGroupe)
                                                    scope.idGroupe = donneesRecurrence[i].idGroupe + 1;
                                            }
                                        }
                                    }

                                    // Initialiser le calendrier
                                    initCalendrier().then(function () {
                                        deferred.resolve();
                                    });
                                });

                                return deferred.promise;
                            };

                            /**
                             * Initialiser la liste des événements disponibles
                             * @param donnees {Object} Tableau des événements en entrée
                             */
                            var initListeEvenementDispo = function (donnees) {
                                if ((scope.opTypePlanning === "contact") || (scope.opTypePlanning === "tache")) {
                                    // Liste actuelle des événements disponibles
                                    var eventDispoActuel = scope.eventDisponibles;

                                    // Compteur pour gérer le tri des événements dans la barre des événements
                                    if (angular.isNullOrUndefined(scope.ordreEvent)) {
                                        scope.ordreEvent = 0;
                                    }

                                    // Réinitialiser le tableau des événements disponibles
                                    scope.eventDisponibles = [];

                                    var libRessourceSelect = getLibRessourceSelect();
                                    var d = donnees.sort(dynamicSort("idGroupe")); // Toujours traiter le tableau en entrée dans la même ordre
                                    for (var i = 0; i < d.length; i++) {
                                        var filtreContact = true;
                                        if (scope.opTypePlanning === "contact") {
                                            // Filtrer selon la ressource sélectionnée
                                            filtreContact = (d[i].libRessource === libRessourceSelect);

                                            if (filtreContact) {
                                                // Filtrer selon la date de l'occupation (début >= date du jour)
                                                filtreContact = (!angular.isNullOrUndefined(d[i].debut) && (moment(d[i].debut).isValid()));
                                                if (filtreContact) {
                                                    var aujourdhui = moment().startOf('date');
                                                    filtreContact = ((moment(d[i].debut).isSame(aujourdhui, 'day')) || (moment(d[i].debut).isAfter(aujourdhui, 'day')));
                                                }
                                            }
                                        }

                                        if (!d[i].ligneVide &&
                                            !angular.isNullOrUndefined(d[i].idGroupe) &&
                                            ((scope.opTypePlanning === "tache") || filtreContact)) {
                                            // Calc titre pour bulle d'info
                                            var eventTitle = (!angular.isNullOrUndefined(d[i].refLigne)) ? d[i].refLigne.trim() + " " + d[i].libLigne : d[i].libLigne;
                                            var eventDuration = "";
                                            if (!angular.isNullOrUndefined(d[i].debut) && (moment(d[i].debut).isValid())) {
                                                eventDuration = "Début : " + moment(d[i].debut).format("DD/MM/YYYY HH:mm");
                                            }

                                            if (!angular.isNullOrUndefined(d[i].fin) && (moment(d[i].fin).isValid())) {
                                                if (eventDuration !== "") {
                                                    eventDuration += "\n";
                                                }

                                                eventDuration += "Fin : " + moment(d[i].fin).format("DD/MM/YYYY HH:mm");
                                            }

                                            var titre = eventTitle.trim();

                                            if (eventDuration !== "") {
                                                titre += "\n" + eventDuration;
                                            }

                                            if (!angular.isNullOrUndefined(d[i].adresseEntete) && (d[i].adresseEntete !== "")) {
                                                titre += "\n" + "Adresse : ";

                                                var adr = d[i].adresseEntete.split(/\\n/);
                                                for (var idxAdr = 0; idxAdr < adr.length; idxAdr++) {
                                                    if (adr[idxAdr].trim() !== "") {
                                                        if (idxAdr > 0) {
                                                            titre += "\n";
                                                        }
                                                        titre += adr[idxAdr].trim();
                                                    }
                                                }
                                            }

                                            if (!angular.isNullOrUndefined(d[i].descLigne) && (d[i].descLigne !== "")) {
                                                titre += "\n" + d[i].descLigne;
                                            }

                                            // Evénement déjà présente dans la barre d'événements ?
                                            var idx = -1;
                                            var j;
                                            if (scope.opTypePlanning !== "contact") {
                                                for (j = 0; j < scope.eventDisponibles.length; j++) {
                                                    if (scope.eventDisponibles[j].idGroupe === d[i].idGroupe) {
                                                        idx = j;
                                                        break;
                                                    }
                                                }
                                            }

                                            if (idx === -1) {
                                                // Nombre d'événements avec le même libellé de référence
                                                var nbre = 1;
                                                if (scope.opTypePlanning !== "contact") {
                                                    for (j = 0; j < scope.eventDisponibles.length; j++) {
                                                        if (scope.eventDisponibles[j].libRef === d[i].libLigne) {
                                                            nbre++;
                                                        }
                                                    }
                                                }

                                                // Conserver certains propriétés
                                                //var selectionne = false;
                                                var ordre = "";
                                                for (j = 0; j < eventDispoActuel.length; j++) {
                                                    if (eventDispoActuel[j].idGroupe === d[i].idGroupe) {
                                                        //selectionne = eventDispoActuel[j].selectionne;
                                                        ordre = eventDispoActuel[j].ordre;
                                                        break;
                                                    }
                                                }

                                                // Ordre pas encore renseigné
                                                if (ordre === "") {
                                                    scope.ordreEvent++;
                                                    ordre = scope.ordreEvent.toString();
                                                }

                                                // Statut de l'événement
                                                var ressourceAffectee = false;
                                                var statut = "";

                                                if (scope.opTypePlanning !== "contact") {
                                                    statut = "\nStatut : ";
                                                    if ((d[i].typeRessource !== "Matériau") && !angular.isNullOrUndefined(d[i].idRessource)) {
                                                        statut += "Affectée";
                                                        ressourceAffectee = true;
                                                    } else {
                                                        statut += "Non affectée";
                                                    }

                                                    if (angular.isNullOrUndefined(d[i].debut)) {
                                                        statut += ", non planifiée";
                                                    } else {
                                                        statut += ", planifiée";
                                                    }
                                                }

                                                // Statut de l'événement
                                                var lectureSeule = false;
                                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                if (!angular.isNullOrUndefined(d[i].statutEntete)) {
                                                    if (paramPlanning.statutsLecutreSeule.indexOf(d[i].statutEntete) !== -1) {
                                                        lectureSeule = true;

                                                        if (scope.opTypePlanning !== "contact") {
                                                            statut = "\nStatut : " + d[i].statutEntete;
                                                        }
                                                    }
                                                }

                                                if (!lectureSeule) {
                                                    if (!angular.isNullOrUndefined(d[i].statutLigne)) {
                                                        if (paramPlanning.statutsLecutreSeule.indexOf(d[i].statutLigne) !== -1) {
                                                            lectureSeule = true;

                                                            if (scope.opTypePlanning !== "contact") {
                                                                statut = "\nStatut : " + d[i].statutLigne;
                                                            }
                                                        }
                                                    }
                                                }

                                                // Libellé de l'événement
                                                var libEvt = (nbre > 1) ? d[i].libLigne + " (" + nbre.toString() + ")" : d[i].libLigne;
                                                if ((scope.opTypePlanning === "contact") && (!angular.isNullOrUndefined(d[i].debut) && (moment(d[i].debut).isValid()))) {
                                                    libEvt += " " + moment(d[i].debut).format("DD/MM/YYYY HH:mm").replace(/ 00:00/g, "");
                                                }

                                                scope.eventDisponibles.push({
                                                    idGroupe: d[i].idGroupe,
                                                    refInterne: d[i].refInterneLigne,
                                                    numeroInterne: d[i].numeroInterneLigne,
                                                    dateCreation: d[i].creationLigne,
                                                    libRef: d[i].libLigne,
                                                    libelle: libEvt,
                                                    description: d[i].descLigne,
                                                    lectureSeule: lectureSeule,
                                                    selectionne: d[i].selectionne,
                                                    affectee: ressourceAffectee,
                                                    planifiee: (!angular.isNullOrUndefined(d[i].debut)),
                                                    ordre: (ordre.length === 1) ? "0" + ordre : ordre,
                                                    titre: (nbre > 1) ? titre.replace(eventTitle, eventTitle + " (" + nbre.toString() + ")") + statut : titre + statut,
                                                    libRessource: (scope.opTypePlanning === "contact") ? d[i].libRessource.trim() : null,
                                                    data: {
                                                        "title": d[i].libLigne,
                                                        "allDay": true,
                                                        "selectionne": d[i].selectionne,
                                                        "element": d[i]
                                                    }
                                                });
                                            } else {
                                                if (!scope.eventDisponibles[idx].lectureSeule) {
                                                    var libStatut = "";
                                                    if (scope.opTypePlanning !== "contact") {
                                                        // Mettre à jour le statut de l'événement
                                                        var affectee = scope.eventDisponibles[idx].affectee;
                                                        var planifiee = scope.eventDisponibles[idx].planifiee;
                                                        libStatut = "\nStatut : ";

                                                        if (!affectee) {
                                                            scope.eventDisponibles[idx].affectee = ((d[i].typeRessource !== "Matériau") && !angular.isNullOrUndefined(d[i].idRessource));

                                                            if (scope.eventDisponibles[idx].affectee) {
                                                                libStatut += "Affectée";
                                                            } else {
                                                                libStatut += "Non affectée";
                                                            }
                                                        } else {
                                                            libStatut += "Affectée";
                                                        }

                                                        if (!planifiee) {
                                                            scope.eventDisponibles[idx].planifiee = (!angular.isNullOrUndefined(d[i].debut));
                                                            libStatut += ", non planifiée";
                                                        } else {
                                                            libStatut += ", planifiée";
                                                        }
                                                    }

                                                    scope.eventDisponibles[idx].titre = titre + libStatut;
                                                }

                                                scope.eventDisponibles[idx].selectionne = d[i].selectionne;

                                                // Mettre à jour les données associées à l'événement
                                                scope.eventDisponibles[idx].data["element"] = d[i];
                                            }
                                        }
                                    }

                                    // Maj nombre d'événements sélectionnés
                                    var nbreSelect = 0;
                                    var nbreSelectLectureSeule = 0;
                                    for (i = 0; i < scope.eventDisponibles.length; i++) {
                                        if (scope.eventDisponibles[i].selectionne) {
                                            nbreSelect++;

                                            if (scope.eventDisponibles[i].lectureSeule) {
                                                nbreSelectLectureSeule++;
                                            }
                                        }
                                    }

                                    // Tri selon ordre de création
                                    scope.eventDisponibles.sort(dynamicSort("ordre"));

                                    scope.nbreEventSelectionnes = nbreSelect;
                                    scope.nbreEventLectureSeuleSelectionnes = nbreSelectLectureSeule;
                                }
                            };

                            /**
                             * Chargement des données en lecture seule selon la période et ressources du planning
                             */
                            var chargementDonneesLectureSeule = function () {
                                var deferred = $q.defer();

                                // Liste des ressources affichées dans le planning
                                var r = getListeRessourcesAffectees(scope.listeRessources);

                                // Chargement des données si au moins une ressource affichée dans le planning
                                if (r.length > 0) {
                                    // Liste des ressources déjà traitées
                                    var nonTraitees = [];
                                    for (var i = 0; i < r.length; i++) {
                                        if (scope.listeIdResLectureSeule.indexOf(r[i].typeRessource + r[i].id.toString()) === -1) {
                                            nonTraitees.push(r[i]);
                                        }
                                    }

                                    // Mantis 6160 Pas de chargement des évenements en lecture seule si élément entête de type modèle
                                    // Si au moins une ressource non traitée
                                    if ((angular.isNullOrUndefined(scope.estModeleEntete) ||
                                        (!angular.isNullOrUndefined(scope.estModeleEntete) && scope.estModeleEntete === 0)) &&
                                        (nonTraitees.length > 0)) {
                                        // Connfig du planning
                                        var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                        if (!angular.isNullOrUndefined(paramPlanning.ecranRechercheAutresTaches) && (paramPlanning.ecranRechercheAutresTaches !== "")) {
                                            // Chargement écran de recherche événements à afficher en lecture seule
                                            serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheAutresTaches, null).then(function (retourChargement) {
                                                // Init des critères de recherche
                                                var typeRessource = [];
                                                var idRessource = [];
                                                for (i = 0; i < nonTraitees.length; i++) {
                                                    var t = ((nonTraitees[i].typeRessource === "Individu") || (nonTraitees[i].typeRessource === "Equipe"))? "Intervenant" : nonTraitees[i].typeRessource;
                                                    typeRessource.push(t);
                                                    idRessource.push(nonTraitees[i].id);
                                                    scope.listeIdResLectureSeule.push(nonTraitees[i].typeRessource + nonTraitees[i].id.toString());
                                                }

                                                if (typeRessource.length === 0) {
                                                    typeRessource.push("Aucun");
                                                }

                                                if (idRessource.length === 0) {
                                                    idRessource.push(-1);
                                                }

                                                var view = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getView');
                                                var debut = (moment(view.start).isValid()) ? moment(view.start).subtract(6, 'months').toDate() : moment(scope.debutEntete).subtract(6, 'months').toDate();
                                                var fin = (moment(view.end).isValid()) ? moment(view.end).add(6, 'months').toDate() : moment(scope.debutEntete).add(6, 'months').toDate();

                                                if (scope.opNomModule === "Contact") {
                                                    // Filtrer selon ressources visibles dans le planning
                                                    scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.idRessource].valeur = idRessource;

                                                    // Filtre selon la période affichée
                                                    // Début tâche >= début période et début tâche <= fin période
                                                    if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "DATETIME") {
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDateTime(debut);
                                                    } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "TIME") {
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versTime(debut);
                                                    } else {
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDate(debut);
                                                    }

                                                    if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "DATETIME") {
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDateTime(fin);
                                                    } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "TIME") {
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versTime(fin);
                                                    } else {
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDate(fin);
                                                    }
                                                } else if (scope.opTypePlanning === "recherche") {
                                                    if (scope.opNomModule === "Intervention") {
                                                        // Filtre selon Id intervention
                                                        //scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.idEntete].valeur = scope.idFormulaire[paramPlanning.colonnesEntete.idEntete];

                                                        // Filtrer selon ressources visibles dans le planning
                                                        //scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.typeRessource].valeur = typeRessource;
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.idRessource].valeur = idRessource;

                                                        // Filtre selon la période affichée
                                                        // Début tâche >= début période et début tâche <= fin période
                                                        if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "DATETIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDateTime(debut);
                                                        } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "TIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versTime(debut);
                                                        } else {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDate(debut);
                                                        }

                                                        if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "DATETIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDateTime(fin);
                                                        } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "TIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versTime(fin);
                                                        } else {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDate(fin);
                                                        }
                                                    }
                                                } else if (scope.opTypePlanning === "tache") {
                                                    if (scope.opActionCode === "GERERTACHE") {
                                                        // Filtre selon Id intervention
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.idEntete].valeur = scope.idFormulaire[paramPlanning.colonnesEntete.idEntete];

                                                        // Filtrer selon ressources visibles dans le planning
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.typeRessource].valeur = typeRessource;
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.idRessource].valeur = idRessource;

                                                        // Filtre selon la période affichée
                                                        // Début tâche >= début période et début tâche <= fin période
                                                        if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "DATETIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDateTime(debut);
                                                        } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "TIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versTime(debut);
                                                        } else {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDate(debut);
                                                        }

                                                        if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "DATETIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDateTime(fin);
                                                        } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "TIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versTime(fin);
                                                        } else {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDate(fin);
                                                        }
                                                    }
                                                } else if (scope.opTypePlanning === "recurrence") {
                                                    if (scope.opActionCode === "GERERRECURRENCE") {
                                                        // Filtre selon Id intervention
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.idEntete].valeur = scope.idFormulaire[paramPlanning.colonnesEntete.idEntete];

                                                        // Filtrer selon ressources visibles dans le planning
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.typeRessource].valeur = typeRessource;
                                                        scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.idRessource].valeur = idRessource;

                                                        // Filtre selon la période affichée
                                                        // Début tâche >= début période et début tâche <= fin période
                                                        if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "DATETIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDateTime(debut);
                                                        } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "TIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versTime(debut);
                                                        } else {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDate(debut);
                                                        }

                                                        if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "DATETIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDateTime(fin);
                                                        } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].descriptions.type === "TIME") {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versTime(fin);
                                                        } else {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut2].valeur = conversion.versDate(fin);
                                                        }
                                                    }
                                                }

                                                // Lancer la recherche
                                                scope.recherche[paramPlanning.ecranRechercheAutresTaches](100000).then(function (retourRecherche) {
                                                    // Renseignement cache de données en lecture seule
                                                    var bufLignes = [];
                                                    var tableLignes = scope.grilles[retourRecherche[0][0]];

                                                    var listeIdExclus = [];
                                                    if (scope.opNomModule !== "Contact") { //if (scope.opTypePlanning !== "contact") {
                                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                                            if (listeIdExclus.indexOf(scope.bufDonnees[i].idEntete) === -1) {
                                                                listeIdExclus.push(scope.bufDonnees[i].idEntete);
                                                            }
                                                        }

                                                        for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                                            if (listeIdExclus.indexOf(scope.bufDonneesRecurrence[i].idEntete) === -1) {
                                                                listeIdExclus.push(scope.bufDonneesRecurrence[i].idEntete);
                                                            }
                                                        }
                                                    }

                                                    //if (!angular.isNullOrUndefined(scope.idEntete)) {
                                                        for (i = 0; i < tableLignes.length; i++) {
                                                            if (!angular.isNullOrUndefined(tableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur) &&
                                                                !angular.isNullOrUndefined(tableLignes[i][paramPlanning.colonnesLigne.libLigne].valeur) &&
                                                                !angular.isNullOrUndefined(tableLignes[i][paramPlanning.colonnesLigne.rechercheDebut1].valeur) &&
                                                                ((listeIdExclus.length === 0) || (listeIdExclus.indexOf(tableLignes[i][paramPlanning.colonnesEntete.idEntete].valeur) === -1))) {
                                                                bufLignes.push({
                                                                    idEntete: tableLignes[i][paramPlanning.colonnesEntete.idEntete].valeur,
                                                                    refEntete: tableLignes[i][paramPlanning.colonnesEntete.refEntete].valeur,
                                                                    adresseEntete: tableLignes[i][paramPlanning.colonnesEntete.adresseEntete].valeur,
                                                                    refInterneLigne: tableLignes[i][paramPlanning.colonnesLigne.refInterneLigne].valeur,
                                                                    numeroInterneLigne: tableLignes[i][paramPlanning.colonnesLigne.numeroInterneLigne].valeur,
                                                                    creationLigne: tableLignes[i][paramPlanning.colonnesLigne.creationLigne].valeur,
                                                                    typeRessource: tableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur,
                                                                    idRessource: tableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur,
                                                                    libRessource: tableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur,
                                                                    //qteRessource: tableLignes[i][paramPlanning.colonnesLigne.qteRessource].valeur,
                                                                    idLigne: tableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur,
                                                                    idGroupe: tableLignes[i][paramPlanning.colonnesLigne.idGroupe].valeur,
                                                                    refLigne: tableLignes[i][paramPlanning.colonnesLigne.refLigne].valeur,
                                                                    libLigne: tableLignes[i][paramPlanning.colonnesLigne.libLigne].valeur,
                                                                    descLigne: tableLignes[i][paramPlanning.colonnesLigne.descLigne].valeur,
                                                                    debut: tableLignes[i][paramPlanning.colonnesLigne.rechercheDebut1].valeur,
                                                                    fin: tableLignes[i][paramPlanning.colonnesLigne.rechercheFin1].valeur,
                                                                    //debutReel: tableLignes[i][paramPlanning.colonnesLigne.debutReel].valeur,
                                                                    //finReelle: tableLignes[i][paramPlanning.colonnesLigne.finReelle].valeur,
                                                                    //heureDebut: tableLignes[i][paramPlanning.colonnesLigne.heureDebut].valeur,
                                                                    //heureFin: tableLignes[i][paramPlanning.colonnesLigne.heureFin].valeur,
                                                                    //dureeJours: tableLignes[i][paramPlanning.colonnesLigne.dureeJours].valeur,
                                                                    //dureeHeures: tableLignes[i][paramPlanning.colonnesLigne.dureeHeures].valeur,
                                                                    //avancement: tableLignes[i][paramPlanning.colonnesLigne.avancement].valeur,
                                                                    visible: true,
                                                                    ligneVide: false,
                                                                    selectionne: false
                                                                });
                                                            }
                                                        }
                                                    //}

                                                    // Renvoyer le tableau des données à ajouter en lecture seule au planning
                                                    deferred.resolve(bufLignes);
                                                }, function () {
                                                    deferred.reject(); // Erreur lors de la recherche
                                                });
                                            }, function () {
                                                deferred.reject(); // Erreur lors de chargement de l'écran
                                            });
                                        }
                                    } else {
                                        deferred.reject(); // Ressources déjà traitées
                                    }
                                } else {
                                    deferred.reject(); // Pas de ressource à traiter
                                }

                                return deferred.promise;
                            };

                            /**
                             * Rétrouver le numéro de récurrence associé à un élément
                             * @param {number} id Identifiant de l'élément
                             * @returns {*}
                             */
                            var rechercheNumeroRecurrence = function (id) {
                                var deferred = $q.defer();

                                if (scope.opTypePlanning === "recurrence") {
                                    if (scope.opActionCode === "GERERRECURRENCE") {
                                        var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                        serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheNumeroRecurrence, null).then(function (retourChargement) {
                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.idEntete].valeur = id;
                                            scope.recherche[paramPlanning.ecranRechercheNumeroRecurrence](1).then(function (retourRecherche) {
                                                if (scope.grilles[retourRecherche[0][0]].length > 0) {
                                                    deferred.resolve(scope.grilles[retourRecherche[0][0]][0][paramPlanning.colonnesEntete.recurrenceNumero].valeur);
                                                } else {
                                                    deferred.resolve(null);
                                                }
                                            });
                                        }, function () {
                                            deferred.reject();
                                        });
                                    }
                                } else {
                                    deferred.resolve(null);
                                }

                                return deferred.promise;
                            };

                            /**
                             * Rétrouver l'id de l'élémént mâtire d'une série à partir d'un numéro de récurrence
                             * @param {number} numeroRecurrence Numéro de référence de la série
                             * @param {number} idFormulaireMaitre Identifiant du formulaire mâitre
                             * @returns {*}
                             */
                            var rechercheIdElementMaitre = function (numeroRecurrence, idFormulaireMaitre) {
                                var deferred = $q.defer();

                                if (!angular.isNullOrUndefined(numeroRecurrence) && (scope.opTypePlanning === "recurrence")) {
                                    if (scope.opActionCode === "GERERRECURRENCE") {
                                        if (numeroRecurrence === -1) {
                                            // Pas encore de récurennces d'enregistrées pour cet élément --> renvoyer l'id du formulaire appelant
                                            deferred.resolve(idFormulaireMaitre);
                                        } else {
                                            var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                            serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheMaitreRecurrence, null).then(function (retourChargement) {
                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.recurrenceNumero].valeur = numeroRecurrence;
                                                scope.recherche[paramPlanning.ecranRechercheMaitreRecurrence](100000).then(function (retourRecherche) {
                                                    var idElementRef = null;
                                                    var debMin = null;

                                                    for (var i = 0; i < scope.grilles[retourRecherche[0][0]].length; i++) {
                                                        if (i === 0) {
                                                            idElementRef = scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.idEntete].valeur;
                                                            debMin = moment(scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.debutEntete].valeur);
                                                        } else {
                                                            if (!moment(debMin).isValid()) {
                                                                idElementRef = scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.idEntete].valeur;
                                                                debMin = moment(scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.debutEntete].valeur);
                                                            } else {
                                                                var d = moment(scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.debutEntete].valeur);
                                                                if (d.isValid() && (d.isBefore(debMin, 'day'))) {
                                                                    idElementRef = scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.idEntete].valeur;
                                                                    debMin = moment(scope.grilles[retourRecherche[0][0]][i][paramPlanning.colonnesEntete.debutEntete].valeur);
                                                                }
                                                            }
                                                        }
                                                    }

                                                    deferred.resolve(idElementRef);
                                                });
                                            }, function () {
                                                deferred.resolve(null);
                                            });
                                        }
                                    }
                                } else {
                                    deferred.resolve(null);
                                }

                                return deferred.promise;
                            };

                            /**
                             * Actualiser le planning (rechargement depuis la base de données)
                             */
                            var actualiserOpPlanning = function () {
                                var i, g;

                                // Réinit tableaux pour la gestion des suppressions
                                scope.eventSupprimes = [];
                                scope.eventRecurrentSupprimes = [];
                                scope.lignesRecurrentSupprimees = [];

                                // Réinit tableau pour la suivi des statuts
                                scope.groupesStatutsLignesModifies = [];

                                // Identifiant de la sélection en cours
                                var cle = getCleSelection();
                                var idSelection = null;
                                var clePrimaire = cle.split(SEPARATEUR_INTERNE);

                                for (i = 0; i < clePrimaire.length; i++) {
                                    var colonneEtValeur = clePrimaire[i].split(SEPARATEUR_NIVEAU_1);

                                    if (angular.isNullOrUndefined(idSelection)) {
                                        idSelection = {};
                                    }

                                    idSelection[colonneEtValeur[0]] = colonneEtValeur[1];
                                }

                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                if (scope.opTypePlanning === "recherche") {
                                    // Chargement données en mode rechrche
                                    serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRecherche, null).then(function (retourChargement) {
                                        var listeIdEntete = [];

                                        if ((scope.opNomModule === "Contact") || (scope.opNomModule === "Intervention")) {
                                            for (i = 0; i < scope.opDonneesRecherche.length; i++) {
                                                if (listeIdEntete.indexOf(scope.opDonneesRecherche[i][paramPlanning.colonnesEntete.idEntete].valeur) === -1) {
                                                    listeIdEntete.push(scope.opDonneesRecherche[i][paramPlanning.colonnesEntete.idEntete].valeur);
                                                }
                                            }
                                        }

                                        // Filtre selon idEntete
                                        if (listeIdEntete.length > 0) {
                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.idEntete].valeur = listeIdEntete;
                                        }

                                        // Filtrer selon la date de début
                                        if (scope.opNomModule === "Contact") {
                                            var debPeriode = moment().subtract(6, 'months').toDate();

                                            if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "DATETIME") {
                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDateTime(debPeriode);
                                            } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "TIME") {
                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versTime(debPeriode);
                                            } else {
                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDate(debPeriode);
                                            }
                                        }

                                        scope.recherche[paramPlanning.ecranRecherche](100000).then(function (retourRecherche) {
                                            // Buffer pour la mise en forme les données pour utilisation par le calendrier
                                            var bufLignes = [];
                                            var idxLignes = [];

                                            var dataTableLignes = scope.grilles[retourRecherche[0][0]];
                                            for (i = 0; i < dataTableLignes.length; i++) {
                                                // Date de début d'entête
                                                var debLigne = (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur)) ? dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur : null;
                                                if (angular.isNullOrUndefined(scope.debutEntete) && !angular.isNullOrUndefined(debLigne)) {
                                                    scope.debutEntete = debLigne;
                                                } else {
                                                    if (moment(debLigne).isBefore(moment(scope.debutEntete, 'day'))) {
                                                        scope.debutEntete = debLigne;
                                                    }
                                                }

                                                // Type de ressource "Individu" ou "Equipe" = "Intervenant"
                                                var typeRessource = null;
                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur)) {
                                                    typeRessource = dataTableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur;
                                                    if ((typeRessource === "Individu") || (typeRessource === "Equipe")) {
                                                        typeRessource = "Intervenant";
                                                    }
                                                }

                                                // idxLigne pour éviter l'ajout des lignes en doublon
                                                var idxLigne = (angular.isNullOrUndefined(typeRessource)) ? "NULL" : typeRessource;

                                                if (typeRessource === "Matériau") {
                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur)) {
                                                        idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur;
                                                    } else {
                                                        idxLigne += "-NULL";
                                                    }
                                                } else {
                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur)) {
                                                        idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur.toString();
                                                    } else {
                                                        idxLigne += "-NULL";
                                                    }
                                                }

                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur)) {
                                                    idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur.toString();
                                                } else {
                                                    idxLigne += "-NULL";
                                                }

                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur)) {
                                                    idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur.toString();
                                                } else {
                                                    idxLigne += "-NULL";
                                                }

                                                if (idxLignes.indexOf(idxLigne) === -1) {
                                                    bufLignes.push({
                                                        idEntete: dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur,
                                                        statutEntete: dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur,
                                                        refEntete: dataTableLignes[i][paramPlanning.colonnesLigne.refEntete].valeur,
                                                        adresseEntete: dataTableLignes[i][paramPlanning.colonnesLigne.adresseEntete].valeur,
                                                        refInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refInterneLigne].valeur,
                                                        numeroInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.numeroInterneLigne].valeur,
                                                        creationLigne: dataTableLignes[i][paramPlanning.colonnesLigne.creationLigne].valeur,
                                                        typeRessource: typeRessource,
                                                        idRessource: dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur,
                                                        libRessource: dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur,
                                                        qteRessource: dataTableLignes[i][paramPlanning.colonnesLigne.qteRessource].valeur,
                                                        idLigne: dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur,
                                                        statutLigne: dataTableLignes[i][paramPlanning.colonnesLigne.statutLigne].valeur,
                                                        idGroupe: dataTableLignes[i][paramPlanning.colonnesLigne.idGroupe].valeur,
                                                        refLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refLigne].valeur,
                                                        libLigne: dataTableLignes[i][paramPlanning.colonnesLigne.libLigne].valeur,
                                                        descLigne: dataTableLignes[i][paramPlanning.colonnesLigne.descLigne].valeur,
                                                        debut: dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur,
                                                        fin: dataTableLignes[i][paramPlanning.colonnesLigne.fin].valeur,
                                                        debutReel: dataTableLignes[i][paramPlanning.colonnesLigne.debutReel].valeur,
                                                        finReelle: dataTableLignes[i][paramPlanning.colonnesLigne.finReelle].valeur,
                                                        //heureDebut: dataTableLignes[i][paramPlanning.colonnesLigne.heureDebut].valeur,
                                                        //heureFin: dataTableLignes[i][paramPlanning.colonnesLigne.heureFin].valeur,
                                                        dureeJours: dataTableLignes[i][paramPlanning.colonnesLigne.dureeJours].valeur,
                                                        dureeHeures: dataTableLignes[i][paramPlanning.colonnesLigne.dureeHeures].valeur,
                                                        avancement: dataTableLignes[i][paramPlanning.colonnesLigne.avancement].valeur,
                                                        visible: true,
                                                        ligneVide: false,
                                                        selectionne: false,
                                                        estAjout: false,
                                                        modifie: false
                                                    });

                                                    idxLignes.push(idxLigne);
                                                }
                                            }

                                            // Cache des données du planning
                                            scope.bufDonnees = bufLignes;
                                            scope.bufDonneesRecurrence = [];

                                            // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                            initListeEvenementDispo(scope.bufDonnees);

                                            // Initialiser tableau du nombre d'éléments par événement
                                            scope.numeroElemParEvenement = initNumeroElemParEvenement();

                                            // Actualiser le grille et le calendrier
                                            //scope.actualiserOpPlanningDepuisCache(false);
                                            construireDonneesGrillePlanning(scope.bufDonnees).then(function () {
                                                construireEventSources(scope.bufDonnees, scope.bufDonneesRecurrence).then(function () {
                                                    if (!angular.isNullOrUndefined(paramPlanning.ecranRechercheRessources) && (paramPlanning.ecranRechercheRessources !== "")) { //if (scope.opNomModule === "Contact") {
                                                        serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheRessources, null).then(function () {
                                                            scope.recherche[paramPlanning.ecranRechercheRessources](100000).then(function (retourRecherche) {
                                                                // Init liste des ressources
                                                                scope.listeRessources = getListeRessources(scope.grilles[retourRecherche[0][0]]);

                                                                chargementDonneesLectureSeule().then(function (retourChargementLectureSeule) {
                                                                    // Ajout source de données au planning en lecture seule (pas d'affichage dans la grille)
                                                                    ajoutEventSourceLectureSeule(retourChargementLectureSeule).then(function (eventSource) {
                                                                        scope.eventSources.push(eventSource);
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    }
                                                });
                                            });

                                            // Affichage planning par défaut
                                            if ((!scope.modeAffichage.grille) && (!scope.modeAffichage.planning)) {
                                                scope.modeAffichage.planning = true;
                                            }
                                        });
                                    });
                                } else if (!angular.isNullOrUndefined(idSelection)) {
                                    // Identifiant de l'écran formulaire en fonction du type de planning
                                    scope.idFormulaire = null;

                                    if (scope.opTypePlanning === "tache") {
                                        if (scope.opActionCode === "GERERTACHE") {
                                            // Format clé sélection attendu = {ID_INTERVENTION: Number}
                                            scope.idFormulaire = {ID_INTERVENTION: Number(idSelection.ID_INTERVENTION)};
                                        }
                                    } else if (scope.opTypePlanning === "recurrence") {
                                        if (scope.opActionCode === "GERERRECURRENCE") {
                                            // Format clé sélection attendu = {ID_INTERVENTION: Number}
                                            scope.idFormulaire = {ID_INTERVENTION: Number(idSelection.ID_INTERVENTION)};
                                        }
                                    }

                                    if (angular.isNullOrUndefined(scope.idFormulaire)) {
                                        // Chargement données via écran recherche (sélection multiple)
                                        // Init liste des ressources
                                        serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheRessources, null).then(function () {
                                            scope.recherche[paramPlanning.ecranRechercheRessources](100000)
                                                .then(function (retourRecherche) {
                                                    // Init liste des ressources
                                                    scope.listeRessources = getListeRessources(scope.grilles[retourRecherche[0][0]]);

                                                    // Init liste des compétences
                                                    scope.filtreRessources.listeCompetences = getListeCompetences(scope.listes.LST_COT_COMPETENCE);

                                                    // Init liste des types de matériels
                                                    //scope.filtreRessources.listeTypesMateriels = getListeTypesMateriels(scope.listes.LST_MAT_TYPE);

                                                    // Init liste des groupes des ressources
                                                    scope.filtreRessources.groupes = getGroupesRessources(scope.listeRessources);

                                                    // Charger les données du planning
                                                    serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRecherche, null).then(function (retourChargement) {
                                                        var listeIdEntete = [];

                                                        if (scope.opNomModule === "Contact") {
                                                            // Filtre selon sélection de la recherche en cours
                                                            for (i = 0; i < scope.opSelection.length; i++) {
                                                                if (listeIdEntete.indexOf(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete].valeur) === -1) {
                                                                    listeIdEntete.push(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete].valeur);
                                                                }
                                                            }

                                                            // Filtrer selon la date de début
                                                            var debPeriode = moment().subtract(6, 'months').toDate();

                                                            if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "DATETIME") {
                                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDateTime(debPeriode);
                                                            } else if (scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].descriptions.type === "TIME") {
                                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versTime(debPeriode);
                                                            } else {
                                                                scope.champs[retourChargement[0][0]][paramPlanning.colonnesLigne.rechercheDebut1].valeur = conversion.versDate(debPeriode);
                                                            }
                                                        }

                                                        if (listeIdEntete.length > 0) {
                                                            scope.champs[retourChargement[0][0]][paramPlanning.colonnesEntete.idEntete].valeur = listeIdEntete;
                                                        }

                                                        scope.recherche[paramPlanning.ecranRecherche](100000).then(function (retourRecherche) {
                                                            // Buffer pour la mise en forme les données pour utilisation par le calendrier
                                                            var bufLignes = [];
                                                            var idxLignes = [];

                                                            var dataTableLignes = scope.grilles[retourRecherche[0][0]];

                                                            // Mémoriser id et référence de l'élément entête
                                                            //var tableEntete = scope.champs[retourChargement[0][0]];
                                                            //scope.idEntete = tableEntete[paramPlanning.colonnesEntete.idEntete].valeur;
                                                            //scope.refEntete = tableEntete[paramPlanning.colonnesEntete.refEntete].valeur;
                                                            //scope.adresseEntete = tableEntete[paramPlanning.colonnesEntete.adresseEntete].valeur;
                                                            //scope.objetEntete = tableEntete[paramPlanning.colonnesEntete.objetEntete].valeur;

                                                            // Planning en lecture/écriture par défaut
                                                            scope.planningLectureSeule = false;

                                                            for (i = 0; i < dataTableLignes.length; i++) {
                                                                // Date de début d'entête
                                                                var debLigne = (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur)) ? dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur : null;
                                                                if (angular.isNullOrUndefined(scope.debutEntete) && !angular.isNullOrUndefined(debLigne)) {
                                                                    scope.debutEntete = debLigne;
                                                                } else {
                                                                    if (moment(debLigne).isBefore(moment(scope.debutEntete, 'day'))) {
                                                                        scope.debutEntete = debLigne;
                                                                    }
                                                                }

                                                                // Planning en lecture seule ?
                                                                if (!scope.planningLectureSeule && !angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete])) {
                                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur) &&
                                                                        (paramPlanning.statutsLecutreSeule.indexOf(dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur) !== -1)) {
                                                                        scope.planningLectureSeule = true;
                                                                    }
                                                                }

                                                                // Debug Type de ressource "Individu" ou "Equipe" = "Intervenant"
                                                                var typeRessource = null;
                                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur)) {
                                                                    typeRessource = dataTableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur;

                                                                    if ((typeRessource === "Individu") || (typeRessource === "Equipe")) {
                                                                        typeRessource = "Intervenant";
                                                                    }
                                                                }

                                                                // idxLigne pour éviter l'ajout des lignes en doublon
                                                                var idxLigne = (angular.isNullOrUndefined(typeRessource)) ? "NULL" : typeRessource;

                                                                if (typeRessource === "Matériau") {
                                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur)) {
                                                                        idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur;
                                                                    } else {
                                                                        idxLigne += "-NULL";
                                                                    }
                                                                } else {
                                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur)) {
                                                                        idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur.toString();
                                                                    } else {
                                                                        idxLigne += "-NULL";
                                                                    }
                                                                }

                                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur)) {
                                                                    idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur.toString();
                                                                } else {
                                                                    idxLigne += "-NULL";
                                                                }

                                                                if (idxLignes.indexOf(idxLigne) === -1) {
                                                                    bufLignes.push({
                                                                        idEntete: dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur,
                                                                        statutEntete: dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur,
                                                                        refEntete: dataTableLignes[i][paramPlanning.colonnesLigne.refEntete].valeur,
                                                                        adresseEntete: dataTableLignes[i][paramPlanning.colonnesLigne.adresseEntete].valeur,
                                                                        refInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refInterneLigne].valeur,
                                                                        numeroInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.numeroInterneLigne].valeur,
                                                                        creationLigne: dataTableLignes[i][paramPlanning.colonnesLigne.creationLigne].valeur,
                                                                        typeRessource: typeRessource,
                                                                        idRessource: dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur,
                                                                        libRessource: dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur,
                                                                        qteRessource: dataTableLignes[i][paramPlanning.colonnesLigne.qteRessource].valeur,
                                                                        idLigne: dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur,
                                                                        statutLigne: dataTableLignes[i][paramPlanning.colonnesLigne.statutLigne].valeur,
                                                                        idGroupe: dataTableLignes[i][paramPlanning.colonnesLigne.idGroupe].valeur,
                                                                        refLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refLigne].valeur,
                                                                        libLigne: dataTableLignes[i][paramPlanning.colonnesLigne.libLigne].valeur,
                                                                        descLigne: dataTableLignes[i][paramPlanning.colonnesLigne.descLigne].valeur,
                                                                        debut: dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur,
                                                                        fin: dataTableLignes[i][paramPlanning.colonnesLigne.fin].valeur,
                                                                        debutReel: dataTableLignes[i][paramPlanning.colonnesLigne.debutReel].valeur,
                                                                        finReelle: dataTableLignes[i][paramPlanning.colonnesLigne.finReelle].valeur,
                                                                        //heureDebut: dataTableLignes[i][paramPlanning.colonnesLigne.heureDebut].valeur,
                                                                        //heureFin: dataTableLignes[i][paramPlanning.colonnesLigne.heureFin].valeur,
                                                                        dureeJours: dataTableLignes[i][paramPlanning.colonnesLigne.dureeJours].valeur,
                                                                        dureeHeures: dataTableLignes[i][paramPlanning.colonnesLigne.dureeHeures].valeur,
                                                                        avancement: dataTableLignes[i][paramPlanning.colonnesLigne.avancement].valeur,
                                                                        visible: true,
                                                                        ligneVide: false,
                                                                        selectionne: false,
                                                                        estAjout: false,
                                                                        modifie: false
                                                                    });

                                                                    idxLignes.push(idxLigne);
                                                                }
                                                            }

                                                            scope.initOptionsRessourceSelect().then(function (retourInitSelect) {
                                                                if ((scope.opTypePlanning === "contact") && !angular.isNullOrUndefined(retourInitSelect)) {
                                                                    // Mantis 6167 Ajout des ressources de la sélection (recherche) en cours
                                                                    for (var s = 0; s < retourInitSelect.length; s++) {
                                                                        var idxBufLigne = -1;
                                                                        for (var j = 0; j < bufLignes.length; j++) {
                                                                            if ((bufLignes[j].typeRessource === "Intervenant") &&
                                                                                (bufLignes[j].idRessource === retourInitSelect[s].id) &&
                                                                                (bufLignes[j].libRessource === retourInitSelect[s].libRessource)) {
                                                                                idxBufLigne = j;
                                                                                break;
                                                                            }
                                                                        }

                                                                        if (idxBufLigne !== -1) {
                                                                            bufLignes[idxBufLigne].visible = true;
                                                                        } else {
                                                                            bufLignes.push({
                                                                                idEntete: scope.idEntete,
                                                                                refEntete: scope.refEntete,
                                                                                adresseEntete: scope.adresseEntete,
                                                                                refInterneLigne: null,
                                                                                numeroInterneLigne: null,
                                                                                creationLigne: null,
                                                                                typeRessource: "Intervenant",
                                                                                idRessource: retourInitSelect[s].id,
                                                                                libRessource: retourInitSelect[s].libRessource,
                                                                                qteRessource: 1,
                                                                                idLigne: null,
                                                                                idGroupe: null,
                                                                                refLigne: null,
                                                                                libLigne: null,
                                                                                descLigne: null,
                                                                                debut: null,
                                                                                fin: null,
                                                                                debutReel: null,
                                                                                finReelle: null,
                                                                                //heureDebut: null,
                                                                                //heureFin: null,
                                                                                dureeJours: null,
                                                                                dureeHeures: null,
                                                                                avancement:null,
                                                                                visible: true,
                                                                                ligneVide: true,
                                                                                selectionne: false
                                                                            });
                                                                        }
                                                                    }
                                                                }

                                                                // Cache des données du planning
                                                                scope.bufDonnees = bufLignes;
                                                                scope.bufDonneesRecurrence = [];

                                                                // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                                                initListeEvenementDispo(scope.bufDonnees);

                                                                // Initialiser tableau du nombre d'éléments par événement
                                                                scope.numeroElemParEvenement = initNumeroElemParEvenement();

                                                                // Actualiser le grille et le calendrier
                                                                //scope.actualiserOpPlanningDepuisCache(true);
                                                                construireDonneesGrillePlanning(scope.bufDonnees).then(function () {
                                                                    construireEventSources(scope.bufDonnees, scope.bufDonneesRecurrence).then(function () {
                                                                        // Maj statut des actions
                                                                        if ((scope.opTypePlanning === "contact") && !angular.isNullOrUndefined(retourInitSelect)) {
                                                                            // Mantis 6167 Initialisation combo sélection de ressources
                                                                            scope.ressourceSelect = retourInitSelect[0].libRessource;
                                                                            scope.changeRessourceSelect(retourInitSelect[0].libRessource);
                                                                        } else {
                                                                            var libRessourceSelect = getLibRessourceSelect();
                                                                            var statut = {
                                                                                nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                                                                nbreSelect: scope.nbreEventSelectionnes,
                                                                                planningModifie: scope.planningModifie,
                                                                                lectureSeule: scope.planningLectureSeule,
                                                                                libRessourceSelect: libRessourceSelect
                                                                            };

                                                                            scope.updateActions()(statut);
                                                                        }

                                                                        chargementDonneesLectureSeule().then(function (retourChargementLectureSeule) {
                                                                            // Ajout source de données au planning en lecture seule (pas d'affichage dans la grille)
                                                                            ajoutEventSourceLectureSeule(retourChargementLectureSeule).then(function (eventSource) {
                                                                                scope.eventSources.push(eventSource);
                                                                            });
                                                                        });
                                                                    });
                                                                });

                                                                // Affichage planning par défaut
                                                                if ((!scope.modeAffichage.grille) && (!scope.modeAffichage.planning)) {
                                                                    scope.modeAffichage.planning = true;
                                                                }
                                                            });
                                                        });
                                                    });
                                                })
                                        });
                                    } else {
                                        // Chargement données via écran formulaire (sélection unitaire)
                                        // Init liste des ressources
                                        serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheRessources, null).then(function () {
                                            scope.recherche[paramPlanning.ecranRechercheRessources](100000)
                                                    .then(function (retourRecherche) {
                                                        // Init liste des ressources
                                                        scope.listeRessources = getListeRessources(scope.grilles[retourRecherche[0][0]]);

                                                        // Init liste des compétences
                                                        scope.filtreRessources.listeCompetences = getListeCompetences(scope.listes.LST_COT_COMPETENCE);

                                                        // Init liste des types de matériels
                                                        scope.filtreRessources.listeTypesMateriels = getListeTypesMateriels(scope.listes.VL_PAT_LST_PAT_NATURE); //LST_MAT_NATURE);

                                                        // Init liste des groupes des ressources
                                                        scope.filtreRessources.groupes = getGroupesRessources(scope.listeRessources);

                                                        // Le cas échéant, prendre en compte le numéro de série de récurrence
                                                        var id = null;
                                                        if ((scope.opTypePlanning === "recurrence") && (scope.opActionCode === "GERERRECURRENCE")) {
                                                            id = scope.idFormulaire.ID_INTERVENTION;
                                                        }

                                                        rechercheNumeroRecurrence(id).then(function (numeroRecurrence) {
                                                            // Si planning de récurrence alors compléter l'identifiant avec le numéro de récurrence
                                                            if ((scope.opTypePlanning === "recurrence") && (scope.opActionCode === "GERERRECURRENCE")) {
                                                                if (!angular.isNullOrUndefined(numeroRecurrence)) {
                                                                    scope.idFormulaire.REC_REFINTERNENUMERO = Number(numeroRecurrence);
                                                                } else {
                                                                    scope.idFormulaire.REC_REFINTERNENUMERO = -1;
                                                                }

                                                                scope.refSerieRecurrence = scope.idFormulaire.REC_REFINTERNENUMERO;
                                                            }

                                                            rechercheIdElementMaitre(numeroRecurrence, id).then(function (idElementMaitre) {
                                                                // Si planning de récurrence alors positionner sur l'élémént "maître"
                                                                if ((scope.opTypePlanning === "recurrence") && (scope.opActionCode === "GERERRECURRENCE")) {
                                                                    if (!angular.isNullOrUndefined(idElementMaitre)) {
                                                                        scope.idFormulaire.ID_INTERVENTION = Number(idElementMaitre);
                                                                    }
                                                                }

                                                                // Charger les données du planning
                                                                serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRecherche, scope.idFormulaire).then(function (retourChargement) {
                                                                    // Buffer pour la mise en forme les données pour utilisation par le calendrier
                                                                    var bufLignes = [];
                                                                    var bufLignesRecurrence = [];

                                                                    // Mémoriser id et référence de l'élément entête
                                                                    var tableEntete = scope.champs[retourChargement[0][0]];
                                                                    scope.idEntete = tableEntete[paramPlanning.colonnesEntete.idEntete].valeur;
                                                                    scope.refEntete = tableEntete[paramPlanning.colonnesEntete.refEntete].valeur;
                                                                    scope.adresseEntete = tableEntete[paramPlanning.colonnesEntete.adresseEntete].valeur;
                                                                    scope.objetEntete = tableEntete[paramPlanning.colonnesEntete.objetEntete].valeur;

                                                                    if (!angular.isNullOrUndefined(tableEntete[paramPlanning.colonnesEntete.debutEntete].valeur)) {
                                                                        scope.debutEntete = tableEntete[paramPlanning.colonnesEntete.debutEntete].valeur;
                                                                    } else {
                                                                        scope.debutEntete = tableEntete[paramPlanning.colonnesEntete.creationEntete].valeur;
                                                                    }

                                                                    // Gérer le type d'élément entête (modèle ou non)
                                                                    if (!angular.isNullOrUndefined(paramPlanning.colonnesEntete.estModele)) {
                                                                        if (angular.isNullOrUndefined(tableEntete[paramPlanning.colonnesEntete.estModele].valeur)) {
                                                                            scope.estModeleEntete = 0;
                                                                        } else {
                                                                            scope.estModeleEntete = tableEntete[paramPlanning.colonnesEntete.estModele].valeur;
                                                                        }
                                                                    }

                                                                    if (!angular.isNullOrUndefined(scope.idEntete)) {
                                                                        var idxLignes = [];
                                                                        var idxLignesRecurrence = [];

                                                                        // Planning en lecture/écriture par défaut
                                                                        scope.planningLectureSeule = false;

                                                                        for (g = 0; g < retourChargement[1].length; g++) {
                                                                            var nomTableLignes = retourChargement[1][g];
                                                                            var dataTableLignes = scope.grilles[nomTableLignes];

                                                                            for (i = 0; i < dataTableLignes.length; i++) {
                                                                                // Planning en lecture seule ?
                                                                                if (!scope.planningLectureSeule && !angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete])) {
                                                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur) &&
                                                                                        (paramPlanning.statutsLecutreSeule.indexOf(dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur) !== -1)) {
                                                                                        scope.planningLectureSeule = true;
                                                                                    }
                                                                                }

                                                                                // Type de ressource "Individu" ou "Equipe" = "Intervenant"
                                                                                var typeRessource = null;
                                                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur)) {
                                                                                    typeRessource = dataTableLignes[i][paramPlanning.colonnesLigne.typeRessource].valeur;
                                                                                    if ((typeRessource === "Individu") || (typeRessource === "Equipe")) {
                                                                                        typeRessource = "Intervenant";
                                                                                    }
                                                                                }

                                                                                // idxLigne pour éviter l'ajout des lignes en doublon
                                                                                var idxLigne = (angular.isNullOrUndefined(typeRessource)) ? "NULL" : typeRessource;

                                                                                if (typeRessource === "Matériau") {
                                                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur)) {
                                                                                        idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur;
                                                                                    } else {
                                                                                        idxLigne += "-NULL";
                                                                                    }
                                                                                } else {
                                                                                    if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur)) {
                                                                                        idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur.toString();
                                                                                    } else {
                                                                                        idxLigne += "-NULL";
                                                                                    }
                                                                                }

                                                                                if (!angular.isNullOrUndefined(dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur)) {
                                                                                    idxLigne += "-" + dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur.toString();
                                                                                } else {
                                                                                    idxLigne += "-NULL";
                                                                                }

                                                                                if (paramPlanning.tablesLignesRecurrence.indexOf(nomTableLignes) !== -1) {
                                                                                    var dRef = moment(scope.debutEntete);
                                                                                    var dLigne = moment(dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur);
                                                                                    if ((dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur !== scope.idEntete) &&
                                                                                        (dLigne.isSame(dRef, 'day') || dLigne.isAfter(dRef, 'day')) &&
                                                                                        (idxLignesRecurrence.indexOf(idxLigne) === -1)) {
                                                                                        bufLignesRecurrence.push({
                                                                                            idEntete: dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur,
                                                                                            statutEntete: dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur,
                                                                                            refEntete: dataTableLignes[i][paramPlanning.colonnesLigne.refEntete].valeur,
                                                                                            adresseEntete: dataTableLignes[i][paramPlanning.colonnesLigne.adresseEntete].valeur,
                                                                                            refInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refInterneLigne].valeur,
                                                                                            numeroInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.numeroInterneLigne].valeur,
                                                                                            creationLigne: dataTableLignes[i][paramPlanning.colonnesLigne.creationLigne].valeur,
                                                                                            typeRessource: typeRessource,
                                                                                            idRessource: dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur,
                                                                                            libRessource: dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur,
                                                                                            qteRessource: dataTableLignes[i][paramPlanning.colonnesLigne.qteRessource].valeur,
                                                                                            idLigne: dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur,
                                                                                            statutLigne: dataTableLignes[i][paramPlanning.colonnesLigne.statutLigne].valeur,
                                                                                            idGroupe: dataTableLignes[i][paramPlanning.colonnesLigne.idGroupe].valeur,
                                                                                            refLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refLigne].valeur,
                                                                                            libLigne: dataTableLignes[i][paramPlanning.colonnesLigne.libLigne].valeur,
                                                                                            descLigne: dataTableLignes[i][paramPlanning.colonnesLigne.descLigne].valeur,
                                                                                            debut: dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur,
                                                                                            fin: dataTableLignes[i][paramPlanning.colonnesLigne.fin].valeur,
                                                                                            debutReel: dataTableLignes[i][paramPlanning.colonnesLigne.debutReel].valeur,
                                                                                            finReelle: dataTableLignes[i][paramPlanning.colonnesLigne.finReelle].valeur,
                                                                                            //heureDebut: dataTableLignes[i][paramPlanning.colonnesLigne.heureDebut].valeur,
                                                                                            //heureFin: dataTableLignes[i][paramPlanning.colonnesLigne.heureFin].valeur,
                                                                                            dureeJours: dataTableLignes[i][paramPlanning.colonnesLigne.dureeJours].valeur,
                                                                                            dureeHeures: dataTableLignes[i][paramPlanning.colonnesLigne.dureeHeures].valeur,
                                                                                            avancement: dataTableLignes[i][paramPlanning.colonnesLigne.avancement].valeur,
                                                                                            visible: true,
                                                                                            ligneVide: false,
                                                                                            selectionne: false,
                                                                                            estAjout: false,
                                                                                            modifie: false
                                                                                        });

                                                                                        idxLignesRecurrence.push(idxLigne);
                                                                                    }
                                                                                } else {
                                                                                    if (idxLignes.indexOf(idxLigne) === -1) {
                                                                                        bufLignes.push({
                                                                                            idEntete: dataTableLignes[i][paramPlanning.colonnesLigne.idEntete].valeur,
                                                                                            statutEntete: dataTableLignes[i][paramPlanning.colonnesLigne.statutEntete].valeur,
                                                                                            refEntete: dataTableLignes[i][paramPlanning.colonnesLigne.refEntete].valeur,
                                                                                            adresseEntete: dataTableLignes[i][paramPlanning.colonnesLigne.adresseEntete].valeur,
                                                                                            refInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refInterneLigne].valeur,
                                                                                            numeroInterneLigne: dataTableLignes[i][paramPlanning.colonnesLigne.numeroInterneLigne].valeur,
                                                                                            creationLigne: dataTableLignes[i][paramPlanning.colonnesLigne.creationLigne].valeur,
                                                                                            typeRessource: typeRessource,
                                                                                            idRessource: dataTableLignes[i][paramPlanning.colonnesLigne.idRessource].valeur,
                                                                                            libRessource: dataTableLignes[i][paramPlanning.colonnesLigne.libRessource].valeur,
                                                                                            qteRessource: dataTableLignes[i][paramPlanning.colonnesLigne.qteRessource].valeur,
                                                                                            idLigne: dataTableLignes[i][paramPlanning.colonnesLigne.idLigne].valeur,
                                                                                            statutLigne: dataTableLignes[i][paramPlanning.colonnesLigne.statutLigne].valeur,
                                                                                            idGroupe: dataTableLignes[i][paramPlanning.colonnesLigne.idGroupe].valeur,
                                                                                            refLigne: dataTableLignes[i][paramPlanning.colonnesLigne.refLigne].valeur,
                                                                                            libLigne: dataTableLignes[i][paramPlanning.colonnesLigne.libLigne].valeur,
                                                                                            descLigne: dataTableLignes[i][paramPlanning.colonnesLigne.descLigne].valeur,
                                                                                            debut: dataTableLignes[i][paramPlanning.colonnesLigne.debut].valeur,
                                                                                            fin: dataTableLignes[i][paramPlanning.colonnesLigne.fin].valeur,
                                                                                            debutReel: dataTableLignes[i][paramPlanning.colonnesLigne.debutReel].valeur,
                                                                                            finReelle: dataTableLignes[i][paramPlanning.colonnesLigne.finReelle].valeur,
                                                                                            //heureDebut: dataTableLignes[i][paramPlanning.colonnesLigne.heureDebut].valeur,
                                                                                            //heureFin: dataTableLignes[i][paramPlanning.colonnesLigne.heureFin].valeur,
                                                                                            dureeJours: dataTableLignes[i][paramPlanning.colonnesLigne.dureeJours].valeur,
                                                                                            dureeHeures: dataTableLignes[i][paramPlanning.colonnesLigne.dureeHeures].valeur,
                                                                                            avancement: dataTableLignes[i][paramPlanning.colonnesLigne.avancement].valeur,
                                                                                            visible: true,
                                                                                            ligneVide: false,
                                                                                            selectionne: false,
                                                                                            estAjout: false,
                                                                                            modifie: false
                                                                                        });

                                                                                        idxLignes.push(idxLigne);
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    // Mantis 6167 Initialisation combo sélection de ressources
                                                                    scope.initOptionsRessourceSelect().then(function (retourInitSelect) {
                                                                        if ((scope.opTypePlanning === "contact") && !angular.isNullOrUndefined(retourInitSelect)) {
                                                                            // Mantis 6167 Ajout des ressources de la sélection (recherche) en cours
                                                                            for (var s = 0; s < retourInitSelect.length; s++) {
                                                                                var idxBufLigne = -1;
                                                                                for (var j = 0; j < bufLignes.length; j++) {
                                                                                    if ((bufLignes[j].typeRessource === "Intervenant") &&
                                                                                        (bufLignes[j].idRessource === retourInitSelect[s].id) &&
                                                                                        (bufLignes[j].libRessource === retourInitSelect[s].libRessource)) {
                                                                                        idxBufLigne = j;
                                                                                        break;
                                                                                    }
                                                                                }

                                                                                if (idxBufLigne !== -1) {
                                                                                    bufLignes[idxBufLigne].visible = true;
                                                                                } else {
                                                                                    bufLignes.push({
                                                                                        idEntete: scope.idEntete,
                                                                                        refEntete: scope.refEntete,
                                                                                        adresseEntete: scope.adresseEntete,
                                                                                        refInterneLigne: null,
                                                                                        numeroInterneLigne: null,
                                                                                        creationLigne: null,
                                                                                        typeRessource: "Intervenant",
                                                                                        idRessource: retourInitSelect[s].id,
                                                                                        libRessource: retourInitSelect[s].libRessource,
                                                                                        qteRessource: 1,
                                                                                        idLigne: null,
                                                                                        idGroupe: null,
                                                                                        refLigne: null,
                                                                                        libLigne: null,
                                                                                        descLigne: null,
                                                                                        debut: null,
                                                                                        fin: null,
                                                                                        debutReel: null,
                                                                                        finReelle: null,
                                                                                        //heureDebut: null,
                                                                                        //heureFin: null,
                                                                                        dureeJours: null,
                                                                                        dureeHeures: null,
                                                                                        avancement:null,
                                                                                        visible: true,
                                                                                        ligneVide: true,
                                                                                        selectionne: false
                                                                                    });
                                                                                }
                                                                            }
                                                                        }

                                                                        // Cache des données du planning
                                                                        scope.bufDonnees = bufLignes;
                                                                        scope.bufDonneesRecurrence = bufLignesRecurrence;

                                                                        // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                                                        initListeEvenementDispo(scope.bufDonnees);

                                                                        // Initialiser tableau du nombre d'éléments par événement
                                                                        scope.numeroElemParEvenement = initNumeroElemParEvenement();

                                                                        // Actualiser le grille et le calendrier
                                                                        //scope.actualiserOpPlanningDepuisCache(true);
                                                                        construireDonneesGrillePlanning(scope.bufDonnees).then(function () {
                                                                            construireEventSources(scope.bufDonnees, scope.bufDonneesRecurrence).then(function () {
                                                                                // Maj statut des actions
                                                                                if ((scope.opTypePlanning === "contact") && !angular.isNullOrUndefined(retourInitSelect)) {
                                                                                    // Mantis 6167 Initialisation combo sélection de ressources
                                                                                    scope.ressourceSelect = retourInitSelect[0].libRessource;
                                                                                    scope.changeRessourceSelect(retourInitSelect[0].libRessource);
                                                                                } else {
                                                                                    var libRessourceSelect = getLibRessourceSelect();
                                                                                    var statut = {
                                                                                        nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                                                                        nbreSelect: scope.nbreEventSelectionnes,
                                                                                        planningModifie: scope.planningModifie,
                                                                                        lectureSeule: scope.planningLectureSeule,
                                                                                        libRessourceSelect: libRessourceSelect
                                                                                    };
                                                                                    scope.updateActions()(statut);
                                                                                }

                                                                                chargementDonneesLectureSeule().then(function (retourChargementLectureSeule) {
                                                                                    // Ajout source de données au planning en lecture seule (pas d'affichage dans la grille)
                                                                                    ajoutEventSourceLectureSeule(retourChargementLectureSeule).then(function (eventSource) {
                                                                                        scope.eventSources.push(eventSource);
                                                                                    });
                                                                                });
                                                                            });
                                                                        });

                                                                        // Affichage planning par défaut
                                                                        if ((!scope.modeAffichage.grille) && (!scope.modeAffichage.planning)) {
                                                                            scope.modeAffichage.planning = true;
                                                                        }
                                                                    });
                                                                });
                                                            });
                                                        });
                                                    })
                                            });
                                    }
                                }
                            };

                            /**
                             * Actualiser le planning sans rechargement depuis la base de données
                             */
                            scope.actualiserOpPlanningDepuisCache = function (donneesLectureSeule) {
                                // S'assurer que les lignes de ressources "vides" restent visible dans le planning
                                if (scope.opTypePlanning === "tache") {
                                    for (var i = 0; i < scope.eventRessources.length; i++) {
                                        var ajoutLigneVide = true;
                                        for (var j = 0; j < scope.bufDonnees.length; j++) {
                                            if ((scope.bufDonnees[j].idRessource === scope.eventRessources[i].idRes) &&
                                                (scope.bufDonnees[j].typeRessource === scope.eventRessources[i].typeRes) &&
                                                (scope.bufDonnees[j].libRessource === scope.eventRessources[i].libRes)) {
                                                ajoutLigneVide = false;
                                                break;
                                            }
                                        }

                                        if (ajoutLigneVide) {
                                            scope.bufDonnees.push({
                                                idEntete: scope.idEntete,
                                                refEntete: scope.refEntete,
                                                adresseEntete: scope.adresseEntete,
                                                refInterneLigne: null,
                                                numeroInterneLigne: null,
                                                creationLigne: null,
                                                typeRessource: scope.eventRessources[i].typeRes,
                                                idRessource: scope.eventRessources[i].idRes,
                                                libRessource: scope.eventRessources[i].libRes,
                                                qteRessource: 1,
                                                idLigne: null,
                                                idGroupe: null,
                                                refLigne: null,
                                                libLigne: null,
                                                descLigne: null,
                                                debut: null,
                                                fin: null,
                                                debutReel: null,
                                                finReelle: null,
                                                //heureDebut: null,
                                                //heureFin: null,
                                                dureeJours: null,
                                                dureeHeures: null,
                                                avancement: null,
                                                visible: true,
                                                ligneVide: true,
                                                selectionne: false
                                            });
                                        }
                                    }
                                }

                                // Actualiser le grille et le calendrier
                                construireDonneesGrillePlanning(scope.bufDonnees).then(function () {
                                    construireEventSources(scope.bufDonnees, scope.bufDonneesRecurrence).then(function () {
                                        if (!angular.isNullOrUndefined(donneesLectureSeule) && donneesLectureSeule) {
                                            chargementDonneesLectureSeule().then(function (retourChargementLectureSeule) {
                                                // Ajout source de données au planning en lecture seule (pas d'affichage dans la grille)
                                                ajoutEventSourceLectureSeule(retourChargementLectureSeule).then(function (eventSource) {
                                                    scope.eventSources.push(eventSource);
                                                });
                                            });
                                        }
                                    });
                                });
                            };

                            /**
                             * Déclencher rechargement depuis la base de données
                             */
                            var rechargerDonnees = function () {
                                actualiserOpPlanning();
                                scope.planningModifie = false;
                            };

                            /**
                             * Modification de la duration d'un évennement
                             * @param {Object} event Evennement modifié
                             * @param {Object} delta Moment duration objet indiquant la quantité de temps dont l'évennement à été déplacé
                             * @param {number} nbreJours Nombre de jours dont le début de l'événement a été déplacée
                             */
                            var modifierDuration = function (event, delta, nbreJours) {
                                if (delta != 0) {
                                    scope.planningModifie = true;

                                    var i, d, f, n, deltaDeb, deltaFin;

                                    if ((event.maitre) && (nbreJours !== 0)) {
                                        // Traitement suite déplacement événemnt maître
                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                            if (scope.bufDonnees[i].idEntete === event.idEntete) {
                                                d = moment(scope.bufDonnees[i].debut);

                                                if (nbreJours < 0) {
                                                    d.subtract(0 - nbreJours, 'days');
                                                } else {
                                                    d.add(nbreJours, 'days');
                                                }

                                                scope.bufDonnees[i].debut = d.toDate();

                                                if ((!angular.isNullOrUndefined(event.end) && (!angular.isNullOrUndefined(scope.bufDonnees[i].fin)))) {
                                                    f = moment(scope.bufDonnees[i].fin);

                                                    if (nbreJours < 0) {
                                                        f.subtract(0 - nbreJours, 'days');
                                                    } else {
                                                        f.add(nbreJours, 'days');
                                                    }

                                                    scope.bufDonnees[i].fin = f.toDate();
                                                }
                                            }
                                        }

                                        for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                            if (scope.bufDonneesRecurrence[i].idEntete === event.idEntete) {
                                                d = moment(scope.bufDonneesRecurrence[i].debut);

                                                if (nbreJours < 0) {
                                                    d.subtract(0 - nbreJours, 'days');
                                                } else {
                                                    d.add(nbreJours, 'days');
                                                }

                                                scope.bufDonneesRecurrence[i].debut = d.toDate();

                                                if ((!angular.isNullOrUndefined(event.end) && (!angular.isNullOrUndefined(scope.bufDonneesRecurrence[i].fin)))) {
                                                    f = moment(scope.bufDonneesRecurrence[i].fin);

                                                    if (nbreJours < 0) {
                                                        f.subtract(0 - nbreJours, 'days');
                                                    } else {
                                                        f.add(nbreJours, 'days');
                                                    }

                                                    scope.bufDonneesRecurrence[i].fin = f.toDate();
                                                }
                                            }
                                        }
                                    } else {
                                        // Mettre à jour le cache des événements
                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                            if (scope.bufDonnees[i].idGroupe === event.idGroupe) {
                                                if (nbreJours < 0) {
                                                    scope.bufDonnees[i].debut = moment(event.debut).subtract(0 - nbreJours, 'days').toDate();
                                                    if (!angular.isNullOrUndefined(event.fin)) {
                                                        scope.bufDonnees[i].fin = moment(event.fin).subtract(0 - nbreJours, 'days').toDate();
                                                    }
                                                } else if (nbreJours > 0) {
                                                    scope.bufDonnees[i].debut = moment(event.debut).add(nbreJours, 'days').toDate();
                                                    if (!angular.isNullOrUndefined(event.fin)) {
                                                        scope.bufDonnees[i].fin = moment(event.fin).add(nbreJours, 'days').toDate();
                                                    }
                                                } else {
                                                    if (!moment(event.resizeStart).isSame(moment(event.start), 'day')) {
                                                        n = moment().year(event.start.year()).month(event.start.month()).date(event.start.date()).hour(0).minute(0).second(0).millisecond(0);
                                                        d = moment().year(moment(event.debut).year()).month(moment(event.debut).month()).date(moment(event.debut).date()).hour(0).minute(0).second(0).millisecond(0);
                                                        deltaDeb = n.diff(d, 'days');
                                                        if (deltaDeb < 0) {
                                                            scope.bufDonnees[i].debut = moment(event.debut).subtract(0 - deltaDeb, 'days').toDate();
                                                        } else if (deltaDeb > 0) {
                                                            scope.bufDonnees[i].debut = moment(event.debut).add(deltaDeb, 'days').toDate();
                                                        }
                                                    }

                                                    if (!angular.isNullOrUndefined(event.fin)) {
                                                        if (!moment(event.resizeEnd).isSame(moment(event.end), 'day')) {
                                                            n = moment().year(event.end.year()).month(event.end.month()).date(event.end.date()).hour(0).minute(0).second(0).millisecond(0);
                                                            n.subtract(1, 'days'); // Rappel : event.end est le moment juste après l'événement se termine. Si la dernière journée entière d'un événement est jeudi alors le fin exclusive de l'événement sera vendredi 00:00:00 !
                                                            f = moment().year(moment(event.fin).year()).month(moment(event.fin).month()).date(moment(event.fin).date()).hour(0).minute(0).second(0).millisecond(0);
                                                            deltaFin = n.diff(f, 'days');
                                                            if (deltaFin < 0) {
                                                                scope.bufDonnees[i].fin = moment(event.fin).subtract(0 - deltaFin, 'days').toDate();
                                                            } else {
                                                                scope.bufDonnees[i].fin = moment(event.fin).add(deltaFin, 'days').toDate();
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // Mettre à jour le cache des événements récurrents
                                        for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                            if (scope.bufDonneesRecurrence[i].idGroupe === event.idGroupe) {
                                                if (nbreJours < 0) {
                                                    scope.bufDonneesRecurrence[i].debut = moment(event.debut).subtract(0 - nbreJours, 'days').toDate();
                                                    if (!angular.isNullOrUndefined(event.fin)) {
                                                        scope.bufDonneesRecurrence[i].fin = moment(event.fin).subtract(0 - nbreJours, 'days').toDate();
                                                    }
                                                } else if (nbreJours > 0) {
                                                    scope.bufDonneesRecurrence[i].debut = moment(event.debut).add(nbreJours, 'days').toDate();
                                                    if (!angular.isNullOrUndefined(event.fin)) {
                                                        scope.bufDonneesRecurrence[i].fin = moment(event.fin).add(nbreJours, 'days').toDate();
                                                    }
                                                } else {
                                                    if (!moment(event.resizeStart).isSame(moment(event.start), 'day')) {
                                                        n = moment().year(event.start.year()).month(event.start.month()).date(event.start.date()).hour(0).minute(0).second(0).millisecond(0);
                                                        d = moment().year(moment(event.debut).year()).month(moment(event.debut).month()).date(moment(event.debut).date()).hour(0).minute(0).second(0).millisecond(0);
                                                        deltaDeb = n.diff(d, 'days');
                                                        if (deltaDeb < 0) {
                                                            scope.bufDonneesRecurrence[i].debut = moment(event.debut).subtract(0 - deltaDeb, 'days').toDate();
                                                        } else if (deltaDeb > 0) {
                                                            scope.bufDonneesRecurrence[i].debut = moment(event.debut).add(deltaDeb, 'days').toDate();
                                                        }
                                                    }

                                                    if (!angular.isNullOrUndefined(event.fin)) {
                                                        if (!moment(event.resizeEnd).isSame(moment(event.end), 'day')) {
                                                            n = moment().year(event.end.year()).month(event.end.month()).date(event.end.date()).hour(0).minute(0).second(0).millisecond(0);
                                                            n.subtract(1, 'days'); // Rappel : event.end est le moment juste après l'événement se termine. Si la dernière journée entière d'un événement est jeudi alors le fin exclusive de l'événement sera vendredi 00:00:00 !
                                                            f = moment().year(moment(event.fin).year()).month(moment(event.fin).month()).date(moment(event.fin).date()).hour(0).minute(0).second(0).millisecond(0);
                                                            deltaFin = n.diff(f, 'days');
                                                            if (deltaFin < 0) {
                                                                scope.bufDonneesRecurrence[i].fin = moment(event.fin).subtract(0 - deltaFin, 'days').toDate();
                                                            } else {
                                                                scope.bufDonneesRecurrence[i].fin = moment(event.fin).add(deltaFin, 'days').toDate();
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // Marquer les lignes du groupe comme modifiées
                                    for (i = 0; i < scope.bufDonnees.length; i++) {
                                        if (scope.bufDonnees[i].idGroupe === event.idGroupe) {
                                            scope.bufDonnees[i].modifie = true;
                                        }
                                    }

                                    for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                        if (scope.bufDonneesRecurrence[i].idGroupe === event.idGroupe) {
                                            scope.bufDonneesRecurrence[i].modifie = true;
                                        }
                                    }

                                    // Mettre à jour la barre des événements disponibles pour ajout via drag/drop
                                    initListeEvenementDispo(scope.bufDonnees);

                                    // Actualiser le grille et le calendrier
                                    scope.actualiserOpPlanningDepuisCache(false);
                                }
                            };

                            /**
                             * Gestion de sélection en cours
                             * @param {Number} id Id de l'élément à sélectionner/désectionner
                             * @param {Boolean} select Statut de sélection
                             */
                            var gererSelection = function (id, select) {
                                if (!angular.isNullOrUndefined(scope.opSelection)) {
                                    // Retrouver l'élément parmis les données de la recherche en cours
                                    var elem = null;
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                    if ((scope.opNomModule === "Contact") || (scope.opNomModule === "Intervention")) {
                                        for (var i = 0; i < scope.opDonneesRecherche.length; i++) {
                                            if (scope.opDonneesRecherche[i][paramPlanning.colonnesEntete.idEntete].valeur === id) {
                                                elem = scope.opDonneesRecherche[i];
                                                break;
                                            }
                                        }
                                    }

                                    // Si trouvé alors ajouter ou retirer de la sélection
                                    if (!angular.isNullOrUndefined(elem)) {
                                        if (select) {
                                            scope.opSelection.push(elem);
                                        } else {
                                            var idx = scope.opSelection.indexOf(elem);
                                            if (idx != -1) {
                                                scope.opSelection.splice(idx, 1);
                                            }
                                        }

                                        // TODO Mantis 6217 : Déclencher watch pour maj affichage de la sélection en cours
                                        //scope.$applyAsync();
                                    }
                                }
                            };

                            /**
                             * Suppression d'un événement du planning
                             * @param event {Object} Evénement à supprimer
                             * @param ressource {Object} Ressource auqel l'événnement à supprimer est attachée
                             */
                            var supprimerEvenement = function (event, ressource) {
                                var nbre = 0;
                                var idx = -1;
                                var idxRecurrence = -1;
                                var i;

                                if (event.maitre) {
                                    // Suppression de tous les événements récurrentes associés à l'événement maître
                                    if (event.recurrente) {
                                        for (i = scope.bufDonneesRecurrence.length - 1; i >= 0; i--) {
                                            if (scope.bufDonneesRecurrence[i].idEntete === event.idEntete) {
                                                // Mémoriser id des événements maîtres supprimés
                                                if (scope.eventRecurrentSupprimes.indexOf(scope.bufDonneesRecurrence[i].idEntete) === -1) {
                                                    scope.eventRecurrentSupprimes.push(scope.bufDonneesRecurrence[i].idEntete);
                                                }

                                                scope.bufDonneesRecurrence.splice(i, 1);
                                                scope.planningModifie = true;
                                            }
                                        }
                                    }
                                } else {
                                    for (i = 0; i < scope.bufDonnees.length; i++) {
                                        if ((ressource.typeRes === scope.bufDonnees[i].typeRessource) &&
                                            (ressource.idRes === scope.bufDonnees[i].idRessource)) {
                                            nbre++;

                                            if (event.idGroupe === scope.bufDonnees[i].idGroupe) {
                                                idx = i;
                                            }
                                        }
                                    }

                                    for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                        if ((ressource.typeRes === scope.bufDonneesRecurrence[i].typeRessource) &&
                                            (ressource.idRes === scope.bufDonneesRecurrence[i].idRessource) &&
                                            (event.idGroupe === scope.bufDonneesRecurrence[i].idGroupe)) {
                                            idxRecurrence = i;
                                            break;
                                        }
                                    }

                                    if (idx !== -1) {
                                        if (nbre === 1) {
                                            // Ajouter nouvelle ligne vide afin de conserver l'affichage de la ressource
                                            scope.bufDonnees.push({
                                                idEntete: scope.bufDonnees[idx].idEntete,
                                                refEntete: scope.bufDonnees[idx].refEntete,
                                                adresseEntete: scope.bufDonnees[idx].adresseEntete,
                                                refInterneLigne: null,
                                                numeroInterneLigne: null,
                                                creationLigne: null,
                                                typeRessource: scope.bufDonnees[idx].typeRessource,
                                                idRessource: scope.bufDonnees[idx].idRessource,
                                                libRessource: scope.bufDonnees[idx].libRessource,
                                                qteRessource: scope.bufDonnees[idx].qteRessource,
                                                idLigne: null,
                                                idGroupe: null,
                                                refLigne: null,
                                                libLigne: null,
                                                descLigne: null,
                                                debut: null,
                                                fin: null,
                                                debutReel: null,
                                                finReelle: null,
                                                //heureDebut: null,
                                                //heureFin: null,
                                                dureeJours: null,
                                                dureeHeures: null,
                                                avancement: null,
                                                visible: true,
                                                ligneVide: true,
                                                selectionne: false,
                                                estAjout: true,
                                                modifie: false
                                            });
                                        }

                                        // Retirer l'affectation ressource de la ligne
                                        scope.bufDonnees[idx].typeRessource = null;
                                        scope.bufDonnees[idx].idRessource = null;
                                        scope.bufDonnees[idx].libRessource = null;
                                        scope.bufDonnees[idx].qteRessource = null;
                                        scope.planningModifie = true;
                                    }

                                    if (idxRecurrence !== -1) {
                                        if (scope.lignesRecurrentSupprimees.indexOf(event.idGroupe) === -1) {
                                            scope.lignesRecurrentSupprimees.push(scope.bufDonneesRecurrence[idxRecurrence]);
                                        }

                                        // Retirer l'affectation ressource de la ligne récurrente
                                        scope.bufDonneesRecurrence.splice(idxRecurrence, 1);
                                        scope.planningModifie = true;
                                    }
                                }

                                // Marquer les lignes du groupe comme modifiées
                                for (i = 0; i < scope.bufDonnees.length; i++) {
                                    if (scope.bufDonnees[i].idGroupe === event.idGroupe) {
                                        scope.bufDonnees[i].modifie = true;
                                    }
                                }

                                // Mettre à jour la barre des événements disponibles pour ajout via drag/drop
                                initListeEvenementDispo(scope.bufDonnees);

                                // Actualiser le grille et le calendrier
                                scope.actualiserOpPlanningDepuisCache(false);
                            };

                            /**
                             * Suppression d'un événement en base
                             * @param nomEcran {string} Nom de l'écran BD
                             * @param idEcran {Object} Identifiant de l'écran BD
                             * @param idGroupe {Number} idGroupe associé à l'événement à supprimer
                             */
                            var supprimerEvenementBase = function (nomEcran, idEcran, idGroupe) {
                                var deferred = $q.defer();

                                // Ecran BD à utilisr pour la suppression
                                var idEventSupprime = idGroupe;
                                serveur.suppression(scope.opNomModule, scope, nomEcran, idEcran).then(function() {
                                    deferred.resolve(idEventSupprime);
                                }, function () {
                                    deferred.reject();
                                });

                                return deferred.promise;
                            };

                            /**
                             * Calcul des références internes d'un événement
                             * @param evt {Array} Données de l'événement cible
                             * @param numeroEvt {number} Numéro d'événement
                             * @param idEvt {string} Id de l'événement
                             * @returns {*}
                             */
                            var getRefInterneEvenement = function (evt, idEvt, refInterneNomEntete) {
                                var deferred = $q.defer();

                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                // Calcul nouveaux valeurs si ajout
                                if (angular.isNullOrUndefined(idEvt) || (idEvt === "")) {
                                    if ((scope.opTypePlanning === "contact") && (scope.opActionCode === "GERERDISPONIBILITE")) {
                                        var dateCreation = new Date();
                                        var numeroEvt = getNumeroElemParEvenement(evt[0].idEntete);
                                        numeroEvt++;

                                        setNumeroElementParEvenement(evt[0].idEntete, numeroEvt).then(function () {
                                            var refEvt = {};

                                            if (refInterneNomEntete.indexOf("IND_") !== -1) {
                                                refEvt.nomInterne = refInterneNomEntete.replace("IND_", "OCC ") + "/" + numeroEvt.toString();
                                            } else if (refInterneNomEntete.indexOf("IND ") !== -1) {
                                                refEvt.nomInterne = refInterneNomEntete.replace("IND ", "OCC ") + "/" + numeroEvt.toString();
                                            } else {
                                                refEvt.nomInterne = refInterneNomEntete.replace("C ", "OCC ") + "/" + numeroEvt.toString();
                                            }

                                            refEvt.numeroInterne = numeroEvt;
                                            refEvt.dateCreation = dateCreation;
                                            deferred.resolve(refEvt);
                                        }, function () {
                                            deferred.resolve(null);
                                        });
                                    } else if (((scope.opTypePlanning === "tache") && (scope.opActionCode === "GERERTACHE")) ||
                                        ((scope.opTypePlanning === "recurrence") && (scope.opActionCode === "GERERRECURRENCE"))) {
                                        var dateCreation = new Date();
                                        var numeroEvt = getNumeroElemParEvenement(evt[0].idEntete);
                                        numeroEvt++;

                                        setNumeroElementParEvenement(evt[0].idEntete, numeroEvt).then(function () {
                                            var refEvt = {};
                                            refEvt.nomInterne = refInterneNomEntete.replace("INT ", "TAC ") + "/" + numeroEvt.toString();
                                            refEvt.numeroInterne = numeroEvt;
                                            refEvt.dateCreation = dateCreation;
                                            deferred.resolve(refEvt);
                                        }, function () {
                                            deferred.resolve(null);
                                        });
                                    } else {
                                        deferred.resolve(null);
                                    }
                                } else {
                                    deferred.resolve(null);
                                }

                                return deferred.promise;
                            };

                            /**
                             * Sauvegarder d'un événement en base
                             * @param evt {Array} Données de l'événement à sauvegarder
                             * @param idEcran {Object} Identifiant de l'écran BD
                             * @param idEntete {number} Id de l'entête associé à l'événement
                             * @param refInterneNomEntete {string} Référence interne de l'entête associé à l'événement
                             */
                            var sauvegardeEvenementBase = function (evt, idEcran, idEntete, refInterneNomEntete) {
                                var deferred = $q.defer();

                                // Chargement de l'écran BD
                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                if (angular.isNullOrUndefined(paramPlanning.ecranSauvegardeLignes) || (paramPlanning.ecranSauvegardeLignes === "")) {
                                    deferred.resolve();
                                } else {
                                    serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranSauvegardeLignes, idEcran).then(function () {
                                        // Renseignement des données
                                        if ((scope.opTypePlanning === "contact") && (scope.opActionCode === "GERERDISPONIBILITE")) {
                                            if (!angular.isNullOrUndefined(scope.champs[paramPlanning.tableSauvegardeEntete])) {
                                                getRefInterneEvenement(evt, idEcran[paramPlanning.colonnesLigne.idLigne], refInterneNomEntete).then(function (refInterneEvt) {
                                                    // Sauvegarder occupation
                                                    var codeActionPostSauvegarde = (evt[0].modifie) ? 102 : null;

                                                    // Renseigner Id utilisateur si ajout
                                                    if (angular.isNullOrUndefined(idEcran[paramPlanning.colonnesLigne.idLigne]) ||
                                                        (idEcran[paramPlanning.colonnesLigne.idLigne] === "")) {
                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.idCreateurLigne].valeur = conversion.versEntier(serveur.getIdUtilisateur(scope.opNomModule)());
                                                        codeActionPostSauvegarde = 101; // Création occupation
                                                    }

                                                    // Références internes
                                                    if (!angular.isNullOrUndefined(refInterneEvt)) {
                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.refInterneLigne].valeur = refInterneEvt.nomInterne;
                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.numeroInterneLigne].valeur = refInterneEvt.numeroInterne;

                                                        if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.creationLigne].descriptions.type === "DATETIME") {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.creationLigne].valeur = conversion.versDateTime(refInterneEvt.dateCreation);
                                                        } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.creationLigne].descriptions.type === "TIME") {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.creationLigne].valeur = conversion.versTime(refInterneEvt.dateCreation);
                                                        } else {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.creationLigne].valeur = conversion.versDate(refInterneEvt.dateCreation);
                                                        }
                                                    }

                                                    // Entête occupation
                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.idEntete].valeur = idEntete;
                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.refLigne].valeur = evt[0].refLigne;
                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.libLigne].valeur = evt[0].libLigne;
                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.descLigne].valeur = evt[0].descLigne;

                                                    // Dates réelles
                                                    if (!angular.isNullOrUndefined(evt[0].debut)) {
                                                        var debut = moment(evt[0].debut).toDate();

                                                        if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.debut].descriptions.type === "DATETIME") {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.debut].valeur = conversion.versDateTime(debut);
                                                        } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.debut].descriptions.type === "TIME") {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.debut].valeur = conversion.versTime(debut);
                                                        } else {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.debut].valeur = conversion.versDate(debut);
                                                        }
                                                    } else {
                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.debut].valeur = null;
                                                    }

                                                    if (!angular.isNullOrUndefined(evt[0].fin)) {
                                                        var fin = moment(evt[0].fin).toDate();

                                                        if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.fin].descriptions.type === "DATETIME") {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.fin].valeur = conversion.versDateTime(fin);
                                                        } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.fin].descriptions.type === "TIME") {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.fin].valeur = conversion.versTime(fin);
                                                        } else {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.fin].valeur = conversion.versDate(fin);
                                                        }
                                                    } else {
                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.fin].valeur = null;
                                                    }

                                                    // Duration
                                                    //scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.heureDebut].valeur = evt[0].heureDebut;
                                                    //scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.heureFin].valeur = evt[0].heureFin;
                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.dureeJours].valeur = evt[0].dureeJours;
                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesLigne.dureeHeures].valeur = evt[0].dureeHeures;

                                                    // Effectuer la sauvegarde
                                                    scope.sauvegarde[paramPlanning.ecranSauvegardeLignes]().then(function (retourSauvegarde) {
                                                        // TODO : Mettre à jour des statuts et l'historique
                                                        if (!angular.isNullOrUndefined(codeActionPostSauvegarde)) {
                                                            //requetePostSauvegardeFiche.execution(scope, scope.opNomModule, retourSauvegarde, codeActionPostSauvegarde);
                                                        }

                                                        // Résoudre la promise et renvoyer l'ancien et nouveau id
                                                        var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                        var idGroupeApresSauvegarde = parseInt(retourSauvegarde[paramPlanning.colonnesLigne.idLigne]);
                                                        var refInterneLigne = (codeActionPostSauvegarde === 101) ? refInterneEvt.nomInterne : null;
                                                        var numeroInterneLigne = (codeActionPostSauvegarde === 101) ? refInterneEvt.numeroInterne : null;
                                                        var idCreateur = (codeActionPostSauvegarde === 101) ? conversion.versEntier(serveur.getIdUtilisateur(scope.opNomModule)()) : null;
                                                        var dateCreation = (codeActionPostSauvegarde === 101) ? refInterneEvt.dateCreation : null;
                                                        deferred.resolve({
                                                            idGroupeAvantSauvegarde: evt[0].idGroupe,
                                                            idGroupeApresSauvegarde: idGroupeApresSauvegarde,
                                                            idEnteteAvantSauvegarde: evt[0].idEntete,
                                                            idEnteteApresSauvegarde: idEntete,
                                                            refInterneNomEntete: refInterneNomEntete,
                                                            refInterneLigne: refInterneLigne,
                                                            numeroInterneLigne: numeroInterneLigne,
                                                            idCreateur: idCreateur,
                                                            dateCreation: dateCreation
                                                        });
                                                    }, function () {
                                                        deferred.reject();
                                                    });
                                                });
                                            }
                                        } else if (((scope.opTypePlanning === "tache") && (scope.opActionCode === "GERERTACHE")) ||
                                            ((scope.opTypePlanning === "recurrence") && (scope.opActionCode === "GERERRECURRENCE"))) {
                                            if (!angular.isNullOrUndefined(scope.champs.TACHE)) {
                                                // Tâche
                                                getRefInterneEvenement(evt, idEcran[paramPlanning.colonnesLigne.idLigne], refInterneNomEntete).then(function (refInterneEvt) {
                                                    var codeActionPostSauvegarde = (evt[0].modifie) ? 102 : null; // Modification tâche sans mise à jour du statut
                                                    var idxStatutModifie = scope.groupesStatutsLignesModifies.indexOf(evt[0].idGroupe);
                                                    if (idxStatutModifie !== -1) {
                                                        // Modification tâche avec mise à jour du statut
                                                        if (evt[0].statutLigne === "Terminée") {
                                                            codeActionPostSauvegarde = 103;
                                                        } else if (evt[0].statutLigne === "Annulée") {
                                                            codeActionPostSauvegarde = 104;
                                                        } else if ((evt[0].statutLigne === "Réactivée") || (evt[0].statutLigne === "Créée")) {
                                                            codeActionPostSauvegarde = 105;
                                                        }
                                                    }

                                                    // Renseigner Id utilisateur si ajout
                                                    if (angular.isNullOrUndefined(idEcran[paramPlanning.colonnesLigne.idLigne]) ||
                                                        (idEcran[paramPlanning.colonnesLigne.idLigne] === "")) {
                                                        scope.champs.TACHE.TAC_ID_CREATEUR.valeur = conversion.versEntier(serveur.getIdUtilisateur(scope.opNomModule)());
                                                        codeActionPostSauvegarde = 101; // Création tâche
                                                    }

                                                    // Références internes
                                                    if (!angular.isNullOrUndefined(refInterneEvt)) {
                                                        scope.champs.TACHE.TAC_REFINTERNENOM.valeur = refInterneEvt.nomInterne;
                                                        scope.champs.TACHE.TAC_REFINTERNENUMERO.valeur = refInterneEvt.numeroInterne;

                                                        if (scope.champs.TACHE.TAC_DATECREATION.descriptions.type === "DATETIME") {
                                                            scope.champs.TACHE.TAC_DATECREATION.valeur = conversion.versDateTime(refInterneEvt.dateCreation);
                                                        } else if (scope.champs.TACHE.TAC_DATECREATION.descriptions.type === "TIME") {
                                                            scope.champs.TACHE.TAC_DATECREATION.valeur = conversion.versTime(refInterneEvt.dateCreation);
                                                        } else {
                                                            scope.champs.TACHE.TAC_DATECREATION.valeur = conversion.versDate(refInterneEvt.dateCreation);
                                                        }
                                                    }

                                                    // Entête tâche
                                                    scope.champs.TACHE.ID_INTERVENTION.valeur = idEntete;
                                                    scope.champs.TACHE.TAC_CODEOBJET.valeur = evt[0].refLigne;
                                                    scope.champs.TACHE.TAC_OBJET.valeur = evt[0].libLigne;
                                                    scope.champs.TACHE.TAC_DESCRIPTION.valeur = evt[0].descLigne;

                                                    // Dates prévues
                                                    if (!angular.isNullOrUndefined(evt[0].debut)) {
                                                        var debut = moment(evt[0].debut).toDate();

                                                        if (scope.champs.TACHE.TAC_DATEDEBUT_PREV.descriptions.type === "DATETIME") {
                                                            scope.champs.TACHE.TAC_DATEDEBUT_PREV.valeur = conversion.versDateTime(debut);
                                                        } else if (scope.champs.TACHE.TAC_DATEDEBUT_PREV.descriptions.type === "TIME") {
                                                            scope.champs.TACHE.TAC_DATEDEBUT_PREV.valeur = conversion.versTime(debut);
                                                        } else {
                                                            scope.champs.TACHE.TAC_DATEDEBUT_PREV.valeur = conversion.versDate(debut);
                                                        }
                                                    } else {
                                                        scope.champs.TACHE.TAC_DATEDEBUT_PREV.valeur = null;
                                                    }

                                                    if (!angular.isNullOrUndefined(evt[0].fin)) {
                                                        var fin = moment(evt[0].fin).toDate();

                                                        if (scope.champs.TACHE.TAC_DATEFIN_PREV.descriptions.type === "DATETIME") {
                                                            scope.champs.TACHE.TAC_DATEFIN_PREV.valeur = conversion.versDateTime(fin);
                                                        } else if (scope.champs.TACHE.TAC_DATEFIN_PREV.descriptions.type === "TIME") {
                                                            scope.champs.TACHE.TAC_DATEFIN_PREV.valeur = conversion.versTime(fin);
                                                        } else {
                                                            scope.champs.TACHE.TAC_DATEFIN_PREV.valeur = conversion.versDate(fin);
                                                        }
                                                    } else {
                                                        scope.champs.TACHE.TAC_DATEFIN_PREV.valeur = null;
                                                    }

                                                    // Dates réelles
                                                    if (!angular.isNullOrUndefined(evt[0].debutReel)) {
                                                        var debutReel = moment(evt[0].debutReel).toDate();

                                                        if (scope.champs.TACHE.TAC_DATEDEBUT.descriptions.type === "DATETIME") {
                                                            scope.champs.TACHE.TAC_DATEDEBUT.valeur = conversion.versDateTime(debutReel);
                                                        } else if (scope.champs.TACHE.TAC_DATEDEBUT.descriptions.type === "TIME") {
                                                            scope.champs.TACHE.TAC_DATEDEBUT.valeur = conversion.versTime(debutReel);
                                                        } else {
                                                            scope.champs.TACHE.TAC_DATEDEBUT.valeur = conversion.versDate(debutReel);
                                                        }
                                                    } else {
                                                        scope.champs.TACHE.TAC_DATEDEBUT.valeur = null;
                                                    }

                                                    if (!angular.isNullOrUndefined(evt[0].finReelle)) {
                                                        var finReelle = moment(evt[0].finReelle).toDate();

                                                        if (scope.champs.TACHE.TAC_DATEFIN.descriptions.type === "DATETIME") {
                                                            scope.champs.TACHE.TAC_DATEFIN.valeur = conversion.versDateTime(finReelle);
                                                        } else if (scope.champs.TACHE.TAC_DATEFIN.descriptions.type === "TIME") {
                                                            scope.champs.TACHE.TAC_DATEFIN.valeur = conversion.versTime(finReelle);
                                                        } else {
                                                            scope.champs.TACHE.TAC_DATEFIN.valeur = conversion.versDate(finReelle);
                                                        }
                                                    } else {
                                                        scope.champs.TACHE.TAC_DATEFIN.valeur = null;
                                                    }

                                                    // Duration et avancement
                                                    //scope.champs.TACHE.TAC_HEUREDEBUT.valeur = evt[0].heureDebut;
                                                    //scope.champs.TACHE.TAC_HEUREFIN.valeur = evt[0].heureFin;
                                                    scope.champs.TACHE.TAC_DUREEJOUR.valeur = evt[0].dureeJours;
                                                    scope.champs.TACHE.TAC_DUREEHEURE.valeur = evt[0].dureeHeures;
                                                    scope.champs.TACHE.TAC_AVANCEMENT.valeur = evt[0].avancement;

                                                    // Nbre de ressources à sauvegarder
                                                    var nbreRessources = 0;
                                                    for (var i = 0; i < evt.length; i++) {
                                                        if (!angular.isNullOrUndefined(evt[i].idRessource)) {
                                                            nbreRessources++
                                                        }
                                                    }

                                                    for (i = scope.grilles.TAC_INTERVENANT.length - 1; i >= 0; i--) {
                                                        scope.grilles.TAC_INTERVENANT.splice(i, 1);
                                                    }

                                                    for (i = scope.grilles.TAC_MATERIEL.length - 1; i >= 0; i--) {
                                                        scope.grilles.TAC_MATERIEL.splice(i, 1);
                                                    }

                                                    for (i = scope.grilles.TAC_MATERIAU.length - 1; i >= 0; i--) {
                                                        scope.grilles.TAC_MATERIAU.splice(i, 1);
                                                    }

                                                    if (nbreRessources > 0) {
                                                        for (i = 0; i < evt.length; i++) {
                                                            var nomTable = "";
                                                            var champRessource = "";
                                                            var champQteRessource = "";
                                                            var ligneGrille = {};

                                                            if (!angular.isNullOrUndefined(evt[i].idRessource) &&
                                                                ((evt[i].typeRessource === "Intervenant") || (evt[i].typeRessource === "Individu") || (evt[i].typeRessource === "Equipe"))) {
                                                                nomTable = "TAC_INTERVENANT";
                                                                champRessource = "ID_CONTACT";
                                                            } else if (!angular.isNullOrUndefined(evt[i].idRessource) && (evt[i].typeRessource === "Matériel")) {
                                                                nomTable = "TAC_MATERIEL";
                                                                champRessource = "ID_MATERIEL";
                                                            } else if (evt[i].typeRessource === "Matériau") {
                                                                nomTable = "TAC_MATERIAU";
                                                                champRessource = "TAC_MATERIAU_LIB";
                                                                champQteRessource = "TAC_MATERIAU_QTE";
                                                            } else {
                                                                nomTable = "";
                                                                champRessource = "";
                                                            }

                                                            if ((nomTable !== "") && (champRessource !== "")) {
                                                                var idx = -1;
                                                                for (var j = 0; j < scope.grilles[nomTable].length; j++) {
                                                                    if (nomTable === "TAC_MATERIAU") {
                                                                        if (scope.grilles[nomTable][j][champRessource].valeur === evt[i].libRessource) {
                                                                            idx = j;
                                                                            break;
                                                                        }
                                                                    } else {
                                                                        if (scope.grilles[nomTable][j][champRessource].valeur === evt[i].idRessource) {
                                                                            idx = j;
                                                                            break;
                                                                        }
                                                                    }
                                                                }

                                                                // Ressource non trouvée : ajout nouvelle ligne
                                                                if (idx === -1) {
                                                                    ligneGrille = scope.grilles[nomTable].descriptions.creerObjet();

                                                                    if (nomTable === "TAC_MATERIAU") {
                                                                        ligneGrille[champRessource].valeur = evt[i].libRessource;
                                                                    } else {
                                                                        ligneGrille[champRessource].valeur = evt[i].idRessource;
                                                                    }

                                                                    if (champQteRessource !== "") {
                                                                        ligneGrille[champQteRessource].valeur = evt[i].qteRessource;
                                                                    }

                                                                    if (((scope.opTypePlanning === "tache") && (scope.opActionCode === "GERERTACHE")) && !evt[i].estAjout) {
                                                                        ligneGrille.ID_TACHE.valeur = evt[i].idGroupe;
                                                                    }

                                                                    scope.grilles[nomTable].push(ligneGrille);
                                                                }
                                                            }
                                                        }

                                                        // Effectuer la sauvegarde
                                                        scope.sauvegarde[paramPlanning.ecranSauvegardeLignes]().then(function (retourSauvegarde) {
                                                            // Mettre à jour des statuts et l'historique
                                                            if (!angular.isNullOrUndefined(codeActionPostSauvegarde)) {
                                                                requetePostSauvegardeFiche.execution(scope, scope.opNomModule, null,retourSauvegarde, codeActionPostSauvegarde);

                                                                // Modification du statut du groupe traitée
                                                                if (idxStatutModifie !== -1) {
                                                                    scope.groupesStatutsLignesModifies.splice(idxStatutModifie, 1);
                                                                }
                                                            }

                                                            // Résoudre la promise et renvoyer l'ancien et nouveau id
                                                            var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                            var idGroupeApresSauvegarde = parseInt(retourSauvegarde[paramPlanning.colonnesLigne.idLigne]);
                                                            var refInterneLigne = (codeActionPostSauvegarde === 101) ? refInterneEvt.nomInterne : null;
                                                            var numeroInterneLigne = (codeActionPostSauvegarde === 101) ? refInterneEvt.numeroInterne : null;
                                                            var idCreateur = (codeActionPostSauvegarde === 101) ? conversion.versEntier(serveur.getIdUtilisateur(scope.opNomModule)()) : null;
                                                            var dateCreation = (codeActionPostSauvegarde === 101) ? refInterneEvt.dateCreation : null;
                                                            deferred.resolve({
                                                                idGroupeAvantSauvegarde: evt[0].idGroupe,
                                                                idGroupeApresSauvegarde: idGroupeApresSauvegarde,
                                                                idEnteteAvantSauvegarde: evt[0].idEntete,
                                                                idEnteteApresSauvegarde: idEntete,
                                                                refInterneNomEntete: refInterneNomEntete,
                                                                refInterneLigne: refInterneLigne,
                                                                numeroInterneLigne: numeroInterneLigne,
                                                                idCreateur: idCreateur,
                                                                dateCreation: dateCreation
                                                            });
                                                        }, function () {
                                                            deferred.reject();
                                                        });
                                                    } else {
                                                        // Effectuer la sauvegarde
                                                        scope.sauvegarde[paramPlanning.ecranSauvegardeLignes]().then(function (retourSauvegarde) {
                                                            // Mettre à jour des statuts et l'historique
                                                            requetePostSauvegardeFiche.execution(scope, scope.opNomModule, null, retourSauvegarde, codeActionPostSauvegarde);

                                                            // Résoudre la promise et renvoyer l'ancien et nouveau id
                                                            var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                            var idGroupeApresSauvegarde = parseInt(retourSauvegarde[paramPlanning.colonnesLigne.idLigne]);
                                                            var refInterneLigne = (codeActionPostSauvegarde === 101) ? refInterneEvt.nomInterne : null;
                                                            var numeroInterneLigne = (codeActionPostSauvegarde === 101) ? refInterneEvt.numeroInterne : null;
                                                            var idCreateur = (codeActionPostSauvegarde === 101) ? conversion.versEntier(serveur.getIdUtilisateur(scope.opNomModule)()) : null;
                                                            var dateCreation = (codeActionPostSauvegarde === 101) ? refInterneEvt.dateCreation : null;
                                                            deferred.resolve({
                                                                idGroupeAvantSauvegarde: evt[0].idGroupe,
                                                                idGroupeApresSauvegarde: idGroupeApresSauvegarde,
                                                                idEnteteAvantSauvegarde: evt[0].idEntete,
                                                                idEnteteApresSauvegarde: idEntete,
                                                                refInterneNomEntete: refInterneNomEntete,
                                                                refInterneLigne: refInterneLigne,
                                                                numeroInterneLigne: numeroInterneLigne,
                                                                idCreateur: idCreateur,
                                                                dateCreation: dateCreation
                                                            });
                                                        }, function () {
                                                            deferred.reject();
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }

                                return deferred.promise;
                            };

                            var getNumeroRecurrenceSuivant = function (recNumero) {
                                var deferred = $q.defer();

                                if (angular.isNullOrUndefined(recNumero) || (recNumero === -1)) {
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                    serveur.chargement(scope.opNomModule, scope, paramPlanning.ecranRechercheNumeroRecurrenceSuivant, null).then(function () {
                                        scope.recherche[paramPlanning.ecranRechercheNumeroRecurrenceSuivant](1).then(function (retourRecherche) {
                                            var prochainNumero = scope.grilles[retourRecherche[0][0]][0].NUMERO.valeur;
                                            deferred.resolve(prochainNumero);
                                        }, function () {
                                            deferred.reject();
                                        });
                                    }, function () {
                                        deferred.reject();
                                    });
                                } else {
                                    deferred.resolve(recNumero);
                                }

                                return deferred.promise;
                            };

                            /**
                             * Sauvegarder l'entête d'un événement en base
                             * @param e {Object} Données de l'entête à sauvegarder
                             */
                            var sauvegardeEnteteBase = function (e) {
                                var deferred = $q.defer();

                                // Chargement de l'écran BD
                                if (!angular.isNullOrUndefined(e.id)) {
                                    var debut, fin;
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                    if (angular.isNullOrUndefined(paramPlanning.ecranSauvegardeEntete) || (paramPlanning.ecranSauvegardeEntete === "")) {
                                        deferred.resolve();
                                    } else {
                                        var id = {};
                                        id[paramPlanning.colonnesEntete.idEntete] = e.id;
                                        serveur.chargement(e.nomModule, scope, paramPlanning.ecranSauvegardeEntete, id).then(function () {
                                            if (e.typePlanning === "tache") {
                                                if (e.actionCode === "GERERTACHE") {
                                                    if (!angular.isNullOrUndefined(scope.champs[paramPlanning.tableSauvegardeEntete])) {
                                                        // Début d'intervention prévu
                                                        if (!angular.isNullOrUndefined(e.debut)) {
                                                            debut = moment(e.debut).toDate();

                                                            if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].descriptions.type === "DATETIME") {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versDateTime(debut);
                                                            } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].descriptions.type === "TIME") {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versTime(debut);
                                                            } else {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versDate(debut);
                                                            }
                                                        } else {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = null;
                                                        }

                                                        // Fin d'intervention prévue
                                                        if (!angular.isNullOrUndefined(e.fin)) {
                                                            fin = moment(e.fin).toDate();

                                                            if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].descriptions.type === "DATETIME") {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versDateTime(fin);
                                                            } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].descriptions.type === "TIME") {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versTime(fin);
                                                            } else {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versDate(fin);
                                                            }
                                                        } else {
                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = null;
                                                        }

                                                        // Effectuer la sauvegarde
                                                        var codeActionPostSauvegarde = (e.id === "") ? 1 : 2;
                                                        scope.sauvegarde[paramPlanning.ecranSauvegardeEntete]().then(function (retourSauvegarde) {
                                                            // Mettre à jour des statuts et l'historique
                                                            requetePostSauvegardeFiche.execution(scope, scope.opNomModule, null, retourSauvegarde, codeActionPostSauvegarde);
                                                            deferred.resolve(retourSauvegarde);
                                                        }, function (erreurSauvegarde) {
                                                            deferred.reject(erreurSauvegarde);
                                                        });
                                                    }
                                                }
                                            } else if (e.typePlanning === "recurrence") {
                                                if (e.actionCode === "GERERRECURRENCE") {
                                                    // Sauvegarde en base des interventions récurrentes
                                                    if (!angular.isNullOrUndefined(scope.champs[paramPlanning.tableSauvegardeEntete])) {
                                                        // Calcul le numéro de référence de la récurrence
                                                        //getNumeroRecurrenceSuivant(e.recNumero).then(function (recNumero) {
                                                        if (e.calcNumeroEntete) {
                                                            // Calcul du prochain numero pour la référence interne
                                                            referenceInterne.rechercheNumeroInterne("Intervention", scope, paramPlanning.ecranRechercheNumeroEntete, "Intervention").then(function (retourRechercheNumeroInterne) {
                                                                    var dateCreation = new Date();
                                                                    var an = dateCreation.getFullYear();
                                                                    var mois = dateCreation.getMonth() + 1;

                                                                    // Id utilisateur
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.idCreateurEntete].valeur = conversion.versEntier(serveur.getIdUtilisateur(scope.opNomModule)());

                                                                    // Date de création
                                                                    if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.creationEntete].descriptions.type === "DATETIME") {
                                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.creationEntete].valeur = conversion.versDateTime(dateCreation);
                                                                    } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.creationEntete].descriptions.type === "TIME") {
                                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.creationEntete].valeur = conversion.versTime(dateCreation);
                                                                    } else {
                                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.creationEntete].valeur = conversion.versDate(dateCreation);
                                                                    }

                                                                    // Numéro et référence d'intervention
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.numeroEntete].valeur = retourRechercheNumeroInterne;
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.refEntete].valeur = 'INT ' + an + '-' + retourRechercheNumeroInterne.toString();

                                                                    // Objet de l'intervention
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.objetEntete].valeur = e.objet;

                                                                    // Début d'intervention prévu
                                                                    if (!angular.isNullOrUndefined(e.debut)) {
                                                                        debut = moment(e.debut).toDate();

                                                                        if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].descriptions.type === "DATETIME") {
                                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versDateTime(debut);
                                                                        } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].descriptions.type === "TIME") {
                                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versTime(debut);
                                                                        } else {
                                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versDate(debut);
                                                                        }
                                                                    } else {
                                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = null;
                                                                    }

                                                                    // Fin d'intervention prévue
                                                                    if (!angular.isNullOrUndefined(e.fin)) {
                                                                        fin = moment(e.fin).toDate();

                                                                        if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].descriptions.type === "DATETIME") {
                                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versDateTime(fin);
                                                                        } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].descriptions.type === "TIME") {
                                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versTime(fin);
                                                                        } else {
                                                                            scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versDate(fin);
                                                                        }
                                                                    } else {
                                                                        scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = null;
                                                                    }

                                                                    // Récurrence
                                                                    if (scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence].length > 0) {
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceNumero].valeur = e.recNumero;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceType].valeur = e.recType;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceIntervalle].valeur = e.recIntervalle;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = e.recDateLimite;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceLundi].valeur = e.recLundi;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceMardi].valeur = e.recMardi;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceMercredi].valeur = e.recMercredi;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceJeudi].valeur = e.recJeudi;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceVendredi].valeur = e.recVendredi;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceSamedi].valeur = e.recSamedi;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceDimanche].valeur = e.recDimanche;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur = e.recJourDuMois;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceMois].valeur = e.recMois;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceOuvre].valeur = e.recOuvre;
                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceFerie].valeur = e.recFerie;
                                                                    } else {
                                                                        var ligneGrille = scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence].descriptions.creerObjet();
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceNumero].valeur = e.recNumero;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceType].valeur = e.recType;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceIntervalle].valeur = e.recIntervalle;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = e.recDateLimite;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceLundi].valeur = e.recLundi;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceMardi].valeur = e.recMardi;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceMercredi].valeur = e.recMercredi;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceJeudi].valeur = e.recJeudi;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceVendredi].valeur = e.recVendredi;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceSamedi].valeur = e.recSamedi;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceDimanche].valeur = e.recDimanche;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur = e.recJourDuMois;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceMois].valeur = e.recMois;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceOuvre].valeur = e.recOuvre;
                                                                        ligneGrille[paramPlanning.colonnesEntete.recurrenceFerie].valeur = e.recFerie;

                                                                        scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence].push(ligneGrille);
                                                                    }

                                                                    // Effectuer la sauvegarde
                                                                    var lignes = e.lignes;
                                                                    var refEntete = scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.refEntete].valeur;
                                                                    var recNumero = e.recNumero;
                                                                    scope.sauvegarde[paramPlanning.ecranSauvegardeEntete]().then(function (retourSauvegarde) {
                                                                        // Mettre à jour des statuts et l'historique
                                                                        requetePostSauvegardeFiche.execution(scope, scope.opNomModule, null, retourSauvegarde, 1);
                                                                        deferred.resolve({
                                                                            retourSauvegardeEntete: retourSauvegarde,
                                                                            refInterneNomEntete: refEntete,
                                                                            recNnumero: recNumero,
                                                                            lignes: lignes
                                                                        });
                                                                    }, function (erreurSauvegarde) {
                                                                        deferred.reject(erreurSauvegarde);
                                                                    });
                                                                })
                                                                .catch(function (erreur) {
                                                                    deferred.reject(erreur);
                                                                });
                                                        } else {
                                                            // Début d'intervention prévu
                                                            if (!angular.isNullOrUndefined(e.debut)) {
                                                                debut = moment(e.debut).toDate();

                                                                if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].descriptions.type === "DATETIME") {
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versDateTime(debut);
                                                                } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].descriptions.type === "TIME") {
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versTime(debut);
                                                                } else {
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = conversion.versDate(debut);
                                                                }
                                                            } else {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.debutEntete].valeur = null;
                                                            }

                                                            // Fin d'intervention prévue
                                                            if (!angular.isNullOrUndefined(e.fin)) {
                                                                fin = moment(e.fin).toDate();

                                                                if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].descriptions.type === "DATETIME") {
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versDateTime(fin);
                                                                } else if (scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].descriptions.type === "TIME") {
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versTime(fin);
                                                                } else {
                                                                    scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = conversion.versDate(fin);
                                                                }
                                                            } else {
                                                                scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.finEntete].valeur = null;
                                                            }

                                                            // Récurrence
                                                            if (scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence].length > 0) {
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceNumero].valeur = e.recNumero;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceType].valeur = e.recType;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceIntervalle].valeur = e.recIntervalle;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = e.recDateLimite;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceLundi].valeur = e.recLundi;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceMardi].valeur = e.recMardi;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceMercredi].valeur = e.recMercredi;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceJeudi].valeur = e.recJeudi;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceVendredi].valeur = e.recVendredi;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceSamedi].valeur = e.recSamedi;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceDimanche].valeur = e.recDimanche;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur = e.recJourDuMois;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceMois].valeur = e.recMois;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceOuvre].valeur = e.recOuvre;
                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence][0][paramPlanning.colonnesEntete.recurrenceFerie].valeur = e.recFerie;
                                                            } else {
                                                                var ligneGrille = scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence].descriptions.creerObjet();
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceNumero].valeur = e.recNumero;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceType].valeur = e.recType;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceIntervalle].valeur = e.recIntervalle;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = e.recDateLimite;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceLundi].valeur = e.recLundi;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceMardi].valeur = e.recMardi;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceMercredi].valeur = e.recMercredi;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceJeudi].valeur = e.recJeudi;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceVendredi].valeur = e.recVendredi;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceSamedi].valeur = e.recSamedi;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceDimanche].valeur = e.recDimanche;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur = e.recJourDuMois;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceMois].valeur = e.recMois;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceOuvre].valeur = e.recOuvre;
                                                                ligneGrille[paramPlanning.colonnesEntete.recurrenceFerie].valeur = e.recFerie;

                                                                scope.grilles[paramPlanning.tableSauvegardeEnteteRecurrence].push(ligneGrille);
                                                            }

                                                            // Effectuer la sauvegarde
                                                            var lignes = e.lignes;
                                                            var refEntete = scope.champs[paramPlanning.tableSauvegardeEntete][paramPlanning.colonnesEntete.refEntete].valeur;
                                                            var recNumero = e.recNumero;
                                                            scope.sauvegarde[paramPlanning.ecranSauvegardeEntete]().then(function (retourSauvegarde) {
                                                                // Mettre à jour des statuts et l'historique
                                                                requetePostSauvegardeFiche.execution(scope, scope.opNomModule, null, retourSauvegarde, 2);
                                                                deferred.resolve({
                                                                    retourSauvegardeEntete: retourSauvegarde,
                                                                    refInterneNomEntete: refEntete,
                                                                    recNnumero: recNumero,
                                                                    lignes: lignes
                                                                });
                                                            }, function (erreurSauvegarde) {
                                                                deferred.reject(erreurSauvegarde);
                                                            });
                                                        }/*
                                                         }, function () {
                                                         deferred.reject();
                                                         });*/
                                                    }
                                                }
                                            }
                                        });
                                    }
                                } else {
                                    deferred.reject();
                                }

                                return deferred.promise;
                            };

                            /**
                             * Sauvegarder un événement récurrent en base
                             * @param evt {Object} Objet événement (entête et lignes) à sauvegarder
                             */
                            var sauvegarderEventRecurrentBase = function (evt) {
                                var deferred = $q.defer();

                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                // Sauvegarder l'entête
                                var dateLimite = scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur;
                                var existeRecurrence = (!angular.isNullOrUndefined(dateLimite));
                                sauvegardeEnteteBase({
                                    nomModule: scope.opNomModule,
                                    typePlanning: scope.opTypePlanning,
                                    actionCode: scope.opActionCode,
                                    id: evt.id,
                                    debut: evt.debut,
                                    fin: evt.fin,
                                    objet: evt.objet,
                                    calcNumeroEntete: (evt.id === ""), //true,
                                    recNumero: evt.recNumero,
                                    recType: (!existeRecurrence) ? "Jour" : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceType].valeur,
                                    recIntervalle: (!existeRecurrence) ? 1 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceIntervalle].valeur,
                                    recDateLimite: (!existeRecurrence) ? null : dateLimite,
                                    recLundi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceLundi].valeur,
                                    recMardi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMardi].valeur,
                                    recMercredi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMercredi].valeur,
                                    recJeudi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJeudi].valeur,
                                    recVendredi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceVendredi].valeur,
                                    recSamedi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceSamedi].valeur,
                                    recDimanche: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDimanche].valeur,
                                    recJourDuMois: (!existeRecurrence) ? 1 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur,
                                    recMois: (!existeRecurrence) ? 1 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMois].valeur,
                                    recOuvre: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceOuvre].valeur,
                                    recFerie: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceFerie].valeur,
                                    lignes: evt.lignes
                                }).then(function (retourSauvegarde) {
                                    // Id de l'entête des lignes
                                    var rs = retourSauvegarde;
                                    var idEnteteSauvegarde = parseInt(rs.retourSauvegardeEntete[paramPlanning.colonnesEntete.idEntete]);
                                    var refInterneNomEntete = rs.refInterneNomEntete;

                                    // Liste des groupes à traiter
                                    var groupes = [];
                                    for (var idxLigne = 0; idxLigne < rs.lignes.length; idxLigne++) {
                                        if (groupes.indexOf(rs.lignes[idxLigne].idGroupe) === -1) {
                                            groupes.push(rs.lignes[idxLigne].idGroupe);
                                        }
                                    }

                                    var listePromesses = [];
                                    for (var idxGroupe = 0; idxGroupe < groupes.length; idxGroupe++) {
                                        var l = [];
                                        for (idxLigne = 0; idxLigne < rs.lignes.length; idxLigne++) {
                                            if (rs.lignes[idxLigne].idGroupe === groupes[idxGroupe]) {
                                                l.push(rs.lignes[idxLigne]);
                                            }
                                        }

                                        if (l.length > 0) {
                                            // Identifiant écran BD pour la sauvegarde
                                            var idFormulaire = {};
                                            idFormulaire[paramPlanning.colonnesLigne.idGroupe] = (l[0].idEntete > 0) ? Number(l[0].idGroupe) : "";

                                            // Sauvegarder les lignes
                                            listePromesses.push(sauvegardeEvenementBase(l, idFormulaire, idEnteteSauvegarde, refInterneNomEntete));
                                        }
                                    }

                                    $q.all(listePromesses).then(function (retourSauvegarde) {
                                        // Mettre à jour le cache des données récurrentes
                                        for (var i = 0; i < retourSauvegarde.length; i++) {
                                            var idLigne = retourSauvegarde[i];
                                            if (idLigne.idEnteteApresSauvegarde !== idLigne.idEnteteAvantSauvegarde) {
                                                for (var j = 0; j < scope.bufDonneesRecurrence.length; j++) {
                                                    if (scope.bufDonneesRecurrence[j].idGroupe === idLigne.idGroupeAvantSauvegarde) {
                                                        scope.bufDonneesRecurrence[j].idEntete = idLigne.idEnteteApresSauvegarde;
                                                        scope.bufDonneesRecurrence[j].refEntete = idLigne.refInterneNomEntete;
                                                        scope.bufDonneesRecurrence[j].idGroupe = idLigne.idGroupeApresSauvegarde;
                                                        scope.bufDonneesRecurrence[j].idLigne = idLigne.idGroupeApresSauvegarde;

                                                        if (!angular.isNullOrUndefined(idLigne.refInterneLigne)) {
                                                            scope.bufDonneesRecurrence[j].refInterneLigne = idLigne.refInterneLigne;
                                                        }

                                                        if (!angular.isNullOrUndefined(idLigne.numeroInterneLigne)) {
                                                            scope.bufDonneesRecurrence[j].numeroInterneLigne = idLigne.numeroInterneLigne;
                                                        }

                                                        if (!angular.isNullOrUndefined(idLigne.idCreateur)) {
                                                            scope.bufDonneesRecurrence[j].idCreateurLigne = idLigne.idCreateur;
                                                        }

                                                        if (!angular.isNullOrUndefined(idLigne.dateCreation)) {
                                                            scope.bufDonneesRecurrence[j].creationLigne = idLigne.dateCreation;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        // Actualiser le planning sans rechargement depuis la base de données
                                        scope.actualiserOpPlanningDepuisCache();
                                        deferred.resolve();
                                    }, function (erreurSauvegarde) {
                                        deferred.reject(erreurSauvegarde);
                                    });
                                }).then(function () {
                                    deferred.resolve();
                                }, function (erreurSauvegarde) {
                                    deferred.reject(erreurSauvegarde);
                                });

                                return deferred.promise;
                            };

                            /**
                             * Sauvegarder le planning
                             */
                            scope.sauvegardePlanning = function () {
                                var deferred = $q.defer();

                                var lignesModif = [];
                                var lignesAjout = [];
                                var e = [];
                                var i, j, f, idFormulaire, idEntete, debEntete, finEntete;

                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                if (scope.opTypePlanning === "contact") {
                                    if (scope.opActionCode === "GERERDISPONIBILITE") {
                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                            // Préparation des listes des lignes ajoutées et modifiées
                                            if (!scope.bufDonnees[i].ligneVide && scope.bufDonnees[i].estAjout) {
                                                lignesAjout.push(scope.bufDonnees[i]);
                                            } else if (!scope.bufDonnees[i].ligneVide && !scope.bufDonnees[i].estAjout && scope.bufDonnees[i].modifie) {
                                                lignesModif.push(scope.bufDonnees[i]);
                                            }
                                        }

                                        // Modifier événements
                                        for (i = 0; i < lignesModif.length; i++) {
                                            // Identifiant écran BD pour la sauvegarde
                                            idFormulaire = {};
                                            idFormulaire[paramPlanning.colonnesLigne.idEntete] = Number(lignesModif[i].idEntete);
                                            idFormulaire[paramPlanning.colonnesLigne.idLigne] = Number(lignesModif[i].idGroupe);

                                            // Sauvegarder les modifications
                                            var m = [];
                                            m.push(lignesModif[i]);
                                            sauvegardeEvenementBase(m, idFormulaire, lignesModif[i].idEntete, lignesModif[i].refEntete).then(function (retourSauvegarde) {
                                                var idGroupeAvantSauvegarde = retourSauvegarde.idGroupeAvantSauvegarde;
                                                var idGroupeApresSauvegarde = retourSauvegarde.idGroupeApresSauvegarde;
                                                for (var k = 0; k < scope.bufDonnees.length; k++) {
                                                    // Modification pris en compte
                                                    if (scope.bufDonnees[k].idGroupe === idGroupeAvantSauvegarde) {
                                                        scope.bufDonnees[k].idGroupe = idGroupeApresSauvegarde;
                                                        scope.bufDonnees[k].modifie = false;
                                                    }
                                                }
                                            });
                                        }

                                        // Ajouter événements
                                        for (i = 0; i < lignesAjout.length; i++) {
                                            // Identifiant écran BD pour la sauvegarde
                                            idFormulaire = {};
                                            idFormulaire[paramPlanning.colonnesLigne.idEntete] = Number(lignesAjout[i].idEntete);
                                            idFormulaire[paramPlanning.colonnesLigne.idLigne] = "";

                                            // Ajouter l'événement
                                            var a = [];
                                            a.push(lignesAjout[i]);
                                            sauvegardeEvenementBase(a, idFormulaire, lignesAjout[i].idEntete, lignesAjout[i].refEntete).then(function (retourSauvegarde) {
                                                var idGroupeAvantSauvegarde = retourSauvegarde.idGroupeAvantSauvegarde;
                                                var idGroupeApresSauvegarde = retourSauvegarde.idGroupeApresSauvegarde;
                                                for (var l = 0; l < scope.bufDonnees.length; l++) {
                                                    // Ajout pris en compte
                                                    if (scope.bufDonnees[l].idGroupe === idGroupeAvantSauvegarde) {
                                                        scope.bufDonnees[l].idGroupe = idGroupeApresSauvegarde;
                                                        scope.bufDonnees[l].estAjout = false;
                                                        scope.bufDonnees[l].modifie = false;
                                                    }
                                                }
                                            });
                                        }

                                        // Supprimer les événements
                                        var s = scope.eventSupprimes;
                                        for (i = 0; i < s.length; i++) {
                                            // Identifiant écran BD pour la suppression
                                            idFormulaire = {};
                                            idFormulaire[paramPlanning.colonnesLigne.idEntete] = Number(s[i].data.element.idEntete);
                                            idFormulaire[paramPlanning.colonnesLigne.idLigne] = Number(s[i].idGroupe);

                                            // Supprimer l'événement
                                            supprimerEvenementBase(paramPlanning.ecranSauvegardeLignes, idFormulaire, s[i].idGroupe).then(function (retourSuppression) {
                                                // Retirer du tableau des événements à supprimer
                                                for (var k = 0; k < scope.eventSupprimes.length; k++) {
                                                    if (scope.eventSupprimes[k].idGroupe === retourSuppression) {
                                                        scope.eventSupprimes.splice(k, 1);
                                                        break;
                                                    }
                                                }
                                            });
                                        }

                                        deferred.resolve();
                                    }
                                } else if (scope.opTypePlanning === "tache") {
                                    if (scope.opActionCode === "GERERTACHE") {
                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                            // Préparation des listes des lignes ajoutées et modifiées
                                            if (!scope.bufDonnees[i].ligneVide && scope.bufDonnees[i].estAjout) {
                                                lignesAjout.push(scope.bufDonnees[i]);
                                            } else if (!scope.bufDonnees[i].ligneVide && !scope.bufDonnees[i].estAjout && scope.bufDonnees[i].modifie) {
                                                lignesModif.push(scope.bufDonnees[i]);
                                            }

                                            // Identifiant de l'entête
                                            if (angular.isNullOrUndefined(idEntete) && !angular.isNullOrUndefined(scope.bufDonnees[i].idEntete)) {
                                                idEntete = scope.bufDonnees[i].idEntete;
                                            }

                                            // Date de début d'entête prévu
                                            if (angular.isNullOrUndefined(debEntete)) {
                                                if (!angular.isNullOrUndefined(scope.bufDonnees[i].debut)) {
                                                    debEntete = moment(scope.bufDonnees[i].debut);
                                                }
                                            } else if (!angular.isNullOrUndefined(scope.bufDonnees[i].debut)) {
                                                if (moment(scope.bufDonnees[i].debut).isBefore(debEntete, 'day')) {
                                                    debEntete = moment(scope.bufDonnees[i].debut);
                                                }
                                            }

                                            // Date de fin d'entête prévue
                                            if (angular.isNullOrUndefined(finEntete)) {
                                                if (!angular.isNullOrUndefined(scope.bufDonnees[i].fin)) {
                                                    finEntete = moment(scope.bufDonnees[i].fin);
                                                    finEntete.subtract(1, 'days');
                                                }
                                            } else if (!angular.isNullOrUndefined(scope.bufDonnees[i].fin)) {
                                                f = moment(scope.bufDonnees[i].fin);
                                                f.subtract(1, 'days');
                                                if (f.isAfter(finEntete, 'day')) {
                                                    finEntete = f;
                                                }
                                            }
                                        }

                                        // Modifier événements
                                        for (i = 0; i < scope.eventDisponibles.length; i++) {
                                            e = [];
                                            for (j = 0; j < lignesModif.length; j++) {
                                                if (lignesModif[j].idGroupe === scope.eventDisponibles[i].idGroupe) {
                                                    e.push(lignesModif[j]);
                                                }
                                            }

                                            if (e.length > 0) {
                                                // Identifiant écran BD pour la sauvegarde
                                                idFormulaire = {};
                                                idFormulaire[paramPlanning.colonnesLigne.idLigne] = Number(e[0].idGroupe);

                                                // Sauvegarder les modifications
                                                sauvegardeEvenementBase(e, idFormulaire, e[0].idEntete, e[0].refEntete).then(function (retourSauvegarde) {
                                                    var idGroupeAvantSauvegarde = retourSauvegarde.idGroupeAvantSauvegarde;
                                                    var idGroupeApresSauvegarde = retourSauvegarde.idGroupeApresSauvegarde;
                                                    for (var k = 0; k < scope.bufDonnees.length; k++) {
                                                        // Modification pris en compte
                                                        if (scope.bufDonnees[k].idGroupe === idGroupeAvantSauvegarde) {
                                                            scope.bufDonnees[k].idGroupe = idGroupeApresSauvegarde;
                                                            scope.bufDonnees[k].modifie = false;
                                                        }
                                                    }
                                                });
                                            }
                                        }

                                        // Ajouter événements
                                        for (i = 0; i < scope.eventDisponibles.length; i++) {
                                            e = [];
                                            for (j = 0; j < lignesAjout.length; j++) {
                                                if (lignesAjout[j].idGroupe === scope.eventDisponibles[i].idGroupe) {
                                                    e.push(lignesAjout[j]);
                                                }
                                            }

                                            if (e.length > 0) {
                                                // Identifiant écran BD pour la sauvegarde
                                                idFormulaire = {};
                                                idFormulaire[paramPlanning.colonnesLigne.idLigne] = "";

                                                // Ajouter l'événement
                                                sauvegardeEvenementBase(e, idFormulaire, e[0].idEntete, e[0].refEntete).then(function (retourSauvegarde) {
                                                    var idGroupeAvantSauvegarde = retourSauvegarde.idGroupeAvantSauvegarde;
                                                    var idGroupeApresSauvegarde = retourSauvegarde.idGroupeApresSauvegarde;
                                                    for (var k = 0; k < scope.bufDonnees.length; k++) {
                                                        // Ajout pris en compte
                                                        if (scope.bufDonnees[k].idGroupe === idGroupeAvantSauvegarde) {
                                                            scope.bufDonnees[k].idGroupe = idGroupeApresSauvegarde;
                                                            scope.bufDonnees[k].estAjout = false;
                                                            scope.bufDonnees[k].modifie = false;
                                                        }
                                                    }
                                                });
                                            }
                                        }

                                        // Supprimer les événements
                                        var s = scope.eventSupprimes;
                                        for (i = 0; i < s.length; i++) {
                                            // Identifiant écran BD pour la suppression
                                            idFormulaire = {};
                                            idFormulaire[paramPlanning.colonnesLigne.idLigne] = Number(s[i].idGroupe);

                                            // Supprimer l'événement
                                            supprimerEvenementBase(paramPlanning.ecranSauvegardeLignes, idFormulaire, s[i].idGroupe).then(function (retourSuppression) {
                                                // Retirer du tableau des événements à supprimer
                                                for (var k = 0; k < scope.eventSupprimes.length; k++) {
                                                    if (scope.eventSupprimes[k].idGroupe === retourSuppression) {
                                                        scope.eventSupprimes.splice(k, 1);
                                                        break;
                                                    }
                                                }
                                            });
                                        }

                                        // Enregistrer informations de l'entête
                                        sauvegardeEnteteBase({
                                            nomModule: scope.opNomModule,
                                            typePlanning: scope.opTypePlanning,
                                            actionCode: scope.opActionCode,
                                            id: idEntete,
                                            debut: debEntete,
                                            fin: finEntete,
                                            calcNumeroEntete: false
                                        }).then(function () {
                                            deferred.resolve();
                                        }, function (erreurSauvegarde) {
                                            deferred.reject(erreurSauvegarde);
                                        });
                                    }
                                } else if (scope.opTypePlanning === "recurrence") {
                                    if (scope.opActionCode === "GERERRECURRENCE") {
                                        // Préparation pour la sauvegarde de l'élément de référence
                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                            if (!scope.bufDonnees[i].ligneVide) {
                                                lignesModif.push(scope.bufDonnees[i]);
                                            }

                                            // Identifiant de l'entête
                                            if (angular.isNullOrUndefined(idEntete) && !angular.isNullOrUndefined(scope.bufDonnees[i].idEntete)) {
                                                idEntete = scope.bufDonnees[i].idEntete;
                                            }

                                            // Date de début d'entête prévu
                                            if (angular.isNullOrUndefined(debEntete)) {
                                                if (!angular.isNullOrUndefined(scope.bufDonnees[i].debut)) {
                                                    debEntete = moment(scope.bufDonnees[i].debut);
                                                }
                                            } else if (!angular.isNullOrUndefined(scope.bufDonnees[i].debut)) {
                                                if (moment(scope.bufDonnees[i].debut).isBefore(debEntete, 'day')) {
                                                    debEntete = moment(scope.bufDonnees[i].debut);
                                                }
                                            }

                                            // Date de fin d'entête prévue
                                            if (angular.isNullOrUndefined(finEntete)) {
                                                if (!angular.isNullOrUndefined(scope.bufDonnees[i].fin)) {
                                                    finEntete = moment(scope.bufDonnees[i].fin);
                                                    finEntete.subtract(1, 'days');
                                                }
                                            } else if (!angular.isNullOrUndefined(scope.bufDonnees[i].fin)) {
                                                f = moment(scope.bufDonnees[i].fin);
                                                f.subtract(1, 'days');
                                                if (f.isAfter(finEntete, 'day')) {
                                                    finEntete = f;
                                                }
                                            }
                                        }

                                        // Sauvegarde en base les lignes de l'élément de référence
                                        for (i = 0; i < scope.eventDisponibles.length; i++) {
                                            e = [];
                                            for (j = 0; j < lignesModif.length; j++) {
                                                if (lignesModif[j].idGroupe === scope.eventDisponibles[i].idGroupe) {
                                                    e.push(lignesModif[j]);
                                                }
                                            }

                                            if (e.length > 0) {
                                                // Identifiant écran BD pour la sauvegarde
                                                idFormulaire = {};
                                                idFormulaire[paramPlanning.colonnesLigne.idLigne] = Number(e[0].idGroupe);

                                                // Sauvegarder les modifications
                                                sauvegardeEvenementBase(e, idFormulaire, e[0].idEntete, e[0].refEntete);
                                            }
                                        }

                                        getNumeroRecurrenceSuivant(scope.refSerieRecurrence).then(function (recNumero) {
                                            // Sauvegarde en base les informations d'entête de l'élément de référence
                                            scope.refSerieRecurrence = recNumero;
                                            var objetEntete = scope.objetEntete;
                                            var dateLimite = scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur;
                                            var existeRecurrence = (!angular.isNullOrUndefined(dateLimite));

                                            sauvegardeEnteteBase({
                                                nomModule: scope.opNomModule,
                                                typePlanning: scope.opTypePlanning,
                                                actionCode: scope.opActionCode,
                                                id: idEntete,
                                                debut: debEntete,
                                                fin: finEntete,
                                                objet: objetEntete,
                                                calcNumeroEntete: false,
                                                recNumero: recNumero,
                                                recType: (!existeRecurrence) ? "Jour" : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceType].valeur,
                                                recIntervalle: (!existeRecurrence) ? 1 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceIntervalle].valeur,
                                                recDateLimite: (!existeRecurrence) ? null : dateLimite,
                                                recLundi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceLundi].valeur,
                                                recMardi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMardi].valeur,
                                                recMercredi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMercredi].valeur,
                                                recJeudi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJeudi].valeur,
                                                recVendredi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceVendredi].valeur,
                                                recSamedi: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceSamedi].valeur,
                                                recDimanche: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDimanche].valeur,
                                                recJourDuMois: (!existeRecurrence) ? 1 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur,
                                                recMois: (!existeRecurrence) ? 1 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMois].valeur,
                                                recOuvre: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceOuvre].valeur,
                                                recFerie: (!existeRecurrence) ? 0 : scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceFerie].valeur
                                            }).then(function (retourSauvegarde) {
                                                // Traitement des interventions récurrentes
                                                var recNnumero = retourSauvegarde.recNnumero;

                                                // Suppression en base des interventions récurrentes avant récréation
                                                var idEnteteSupp = [];
                                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                                // Evénements interventions supprimés
                                                for (i = 0; i < scope.eventRecurrentSupprimes.length; i++) {
                                                    if (idEnteteSupp.indexOf(scope.eventRecurrentSupprimes[i]) === -1) {
                                                        idEnteteSupp.push(scope.eventRecurrentSupprimes[i]);
                                                        if (scope.eventRecurrentSupprimes[i] > 0) {
                                                            idFormulaire = {};
                                                            idFormulaire[paramPlanning.colonnesEntete.idEntete] = scope.eventRecurrentSupprimes[i];
                                                            supprimerEvenementBase(paramPlanning.ecranSauvegardeEntete, idFormulaire, scope.eventRecurrentSupprimes[i]);
                                                        }
                                                    }
                                                }

                                                scope.eventRecurrentSupprimes = [];

                                                // Evénéments tâches supprimées
                                                var idLigneSupp = [];
                                                var s = scope.lignesRecurrentSupprimees;

                                                for (i = 0; i < s.length; i++) {
                                                    if (idEnteteSupp.indexOf(s[i].idEntete) !== -1) {
                                                        // Retirer du tableau des événements à supprimer
                                                        for (var idxEntete = 0; idxEntete < scope.lignesRecurrentSupprimees.length; idxEntete++) {
                                                            if (scope.lignesRecurrentSupprimees[idxEntete].idEntete === s[idxEntete].idEntete) {
                                                                scope.lignesRecurrentSupprimees.splice(idxEntete, 1);
                                                            }
                                                        }
                                                    } else {
                                                        // Ne pas supprimer les événements au statut traitée/annulée/refusée
                                                        var autoriserSuppression = true;

                                                        if (!angular.isNullOrUndefined(s[i].statutEntete) &&
                                                            (paramPlanning.statutsLecutreSeule.indexOf(s[i].statutEntete) !== -1)) {
                                                            autoriserSuppression = false;
                                                        }

                                                        if (autoriserSuppression && !angular.isNullOrUndefined(s[i].statutLigne) &&
                                                            (paramPlanning.statutsLecutreSeule.indexOf(s[i].statutLigne) !== -1)) {
                                                            autoriserSuppression = false;
                                                        }

                                                        if (!autoriserSuppression || (s[i].idGroupe <= 0)) {
                                                            for (var idxLigne = 0; idxLigne < scope.lignesRecurrentSupprimees.length; idxLigne++) {
                                                                if (scope.lignesRecurrentSupprimees[idxLigne].idGroupe === s[i].idGroupe) {
                                                                    scope.lignesRecurrentSupprimees.splice(idxLigne, 1);
                                                                    break;
                                                                }
                                                            }
                                                        } else {
                                                            if (idLigneSupp.indexOf(s[i].idGroupe) === -1) {
                                                                idLigneSupp.push(s[i].idGroupe);

                                                                // Identifiant écran BD pour la suppression
                                                                idFormulaire = {};
                                                                idFormulaire[paramPlanning.colonnesLigne.idLigne] = Number(s[i].idGroupe);

                                                                // Supprimer l'événement
                                                                supprimerEvenementBase(paramPlanning.ecranSauvegardeLignes, idFormulaire, s[i].idGroupe).then(function (idGroupe) {
                                                                    // Retirer du tableau des événements à supprimer
                                                                    for (var idxLigne = 0; idxLigne < scope.lignesRecurrentSupprimees.length; idxLigne++) {
                                                                        if (scope.lignesRecurrentSupprimees[idxLigne].idGroupe === idGroupe) {
                                                                            scope.lignesRecurrentSupprimees.splice(idxLigne, 1);
                                                                            break;
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        }
                                                    }
                                                }

                                                // Préparation pour la sauvegarde des interventions récurrentes
                                                var entetes = [];
                                                var lignes = [];
                                                for (i = 0; i < scope.bufDonneesRecurrence.length; i++) {
                                                    if ((scope.bufDonneesRecurrence[i].idEntete < 0) && !scope.bufDonneesRecurrence[i].ligneVide) {
                                                        lignesAjout.push(scope.bufDonneesRecurrence[i]);
                                                        lignesAjout[lignesAjout.length - 1].indexLigne = i;
                                                    } else if ((scope.bufDonneesRecurrence[i].idEntete > 0) && !scope.bufDonneesRecurrence[i].ligneVide) {
                                                        if (idEnteteSupp.indexOf(scope.bufDonneesRecurrence[i].idEntete) === -1) {
                                                            lignesAjout.push(scope.bufDonneesRecurrence[i]);
                                                            lignesAjout[lignesAjout.length - 1].indexLigne = i;
                                                        }
                                                    }
                                                }

                                                lignesAjout.sort(dynamicSort("idEntete"));

                                                var idEntetePrec;
                                                debEntete = null;
                                                finEntete = null;
                                                for (i = 0; i < lignesAjout.length; i++) {
                                                    if ((i > 0) && (lignesAjout[i].idEntete !== idEntetePrec)) {
                                                        // Changement d'entête
                                                        entetes.push({
                                                            id: (idEntetePrec > 0) ? idEntetePrec.toString() : "",
                                                            debut: debEntete,
                                                            fin: finEntete,
                                                            recNumero: recNnumero,
                                                            lignes: lignes
                                                        });
                                                        lignes = [];
                                                        debEntete = null;
                                                        finEntete = null;
                                                    }

                                                    // Date de début d'entête prévu
                                                    if (angular.isNullOrUndefined(debEntete)) {
                                                        if (!angular.isNullOrUndefined(lignesAjout[i].debut)) {
                                                            debEntete = moment(lignesAjout[i].debut);
                                                        }
                                                    } else if (!angular.isNullOrUndefined(lignesAjout[i].debut)) {
                                                        if (moment(lignesAjout[i].debut).isBefore(debEntete, 'day')) {
                                                            debEntete = moment(lignesAjout[i].debut);
                                                        }
                                                    }

                                                    // Date de fin d'entête prévue
                                                    if (angular.isNullOrUndefined(finEntete)) {
                                                        if (!angular.isNullOrUndefined(lignesAjout[i].fin)) {
                                                            finEntete = moment(lignesAjout[i].fin);
                                                            finEntete.subtract(1, 'days');
                                                        }
                                                    } else if (!angular.isNullOrUndefined(lignesAjout[i].fin)) {
                                                        f = moment(lignesAjout[i].fin);
                                                        f.subtract(1, 'days');
                                                        if (f.isAfter(finEntete, 'day')) {
                                                            finEntete = f;
                                                        }
                                                    }

                                                    lignes.push(lignesAjout[i]);
                                                    idEntetePrec = lignesAjout[i].idEntete;
                                                }

                                                // Traitement de la dernière entête
                                                if (lignesAjout.length > 0) {
                                                    entetes.push({
                                                        id: (idEntetePrec > 0) ? idEntetePrec.toString() : "",
                                                        debut: debEntete,
                                                        fin: finEntete,
                                                        recNumero: recNnumero,
                                                        lignes: lignes
                                                    });
                                                }

                                                // Sauvegarder les interventions récurrentes dans l'ordre
                                                var chaine = $q.when();
                                                angular.forEach(entetes.reverse(), function(elem) {
                                                    var evt = elem;
                                                    evt.objet = objetEntete;
                                                    chaine = chaine.then(function () {
                                                        return sauvegarderEventRecurrentBase(evt);
                                                    });
                                                });

                                                deferred.resolve();
                                            }, function (erreurSauvegarde) {
                                                deferred.reject(erreurSauvegarde);
                                            });
                                        }, function (erreurSauvegarde) {
                                            deferred.reject(erreurSauvegarde);
                                        });
                                    }
                                }

                                return deferred.promise;
                            };

                            /**
                             * Afichage popup pour confirmation avant action
                             * @param msg Message de confirmation à afficher
                             * @param libBoutonOK Libellé pour le bouton OK
                             * @param libBoutonNO Libellé pour le bouton Non
                             */
                            scope.confirmationAvantAction = function (msg, libBoutonOK, libBoutonNO) {
                                var deferred = $q.defer();

                                var libOK = "OK";
                                if (!angular.isNullOrUndefined(libBoutonOK)) {
                                    libOK = libBoutonOK;
                                }

                                var libNO = "Annuler";
                                if (!angular.isNullOrUndefined(libBoutonNO)) {
                                    libNO = libBoutonNO;
                                }

                                // Confirmation avant d'actualiser
                                var popupConfirmation = opPopupAlerte.creer('warning', msg, true, true, libOK, libNO);

                                popupConfirmation.result.then(function () {
                                    deferred.resolve();
                                }, function () {
                                    deferred.reject();
                                });

                                return deferred.promise;
                            };

                            /**
                             * Gestion avant fermêture du planning
                             */
                            scope.fermerPlanning = function (sauvegarde) {
                                // Sauvegarder avant fermêture ?
                                if (!angular.isNullOrUndefined(sauvegarde) && sauvegarde) {
                                    scope.sauvegardePlanning().then(function () {
                                        scope.planningModifie = false;

                                        // Retirer planning et grille du scope (via ng-if)
                                        scope.modeAffichage.grille = false;
                                        scope.modeAffichage.planning = false;

                                        // Vider le tableau des sources d'événements sur le scope
                                        scope.eventSources = [];
                                        scope.eventRessources = [];

                                        // Vider cache données de la grille
                                        scope.dataGrillePlanning = [];

                                        // Vider cache données du planning
                                        //scope.bufDonnees = [];
                                        //scope.bufDonneesRecurrence = [];
                                        scope.listeIdLectureSeule = [];
                                        scope.listeIdResLectureSeule = [];
                                        //scope.eventSupprimes = [];
                                        //scope.eventRecurrentSupprimes = [];
                                        //scope.lignesRecurrentSupprimees = [];

                                        // Réinit ressource sélectionnée
                                        scope.libRessourceSelect = "";
                                        scope.ressourceSelect = null;
                                        scope.listeRessourcesAffectees = [];
                                        scope.listeLibRessourcesAffectees = [];										

                                        // Activer réinit date de défaut
                                        scope.reinitDefaultDate = true;

                                        // Réinit gestion groupes de ressources
                                        if (!angular.isNullOrUndefined(scope.nbreClickRessourceExpander)) {
                                            scope.nbreClickRessourceExpander = 0;
                                        }										
                                    });
                                } else {
                                    // Retirer planning et grille du scope (via ng-if)
                                    scope.modeAffichage.grille = false;
                                    scope.modeAffichage.planning = false;

                                    // Vider le tableau des sources d'événements sur le scope
                                    scope.eventSources = [];
                                    scope.eventRessources = [];

                                    // Vider cache données de la grille
                                    scope.dataGrillePlanning = [];

                                    // Vider cache données du planning
                                    //scope.bufDonnees = [];
                                    //scope.bufDonneesRecurrence = [];
                                    scope.listeIdLectureSeule = [];
                                    scope.listeIdResLectureSeule = [];
                                    //scope.eventSupprimes = [];
                                    //scope.eventRecurrentSupprimes = [];
                                    //scope.lignesRecurrentSupprimees = [];

                                    // Réinit ressource sélectionnée
                                    scope.libRessourceSelect = "";
                                    scope.ressourceSelect = null;
                                    scope.listeRessourcesAffectees = [];
                                    scope.listeLibRessourcesAffectees = [];

                                    // Activer réinit date de défaut
                                    scope.reinitDefaultDate = true;

                                    // Réinit gestion groupes de ressources
                                    if (angular.isNullOrUndefined(scope.nbreClickRessourceExpander)) {
                                        scope.nbreClickRessourceExpander = 0;
                                    }
                                }
                            };

                            /**
                             * Ecran modal pour l'ajout simple des occupations
                             */
                            scope.ajoutOccupation = function (nomEcran) {
                                // Lancer fênetre modal pour ajout
                                var modalAjoutOccupation = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAjoutOccupation.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAjoutOccupationControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return "Ajout indisponibilité";
                                        },
                                        vueFormulaire: function() {
                                            var libRessourceSelect = getLibRessourceSelect();
                                            var identifiant = {ID_CONTACT: -1};
                                            for (var i = 0; i < scope.listeRessourcesAffectees.length; i++) {
                                                if (scope.listeRessourcesAffectees[i].libRessource === libRessourceSelect) {
                                                    identifiant.ID_CONTACT = Number(scope.listeRessourcesAffectees[i].id);
                                                    break;
                                                }
                                            }

                                            return {
                                                nomModule: scope.opNomModule,
                                                nomEcran: nomEcran,
                                                identifiant: identifiant,
                                                estAjout: true
                                            };
                                        }
                                    }
                                });

                                modalAjoutOccupation.result.then(function (occupation) {
                                    if (!angular.isNullOrUndefined(occupation)) {
                                        // Construire objet occupation ajouté
                                        scope.idGroupe = scope.idGroupe + 1;

                                        var occupationAjoute = {
                                            idEntete: occupation.idEntete,
                                            refEntete: occupation.refEntete,
                                            adresseEntete: null,
                                            refInterneLigne: null,
                                            numeroInterneLigne: null,
                                            creationLigne: null,
                                            typeRessource: occupation.typeRessource,
                                            idRessource: occupation.idRessource,
                                            libRessource: occupation.libRessource,
                                            qteRessource: occupation.qteRessource,
                                            idLigne: null,
                                            idGroupe: scope.idGroupe,
                                            refLigne: occupation.refLigne,
                                            libLigne: occupation.libLigne,
                                            descLigne: occupation.descLigne,
                                            debut: occupation.debut,
                                            fin: occupation.fin,
                                            debutReel: occupation.debutReel,
                                            finReelle: occupation.finReelle,
                                            //heureDebut: occupation.heureDebut,
                                            //heureFin: occupation.heureFin,
                                            dureeJours: occupation.dureeJours,
                                            dureeHeures: occupation.dureeHeures,
                                            avancement: occupation.avancement,
                                            visible: true,
                                            ligneVide: false,
                                            selectionne: false,
                                            estAjout: true,
                                            modifie: false
                                        };

                                        // Ajout ligne au bufDonnees
                                        scope.bufDonnees.push(occupationAjoute);

                                        // Marquer le planning comme modifié
                                        scope.planningModifie = true;

                                        // Mettre à jour la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(true);
                                    }
                                });
                            };

                            /**
                             * Ecran modal pour la modification d'une occupation
                             */
                            scope.modifierOccupation = function (nomEcran) {
                                // Sélection en cours
                                var occupation = null;
                                var idGroupe = -1;
                                for (var i = 0; i < scope.eventDisponibles.length; i++) {
                                    if (scope.eventDisponibles[i].selectionne) {
                                        occupation = scope.eventDisponibles[i];
                                        idGroupe = scope.eventDisponibles[i].idGroupe;
                                        break;
                                    }
                                }

                                // Lancer fênetre modal pour modification
                                var modalModifOccupation = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAjoutOccupation.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAjoutOccupationControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return "Modifier indisponibilité";
                                        },
                                        vueFormulaire: function() {
                                            var libRessourceSelect = getLibRessourceSelect();
                                            var identifiant = {ID_CONTACT: -1};
                                            for (var i = 0; i < scope.listeRessourcesAffectees.length; i++) {
                                                if (scope.listeRessourcesAffectees[i].libRessource === libRessourceSelect) {
                                                    identifiant.ID_CONTACT = Number(scope.listeRessourcesAffectees[i].id);
                                                    break;
                                                }
                                            }

                                            return {
                                                nomModule: scope.opNomModule,
                                                nomEcran: nomEcran,
                                                identifiant: identifiant,
                                                occupation: occupation,
                                                estAjout: false
                                            };
                                        }
                                    }
                                });

                                modalModifOccupation.result.then(function (occupation) {
                                    if (!angular.isNullOrUndefined(occupation)) {
                                        // Retrouver l'indice de la ligne à mettre à jour dans le cache des données
                                        var idxLigne = -1;
                                        for (var i = 0; i < scope.bufDonnees.length; i++) {
                                            if ((scope.bufDonnees[i].typeRessource === occupation.typeRessource) &&
                                                (scope.bufDonnees[i].idRessource === occupation.idRessource) &&
                                                (scope.bufDonnees[i].libRessource === occupation.libRessource) &&
                                                (scope.bufDonnees[i].idGroupe === idGroupe)) {
                                                idxLigne = i;
                                                break;
                                            }
                                        }

                                        if (idxLigne !== -1) {
                                            // Maj ligne du bufDonnees
                                            scope.bufDonnees[idxLigne].refLigne = occupation.refLigne;
                                            scope.bufDonnees[idxLigne].libLigne = occupation.libLigne;
                                            scope.bufDonnees[idxLigne].descLigne = occupation.descLigne;
                                            scope.bufDonnees[idxLigne].debut = occupation.debut;
                                            scope.bufDonnees[idxLigne].fin = occupation.fin;
                                            scope.bufDonnees[idxLigne].debutReel = occupation.debutReel;
                                            scope.bufDonnees[idxLigne].finReelle = occupation.finReelle;
                                            //scope.bufDonnees[idxLigne].heureDebut = occupation.heureDebut;
                                            //scope.bufDonnees[idxLigne].heureFin = occupation.heureFin;
                                            scope.bufDonnees[idxLigne].dureeJours = occupation.dureeJours;
                                            scope.bufDonnees[idxLigne].dureeHeures = occupation.dureeHeures;
                                            scope.bufDonnees[idxLigne].modifie = true;

                                            // Marquer le planning comme modifié
                                            scope.planningModifie = true;

                                            // Mettre à jour la barre des événements disponibles pour ajout via drag/drop
                                            initListeEvenementDispo(scope.bufDonnees);

                                            // Actualiser le grille et le calendrier
                                            scope.actualiserOpPlanningDepuisCache(true);
                                        }
                                    }
                                });
                            };

                            /**
                             * Ecran modal pour l'ajout simple des tâches
                             */
                            scope.ajoutTache = function (nomEcran) {
                                // Lancer fênetre modal pour ajout
                                var modalAjoutTache = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAjoutTache.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return getLibSelection();
                                        },
                                        premiereTache: function () {
                                            return (angular.isNullOrUndefined(scope.eventDisponibles) || (scope.eventDisponibles.length === 0));
                                        },
                                        vueFormulaire: function() {
                                            return {
                                                nomModule: scope.opNomModule,
                                                nomEcran: nomEcran,
                                                identifiant: scope.idFormulaire
                                            };
                                        }
                                    }
                                });

                                modalAjoutTache.result.then(function (tache) {
                                    if (!angular.isNullOrUndefined(tache)) {
                                        // Construire objet tache ajouté
                                        scope.idGroupe = scope.idGroupe + 1;

                                        var tacheAjoute = {
                                            idEntete: tache.idEntete,
                                            refEntete: tache.refEntete,
                                            adresseEntete: tache.adresseEntete,
                                            refInterneLigne: null,
                                            numeroInterneLigne: null,
                                            creationLigne: null,
                                            typeRessource: tache.typeRessource,
                                            idRessource: tache.idRessource,
                                            libRessource: tache.libRessource,
                                            qteRessource: tache.qteRessource,
                                            idLigne: null,
                                            idGroupe: scope.idGroupe,
                                            refLigne: tache.refLigne,
                                            libLigne: tache.libLigne,
                                            descLigne: tache.descLigne,
                                            debut: tache.debut,
                                            fin: tache.fin,
                                            debutReel: tache.debutReel,
                                            finReelle: tache.finReelle,
                                            //heureDebut: tache.heureDebut,
                                            //heureFin: tache.heureFin,
                                            dureeJours: tache.dureeJours,
                                            dureeHeures: tache.dureeHeures,
                                            avancement: tache.avancement,
                                            visible: true,
                                            ligneVide: false,
                                            selectionne: false,
                                            estAjout: true,
                                            modifie: false
                                        };

                                        // Ligne de ressource déjà présente sans événement ?
                                        var idxLigne = -1;
                                        for (var i = 0; i < scope.bufDonnees.length; i++) {
                                            if ((scope.bufDonnees[i].typeRessource === tacheAjoute.typeRessource) &&
                                                (scope.bufDonnees[i].idRessource === tacheAjoute.idRessource) &&
                                                (scope.bufDonnees[i].libRessource === tacheAjoute.libRessource) &&
                                                (scope.bufDonnees[i].ligneVide)) {
                                                idxLigne = i;
                                                break;
                                            }
                                        }

                                        if (idxLigne !== -1) {
                                            // Maj ligne du bufDonnees
                                            scope.bufDonnees[idxLigne] = tacheAjoute;
                                        } else {
                                            // Ajout ligne au bufDonnees
                                            scope.bufDonnees.push(tacheAjoute);
                                        }

                                        scope.planningModifie = true;

                                        // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(true);
                                    }
                                });
                            };

                            /**
                             * Ecran modal pour l'ajout détaillée des tâches
                             */
                            scope.ajoutTacheDetaillee = function (nomEcran) {
                                // Lancer fênetre modal pour ajout
                                var modalAjoutTacheDetaillee = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAjoutTacheDetaillee.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheDetailleeControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return getLibSelection();
                                        },
                                        premiereTache: function () {
                                            return (angular.isNullOrUndefined(scope.eventDisponibles) || (scope.eventDisponibles.length === 0));
                                        },
                                        vueFormulaire: function() {
                                            return {
                                                nomModule: scope.opNomModule,
                                                nomEcran: nomEcran,
                                                identifiant: scope.idFormulaire,
                                                tache: null,
                                                ressources: null,
                                                listeRessources: scope.listeRessources,
                                                filtreRessources: scope.filtreRessources
                                            };
                                        }
                                    }
                                });

                                modalAjoutTacheDetaillee.result.then(function (taches) {
                                    if (!angular.isNullOrUndefined(taches)) {
                                        // Construire tableau des objets tache à ajouter (une ligne par couple tâche/ressource)
                                        scope.idGroupe = scope.idGroupe + 1;

                                        var lignesTache = [];
                                        if ((taches.length === 1) && angular.isNullOrUndefined(taches[0].idRessource)) {
                                            // Tâche sans ressources
                                            lignesTache.push({
                                                idEntete: taches[0].idEntete,
                                                refEntete: taches[0].refEntete,
                                                adresseEntete: taches[0].adresseEntete,
                                                refInterneLigne: null,
                                                numeroInterneLigne: null,
                                                creationLigne: null,
                                                typeRessource: null,
                                                idRessource: null,
                                                libRessource: null,
                                                qteRessource: null,
                                                idLigne: null,
                                                idGroupe: scope.idGroupe,
                                                refLigne: taches[0].refLigne,
                                                libLigne: taches[0].libLigne,
                                                descLigne: taches[0].descLigne,
                                                debut: taches[0].debut,
                                                fin: taches[0].fin,
                                                debutReel: taches[0].debutReel,
                                                finReelle: taches[0].finReelle,
                                                //heureDebut: taches[0].heureDebut,
                                                //heureFin: taches[0].heureFin,
                                                dureeJours: taches[0].dureeJours,
                                                dureeHeures: taches[0].dureeHeures,
                                                avancement: taches[0].avancement,
                                                visible: true,
                                                ligneVide: false,
                                                selectionne: false,
                                                estAjout: true,
                                                modifie: false
                                            });
                                        } else {
                                            // Tâche avec ressources
                                            for (var i = 0; i < taches.length; i++) {
                                                lignesTache.push({
                                                    idEntete: taches[i].idEntete,
                                                    refEntete: taches[i].refEntete,
                                                    adresseEntete: taches[i].adresseEntete,
                                                    refInterneLigne: null,
                                                    numeroInterneLigne: null,
                                                    creationLigne: null,
                                                    typeRessource: taches[i].typeRessource,
                                                    idRessource: taches[i].idRessource,
                                                    libRessource: taches[i].libRessource,
                                                    qteRessource: taches[i].qteRessource,
                                                    idLigne: null,
                                                    idGroupe: scope.idGroupe,
                                                    refLigne: taches[i].refLigne,
                                                    libLigne: taches[i].libLigne,
                                                    descLigne: taches[i].descLigne,
                                                    debut: taches[i].debut,
                                                    fin: taches[i].fin,
                                                    debutReel: taches[i].debutReel,
                                                    finReelle: taches[i].finReelle,
                                                    //heureDebut: taches[i].heureDebut,
                                                    //heureFin: taches[i].heureFin,
                                                    dureeJours: taches[i].dureeJours,
                                                    dureeHeures: taches[i].dureeHeures,
                                                    avancement: taches[i].avancement,
                                                    visible: true,
                                                    ligneVide: false,
                                                    selectionne: false,
                                                    estAjout: true,
                                                    modifie: false
                                                });
                                            }
                                        }

                                        // Ajout des lignes au cache des données du planning
                                        for (i = 0; i < lignesTache.length; i++) {
                                            // Ligne de ressource déjà présente sans événement ?
                                            var idxLigne = -1;
                                            for (var j = 0; j < scope.bufDonnees.length; j++) {
                                                if ((scope.bufDonnees[j].typeRessource === lignesTache[i].typeRessource) &&
                                                    (scope.bufDonnees[j].idRessource === lignesTache[i].idRessource) &&
                                                    (scope.bufDonnees[j].libRessource === lignesTache[i].libRessource) &&
                                                    (scope.bufDonnees[j].ligneVide)) {
                                                    idxLigne = j;
                                                    break;
                                                }
                                            }

                                            if (idxLigne !== -1) {
                                                // Maj ligne du bufDonnees
                                                scope.bufDonnees[idxLigne] = lignesTache[i];
                                            } else {
                                                // Ajout ligne au bufDonnees
                                                scope.bufDonnees.push(lignesTache[i]);
                                            }

                                            scope.planningModifie = true;
                                        }

                                        // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(true);
                                    }
                                });
                            };

                            /**
                             * Ecran modal pour la modification d'une tâche
                             */
                            scope.modifierTache = function (nomEcran) {
                                // Sélection en cours
                                var tache = null;
                                for (var i = 0; i < scope.eventDisponibles.length; i++) {
                                    if (scope.eventDisponibles[i].selectionne) {
                                        tache = scope.eventDisponibles[i];
                                        break;
                                    }
                                }

                                // Ressources de la tâche sélectionée
                                var ressources = [];
                                var idGroupe = -1;
                                for (i = 0; i < scope.eventDisponibles.length; i++) {
                                    if (scope.eventDisponibles[i].selectionne) {
                                        idGroupe = scope.eventDisponibles[i].idGroupe;
                                        break;
                                    }
                                }

                                if (idGroupe !== -1) {
                                    for (i = 0; i < scope.bufDonnees.length; i++) {
                                        if (scope.bufDonnees[i].idGroupe === idGroupe) {
                                            if (scope.bufDonnees[i].typeRessource === "Matériau") {
                                                ressources.push(scope.bufDonnees[i]);
                                            } else if (!angular.isNullOrUndefined(scope.bufDonnees[i].idRessource)) {
                                                ressources.push(scope.bufDonnees[i]);
                                            }
                                        }
                                    }
                                }

                                // Lancer fênetre modal pour modification
                                var modalModifierTache = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAjoutTacheDetaillee.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheDetailleeControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return getLibSelection();
                                        },
                                        premiereTache: function () {
                                            return (angular.isNullOrUndefined(scope.eventDisponibles) || (scope.eventDisponibles.length === 0));
                                        },
                                        vueFormulaire: function() {
                                            return {
                                                nomModule: scope.opNomModule,
                                                nomEcran: nomEcran,
                                                identifiant: scope.idFormulaire,
                                                tache:  tache,
                                                ressources: ressources,
                                                listeRessources: scope.listeRessources,
                                                filtreRessources: scope.filtreRessources
                                            };
                                        }
                                    }
                                });

                                modalModifierTache.result.then(function (taches) {
                                    if (!angular.isNullOrUndefined(taches)) {
                                        // Marquer les lignes tâches existantes comme vides
                                        var idLigne = null;
                                        for (i = 0; i < taches.length; i++) {
                                            for (j = 0; j < scope.bufDonnees.length; j++) {
                                                if (scope.bufDonnees[j].idGroupe === idGroupe) {
                                                    // Conserver la propriété idLigne
                                                    if (angular.isNullOrUndefined(idLigne)) {
                                                        idLigne = scope.bufDonnees[j].idLigne;
                                                    }

                                                    // Marquer la ligne comme vide
                                                    scope.bufDonnees[j].refInterneLigne = null;
                                                    scope.bufDonnees[j].numeroInterneLigne = null;
                                                    scope.bufDonnees[j].creationLigne = null;
                                                    scope.bufDonnees[j].idLigne = null;
                                                    scope.bufDonnees[j].refLigne = null;
                                                    scope.bufDonnees[j].idGroupe = null;
                                                    scope.bufDonnees[j].libLigne = null;
                                                    scope.bufDonnees[j].descLigne = null;
                                                    scope.bufDonnees[j].debut = null;
                                                    scope.bufDonnees[j].fin = null;
                                                    scope.bufDonnees[j].debutReel = null;
                                                    scope.bufDonnees[j].finReelle = null;
                                                    //scope.bufDonnees[j].heureDebut = null;
                                                    //scope.bufDonnees[j].heureFin = null;
                                                    scope.bufDonnees[j].dureeJours = null;
                                                    scope.bufDonnees[j].dureeHeures = null;
                                                    scope.bufDonnees[j].avancement = null;
                                                    scope.bufDonnees[j].visible = true;
                                                    scope.bufDonnees[j].ligneVide = true;
                                                    scope.bufDonnees[j].selectionne = false;

                                                    scope.planningModifie = true;
                                                }
                                            }
                                        }

                                        // Rajouter les lignes tâches
                                        var lignesTache = [];
                                        if ((taches.length === 1) && angular.isNullOrUndefined(taches[0].idRessource)) {
                                            // Tâche sans ressources
                                            lignesTache.push({
                                                idEntete: taches[0].idEntete,
                                                refEntete: taches[0].refEntete,
                                                adresseEntete: taches[0].adresseEntete,
                                                refInterneLigne: taches[0].refInterne,
                                                numeroInterneLigne: taches[0].numeroInterne,
                                                creationLigne: taches[0].dateCreation,
                                                typeRessource: null,
                                                idRessource: null,
                                                libRessource: null,
                                                qteRessource: null,
                                                idLigne: (angular.isNullOrUndefined(idLigne)) ? null : idLigne,
                                                idGroupe: idGroupe,
                                                refLigne: taches[0].refLigne,
                                                libLigne: taches[0].libLigne,
                                                descLigne: taches[0].descLigne,
                                                debut: taches[0].debut,
                                                fin: taches[0].fin,
                                                debutReel: taches[0].debutReel,
                                                finReelle: taches[0].finReelle,
                                                //heureDebut: taches[0].heureDebut,
                                                //heureFin: taches[0].heureFin,
                                                dureeJours: taches[0].dureeJours,
                                                dureeHeures: taches[0].dureeHeures,
                                                avancement: taches[0].avancement,
                                                visible: true,
                                                ligneVide: false,
                                                selectionne: false,
                                                estAjout: taches[0].estAjout,
                                                modifie: true
                                            });
                                        } else {
                                            // Tâche avec ressources
                                            for (i = 0; i < taches.length; i++) {
                                                lignesTache.push({
                                                    idEntete: taches[i].idEntete,
                                                    refEntete: taches[i].refEntete,
                                                    adresseEntete: taches[i].adresseEntete,
                                                    refInterneLigne: taches[i].refInterne,
                                                    numeroInterneLigne: taches[i].numeroInterne,
                                                    creationLigne: taches[i].dateCreation,
                                                    typeRessource: taches[i].typeRessource,
                                                    idRessource: taches[i].idRessource,
                                                    libRessource: taches[i].libRessource,
                                                    qteRessource: taches[i].qteRessource,
                                                    idLigne: (angular.isNullOrUndefined(idLigne)) ? null : idLigne,
                                                    idGroupe: idGroupe,
                                                    refLigne: taches[i].refLigne,
                                                    libLigne: taches[i].libLigne,
                                                    descLigne: taches[i].descLigne,
                                                    debut: taches[i].debut,
                                                    fin: taches[i].fin,
                                                    debutReel: taches[i].debutReel,
                                                    finReelle: taches[i].finReelle,
                                                    //heureDebut: taches[i].heureDebut,
                                                    //heureFin: taches[i].heureFin,
                                                    dureeJours: taches[i].dureeJours,
                                                    dureeHeures: taches[i].dureeHeures,
                                                    avancement: taches[i].avancement,
                                                    visible: true,
                                                    ligneVide: false,
                                                    selectionne: false,
                                                    estAjout: taches[i].estAjout,
                                                    modifie: true
                                                });
                                            }
                                        }

                                        // Ajout des lignes au cache des données du planning
                                        for (i = 0; i < lignesTache.length; i++) {
                                            // Ligne de ressource déjà présente sans événement ?
                                            var idxLigne = -1;
                                            for (var j = 0; j < scope.bufDonnees.length; j++) {
                                                if ((scope.bufDonnees[j].typeRessource === lignesTache[i].typeRessource) &&
                                                    (scope.bufDonnees[j].idRessource === lignesTache[i].idRessource) &&
                                                    (scope.bufDonnees[j].libRessource === lignesTache[i].libRessource) &&
                                                    (scope.bufDonnees[j].ligneVide)) {
                                                    idxLigne = j;
                                                    break;
                                                }
                                            }

                                            if (idxLigne !== -1) {
                                                // Maj ligne du bufDonnees
                                                scope.bufDonnees[idxLigne] = lignesTache[i];
                                            } else {
                                                // Ajout ligne au bufDonnees
                                                scope.bufDonnees.push(lignesTache[i]);
                                            }

                                            scope.planningModifie = true;
                                        }

                                        // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(true);
                                    }
                                });
                            };

                            /**
                             * Ecran modal pour gérer l'avancement d'une tâche
                             */
                            scope.avancementTache = function (nomEcran) {
                                // Sélection en cours
                                var tache = null;
                                for (var i = 0; i < scope.eventDisponibles.length; i++) {
                                    if (scope.eventDisponibles[i].selectionne) {
                                        tache = scope.eventDisponibles[i];
                                        break;
                                    }
                                }

                                // Ressources de la tâche sélectionée
                                var ressources = [];
                                var idGroupe = -1;
                                for (i = 0; i < scope.eventDisponibles.length; i++) {
                                    if (scope.eventDisponibles[i].selectionne) {
                                        idGroupe = scope.eventDisponibles[i].idGroupe;
                                        break;
                                    }
                                }

                                if (idGroupe !== -1) {
                                    for (i = 0; i < scope.bufDonnees.length; i++) {
                                        if (scope.bufDonnees[i].idGroupe === idGroupe) {
                                            if (scope.bufDonnees[i].typeRessource === "Matériau") {
                                                ressources.push(scope.bufDonnees[i]);
                                            } else if (!angular.isNullOrUndefined(scope.bufDonnees[i].idRessource)) {
                                                ressources.push(scope.bufDonnees[i]);
                                            }
                                        }
                                    }
                                }

                                // Lancer fênetre modal pour modification de 'avancement
                                var modalAvancementTache = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAvancementTache.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAvancementTacheControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return getLibSelection();
                                        },
                                        vueFormulaire: function() {
                                            return {
                                                nomModule: scope.opNomModule,
                                                nomEcran: nomEcran,
                                                identifiant: scope.idFormulaire,
                                                tache:  tache,
                                                ressources: ressources,
                                                listeRessources: scope.listeRessources,
                                                filtreRessources: scope.filtreRessources
                                            };
                                        }
                                    }
                                });

                                modalAvancementTache.result.then(function (tache) {
                                    if (!angular.isNullOrUndefined(tache)) {
                                        // Mettre à jour le statut d'avancement dans la cache des données
                                        for (var i = 0; i < scope.bufDonnees.length; i++) {
                                            if (scope.bufDonnees[i].idGroupe === idGroupe) {
                                                scope.bufDonnees[i].debutReel = tache.debutReel;
                                                scope.bufDonnees[i].finReelle = tache.finReelle;
                                                //scope.bufDonnees[i].heureDebut = tache.heureDebut;
                                                //scope.bufDonnees[i].heureFin = tache.heureFin;
                                                scope.bufDonnees[i].dureeJours = tache.dureeJours;
                                                scope.bufDonnees[i].dureeHeures = tache.dureeHeures;
                                                scope.bufDonnees[i].avancement = tache.avancement;
                                                scope.bufDonnees[i].statutLigne = tache.statutLigne;
                                                scope.bufDonnees[i].modifie = true;

                                                // Ajout idGroupe à la liste des groupes dont le statut des lignes a été modifié
                                                if (scope.groupesStatutsLignesModifies.indexOf(idGroupe) === -1) {
                                                    scope.groupesStatutsLignesModifies.push(idGroupe);
                                                }

                                                // Marquer le planning comme modifié
                                                scope.planningModifie = true;
                                            }
                                        }

                                        // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(true);
                                    }
                                });
                            };

                            /**
                             * Réactiver des éléments du planning
                             * @param actionCode Code d'action appelant
                             */
                            scope.reactiverElements = function (actionCode) {
                                var selection = [];

                                // Sélection en cours
                                for (var i = 0; i < scope.eventDisponibles.length; i++) {
                                    if ((scope.eventDisponibles[i].selectionne) && scope.eventDisponibles[i].lectureSeule) {
                                        selection.push(scope.eventDisponibles[i]);
                                    }
                                }

                                if (selection.length > 0) {
                                    // Message de confirmation selon nombre d'éléments sélectionnés et l'action appelant
                                    var msg = "Réactiver " + selection.length.toString();

                                    if (selection.length === 1) {
                                        if (actionCode === "REACTIVERTACHE") {
                                            msg += " tâche ?";
                                        } else {
                                            msg += " élément ?";
                                        }
                                    } else {
                                        if (actionCode === "REACTIVERTACHE") {
                                            msg += " tâches ?";
                                        } else {
                                            msg += " éléments ?";
                                        }
                                    }

                                    // Confirmation avant suppression
                                    scope.confirmationAvantAction(msg, "Oui", "Non").then(function () {
                                        // Parcourir la sélection
                                        for (i = 0; i < selection.length; i++) {
                                            var idGroupe = selection[i].data.element.idGroupe;
                                            for (var j = 0; j < scope.bufDonnees.length; j++) {
                                                if (scope.bufDonnees[j].idGroupe === idGroupe) {
                                                    // Réactiver l'élément
                                                    scope.bufDonnees[j].statutLigne = (actionCode === "REACTIVERTACHE") ? "Créée" : "Réactivé";
                                                    scope.bufDonnees[j].modifie = true;

                                                    // Ajout idGroupe à la liste des groupes dont le statut des lignes a été modifié
                                                    if (scope.groupesStatutsLignesModifies.indexOf(idGroupe) === -1) {
                                                        scope.groupesStatutsLignesModifies.push(idGroupe);
                                                    }

                                                    // Marquer le planning comme modifié
                                                    scope.planningModifie = true;
                                                }
                                            }
                                        }

                                        // Actualiser la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(false);
                                    });
                                }
                            };

                            /**
                             * Suppression des éléments du planning
                             * @param actionCode Code d'action appelant
                             */
                            scope.supprimerElements = function (actionCode) {
                                var selection = [];

                                // Sélection en cours
                                for (var i = 0; i < scope.eventDisponibles.length; i++) {
                                    if ((scope.eventDisponibles[i].selectionne) && !scope.eventDisponibles[i].lectureSeule) {
                                        selection.push(scope.eventDisponibles[i]);
                                    }
                                }

                                if (selection.length > 0) {
                                    // Message de confirmation selon nombre d'éléments sélectionnés et l'action appelant
                                    var msg = "Supprimer " + selection.length.toString();

                                    if (selection.length === 1) {
                                        if (actionCode === "SUPPRIMERTACHE") {
                                            msg += " tâche ?";
                                        } else {
                                            msg += " élément ?";
                                        }
                                    } else {
                                        if (actionCode === "SUPPRIMERTACHE") {
                                            msg += " tâches ?";
                                        } else {
                                            msg += " éléments ?";
                                        }
                                    }

                                    // Confirmation avant suppression
                                    scope.confirmationAvantAction(msg, "Oui", "Non").then(function () {
                                        // Parcourir la sélection
                                        for (i = 0; i < selection.length; i++) {
                                            var idGroupe = selection[i].data.element.idGroupe;
                                            for (var j = 0; j < scope.bufDonnees.length; j++) {
                                                if (scope.bufDonnees[j].idGroupe === idGroupe) {
                                                    // Marquer la ligne comme vide afin de conserver l'affichage de la ressource
                                                    scope.bufDonnees[j].refInterneLigne = null;
                                                    scope.bufDonnees[j].numeroInterneLigne = null;
                                                    scope.bufDonnees[j].creationLigne = null;
                                                    scope.bufDonnees[j].idLigne = null;
                                                    scope.bufDonnees[j].refLigne = null;
                                                    scope.bufDonnees[j].idGroupe = null;
                                                    scope.bufDonnees[j].libLigne = null;
                                                    scope.bufDonnees[j].descLigne = null;
                                                    scope.bufDonnees[j].debut = null;
                                                    scope.bufDonnees[j].fin = null;
                                                    scope.bufDonnees[j].debutReel = null;
                                                    scope.bufDonnees[j].finReelle = null;
                                                    //scope.bufDonnees[j].heureDebut = null;
                                                    //scope.bufDonnees[j].heureFin = null;
                                                    scope.bufDonnees[j].dureeJours = null;
                                                    scope.bufDonnees[j].dureeHeures = null;
                                                    scope.bufDonnees[j].avancement = null;
                                                    scope.bufDonnees[j].visible = true;
                                                    scope.bufDonnees[j].ligneVide = true;
                                                    scope.bufDonnees[j].selectionne = false;
                                                }
                                            }

                                            // Mémoriser l'éléments supprimé pour traitement lors de la sauvegarde du planning
                                            scope.eventSupprimes.push(selection[i]);
                                            scope.planningModifie = true;
                                        }

                                        // Identifier les lignes vides en doublon suite étape précédent
                                        var lignesVides = [];
                                        for (i = 0; i < scope.bufDonnees.length; i++) {
                                            if (scope.bufDonnees[i].ligneVide) {
                                                var idx = -1;
                                                for (j = 0; j < lignesVides.length; j++) {
                                                    if ((lignesVides[j].typeRessource === scope.bufDonnees[i].typeRessource) &&
                                                        (lignesVides[j].idRessource === scope.bufDonnees[i].idRessource)) {
                                                        idx = j;
                                                        break;
                                                    }
                                                }

                                                if (idx !== -1) {
                                                    lignesVides[idx].nbre++;
                                                } else {
                                                    lignesVides.push({
                                                        idEntete: scope.bufDonnees[i].idEntete,
                                                        refEntete: scope.bufDonnees[i].refEntete,
                                                        typeRessource: scope.bufDonnees[i].typeRessource,
                                                        idRessource: scope.bufDonnees[i].idRessource,
                                                        libRessource: scope.bufDonnees[i].libRessource,
                                                        qteRessource: scope.bufDonnees[i].qteRessource,
                                                        nbre: 1
                                                    });
                                                }
                                            }
                                        }

                                        // Retirer toutes les lignes ressources vides en doublon
                                        for (i = 0; i < lignesVides.length; i++) {
                                            if (lignesVides[i].nbre > 1) {
                                                for (j = scope.bufDonnees.length - 1; j >= 0; j--) {
                                                    if (scope.bufDonnees[j].ligneVide &&
                                                        (scope.bufDonnees[j].typeRessource === lignesVides[i].typeRessource) &&
                                                        (scope.bufDonnees[j].idRessource === lignesVides[i].idRessource)) {
                                                        scope.bufDonnees.splice(j, 1);
                                                    }
                                                }
                                            }
                                        }

                                        // Recréer une ligne vide pour chaque ressource supprimée
                                        for (i = 0; i < lignesVides.length; i++) {
                                            if (lignesVides[i].nbre > 1) {
                                                scope.bufDonnees.push({
                                                    idEntete: lignesVides[i].idEntete,
                                                    refEntete: lignesVides[i].refEntete,
                                                    adresseEntete: lignesVides[i].adresseEntete,
                                                    refInterneLigne: null,
                                                    numeroInterneLigne: null,
                                                    creationLigne: null,
                                                    typeRessource: lignesVides[i].typeRessource,
                                                    idRessource: lignesVides[i].idRessource,
                                                    libRessource: lignesVides[i].libRessource,
                                                    qteRessource: lignesVides[i].qteRessource,
                                                    idLigne: null,
                                                    idGroupe: null,
                                                    refLigne: null,
                                                    libLigne: null,
                                                    descLigne: null,
                                                    debut: null,
                                                    fin: null,
                                                    debutReel: null,
                                                    finReelle: null,
                                                    //heureDebut: null,
                                                    //heureFin: null,
                                                    dureeJours: null,
                                                    dureeHeures: null,
                                                    avancement: null,
                                                    visible: true,
                                                    ligneVide: true,
                                                    selectionne: false,
                                                    estAjout: true,
                                                    modifie: false
                                                });
                                            }
                                        }

                                        // Actualiser la barre des événements disponibles pour ajout via drag/drop
                                        initListeEvenementDispo(scope.bufDonnees);

                                        // Actualiser le grille et le calendrier
                                        scope.actualiserOpPlanningDepuisCache(false);
                                    });
                                }
                            };

                            /**
                             * Ecran modal pour la gestion de la périodicité des éléments récurrents
                             */
                            scope.gererPeriodicite = function (actionCode) {
                                if (actionCode = "GERERPERIODICITE") {
                                    // Lancer fênetre modal pour gérer la périodicité
                                    var modalRecurrence = $modal.open({
                                        templateUrl: 'moteur/composants/planning/OpPopupRecurrence.html',
                                        controller: 'fr.operis.moteurV4.composants.planning.OpPopupRecurrenceControleur',
                                        size: 'lg',
                                        resolve: {
                                            titre: function () {
                                                return "Périodicité";
                                            },
                                            recurrence: function () {
                                                var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                var dateLimite = scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur;
                                                if (!angular.isNullOrUndefined(dateLimite)) {
                                                    return {
                                                        recType: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceType].valeur,
                                                        recIntervalle: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceIntervalle].valeur,
                                                        recDateLimite: dateLimite,
                                                        recLundi: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceLundi].valeur,
                                                        recMardi: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMardi].valeur,
                                                        recMercredi: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMercredi].valeur,
                                                        recJeudi: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJeudi].valeur,
                                                        recVendredi: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceVendredi].valeur,
                                                        recSamedi: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceSamedi].valeur,
                                                        recDimanche: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDimanche].valeur,
                                                        recJourDuMois: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur,
                                                        recMois: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMois].valeur,
                                                        recOuvre: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceOuvre].valeur,
                                                        recFerie: scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceFerie].valeur
                                                    };
                                                } else {
                                                    return {
                                                        recType: "Jour",
                                                        recIntervalle: 1,
                                                        recDateLimite: null,
                                                        recLundi: 0,
                                                        recMardi: 0,
                                                        recMercredi: 0,
                                                        recJeudi: 0,
                                                        recVendredi: 0,
                                                        recSamedi: 0,
                                                        recDimanche: 0,
                                                        recJourDuMois: 1,
                                                        recMois: 1,
                                                        recOuvre: 0,
                                                        recFerie: 0
                                                    };
                                                }
                                            }
                                        }
                                    });

                                    modalRecurrence.result.then(function (recurrence) {
                                        if (!angular.isNullOrUndefined(recurrence)) {
                                            // Enregistrer les propriétés de récurrence
                                            var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                            var dateLimite = moment(recurrence.recDateLimite).toDate();
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceType].valeur = recurrence.recType;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceIntervalle].valeur = recurrence.recIntervalle;

                                            if (scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].descriptions.type === "DATETIME") {
                                                scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = conversion.versDateTime(dateLimite);
                                            } else if (scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].descriptions.type === "TIME") {
                                                scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = conversion.versTime(dateLimite);
                                            } else {
                                                scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDateLimite].valeur = conversion.versDate(dateLimite);
                                            }
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceLundi].valeur = recurrence.recLundi;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMardi].valeur = recurrence.recMardi;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMercredi].valeur = recurrence.recMercredi;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJeudi].valeur = recurrence.recJeudi;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceVendredi].valeur = recurrence.recVendredi;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceSamedi].valeur = recurrence.recSamedi;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceDimanche].valeur = recurrence.recDimanche;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceJourDuMois].valeur = recurrence.recJourDuMois;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceMois].valeur = recurrence.recMois;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceOuvre].valeur = recurrence.recOuvre;
                                            scope.champs[paramPlanning.tableEntete][paramPlanning.colonnesEntete.recurrenceFerie].valeur = recurrence.recFerie;

                                            // Mémoriser id des événements maîtres supprimés
                                            for (var s = 0; s < scope.bufDonneesRecurrence.length; s++) {
                                                // Ne pas supprimer les événements au statut traitée/annulée/refusée
                                                var autoriserSuppression = true;

                                                if (!angular.isNullOrUndefined(scope.bufDonneesRecurrence[s].statutEntete) &&
                                                    (paramPlanning.statutsLecutreSeule.indexOf(scope.bufDonneesRecurrence[s].statutEntete) !== -1)) {
                                                    autoriserSuppression = false;
                                                }

                                                if (autoriserSuppression && !angular.isNullOrUndefined(scope.bufDonneesRecurrence[s].statutLigne) &&
                                                    (paramPlanning.statutsLecutreSeule.indexOf(scope.bufDonneesRecurrence[s].statutLigne) !== -1)) {
                                                    autoriserSuppression = false;
                                                }

                                                if (autoriserSuppression) {
                                                    if (scope.eventRecurrentSupprimes.indexOf(scope.bufDonneesRecurrence[s].idEntete) === -1) {
                                                        scope.eventRecurrentSupprimes.push(scope.bufDonneesRecurrence[s].idEntete);
                                                    }
                                                }
                                            }

                                            // Vider le tableau des interventions récurrentes avant reconstruction
                                            for (s = scope.bufDonneesRecurrence.length - 1; s >= 0; s--) {
                                                if (scope.eventRecurrentSupprimes.indexOf(scope.bufDonneesRecurrence[s].idEntete) !== -1) {
                                                    scope.bufDonneesRecurrence.splice(s, 1);
                                                }
                                            }

                                            // Nombre d'occurences à traiter
                                            var nbreOccurence = getNbreRecurrences(scope.debutEntete, recurrence.recType, recurrence.recIntervalle, recurrence.recDateLimite);

                                            // Jours/mois cilbés
                                            var joursCibles = [];
                                            if (recurrence.recType === "Jour") {
                                                joursCibles.push(0);
                                            } else if (recurrence.recType === "Semaine") {
                                                if (recurrence.recLundi) {
                                                    joursCibles.push(1);
                                                }

                                                if (recurrence.recMardi) {
                                                    joursCibles.push(2);
                                                }

                                                if (recurrence.recMercredi) {
                                                    joursCibles.push(3);
                                                }

                                                if (recurrence.recJeudi) {
                                                    joursCibles.push(4);
                                                }

                                                if (recurrence.recVendredi) {
                                                    joursCibles.push(5);
                                                }

                                                if (recurrence.recSamedi) {
                                                    joursCibles.push(6);
                                                }

                                                if (recurrence.recDimanche) {
                                                    joursCibles.push(7);
                                                }

                                                if (joursCibles.length === 0) {
                                                    joursCibles.push(0);
                                                }
                                            } else if (recurrence.recType === "Mois") {
                                                joursCibles.push(recurrence.recJourDuMois);
                                            } else if (recurrence.recType === "Année") {
                                                joursCibles.push(recurrence.recMois - 1);
                                            }

                                            // Parcourir les tâches de l'élément maître selon leur idGroupe
                                            var d = scope.bufDonnees;
                                            d.sort(dynamicSort("idGroupe"));

                                            // Boucler sur nombre d'occurances
                                            var idEntete = 0;
                                            for (var i = 1; i <= nbreOccurence; i++) {
                                                // Boucler sur les jours/mois cibles
                                                for (var j = 0; j < joursCibles.length; j++) {
                                                    // idEntete par jour/mois cible
                                                    idEntete++;

                                                    // idGroupe par événément
                                                    var groupePrec = -1;

                                                    // Recopie des événements de l'élément mâtire
                                                    for (var k = 0; k < d.length; k++) {
                                                        if (k === 0) {
                                                            scope.idGroupe = scope.idGroupe + 1;
                                                        } else if (d[k].idGroupe !== groupePrec) {
                                                            scope.idGroupe = scope.idGroupe + 1;
                                                        }

                                                        var tacheRecurrente = {
                                                            idEntete: 0 - idEntete, // idEntete provisoire
                                                            refEntete: d[k].refEntete + "(" + idEntete.toString() + ")", // refEntete provisoire
                                                            adresseEntete: d[k].adresseEntete,
                                                            refInterneLigne: null,
                                                            numeroInterneLigne: null,
                                                            creationLigne: null,
                                                            typeRessource: d[k].typeRessource,
                                                            idRessource: d[k].idRessource,
                                                            libRessource: d[k].libRessource,
                                                            qteRessource: d[k].qteRessource,
                                                            idLigne: null,
                                                            idGroupe: scope.idGroupe,
                                                            refLigne: d[k].refLigne,
                                                            libLigne: d[k].libLigne,
                                                            descLigne: d[k].descLigne,
                                                            debut: d[k].debut,
                                                            fin: d[k].fin,
                                                            debutReel: d[k].debutReel,
                                                            finReelle: d[k].finReelle,
                                                            //heureDebut: d[k].heureDebut,
                                                            //heureFin: d[k].heureFin,
                                                            dureeJours: d[k].dureeJours,
                                                            dureeHeures: d[k].dureeHeures,
                                                            avancement: d[k].avancement,
                                                            visible: d[k].visible,
                                                            ligneVide: d[k].ligneVide,
                                                            selectionne: d[k].selectionne,
                                                            estAjout: d[k].estAjout,
                                                            modifie: false
                                                        };

                                                        // Calcul dates de début et fin d'événement
                                                        var delta = getDeltaEnJours(tacheRecurrente.debut, recurrence.recType, recurrence.recIntervalle, i, joursCibles[j]);
                                                        var debut = moment(tacheRecurrente.debut).add(delta, 'days').toDate();
                                                        // Utiliser systématiquement versDateTiem ici car tacheRecurrente.debut peut être du type DATE, TIME ou DATETIME
                                                        tacheRecurrente.debut = conversion.versDateTime(debut);

                                                        if (!angular.isNullOrUndefined(tacheRecurrente.fin)) {
                                                            var fin = moment(tacheRecurrente.fin).add(delta, 'days').toDate();
                                                            // Utiliser systématiquement versDateTiem ici car tacheRecurrente.fin  peut être du type DATE, TIME ou DATETIME
                                                            tacheRecurrente.fin = conversion.versDateTime(fin);
                                                        }

                                                        // Ajout ligne au bufDonneesRecurrence
                                                        if (!angular.isNullOrUndefined(tacheRecurrente.idRessource)) {
                                                            scope.bufDonneesRecurrence.push(tacheRecurrente);
                                                        }

                                                        // Mémoriser idGroupe de l'événement traitée
                                                        groupePrec = d[k].idGroupe;
                                                    }
                                                }
                                            }

                                            scope.planningModifie = true;

                                            // Initialiser la barre des événements disponibles pour ajout via drag/drop
                                            initListeEvenementDispo(scope.bufDonnees);

                                            // Initialiser tableau du nombre d'éléments par événement
                                            scope.numeroElemParEvenement = initNumeroElemParEvenement();

                                            // Actualiser le grille et le calendrier
                                            scope.actualiserOpPlanningDepuisCache(true);
                                        }
                                    });
                                }
                            };

                            /**
                             * Gestion début de drag/drop des éléments de la barre des événement disponibles
                             */
                            scope.startDrag = function() {
                                scope.dragged = true;
                            };

                            /**
                             * Basculer la selection d'une ligne de la grille planning
                             * @param lignes Tableau des lignes à traiter (avec leur statuts et indices)
                             */
                            var basculeSelectionLigne = function (lignes) {
                                $interval(function () {
                                    scope.gridApi.selection.clearSelectedRows();

                                    for (var i = 0; i < lignes.length; i++) {
                                        if (lignes[i].selectionne) {
                                            scope.gridApi.selection.selectRow(scope.gridOptions.data[lignes[i].idx]);
                                        } else {
                                            scope.gridApi.selection.unSelectRow(scope.gridOptions.data[lignes[i].idx]);
                                        }
                                    }
                                }, 0, 1);
                            };

                            /**
                             * Actualiser la sélection de la grille
                             */
                            var majSelectionGrille = function () {
                                var lignes = [];

                                for (var i = 0; i < scope.gridOptions.data.length; i++) {
                                    for (var j = 0; j <  scope.bufDonnees.length; j++) {
                                        if (scope.gridOptions.data[i].idGroupe === scope.bufDonnees[j].idGroupe) {
                                            lignes.push({selectionne: scope.bufDonnees[j].selectionne, idx: i});
                                        }
                                    }
                                }

                                basculeSelectionLigne(lignes);
                            };

                            /**
                             * Gestion clique sur événement dans la barre des événements disponibles
                             * @param evenement {Object} Evennement qui a reçu le clique
                             */
                            scope.clickEvenement = function (evenement) {
                                // Marquer l'élément comme sélectionné si pas de drag/drop
                                if (!scope.dragged) {
                                    evenement.selectionne = !evenement.selectionne;
                                    evenement.data["selectionne"] = evenement.selectionne;

                                    // Mettre à jour la cache des données du planning
                                    for (var i = 0; i < scope.bufDonnees.length; i++) {
                                        if (!angular.isNullOrUndefined(scope.bufDonnees[i].idGroupe) &&
                                            (scope.bufDonnees[i].idGroupe === evenement.data.element.idGroupe)) {
                                            scope.bufDonnees[i].selectionne = evenement.selectionne;
                                        }
                                    }

                                    // Actualiser le grille et le calendrier
                                    construireDonneesGrillePlanning(scope.bufDonnees).then(function () {
                                        // Maj des événements du planning
                                        construireEventSources(scope.bufDonnees, scope.bufDonneesRecurrence);

                                        // Maj sélection si dans la vue grille
                                        if (scope.modeAffichage.grille) {
                                            majSelectionGrille();
                                        }

                                        // Maj nombre d'événements sélectionnés
                                        var nbreSelect = 0;
                                        var nbreSelectLectureSeule = 0;
                                        for (i = 0; i < scope.eventDisponibles.length; i++) {
                                            if (scope.eventDisponibles[i].selectionne) {
                                                nbreSelect++;

                                                if (scope.eventDisponibles[i].lectureSeule) {
                                                    nbreSelectLectureSeule++;
                                                }
                                            }
                                        }

                                        scope.nbreEventSelectionnes = nbreSelect;
                                        scope.nbreEventLectureSeuleSelectionnes = nbreSelectLectureSeule;
                                    });
                                }

                                // Réinit flag suite drag/drop pour permettre à nouveau la sélection par click
                                scope.dragged = false;
                            };

                            /**
                             * Aller à la date du jour
                             */
                            scope.aujourdhui = function () {
                                uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('today');
                            };

                            /**
                             * Aller à la date de début de l'éménet entête
                             */
                            scope.versDateDebutEntete = function () {
                                if (!angular.isNullOrUndefined(scope.debutEntete)) {
                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('gotoDate', moment(scope.debutEntete));
                                }
                            };

                            /**
                             * Période précédente
                             */
                            scope.precedent = function () {
                                uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('prev');
                            };

                            /**
                             * Période suivante
                             */
                            scope.suivant = function () {
                                uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('next');
                            };

                            /**
                             * TODO Mantis 6217 : Ouvrir la gestion des tâches pour une intervention donnée
                             */
                            /*scope.getEventContexteMenu = function() {
                                return [{
                                    name: 'Basculer sélection',
                                    title: 'Basculer sélection',
                                    //img: '',
                                    fun: function (data, event) {
                                        var idEntete = Number(data.trigger[0].attributes['event-id-entete'].value);
                                        var selectionne = (data.trigger[0].attributes['event-selectionne'].value === 'true') ? false : true;

                                        if (!angular.isNullOrUndefined(idEntete)) {
                                            // Gérer la sélection
                                            var clientEvents = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('clientEvents');
                                            for (var i = 0; i < clientEvents.length; i++) {
                                                var eventArrierPlan = (!angular.isNullOrUndefined(clientEvents[i].rendering)) ? (clientEvents[i].rendering == "background") : false;
                                                if (!eventArrierPlan && (clientEvents[i].idEntete === idEntete)) {
                                                    clientEvents[i].selectionne = (!clientEvents[i].selectionne);

                                                    if (clientEvents[i].selectionne) {
                                                        // Evénement sélectionné (botstrap css btn-info)
                                                        clientEvents[i].backgroundColor = "#5bc0de";
                                                        clientEvents[i].borderColor = "#46b8da";
                                                    } else {
                                                        var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                        if (clientEvents[i].maitre) {
                                                            if (!angular.isNullOrUndefined(clientEvents[i].statutEntete) &&
                                                                (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutEntete) !== -1)) {
                                                                clientEvents[i].backgroundColor = "#527a7a";
                                                                clientEvents[i].borderColor = "#527a7a";
                                                            } else {
                                                                clientEvents[i].backgroundColor = "#339966";
                                                                clientEvents[i].borderColor = "#339966";
                                                            }
                                                        } else {
                                                            if (clientEvents[i].lectureSeule && (scope.listeIdLectureSeule.indexOf(clientEvents[i].id) !== -1)) {
                                                                clientEvents[i].backgroundColor = "#bbbb77";
                                                                clientEvents[i].borderColor = "#bbbb77";
                                                            } else if (!angular.isNullOrUndefined(clientEvents[i].statutEntete) &&
                                                                (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutEntete) !== -1)) {
                                                                clientEvents[i].backgroundColor = "#75a3a3";
                                                                clientEvents[i].borderColor = "#75a3a3";
                                                            } else if (!angular.isNullOrUndefined(clientEvents[i].statutLigne) &&
                                                                (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutLigne) !== -1)) {
                                                                clientEvents[i].backgroundColor = "#75a3a3";
                                                                clientEvents[i].borderColor = "#75a3a3";
                                                            } else {
                                                                // Bootstrap css btn-primary
                                                                clientEvents[i].backgroundColor = "#337ab7";
                                                                clientEvents[i].borderColor = "#2e6da4";
                                                            }
                                                        }
                                                    }
                                                }
                                            }

                                            // Redessiner les évenements
                                            uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('rerenderEvents');

                                            // Modifier la sélection de la recherche
                                            gererSelection(idEntete, selectionne);
                                        }
                                    }
                                }, {
                                    name: 'Gérer',
                                    title: 'Gérer',
                                    //img: '',
                                    fun: function (data, event) {
                                        scope.ouvrirGestionTache('Gérer');
                                    }
                                }];
                            };

                            scope.ouvrirGestionTache = function (event) {
                                alert(event);
                            };*/

                            /**
                             * Affichage grille
                             */
                            scope.affichageGrille = function () {
                                if (!scope.modeAffichage.grille) {
                                    // Basculer à l'affichage grille
                                    var agenda = scope.modeAffichage.agenda;
                                    scope.modeAffichage = {grille: true, planning: false, agenda: agenda};
                                    scope.affichageActive = "grille";

                                    // Actualiser la sélection de la grille suite bascule depuis la vue planning
                                    majSelectionGrille();
                                }
                            };

                            /**
                             * Affichage agenda
                             */
                            scope.affichageAgenda = function () {
                                if (scope.modeAffichage.grille || (scope.modeAffichage.planning && !scope.modeAffichage.agenda)) {
                                    // Bascule depuis la vue grille ?
                                    var depuisGrille = scope.modeAffichage.grille;

                                    // Basculer à l'affichage agenda
                                    scope.uiConfig.calendar.defaultDate = scope.dateDebutPeriode;
                                    scope.uiConfig.calendar.defaultView = "month";
                                    scope.uiConfig.calendar.droppable = false;
                                    scope.modeAffichage = {grille: false, planning: true, agenda: true};
                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('changeView', "month");
                                    scope.dureeEnCours = "1 mois";

                                    // Actualiser la sélection du planning suite bascule depuis la vue grille
                                    if (depuisGrille) {
                                        uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('rerenderEvents');
                                    } else if (scope.opTypePlanning === "recherche") {
                                        // Gérer affichage de la sélection en cours
                                        scope.actualiserOpPlanningDepuisCache(false);
                                    }
                                }
                            };

                            /**
                             * Affichage timeline
                             */
                            scope.affichageTimeline = function () {
                                if (scope.modeAffichage.grille || (scope.modeAffichage.planning && scope.modeAffichage.agenda)) {
                                    // Bascule depuis la vue grille ?
                                    var depuisGrille = scope.modeAffichage.grille;

                                    // Basculer à l'affichage timeline
                                    var nomVue = getNomVue(scope.dureeEnCours);
                                    scope.uiConfig.calendar.defaultDate = scope.dateDebutPeriode;
                                    scope.uiConfig.calendar.defaultView = nomVue;
                                    scope.uiConfig.calendar.droppable = (scope.eventRessources.length > 0); //true;
                                    scope.modeAffichage = {grille: false, planning: true, agenda: false};
                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('changeView', nomVue);

                                    // Actualiser la sélection du planning suite bascule depuis la vue grille
                                    if (depuisGrille) {
                                        uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('rerenderEvents');
                                    } else if (scope.opTypePlanning === "recherche") {
                                        // Gérer affichage de la sélection en cours
                                        scope.actualiserOpPlanningDepuisCache(false);
                                    }
                                }
                            };

                            /**
                             * Mantis Operia 6174 Impression du planning
                             */
                            scope.imprimerPlanning = function () {
                                if (angular.isNullOrUndefined(scope.apercuImpressionPlanning)) {
                                    scope.apercuImpressionPlanning = {};
                                }

                                if (angular.isNullOrUndefined(scope.apercuImpressionPlanning[scope.opNomModule])) {
                                    scope.apercuImpressionPlanning[scope.opNomModule] = 0;
                                }

                                scope.apercuImpressionPlanning[scope.opNomModule]++;

                                var idCalendarContainer = 'calendar-container-' + scope.opNomPlanning;
                                var nomWindow = 'Operia' + scope.opNomModule + 'Planning' + scope.apercuImpressionPlanning[scope.opNomModule].toString();
                                var titre = 'Operia - ' + scope.opNomModule + ' - Planning';
                                /*if (scope.apercuImpressionPlanning[scope.opNomModule] > 1) {
                                    titre += ' (' + scope.apercuImpressionPlanning[scope.opNomModule].toString() + ')';
                                }*/

                                var w = $window.open('', nomWindow);
                                w.document.write('<html><head><title>' + titre + '</title>');
                                w.document.write('</head><body>');
                                w.document.write('<link href="css/fullcalendar.min.css" rel="stylesheet" type="text/css" />');
                                w.document.write('<link href="css/scheduler.min.css" rel="stylesheet" type="text/css" />');
                                w.document.write('<link href="css/fullcalendar.print.min.css" rel="stylesheet" type="text/css" media="print" />');
                                w.document.write(angular.element(document.getElementById(idCalendarContainer)).html());
                                w.document.write('</body></html>');

                                $timeout(function () {
                                    w.print();
                                });
                            };

                            /**							
                             * Gestion affichage des intervenants
                             */
                            scope.afficherIntervenants = function () {
                                // Lancer fênetre modal pour  modifier l'affichage des intervenants
                                var modalRessources = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAfficherRessources.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAfficherRessourcesControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return "Afficher des intervenants";
                                        },
                                        listeRessources: function (){
                                            return scope.listeRessources;
                                        },
                                        listeRessourcesAffectees: function (){
                                            return getListeRessourcesAffectees(scope.listeRessources);
                                        },
                                        filtreRessources: function (){
                                            scope.filtreRessources.typeRessource = "Individu";
                                            return scope.filtreRessources;
                                        }
                                    }
                                });

                                modalRessources.result.then(function (listeRessources) {
                                    if (!angular.isNullOrUndefined(listeRessources)) {
                                        // Mettre à jour la liste des ressource
                                        var bufLignes = [];

                                        //if (!angular.isNullOrUndefined(scope.idEntete)) {
                                            for (var i = 0; i < listeRessources.length; i++) {
                                                var idxLigne = -1;

                                                for (var j = 0; j < scope.bufDonnees.length; j++) {
                                                    if ((scope.bufDonnees[j].typeRessource === "Intervenant") &&
                                                        (scope.bufDonnees[j].idRessource === listeRessources[i].id) &&
                                                        (scope.bufDonnees[j].libRessource === listeRessources[i].libRessource)) {
                                                        idxLigne = j;
                                                        break;
                                                    }
                                                }

                                                if (idxLigne !== -1) {
                                                    scope.bufDonnees[idxLigne].visible = true;
                                                } else {
                                                    bufLignes.push({
                                                        idEntete: scope.idEntete,
                                                        refEntete: scope.refEntete,
                                                        adresseEntete: scope.adresseEntete,
                                                        refInterneLigne: null,
                                                        numeroInterneLigne: null,
                                                        creationLigne: null,
                                                        typeRessource: "Intervenant",
                                                        idRessource: listeRessources[i].id,
                                                        libRessource: listeRessources[i].libRessource,
                                                        qteRessource: 1,
                                                        idLigne: null,
                                                        idGroupe: null,
                                                        refLigne: null,
                                                        libLigne: null,
                                                        descLigne: null,
                                                        debut: null,
                                                        fin: null,
                                                        debutReel: null,
                                                        finReelle: null,
                                                        //heureDebut: null,
                                                        //heureFin: null,
                                                        dureeJours: null,
                                                        dureeHeures: null,
                                                        avancement:null,
                                                        visible: true,
                                                        ligneVide: true,
                                                        selectionne: false
                                                    });
                                                }
                                            }

                                            for (i = 0; i < bufLignes.length; i++) {
                                                scope.bufDonnees.push(bufLignes[i]);
                                            }

                                            // Actualiser le grille et le calendrier
                                            scope.actualiserOpPlanningDepuisCache(true);
                                        //}
                                    }
                                });
                            };

                            /**
                             * Gestion affichage des matériels
                             */
                            scope.afficherMateriels = function () {
                                // Lancer fênetre modal pour  modifier l'affichage des matériels
                                var modalRessources = $modal.open({
                                    templateUrl: 'moteur/composants/planning/OpPopupAfficherRessources.html',
                                    controller: 'fr.operis.moteurV4.composants.planning.OpPopupAfficherRessourcesControleur',
                                    size: 'lg',
                                    resolve: {
                                        titre: function () {
                                            return "Afficher des matériels";
                                        },
                                        listeRessources: function (){
                                            return scope.listeRessources;
                                        },
                                        listeRessourcesAffectees: function (){
                                            return getListeRessourcesAffectees(scope.listeRessources);
                                        },
                                        filtreRessources: function (){
                                            scope.filtreRessources.typeRessource = "Matériel";
                                            return scope.filtreRessources;
                                        }
                                    }
                                });

                                modalRessources.result.then(function (listeRessources) {
                                    if (!angular.isNullOrUndefined(listeRessources)) {
                                        // Mettre à jour la liste des ressource
                                        var bufLignes = [];

                                        //if (!angular.isNullOrUndefined(scope.idEntete)) {
                                            for (var i = 0; i < listeRessources.length; i++) {
                                                var idxLigne = -1;

                                                for (var j = 0; j < scope.bufDonnees.length; j++) {
                                                    if ((scope.bufDonnees[j].typeRessource === "Matériel") &&
                                                        (scope.bufDonnees[j].idRessource === listeRessources[i].id) &&
                                                        (scope.bufDonnees[j].libRessource === listeRessources[i].libRessource)) {
                                                        idxLigne = j;
                                                        break;
                                                    }
                                                }

                                                if (idxLigne !== -1) {
                                                    scope.bufDonnees[idxLigne].visible = true;
                                                } else {
                                                    bufLignes.push({
                                                        idEntete: scope.idEntete,
                                                        refEntete: scope.refEntete,
                                                        adresseEntete: scope.adresseEntete,
                                                        refInterneLigne: null,
                                                        numeroInterneLigne: null,
                                                        creationLigne: null,
                                                        typeRessource: "Matériel",
                                                        idRessource: listeRessources[i].id,
                                                        libRessource: listeRessources[i].libRessource,
                                                        qteRessource: 1,
                                                        idLigne: null,
                                                        idGroupe: null,
                                                        refLigne: null,
                                                        libLigne: null,
                                                        descLigne: null,
                                                        debut: null,
                                                        fin: null,
                                                        debutReel: null,
                                                        finReelle: null,
                                                        //heureDebut: null,
                                                        //heureFin: null,
                                                        dureeJours: null,
                                                        dureeHeures: null,
                                                        avancement:null,
                                                        visible: true,
                                                        ligneVide: true,
                                                        selectionne: false
                                                    });
                                                }
                                            }

                                            for (i = 0; i < bufLignes.length; i++) {
                                                scope.bufDonnees.push(bufLignes[i]);
                                            }

                                            // Actualiser le grille et le calendrier
                                            scope.actualiserOpPlanningDepuisCache(true);
                                        //}
                                    }
                                });
                            };

                            /**
                             * Ecouter demande de rechargement des données
                             */
                            scope.$watch(function () {return scope.demanderRechargement;}, function (newValue, oldValue) {
                                if (!angular.isNullOrUndefined(newValue) && (newValue !== oldValue)) {
                                    // Recharger depuis la base de données
                                    actualiserOpPlanning();
                                    scope.planningModifie = false;
                                }
                            });

                            /**
                             * Ecouter sur la sélection en cours
                             */
                            scope.$watch(function () {return (angular.isNullOrUndefined(scope.opSelection)) ? null : scope.opSelection.length;}, function (newValue, oldValue) {
                                if ((scope.opTypePlanning === "recherche") && (!angular.isNullOrUndefined(newValue) && (newValue !== oldValue))) {
                                    // Mettre à jour l'affichage suite modification de la sélection en cours
                                    var i;
                                    var idElem = [];
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                    if ((scope.opNomModule === "Contact") || (scope.opNomModule === "Intervention")) {
                                        for (i = 0; i < scope.opSelection.length; i++) {
                                            if (!angular.isNullOrUndefined(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete].valeur) &&
                                                (idElem.indexOf(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete].valeur) === -1)) {
                                                idElem.push(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete].valeur);
                                            }
                                        }
                                    }

                                    if (!angular.isNullOrUndefined(uiCalendarConfig.calendars[scope.opNomPlanning])) {
                                        var clientEvents = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('clientEvents');
                                        for (i = 0; i < clientEvents.length; i++) {
                                            var eventArrierPlan = (!angular.isNullOrUndefined(clientEvents[i].rendering)) ? (clientEvents[i].rendering == "background") : false;
                                            if (!eventArrierPlan) {
                                                var select = (idElem.indexOf(clientEvents[i].idEntete) !== -1);
                                                if (clientEvents[i].selectionne !== select) {
                                                    clientEvents[i].selectionne = select;
												}

                                                if (clientEvents[i].selectionne) {
                                                    clientEvents[i].className = 'op-event-selectionne';
                                                } else {
                                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);
                                                    if (clientEvents[i].maitre) {
                                                        if (!angular.isNullOrUndefined(clientEvents[i].statutEntete) &&
                                                            (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutEntete) !== -1)) {
                                                            clientEvents[i].className = 'op-event-maitre-statut-entete';
                                                        } else {
                                                            clientEvents[i].className = 'op-event-maitre';
                                                        }
                                                    } else {
                                                        if (clientEvents[i].lectureSeule && (scope.listeIdLectureSeule.indexOf(clientEvents[i].id) !== -1)) {
                                                            clientEvents[i].className = 'op-event-lecture-seule';
                                                        } else if (!angular.isNullOrUndefined(clientEvents[i].statutEntete) &&
                                                            (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutEntete) !== -1)) {
                                                            clientEvents[i].className = 'op-event-statut-entete';
                                                        } else if (!angular.isNullOrUndefined(clientEvents[i].statutLigne) &&
                                                            (paramPlanning.statutsLecutreSeule.indexOf(clientEvents[i].statutLigne) !== -1)) {
                                                            clientEvents[i].className = 'op-event-statut-ligne';
                                                        } else {
                                                            clientEvents[i].className = 'op-event-defaut';
                                                        }
                                                    }
                                                }

                                                if (scope.opNomModule !== "Contact") {
                                                    // Redessiner l'évenement
                                                    uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('updateEvent', clientEvents[i]);
                                                }
                                            }
                                        }

                                        if (scope.opNomModule === "Contact") {
                                            // Redessiner les ressources
                                            uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('refetchResources');
                                        }
                                    }
                                }
                            });

                            /**
                             * Ecouter sur la date de début demandée
                             */
                            scope.$watch('dateDebutPeriode', function (newValue, oldValue) {
                                //if ((!angular.isNullOrUndefined(newValue)) && (newValue.toString() !== oldValue.toString())) {
                                if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                                    // Se positionner selon la date de début demandée
                                    if (!angular.isNullOrUndefined(uiCalendarConfig.calendars[scope.opNomPlanning])) {
                                        uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('gotoDate', moment(newValue));
                                    }
                                }
                            });

                            /**
                             * Ecouter sur la duration d'affichage demandée
                             */
                            scope.$watch('dureeEnCours', function (newValue, oldValue) {
                                if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                                    // Changer de vue
                                    var nomVue = getNomVue(newValue);
                                    if (!angular.isNullOrUndefined(uiCalendarConfig.calendars[scope.opNomPlanning])) {
                                        var view = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getView');
                                        if ((view.name !== "month") || ((view.name === "month") && (nomVue !== "timelineMonth"))) {
                                            uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('changeView', nomVue);
                                        }

                                        view = uiCalendarConfig.calendars[scope.opNomPlanning].fullCalendar('getView');

                                        if (view.name == 'month') {
                                            // Affichage agenda
                                            scope.uiConfig.calendar.defaultDate = moment(view.intervalStart).toDate();
                                        } else {
                                            // Affichage timeline
                                            scope.uiConfig.calendar.defaultDate = moment(view.start).toDate();
                                        }

                                        scope.uiConfig.calendar.defaultView = view.name;
                                    }
                                }
                            });

                            /**
                             * Gestion sutie modification de ressource sélectionnée
                             * @param {string} libRessourceSelect Libellé ressource sélectionnée
                             */
                            scope.changeRessourceSelect = function (libRessourceSelect) {
                                if (scope.opTypePlanning === "contact") {
                                    scope.libRessourceSelect = libRessourceSelect; //ressourceSelect.libRessource;
                                    setRessourceSelect(scope.libRessourceSelect);

                                    // Maj barre d'événements
                                    initListeEvenementDispo(scope.bufDonnees);

                                    // Gérer la statut des actions selon la sélection en cours
                                    var statut = {
                                        nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                        nbreSelect: scope.nbreEventSelectionnes,
                                        planningModifie: scope.planningModifie,
                                        lectureSeule: scope.planningLectureSeule,
                                        libRessourceSelect: scope.libRessourceSelect
                                    };
                                    scope.updateActions()(statut);
                                }
                            };

                            /**
                             * Mantis 6167 Initialisation liste des ressources pour combo de sélection de ressource (planning des indisponibilités)
                             */
                            scope.initOptionsRessourceSelect = function () {
                                var deferred = $q.defer();

                                if (scope.opTypePlanning === "contact") {
                                    var ressources = [];
                                    var paramPlanning = utilsPlanning.lireParametrePlanning(scope.opNomModule, scope.opActionCode);

                                    for (var i = 0; i < scope.opSelection.length; i++) {
                                        if (!angular.isNullOrUndefined(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete]) &&
                                            !angular.isNullOrUndefined(scope.opSelection[i][paramPlanning.colonnesEntete.idEntete]).valeur) {
                                            var idRessource = scope.opSelection[i][paramPlanning.colonnesEntete.idEntete].valeur;

                                            for (var j = 0; j < scope.listeRessources.length; j++) {
                                                if (scope.listeRessources[j].id === idRessource) {
                                                    ressources.push(scope.listeRessources[j]);
                                                    break;
                                                }
                                            }
                                        }
                                    }

                                    if (ressources.length > 0) {
                                        deferred.resolve(ressources);
                                    } else {
                                        deferred.resolve(null);
                                    }
                                } else {
                                    deferred.resolve(null);
                                }

                                return deferred.promise;
                            };

                            /**
                             * Ecouter sur le nombre d'événements sélectionnées
                             */
                            scope.$watch('nbreEventSelectionnes', function (newValue, oldValue) {
                                if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                                    // Gérer la statut des actions selon la sélection en cours
                                    var libRessourceSelect = getLibRessourceSelect();
                                    var statut = {
                                        nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                        nbreSelect: newValue,
                                        planningModifie: scope.planningModifie,
                                        lectureSeule: scope.planningLectureSeule,
                                        libRessourceSelect: libRessourceSelect
                                    };
                                    scope.updateActions()(statut);
                                }
                            });

                            /**
                             * Ecouter sur le nombre d'événements en lecture seule sélectionnées
                             */
                            scope.$watch('nbreEventLectureSeuleSelectionnes', function (newValue, oldValue) {
                                if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                                    // Gérer la statut des actions selon la sélection en cours
                                    var libRessourceSelect = getLibRessourceSelect();
                                    var statut = {
                                        nbreSelectLectureSeule: newValue,
                                        nbreSelect: scope.nbreEventSelectionnes,
                                        planningModifie: scope.planningModifie,
                                        lectureSeule: scope.planningLectureSeule,
                                        libRessourceSelect: libRessourceSelect
                                    };
                                    scope.updateActions()(statut);
                                }
                            });

                            /**
                             * Ecouter sur le statut de modification du planning
                             */
                            scope.$watch('planningModifie', function (newValue, oldValue) {
                                if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                                    var libRessourceSelect = getLibRessourceSelect();
                                    var statut = {
                                        nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                        nbreSelect: scope.nbreEventSelectionnes,
                                        planningModifie: newValue,
                                        lectureSeule: scope.planningLectureSeule,
                                        libRessourceSelect: libRessourceSelect
                                    };
                                    scope.updateActions()(statut);
                                }
                            });

                            /**
                             * Ecouter sur le statut du planning
                             */
                            scope.$watch('planningLectureSeule', function (newValue, oldValue) {
                                if ((!angular.isNullOrUndefined(newValue)) && (newValue !== oldValue)) {
                                    var libRessourceSelect = getLibRessourceSelect();
                                    var statut = {
                                        nbreSelectLectureSeule: scope.nbreEventLectureSeuleSelectionnes,
                                        nbreSelect: scope.nbreEventSelectionnes,
                                        planningModifie: scope.planningModifie,
                                        lectureSeule: newValue,
                                        libRessourceSelect: libRessourceSelect
                                    };
                                    scope.updateActions()(statut);
                                }
                            });

                            /**
                             * Chargement intiale des données
                             */
                            actualiserOpPlanning();

                            /**
                             * Gestion actualisation automatique
                             */
                            scope.finChargement({rechargerDonnees: rechargerDonnees});
                        });
                }
            };
        }
    ]);