/**
 * @version 2.0
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpOnglet',['fr.operis.moteurV4.communication.OperisWSServer'])
    // Vue de recherche des actions fiches
    .service('opOngletService',['$modal','fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.operia.utils.categoriemodule.ID_CATEGORIEMODULE_INVENTAIRE',
        function ($modal, moduleInfos, serveur, ID_CATEGORIEMODULE_INVENTAIRE) {

        this.basculerRecherche = function(onglet) {
            onglet.affichageRecherche = !onglet.affichageRecherche;
        };

        //Fonction qui permet d'afficher la section gauche
        this.afficherSectionGauche = function(onglet) {
            onglet.affichageSectionGauche = true;
        };

        //Fonction qui permet d'afficher la section droite
        this.afficherSectionDroite = function(onglet) {
            onglet.affichageSectionDroite = true;
        };

        //Fonction qui permet de masquer la section gauche
        this.masquerSectionGauche = function(onglet) {
            onglet.affichageSectionGauche = false;
        };

        //Fonction qui permet de masquer la section droite
        this.masquerSectionDroite = function(onglet) {
            onglet.affichageSectionDroite = false;
        };

        //Fonction qui permet de basculer la section gauche
        this.basculerSectionGauche = function(onglet) {
            if (onglet.affichageSectionGauche === undefined) {
                onglet.affichageSectionGauche = false;
            } else {
                onglet.affichageSectionGauche = !onglet.affichageSectionGauche;
            }
        };

        //Fonction qui permet de basculer la section droite
        this.basculerSectionDroite = function(onglet) {
            onglet.affichageSectionDroite = !onglet.affichageSectionDroite;

            // Si affichage de la fiche et ni action ni outil visible, il faut réafficher la synthèse
            if (onglet.affichageFiche && !onglet.affichageAction && !onglet.affichageOutils) {
                onglet.afficherResume();
            }
        };

        //Fonction qui permet de définir le type d'affichage de la section outils
        this.afficherOutils = function(onglet,type) {
            onglet.affichageOutils = type;

            // Si affichage d'un outil, il faut masquer la synthèse
            onglet.masquerResume();

            // Actualiser le planning
            if (type === "planning") {
                onglet.actualiserPlanningSectionDroite();
            }
        };

        //Fonction qui permet d'afficher le résumé
        this.afficherResume = function(onglet) {
            onglet.affichageResume = true;
        };

        //Fonction qui permet de masquer le résumé
        this.masquerResume = function(onglet) {
            onglet.affichageResume = false;
        };

        //Fonction qui permet d'afficher la fiche
        this.afficherFiche = function(onglet) {
            onglet.affichageFiche = true;
        };

        //Fonction qui permet de masquer la fiche
        this.masquerFiche = function(onglet) {
            onglet.affichageFiche = false;
        };

        //Fonction qui permet d'afficher le planning
        this.afficherPlanning = function(onglet) {
            onglet.affichagePlanning = true;
        };

        //Fonction qui permet de masquer le planning
        this.masquerPlanning = function(onglet) {
            onglet.affichagePlanning = false;
            onglet.fermerPlanningSectionDroite();
        };

        //Fonction qui permet d'afficher l'écran action
        this.afficherEcranAction = function(onglet) {
            onglet.affichageResume = false;
            onglet.affichageAction = true;
        };

        //Fonction qui permet de masquer l'écran action
        this.masquerEcranAction = function(onglet) {
            onglet.affichageAction = false;
        };

        //Fonction qui permet de basculer l'affichage du résumé
        this.basculerResume = function(onglet) {
            var masquerLesAutres = (!onglet.affichageResume);

            if (masquerLesAutres) {
                onglet.affichageFiche = false;
                onglet.affichagePlanning = false;
                onglet.fermerPlanningSectionDroite();
                onglet.affichageAction = false;
            }

            onglet.affichageResume = !onglet.affichageResume;
        };

        //Fonction qui permet de basculer l'affichage de la fiche
        this.basculerFiche = function(onglet) {
            var masquerLesAutres = (!onglet.affichageFiche);

            if (masquerLesAutres) {
                onglet.affichagePlanning = false;
                onglet.fermerPlanningSectionDroite();
                onglet.affichageResume = false;
                onglet.affichageAction = false;
            }

            onglet.affichageFiche = !onglet.affichageFiche;
        };

        //Fonction qui permet de basculer l'affichage de l'écran action
        this.basculerEcranAction = function(onglet) {
            var masquerLesAutres = (!onglet.affichageAction);

            if (masquerLesAutres) {
                onglet.affichageResume = false;
                onglet.affichagePlanning = false;
                onglet.fermerPlanningSectionDroite();
                onglet.affichageFiche = false;
            }

            onglet.affichageAction = !onglet.affichageAction;
        };

        // Fonction d'édition de la carto
        this.editerCoordonnees = function(onglet,model) {
            if ($window.matchMedia("(max-width: 767px)").matches){
                onglet.masquerSectionGauche();
                onglet.afficherSectionDroite();
            } else {
                onglet.afficherSectionDroite();
            }

            onglet.afficherOutils('cartographie');
            onglet.modelCarto = model;
            onglet.editionCarto = true;
        };

        // Fonction d'edition de courrier
        this.editerDocument = function(onglet,selection) {
            var popupEditionDocument = $modal.open({
                templateUrl: 'moteur/composants/popup/OpPopupEdition.html',
                controller: 'fr.operis.moteurV4.composants.OpPopupEditionControleur',
                size: 'lg',
                resolve: {
                    moduleNom: function () {
                        return onglet.moduleNom;
                    },
                    selection: function () {
                        return selection;
                    },
                    onglet:function(){
                        return onglet;
                    }
                }
            });
        };

        // Fonction de gestion des documents attachés
        this.gererDocumentsAttaches = function(onglet, selection, idDepuisSynthese) {
            var tableEntite = moduleInfos.getInfo(onglet.moduleNom, "MOD_TABLEENTITE");
            var entite = moduleInfos.getInfo(onglet.moduleNom, "MOD_ENTITE");

            if (onglet.affichageFiche || onglet.affichageResume){
                if ( angular.isNullOrUndefined(onglet.ongletCtrl.scopeFormulaire.identifiant[entite]) || onglet.ongletCtrl.scopeFormulaire.identifiant[entite]=="")
                    return alert("Veuillez enregistrer au préalable le dossier")
            }

            var popupGestionDocuments = $modal.open({
                templateUrl: 'moteur/composants/popup/OpPopupDocumentsAttaches.html',
                controller: 'fr.operis.moteurV4.composants.popup.OpPopupDocumentsAttachesControleur',
                size: 'lg',
                resolve: {
                    moduleNom: function () {
                        return onglet.moduleNom;
                    },
                    idAction: function () {
                        return 7;
                    },
                    ecranNom: function () {
                        return (moduleInfos.getInfo(onglet.moduleNom, "MOD_TRIGRAMME") + ((serveur.isMobile())?"_M":"") +  '_DOCUMENTS');
                    },
                    identifiant: function () {
                        var identifiant = {};
                        var entiteNom = moduleInfos.getInfo(onglet.moduleNom, "MOD_ENTITE");

                        // Mantis 5921 Bouton Document standard inactif en passant par un lien du résumé
                        if (onglet.affichageFiche || (onglet.affichageResume && (!angular.isNullOrUndefined(idDepuisSynthese)) && idDepuisSynthese)) {
                            identifiant[entite] = onglet.ongletCtrl.scopeFormulaire.identifiant[entite];
                        } else {
                            var idCategModule =  moduleInfos.getInfo(onglet.moduleNom, "ID_CATEGORIEMODULE");
                            if (idCategModule === ID_CATEGORIEMODULE_INVENTAIRE) {
                                identifiant[entiteNom] = selection[0][entiteNom].valeur;
                            } else {
                                identifiant["ID_" + onglet.moduleNom.replace(/Mobile/i, "").toUpperCase()] = selection[0][entiteNom].valeur;
                            }
                        }

                        return identifiant;
                    }
                }
            });
        };

        // Fonction d'affichage des messages
        this.afficherMessagerie = function(onglet,selection,onChangeEtat) {
            var popupMessagerie = $modal.open({
                templateUrl: 'moteur/composants/popup/OpPopupMessagerie.html',
                controller: 'fr.operis.moteurV4.composants.popup.OpPopupMessagerieControleur',
                size: 'lg',
                resolve: {
                    onglet: function () {
                        return onglet;
                    },
                    selection: function () {
                        return selection;
                    },
                    onChangeEtat: function () {
                        return onChangeEtat;
                    }
                }
            });
        };

        // Fonction de récupération de l'écran d'édition d'une recherche
        this.getEcranEdition = function (aScope,aTableNom){
          var descriptionChamps, descriptionLien;

          if ( angular.isNullOrUndefined(aScope.descriptions[aTableNom]))
            return null;

          for  (var i=0;i<aScope.descriptions[aTableNom].length;i++){
              descriptionChamps = aScope.descriptions[aTableNom][i];
              if ( !angular.isNullOrUndefined(descriptionChamps.lien) && descriptionChamps.lien.length>0 ){
                  descriptionLien = descriptionChamps.lien.split('|');
                  return descriptionLien[1];
              }
          }
        };

        /**
         * Fonction qui envoie le texte à afficher dans la partie CONTEXTE de l'onglet
         * @param {Object}  Onglet en cours
         * @param {String}  Contexte à afficher
         */
        this.definirTexteContextuel = function (aOnglet,aContexte){
            if ( !angular.isNullOrUndefined(aContexte) && aContexte.length > 0 )
                aOnglet.contexte = aContexte;
            else
                aOnglet.contexte = "";
        }
    }])

    .directive('opOnglet',
    ['$q', '$modal', '$ocLazyLoad', 'fr.operis.moteurV4.communication.OperisWSServer',
        'opOngletService',
        'ongletActifService',
        'fr.operis.moteurV4.utils.Config',
        'fr.operis.moteurV4.utils.Planning',
        'fr.operis.moteurV4.utils.RequetePreSauvegardeFiche',
        'fr.operis.moteurV4.utils.RequetePostSauvegardeFiche',
        'fr.operis.moteurV4.utils.Habilitation',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.composants.OpPopupAlerte',	
        'fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFiche',
        '$timeout'
        ,function ($q, $modal, $ocLazyLoad, serveur,
                   ongletService,
                   ongletActifService,
                   $config,
                   utilsPlanning,
                   requetePreSauvegardeFiche,
                   requetePostSauvegardeFiche,
                   habilitation,
                   moduleInfos,
                   opPopupAlerte,				   
                   opPopupEnregistrementFiche,
                   $timeout
                ) {
            return {
                restrict: 'E',
                replace:false,
                scope:{
                    onglet:'='
                },
                template: function (element, attributs){
                    var retour ='<!-- Recherche -->'+
                                '<div ng-hide="true"> <canvas width="3" height="3" id="canvasResizeImage" /></div>'+
                                '<div class="recherche" ng-show="onglet.affichageRecherche">'+
                                    '<ul class="list-group">'+
                                        '<accordion>'+
                                            '<op-onglet-recherche ng-repeat="ecranRecherche in ongletCtrl.listeEcranRecherche" module-nom="onglet.moduleNom" action-code="onglet.actionCode"' +
                                            ' ecran-libelle="ecranRecherche.ECRANLIBELLE.valeur" ecran-nom="ecranRecherche.ECRANNOM.valeur" default="ecranRecherche.PARDEFAUT.valeur"' +
                                            ' echap-defaut="onglet.echapRechDefaut"' +
                                            ' fin-chargement="ongletCtrl.finChargementRecherche(scope,ecrannom,ecranlibelle,tablenom)" ' +
                                            '>' +
                                            '</op-onglet-recherche>'+
                                        '</accordion>'+
                                    '</ul>'+
                                '</div>'+
                                '<div ng-hide="onglet.affichageRecherche" ng-style="{\'background-color\' : onglet.moduleCouleurPrimaire}"><op-onglet-barre-fonctions ></op-onglet-barre-fonctions></div>'+
                                '<div class="row wrapper-gauche-droite" ng-hide="onglet.affichageRecherche" ng-show="ongletCtrl.bRechercheSelectionne || onglet.affichageFiche || onglet.affichageResume || onglet.affichagePlanning" ng-style="{\'background-color\' : onglet.moduleCouleurPrimaire}">'+
                                    '<!-- Section gauche -->'+
                                    '<div ng-show="!onglet.affichageRecherche && onglet.affichageSectionGauche" class="section section-gauche" ng-class="{\'col-sm-12\':onglet.affichageSectionGauche&&!onglet.affichageSectionDroite,\'col-sm-6\':onglet.affichageSectionGauche&&onglet.affichageSectionDroite}" > '+
                                        '<!-- Type/Résultat de Recherche -->'+
                                        '<op-onglet-resultat ></op-onglet-resultat>'+
                                        '<!-- Fiche Saisie-->'+
                                        '<div ng-show="onglet.affichageFiche" class="fiche">'+
                                            '<op-onglet-fiche onglet="onglet" mode-edition="false"></op-onglet-fiche>'+
                                        '</div>'+
                                        '<div ng-show="onglet.affichagePlanning" class="fiche">'+
                                            '<op-onglet-planning onglet="onglet"></op-onglet-planning>'+
                                        '</div>'+
                                    '</div>'+
                                    '<!-- Section droite -->'+
                                    '<div ng-show="!onglet.affichageRecherche && onglet.affichageSectionDroite" class="section section-droite" ng-class="{\'col-sm-12\':!onglet.affichageSectionGauche&&onglet.affichageSectionDroite,\'col-sm-6\':onglet.affichageSectionGauche&&onglet.affichageSectionDroite}">'+
                                        '<!-- Outils Carto,Planning -->'+
                                        '<div ng-show="!onglet.affichageResume && !onglet.affichageAction" class="outils">'+
                                            '<op-onglet-outils ></op-onglet-outils>'+
                                        '</div>'+
                                        '<!-- Fiche Edition -->'+
                                        '<div ng-show="onglet.affichageResume" class="fiche">'+
                                            '<op-onglet-fiche mode-edition="true"></op-onglet-fiche>'+
                                        '</div>'+
                                        '<!-- Ecran Action -->'+
                                        '<div ng-show="onglet.affichageAction" class="fiche">'+
                                            '<div class="btn-group barre-fonctions hidden-xs">'+
                                                '<a class="btn btn-default" href="" ng-click="onglet.basculerSectionGauche()"><span class="fa" ng-class="{\'fa-expand\': onglet.affichageSectionGauche, \'fa-columns\': !onglet.affichageSectionGauche}"></span></a>'+
                                            '</div>'+
                                            '<div class="btn-group barre-fonctions">'+
                                                '<a class="btn btn-default" href="" ng-click="ongletCtrl.sauvegarderAction()"><span class="fa fa-floppy-o"></span></a>'+
                                                '<a class="btn btn-default" href="" ng-click="ongletCtrl.fermerAction()"><span class="fa fa-times"></span></a>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                '</div>';

                    return retour;
                },
                controllerAs:'ongletCtrl',
                controller: ['$scope','$window',/*'$element',*/
                    function($scope,$window/*,$element*/){
                        var vm = this;

                        // Description et données de recherche
                        vm.listeEcranRecherche;
                        vm.descriptionRecherche;
                        vm.criteresRecherche; // Mantis 5979 Critères de recherche
                        vm.donneesRecherche;
						vm.donneesListe;

                        vm.descriptionCarto;
                        vm.donneesCarto;
                        vm.selectionRecherche;
                        vm.selectionRechercheCle;
                        vm.filtre;
                        vm.selectionnerTout;
                        vm.pageChanged;
                        vm.retourRechercheOnglet;
                        vm.actualiser;
                        vm.finChargementRecherche;
                        vm.ecranCourantRecherche;
                        vm.rechercheParDefaut;
                        vm.bRechercheSelectionne = false;
                        vm.nomRechercheEnCours;

                        // Nombre de pages de recherche
                        vm.nombrePages = 0;

                        // Numéro de la page de recherche
                        vm.numeroPage = 1;

                        // Taille d'une page de recherche
                        vm.taillePage = $config.bureauLimiteElement;

                        // Mantis 6117 : Taille d'une page de recherche personnalisé
                        vm.seuilsTaillePage = [
                            {seuilId: 10, seuil: '10'},
                            {seuilId: 20, seuil: '20'},
                            {seuilId: 30, seuil: '30'},
                            {seuilId: 40, seuil: '40'},
                            {seuilId: 50, seuil: '50'},
                            {seuilId: 100, seuil: '100'},
                            {seuilId: 150, seuil: '150'},
                            {seuilId: 200, seuil: '200'},
                            {seuilId: 250, seuil: '250'},
                            {seuilId: 500, seuil: '500'},
                            {seuilId: 1000, seuil: '1000'},
                            {seuilId: 5000, seuil: '5000'},
                            {seuilId: 10000, seuil: '10000'}
                        ];

                        vm.taillePagePerso = {
                            seuilId: vm.taillePage,
                            seuil: vm.taillePage.toString()
                        };

                        vm.taillePageMosaique = {
                            seuilId: $config.bureauLimiteElement,
                            seuil: $config.bureauLimiteElement.toString()
                        };

                        vm.appliquerTaillePagePerso = function(taillePage) {
                            //$ocLazyLoad.load({serie: true, files: ['lib/jquery.min.js']}).then(function () {
                                //$($element[0]).blur(); // Retirer le focus
                                vm.taillePage = Number(taillePage.seuil);
                                vm.nombrePages = Math.ceil(vm.tailleTotale / vm.taillePage);
                                vm.numeroPage = 1;
                                $scope.rechercheOnglet[vm.ecranCourantRecherche]();
                            //});
                        };
						
                        // Taille totale de la recherche
                        vm.tailleTotale = 0;

                        // Paramètres planning
                        vm.nomRecherchePlanning;
                        vm.descriptionRecherchePlanning;
                        vm.donneesRecherchePlanning;
                        vm.typeRecherchePlanning;
                        vm.contexteRecherchePlanning;
                        vm.moduleRecherchePlanning;
                        vm.actionCodeRecherchePlanning;
                        vm.planningParameter = {};

                        // Déclaration des Fonctions pour le planning
                        vm.ouvrirPlanning = ouvrirPlanning;
                        vm.actionPlanning = actionPlanning;
                        vm.fermerPlanning = fermerPlanning;
                        vm.sauvegarderPlanning = sauvegarderPlanning;
                        vm.rechargerPlanning = rechargerPlanning;
                        vm.affichageGrillePlanning = affichageGrillePlanning;
                        vm.affichageTimelinePlanning = affichageTimelinePlanning;
                        vm.affichageAgendaPlanning = affichageAgendaPlanning;
                        vm.finChargementPlanning =  finChargementPlanning;
						vm.rechercheSelonTri = rechercheSelonTri; // Mantis Operia 6195

                        // Déclaration des Fonctions pour la fiche
                        vm.ouvrirFiche = ouvrirFiche;
                        vm.editerFiche = editerFiche;
                        vm.resumerFiche = resumerFiche;
                        vm.sauvegarderFiche = sauvegarderFiche;
                        vm.rechargerFiche = rechargerFiche;
                        vm.fermerFiche = fermerFiche;
                        vm.vueFormulaireFiche;
                        vm.vueFormulaireFicheSynthese;
                        vm.demandeDetailEntite = demandeDetailEntite;

                        // Déclaration des Fonctions
                        vm.sauvegarderAction = sauvegarderAction;
                        vm.fermerAction = fermerAction;
                        vm.chargerDonneesCartoDepuisFiche = chargerDonneesCartoDepuisFiche;
                        vm.selectionnerTout = selectionnerTout;
                        vm.pageChanged = pageChanged;
                        vm.retourRechercheOnglet = retourRechercheOnglet;
                        vm.actualiser = actualiser;
                        vm.rechercheDernierDossiersCrees = rechercheDernierDossiersCrees;
                        vm.finChargementRecherche = finChargementRecherche;

                        $scope.onglet["ongletCtrl"] = vm;

                        // Fonctions de service pour le scope interne et externe
                        $scope.onglet.basculerRecherche = function(){
                            return ongletService.basculerRecherche($scope.onglet);
                        };

                        $scope.onglet.afficherSectionGauche = function(){
                            return ongletService.afficherSectionGauche($scope.onglet);
                        };

                        $scope.onglet.afficherSectionDroite= function(){
                            return ongletService.afficherSectionDroite($scope.onglet);
                        };

                        $scope.onglet.masquerSectionGauche= function(){
                            return ongletService.masquerSectionGauche($scope.onglet);
                        };

                        $scope.onglet.masquerSectionDroite= function(){
                            return ongletService.masquerSectionDroite($scope.onglet);
                        };

                        $scope.onglet.basculerSectionGauche= function(){
                            return ongletService.basculerSectionGauche($scope.onglet);
                        };

                        $scope.onglet.basculerSectionDroite= function(){
                            return ongletService.basculerSectionDroite($scope.onglet);
                        };

                        $scope.onglet.afficherOutils= function(type){
                            return ongletService.afficherOutils($scope.onglet,type);
                        };

                        $scope.onglet.afficherResume= function(){
                            return ongletService.afficherResume($scope.onglet);
                        };

                        $scope.onglet.afficherFiche = function(){
                            return ongletService.afficherFiche($scope.onglet);
                        };

                        $scope.onglet.afficherPlanning = function(){
                            return ongletService.afficherPlanning($scope.onglet);
                        };

                        $scope.onglet.afficherEcranAction = function(){
                            return ongletService.afficherEcranAction($scope.onglet);
                        };

                        $scope.onglet.masquerResume= function(){
                            return ongletService.masquerResume($scope.onglet);
                        };

                        $scope.onglet.masquerFiche = function(){
                            return ongletService.masquerFiche($scope.onglet);
                        };

                        $scope.onglet.masquerPlanning = function(){
                            return ongletService.masquerPlanning($scope.onglet);
                        };

                        $scope.onglet.masquerEcranAction = function(){
                            return ongletService.masquerEcranAction($scope.onglet);
                        };

                        $scope.onglet.basculerResume= function(){
                            return ongletService.basculerResume($scope.onglet);
                        };

                        $scope.onglet.basculerFiche= function(){
                            return ongletService.basculerFiche($scope.onglet);
                        };

                        $scope.onglet.basculerEcranAction = function(){
                            return ongletService.basculerEcranAction($scope.onglet);
                        };

                        $scope.onglet.editerDocument = function(){
                            // Mantis 5963 Bouton Edition standard (Intervention) inactif en passant par le lien du résumé Demande
                            if (!angular.isNullOrUndefined($scope.onglet.idEntite) &&
                                !angular.isNullOrUndefined($scope.onglet.idEntiteValeur) &&
                                (angular.isNullOrUndefined(vm.selectionRecherche) || (vm.selectionRecherche.length == 0))) {
                                // Renseigner sélection
                                var selection = {};
                                selection[$scope.onglet.idEntite] = $scope.onglet.idEntiteValeur.valeur;

                                // Mantis 6073 Edition impossible suite ouverture onglet par lien dans synthèse
                                var tableauSelection = [];
                                tableauSelection.push(selection);

                                return ongletService.editerDocument($scope.onglet, tableauSelection);
                            } else {
                                return ongletService.editerDocument($scope.onglet, vm.selectionRecherche);
                            }
                        };

                        $scope.onglet.extraireListe = function(){
                            $ocLazyLoad.load({
                                    serie: true, files: [
                                        'lib/ng-csv.min.js',
                                        'lib/angular-sanitize.min.js'
                                    ]
                                })
                                .then(function () {
                                    var modalExportCSV = $modal.open({
                                        templateUrl: 'moteur/composants/popup/OpPopupOptionExportCSV.html',
                                        controller: 'fr.operis.moteurV4.composants.popup.OpPopupOptionExportCSVCtrl',
                                        size: 'sm',
                                        resolve: {
                                            // Nom du module
                                            moduleNom: function () {
                                                return $scope.onglet.moduleNom;
                                            },
                                            // Sélection en cours
                                            selectionCourante: function () {
                                                return $scope.ongletCtrl.donneesRecherche;
                                            },
                                            // Ecran de recherche
                                            ecranRecherche:function(){
                                                return $scope.ongletCtrl.ecranCourantRecherche;
                                            },
                                            // Mantis 5979 Critères de recherche en cours
                                            criteresRecherche: function () {
                                                return $scope.ongletCtrl.criteresRecherche;
                                            },
                                            // Page courante
                                            pageCourante:function(){
                                                return $scope.ongletCtrl.numeroPage;
                                            },
                                            // Nombres de pages
                                            nombrePages:function(){
                                                return $scope.ongletCtrl.nombrePages;
                                            },
                                            // Mantis 6117 : Taille d'une page de recherche personnalisé
                                            taillePage: function () {
                                                return $scope.ongletCtrl.taillePage;
                                            }
                                        }
                                    });
                                })

                            // TODO A NETTOYER ANCIENNE FONCTION EXPORT CSV
                        };

                        $scope.onglet.gererDocumentsAttaches = function(){
                            // Mantis 5921 Bouton Document standard inactif en passant par un lien du résumé
                            if (!angular.isNullOrUndefined($scope.onglet.idEntite) &&
                                !angular.isNullOrUndefined($scope.onglet.idEntiteValeur) &&
                                (angular.isNullOrUndefined(vm.selectionRecherche) || (vm.selectionRecherche.length == 0))) {
                                // Renseigner sélection
                                var selection = {};
                                selection[$scope.onglet.idEntite] = $scope.onglet.idEntiteValeur.valeur;

                                // Mantis 6073 Edition impossible suite ouverture onglet par lien dans synthèse
                                var tableauSelection = [];
                                tableauSelection.push(selection);

                                return ongletService.gererDocumentsAttaches($scope.onglet, tableauSelection, true);
                            } else {
                                return ongletService.gererDocumentsAttaches($scope.onglet, vm.selectionRecherche, false);
                            }
                        };

                        $scope.onglet.afficherMessagerie = function(onChangeEtat){
                            return ongletService.afficherMessagerie($scope.onglet, vm.selectionRecherche, onChangeEtat);
                        };

                        $scope.onglet.ouvrirFiche = function(aEntiteRecherche, aEstAction, aAffichagePleinEcran){
                            return vm.ouvrirFiche (aEntiteRecherche, aEstAction, aAffichagePleinEcran);
                        };

                        $scope.onglet.editerFiche = function(args){
                            return vm.editerFiche (args);
                        };

                        $scope.onglet.sauvegarderFiche = function(){
                            return vm.sauvegarderFiche ();
                        };

                        $scope.onglet.ouvrirPlanning = function (args) {
                            return vm.ouvrirPlanning(args);
                        };

                        $scope.onglet.actionPlanning = function (args) {
                            return vm.actionPlanning(args);
                        };

                        // Selection de l'onglet
                        $scope.onglet.getSelection = function(){
                            return vm.selectionRecherche;
                        };

                        // Formulaire Fiche
                        $scope.onglet.getFormulaireFiche = function(){
                            return vm.vueFormulaireFiche;
                        };

                        // Nombre de dossier créer par utilisateur
                        $scope.onglet.nombreDernierDossiersCrees = 0;

                        // Ouverture Fiche
                        function ouvrirFiche (entiteRecherche, estAction, estAffichagePleinEcran) {
                            var onglet = $scope.onglet;

                            // Gestion du contexte
                            var titre;
                            for (var property in entiteRecherche) {
                                if (!angular.isNullOrUndefined(entiteRecherche[property]) &&
                                    !angular.isNullOrUndefined(entiteRecherche[property].descriptions) &&
                                    !angular.isNullOrUndefined(entiteRecherche[property].descriptions.estChampPrincipal) &&
                                    entiteRecherche[property].descriptions.estChampPrincipal == true )
                                    titre = entiteRecherche[property].valeur;
                            }
                            ongletService.definirTexteContextuel(onglet, titre);

                            // Affichage de l'entite
                            var identifiant = {};
                            var tabNomsIdentifiants = onglet.moduleEntite.split(";");

                            for (var i = 0; i < tabNomsIdentifiants.length; i++) {
                                identifiant[tabNomsIdentifiants[i]] = entiteRecherche[tabNomsIdentifiants[i]].valeur;
                            }

                            // Droit de mofification
                            onglet.droitAccessFiche = entiteRecherche.DROITACCES;

                            var ecranFiche = onglet.moduleTrigramme + ((serveur.isMobile())?"_M":"") + "_FICHE";
                            var identifiantFiche = onglet.moduleEntite;
                            var args = {
                                nomModule: onglet.moduleNom,
                                nomEcran: ecranFiche,
                                identifiant: identifiant,
                                entiteFiche: entiteRecherche,
                                estAction: estAction
                            };
                            $scope.$broadcast('OperisOnglet_OuvrirFicheSyntheseEvt', args);

                            if ($window.matchMedia("(max-width: 767px)").matches || (estAffichagePleinEcran == true)) {
                                ongletService.masquerSectionGauche(onglet);
                                ongletService.afficherSectionDroite(onglet);
                            } else {
                                ongletService.afficherSectionDroite(onglet);
                            }

                            if ((!angular.isNullOrUndefined(estAction)) && estAction) {
                                ongletService.afficherEcranAction(onglet);
                            } else {
                                ongletService.masquerEcranAction(onglet);
                                ongletService.afficherResume(onglet);
                            }
                        }

                        // Editer Fiche
                        function editerFiche (args) {

                            $scope.$broadcast('OperisOnglet_OuvrirFicheEvt',args);

                            $scope.onglet.masquerSectionDroite();
                            $scope.onglet.masquerResume();
                            $scope.onglet.afficherSectionGauche();
                            $scope.onglet.afficherFiche();
                        }

                        // Actualiser le planning
                        $scope.onglet.actualiserPlanningSectionDroite = function () {
                            $scope.$broadcast('OperisOnglet_ActualiserPlanningSectionDroiteEvt', {nomPlanning: vm.nomRecherchePlanning, typePlanning: vm.typeRecherchePlanning, confirmerAction: false});
                        };

                        // Fermer le planning (vue recherche section droite)
                        $scope.onglet.fermerPlanningSectionDroite = function () {
                            $scope.$broadcast('OperisOnglet_FermerPlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning, sauvegarde: false});
                        };

                        // Ouvrir planning
                        function ouvrirPlanning (args) {
                            // Gestion du contexte
                            var titre = "";

                            if (!angular.isNullOrUndefined(args.titreContexte) && !angular.isNullOrUndefined(args.titreContexte)) {
                                titre = args.titreContexte;
                            } else if (!angular.isNullOrUndefined(args.selection) && !angular.isNullOrUndefined(args.selection[0])) {
                                var selection = args.selection[0];
                                for (var property in args.selection[0]) {
                                    if (!angular.isNullOrUndefined(selection[property].descriptions) && !angular.isNullOrUndefined(selection[property].descriptions.estChampPrincipal) && selection[property].descriptions.estChampPrincipal == true)
                                        titre = selection[property].valeur;
                                }
                            }

                            ongletService.definirTexteContextuel($scope.onglet, titre);

                            // Cacher volet outils et résumé
                            $scope.onglet.masquerSectionDroite();
                            $scope.onglet.masquerResume();
                            $scope.onglet.masquerPlanning();

                            // Ouverture du planning
                            $scope.$broadcast('OperisOnglet_OuvrirPlanningEvt', args);
                            $scope.onglet.afficherSectionGauche();
                            $scope.onglet.afficherPlanning();

                            // Actualiser le planning
                            $scope.$broadcast('OperisOnglet_ActualiserPlanningEvt', {nomPlanning: args.nomPlanning, typePlanning: args.typePlanning, confirmerAction: false});
                        }

                        // Exécuter action planning
                        function actionPlanning (args) {
                            $scope.$broadcast('OperisOnglet_ActionPlanningEvt', args);
                        }

                        // Résumer Fiche
                        function resumerFiche(args){
                            $scope.$broadcast('OperisOnglet_OuvrirFicheSyntheseEvt',args);
                            $scope.onglet.afficherResume();
                            $scope.onglet.afficherSectionDroite();
                            $scope.onglet.masquerSectionGauche();
                            $scope.onglet.masquerFiche();
                            $scope.onglet.masquerPlanning();
                        }

                        function sauvegarderFiche (){
                            var deferred = $q.defer();

                            if ( !vm.vueFormulaireFiche.vueModifie.$valid )
                                return alert("Données mal renseignées. Enregistrement impossible");

                            // Action de controle de préSauvegarde (contrôle, calcul)
                            requetePreSauvegardeFiche.execution($scope,$scope.onglet.moduleNom,$scope.onglet.id_categoriemodule,
                                vm.scopeFormulaire,vm.vueFormulaireFiche.idAction)
                                .then(function(retourPreSauvegarde){
                                    vm.vueFormulaireFiche.sauvegarde().then(
                                        function (retourSauvegarde) {
                                            // Action postSauvegarde (mise à jour des statuts, de l'historique)
                                            requetePostSauvegardeFiche.execution($scope,$scope.onglet.moduleNom,$scope.onglet.id_categoriemodule,
                                                retourSauvegarde,vm.vueFormulaireFiche.idAction);

                                            if ( vm.vueFormulaireFiche.idAction == 1 )
                                                vm.vueFormulaireFiche.idAction = 2;

                                            // Réinit OpCalendarRessources nécessaire
                                            /*if (!angular.isNullOrUndefined(vm.planningParameter.clefFiche)) {
                                                vm.planningParameter.clefFiche.nbreAppels = 0;
                                                vm.planningParameter.clefFiche.id = null;
                                            }*/

                                            vm.chargerDonneesCartoDepuisFiche(vm.vueFormulaireFiche);

                                            if ($scope.onglet.editionCarto) {
                                                $scope.onglet.editionCarto = false;
                                                $scope.onglet.modelCarto = null;
                                            }

                                            $scope.actualisationFiche = true;

                                            deferred.resolve();
                                        }, function(erreur){
                                            deferred.reject(erreur);
                                        }
                                    );
                                })


                            return deferred.promise;
                        }

                        function rechargerFiche(){
                            // Message de confirmation
                            var reponse = confirm("Les éléments saisis seront définitivement annulés. Etes-vous sûr de vouloir continuer ?");

                            if ( reponse ){
                                vm.vueFormulaireFiche.recharger();
                                if ($scope.onglet.editionCarto) {
                                    $scope.onglet.editionCarto = false;
                                    $scope.onglet.modelCarto = null;
                                }
                            }

                        }

                        function fermerFiche(aForceFermeture){
                            var deferred = $q.defer();

                            // Modification ?// Fiche Saisie
                            if (  angular.isNullOrUndefined(aForceFermeture) && !angular.isNullOrUndefined(vm.vueFormulaireFiche) && !angular.isNullOrUndefined(vm.vueFormulaireFiche.vueModifie) && !vm.vueFormulaireFiche.vueModifie.$pristine ){
                                var popupEnregistrement = opPopupEnregistrementFiche.creer();
                                return popupEnregistrement.result.then(function(aValidation){
                                    if ( aValidation ){
                                        vm.sauvegarderFiche()
                                            .then(function(){
                                                $scope.actualisationFiche = true;
                                                return vm.fermerFiche();
                                            })
                                    }
                                    else {vm.vueFormulaireFiche.vueModifie.$setPristine();return vm.fermerFiche(true)};
                                },function(){
                                    // Annulation
                                })
                            }


                            if ($scope.onglet.affichageResume) {
                                // Mantis 5963 Bouton Edition standard (Intervention) inactif en passant par le lien du résumé Demande
                                if (!$scope.onglet.faireBasculeSynthese) {
                                    // Fiche Résumé
                                    $scope.onglet.masquerSectionDroite();
                                    $scope.onglet.masquerResume();
                                    $scope.onglet.afficherSectionGauche();
                                } else {
                                    $scope.onglet.faireBasculeSynthese = false;
                                }
                            } else {
                                // Fiche Saisie
                                // Fermer l'écran fiche et replacer les données carto de recherche
                                $scope.onglet.masquerFiche();

                                if ( !angular.isNullOrUndefined( $scope.actualisationFiche ) && $scope.actualisationFiche )
                                    vm.actualiser();

                                if ($scope.onglet.editionCarto) {
                                    $scope.onglet.editionCarto = false;
                                    $scope.onglet.modelCarto = null;
                                }

                            }

                            // Réinit OpCalendarRessources nécessaire
                            /*if (!angular.isNullOrUndefined(vm.planningParameter.clefFiche)) {
                                vm.planningParameter.clefFiche.nbreAppels = 0;
                                vm.planningParameter.clefFiche.id = null;
                            }*/

                            ongletService.definirTexteContextuel($scope.onglet,"");

                            $scope.onglet.faireBasculeSynthese = false;

                            deferred.resolve();
                            return deferred.promise;
                        }

                        function sauvegarderPlanning () {
                            $scope.$broadcast('OperisOnglet_SauvegarderPlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning});
                        }

                        function rechargerPlanning() {
                            $scope.$broadcast('OperisOnglet_RechargerPlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning, typePlanning: vm.planningParameter.typePlanning, confirmerAction: vm.planningParameter.planningModifie});
                        }

                        function affichageGrillePlanning() {
                            $scope.$broadcast('OperisOnglet_AffichageGrillePlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning});
                        }

                        function affichageTimelinePlanning() {
                            $scope.$broadcast('OperisOnglet_AffichageTimelinePlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning});
                        }

                        function affichageAgendaPlanning() {
                            $scope.$broadcast('OperisOnglet_AffichageAgendaPlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning});
                        }

                        function fermerPlanning() {
                            // Sauvegarde avant fermêture ?
                            if (vm.planningParameter.planningModifie){
                                var popupEnregistrement = opPopupEnregistrementFiche.creer();
                                return popupEnregistrement.result.then(function (aValidation) {
                                    $scope.$broadcast('OperisOnglet_FermerPlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning, sauvegarde: aValidation});

                                    if ($scope.onglet.affichageResume) {
                                        // Fermer l'écran résumé et réafficher la section de gauche
                                        $scope.onglet.masquerSectionDroite();
                                        $scope.onglet.masquerResume();
                                        $scope.onglet.afficherSectionGauche();
                                    } else {
                                        // Fermer l'écran planning et replacer les données carto de recherche
                                        $scope.onglet.masquerPlanning();

                                        vm.actualiser();

                                        if ($scope.onglet.editionCarto) {
                                            $scope.onglet.editionCarto = false;
                                            $scope.onglet.modelCarto = null;
                                        }
                                    }

                                    // Réinit OpCalendarRessources nécessaire
                                    /*if (!angular.isNullOrUndefined(vm.planningParameter.clefFiche)) {
                                        vm.planningParameter.clefFiche.nbreAppels = 0;
                                        vm.planningParameter.clefFiche.id = null;
                                    }*/

                                    ongletService.definirTexteContextuel($scope.onglet,"");
                                },function(){
                                    // Annulation
                                })
                            } else {
                                $scope.$broadcast('OperisOnglet_FermerPlanningEvt', {nomPlanning: vm.planningParameter.nomPlanning, sauvegarde: false});

                                if ($scope.onglet.affichageResume) {
                                    // Fermer l'écran résumé et réafficher la section de gauche
                                    $scope.onglet.masquerSectionDroite();
                                    $scope.onglet.masquerResume();
                                    $scope.onglet.afficherSectionGauche();
                                } else {
                                    // Fermer l'écran planning et replacer les données carto de recherche
                                    $scope.onglet.masquerPlanning();

                                    vm.actualiser();

                                    if ($scope.onglet.editionCarto) {
                                        $scope.onglet.editionCarto = false;
                                        $scope.onglet.modelCarto = null;
                                    }
                                }

                                // Réinit OpCalendarRessources nécessaire
                                /*if (!angular.isNullOrUndefined(vm.planningParameter.clefFiche)) {
                                    vm.planningParameter.clefFiche.nbreAppels = 0;
                                    vm.planningParameter.clefFiche.id = null;
                                }*/

                                ongletService.definirTexteContextuel($scope.onglet,"");
                            }
                        }

                        /**
                         * Sauvegarder saisie dans l'écran action
                         */
                        function sauvegarderAction () {
                            // Sauvegarder saisie dans l'écran action puis fermer l'écran
                            $scope.onglet.vueFormulaireAction.sauvegarde()
                                .then(function(reponse) {

                                    });
                            fermerAction();
                        }

                        /**
                         * Fermer l'écran action
                         */
                        function fermerAction () {
                            $scope.onglet.masquerEcranAction();
                            $scope.onglet.masquerSectionDroite();
                            $scope.onglet.afficherSectionGauche();
                        }

                        function chargerDonneesCartoDepuisFiche(vueFormulaire) {

                        }

                        function selectionnerTout(){
                            var lengthSelection = vm.selectionRecherche.length;
                            vm.selectionRecherche = [];
                            if (lengthSelection != vm.donneesRecherche.length){
                                for (var i=0;i<vm.donneesRecherche.length;i++){
                                    vm.selectionRecherche.push(vm.donneesRecherche[i])
                                }
                            }
                        }

                        //Fonction de changement de page de recherche
                        function pageChanged(){
                            $scope.recherchePageOnglet[vm.ecranCourantRecherche](vm.numeroPage);
                        }

                        //Fonction d'actualiser
                        function actualiser(echapRechDernierDossiersCrees){

                            if ( !angular.isNullOrUndefined ( $scope.actualisationFiche ))
                                $scope.actualisationFiche = false;

                            if ( !angular.isNullOrUndefined( $scope.recherchePageOnglet) && !angular.isNullOrUndefined($scope.recherchePageOnglet[vm.ecranCourantRecherche]) && $config.bureauRaffraichissementAuto == true ){
                                $scope.recherchePageOnglet[vm.ecranCourantRecherche](vm.numeroPage);
                            }

                            if (angular.isNullOrUndefined(echapRechDernierDossiersCrees)) {
                                $scope.ongletCtrl.rechercheDernierDossiersCrees();
                            } else if (!echapRechDernierDossiersCrees) {
                                $scope.ongletCtrl.rechercheDernierDossiersCrees();
                            }
                        }

                        // Mantis Operia 6195 : Recherche avec tri personnalisé
                        function rechercheSelonTri(aTri) {
                            vm.numeroPage = 1;
                            $scope.rechercheOnglet[vm.ecranCourantRecherche](null, null, aTri);
                        }

                        // Fin de chargement des recherches plannings
                        function finChargementPlanning(rechargerDonnees){
                            vm.actualiserPlanningSectionDroite = rechargerDonnees;
                        }

                        // Retour de la recherche d'onglet
                        function retourRechercheOnglet (aScope, aEcranNom, aNumeroPage, aRetourRecherche){
                            if ( aNumeroPage == 1 ){
                                vm.tailleTotale = aRetourRecherche[1];
                                vm.nombrePages = Math.ceil(vm.tailleTotale / vm.taillePage);
                                vm.ecranCourantRecherche = aEcranNom;
                                vm.numeroPage = aNumeroPage;

                                // Chargement de la description de la grille
                                vm.descriptionRecherche = vm.descriptionCarto = aScope.descriptions[aRetourRecherche[0][0]];
                                vm.criteresRecherche = aScope.champs[aRetourRecherche[0][0]]; // Mantis 5979 Critères de recherche
                                vm.donneesListe = vm.listes;
                            }


                            // Chargement des données de la grille avec le droit d'accès sur les services
                            vm.donneesRecherche = vm.donneesCarto = aScope.grilles[aRetourRecherche[0][0]].map(function(item){
                                item["DROITACCES"] = 2;
                                if ( !angular.isNullOrUndefined( item.ID_SERVICEGESTIONNAIRE )
                                        && !angular.isNullOrUndefined( habilitation.habilitationServiceGestionnaire())
                                        && !angular.isNullOrUndefined( habilitation.habilitationServiceGestionnaire()[item.ID_SERVICEGESTIONNAIRE.valeur] ) ){
                                    if ( angular.isNullOrUndefined( item.ID_SERVICEGESTIONNAIRE.valeur ) )
                                        item["DROITACCES"] = 2;
                                    else
                                        item["DROITACCES"] = habilitation.habilitationServiceGestionnaire()[item.ID_SERVICEGESTIONNAIRE.valeur];
                                }

                                else if (!angular.isNullOrUndefined( item.IDENT_SERVICEGESTIONNAIRE )
                                    && !angular.isNullOrUndefined( habilitation.habilitationServiceGestionnaire())){
                                    if ( angular.isNullOrUndefined( item.IDENT_SERVICEGESTIONNAIRE.valeur ) )
                                        item["DROITACCES"] = 2;
                                    else
                                        item["DROITACCES"] = (!angular.isNullOrUndefined( habilitation.habilitationServiceGestionnaire()[item.IDENT_SERVICEGESTIONNAIRE.valeur] ))?
                                            habilitation.habilitationServiceGestionnaire()[item.IDENT_SERVICEGESTIONNAIRE.valeur]:0;
                                }

                                return item;
                            });

                            // Fermeture de la fiche de synthese ou de saisie si la fiche n'est plus présente dans la liste
                            if ( $scope.onglet.affichageResume || $scope.onglet.affichageFiche ){
                                var colNomEntite = $scope.onglet.moduleEntite;
                                var identifiant = ($scope.onglet.affichageResume)?vm.vueFormulaireFicheSynthese.identifiant:vm.vueFormulaireFiche.identifiant;
                                for (var indice = 0; indice < vm.donneesRecherche.length; indice++){
                                    var bResultat = true;
                                    for (var colNomEntite in identifiant ){
                                        if ( !angular.isNullOrUndefined(vm.donneesRecherche[indice][colNomEntite])){
                                            if ( vm.donneesRecherche[indice][colNomEntite].valeur != identifiant[colNomEntite].valeur){
                                                bResultat = false;
                                                break;
                                            }
                                        }
                                    }

                                    if ( bResultat ){
                                        vm.fermerFiche(true);
                                        break;
                                    }

                                }
                            }

                            // Paramètres volet recherche planning
                            if ( aNumeroPage == 1 )
                                vm.descriptionRecherchePlanning = vm.descriptionRecherche;

                            vm.donneesRecherchePlanning = vm.donneesRecherche;
                            vm.moduleRecherchePlanning = $scope.onglet.moduleNom;
                            vm.actionCodeRecherchePlanning = "PLANIFIER";
                            vm.nomRecherchePlanning = "planning" + vm.moduleRecherchePlanning + vm.actionCodeRecherchePlanning;
                            vm.typeRecherchePlanning = utilsPlanning.getTypePlanning($scope.onglet.moduleNom, vm.actionCodeRecherchePlanning);

                            // Gestion du contexte
                            var contexteRecherche = $scope.onglet.moduleLibelle + " " + $scope.onglet.contexte;
                            vm.contexteRecherchePlanning = contexteRecherche.trim();

                            $timeout(function(){
                                if (!angular.isNullOrUndefined(vm.actualiserPlanningSectionDroite))
                                    vm.actualiserPlanningSectionDroite();
                            });

                            // On remet à zéro la sélection des entités
                            vm.selectionRecherche = [];
                            vm.selectionRechercheCle = [];
                        }

                        // Fin de chargement des recherches
                        function finChargementRecherche(aScope, aEcranNom, aEcranLibelle, aTableNom){

                            // Ajout de la fonction de recherche page 1
                            if (angular.isNullOrUndefined($scope.rechercheOnglet))
                                $scope.rechercheOnglet = $scope.rechercheOnglet || {};

                            $scope.rechercheOnglet[aEcranNom] = function (aIdEntite, aIdEntiteValeur, aTri) { // Mantis Operia 6195 : Ajout gestion tri personnalisé
                                $scope.onglet.affichageRecherche = false;

                                vm.fermerFiche().then(function(resultat){
                                    var deferred = $q.defer();

                                    vm.nomRechercheEnCours = aEcranLibelle;

                                    if ( !vm.bRechercheSelectionne )
                                        vm.bRechercheSelectionne = true;

                                    // Dossier
                                    if (!angular.isNullOrUndefined(aIdEntite)){
                                        aScope.champs[aTableNom][aIdEntite].valeur = aIdEntiteValeur[aIdEntite].valeur;
                                    }

                                    aScope.recherche[aEcranNom](vm.taillePage, aTri) // Mantis Operia 6195 : Ajout gestion tri personnalisé
                                        .then(function(retourRecherche){
                                            if (!angular.isNullOrUndefined(aIdEntite)){
                                                aScope.champs[aTableNom][aIdEntite].valeur = null;
                                            }

                                            vm.retourRechercheOnglet(aScope, aEcranNom, 1, retourRecherche);
                                            deferred.resolve(retourRecherche);
                                        });
                                    return deferred.promise;
                                })
                            };

                            // Ajout de la fonction de recherche par page
                            if (angular.isNullOrUndefined($scope.recherchePageOnglet))
                                $scope.recherchePageOnglet = $scope.recherchePageOnglet || {};
                            $scope.recherchePageOnglet[aEcranNom] = function (){
                                aScope.recherchePage[aEcranNom](vm.numeroPage)
                                    .then(function(retourRecherche){
                                        vm.retourRechercheOnglet(aScope, aEcranNom, vm.numeroPage, retourRecherche);
                                });
                            };


                            if ( aEcranNom.indexOf(vm.rechercheParDefaut) !== -1) {
                                $scope.rechercheOnglet[aEcranNom]();
                            }

                        }

                        // Fonction de recherche des derniers créés - pas disponible pour la partie mobile
                        function rechercheDernierDossiersCrees(){
                            if ( !serveur.isMobile() ){
                                var ecranNom = $scope.onglet.moduleTrigramme  + "_RECHERCHEDERNIERDOSSIERCREE";
                                serveur.chargement($scope.onglet.moduleNom,$scope, ecranNom, null)
                                    .then(function(retourChargement){
                                        vm.finChargementRecherche($scope,ecranNom,"Nouveautés",retourChargement[0][0]);
                                        $scope.recherche[ecranNom](vm.taillePage)
                                            .then(function(retourRecherche){
                                                $scope.onglet.nombreDernierDossiersCrees = $scope.grilles[retourRecherche[0][0]].length;
                                            });

                                        $scope.onglet.basculerDossierCrees = function(){
                                            $scope.rechercheOnglet[ecranNom]();
                                        };

                                        if ($scope.onglet.bRechercheDernierDossierCreeParDefaut)
                                            $scope.onglet.basculerDossierCrees();

                                    })
                            }
                        }

                        vm.actualiser();

                        // Recherche des recherches de l'onglet
                        $scope.onglet.rechercherEcranRecherche($scope.onglet.idModule)
                           .then(function(retourRecherche){
                               
                               // Gestion du bouton de recherche des derniers créers
                               vm.listeEcranRecherche = retourRecherche.filter(function(recherche){
                                   if ( recherche.ECRANNOM.valeur.indexOf("_RECHERCHEDERNIERDOSSIERCREE") !== -1){
                                       $scope.onglet.bRechercheDernierDossierCreeParDefaut  = recherche.PARDEFAUT.valeur;

                                       if (!angular.isNullOrUndefined($scope.onglet.basculerDossierCrees)) {
                                           $scope.onglet.basculerDossierCrees();
                                       }
                                   }
                                   return recherche.ECRANNOM.valeur.indexOf("_RECHERCHEDERNIERDOSSIERCREE") === -1;
                               });
                           });

                        // Ouverture synthèse d'une entité dans un nouvel onglet
                        function demandeDetailEntite (aModule, colCle, modeleEntite) {
                            // Mantis 6194 Contrôle des habilitations pour les liens inter-modules
                            ongletActifService.demanderActivite($scope, aModule, colCle, modeleEntite, true).then(function (onglet) {
                                var nomModule = aModule.MODULENOM.valeur;
                                var trigramme = onglet.moduleTrigramme;
                                var mobilite = serveur.isMobile()?'_M':'';
                                var ecranServiceGestionnaire = trigramme + mobilite + '_SAUVEGARDE_SERVICEGESTIONNAIRE';
                                var identite = {};
                                identite[colCle] = modeleEntite.valeur;

                                serveur.chargement(nomModule, $scope,  ecranServiceGestionnaire, identite).then(function (retourChargement) {
                                        if (!angular.isNullOrUndefined(retourChargement[0])) {
                                            var idService = $scope.champs[retourChargement[0][0]].ID_SERVICEGESTIONNAIRE.valeur;
                                            var clef = {};
                                            clef[colCle] = {valeur: modeleEntite.valeur};
                                            clef["DROITACCES"] = (angular.isNullOrUndefined(idService)) ? 1 : habilitation.habilitationServiceGestionnaire()[idService];

                                            if (clef["DROITACCES"] > 0) {
                                                ongletActifService.demanderActivite($scope, aModule, colCle, modeleEntite).then(function (onglet) {
                                                    onglet.ouvrirFiche(clef, null, true);
                                                });
                                            } else {
                                                opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + nomModule.toLowerCase() + ".", true, false);
                                            }
                                        } else {
                                            opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + nomModule.toLowerCase() + ".", true, false);
                                        }
                                    }, function () {
                                        //onglet.ouvrirFiche(clef, null, true);
                                        //opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + nomModule.toLowerCase() + ".", true, false);
                                    }
                                );
                            });
                        }
                    }]
                };
            }
    ])

    //Directive de Barre de fonctions Operis
    .directive('opOngletBarreFonctions',['fr.operis.moteurV4.communication.OperisWSServer', '$modal',
        'fr.operis.moteurV4.utils.Config', 'fr.operis.moteurV4.utils.Planning',
        'fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFiche',
        function(serveur, $modal, $config, utilsPlanning, opPopupEnregistrementFiche) {
        return {
            restrict: 'E',
            transclude: true,
            require: '^opOnglet',
            template: function (){
                var retour = '<div class="row">' +
                             '<div class="btn-group barre-fonctions col-md-9" ng-if="!barreFonctionCtrl.isMobile">'+
                                '<button class="btn btn-default" ng-class="{\'fa fa-lg action-ajout\':barreFonctionCtrl.isMobile}" style="padding: 0;" ' +
                                    'ng-disabled="(action.DROITACCES !=2 && action.DROITACCES !=1 && !onglet.affichageFiche )' +
                                        '||(action.ID_ACTION.valeur !=1 && action.ID_ACTIONTYPE.valeur != 7 && ongletCtrl.selectionRecherche == null && !onglet.affichageFiche )' +
                                        '|| ( action.ID_ACTION.valeur !=1 && action.ID_ACTIONTYPE.valeur != 7 && ongletCtrl.selectionRecherche.length == 0  && !onglet.affichageFiche ) ' + // Action standard
                                        '|| (( action.ID_ACTION.valeur !=1 && action.ID_ACTIONTYPE.valeur == 5 && ongletCtrl.selectionRecherche.length > 1  && !onglet.affichageFiche ) || barreFonctionCtrl.desactiverActionSelonRecherche(action) )' + // Action de type unitaire
                                        '|| ( action.ID_ACTION.valeur == 2 && onglet.affichageResume && (!onglet.affichageSectionGauche && onglet.affichageSectionDroite) ) ' +                 // Action de type modifier bloquer si fiche résumé présent
                                        '|| ( action.ID_ACTION.valeur == 2 && onglet.affichageFiche )' +
                                        '|| ( action.ID_ACTION.valeur == 2 && ongletCtrl.selectionRecherche.length == 1 && ongletCtrl.selectionRecherche[0].DROITACCES != 2 )' +
                                        '|| ( action.ID_ACTION.valeur == 1 && onglet.affichageFiche )" ' +                 // Action de type modifier bloquer si fiche fiche présent
                                    'ng-click="barreFonctionCtrl.gererActionEcran(action)" '+
                                    'ng-repeat="action in barreFonctionCtrl.actions" ' +
                                    'ng-if="!onglet.affichagePlanning" ' +
                                    'title="{{::action.ACTIONCOMMENTAIRE.valeur}}">' +
                                    '<img ng-if="!barreFonctionCtrl.isMobile" src="img/{{::action.ACTIONICONE.valeur}}" width="50" height="35" alt="{{::action.ACTIONLIBELLE.valeur}}">'+
                                '</button>'+
                                '<button class="btn btn-default" style="padding: 0; width: 52px; height: 37px;" title="{{::onglet.demoActionTitre}}" ng-if="onglet.demoActif" ng-disabled="ongletCtrl.selectionRecherche.length != 1" ng-click="barreFonctionCtrl.gererActionDemo()">'+
                                    '<span class="{{::onglet.demoActionIcone}}" style="color: steelblue;"></span>'+
                                '</button>'+
                                '<button class="btn btn-default" style="padding: 0;" ' +
                                    'ng-click="barreFonctionCtrl.gererActionPlanning(action)" '+
                                    'ng-repeat="action in barreFonctionCtrl.actionsPlanning" ' +
                                    'ng-if="onglet.affichagePlanning && (action.typePlanning === barreFonctionCtrl.typePlanning) && !barreFonctionCtrl.isMobile" ' +
                                    'ng-disabled="(action.actionType == 1 && ongletCtrl.planningParameter.lectureSeule) ' +
                                    '|| (action.actionType == 5 && (ongletCtrl.planningParameter.paramActions.nbreSelect != 1 || ongletCtrl.planningParameter.paramActions.nbreSelect == ongletCtrl.planningParameter.paramActions.nbreSelectLectureSeule)) ' +
                                    '|| (action.actionType == 6 && (ongletCtrl.planningParameter.paramActions.nbreSelect == 0 || ongletCtrl.planningParameter.paramActions.nbreSelect == ongletCtrl.planningParameter.paramActions.nbreSelectLectureSeule)) ' +
                                    '|| (action.actionType == 7 && (ongletCtrl.planningParameter.paramActions.nbreSelectLectureSeule == 0)) ' +
                                    '|| (action.actionType == 8 && (ongletCtrl.planningParameter.paramActions.libRessourceSelect === \'\'))" ' +
                                    'title="{{::action.actionCommentaire}}">' +
                                    '<img src="img/{{::action.actionIcone}}" width="50" height="35" alt="{{::action.actionLibelle}}">'+
                                '</button>'+
                            '</div>'+
                            '<div class="btn-toolbar barre-fonctions col-md-3" ng-if="!barreFonctionCtrl.isMobile">'+
                                '<div class=pull-right>'+
                                    '<div class="btn-group">'+
                                        '<button class="btn btn-default" ng-disabled="!(barreFonctionCtrl.droitModificationFiche && onglet.droitAccessFiche==2)" ng-click="ongletCtrl.editerFiche()" ng-if="(onglet.affichageResume) && !ficheCtrl.isMobile">Saisie</button>'+
                                        '<button class="btn btn-default" ng-click="ongletCtrl.resumerFiche()" ng-if="(onglet.affichageFiche) && !ficheCtrl.isMobile">Résumé</button>'+
                                    '</div>'+
                                    '<div class="btn-group" ng-if="!onglet.affichagePlanning">'+
                                        '<button class="btn btn-default" ng-disabled="ongletCtrl.vueFormulaireFiche.vueModifie.$pristine" ng-click="ongletCtrl.rechargerFiche()" ng-if="!ficheCtrl.isMobile && (onglet.affichageFiche)"><span class="fa fa-reply"></span></button>'+
                                        '<button class="btn btn-default" ng-disabled="ongletCtrl.vueFormulaireFiche.vueModifie.$pristine" ng-click="ongletCtrl.sauvegarderFiche()" ng-if="!ficheCtrl.isMobile && (onglet.affichageFiche)" ><span class="fa fa-floppy-o"></span></button>'+
                                        '<button class="btn btn-default" ng-click="ongletCtrl.fermerFiche()" ng-if="(onglet.affichageFiche || onglet.affichageResume) && !ficheCtrl.isMobile"><span class="fa fa-times"></span></button>'+
                                    '</div>'+
                                    '<div class="btn-group" ng-if="!ficheCtrl.isMobile && onglet.affichagePlanning">'+
                                        '<button class="btn btn-default" ng-click="ongletCtrl.affichageTimelinePlanning()" title="Affichage échéancier"><span class="fa fa-align-left"></span></button>' +
                                        '<button class="btn btn-default" ng-click="ongletCtrl.affichageAgendaPlanning()" title="Affichage calendrier"><span class="fa fa-calendar-o"></span></button>' +
                                        '<button class="btn btn-default" ng-click="ongletCtrl.affichageGrillePlanning()" title="Affichage grille" ng-if="((barreFonctionCtrl.typePlanning === \'tache\') || (barreFonctionCtrl.typePlanning === \'contact\'))"><span class="fa fa-list-alt"></span></button>' +
                                    '</div>'+
                                    '<div class="btn-group" ng-if="!ficheCtrl.isMobile && onglet.affichagePlanning">'+
                                        '<button class="btn btn-default" ng-click="ongletCtrl.rechargerPlanning()"><span class="fa fa-reply"></span></button>'+
                                        '<button class="btn btn-default" ng-click="ongletCtrl.sauvegarderPlanning()" ng-disabled="(!ongletCtrl.planningParameter.planningModifie)"><span class="fa fa-floppy-o"></span></button>'+
                                        '<button class="btn btn-default" ng-click="ongletCtrl.fermerPlanning()"><span class="fa fa-times"></span></button>'+
                                    '</div>'+
                                    '<div class="btn-group" ng-if="onglet.affichageSectionDroite && !onglet.affichageFiche && !onglet.affichageResume && !onglet.affichagePlanning && !ficheCtrl.isMobile">'+
                                        // Mantis 5887 : Pas de bouton 'plein écran' pour l'outil cartographie
                                        '<button class="btn btn-default" ng-click="onglet.basculerSectionGauche()" ng-if="onglet.affichageOutils !== \'cartographie\'"><span class="fa" ng-class="{\'fa-expand\': (onglet.affichageSectionGauche), \'fa-columns\': !(onglet.affichageSectionGauche)}"></span></button>'+
                                        '<button class="btn btn-default" ng-click="onglet.masquerSectionDroite();onglet.afficherSectionGauche()"><span class="fa fa-times"></span></button>'+
                                    '</div>'+
                                '</div>'+
                            '</div>'+
                            '<div ng-if="barreFonctionCtrl.isMobile" class="barre-fonctions-mobile">'+
                                    '<button class="fa fa-lg btn-success" ng-if="(onglet.affichageFiche || onglet.affichageResume)" ng-click="onglet.gererDocumentsAttaches()" ><span class="fa fa-camera"></span></button>'+
                                    '<button class="fa fa-lg btn-primary" ng-class="{\'bouton-desactive\': ongletCtrl.vueFormulaireFiche.vueModifie.$pristine}" ng-if="onglet.affichageFiche" ng-disabled="ongletCtrl.vueFormulaireFiche.vueModifie.$pristine" ng-click="ongletCtrl.sauvegarderFiche()" ><span class="fa fa-floppy-o"></span></button>'+
                                    '<button class="btn btn-success" ng-class="{\'fa fa-lg action-ajout\':action.ID_ACTION.valeur == 1, \'fa fa-lg fa-pencil\':action.ID_ACTION.valeur == 2}" style="padding: 0;" ' +
                                        'ng-disabled="(action.DROITACCES !=2 && action.DROITACCES !=1 )' +
                                        '||(action.ID_ACTION.valeur !=1 && action.ID_ACTIONTYPE.valeur != 7 && ongletCtrl.selectionRecherche == null )' +
                                        '|| ( action.ID_ACTION.valeur !=1 && action.ID_ACTIONTYPE.valeur != 7 && ongletCtrl.selectionRecherche.length == 0  ) ' + // Action standard
                                        '|| ( action.ID_ACTION.valeur !=1 && action.ID_ACTIONTYPE.valeur == 5 && ongletCtrl.selectionRecherche.length > 1 )' + // Action de type unitaire
                                        '|| ( action.ID_ACTION.valeur == 1 && onglet.affichageFiche )" ' +                 // Action de type modifier bloquer si fiche fiche présent
                                        'ng-hide="(action.ID_ACTION.valeur == 1 && (onglet.affichageFiche || onglet.affichageResume))' +
                                        '|| (action.ID_ACTION.valeur == 2 && !onglet.affichageResume)' +
                                        '|| (action.ACTIONCODE.valeur == \'AFFECTER\' && (!onglet.affichageResume || onglet.affichageFiche))' +
                                        '|| (action.ACTIONCODE.valeur == \'INTERVENIR\' && (!onglet.affichageResume || onglet.affichageFiche))' +
                                        '|| (action.ACTIONCODE.valeur == \'MODIFIERAVANCEMENT\' && (!onglet.affichageResume || onglet.affichageFiche))' +
                                        '|| (action.ACTIONCODE.valeur == \'CLOTURER\' && (!onglet.affichageResume || onglet.affichageFiche))" '+
                                        'ng-click="barreFonctionCtrl.gererActionEcran(action)" '+
                                        'ng-repeat="action in barreFonctionCtrl.actions" ' +
                                        'title="{{::action.ACTIONCOMMENTAIRE.valeur}}">' +
                                        '<span ng-if="action.ACTIONCODE.valeur == \'INTERVENIR\' || action.ACTIONCODE.valeur == \'MODIFIERAVANCEMENT\' || action.ACTIONCODE.valeur == \'AFFECTER\' || action.ACTIONCODE.valeur == \'CLOTURER\'">' +
                                            '<img ng-src="img/{{::action.ACTIONICONE.valeur}}" />' +
                                        '</span>' +
                                    '</button>'+
                                    '<button class="fa fa-lg action-mobile-fermeture" ng-if="(onglet.affichageFiche || onglet.affichageResume)" ng-click="ongletCtrl.fermerFiche()"><span class="fa fa-times"></span></button>'+
                            '</div>';
                return retour;
            },
            controllerAs:'barreFonctionCtrl',
            controller: ['$scope','$rootScope','$modal','fr.operis.moteurV4.utils.ModuleInfos','fr.operis.moteurV4.composants.OpPopupDemo',
                function($scope,$rootScope,$modal, moduleInfos, opPopupDemo){
                    var vm = this;

                    vm.actions;
                    vm.droitModificationFiche =false;

                    // Gestion des actions planning
                    vm.typePlanning = "inconnu";
                    vm.actionsPlanning = utilsPlanning.getActionsPlanning();

                    vm.isMobile = serveur.isMobile();

                    var tableEntite = moduleInfos.getInfo($scope.onglet.moduleNom, "MOD_TABLEENTITE");
                    var entite = moduleInfos.getInfo($scope.onglet.moduleNom, "MOD_ENTITE");

                    // Gestion du popup de démonstration
                    $scope.onglet.demoActif = (false && ($scope.onglet.moduleNom.toUpperCase() === 'PATRIMOINE')); // Variable pour activer l'affichage du bouton "Démonstration"
                    $scope.onglet.demoActionTitre = "";
                    $scope.onglet.demoActionIcone = "fa fa-question fa-2x";
                    if ($scope.onglet.demoActif) {
                        if ($scope.onglet.moduleNom.toUpperCase() === 'PATRIMOINE') {
                            $scope.onglet.demoActionIcone = "fa fa-sitemap fa-2x";
                            $scope.onglet.demoActionTitre = "Filiation";
                        }
                    }

                    vm.gererActionDemo = function () {
                        if ($scope.onglet.moduleNom.toUpperCase() === 'PATRIMOINE') {
                            // Démo composant OrgChart
                            opPopupDemo.creer("orgchart", $scope.onglet.demoActionTitre, true, false, "Fermer", "Annuler");
                        }
                    };

                    // Récupération des actions
                    $scope.onglet.rechercherActionMetier($scope.onglet.idModule)
                        .then(function(retourRecherche){
                            // Action métier
                            vm.actions = angular.copy(retourRecherche.filter(function(item){ return ( item.ID_ACTION < 4 || item.ID_ACTION>99 )}));

                            // Droit de modification
                            for (var i=0;i<vm.actions.length;i++){
                                if ( vm.actions[i].ACTIONCODE.valeur === "MODIFIER" ){
                                    // Droit sur l'action et sur le service gestionnaire de l'élément ouvert
                                    vm.droitModificationFiche = ( vm.actions[i].DROITACCES.valeur === 2 );
                                }
                            }

                            // Action outils
                            $scope.onglet.actionsGenerique = angular.copy(retourRecherche.filter(function(item){ return ( item.ID_ACTION > 3 && item.ID_ACTION < 100 )}));
                        });

                    // Mantis 6160 Gestion exceptions affichage actions
                    vm.desactiverActionSelonRecherche = function (action) {
                        return utilsPlanning.desactiverActionSelonRecherche($scope.onglet.moduleNom,
                            action.ACTIONCODE.valeur, $scope.onglet.ongletCtrl.ecranCourantRecherche);
                    };

                    // Fonction de gestion des actions planning
                    vm.gererActionPlanning = function (action) {
                        if (!angular.isNullOrUndefined(action) && !angular.isNullOrUndefined(action.actionCode)) {
                            $scope.onglet.actionPlanning(action);
                        }
                    };

                    // Fonction de gestion des actions
                    vm.gererActionEcran = function (action){
                        var ecranIHM = action["ECRANIHM"];

                        if ( action.ID_ACTION.valeur == 1 || action.ID_ACTION.valeur == 2 ) {
                            // Action de standard de création et de modification
                            var ecranFiche = $scope.onglet.moduleTrigramme + ((serveur.isMobile())?"_M":"") + "_FICHE";
                            var identifiant = {};
                            identifiant[$scope.onglet.moduleEntite] = "";
                            if ( action.ID_ACTION.valeur != 1  ){
                                // Modification de fiche
                                identifiant[$scope.onglet.moduleEntite] = $scope.ongletCtrl.selectionRecherche[0][$scope.onglet.moduleEntite].valeur;
                            }
                            $scope.onglet.editerFiche({nomModule:$scope.onglet.moduleNom,nomEcran:ecranFiche,identifiant:identifiant, idAction:action.ID_ACTION.valeur});
                        }
                        else {
                            var moduleNom = $scope.onglet.moduleNom.toLowerCase();

                            if ((action.ACTIONCODE.valeur === "GERERTACHE") ||
                                (action.ACTIONCODE.valeur === "GERERRECURRENCE") ||
                                (action.ACTIONCODE.valeur === "GERERDISPONIBILITE")) {
                                // Type de planning pour la gestion de la barre d'actions du planning
                                vm.typePlanning = utilsPlanning.getTypePlanning($scope.onglet.moduleNom, action.ACTIONCODE.valeur);

                                // Lancement planning
                                if ($scope.onglet.affichageFiche) {
                                    // Proposer enregistrement de la fiche si besoin
                                    if (!angular.isNullOrUndefined($scope.ongletCtrl.vueFormulaireFiche) &&
                                        !angular.isNullOrUndefined($scope.ongletCtrl.vueFormulaireFiche.vueModifie) &&
                                        !$scope.ongletCtrl.vueFormulaireFiche.vueModifie.$pristine) {
                                        var popupEnregistrement = opPopupEnregistrementFiche.creer();
                                        popupEnregistrement.result.then(function (aValidation) {
                                            if (aValidation) {
                                                // Sauvegarde modifications dans la fiche
                                                $scope.ongletCtrl.sauvegarderFiche().then(function () {
                                                    // Fermêture de la fiche
                                                    $scope.actualisationFiche = true;
                                                    $scope.ongletCtrl.fermerFiche().then(function () {
                                                        var titreContexte = "";
                                                        var nomTable = utilsPlanning.getNomTableFiche($scope.onglet.moduleNom, action.ACTIONCODE.valeur);
                                                        var nomChamp = utilsPlanning.getNomChampPrincipal($scope.onglet.moduleNom, action.ACTIONCODE.valeur);

                                                        if (!angular.isNullOrUndefined(nomTable) && !angular.isNullOrUndefined(nomChamp)) {
                                                            titreContexte = $scope.ongletCtrl.vueFormulaireFiche.champs[nomTable][nomChamp].valeur;
                                                        }

                                                        // Lancement planning
                                                        $scope.onglet.ouvrirPlanning({
                                                            nomModule: $scope.onglet.moduleNom,
                                                            actionCode: action.ACTIONCODE.valeur,
                                                            selection: $scope.ongletCtrl.selectionRecherche,
                                                            titreContexte: (titreContexte === "") ? null : titreContexte,
                                                            paramActions: {nbreSelect: 0, nbreSelectLectureSeule: 0},
                                                            planningModifie: false,
                                                            lectureSeule: false
                                                        });
                                                    });
                                                })
                                            }
                                            else {
                                                // Annuler modifications dans la fiche
                                                $scope.ongletCtrl.vueFormulaireFiche.vueModifie.$setPristine();

                                                // Fermêture de la fiche
                                                $scope.ongletCtrl.fermerFiche(true).then(function () {
                                                    // Lancement planning
                                                    $scope.onglet.ouvrirPlanning({
                                                        nomModule: $scope.onglet.moduleNom,
                                                        actionCode: action.ACTIONCODE.valeur,
                                                        selection: $scope.ongletCtrl.selectionRecherche,
                                                        paramActions: {nbreSelect: 0, nbreSelectLectureSeule: 0},
                                                        planningModifie: false,
                                                        lectureSeule: false
                                                    });
                                                });
                                            }
                                        }, function () {
                                            // Annulation lancement planning
                                        })
                                    } else {
                                        // Fermêture de la fiche
                                        $scope.ongletCtrl.fermerFiche(true).then(function () {
                                            // Lancement planning
                                            $scope.onglet.ouvrirPlanning({
                                                nomModule: $scope.onglet.moduleNom,
                                                actionCode: action.ACTIONCODE.valeur,
                                                selection: $scope.ongletCtrl.selectionRecherche,
                                                paramActions: {nbreSelect: 0, nbreSelectLectureSeule: 0},
                                                planningModifie: false,
                                                lectureSeule: false
                                            });
                                        });
                                    }
                                } else {
                                    // Lancement planning
                                    $scope.onglet.ouvrirPlanning({
                                        nomModule: $scope.onglet.moduleNom,
                                        actionCode: action.ACTIONCODE.valeur,
                                        selection: $scope.ongletCtrl.selectionRecherche,
                                        paramActions: {nbreSelect: 0, nbreSelectLectureSeule: 0},
                                        planningModifie: false,
                                        lectureSeule: false
                                    });
                                }
                            } else {
                                if ( action.ID_ACTION.valeur == 3 ){
                                    var controller = 'fr.operis.moteurV4.composants.popup.OpPopupSuppressionFicheCtrl';
                                    var templateUrl = 'moteur/composants/popup/OpPopupSuppressionFiche.html';
                                    action.ECRANNOM.valeur = $scope.onglet.moduleTrigramme + ((serveur.isMobile())?"_M":"") + "_FICHE";

                                }else{
                                    var controller,templateUrl;
                                    // Cas des chemins dans un autre module
                                    if ( ecranIHM.valeur.indexOf('/') !== -1 ){
                                        templateUrl = ecranIHM.valeur + '.html';
                                        controller = 'fr.operis.' + ecranIHM.valeur.replace(/\//g,'.') + 'Ctrl';
                                    }else{
                                        controller = 'fr.operis.'+ moduleNom +'.ecrans.' + ecranIHM.valeur.toLowerCase() + 'Ctrl';
                                        templateUrl = moduleNom + '/ecrans/' + ecranIHM.valeur.toLowerCase() + '.html';
                                    }
                                }

                                // Ouverture du popup d'action
                                if ($scope.onglet.affichageFiche){
                                    if ( angular.isNullOrUndefined($scope.ongletCtrl.scopeFormulaire.identifiant[entite]) || $scope.ongletCtrl.scopeFormulaire.identifiant[entite]=="")
                                        return alert("Veuillez enregistrer au préalable le dossier")
                                }


                                var popupActionEcran = $modal.open({
                                    templateUrl: templateUrl,
                                    controller: controller,
                                    size: 'xlg',
                                    windowClass: (serveur.isMobile() ? 'mobile' : ''),
                                    resolve: {
                                        // Action
                                        action: function () {
                                            return action;
                                        },
                                        // Nom du module
                                        moduleNom: function () {
                                            return $scope.onglet.moduleNom;
                                        },
                                        // Sélection en cours
                                        selection: function () {
                                            if ($scope.onglet.affichageFiche)
                                                // Récupération de l'ID de la fiche
                                                return [$scope.ongletCtrl.scopeFormulaire.champs[tableEntite]];
                                            else
                                                return $scope.ongletCtrl.selectionRecherche;
                                        },
                                        // Description de la sélection en cours
                                        descriptionSelection: function () {
                                            return $scope.ongletCtrl.descriptionRecherche;
                                        }
                                    }
                                });

                                //Retour du popup d'action
                                popupActionEcran.result.then(function(){
                                    $scope.ongletCtrl.actualiser();
                                },function(){

                                })
                            }
                        }
                    };
                }
            ]
        };
    }])

    //Directive de la recherche
    .directive('opOngletRecherche',
        ['fr.operis.moteurV4.communication.OperisWSServer', '$compile', '$timeout',
            'fr.operis.moteurV4.utils.GenerationFormulaire',
            'fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_CHAMPS',
            'fr.operis.moteurV4.utils.ModuleInfos',
            function(serveur, $compile, $timeout,
                     generateurFormulaire,
                     TYPE_CHAMPS,
                     moduleInfos) {
        return {
            restrict: 'E',
            template:function (element, attributs) {
                var retour = '<accordion-group class="activite" ng-style="{\'background-color\': \'{{couleurPrimaire}}\'}" is-open="status.open">'+
                    '   <accordion-heading>'+
                    '     <div ng-style="{\'background-color\': \'{{couleurSecondaire}}\'}" style="padding: 10px 15px; height: 37.6px;">'+
                    '       <span class="fa fa-search pull-left" style="margin-left:10px"'+
                    '           ng-click="appel_recherche();$event.stopPropagation();">'+
                    '       </span>'+
                    '       <span class="pull-left">'+
                    '           {{ecranLibelle}}'+
                    '       </span>'+
                    '       <span class="fa pull-left fa-margin-left"'+
                    '           ng-class="{\'fa-chevron-down\': status.open, \'fa-chevron-right\': !status.open}">' +
                    '       </span>'+
                    '     </div>'+
                    '   </accordion-heading>'+
                    '   <ul class="list-group borderless">'+
                    '   <op-formulaire ' +
                    '       op-vue-formulaire="vueFormulaire" '+
                    '       finchargement="finChargementFormulaire(scope)" '+
                    '       is-formulaire-recherche="true" '+
                    '   />'+
                    '   </ul>'+
                    '</accordion-group>';

                return retour;
            },
            require: '^opOnglet',
            scope:{
                moduleNom:'=',
                actionCode:'=',
                ecranLibelle:'=',
                ecranNom:'=',
                default:'=',
                echapDefaut:'=',
                finChargement:'&'
            },
            controller: ['$scope', '$attrs', '$modal', 'ongletActifService',
                function($scope, $attrs, $modal, ongletActifService) {
                    $scope.vueFormulaire = null;
                    $scope.finChargementFormulaire;
                    $scope.appel_recherche = appel_recherche;
                    $scope.finChargementFormulaire = finChargementFormulaire;
                    $scope.basculerRecherche = ongletActifService.basculerRecherche;
                    $scope.status;
                    $scope.bFormulaireCharge = false;
                    $scope.couleurPrimaire = moduleInfos.getInfo($scope.moduleNom, "MOD_COULEURPRIMAIRE");
                    $scope.couleurSecondaire = moduleInfos.getInfo($scope.moduleNom, "MOD_COULEURSECONDAIRE");
                    $scope.bLancerRecherche = false;

                    // Fonction d'appel de recherche
                    function appel_recherche() {
                        if ( !$scope.bFormulaireCharge ){
                            if ( angular.isNullOrUndefined($scope.status) )
                                $scope.status = {};

                            $scope.bLancerRecherche = true;
                            $scope.status.open = true;
                        }else{
                            if ( !angular.isNullOrUndefined($scope.$parent.rechercheOnglet[$scope.ecranNom] )){
                                $scope.$parent.rechercheOnglet[$scope.ecranNom]();
                            }else
                                $scope.basculerRecherche();

                        }
                    };

                    // Fonction de chargement
                    function finChargementFormulaire(aScope){
                        $scope.bFormulaireCharge = true;

                        // Recherche de la table
                        var tableNom;
                        for ( var table in aScope.champs){
                            tableNom = table;
                            break;
                        }

                        $scope.finChargement({scope:aScope, ecrannom:$scope.ecranNom, ecranlibelle:$scope.ecranLibelle, tablenom:tableNom});

                        // Si pas de critère on lance la recherche ou recherche par défault
                        var bCritereNonPresent = true;
                        for (var colNom in aScope.descriptions[tableNom]){
                            if ( aScope.descriptions[tableNom][colNom].estCritereRecherche ){
                                bCritereNonPresent = false;
                                break;
                            }
                        }

                        if ( bCritereNonPresent || $scope.default == 1 || $scope.bLancerRecherche )
                            $scope.appel_recherche();
                    };

                    if ($scope.default == 1) {
                        if (!angular.isNullOrUndefined($scope.echapDefaut) && $scope.echapDefaut) {
                            $scope.echapDefaut = false;
                        } else {
                            $scope.appel_recherche();
                        }
                    }
                }
            ],
            controllerAs:'rechercheCtrl',
            link: function (scope, element, attrs, controller) {

                scope.$watch('status.open', function(value){
                    if ( value && !scope.bFormulaireCharge ){
                        scope.vueFormulaire ={
                            nomModule:scope.moduleNom,
                            nomEcran:scope.ecranNom,
                            identifiant: null
                        };
                    }
                })
            }
        };
    }])

    //Directive des résultats
    .directive('opOngletResultat',[ function() {
        return {
            restrict: 'E',
            transclude: true,
            require: '^opOnglet',
            template: function (){
                var retour ='<div ng-show="!onglet.affichageFiche && !onglet.affichagePlanning" class="resultat">'+
                                '<div class="row barre-affichage" ng-style="{\'width\': (onglet.affichageSectionDroite ? \'auto\' : \'50%\')}">' +
                                    '<div class="barre-affichage-outil col-md-2 col-xs-12">' +
                                        '<b>{{ongletCtrl.nomRechercheEnCours}} ({{ongletCtrl.tailleTotale}})</b>' +
                                        '<span class="fa hidden-xs" ng-class="{\'fa-square-o\': ongletCtrl.selectionRecherche.length == 0, ' +
                                                                    '\'fa-square\': ongletCtrl.selectionRecherche.length != 0 && ongletCtrl.selectionRecherche.length != ongletCtrl.donneesRecherche.length, ' +
                                                                    '\'fa-check-square-o\': ongletCtrl.selectionRecherche.length == ongletCtrl.donneesRecherche.length}" ' +
                                            'ng-style="{\'color\': (ongletCtrl.selectionRecherche.length != 0 && ongletCtrl.selectionRecherche.length != ongletCtrl.donneesRecherche.length ? \'lightgrey\' : \'black\')}" ng-click="ongletCtrl.selectionnerTout()" title="Sélectionner tout"></span>' +
                                        ' ({{ongletCtrl.selectionRecherche.length}})'+
                                    '</div>'+
                                    // Mantis 6117 : Taille d'une page de recherche personnalisé
                                    '<!-- Nombre de résultats par page -->'+
                                    //'<div ng-if="onglet.affichageResultat === \'grille\'" class="barre-affichage-outil col-md-2 filtre hidden-xs">'+
                                    '<div class="barre-affichage-outil col-md-2 filtre hidden-xs">'+
                                        '<select class="form-control" '+
                                        'id="selectNbreElements" '+
                                        'name="selectNbreElements" '+
                                        'ng-model="ongletCtrl.taillePagePerso" ' +
                                        'ng-change="ongletCtrl.appliquerTaillePagePerso(ongletCtrl.taillePagePerso)" ' +
                                        'ng-options="seuilTaillePage.seuil for seuilTaillePage in ongletCtrl.seuilsTaillePage track by seuilTaillePage.seuilId">' +
                                        '</select>'+
                                    '</div>'+
                                    '<!-- Recherche sur le résultat -->'+
                                    '<div class="barre-affichage-outil col-md-4 filtre hidden-xs">'+
                                        '<input class="form-control filtre-recherche-resultat" type="text" placeholder="Appliquer un filtre sur la page courante"'+
                                        'ng-model="ongletCtrl.filtre.numero" />'+
                                    '</div>'+
                                    '<div class="barre-affichage-outil col-md-4 hidden-xs">'+
                                        '<div class="pull-right">'+
                                            //'<div class="btn btn-default" ng-model="onglet.affichageResultat" btn-radio="\'mosaique\'" title="Mosaïque" ng-click="ongletCtrl.appliquerTaillePagePerso(ongletCtrl.taillePageMosaique)"><span class="fa fa-th"></span></div>'+
                                            '<div class="btn btn-default" ng-model="onglet.affichageResultat" btn-radio="\'mosaique\'" title="Mosaïque" ng-click="ongletCtrl.appliquerTaillePagePerso(ongletCtrl.taillePagePerso)"><span class="fa fa-th"></span></div>'+
                                            '<div class="btn btn-default" ng-model="onglet.affichageResultat" btn-radio="\'grille\'" title="Grille" ng-click="ongletCtrl.appliquerTaillePagePerso(ongletCtrl.taillePagePerso)"><span class="fa fa-table"></span></div>'+                                            // TODO : Actuellement l'affichage 'Catégories' ne sert pas. Réactiver le bouton lorsque l'affichage est operationnelle.
                                            //'<div class="btn btn-default" ng-model="onglet.affichageResultat" btn-radio="\'categories\'" title="Catégories"><span class="fa fa-align-left"></span></div>'+
                                            '<div class="btn btn-default" ng-click="ongletCtrl.actualiser(true)" title="Actualiser" style="margin-left: 3px;margin-right: 16px;"><span class="fa fa-refresh"></span></div>'+
                                        '</div>'+
                                    '</div>' +
                                '</div>'+
                                '<div style="height: calc(100% - 44px);">'+
                                    '<!-- Mosaique -->'+
                                    '<div ng-if="onglet.affichageResultat === \'mosaique\'" class="mosaique" ng-class="{\'avec-pagination\': (ongletCtrl.tailleTotale/ongletCtrl.taillePage)>1}">'+
                                        '<op-mosaique '+
                                        'op-module="{{onglet.moduleNom}}" '+
                                        'op-description="ongletCtrl.descriptionRecherche" '+
                                        'op-donnees="ongletCtrl.donneesRecherche" '+
                                        'op-selection="ongletCtrl.selectionRecherche" '+
                                        'op-selection-cle="ongletCtrl.selectionRechercheCle" '+
                                        'op-detail="ongletCtrl.ouvrirFiche" '+
                                        'op-filtre="ongletCtrl.filtre.numero" '+
                                        'op-entite="{{onglet.moduleEntite}}" '+
                                        'op-trigramme="{{onglet.moduleTrigramme}}" '+
                                        'op-comparaison="opComparaison" '+
                                        'op-taille-page="{{ongletCtrl.taillePageMosaique.seuilId}}"> '+
                                        '</op-mosaique>'+
                                    '</div>'+
                                    '<!-- Grille -->'+
                                    // Mantis 6055 Gérer attribut scroll vertical
                                    '<div ng-if="onglet.affichageResultat === \'grille\'" ng-class="{\'avec-pagination\': (ongletCtrl.tailleTotale/ongletCtrl.taillePage)>1}">'+
                                        '<op-grille class="onglet-resultat-grille" op-vertical-scroll '+
                                        'op-description="ongletCtrl.descriptionRecherche" '+
                                        'op-donnees="ongletCtrl.donneesRecherche" '+
                                        'op-selection="ongletCtrl.selectionRecherche" '+
                                        'op-liste="ongletCtrl.donneesListe" '+
                                        'op-filtre="ongletCtrl.filtre.numero" '+
                                        'op-entite="{{onglet.moduleEntite}}" '+
                                        'op-trigramme="{{onglet.moduleTrigramme}}" '+
                                        'op-module="{{onglet.moduleNom}}" '+
                                        'op-detail="ongletCtrl.ouvrirFiche" '+
                                        'op-section-droite-visible="onglet.affichageSectionDroite" '+
                                        'op-demande-detail-entite="ongletCtrl.demandeDetailEntite"' +
                                        'on-tri-recherche="ongletCtrl.rechercheSelonTri"> '+  // Mantis Operia 6195 : Gestion tri personnalisé pour la vue grille des recherches
                                        '</op-grille>'+
                                    '</div>'+
                                     '<!-- Catégories -->'+
                                    '<div ng-if="onglet.affichageResultat === \'categories\'" class="grille" ng-class="{\'avec-pagination\': (ongletCtrl.tailleTotale/ongletCtrl.taillePage)>1}">'+
                                        '<op-grille '+
                                        'op-description="ongletCtrl.descriptionRecherche" '+
                                        'op-donnees="ongletCtrl.donneesRecherche" '+
                                        'op-selection="ongletCtrl.selectionRecherche" '+
                                        'op-filtre="ongletCtrl.filtre.numero" '+
                                        'op-detail="ongletCtrl.ouvrirFiche" '+
                                        'op-section-droite-visible="onglet.affichageSectionDroite" '+
                                        'op-demande-detail-entite="ongletCtrl.demandeDetailEntite" '+
                                        'op-categorie="true" >'+
                                        '</op-grille> '+
                                    '</div>'+
                                    '<div class="btn-group barre-fonctions" ng-if="!barreFonctionCtrl.isMobile && ((ongletCtrl.tailleTotale/ongletCtrl.taillePage)>1)">' +
                                        '<pagination first-text="Premier" items-per-page="ongletCtrl.taillePage" last-text="Dernier" ng-change="ongletCtrl.pageChanged($model)" previous-text="Précédent" next-text="Suivant" total-items="ongletCtrl.tailleTotale" ng-model="ongletCtrl.numeroPage" max-size="10" class="pagination-sm" boundary-links="true" rotate="false"></pagination>'+
                                    '</div>'+
                                    '<div class="btn-group" style="{padding: 5px 5px 5px 0px;}" ng-if="barreFonctionCtrl.isMobile && ((ongletCtrl.tailleTotale/ongletCtrl.taillePage)>1)">' +
                                        '<pagination first-text="Premier" items-per-page="ongletCtrl.taillePage" last-text="Dernier" ng-change="ongletCtrl.pageChanged($model)" previous-text="Précédent" next-text="Suivant" total-items="ongletCtrl.tailleTotale" ng-model="ongletCtrl.numeroPage" max-size="2" class="pagination-sm" direction-links="true" boundary-links="false" boundary-link-numbers="true" rotate="false"></pagination>'+
                                    '</div>'+
                                '</div>'+
                            '</div>';
                return retour;
            }
        };
    }])

    //Directive de la fiche
    .directive('opOngletFiche',['fr.operis.moteurV4.utils.Planning', 'ongletActifService','fr.operis.moteurV4.utils.RequetePreChargementFiche',
        'fr.operis.moteurV4.communication.OperisWSServer', 'fr.operis.moteurV4.utils.Habilitation',
        function(utilsPlanning, ongletActifService, requetePreChargementFiche, serveur, habilitation) {
        return {
            restrict: 'E',
            scope:{onglet:"="},
            transclude: true,
            bindToController: true,
            require: '^opOnglet',
            template: function (element, attributs){
                var retour='', nomFormulaire = (attributs.modeEdition == "true")?'formulaireSynthese':'formulaireSaisie';
                var vueFormulaire = (attributs.modeEdition == "true")? 'vueFormulaireFicheSynthese':'vueFormulaireFiche';
                retour = '<div class="ecran">'+
                                '<op-formulaire ' +
                                    'op-name="'+ nomFormulaire +'" '+
                                    'op-is-lecture="'+attributs.modeEdition+'" ' +
                                    'op-vue-formulaire="$parent.ongletCtrl.' +vueFormulaire +'" '+
                                    'op-onglet="ficheCtrl.onglet" '+
                                    'finchargement="ficheCtrl.finChargementFormulaire(scope)" '+
                                    'on-demande-activite="ficheCtrl.demanderActivite(idaction,entite,modele)" '+
                                '/>'+
                             '</div>';
                return retour;
            },
            controllerAs:'ficheCtrl',
            link: function (scope, element, attrs) {},
            controller: ['$scope', '$attrs',
                function($scope, $attrs) {
                    var vm = this;

                    // Déclation des fonctions
                    vm.demanderActivite = demanderActivite;
                    vm.finChargementFormulaire = finChargementFormulaire;

                    function finChargementFormulaire(aScope){
                        $scope.$parent.ongletCtrl.scopeFormulaire = aScope;

                        if ( $attrs.modeEdition == "false" )
                            requetePreChargementFiche.execution(aScope, vm.onglet.moduleNom.toLocaleUpperCase(),$scope.$parent.onglet.id_categoriemodule,
                                $scope.$parent.ongletCtrl.vueFormulaireFiche.idAction )
                    }

                    /**
                     * Ecouteur de l'événement de d'ouverture de fiche d'édition
                     */
                    var evenement = ( $attrs.modeEdition === "true" )?"Synthese":"";
                    $scope.$on("OperisOnglet_OuvrirFiche" + evenement  + "Evt",function handleOuvrirFicheEvent( event,args ) {
                            if ( !angular.isNullOrUndefined( args ) ){
                                if ( $attrs.modeEdition === "true" )
                                    $scope.$parent.ongletCtrl.vueFormulaireFicheSynthese = args;
                                else
                                    $scope.$parent.ongletCtrl.vueFormulaireFiche = args;
                            }else{
                                    if ( $attrs.modeEdition === "true" ){
                                        if ( !angular.isNullOrUndefined($scope.$parent.ongletCtrl.vueFormulaireFiche) )
                                            $scope.$parent.ongletCtrl.vueFormulaireFicheSynthese = $scope.$parent.ongletCtrl.vueFormulaireFiche;
                                    }
                                    else {
                                        if ( !angular.isNullOrUndefined($scope.$parent.ongletCtrl.vueFormulaireFicheSynthese) )
                                            $scope.$parent.ongletCtrl.vueFormulaireFiche = $scope.$parent.ongletCtrl.vueFormulaireFicheSynthese;
                                    }
                            }


                            // Dans le cas de la fiche, on place les données carto de la fiche
                            if (event.name == "OperisOnglet_OuvrirFicheEvt")
                                if ( $attrs.modeEdition === "true" )
                                    $scope.$parent.ongletCtrl.chargerDonneesCartoDepuisFiche($scope.$parent.ongletCtrl.vueFormulaireFicheSynthese);
                                else
                                    $scope.$parent.ongletCtrl.chargerDonneesCartoDepuisFiche($scope.$parent.ongletCtrl.vueFormulaireFiche);
                        }
                    );

                    /**
                     * Fonction d'ouverture d'entite dans un autre onglet
                     * @param {Object} aModule
                     * @param {String} aEntite
                     * @param {Object} aModele
                     */
                    function demanderActivite(aScope, aModule, aEntite, aModele){
                        var mapEntite = aEntite.split('#');
                        var colEntite = mapEntite[0];
                        var colModele = (mapEntite.length === 2) ? mapEntite[1] : mapEntite[0];
                        ongletActifService.demanderActivite(aScope, aModule, colEntite, aModele).then(function (onglet) {
                            // Baculer vers le nouvel onglet et ouvrir la synthèse
                            var clef = {};
                            clef[colEntite] = {valeur: aModele[colModele].valeur};
                            clef["DROITACCES"] = 0;

                            var nomModule = aModule.MODULENOM.valeur;
                            var trigramme = onglet.moduleTrigramme;
                            var mobilite = serveur.isMobile()?'_M':'';
                            var ecranServiceGestionnaire = trigramme + mobilite + '_SAUVEGARDE_SERVICEGESTIONNAIRE';
                            var identite = {};
                            identite[colEntite] = aModele[colModele].valeur;
                            serveur.chargement(nomModule, $scope,  ecranServiceGestionnaire, identite)
                                .then(function (retourChargement) {
                                        if (!angular.isNullOrUndefined(retourChargement[0])) {
                                            var idService = $scope.champs[retourChargement[0][0]].ID_SERVICEGESTIONNAIRE.valeur;
                                            clef["DROITACCES"] = habilitation.habilitationServiceGestionnaire()[idService];
                                        }
                                        onglet.ouvrirFiche(clef, null, true);
                                    },
                                    function () {
                                        onglet.ouvrirFiche(clef, null, true);
                                    });
                        });
                    }
                }
            ]
        };
    }])

    //Directive du planning
    .directive('opOngletPlanning',['fr.operis.moteurV4.utils.Planning', 'ongletActifService',
        function(utilsPlanning, ongletActifService) {
            return {
                restrict: 'E',
                scope: {
                    onglet: "="
                },
                transclude: true,
                bindToController: true,
                require: '^opOnglet',
                template: function (element, attributs) {
                    var retour = '<div ng-if="!planningCtrl.typePlanningConnu || (planningCtrl.selectionMultiple && (planningCtrl.actionCode !== \'GERERDISPONIBILITE\'))">' +
                        '<alert type="warning"><i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;{{planningCtrl.msgAvertissement}}</alert>' +
                        '</div>' +
                        '<div class="ecran" ng-if="planningCtrl.typePlanningConnu && (!planningCtrl.selectionMultiple || (planningCtrl.actionCode === \'GERERDISPONIBILITE\'))">' +
                        '<op-planning ' +
                        'op-contexte-planning="planningCtrl.contextePlanning" ' +
                        'op-nom-planning="planningCtrl.nomPlanning" ' +
                        'op-type-planning="planningCtrl.typePlanning" ' +
                        'op-nom-module="planningCtrl.nomModule" ' +
                        'op-action-code="planningCtrl.actionCode" ' +
                        'op-selection="planningCtrl.selection" ' +
                        'op-donnees-recherche="planningCtrl.donneesRecherche" ' +
                        'on-fin-chargement="planningCtrl.finChargementFormulaire(scope)" ' +
                        'on-update-actions="planningCtrl.gererStatutActions"' +
                        '/>' +
                        '</div>';

                    return retour;
                },
                controllerAs:'planningCtrl',
                link: function (scope, element, attrs) {},
                controller: ['$scope', '$attrs',
                    function($scope, $attrs) {
                        var vm = this;

                        // Déclaration des fonctions
                        vm.finChargementFormulaire = finChargementFormulaire;
                        vm.gererStatutActions = gererStatutActions;

                        // Gestion suite fin de chargement du fromulaire
                        function finChargementFormulaire(aScope){
                            $scope.$parent.ongletCtrl.scopeFormulaire = aScope;
                        }

                        // Mise à jour les statuts des actions du planning
                        function gererStatutActions(statut) {
                            // Nombre d'éléments sélectionnés
                            if (!angular.isNullOrUndefined(statut.nbreSelect)) {
                                $scope.$parent.ongletCtrl.planningParameter.paramActions.nbreSelect = statut.nbreSelect;
                            }

                            // Nombre d'éléments sélectionnés en lecture seule
                            if (!angular.isNullOrUndefined(statut.nbreSelectLectureSeule)) {
                                $scope.$parent.ongletCtrl.planningParameter.paramActions.nbreSelectLectureSeule = statut.nbreSelectLectureSeule;
                            }

                            // Modifications en cours ?
                            $scope.$parent.ongletCtrl.planningParameter.planningModifie = statut.planningModifie;

                            // Planning en lecture seule ?
                            $scope.$parent.ongletCtrl.planningParameter.lectureSeule = statut.lectureSeule;

                            // Libellé de la ressource sélectionnée
                            if (!angular.isNullOrUndefined(statut.libRessourceSelect)) {
                                $scope.$parent.ongletCtrl.planningParameter.paramActions.libRessourceSelect = statut.libRessourceSelect;
                            } else {
                                $scope.$parent.ongletCtrl.planningParameter.paramActions.libRessourceSelect = "";
                            }
                        }

                        // Gestion ouverture du planning
                        $scope.$on("OperisOnglet_OuvrirPlanningEvt", function handleOuvrirPlanningEvent(event, args) {
                            vm.args = args;

                            // Gestion du contexte
                            var titre = "";

                            if (!angular.isNullOrUndefined(args.titreContexte) && !angular.isNullOrUndefined(args.titreContexte)) {
                                titre = args.titreContexte;
                            } else if (!angular.isNullOrUndefined(args.selection) && !angular.isNullOrUndefined(args.selection[0])) {
                                var selection = args.selection[0];
                                for (var property in args.selection[0]) {
                                    if (!angular.isNullOrUndefined(selection[property].descriptions) && !angular.isNullOrUndefined(selection[property].descriptions.estChampPrincipal) && selection[property].descriptions.estChampPrincipal == true)
                                        titre = selection[property].valeur;
                                }
                            }

                            vm.contextePlanning = (titre.trim() === "") ? $scope.$parent.onglet.moduleLibelle : titre;
                            vm.nomPlanning = "planning" + args.nomModule + args.actionCode;
                            vm.typePlanning = utilsPlanning.getTypePlanning(args.nomModule, args.actionCode);
                            vm.nomModule = args.nomModule;
                            vm.actionCode = args.actionCode;
                            vm.selection = args.selection;
                            vm.typePlanningConnu = (!angular.isNullOrUndefined(vm.typePlanning))?true:false;
                            vm.selectionMultiple = (vm.selection.length > 1);
                            vm.donneesRecherche = $scope.$parent.ongletCtrl.donneesRecherche;

                            if (!vm.typePlanningConnu) {
                                vm.msgAvertissement = "Type de planning non définié pour l'action '" + vm.actionCode + "' du module " + vm.nomModule + ".";
                            } else if (vm.selectionMultiple) {
                                vm.msgAvertissement = "Veuillez ne sélectionner qu'un seul élément.";
                            } else {
                                vm.msgAvertissement = "Erreur lors de l'ouverture du planning pour l'action '" + vm.actionCode + "' du module " + vm.nomModule + ".";
                            }

                            vm.args.nomPlanning = vm.nomPlanning;
                            vm.args.typePlanning = vm.typePlanning;
                            $scope.$parent.ongletCtrl.planningParameter = vm.args;
                        });

                        // Gestion action du planning
                        $scope.$on("OperisOnglet_ActionPlanningEvt", function handleActionPlanningEvent(event, args) {
                            vm.args = args;
                            vm.args.nomPlanning = vm.nomPlanning;
                        });
                    }
                ]
            };
        }])

    //Directive des outils
    .directive('opOngletOutils',['fr.operis.moteurV4.communication.OperisWSServer',
        function(serveur) {
        return {
            restrict: 'E',
            transclude: true,
            require: '^opOnglet',
            template: function (){
                var retour =
                    '<!-- Cartographie -->'+
                    '<div ng-if="onglet.affichageOutils === \'cartographie\'" class="cartographie">'+
                        '<op-cartographie '+
                            'op-module="{{onglet.moduleNom}}" '+
                            'op-description="ongletCtrl.descriptionCarto" '+
                            'op-donnees="ongletCtrl.donneesCarto" '+
                            'op-selection="ongletCtrl.selectionRecherche" '+
                            'op-style="' + (serveur.isMobile() ? '-mobile"' : '"')+
                            'op-affichage-section-gauche="onglet.affichageSectionGauche"> '+
                        '</op-cartographie>'+
                    '</div>'+
                    '<!-- Planning -->'+
                    '<div ng-if="onglet.affichageOutils === \'planning\'" class="planning">'+
                        '<op-planning ' +
                        'op-contexte-planning="ongletCtrl.contexteRecherchePlanning" ' +
                        'op-nom-planning="ongletCtrl.nomRecherchePlanning" ' +
                        'op-type-planning="ongletCtrl.typeRecherchePlanning" ' +
                        'op-nom-module="ongletCtrl.moduleRecherchePlanning" ' +
                        'op-action-code="ongletCtrl.actionCodeRecherchePlanning"' +
                        'op-selection="ongletCtrl.selectionRecherche" ' +
                        'op-donnees-recherche="ongletCtrl.donneesRecherchePlanning" ' +
                        //'on-fin-chargement="planningCtrl.finChargementFormulaire(scope)" ' +
                        //'on-update-actions="planningCtrl.gererStatutActions"' +
                        '/>' +
                    '</div>'+
                    '<!-- Statistiques -->'+
                    '<div ng-if="onglet.affichageOutils === \'statistiques\'" ng-controller="SyntheseCtrl as syntheseCtrl" class="statistiques">'+
                        '<!-- Demandes -->'+
                        //'<div ng-if="onglet.actionCode === \'Deman\'">'+
                        '<div ng-if="onglet.moduleNom === \'Demande\'">'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieDemandePriorite" op-chart-options="optionsPie" op-chart-legend="legendPieDemandePriorite" op-titre="{{titrePieDemandePriorite}}"></op-chart-pie>'+
                                '<op-chart-pie op-chart-data="dataPieDemandeEtat" op-chart-options="optionsPie" op-chart-legend="legendPieDemandeEtat" op-titre="{{titrePieDemandeEtat}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-8">'+
                                '<op-chart-bar op-chart-data="dataBar" op-chart-options="optionsBar" op-chart-legend="legendBar" op-titre="{{titreBar}}"></op-chart-bar>'+
                            '</div>'+
                        '</div>'+
                        '<!-- Interventions -->'+
                        //'<div ng-if="onglet.actionCode === \'Inter\'">'+
                        '<div ng-if="onglet.moduleNom === \'Intervention\'">'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieEtatInterv" op-chart-options="optionsPie" op-chart-legend="legendPieEtatInterv" op-titre="{{titrePieEtatInterv}}"></op-chart-pie>'+
                            '</div>'+
                        '</div>'+
                        '<!-- Patrimoine -->'+
                        //'<div ng-if="onglet.actionCode === \'Patri\'">'+
                        '<div ng-if="onglet.moduleNom === \'Patrimoine\'">'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieCategPatri" op-chart-options="optionsPie" op-chart-legend="legendPieCategPatri" op-titre="{{titrePieCategPatri}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeBati" op-chart-options="optionsPie" op-chart-legend="legendPieTypeBati" op-titre="{{titrePieTypeBati}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeEspVert" op-chart-options="optionsPie" op-chart-legend="legendPieTypeEspVert" op-titre="{{titrePieTypeEspVert}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeAssain" op-chart-options="optionsPie" op-chart-legend="legendPieTypeAssain" op-titre="{{titrePieTypeAssain}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeVoirie" op-chart-options="optionsPie" op-chart-legend="legendPieTypeVoirie" op-titre="{{titrePieTypeVoirie}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeMotor" op-chart-options="optionsPie" op-chart-legend="legendPieTypeMotor" op-titre="{{titrePieTypeMotor}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeSite" op-chart-options="optionsPie" op-chart-legend="legendPieTypeSite" op-titre="{{titrePieTypeSite}}"></op-chart-pie>'+
                            '</div>'+
                        '</div>'+
                        '<div ng-if="onglet.moduleNom === \'Batiment\'">'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieCategPatri" op-chart-options="optionsPie" op-chart-legend="legendPieCategPatri" op-titre="{{titrePieCategPatri}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeBati" op-chart-options="optionsPie" op-chart-legend="legendPieTypeBati" op-titre="{{titrePieTypeBati}}"></op-chart-pie>'+
                            '</div>'+
                        '</div>'+
                        '<div ng-if="onglet.moduleNom === \'EspaceVert\'">'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieCategPatri" op-chart-options="optionsPie" op-chart-legend="legendPieCategPatri" op-titre="{{titrePieCategPatri}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeEspVert" op-chart-options="optionsPie" op-chart-legend="legendPieTypeEspVert" op-titre="{{titrePieTypeEspVert}}"></op-chart-pie>'+
                            '</div>'+
                        '</div>'+
                        '<div ng-if="onglet.moduleNom === \'Assainissement\'">'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieCategPatri" op-chart-options="optionsPie" op-chart-legend="legendPieCategPatri" op-titre="{{titrePieCategPatri}}"></op-chart-pie>'+
                            '</div>'+
                            '<div class="col-xs-4">'+
                                '<op-chart-pie op-chart-data="dataPieTypeAssain" op-chart-options="optionsPie" op-chart-legend="legendPieTypeAssain" op-titre="{{titrePieTypeAssain}}"></op-chart-pie>'+
                            '</div>'+
                        '</div>'+
                    '</div>';
                return retour;
            },
            controllerAs: 'outilsCtrl',
            controller: ['$scope',
                function($scope){
                    var vm = this;
                }
            ]
        };
    }])
    .controller('SyntheseCtrl',
        ['$scope', 'fr.operis.moteurV4.communication.OperisWSServer',
            function ($scope, serveur) {

                function getRandomColor() {
                    var letters = '0123456789ABCDEF'.split('');
                    var color = '#';
                    for (var i = 0; i < 6; i++ ) {
                        color += letters[Math.floor(Math.random() * 16)];
                    }

                    if (color == '#FFFFFF') {
                        color = '#E6E6E6';
                    }

                    return color;
                }

                function colorLuminance(hex, lum) {

                    // validate hex string
                    hex = String(hex).replace(/[^0-9a-f]/gi, '');
                    if (hex.length < 6) {
                        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
                    }
                    lum = lum || 0;

                    // convert to decimal and change luminosity
                    var rgb = "#", c, i;
                    for (i = 0; i < 3; i++) {
                        c = parseInt(hex.substr(i*2,2), 16);
                        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
                        rgb += ("00"+c).substr(c.length);
                    }

                    return rgb;
                }

                // Ecrans gérés
                var nomEcranPieObjet = 'OperiaSynthese1Activite_Deman';
                var nomEcranPiePriorite = 'SyntheseDemandesPriorite';
                var nomEcranPieEtatDemande = 'SyntheseDemandesEtat';
                var nomEcranPieEtatInterv = 'SyntheseInterventionEtat';
                var nomEcranPieCategPatri = 'SynthesePatrimoineCateg';
                var nomEcranPieTypePatri = 'SynthesePatrimoineType';
                var nomEcranPieTypeBati = 'SynthesePatrimoineTypeBati';
                var nomEcranPieTypeEspVert = 'SynthesePatrimoineTypeEspVert';
                var nomEcranPieTypeAssain = 'SynthesePatrimoineTypeAssain';
                var nomEcranPieTypeVoirie = 'SynthesePatrimoineTypeVoirie';
                var nomEcranPieTypeMotor = 'SynthesePatrimoineTypeMotor';
                var nomEcranPieTypeSite = 'SynthesePatrimoineTypeSite';
                var nomEcranBar = 'OperiaSynthese2Activite_Deman';

                function updatePie(nomEcran) {

                    var dataPie = [];
                    var objGrilles = [];

                    if (nomEcran == nomEcranPieObjet) {
                        objGrilles = $scope.grilles.VL_OP_DEMANDEGROUPEOBJET;
                    } else if (nomEcran == nomEcranPiePriorite) {
                        objGrilles = $scope.grilles.VL_OP_DEMANDEGROUPEPRIORITE;
                    } else if (nomEcran == nomEcranPieEtatDemande) {
                        objGrilles = $scope.grilles.VL_OP_DEMANDEGROUPEETAT;
                    } else if (nomEcran == nomEcranPieEtatInterv) {
                        objGrilles = $scope.grilles.VL_OP_INTERVENTIONGROUPEETAT;
                    } else if (nomEcran == nomEcranPieCategPatri) {
                        objGrilles = $scope.grilles.VL_OP_PATRIMOINEGROUPECATEG;
                    } else if (nomEcran == nomEcranPieTypePatri) {
                        objGrilles = $scope.grilles.VL_OP_PATRIMOINEGROUPETYPE;
                    } else if (nomEcran == nomEcranPieTypeBati) {
                        objGrilles = $scope.grilles.VL_OP_BATIGROUPETYPE;
                    } else if (nomEcran == nomEcranPieTypeEspVert) {
                        objGrilles = $scope.grilles.VL_OP_ESPVGROUPETYPE;
                    } else if (nomEcran == nomEcranPieTypeAssain) {
                        objGrilles = $scope.grilles.VL_OP_ASSAINGROUPETYPE;
                    } else if (nomEcran == nomEcranPieTypeVoirie) {
                        objGrilles = $scope.grilles.VL_OP_VOIRIEGROUPETYPE;
                    } else if (nomEcran == nomEcranPieTypeMotor) {
                        objGrilles = $scope.grilles.VL_OP_MOTORGROUPETYPE;
                    } else if (nomEcran == nomEcranPieTypeSite) {
                        objGrilles = $scope.grilles.VL_OP_SITEGROUPETYPE;
                    }

                    for (var i = 0; i < objGrilles.length; ++i) {

                        // Couleur aléatoire
                        var couleur = getRandomColor();
                        var surbrillance = colorLuminance(couleur, 0.5);

                        // Nombre de demandes et Objet de demande
                        var obj = objGrilles[i];

                        dataPie.push(
                            {
                                value: obj.VALEURS.valeur,
                                color: couleur,
                                highlight: surbrillance,
                                label: obj.GROUPE.valeur
                            }
                        )
                    }

                    if (nomEcran == nomEcranPieObjet) {
                        $scope.dataPieDemandeObjet = dataPie;
                    } else if (nomEcran == nomEcranPiePriorite) {
                        $scope.dataPieDemandePriorite = dataPie;
                    } else if (nomEcran == nomEcranPieEtatDemande) {
                        $scope.dataPieDemandeEtat = dataPie;
                    } else if (nomEcran == nomEcranPieEtatInterv) {
                        $scope.dataPieEtatInterv = dataPie;
                    } else if (nomEcran == nomEcranPieCategPatri) {
                        $scope.dataPieCategPatri = dataPie;
                    } else if (nomEcran == nomEcranPieTypePatri) {
                        $scope.dataPieTypePatri = dataPie;
                    } else if (nomEcran == nomEcranPieTypeBati) {
                        $scope.dataPieTypeBati = dataPie;
                    } else if (nomEcran == nomEcranPieTypeEspVert) {
                        $scope.dataPieTypeEspVert = dataPie;
                    } else if (nomEcran == nomEcranPieTypeAssain) {
                        $scope.dataPieTypeAssain = dataPie;
                    } else if (nomEcran == nomEcranPieTypeVoirie) {
                        $scope.dataPieTypeVoirie = dataPie;
                    } else if (nomEcran == nomEcranPieTypeMotor) {
                        $scope.dataPieTypeMotor = dataPie;
                    } else if (nomEcran == nomEcranPieTypeSite) {
                        $scope.dataPieTypeSite = dataPie;
                    }
                }

                function updateDoughnut() {

                    $scope.titreDoughnut = 'Demandes par objet';

                    // Custom Legend Variable, to link chart to the legend directive
                    $scope.legendDoughnut = '';

                    // Données Chart.js
                    $scope.dataDoughnut = [];

                    for (var i = 0; i < $scope.grilles.VL_OP_DEMANDEGROUPEOBJET.length; ++i) {

                        // Couleur aléatoire
                        var couleur = getRandomColor();
                        var surbrillance = colorLuminance(couleur, 0.5);

                        // Nombre de demandes et Objet de demande
                        var obj = $scope.grilles.VL_OP_DEMANDEGROUPEOBJET[i];

                        $scope.dataDoughnut.push(
                            {
                                value: obj.VALEURS.valeur,
                                color: couleur,
                                highlight: surbrillance,
                                label: obj.GROUPE.valeur
                            }
                        )
                    }

                    // Options Chart.js
                    $scope.optionsDoughnut =  {

                        // Sets the chart to be responsive
                        responsive: true,

                        //Boolean - Whether we should show a stroke on each segment
                        segmentShowStroke : true,

                        //String - The colour of each segment stroke
                        segmentStrokeColor : '#fff',

                        //Number - The width of each segment stroke
                        segmentStrokeWidth : 2,

                        //Number - The percentage of the chart that we cut out of the middle
                        percentageInnerCutout : 50, // This is 0 for Pie charts

                        //Number - Amount of animation steps
                        animationSteps : 100,

                        //String - Animation easing effect
                        animationEasing : 'easeOutBounce',

                        //Boolean - Whether we animate the rotation of the Doughnut
                        animateRotate : true,

                        //Boolean - Whether we animate scaling the Doughnut from the centre
                        animateScale : false,

                        //String - A legend template
                        legendTemplate : '<ul class="tc-chart-js-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="font-size:20px;color:<%=segments[i].fillColor%>"><strong>&#x25A0;&nbsp;</strong></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>'

                    };
                }

                function updateBar() {

                    $scope.titreBar = 'Nombre de demandes par année et mois';

                    // Custom Legend Variable, to link chart to the legend directive
                    $scope.legendBar = '';

                    // Données Chart.js
                    // Chargement libellés
                    $scope.dataBar = {
                        labels: ['janv', 'fév', 'mars', 'avril', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
                        datasets: []
                    };

                    // Chargement ensembles de données
                    for (var i = 0; i < $scope.grilles.VL_OP_DEMANDEGROUPEDATECRE.length; ++i) {

                        // Nombre de demandes par année et mois
                        var obj = $scope.grilles.VL_OP_DEMANDEGROUPEDATECRE[i];

                        // Libellés axe X
                        if (i == 0 && obj.LIBELLES_X.valeur != "") {
                            var lbl = obj.LIBELLES_X.valeur;
                            $scope.dataBar.labels = lbl.split(",");
                        }

                        // Groupes et données
                        var annee = obj.GROUPE.valeur;

                        // Récupérer index du groupe
                        var j = $scope.dataBar.datasets.length;
                        while( j-- ) {
                            if( $scope.dataBar.datasets[j].label === annee ) break;
                        }

                        var idxGroupe = j;

                        if (idxGroupe == -1) {

                            // Couleur aléatoire
                            var couleur = getRandomColor();
                            var couleurRemplissage = colorLuminance(couleur, 0.5);
                            var couleurLigne = colorLuminance(couleur, 0.8);
                            var surbrillanceRemplissage = colorLuminance(couleur, 0.75);
                            var surbrillanceLigne = colorLuminance(couleur, 1);

                            var ds = {
                                label: annee,
                                fillColor: couleurRemplissage,
                                strokeColor: couleurLigne,
                                highlightFill: surbrillanceRemplissage,
                                highlightStroke: surbrillanceLigne,
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            };

                            $scope.dataBar.datasets.push(ds);
                            idxGroupe = $scope.dataBar.datasets.length - 1;
                        }

                        var idxData = Number(obj.VALEURS_X.valeur) - 1;
                        $scope.dataBar.datasets[idxGroupe].data[idxData] = obj.VALEURS_Y.valeur;
                    }

                    // Options Chart.js
                    $scope.optionsBar = {

                        // Sets the chart to be responsive
                        responsive: true,

                        //Boolean - Whether the scale should start at zero, or an order of magnitude down from the lowest value
                        scaleBeginAtZero : true,

                        //Boolean - Whether grid lines are shown across the chart
                        scaleShowGridLines : true,

                        //String - Colour of the grid lines
                        scaleGridLineColor : "rgba(0,0,0,.05)",

                        //Number - Width of the grid lines
                        scaleGridLineWidth : 1,

                        //Boolean - If there is a stroke on each bar
                        barShowStroke : true,

                        //Number - Pixel width of the bar stroke
                        barStrokeWidth : 2,

                        //Number - Spacing between each of the X value sets
                        barValueSpacing : 5,

                        //Number - Spacing between data sets within X values
                        barDatasetSpacing : 1,

                        //String - A legend template
                        legendTemplate : '<ul class="tc-chart-js-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="font-size:20px;color:<%=datasets[i].fillColor%>"><strong>&#x25A0;&nbsp;</strong></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>'
                    };
                }

                function updateLine() {

                    $scope.titreLine = 'Nombre de demandes par année et mois';

                    // Custom Legend Variable, to link chart to the legend directive
                    $scope.legendLine = '';

                    // Données Chart.js
                    // Chargement libellés
                    $scope.dataLine = {
                        labels: ['janv', 'fév', 'mars', 'avril', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'],
                        datasets: []
                    };

                    // Chargement ensembles de données
                    for (var i = 0; i < $scope.grilles.VL_OP_DEMANDEGROUPEDATECRE.length; ++i) {

                        // Nombre de demandes par année et mois
                        var obj = $scope.grilles.VL_OP_DEMANDEGROUPEDATECRE[i];

                        // Libellés axe X
                        if (i == 0 && obj.LIBELLES_X.valeur != "") {
                            var lbl = obj.LIBELLES_X.valeur;
                            $scope.dataLine.labels = lbl.split(",");
                        }

                        // Groupes et données
                        var annee = obj.GROUPE.valeur;

                        // Récupérer index du groupe
                        var j = $scope.dataLine.datasets.length;
                        while( j-- ) {
                            if( $scope.dataLine.datasets[j].label === annee ) break;
                        }

                        var idxGroupe = j;

                        if (idxGroupe == -1) {

                            // Couleur aléatoire
                            var couleur = getRandomColor();
                            var couleurRemplissage = colorLuminance(couleur, 0.2);
                            var couleurLigne = colorLuminance(couleur, 1);
                            var couleurRemplissagePoint = colorLuminance(couleur, 1);
                            var couleurLignePoint = '#fff'; //colorLuminance(couleur, 0.75);
                            var surbrillanceRemplissagePoint = '#fff'; //colorLuminance(couleur, 0.75);
                            var surbrillanceLignePoint = colorLuminance(couleur, 1);

                            var ds = {
                                label: annee,
                                fillColor: couleurRemplissage,
                                strokeColor: couleurLigne,
                                pointColor: couleurRemplissagePoint,
                                pointStrokeColor: couleurLignePoint,
                                pointHighlightFill: surbrillanceRemplissagePoint,
                                pointHighlightStroke: surbrillanceLignePoint,
                                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                            };

                            $scope.dataLine.datasets.push(ds);
                            idxGroupe = $scope.dataLine.datasets.length - 1;
                        }

                        var idxData = Number(obj.VALEURS_X.valeur) - 1;
                        $scope.dataLine.datasets[idxGroupe].data[idxData] = obj.VALEURS_Y.valeur;
                    }

                    // Options Chart.js
                    $scope.optionsLine =  {

                        // Sets the chart to be responsive
                        responsive: true,

                        ///Boolean - Whether grid lines are shown across the chart
                        scaleShowGridLines : true,

                        //String - Colour of the grid lines
                        scaleGridLineColor : "rgba(0,0,0,.05)",

                        //Number - Width of the grid lines
                        scaleGridLineWidth : 1,

                        //Boolean - Whether the line is curved between points
                        bezierCurve : true,

                        //Number - Tension of the bezier curve between points
                        bezierCurveTension : 0.4,

                        //Boolean - Whether to show a dot for each point
                        pointDot : true,

                        //Number - Radius of each point dot in pixels
                        pointDotRadius : 4,

                        //Number - Pixel width of point dot stroke
                        pointDotStrokeWidth : 1,

                        //Number - amount extra to add to the radius to cater for hit detection outside the drawn point
                        pointHitDetectionRadius : 20,

                        //Boolean - Whether to show a stroke for datasets
                        datasetStroke : true,

                        //Number - Pixel width of dataset stroke
                        datasetStrokeWidth : 2,

                        //Boolean - Whether to fill the dataset with a colour
                        datasetFill : true,

                        // Function - on animation progress
                        onAnimationProgress: function(){},

                        // Function - on animation complete
                        onAnimationComplete: function(){},

                        //String - A legend template
                        legendTemplate : '<ul class="tc-chart-js-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="font-size:20px;color:<%=datasets[i].fillColor%>"><strong>&#x25A0;&nbsp;</strong></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>'
                    };
                }

                // Options
                $scope.optionsPie = {
                    responsive: true,// Sets the chart to be responsive
                    segmentShowStroke : true,//Boolean - Whether we should show a stroke on each segment
                    segmentStrokeColor : '#fff',//String - The colour of each segment stroke
                    segmentStrokeWidth : 2,//Number - The width of each segment stroke
                    percentageInnerCutout : 0, //Number - The percentage of the chart that we cut out of the middle (This is 0 for Pie charts)
                    animationSteps : 100,//Number - Amount of animation steps
                    animationEasing : 'easeOutBounce',//String - Animation easing effect
                    animateRotate : true,//Boolean - Whether we animate the rotation of the Doughnut
                    animateScale : false,//Boolean - Whether we animate scaling the Doughnut from the centre
                    //String - A legend template
                    legendTemplate : '<ul class="tc-chart-js-legend"><% for (var i=0; i<segments.length; i++){%><li><span style="font-size:20px;color:<%=segments[i].fillColor%>"><strong>&#x25A0;&nbsp;</strong></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>'
                };

                // Chart demandes par objet
                $scope.titrePieDemandeObjet = 'Demandes par objet';
                $scope.legendPieDemandeObjet = '';
                $scope.dataPieDemandeObjet = [];

                // Chart demandes par priorité
                $scope.titrePieDemandePriorite = 'Demandes par priorité';
                $scope.legendPieDemandePriorite = '';
                $scope.dataPieDemandePriorite = [];

                // Chart demandes par état
                $scope.titrePieDemandeEtat = 'Etat des demandes';
                $scope.legendPieDemandeEtat = '';
                $scope.dataPieDemandeEtat = [];

                // Chart interventions par état
                $scope.titrePieEtatInterv = 'Etat des interventions';
                $scope.legendPieEtatInterv = '';
                $scope.dataPieEtatInterv = [];

                // Chart patrimoine par catégorie
                $scope.titrePieCategPatri = 'Catégories de patrimoine';
                $scope.legendPieCategPatri = '';
                $scope.dataPieCategPatri = [];

                // Chart patrimoine par type
                $scope.titrePieTypePatri = 'Types de patrimoine';
                $scope.legendPieTypePatri = '';
                $scope.dataPieTypePatri = [];

                // Chart patrimoine bâti par type
                $scope.titrePieTypeBati = 'Bâti';
                $scope.legendPieTypeBati = '';
                $scope.dataPieTypeBati = [];

                // Chart patrimoine espace vert par type
                $scope.titrePieTypeEspVert = 'Espace vert';
                $scope.legendPieTypeEspVert = '';
                $scope.dataPieTypeEspVert = [];

                // Chart patrimoine eau et assainissement par type
                $scope.titrePieTypeAssain = 'Eau et assainissement';
                $scope.legendPieTypeAssain = '';
                $scope.dataPieTypeAssain = [];

                // Chart patrimoine voirie par type
                $scope.titrePieTypeVoirie = 'Voirie';
                $scope.legendPieTypeVoirie = '';
                $scope.dataPieTypeVoirie = [];

                // Chart patrimoine parc motorisé par type
                $scope.titrePieTypeMotor = 'Parc motorisé';
                $scope.legendPieTypeMotor = '';
                $scope.dataPieTypeMotor = [];

                // Chart patrimoine site par type
                $scope.titrePieTypeSite = 'Site';
                $scope.legendPieTypeSite = '';
                $scope.dataPieTypeSite = [];

                // Chargement
                serveur.chargement('Demande', $scope, nomEcranPieObjet, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieObjet](30000);
                    })
                    .then(function (retourRecherche) {
                        //updatePie(nomEcranPieObjet);
                        updateDoughnut();
                    });

                serveur.chargement('Demande', $scope, nomEcranPiePriorite, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPiePriorite](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPiePriorite);
                        //updateDoughnut();
                    });

                serveur.chargement('Demande', $scope, nomEcranPieEtatDemande, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieEtatDemande](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieEtatDemande);
                        //updateDoughnut();
                    });

                serveur.chargement('Intervention', $scope, nomEcranPieEtatInterv, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieEtatInterv](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieEtatInterv);
                        //updateDoughnut();
                    });

                serveur.chargement('Patrimoine', $scope, nomEcranPieCategPatri, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieCategPatri](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieCategPatri);
                        //updateDoughnut();
                    });

                /*serveur.chargement('Patrimoine', $scope, nomEcranPieTypePatri, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypePatri](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypePatri);
                        //updateDoughnut();
                    });*/

                serveur.chargement('Patrimoine', $scope, nomEcranPieTypeBati, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypeBati](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypeBati);
                        //updateDoughnut();
                    });

                serveur.chargement('Patrimoine', $scope, nomEcranPieTypeEspVert, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypeEspVert](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypeEspVert);
                        //updateDoughnut();
                    });

                serveur.chargement('Patrimoine', $scope, nomEcranPieTypeAssain, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypeAssain](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypeAssain);
                        //updateDoughnut();
                    });

                serveur.chargement('Patrimoine', $scope, nomEcranPieTypeVoirie, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypeVoirie](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypeVoirie);
                        //updateDoughnut();
                    });

                serveur.chargement('Patrimoine', $scope, nomEcranPieTypeMotor, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypeMotor](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypeMotor);
                        //updateDoughnut();
                    });

                serveur.chargement('Patrimoine', $scope, nomEcranPieTypeSite, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranPieTypeSite](30000);
                    })
                    .then(function (retourRecherche) {
                        updatePie(nomEcranPieTypeSite);
                        //updateDoughnut();
                    });

                serveur.chargement('Demande', $scope, nomEcranBar, null)
                    .then(function (retourChargement) {
                        return $scope.recherche[nomEcranBar](30000);
                    })
                    .then(function (retourRecherche) {
                        updateBar();
                        //updateLine();
                    });

                $scope.reload = function () {
                    serveur.chargement('Demande', $scope, nomEcranPieObjet);
                    serveur.chargement('Demande', $scope, nomEcranPiePriorite);
                    serveur.chargement('Demande', $scope, nomEcranPieEtatDemande);
                    serveur.chargement('Intervention', $scope, nomEcranPieEtatInterv);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieCategPatri);
                    //serveur.chargement('Patrimoine', $scope, nomEcranPieTypePatri);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieTypeBati);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieTypeEspVert);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieTypeAssain);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieTypeVoirie);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieTypeMotor);
                    serveur.chargement('Patrimoine', $scope, nomEcranPieTypeSite);
                    serveur.chargement('Demande', $scope, nomEcranBar);
                };
            }])
;
