/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpPopupAlerte', [
    'ui.bootstrap',
    'fr.operis.moteurV4.directives.OpAutoFocus'])
    .controller('fr.operis.moteurV4.composants.OpPopupAlerteControleur',
    ['$scope', '$modalInstance','type','libelle',
        function ($scope, $modalInstance, type, libelle) {
            var titre = "Information";

            if ((type === 'danger') || (type === 'warning')) {
                titre = "Avertissement";
            }  else if (type === 'error') {
                titre = "Erreur";
            }

            $scope.alerte={
                titre: titre,
                type: type,
                libelle: libelle
            };

            $scope.ok = function () {
                $modalInstance.close();
            };

            $scope.annuler = function () {
                $modalInstance.dismiss();
            };
        }])

    .service('fr.operis.moteurV4.composants.OpPopupAlerte', ['$modal', function ($modal) {
        this.creer = function(type,libelle,aBoutonOK, aBoutonNO, libBoutonOK, libBoutonNO, titre){
            var boutonNO = (angular.isNullOrUndefined(aBoutonNO))?true:aBoutonNO;
            var boutonOK = (angular.isNullOrUndefined(aBoutonNO))?false:aBoutonOK;
            
            if(angular.isNullOrUndefined(titre)) {
                titre = "Information";

                if (boutonNO && boutonOK) {
                    titre = "Confirmation";
                } else if ((type === 'danger') || (type === 'warning')) {
                    titre = "Avertissement";
                }  else if (type === 'error') {
                    titre = "Erreur";
                }
            }

            var libOK = "OK";
            if (!angular.isNullOrUndefined(libBoutonOK)) {
                libOK = libBoutonOK;
            }

            var libNO = "Annuler";
            if (!angular.isNullOrUndefined(libBoutonNO)) {
                libNO = libBoutonNO;
            }

            var popupOpAlerte = $modal.open({
                template: '<div class="modal-header">' +
                                '<h4 class="modal-title">' + titre + '</h4>' +
                          '</div>' +
                          '<div class="modal-body">'+
                                '<div>'+
                                    '<alert type="{{alerte.type}}" ng-bind-html="alerte.libelle"></alert>'+
                                '</div>'+
                                '<form>'+
                                    '<div class="modal-footer">'+
                                        '<button ng-if=" '+ boutonOK +'" class="btn btn-primary" type="button" ng-click="ok()">' + libOK + '</button>'+
                                        '<button ng-if=" '+ boutonNO +'" class="btn btn-warning" type="button" ng-click="annuler()">' + libNO + '</button>'+
                                    '</div>'+
                                '</form>'+
                            '</div>',
                controller: 'fr.operis.moteurV4.composants.OpPopupAlerteControleur',
                size: 'sm',
                resolve: {
                    type: function () {
                        return type;
                    },
                    libelle: function () {
                        return libelle;
                    }
                }
            });

            return popupOpAlerte;
        }
    }]);