/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.UtilisateurContexteIHM', [])

    .constant('fr.operis.moteurV4.utils.UtilisateurContexteIHM.ECRAN', 'OperiaAccueilUtilisateurContexteIHM')
    .constant('fr.operis.moteurV4.utils.UtilisateurContexteIHM.VUE_UTILISATEURCONTEXTEIHM', 'VL_OP_PRM_UTILISATEURCONTEXTEIHM')

    .service('fr.operis.moteurV4.utils.UtilisateurContexteIHM',
        ['fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.utils.Conversion',
            'fr.operis.moteurV4.utils.UtilisateurContexteIHM.ECRAN',
            'fr.operis.moteurV4.utils.UtilisateurContexteIHM.VUE_UTILISATEURCONTEXTEIHM',
        function (serveur, conversion, ECRAN, VUE_UTILISATEURCONTEXTEIHM) {

            var _utilisateurContexteIHM={};

            // Fonction de chargement de l'écran de contexte
            this.chargement = function(nomModule){
                    var idUtilisateur = conversion.versEntier(serveur.getIdUtilisateur(nomModule)());
                    if ( !angular.isNullOrUndefined( idUtilisateur ) ){
                        var that = this
                        serveur.chargement(nomModule, that, ECRAN, {"ID_UTILISATEUR":idUtilisateur})
                            .then(function (retourChargement) {
                                if ( !angular.isNullOrUndefined( that.grilles[VUE_UTILISATEURCONTEXTEIHM] )){
                                    that.grilles[VUE_UTILISATEURCONTEXTEIHM].forEach(function(item){
                                        if (angular.isNullOrUndefined(_utilisateurContexteIHM[item.IDENT_MODULE]))
                                            _utilisateurContexteIHM[item.IDENT_MODULE] = item.IHMCONTEXTE.valeur;
                                    })
                                }
                            })
                    }
             };

            // Fonction de récupération du contexte
            this.getContexte = function (nomModule){
                if ( angular.isNullOrUndefined(nomModule) )
                    return;

                var idModule = conversion.versEntier(serveur.getIdModule(nomModule));
                if ( angular.isNullOrUndefined(idModule)  )
                    return;

                if ( angular.isNullOrUndefined(_utilisateurContexteIHM[idModule] ) )
                    return;

                return _utilisateurContexteIHM[idModule];
            };

        }



    ]
);