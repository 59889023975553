/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.GenerationFormulaire', ['fr.operis.moteurV4.modele.Modele'
    ,'fr.operis.moteurV4.utils.GestionActionFormulaire'
    ,'fr.operis.moteurV4.communication.OperisWSServer'
    ,'fr.operis.moteurV4.utils.Conversion'])
    /**
     * Nom de l'attribut du type champs
     * @type {String}
     * @value champs
     */
    .constant('fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_CHAMPS', 'TYPE_CHAMPS')

    /**
     * Nom de l'attribut du type grille
     * @type {String}
     * @value grille
     */
    .constant('fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_GRILLE', 'TYPE_GRILLE')

    /**
     * Nom de l'attribut du type image
     * @type {String}
     * @value IMAGE
     */
    .constant('fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_COMPLEXE_IMAGE', 'IMAGE')

    .service('fr.operis.moteurV4.utils.GenerationFormulaire',
        ['fr.operis.moteurV4.modele.Modele.SCOPE_NOM',
        'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS',
        'fr.operis.moteurV4.modele.Modele.SCOPE_CLE_PRIMAIRE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_CLE_ETRANGERE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_ACTION',
        'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS',
        'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES',
        'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_LONGUEUR_MAX',
        'fr.operis.moteurV4.modele.Modele.SCOPE_LIBELLE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_VISIBLE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_REPRESENTATION',
        'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_INTERACTION',
        'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_COMPLEXE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_REF_ETRANGERE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_NOM_GROUPE',
        'fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_CHAMPS',
        'fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_GRILLE',
        'fr.operis.moteurV4.utils.GenerationFormulaire.TYPE_COMPLEXE_IMAGE',
        'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE',
        'fr.operis.moteurV4.utils.GestionActionFormulaire.separateurActionNiveau1',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Config',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.Representation',
        'fr.operis.moteurV4.utils.GestionGrilleElementPrincipal',
        'fr.operis.moteurV4.utils.GestionRegroupement',
        'fr.operis.moteurV4.utils.ModuleInfos',
        '$location',
        '$anchorScroll',
        '$timeout',
        function (SCOPE_NOM,
                  SCOPE_DESCRIPTIONS,
                  SCOPE_CLE_PRIMAIRE,
                  SCOPE_CLE_ETRANGERE,
                  SCOPE_ACTION,
                  SCOPE_CHAMPS,
                  SCOPE_GRILLES,
                  SCOPE_TYPE,
                  SCOPE_LONGUEUR_MAX,
                  SCOPE_LIBELLE,
                  SCOPE_VISIBLE,
                  SCOPE_REPRESENTATION,
                  SCOPE_TYPE_INTERACTION,
                  SCOPE_TYPE_COMPLEXE,
                  SCOPE_REF_ETRANGERE,
                  SCOPE_NOM_GROUPE,
                  TYPE_CHAMPS,
                  TYPE_GRILLE,
                  TYPE_COMPLEXE_IMAGE,
                  SEPARATEUR_INTERNE,
                  separateurActionNiveau1,
                  serveur,
                  config,
                  conversion,
                  representationService,
                  gestionGrilleElementPrincipal,
                  gestionRegroupement,
                  moduleInfos,
                  $location,
                  $anchorScroll,
                  $timeout
                  ) {

            var nomGestionnaireAction;


            /**
             * Recupération du type de grille
             * @param {String} nomGroupe
             */
            this.getTypeGrille = function(nomGroupe){
                if ( !angular.isNullOrUndefined(nomGroupe)){
                    var nomGroupeArray = nomGroupe.split('|');
                    if ( nomGroupeArray.length >= 6 ){
                        return nomGroupeArray[5];
                    }
                }
                return null;
            };


            /**
             * Recupération de l'élement principal d'une grille
             * @param {Objet} aScope scope
             * @param {Entier} nomGrille nom de la grille
             */
            this.getElementPrincipal = function (aScope,nomGrille){
                // Récupération du champs principal
                var nomChampsPrincipal, indexElementPrincipal = -1;

                if (angular.isNullOrUndefined (aScope.descriptions[nomGrille]))
                    return indexElementPrincipal;

                for ( var i=0; i<aScope.descriptions[nomGrille].length;i++ ){
                    if ( aScope.descriptions[nomGrille][i].nom.toUpperCase().indexOf("ESTPRINCIPAL") !==-1 ){
                        nomChampsPrincipal = aScope.descriptions[nomGrille][i].nom;
                        break;
                    }
                }

                // Recheche de l'élément principal dans la grille
                if ( !angular.isNullOrUndefined( nomChampsPrincipal ) ){
                    for ( var i=0; i<aScope.grilles[nomGrille].length;i++ ){
                        if ( aScope.grilles[nomGrille][i][nomChampsPrincipal].valeur === true){
                            indexElementPrincipal =  i;
                            break;
                        }
                    }
                }

                return indexElementPrincipal;
            };

            /**
             * Recupération de la liste
             * @param {Objet} descriptionChamps description du champs
             */
            this.getListe = function (aScope,descriptionChamps){
                var liste='';
                if (descriptionChamps[SCOPE_TYPE_INTERACTION] == 'LIST' ||
                    descriptionChamps[SCOPE_TYPE_INTERACTION] == 'MULTIPLE' ||
                    descriptionChamps[SCOPE_TYPE_INTERACTION] == 'DATALIST' ||
                    descriptionChamps[SCOPE_TYPE_INTERACTION] == 'TYPEAHEAD') {
                    var refEtranger = descriptionChamps[SCOPE_REF_ETRANGERE];
                    if( !angular.isNullOrUndefined(refEtranger)){
                        var refEtrangerArray = refEtranger.split('|');
                        if ( !angular.isNullOrUndefined( refEtrangerArray) ){
                            // Récupération de la liste
                            var listeNom = [];

                            if ( refEtrangerArray.length > 1 ){
                                listeNom = refEtrangerArray[0];
                                var listeArray = refEtrangerArray[1].split('#');
                                var listeLabel = listeArray[0], listeValeur = listeLabel,listeGroupement;
                                if ( listeArray.length === 2){
                                    listeLabel  = listeArray[0];
                                    listeValeur = listeArray[1];
                                }

                                if ( listeArray.length === 3){
                                    listeLabel  = listeArray[0];
                                    listeValeur = listeArray[1];
                                    listeGroupement = listeArray[2];
                                }

                                if( !angular.isNullOrUndefined(listeNom) && !angular.isNullOrUndefined(listeLabel) )
                                    liste = listeValeur+ ' as '+ listeLabel;

                                if ( !angular.isNullOrUndefined(listeGroupement))
                                    liste = liste + ' group by ' + listeGroupement;

                                liste = liste + ' in ' + listeNom;
                            }

                            // Si rien défini dans COLTRI, on trie selon le label
                            var bTri = false;
                            if ( listeNom.length>0 && !angular.isNullOrUndefined(aScope.descriptions[listeNom]) ){
                                for ( var i=0;i<aScope.descriptions[listeNom].length;i++){
                                    var descriptionChamps = aScope.descriptions[listeNom][i];
                                    if ( descriptionChamps.ordreTri != 0){
                                        bTri = true;
                                        break;
                                    }
                                }
                            }

                            // Récupération du filtre via un object
                            if ( refEtrangerArray.length == 5 ){
                                var filtreObject = refEtrangerArray[4];
                                var query = SCOPE_CHAMPS + '.' + refEtrangerArray[2] + '.' + refEtrangerArray[3] + '.valeur';
                                var sytaxeObj = (filtreObject.indexOf('#') !== -1);

                                if (sytaxeObj) {
                                    //Construire objet de filtre et query
                                    sytaxeObj = true;
                                    var arrayFiltreObjet = filtreObject.split('#');
                                    var arrayQueryObjet = refEtrangerArray[3].split('#');

                                    filtreObject = "{";
                                    query = "{";
                                    for (var i = 0; i < arrayFiltreObjet.length; i++) {
                                        if (i > 0) {
                                            filtreObject += ',';
                                            query += ',';
                                        }

                                        filtreObject += i.toString() + ":'" + arrayFiltreObjet[i] + "'";
                                        query += i.toString() + ":" + SCOPE_CHAMPS + "." + refEtrangerArray[2] + "." + arrayQueryObjet[i] + ".valeur";
                                    }
                                    filtreObject += "}";
                                    query += "}";
                                }

                                if ( refEtrangerArray[2].indexOf("[") !== -1 ){
                                    var nomGrille = refEtrangerArray[2].replace("[]","");
                                    query = SCOPE_GRILLES + '.' + nomGrille + '[$index].' + refEtrangerArray[3] + '.valeur';
                                }

                                if ( !angular.isNullOrUndefined(filtreObject) && !angular.isNullOrUndefined(query)) {
                                    if ( bTri ){
                                        if (sytaxeObj) {
                                            liste += ' |filtreListe: ' + filtreObject + ': ' + query + ':\'' + listeLabel + '\'';
                                        } else {
                                            liste += ' |filtreListe: \'' + filtreObject + '\': ' + query + ':\'' + listeLabel + '\'';
                                        }
                                    }else{
                                        if (sytaxeObj) {
                                            liste += ' |filtreListe: ' + filtreObject + ': ' + query + ':\'' + listeLabel + '\'';
                                        } else {
                                            liste += ' |filtreListe: \'' + filtreObject + '\': ' + query + ':\'' + listeLabel + '\'';
                                        }
                                    }


                                }
                            } else if ((refEtrangerArray.length >= 2) && (!angular.isNullOrUndefined(listeLabel)) && (listeLabel !== "")) {
                                if ( !bTri)
                                    liste += ' |trierListe: \''+ listeLabel + '\'';
                            } // TODO : Tri par tri selon description (COLTRI) au lieu du filter angular ?

                        }
                    }
                }
                return liste;
            };

            /**
             * Recupération de la représentation
             * @param {Objet} aScope Scope
             * @param {String} nomTable Nom de la table
             * @param {Objet} descriptionChamps Description du champs
             */
            this.getTemplateRepresentation = function (aScope,nomTable,descriptionChamps,typeElement){
                if ( typeElement === TYPE_CHAMPS && descriptionChamps[SCOPE_REPRESENTATION].length>0 ){
                    var colnom = descriptionChamps[SCOPE_NOM];
                    return representationService.getTemplateRepresentation('mosaique',descriptionChamps[SCOPE_REPRESENTATION], aScope[SCOPE_CHAMPS][nomTable][colnom].valeur);
                }

                return '';
            };

            /**
             * Recupération du titre
             * @param {Objet} aScope Scope
             * @param {String} nomTable Nom de la table
             * @param {Objet} descriptionChamps Description du champs
             * @param {String} typeElement Type de l'élément (grille ou champs)
             */
            this.getTemplateRegroupementTitre = function (aScope,nomTable,descriptionChamps,typeElement){
                var scopeSource = (typeElement == TYPE_CHAMPS)?SCOPE_CHAMPS:SCOPE_GRILLES;
                var titre={
                    TITRE:'',
                    TITREGRILLE:'',
                    NUMEROREGROUPEMENT:''
                };

                var nomRegroupement,formuleRegroupement;
                var regroupement=descriptionChamps[SCOPE_NOM_GROUPE].split('|');

                if ( regroupement.length==3 )
                {
                    // Titre avec formule
                    formuleRegroupement=regroupement[2];
                    if ( typeElement == TYPE_CHAMPS )
                        formuleRegroupement = formuleRegroupement.replace(/#((.*?))#/g,'champs.'+nomTable+ '.'+ '$1' +'.valeur');
                    else
                        formuleRegroupement = formuleRegroupement.replace(/#((.*?))#/g,'entite.'+ '$1' +'.valeur');

                    titre.TITRE = formuleRegroupement;
                }
                else{
                    // Pas de formule
                    titre.TITRE = regroupement[1];
                }
                nomRegroupement = regroupement[1];
                titre.NUMEROREGROUPEMENT = regroupement[0];

                if (nomRegroupement && nomRegroupement.length>0){
                    titre.TITREGRILLE += nomRegroupement;
                }

                return titre;
            };


            /**
             * Visbilité d'un champ en fonction de la métadonnées visible
             * @param {Objet} descriptionChamps Description du champs
             * @returns {String}
             */
            this.getTemplateRegroupementVisibilite = function ( descriptionChamps ){
                var template = '';

                var visibiliteNomGroupeArray = descriptionChamps[SCOPE_NOM_GROUPE].split('|');
                if ( !angular.isNullOrUndefined(visibiliteNomGroupeArray) && visibiliteNomGroupeArray.length == 4 ){
                    var visibiliteDescriptionArray = visibiliteNomGroupeArray[3].split('#');
                    if ( !angular.isNullOrUndefined(visibiliteDescriptionArray) && visibiliteDescriptionArray.length == 3 )
                        template = 'ng-show="(champs.'+visibiliteDescriptionArray[0] + '.' + visibiliteDescriptionArray[1] + '==' + visibiliteDescriptionArray[2] + ')"';
                }
                else if ( !angular.isNullOrUndefined(visibiliteNomGroupeArray) && visibiliteNomGroupeArray.length == 3 && visibiliteNomGroupeArray[2] === "HIDE"){
                    template = 'ng-show="false"';
                }
                return template;
            };

            // Trie par rapport au numéro de regroupement
            this.sortTableClef = function(a,b){
                if ( angular.isNullOrUndefined(a.nom) ){
                    return -1;
                }
                if ( angular.isNullOrUndefined(b.nom) ) {
                    return 1;
                }

                if (a < b){
                    return -1;
                }
                else{
                    return 1;
                }
            };


            /**
             * Vérification si une table est une table dépendante
             * @param {Objet} aScope Scope
             * @param {String} nomTable Nom de la table
             * @param {Array} listeGrille Liste des grilles
             * @returns {Boolean}
             */
            this.isTableDependante = function (aScope,nomTable,listeGrille){
                var scopeDescription = SCOPE_DESCRIPTIONS;
                var clefPrimaire =[];
                var clefEtrangere =[];
                var btableDependante = false ;

                // Vérification
                if (angular.isNullOrUndefined(listeGrille))
                    return btableDependante;

                // Récupération des champs de la clé étrangére
                for (var index=0;index<aScope[scopeDescription][nomTable].length;index++){
                    if (aScope[scopeDescription][nomTable][index][SCOPE_CLE_ETRANGERE] ){
                        var nomColonne = aScope[scopeDescription][nomTable][index][SCOPE_NOM];
                        clefEtrangere.push(nomColonne);
                    }
                }
                clefEtrangere = clefEtrangere.sort(this.sortTableClef);

                // Cette clef est-elle clef étrangére
                for (var indexTable=0;indexTable<listeGrille.length;indexTable++){
                    var nomTableRecherche = listeGrille[indexTable];
                    clefPrimaire = [];
                    if ( nomTableRecherche != nomTable){
                        for (var index=0;index<aScope[scopeDescription][nomTableRecherche].length;index++){
                            if (aScope[scopeDescription][nomTableRecherche][index][SCOPE_CLE_PRIMAIRE]){
                                var nomColonne = aScope[scopeDescription][nomTableRecherche][index][SCOPE_NOM];
                                clefPrimaire.push(nomColonne);
                            }
                        }
                        clefPrimaire = clefPrimaire.sort(this.sortTableClef);

                        if (clefEtrangere.toString() == clefPrimaire.toString())
                            return true;
                    }
                }
                return btableDependante;
            };

            /**
             * Recupération de la liste des tables dépendantes
             * @param {Objet} aScope Scope
             * @param {String} nomTable Nom de la table
             * @param {Array} listeGrille Liste des grilles
             * @returns {Array} liste des tables dépendantes
             */
            this.getTableDependante = function (aScope,nomTable,listeGrille){
                var scopeDescription = SCOPE_DESCRIPTIONS;
                var clefPrimaire ='';
                var clefEtrangere ='';
                var listeTableDependante = [];
                var listeColonnePrimaire = [];
                var tableDependante ;
                var filtre = '';
				
				// Vérification
                if (angular.isNullOrUndefined(listeGrille))
                    return ;

                // Récupération des champs de la clé primaire
                for (var index=0;index<aScope[scopeDescription][nomTable].length;index++){
                    if (aScope[scopeDescription][nomTable][index][SCOPE_CLE_PRIMAIRE] ){
                        listeColonnePrimaire.push(aScope[scopeDescription][nomTable][index].nom);
                        clefPrimaire += aScope[scopeDescription][nomTable][index].nom;
                    }
                }

                // Cette clef est-elle clef étrangére
                for (var indexTable=0;indexTable<listeGrille.length;indexTable++){
                    var nomTableRecherche = listeGrille[indexTable];
                    clefEtrangere = '';
                    if ( nomTableRecherche != nomTable){
                        for (var index=0;index<aScope[scopeDescription][nomTableRecherche].length;index++){
                            if (aScope[scopeDescription][nomTableRecherche][index][SCOPE_CLE_ETRANGERE] ){
                                clefEtrangere += aScope[scopeDescription][nomTableRecherche][index].nom;
                            }
                        }

                        if ( clefEtrangere == clefPrimaire )
                            listeTableDependante.push(nomTableRecherche);
                    }
                }

                if (listeTableDependante.length >0 ){
                    filtre = '| filter:{';
                    for (var indexChampsPrimaire=0;indexChampsPrimaire<listeColonnePrimaire.length;indexChampsPrimaire++){
                        var nomColonne = listeColonnePrimaire[indexChampsPrimaire];
                        if ( indexChampsPrimaire>0 )
                            filtre+=',';
                        filtre += nomColonne+':entite.'+nomColonne +'.valeur';
                    }
                    filtre +='}';
                }

                tableDependante ={
                    "LISTETABLE":listeTableDependante,
                    "FILTRE":filtre
                };
                return tableDependante;
            };

            /**
             * Recupération du type de composant
             * @param {Objet} description Nom de la table
             * @param {Boolean} bLecture composant en lecture?
             * @param {String} estliste composant de type liste?
             * @returns {String} Type de composant
             */
            this.getType = function(description, bLecture, estliste) {

                // Vérification
                if ( angular.isNullOrUndefined( description) )
                    return '';


                // Lecture (static control)
                if ( bLecture)
                    return 'static';

                var estlectureseule = description.estLectureSeule;

                // Type complexe: Fichier (image)
                if ( description.typeComplexe.indexOf('IMAGE') ===0 )
                    return "fichier";
                else if ( description.typeComplexe.indexOf( 'PASSWORD') === 0)
                    return "password";
                else if (description.typeInteraction.indexOf('ANNEE') === 0 )
                    return "annee";
                else if ( description.typeComplexe.indexOf('TEL') === 0 )
                    return 'tel';
                else if (description.typeComplexe.indexOf('COORDONNEES') ===0 )
                    return 'coordonnees';
                else if (description.typeComplexe.indexOf('MULTIPLE') === 0 && !estlectureseule) {
                    if ( Array.isArray($scope.champs.PATRIMOINE[description[SCOPE_NOM]].valeur))
                        $scope.champs.PATRIMOINE.PAT_CATEGORIE.valeur= $scope.champs.PATRIMOINE.PAT_CATEGORIE.valeur.reverse(); //Bidouille
                    return 'listmultiple';
                }
                else if ( description.typeInteraction === 'DATALIST')
                    return 'datalist';
                else if ( description.typeInteraction === 'TYPEAHEAD')
                    return 'typeahead';
                else if ( description.typeInteraction.indexOf('REFERENTIEL') !== -1 )
                    return description.typeInteraction;
                else if (description.typeInteraction.indexOf('RANGE') === 0)
                    return 'range';

                if ( estliste && !estlectureseule)
                    return "list";

                // Type interaction radio, chexbox, list
                if ( description.typeInteraction === "RADIO" || description.typeInteraction=== "CHECKBOX" || description.typeInteraction === "LIST"  )
                    return description.typeInteraction.toLowerCase();

                // Texte complexe
                if ((!description.estCritereRecherche) && (description.longueurMax>250))
                    return 'textarea';

                // Type text, date, nombre, email
                if ( description.type === 'DATE' )
                    return 'date';
                else if (description.type === 'TIME')
                    return 'time';
                else if (description.type === 'DATETIME')
                    return 'datetime';
                else if ( description.type === 'NUMBER')
                    return 'number';
                else
                    return 'text';

            };

            /**
             * Recupération du template HTML d'un élément de formulaire
             * @param {String} nomTable Nom de la table
             * @param {String} aNomEcran Nom d'écran
             * @param {Objet} descriptionChamps Description du champs
             * @param {String} typeElement Type de l'élément (grille ou champs)
             * * @param {String} nomFormulaire Nom du formulaire
             * @returns {String} Template HTML
             */
            this.getTemplateElement = function (aScope, aNomEcran, nomTable,descriptionChamps,typeElement,nomFormulaire,modeLecture,isFormulaireRecherche){
                var templateElement='';
                var liste=this.getListe(aScope,descriptionChamps);
                var identite = descriptionChamps[SCOPE_NOM];
                var indexTable =(typeElement==TYPE_CHAMPS)?'':'[$index]';
                if ( !angular.isNullOrUndefined(descriptionChamps.tablenom) && descriptionChamps.tablenom != nomTable )
                    nomTable = descriptionChamps.tablenom;
                var nomChamps = nomTable + indexTable + '.' + identite ;
                var opFormulaire = '';
                var opAction = '';
                var opInfoBulle = '';

                // Formulaire de validation
                if (!angular.isNullOrUndefined(nomFormulaire))
                    opFormulaire = 'op-formulaire = "'+nomFormulaire+'"';

                // Action
                if (descriptionChamps[SCOPE_ACTION]!=''){
                    var actions = descriptionChamps[SCOPE_ACTION].split(separateurActionNiveau1);
                    var action = '';

                    if ((actions.length > 1) && (actions[0] === "InfoBulle")) {
                        // Paramétrage d'une icône "Info"
                        opInfoBulle = (!angular.isNullOrUndefined(descriptionChamps.action) && !isFormulaireRecherche) ? 'op-info-bulle="'+descriptionChamps.action+'"':'';
                    } else if ((actions.length === 2) && (actions[0] === "CHANGE_LISTE")) {
                        // Action CHANGE_LISTE (ng-change des éléments de type LIST)
                        if (typeElement === TYPE_GRILLE)
                            action += '{mapChamps:\'' + actions[1] + '\', opModel: \'' + nomChamps + '\', index: $index}';
                        else
                            action += '{mapChamps:\'' + actions[1] + '\', opModel: \'' + nomChamps + '\'}';

                        opAction = 'op-change = "gererChangeListe('+ action +')"';
                    } else {
                        action += '[';
                        for (var iAction = 0; iAction < actions.length; iAction++) {

                            if (typeElement === TYPE_GRILLE)
                                action += '{opAction:\'' + actions[iAction] + '\', opModel: \'' + nomChamps + '\', index: $index}';
                            else
                                action += '{opAction:\'' + actions[iAction] + '\', opModel: \'' + nomChamps + '\'}';
                            if (iAction < actions.length - 1)
                                action += ',';
                        }
                        action += ']';

                        opAction = 'op-change = "'+ this.nomGestionnaireAction +'('+ action +')"';
                    }
                }

                // Mode Lecture
                var opModeLecture = modeLecture?'op-lecture = "true"':'';

                // Type du champs
                var typeChamps = this.getType(descriptionChamps,modeLecture,liste.length>0);

                // formule
                var formuleChamps = (descriptionChamps.typeComplexe.indexOf("FORMULE") !==- 1)? descriptionChamps.typeComplexe:'';

                // Lien
                var opLien = (!angular.isNullOrUndefined(descriptionChamps.lien) && !isFormulaireRecherche )?'op-lien="'+descriptionChamps.lien+'"':'';

                // Visibilite conditionnelle
                var ngshow=''+descriptionChamps.estVisible+'';
                if ( !angular.isNullOrUndefined(descriptionChamps.visible)){
                    var visibleArray = descriptionChamps.visible.split('|');

                    if ( visibleArray.length >= 3){
                        var operateur = '===';
                        if (visibleArray.length === 4) {
                            if (visibleArray[3].trim() !== "") {
                                operateur = visibleArray[3].trim();
                            }
                        }

                        // Gérer une liste des colonnes cibles
                        var colonnesCibles = visibleArray[1].split(SEPARATEUR_INTERNE);
                        var tabValeursCibles = visibleArray[2].split(SEPARATEUR_INTERNE);
                        var valeursCibles = [];

                        for (var i = 0; i < tabValeursCibles.length; i++) {
                            valeursCibles = tabValeursCibles[i].split('#');

                            for (var idxValeur = 0; idxValeur < valeursCibles.length; idxValeur++) {
                                if (idxValeur == 0) {
                                    ngshow += ' && (';
                                } else {
                                    ngshow += ' || ';
                                }

                                if (typeElement == TYPE_GRILLE) {
                                    if (aScope.descriptions[visibleArray[0]][colonnesCibles[i]].type === 'NUMBER')
                                        ngshow += '(grilles.' + visibleArray[0] + '[$index].' + colonnesCibles[i] + '.valeur' + operateur + valeursCibles[idxValeur] + ')';
                                    else
                                        ngshow += '(grilles.' + visibleArray[0] + '[$index].' + colonnesCibles[i] + '.valeur' + operateur + '\'' + valeursCibles[idxValeur] + '\')';
                                } else {
                                    if (aScope.descriptions[visibleArray[0]][colonnesCibles[i]].type === 'NUMBER')
                                        ngshow += '(champs.' + visibleArray[0] + '.' + colonnesCibles[i] + '.valeur' + operateur + valeursCibles[idxValeur] + ')';
                                    else
                                        ngshow += '(champs.' + visibleArray[0] + '.' + colonnesCibles[i] + '.valeur' + operateur + '\'' + valeursCibles[idxValeur] + '\')';
                                }
                            }

                            ngshow += ')';
                        }
                    }
                }


                // Champs visible si formule ou visible
                if ( (descriptionChamps.estVisible && !isFormulaireRecherche) || (isFormulaireRecherche && descriptionChamps.estCritereRecherche) || (descriptionChamps.typeComplexe.length>0 && !isFormulaireRecherche) ){
                    if ( descriptionChamps.typeComplexe == 'ADRESSE' ){
                        if ( descriptionChamps.nom.indexOf("_ADRESSECOMPLETE") != -1 ){
                            templateElement =
                                '<br/><op-adresse ' +
                                'op-show="'+ ngshow +'" ' +
                                'op-module-nom="'+ aScope.nomModule +'" '+
                                'op-model="' + nomChamps + '" ' +
                                '>' +
                                '</op-adresse>' ;
                        }
                    }
                    else if ( descriptionChamps.typeComplexe == 'CONTACT' ){
                        if ( descriptionChamps.nom.indexOf("_CONTACTCOMPLET") != -1 ){
                            templateElement =
                                '<br/><op-contact ' +
                                'op-show="'+ ngshow +'" ' +
                                'op-module-nom="'+ aScope.nomModule +'" '+
                                'op-model="' + nomChamps + '" ' +
                                '>' +
                                '</op-contact>' ;
                        }
                    }
                    else if (descriptionChamps.typeComplexe === "LISTPERSO") {
                        if (descriptionChamps.nom.indexOf("_CLEF") != -1 ) {
                            var descriptionChampValeur = aScope.grilles[nomTable].descriptions[identite.replace("_CLEF", "_VALEUR")];
                            var listeValeur = this.getListe(aScope, descriptionChampValeur);

                            templateElement = '<op-liste-perso ' +
                                'op-show="' + ngshow + '" ' +
                                'op-module-nom="' + aScope.nomModule + '" ' +
                                'op-model="' + nomChamps + '" ' +
                                'op-liste-clef="' + liste + '" ' +
                                'op-liste-valeur="' + listeValeur + '" ' +
                                'op-form="form.form_' + aNomEcran + '"' +
                                '>' +
                                '</op-liste-perso>';
                        }
                    }
                    else{
                        templateElement =
                            '<op-textinput ' +
                            'op-type="'+ typeChamps +'" ' +
                            (descriptionChamps.typeComplexe?'op-type-complexe="'+ descriptionChamps.typeComplexe +'" ':'') +
                            'op-show="'+ ngshow +'" ' +
                            'op-module-nom="'+ aScope.nomModule +'" '+
                            'op-model="' + nomChamps + '" ' +
                            'op-liste="' + liste + '" ' + opFormulaire + ' ' + opAction + ' ' + opModeLecture +' ' +
                            'op-formule="'+ formuleChamps +'" ' +
                            'op-form="form.form_' + aNomEcran + '" '+
                            opLien + ' ' +
                            opInfoBulle + ' ' +
                            '>' +
                            '</op-textinput>' ;
                    }
                }

                // Ajout d'un séparateur
                if ( descriptionChamps.typeComplexe.indexOf('DIVIDER') !== -1 )
                    templateElement += '<hr/>';

                return templateElement;
            };

            /**
             * Recupération de l'élément image HTML
             * @param {Objet} aScope Scope
             * @param {String} nomTable Nom de la table
             * @param {Objet} descriptionChamps Description du champs
             * @param {String} typeElement Type de l'élément (grille ou champs)
             * @returns {String} Template de l'image
             */
            this.getTemplateElementImage = function (aScope, nomModule, nomTable, aEstVignette){
                var trigramme = moduleInfos.getInfo(nomModule, "MOD_TRIGRAMME");
                var tableEntite = moduleInfos.getInfo(nomModule, "MOD_TABLEENTITE");
                var identite =  "ID_" + trigramme + "_DOC_ID_FICHIER";
                var colNomEstPrincipal = trigramme + "_DOC_ESTPRINCIPAL";
                var colNomCommentaire = trigramme + "_DOC_DESCRIPTION";
                var colNomLibelle = trigramme + "_DOC_LIB";
                var templateElement;

                // Image de type vignette ou carroussel
                if ( aEstVignette ) {
                    if ( tableEntite !== nomTable) return '';

                    templateElement = ''+
                        '<p class="profile-picture">' +
                            '<img class="img-rounded img-responsive col-sm-12" data-ng-src="{{vignettePrincipale}}" alt="image"/>' +
                        '</p>';

                }else{
                    // Mantis 5921 Gestion documents éléménets liés
                    var pasDocLie = (nomTable.indexOf('DOCUMENT_LIE') != -1) ? 'false' : 'true';
                    var suffixeSlide = (nomTable.indexOf('DOCUMENT_LIE') != -1) ? 'DocLie' : '';
					templateElement =''+
                        '<a ng-href="" ng-click="ouvrirDocument(slide' + suffixeSlide + ')" class="thumbnail">'+
                            '<img data-ng-src="{{slide' + suffixeSlide + '.valeurVignette}}" style="width:40%">' +
                        '</a>'+
                        '<div class="operis-photo-control" style="width:60%;" >' +
                        '<div class="input-group" ng-if="' + pasDocLie + '">' +
                            '<input type=text" class="form-control" ng-model="grilles.'+nomTable+'[$index].'+ colNomLibelle +'.valeur" placeholder="Nom" style="text-align:center;"/>' +
                            '<span class="input-group-btn" ng-click="supprimerFichier(\''+nomTable+'\',$index);$event.stopPropagation();">'+
                                '<button class="btn btn-default" type="button"><i class="fa fa-times"></i></button>'+
                            '</span>'+
                        '</div>'+
                        '<input type=text" class="form-control" ng-if="!' + pasDocLie + '" ng-model="grilles.'+nomTable+'[$index].'+ colNomLibelle +'.valeur" placeholder="Nom" style="text-align:center;" ng-disabled="true"/>' +
                        '<textarea class="form-control" ng-model="grilles.'+nomTable+'[$index].'+ colNomCommentaire +'.valeur" placeholder="Description" style="text-align:center;" ng-disabled="!' + pasDocLie + '"></textarea>';

                    if (nomTable.indexOf('DOCUMENT_LIE') != -1) {
                        templateElement += '<div class="checkbox"><label>' +
                            '<input type="checkbox" ng-disabled="true" ng-model="grilles.' + nomTable + '[$index].' + colNomEstPrincipal + '.valeur" ng-true-value="1" ng-false-value="0">Vignette</label></div>' +
                            '</div>';
                    } else {
                        templateElement += '<div class="checkbox"><label>' +
                            '<input type="checkbox" ng-disabled="getDisableVignette(\'' + nomTable + '\',$index)" ng-change="onEstPrincipal(\'' + nomTable + '\',$index)" ' +
                            'ng-model="grilles.' + nomTable + '[$index].' + colNomEstPrincipal + '.valeur" ng-true-value="1" ng-false-value="0">Vignette</label></div>' +
                            '</div>';
                    }
                }

                return templateElement;
            };

            /**
             * Récupèration du code pour l'ouverture d'un regroupement
             * @param {String} nomRegroupement
             * @returns {String} CodeRegroupement
             */
            this.getCodeOpenRegroupement = function(nomRegroupement){
                var codeOpenRegroupement = nomRegroupement.replace(/\s/g,'');
                var pattern_accent = ["é", "è", "ê", "ë", "ç", "à", "â", "ä", "î", "ï", "ù", "ô", "ó", "ö"];
                var pattern_replace_accent = ["e", "e", "e", "e", "c", "a", "a", "a", "i", "i", "u", "o", "o", "o"];
                pattern_accent.forEach(function(caractere,index){
                    codeOpenRegroupement = codeOpenRegroupement.replace(new RegExp(caractere, 'g'),pattern_replace_accent[index]);
                });

                codeOpenRegroupement = "open_" + codeOpenRegroupement;
                return codeOpenRegroupement;
            };


            /**
             * Récupèration du template d'un regroupement
             * @param {Objet} aScope Scope
             * @param {Objet} groupe(nom de la table,Liste des champs,typeElement,elementPrincipal
             * @param {String} nom du formulaire
             * @returns {String} Template HTML du regroupement
             */
            this.getTemplateRegroupement = function (aScope, nomModule, nomEcran, groupe, listeGrille, nomFormulaire,isGroupOpen,isFormulaireRecherche){
                var scopeDescription = SCOPE_DESCRIPTIONS;
                var representation=''
                    ,templateElement=''
                    ,templateAssocie=''
                    ,templateImage=''
                    ,templateHeading=''
                    ,template='';
                var nomTable = groupe.nomTable;
                var listeChamps = groupe.listeChamps;
                var typeElement = groupe.type;
                var estElementPrincipal = groupe.estElementPrincipal;
                var estGroupeAccordion = angular.isNullOrUndefined(groupe.estGroupeAccordion)?true:groupe.estGroupeAccordion;


                var typeGrille = this.getTypeGrille(groupe.nom);

                var estGroupeOpen = gestionRegroupement.estRegroupementOuvert(aScope, nomModule, nomEcran, groupe.nom.split('|')[1], isGroupOpen);
                var nomOpenRegroupement = groupe.nom.split('|')[1];
                if ( !angular.isNullOrUndefined(nomOpenRegroupement)){
                    nomOpenRegroupement = this.getCodeOpenRegroupement(nomOpenRegroupement);

                    if ( angular.isNullOrUndefined(aScope.statutRegroupement))
                        aScope.statutRegroupement = {};
                    aScope.statutRegroupement[nomOpenRegroupement] = estGroupeOpen;
                }

                // Vérification des champs obligatoires
                if (angular.isNullOrUndefined(listeChamps))
                    return;

                // Grille de type document ou non implique un carrousel
                var isTableDocument = (nomTable.indexOf('DOCUMENT')!=-1);
                if ( isTableDocument ){
                    // Ajout des fonctions pour la gestion des images
                    aScope.estFormulaireFichier = true;
                    if ( angular.isNullOrUndefined (aScope.tableDocument) )
                        aScope.tableDocument = [];
                    aScope.tableDocument.push(nomTable);
                    aScope.getFichiers = function (){
                        var fichiers = [];

                        for ( var j=0;j<aScope.tableDocument.length;j++){
                            var nomTable = aScope.tableDocument[j];

                            if ( !angular.isNullOrUndefined( aScope.grilles[nomTable] ) ){
                                for (var i=0;i<aScope.grilles[nomTable].length;i++){
                                    fichiers.push(aScope.grilles[nomTable][i])
                                }
                            }
                        }

                        return fichiers;
                    };
                }

                // Récupération des templates des élements du regroupement pour le champs
                representation = '<div class="pull-right">';
                for (var i=0;i<listeChamps.length;i++) {
                    representation += this.getTemplateRepresentation(aScope,nomTable,listeChamps[i],typeElement);
                    templateElement+= this.getTemplateElement(aScope,nomEcran, nomTable,listeChamps[i],typeElement,nomFormulaire, false,isFormulaireRecherche);
                }
                representation += '</div>';

                // Récupération du titre du regroupement
                var titres = this.getTemplateRegroupementTitre(aScope,nomTable,listeChamps[0],typeElement);

                // Récupération de la visibilité du regroupement
                var visibiliteRegroupement = this.getTemplateRegroupementVisibilite(listeChamps[0]);

                // Création du regroupement champs ou grille
                if ( (groupe.estElementPrincipal && typeElement === TYPE_CHAMPS ) || ( typeElement === TYPE_GRILLE &&  nomTable.indexOf("DOCUMENT") != -1 ) )
                    templateImage = this.getTemplateElementImage(aScope,nomModule,nomTable,typeElement === TYPE_CHAMPS);
                if ( typeElement == TYPE_CHAMPS ){
                    if (estElementPrincipal) {
                        templateHeading += representation;
                    } else {

                    }

                    if ( estGroupeAccordion == true && groupe.nom != ''){
                        template += '<accordion-group style="background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURPRIMAIRE") + ';" is-open="' + (estElementPrincipal || estGroupeOpen) + '" ' + visibiliteRegroupement + '>'+
                            '<accordion-heading><div style="padding: 10px 15px;background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURSECONDAIRE") + ';"><b>' + titres.TITRE + ' ' + templateHeading + '</b></div>' +
                            '</accordion-heading>' + //barreOutils +
                            '<div class="col-xs-12 col-sm-2 center hidden-xs">' + templateImage + '</div>'+
                            '<div class="col-xs-12 col-sm-10">' + templateElement + '</div>'+
                            '</accordion-group>';
                    }
                    else
                        template += templateElement;

                }
                else{
                    if ( groupe.nom == '')
                        return '';

                    // Options de la grille
                    if ( angular.isNullOrUndefined(aScope.grilles[nomTable].optionGrilles)){
                        // Data de la grille
                        aScope.grilles[nomTable].optionGrilles ={
                            data: aScope.grilles[nomTable],
                            nomTable:nomTable
                        };
                    }

                    // Récupération des templates des tables associées
                    var tableDependante = groupe.listeGroupesAssocies;
                    if ( !angular.isNullOrUndefined(tableDependante.LISTETABLE)){
                        for (var index=0;index<tableDependante["LISTETABLE"].length;index++){
                            var groupeAssocie = new Object({
                                nom: '',
                                nomTable: groupe.listeGroupesAssocies.LISTETABLE[index],
                                type: TYPE_GRILLE,
                                listeChamps: [],
                                listeGroupesAssocies: [],
                                estElementPrincipal: false
                            });
                            for (var indexTableAssocie = 0; indexTableAssocie < aScope[scopeDescription][groupeAssocie.nomTable].length; indexTableAssocie++) {
                                groupeAssocie.listeChamps.push(aScope[scopeDescription][groupeAssocie.nomTable][indexTableAssocie]);
                            }
                            groupeAssocie.nom = aScope[scopeDescription][[groupeAssocie.nomTable]][0].nomGroupe;
                            groupeAssocie.listeGroupesAssocies = this.getTableDependante(aScope,groupeAssocie.nomTable,listeGrille);
                            templateAssocie += this.getTemplateRegroupement(aScope,nomModule, nomEcran, groupeAssocie, listeGrille,nomFormulaire);
                        }
                    }

                    template += '<accordion-group style="background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURPRIMAIRE") + ';" is-open="statutRegroupement.'+nomOpenRegroupement+'" ' + ' ' + visibiliteRegroupement + '>' +
                        '<accordion-heading><div style="padding: 10px 15px;background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURSECONDAIRE") + ';"><b>' + titres.TITREGRILLE +'</b>';

                    // Nombre d'élément
                    template+='<span ng-if="(grilles.'+nomTable+'.length>1)"> ({{grilles.'+nomTable+'.length}}) </span>';

                    if (typeGrille !=="GRILLE" )
                        template += '<div class="ibox-tools"><a ng-click="ajouterElement(\'' + nomTable + '\',\'' + nomOpenRegroupement + '\');$event.stopPropagation();"><i class="fa fa-plus"></i></a></div>';

                    template +=  '</div></accordion-heading>';

                    // Pour la gestion simplifiée des grilles
                    if ( !isTableDocument){
                        if ( aScope.grilles[nomTable].length === 0)
                            aScope.grilles[nomTable].push(aScope.grilles[nomTable].descriptions.creerObjet());

                        // Bug car presence de # pour la construction dans le cas non mobile
                        var titreDialogue = ""; //'\'Supprimer \'';
                        var buf = titres.TITRE;
                        var d = titres.TITRE.search("{{");
                        var f = titres.TITRE.search("}}") + 2;
                        // Construire titre pour dialogue modal supprimer
                        if (d > -1) {
                            if (f == buf.length) {
                                titreDialogue += buf; //'+' + buf;
                            } else {
                                titreDialogue += buf.substring(d, f); //'+' + buf.substring(d, f);
                                buf = buf.substr(f);

                                while (buf.length > 0) {
                                    d = buf.search("{{");
                                    f = buf.search("}}") + 2;

                                    if (d > -1) {
                                        if (d > 0) {
                                            titreDialogue += '+\'' + buf.substring(0, d) + '\'+';
                                            titreDialogue += buf.substring(d, f);
                                        } else {
                                            titreDialogue += buf.substring(d, f);
                                        }

                                        buf = buf.substr(f);
                                    } else {
                                        titreDialogue += '+\'' + buf + '\'';
                                        buf = "";
                                    }
                                }
                            }

                            titreDialogue = titreDialogue.replace(/{{/g, "");
                            titreDialogue = titreDialogue.replace(/}}/g, "");
                        } else {
                            titreDialogue += '\'' + titres.TITRE + '\''; //'+\'' + titres.TITRE + '\'';
                        }

                        // Ne plus utiliser l'affichage op-grille-saisie
                        /*
                        // Affichage op-grille-saisie si pas mobile
                        var disabledExpandable = (!angular.isNullOrUndefined(typeGrille) && typeGrille ==="GRILLE") ?
                            " op-disabled-expandable-row ":"";

                        if ( serveur.isMobile()){
                            template +=
                                '<div class="col-xs-12" ng-if="grilles.' + nomTable + '.length>1">' +
                                '<accordion-group style="background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURPRIMAIRE") + ';" ng-repeat="entite in grilles.' + nomTable + ' ' +'">' +
                                '<accordion-heading>' +
                                '<div style="padding: 10px 15px;background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURSECONDAIRE") + ';">' +
                                '<b>' + titres.TITRE + '</b> <div class="ibox-tools"><a ng-click="supprimerElement(\'' + nomTable + '\',' + titreDialogue + ',entite,$index);$event.stopPropagation();"><i class="fa fa-times"></i></a></div>' +
                                '</div>' +
                                '</accordion-heading>' +
                                '<div class="col-xs-12 center">' + templateImage + '</div>'+
                                '<div class="col-xs-12">' + templateElement + '</div>'+
                                ''+ templateAssocie + '' +
                                '</accordion-group>' +
                                '</div>';
                        }else{
                            template +=
                                '<div class="col-sm-12" ng-if="grilles.' + nomTable + '.length>1">' +
                                '<op-grille-saisie op-grille-options="grilles.'+nomTable+'.optionGrilles" ' +
                                'op-section-droite-visible="droiteVisible" '+ disabledExpandable + ' ' +
                                'on-demande-activite="linkButtonOuvrirActivite_click(idaction,modele,index,type)"' +
                                'op-formulaire="form.form_'+ nomEcran + '"  >' +
                                '</op-grille-saisie>' +
                                '</div>';
                        }*/
                        template += '<div class="col-xs-12" ng-if="grilles.' + nomTable + '.length>1">' +
                            '<accordion-group style="background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURPRIMAIRE") + ';" ng-repeat="entite in grilles.' + nomTable + ' ' +'">' +
                                '<accordion-heading>' +
                                    '<div style="padding: 10px 15px;background-color: ' + moduleInfos.getInfo(nomModule, "MOD_COULEURSECONDAIRE") + ';">' +
                                        '<b>' + titres.TITRE + '</b> <div class="ibox-tools"><a ng-click="supprimerElement(\'' + nomTable + '\',' + titreDialogue + ',entite,$index);$event.stopPropagation();"><i class="fa fa-times"></i></a></div>' +
                                    '</div>' +
                                '</accordion-heading>';

                        if (serveur.isMobile()) {
                            template += '<div class="col-xs-12 center">' + templateImage + '</div>' +
                                '<div class="col-xs-12">' + templateElement + '</div>' +
                                ''+ templateAssocie + '';
                        } else {
                            template += '<div class="col-xs-12 col-sm-12">' + templateElement + '</div>' +
                                '<div class="col-xs-12 col-sm-12">' + templateAssocie + '</div>';
                        }

                        template += '</accordion-group></div>';

                        // Affichage champs si une seule enregistrement dans grilles
                        template += '' +
                            '<div ng-if="grilles.' + nomTable + '.length<2">' +
                                '<div class="col-xs-12 col-sm-12" ng-repeat="entite in grilles.' + nomTable + '">' +
                                '<div class="col-xs-12 col-sm-12">' + templateElement + '</div>' +
                            '</div>' +
                                '<div class="col-xs-12 col-sm-12">' + templateAssocie + '</div>' +
                            '</div>';
                    }
                    else{
                            // Grille de type carrousel pour les grilles d'images
                            // Mantis 5921 Gestion documents éléménets liés
                            var suffixeSlide = (nomTable.indexOf('DOCUMENT_LIE') != -1) ? 'DocLie' : '';							
                            template += '<div class="col-xs-12 col-sm-offset-3 col-sm-6 center" >' +
                                '<carousel id="carousel_'+ nomFormulaire + nomTable +'" interval="-1">'+
                                    '<slide ng-repeat="slide' + suffixeSlide + ' in grilles.' + nomTable + '" active="slide' + suffixeSlide + '.active">'+ templateImage +
                                    '</slide>'+
                                '</carousel>' +
                                '</div>';

                            template +='<div class="col-xs-12" ng-hide="true" >' +
                                '<input id="id_documentformulaire'+ nomFormulaire + nomTable +'" ' +
                                    'type="file" style="display:none;" ' +
                                    'onchange="event.stopPropagation();angular.element(this).scope().ajouterFichier(event,\'' + nomTable + '\',\'carousel_'+ nomFormulaire + nomTable + '\');" ' +
                                '/>' +
                                '</div>';
                    }



                    template += '</accordion-group>';
                }

                return template;
            };

            /**
             * Récupération du template d'un regroupement de lecture
             * @param {Objet} aScope Scope
             * @param {Objet} groupe(nom de la table,Liste des champs,typeElement,elementPrincipal
             * @param {String} nom du formulaire
             * @returns {String} Template HTML du regroupement
             */
            this.getTemplateRegroupementLecture = function (aScope, nomModule, nomEcran, groupe, listeGrille, nomFormulaire){
                var representation=''
                    ,templateElement=''
                    ,templateImage=''
                    ,template='';
                var nomTable = groupe.nomTable;
                var listeChamps = groupe.listeChamps;
                var typeElement = groupe.type;
                var estElementPrincipal = groupe.estElementPrincipal;


                // Vérification des champs obligatoires
                if (angular.isNullOrUndefined(listeChamps))
                    return;

                // Visibilité
                var visibilite = this.getTemplateRegroupementVisibilite(listeChamps[0]);

                // Récupération des templates des élements du regroupement
                representation = '<div class="pull-right">';
                for (var i=0;i<listeChamps.length;i++) {
                    representation += this.getTemplateRepresentation(aScope,nomTable,listeChamps[i],typeElement);
                    templateElement+= this.getTemplateElement(aScope,nomEcran,nomTable,listeChamps[i],typeElement,nomFormulaire,true);
                }
                representation += '</div>';

                if ( (groupe.estElementPrincipal && typeElement === TYPE_CHAMPS ) || typeElement === TYPE_GRILLE )
                    templateImage = this.getTemplateElementImage(aScope,nomModule,nomTable,typeElement === TYPE_CHAMPS);

                // Récupération du titre du regroupement
                var titres = this.getTemplateRegroupementTitre(aScope,nomTable,listeChamps[0],typeElement);

                // Création du regroupement champs ou grille
                template +='<div class="operis-list-element" >';
                if ( typeElement === TYPE_CHAMPS ){
                    // Champs
                    if (estElementPrincipal){
                        // Element principal entete
                        template +=
                            '<div ' + visibilite + ' class="row operis-list-element-header">'+
                                '<div class="pull-left col-sm-2">' + templateImage + '</div>'+
                                '<div class="media-body">' +
                                    ''+representation+''+
                                    '<h4>' + titres.TITRE + '</h4>' +
                                '</div>'+
                            '</div>'+
                            '<div class="row">'+
                                ''+templateElement+''+
                            '</div>';
                    }
                    else{
                        // Element non principal
                        template +=
                            '<div ' + visibilite + ' class="row operis-list-element-header">'+
                                '<div class="col-xs-12 col-sm-12"><h4>' + titres.TITRE + '</h4></div>'+
                            '</div>'+
                            '<div class="row">'+
                                ''+templateElement+''+
                            '</div>';
                    }
                }
               else{
                    if (nomTable.indexOf('LOCALISATION')!== -1 ){
                        // Localisation principale
                        var locPrincipale = gestionGrilleElementPrincipal.getElementPrincipal(aScope,nomTable);
                        // Coordonnées
                        var colCoordonnees;
                        var descriptions = aScope.grilles[nomTable].descriptions;
                        for (var j = 0; j < descriptions.length; j++) {
                            if (descriptions[j].typeComplexe && descriptions[j].typeComplexe == 'COORDONNEES') {
                                colCoordonnees = descriptions[j].nom;
                            }
                        }

                        aScope.scrollCartoSynthese = function() {
                            $timeout(function(){ //timeout pour que le scroll se fasse après l'insertion de la div carto
                                $location.hash("syntheseLocalisationHeader");
                                $anchorScroll();
                            });
                        };

                        // Grille de localisation
                        var hrefPrincipale = (!angular.isNullOrUndefined(locPrincipale) ) ?
                            config.$config.urlItineraire + locPrincipale[colCoordonnees].valeur.split(';').slice(0,2).reverse().toString():"";
                        template +=
                            '<div id="syntheseLocalisationHeader" class="row operis-list-element-header">'+
                                '<div class="hidden-xs col-sm-12">' +
                                    '<h4>' +
                                        titres.TITREGRILLE +
                                        '<button type="button" ' +
                                            'class="fa btn btn-default btn-sm pull-right" ' +
                                            'ng-class="{\'fa-chevron-down\': affichageCartoSynthese, \'fa-chevron-right\': !affichageCartoSynthese}"' +
                                            'style="font-size: 12px;line-height: 1.5;margin-bottom: 10px" ' +
                                            'ng-click="affichageCartoSynthese=!affichageCartoSynthese' +
                                                (serveur.isMobile() ? '; (affichageCartoSynthese ?  scrollCartoSynthese() : null)"' : '"') + '>' +
                                        '</button>' +
                                        '<a class="btn btn-default btn-sm pull-right" type="button" ng-href="' + hrefPrincipale + '" target="_blank"> <i class="fa fa-map-signs" aria-hidden="true"></i></a>' +
                                    '</h4>' +
                                '</div>'+
                                '<div class="visible-xs">' +
                                    '<h4>' +
                                        titres.TITREGRILLE +
                                        '<button type="button" ' +
                                            'class="fa btn btn-default btn-sm pull-right" ' +
                                            'ng-class="{\'fa-chevron-down\': affichageCartoSynthese, \'fa-chevron-right\': !affichageCartoSynthese}"' +
                                            'style="font-size: 12px;line-height: 1.5;margin-bottom: 10px" ' +
                                            'ng-click="affichageCartoSynthese=!affichageCartoSynthese' +
                                            (serveur.isMobile() ? '; (affichageCartoSynthese ?  scrollCartoSynthese() : null)"' : '"') + '>' +
                                        '</button>' +
                                        '<a class="btn btn-default btn-sm pull-right" type="button" ng-href="' + hrefPrincipale + '" target="_blank"> <i class="fa fa-map-signs" aria-hidden="true"></i></a>' +
                                    '</h4>' +
                                '</div>'+
                            '</div>'+
                            '<div class="row operis-list-element-grille" ng-if="affichageCartoSynthese">'+
                                '<op-cartographie '+
                                    'op-module="{{nomModule}}"'+
                                    'op-style="-regroupement-lecture' + (serveur.isMobile() ? '-mobile"' : '"')+
                                    'op-description="'+SCOPE_DESCRIPTIONS+ '.' + nomTable +'" '+
                                    'op-donnees="'+SCOPE_GRILLES + '.' + nomTable +'" > '+
                                '</op-cartographie>'+
                            '</div>'+
                            '<div class="row">'+
                            '</div>'+
                            '<div class="hidden-xs row operis-list-element-grille">'+
                                '<op-grille '+
                                    'op-description="'+SCOPE_DESCRIPTIONS+ '.' + nomTable +'" '+
                                    'op-donnees="'+SCOPE_GRILLES + '.' + nomTable +'" '+
                                    'op-demande-detail-entite="demandeDetailEntiteGrilleSynthese" ' +
                                    'op-is-grilleformulaire="true"> '+
                                '</op-grille>'+
                            '</div>'+
                            '<div class="visible-xs row">'+
                                '<op-grille '+
                                    'op-description="'+SCOPE_DESCRIPTIONS+ '.' + nomTable +'" '+
                                    'op-donnees="'+SCOPE_GRILLES + '.' + nomTable +'" '+
                                    'op-demande-detail-entite="demandeDetailEntiteGrilleSynthese" ' +
                                    'op-is-grilleformulaire="true"> '+
                                '</op-grille>'+
                            '</div>';
                    }
                    else{
                        // Grille Simple
                        var classHeader = (serveur.isMobile())?"media-body":"col-sm-12";
                        template +=
                            '<div class="row operis-list-element-header">'+
                                '<div class="' +classHeader+'"><h4>' + titres.TITREGRILLE + '</h4></div>'+
                            '</div>';

                        var classGrille = (serveur.isMobile())?"":"operis-list-element-grille";
                        template +='<div class="col-sm-12 '+classGrille+'">'+
                            '<op-grille '+
                                'op-module="{{nomModule}}"'+
                                'op-description="'+SCOPE_DESCRIPTIONS+ '.' + nomTable +'" '+
                                'op-donnees="'+SCOPE_GRILLES + '.' + nomTable +'" '+
                                'op-demande-detail-entite="demandeDetailEntiteGrilleSynthese" ' +
                                'op-is-grilleformulaire="true" > '+
                            '</op-grille>'+
                        '</div>';
                    }
                }

                template +='</div>';
                return template;
            };

            /**
             * Fonction de création de regroupement
             * @param {Objet} aScope Scope
             * @param {Array} listeGroupe Liste des regroupements
             * @param {Array} listeAction Liste des actions
             * @param {Array} listeTable Liste de tables
             * @param {Array} listeGrille Liste des grilles
             * @param {String} typeElement Groupe de type champs ou grille
             */
            this.addListeRegroupement = function (aScope, listeGroupe,listeAction,listTable,listeGrille,typeElement, estGroupeAccordion) {
                var groupe = new Object({
                    nom:'',
                    nomTable:'',
                    type:typeElement,
                    listeGroupesAssocies:[],
                    listeChamps:[],
                    estElementPrincipal:false,
                    estGroupeAccordion:angular.isNullOrUndefined(estGroupeAccordion)?true:estGroupeAccordion
                });

                var dictionnaryGroupe = {};

                if (!angular.isNullOrUndefined(listTable)){
                    groupe.nom=null;
                    groupe.listeGroupesAssocies = [];
                    groupe.listeChamps = [];
                    groupe.estElementPrincipal = false;

                    // Parcours des champs d'une table
                    for ( var j=0; j<listTable.length;j++){

                        for (var i = 0; i < aScope[SCOPE_DESCRIPTIONS][listTable[j]].length; i++) {
                            var groupeNom =  aScope[SCOPE_DESCRIPTIONS][listTable[j]][i].nomGroupe;
                            aScope[SCOPE_DESCRIPTIONS][listTable[j]][i] ["tablenom"] = listTable[j];

                            if ( groupe.nom !== null && groupe.nom !== groupeNom ){

                                groupe.listeGroupesAssocies = this.getTableDependante(aScope,groupe.nomTable,listeGrille);
                                // Vérification que la table n'est pas une table dépendante car elle est déjà comprise dans les tables associées
                                if (!this.isTableDependante(aScope,groupe.nomTable,listeGrille) ){
                                    if ( angular.isNullOrUndefined(dictionnaryGroupe[groupe.nom]))
                                        dictionnaryGroupe[groupe.nom] = {nom:groupe.nom,nomTable:groupe.nomTable,type:typeElement,listeGroupesAssocies:groupe.listeGroupesAssocies,estElementPrincipal:groupe.estElementPrincipal,listeChamps:groupe.listeChamps};
                                    else
                                        dictionnaryGroupe[groupe.nom].listeChamps.push(aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i]);
                                }
                                groupe.listeChamps=[];
                                groupe.listeGroupesAssocies=[];
                                groupe.estElementPrincipal = false;
                            }
                            groupe.nom = groupeNom;
                            groupe.nomTable = listTable[j];
                            groupe.listeChamps.push(aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i]);

                            if (aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i].estClePrimaire && !aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i].estCleEtrangere){
                                groupe.estElementPrincipal = true;
                            }

                            if ( aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i][SCOPE_ACTION] != '' ){
                                var nomColonne = aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i].nom;

                                // Ajout de l'action
                                var actionsChamps = aScope[SCOPE_DESCRIPTIONS][groupe.nomTable][i][SCOPE_ACTION].split(separateurActionNiveau1);
                                for (var indexAction=0;indexAction<actionsChamps.length;indexAction++){
                                    if ( actionsChamps[indexAction].indexOf("CHANGEMENTETAT") ===-1)
                                        listeAction.push({
                                            opAction:actionsChamps[indexAction],
                                            opModel:groupe.nomTable + '.' +  [nomColonne]
                                        });
                                }


                            }
                        }
                    }

                    if ( !angular.isNullOrUndefined(listeGrille) && listeGrille.length>0 ){
                        groupe.listeGroupesAssocies = this.getTableDependante(aScope,groupe.nomTable,listeGrille);
                    }

                    if ( !this.isTableDependante(aScope,groupe.nomTable,listeGrille) ){
                        if ( angular.isNullOrUndefined(dictionnaryGroupe[groupe.nom]))
                            dictionnaryGroupe[groupe.nom] = {nom:groupe.nom,nomTable:groupe.nomTable,type:typeElement,listeGroupesAssocies:groupe.listeGroupesAssocies,estElementPrincipal:groupe.estElementPrincipal,listeChamps:groupe.listeChamps,estGroupeAccordion:groupe.estGroupeAccordion};
                        else{
                            dictionnaryGroupe[groupe.nom].listeChamps = dictionnaryGroupe[groupe.nom].listeChamps.concat(groupe.listeChamps);
                        }


                    }

                    // Remplissage de liste des groupe
                    for ( groupe in dictionnaryGroupe ){
                        listeGroupe.push(dictionnaryGroupe[groupe]);
                    }
                }
            }


            /**
             * Création du template
             * @param {Objet} aScope Scope
             * @param {Array} retourChargement Noms des tables chargées
             * @param {Array} actions Listes des actions en cours
             * @param {Array} nomGestionnaireAction Nom du gestionnaire d'action
             * @param {Boolean} modeEdition template en lecture ou ecriture (0: ecriture, 1: lecture)
             */
            this.creerTemplate = function (aScope,nomModule,entite,nomEcran,nomFormulaire,nomGestionnaireAction,modeEdition,isAccordion,isGroupOpen,isFormulaireRecherche){
                var listeGroupe = [];
                var listeAction = [];
                var ficheTitre;

                this.nomGestionnaireAction = nomGestionnaireAction;

                // Parcours des tables champs et grilles pour avoir les groupes et les actions
                this.addListeRegroupement(aScope,listeGroupe,listeAction,entite[0],entite[1],TYPE_CHAMPS,isAccordion);
                this.addListeRegroupement(aScope,listeGroupe,listeAction,entite[1],entite[1],TYPE_GRILLE,isAccordion);

                // Trie par rapport au numéro de regroupement
                listeGroupe = listeGroupe.sort(function(a,b){
                    if ( angular.isNullOrUndefined(a.nom) ){
                        return -1;
                    }
                    if ( angular.isNullOrUndefined(b.nom) ) {
                        return 1;
                    }

                    var aArray = a.nom.split('|');
                    var bArray = b.nom.split('|');

                    if (parseInt(aArray[0])< parseInt(bArray[0])){
                        return -1;
                    }
                    else{
                        return 1;
                    }
                });

                // Création du template formulaire
                var template='';
                if ( modeEdition ){
                    template ='<form name="form.form_' + nomEcran + '" id="form' + nomFormulaire + "_" + nomEcran + '" class="form-horizontal operis-row css-form">';
                    if ( listeGroupe.length > 0 ){
                        for (var indexElement=0;indexElement<listeGroupe.length;indexElement++){
                            template+= this.getTemplateRegroupementLecture(aScope,nomModule,nomEcran,listeGroupe[indexElement],entite[1],nomFormulaire);
                        }
                    }
                    template +='</form>';
                }
                else{
                    template ='<form name="form.form_' + nomEcran + '" id="form' + nomFormulaire + "_" + nomEcran + '" class="form-horizontal css-form">'
                        +'<accordion close-others="false">';
                    if ( listeGroupe.length > 0 ){
                        for (var indexElement=0;indexElement<listeGroupe.length;indexElement++){
                            template+= this.getTemplateRegroupement(aScope,nomModule,nomEcran,listeGroupe[indexElement],entite[1],nomFormulaire,isGroupOpen,isFormulaireRecherche);

                        }
                    }
                    template +='</accordion>';
                    template +='</form>';

                    // Mantis 6030 : Ajout de l'espace blanche en fin de formulaire en mode mobile
                    if (serveur.isMobile()) {
                        // Mantis 5921 : Exception écrans documents et avancement tâche
                        if ((nomEcran.indexOf("M_DOCUMENTS") === -1) && (nomEcran !== "TAC_M_MODIFIERAVANCEMENT") && (nomEcran !== "INT_CLOTURER")) {
                            template += '<div><br><br><br><br><br><br><br><br><br><br><br><br><br><br><br></div>';
                        }
                    }
                }

                return {
                    template:template,
                    actions:listeAction,
                    ficheTitre:ficheTitre
                };
            }
        }
    ]
);