/**
 * Created by Laurent Dorie on 12/01/2017
 */
'use strict';

angular.module('fr.operis.moteurV4.utils.ReferenceInterne',[])

    .service('fr.operis.moteurV4.utils.ReferenceInterne',['fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Conversion','$q',
    function(serveur, conversion, $q){
        /**
         * Fonction qui recheche le prochain numéro d'une référence
         * @param {String} aNomModule Le nom du module
         * @param {Object} aScope Scope dans lequel les données sont à charger.
         * @param {String} aNomEcran Nom d'écran des statuts du module
		 * @param {String} aNomModuleCible Nom du module ciblé par la procédure interne
         * @returns {ng.IPromise<T>}
         */
        this.rechercheNumeroInterne = function (aNomModule, aScope, aNomEcran, aNomModuleCible) {
            var deferred = $q.defer();

            // Mantis Operia 6585 Gestion séquence pour les références internes
            var an = (new Date()).getFullYear();
            serveur.procedureInterne(aNomModule, aScope, "Ope_RefInterneSuivant", 0, an, aNomModuleCible.toUpperCase(), 0)
                .then(function (resultat) {
                    if (!angular.isNullOrUndefined(resultat) && (resultat.length > 0)) {
                        var refSuivant = parseInt(resultat[0]);
                        if (isNaN(refSuivant)) {
                            deferred.reject("Erreur lors de la recherche de numéro interne");
                        } else {
                            deferred.resolve(refSuivant);
                        }
                    }
                    else {
                        deferred.reject("Erreur lors de la recherche de numéro interne");
                    }
                }, function(erreur){
                    deferred.reject(erreur);
                });
            /*// Chargement de l'écran de recherche du numéro interne
                serveur.chargement(aNomModule, aScope, aNomEcran, null)
                    .then(function (retourChargement) {
                        if (!angular.isNullOrUndefined(aScope.champs[retourChargement[0]].ANNEE))
                            aScope.champs[retourChargement[0]].ANNEE.valeur = (new Date()).getFullYear().toString();
                        aScope.recherche[aNomEcran](1)
                            .then(function (retourRecherche) {
                                if (!angular.isNullOrUndefined(aScope.grilles[retourRecherche[0]]) && aScope.grilles[retourRecherche[0]].length >= 0) {
                                    if (aScope.grilles[retourRecherche[0]].length == 0)
                                        deferred.resolve(1);
                                    else
                                        deferred.resolve(aScope.grilles[retourRecherche[0]][0].NUMERO.valeur);
                                }
                                else
                                    deferred.reject("Erreur dans la recherche de numéro");
                            })
                    })
                    .catch(function (erreur) {
                        deferred.reject(erreur);
                    });
            }*/
            
			return deferred.promise;
        }

    }
    ]);


