/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Matthieu Louchet, Alcer - Operis
 */
'use strict';

/*
 * Gestion du message d'avertissement sur les cookies.
 */

angular.module('fr.operis.moteurV4.controleurs.WarnCookie', ['fr.operis.moteurV4.utils.Cookie',
        'fr.operis.moteurV4.utils.Config'])
    .controller("fr.operis.moteurV4.controleurs.WarnCookieControleur", ["$scope", "$window", "fr.operis.moteurV4.utils.Cookie", 
        "fr.operis.moteurV4.utils.Config", 
        function ($scope, $window, cookie, config) {
            $scope.cookieWarnAccepted = null;
            $scope.isCookieWarnAccepted = function () {
                $scope.cookieWarnAccepted = cookie.get("cookieWarnAccepted");
                
                if($scope.cookieWarnAccepted == undefined || $scope.cookieWarnAccepted == null) {
                    $scope.cookieWarnAccepted = false;              
                }
                    
                return $scope.cookieWarnAccepted;
            } 

            $scope.getApplication = function() {
                return config.$config.libApplication[$window.APPLICATION] ? config.$config.libApplication[$window.APPLICATION] : $window.APPLICATION;
            }
            
            $scope.acceptedWarn = function() {
                $scope.cookieWarnAccepted = true;
                cookie.set("cookieWarnAccepted", $scope.cookieWarnAccepted);
            };
        }
    ]);