/**
 * @name Representation
 *
 * @version 1.0b
 * @copyright 2015 Operis
 * @author Brian Kjerulf, Operis
 *
 * @description
 *   Service Representation : gestion de la représentation par mode d'affichage et type de représentation
 *
 */

"use strict";

angular.module('fr.operis.moteurV4.utils.Representation', [])
    .constant('fr.operis.moteurV4.utils.Representation.REP_DEFAULT', 'REP_DEFAULT')
    .provider('fr.operis.moteurV4.utils.Representation',
        function() {
            var _affichages ={
                                "mosaique":{},
                                "planning":{}
                              } ;

            // Configuration du service de représentation
            this.ajouterRepresentation = function( listeRepresentation ){
                if (!angular.isNullOrUndefined( listeRepresentation ) ) {
                    for (var i=0;i<listeRepresentation.length;i++){
                        var representation = listeRepresentation[i];
                        var nomRepresentation = listeRepresentation[i].name;
                        var valeurVisible = listeRepresentation[i].valeurVisible;

                        if ( !angular.isNullOrUndefined ( representation["mosaique"])
                            && !angular.isNullOrUndefined ( nomRepresentation)
                            && angular.isNullOrUndefined ( _affichages["mosaique"][nomRepresentation] ) ){
                            _affichages["mosaique"][nomRepresentation] = representation["mosaique"];
                            _affichages["mosaique"][nomRepresentation].valeurVisible = valeurVisible;
                        }

                        if  ( !angular.isNullOrUndefined ( representation["planning"])
                            && !angular.isNullOrUndefined ( nomRepresentation)
                            && angular.isNullOrUndefined ( _affichages["planning"][nomRepresentation] ) ){
                            _affichages["planning"][nomRepresentation] = representation["planning"];
                            _affichages["planning"][nomRepresentation].valeurVisible = valeurVisible;
                        }
                    }
                }
            };

            /**
             * Retourne le service représentation
             * @param {injector} $injector
             * @returns {fr.operis.moteurV4.utils.communicationServlet}
             */
            this.$get = ['$injector', function ($injector) {
                return $injector.instantiate([
                    '$q', '$http', 'fr.operis.moteurV4.utils.Representation.REP_DEFAULT',
                    representation
                ]);
            }
            ];

            function representation($q, $http, REP_DEFAULT) {
                    /**
                     * Récuperer le style de représentation en fonction du mode d'affichage, type d'entité à représenter et valeur
                     * @param {String} typeRepresentation Type de représentation ('PRIORITE', 'STATUT', etc.)
                     * @param {String} valeur Valeur à représenter ('Urgent', 'Enregistrée', 'Planifiée', etc.)
                     * @returns {Object}
                     */
                    this.getRepresentationStyle = function (typeRepresentation, valeur){
                        var representation ='';
                        if (!angular.isNullOrUndefined( typeRepresentation )
                            && !angular.isNullOrUndefined( valeur)
                            && !angular.isNullOrUndefined(_affichages["mosaique"][typeRepresentation] ) ){
                            if ( typeof(_affichages["mosaique"][typeRepresentation]) === "function" ){
                                representation = _affichages["mosaique"][typeRepresentation](valeur);
                                representation = representation.style;
                            }
                            else{
                                if ( !angular.isNullOrUndefined(_affichages["mosaique"][typeRepresentation][valeur])
                                && !angular.isNullOrUndefined(_affichages["mosaique"][typeRepresentation][valeur]().style))
                                    representation =  _affichages["mosaique"][typeRepresentation][valeur]().style;
                            }
                        }

                        return representation ;
                    };

                    /**
                     * Récuperer le tooltip de représentation
                     * @param {String} typeRepresentation Type de représentation ('PRIORITE', 'STATUT', etc.)
                     * @param {String} valeur Valeur à représenter ('Urgent', 'Enregistrée', 'Planifiée', etc.)
                     * @returns {Object}
                     */
                    this.getRepresentationToolTip = function (typeRepresentation, valeur){
                        var representation ='';
                        if (!angular.isNullOrUndefined( typeRepresentation )
                            && !angular.isNullOrUndefined( valeur)
                            && !angular.isNullOrUndefined(_affichages["mosaique"][typeRepresentation] ) ){
                            if ( typeof(_affichages["mosaique"][typeRepresentation]) === "function" ){
                                representation = _affichages["mosaique"][typeRepresentation](valeur);
                                representation = representation.tooltip;
                            }
                            else{
                                if ( !angular.isNullOrUndefined(_affichages["mosaique"][typeRepresentation][valeur])
                                    && !angular.isNullOrUndefined(_affichages["mosaique"][typeRepresentation][valeur]().tooltip))
                                    representation =  _affichages["mosaique"][typeRepresentation][valeur]().tooltip;
                            }
                        }

                        return representation ;
                    };

                    /**
                     * Valeur Visble ?
                     * @param {String} modeAffichage Mode d'affichage (mosaique, planning, etc.)
                     * @param {String} typeRepresentation Type de représentation ('PRIORITE', 'STATUT', etc.)
                     * @param {String} valeur Valeur à représenter ('Urgent', 'Enregistrée', 'Planifiée', etc.)
                     * @returns {Boolean}
                     */
                    this.isValeurVisble = function(modeAffichage, typeRepresentation){
                        if (!angular.isNullOrUndefined( typeRepresentation )
                                && !angular.isNullOrUndefined( modeAffichage )
                                && !angular.isNullOrUndefined(_affichages[modeAffichage][typeRepresentation].valeurVisible ))
                            return _affichages[modeAffichage][typeRepresentation].valeurVisible;
                        else
                            return false;
                    };

                    /**
                     * Récuperer le template de la représentation en fonction du mode d'affichage, type d'entité à représenter et valeur
                     * @param {String} modeAffichage Mode d'affichage (mosaique, planning, etc.)
                     * @param {String} typeRepresentation Type de représentation ('PRIORITE', 'STATUT', etc.)
                     * @param {String} valeur Valeur à représenter ('Urgent', 'Enregistrée', 'Planifiée', etc.)
                     * @returns {String}
                     */
                    this.getTemplateRepresentation = function (modeAffichage, typeRepresentation, valeur){
                        var icone = this.getRepresentation(modeAffichage, typeRepresentation, valeur).icon;
                        var representionStyle = this.getRepresentationStyle(typeRepresentation, valeur).color;
                        if ( this.isValeurVisble(modeAffichage,typeRepresentation))
                            if (angular.isNullOrUndefined(valeur) || (valeur === "")) {
                                return '<span class="fa ' + icone + '" style="color:' + representionStyle + '" ></span>';
                            } else {
                                return valeur + ' <span class="fa ' + icone + '" style="color:' + representionStyle + '" ></span>';
                            }
                        else
                            return '<i class="fa '+ icone +'" style="color:'+representionStyle+'" ></i>';
                    };

                    /**
                     * Récuperer la représentation en fonction du mode d'affichage, type d'entité à représenter et valeur
                     * @param {String} modeAffichage Mode d'affichage (mosaique, planning, etc.)
                     * @param {String} typeRepresentation Type de représentation ('PRIORITE', 'STATUT', etc.)
                     * @param {String} valeur Valeur à représenter ('Urgent', 'Enregistrée', 'Planifiée', etc.)
                     * @returns {Object}
                     */
                    this.getRepresentation = function(modeAffichage, typeRepresentation, valeur) {

                        var representation = {};

                        if (modeAffichage in _affichages) {
                            if (typeRepresentation in _affichages[modeAffichage]) {
                                if ( typeof(_affichages[modeAffichage][typeRepresentation]) === "function" ){
                                    representation = _affichages[modeAffichage][typeRepresentation](valeur);
                                }else{
                                    if (valeur in _affichages[modeAffichage][typeRepresentation]) {
                                        representation = _affichages[modeAffichage][typeRepresentation][valeur]();
                                    } else {
                                        representation = _affichages[modeAffichage][typeRepresentation][REP_DEFAULT]();
                                    }
                                }
                            }
                        }

                        return representation;
                    };

                    /**
                     * Concatenation de plusieurs représentations (les N objets passés en paramètre seront fusionnés)
                     * Si 2 objets portent le même argument alors c'est la valeur de la dernier objet passés en paramètre qui est pris
                     * @returns {Object}
                     */
                    this.concatRepresentation = function () {
                        var representation = {};
                        var len = arguments.length;
                        var i, arg;

                        for (i=0; i<len; i++) {
                            for (arg in arguments[i]) {
                                if (arguments[i].hasOwnProperty(arg)) {
                                    representation[arg] = arguments[i][arg];
                                }
                            }
                        }

                        return representation;
                    };

                    this.getJoursFeries = function () {
                        var deferred = $q.defer();
                        //var url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port +
                        //    '/' + window.location.pathname.split('/')[1] + '/commun/joursferies.json';
                        var url = window.location.pathname.replace("/Application","/") + 'commun/joursferies.json';

                        $http.get(url)
                            .success(function (data) {
                                deferred.resolve(data);
                            })
                            .error(function (data) {
                                deferred.reject(data);
                            });

                        return deferred.promise;
                    };
                }
            }



    );
