/**
 * @version 1.0
 * @copyright 2017 Operis
 * @Author Laure-Hélène Bruneton, Alcer, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.popup.OpPopupDocumentsAttaches', [])

    .controller('fr.operis.moteurV4.composants.popup.OpPopupDocumentsAttachesControleur',
        ['$scope'
            , '$modalInstance'
            , 'fr.operis.moteurV4.communication.OperisWSServer'
            , 'fr.operis.moteurV4.utils.ModuleInfos'
            , 'fr.operis.moteurV4.utils.RequetePreSauvegardeFiche'
            , 'fr.operis.moteurV4.utils.RequetePostSauvegardeFiche'
            , 'moduleNom'
            , 'idAction'
            , 'ecranNom'
            , 'identifiant'
            , function ($scope
            , $modalInstance
            , serveur
            , moduleInfos
            , requetePreSauvegardeFiche
            , requetePostSauvegardeFiche
            , moduleNom
            , idAction
            , ecranNom
            , identifiant) {
            var vm = this;

            $scope.enCours = false;
            $scope.estAffichageMobile = serveur.isMobile();

            $scope.couleurPrimaire = moduleInfos.getInfo(moduleNom, "MOD_COULEURPRIMAIRE");
            $scope.couleurSecondaire = moduleInfos.getInfo(moduleNom, "MOD_COULEURSECONDAIRE");

            $scope.vueFormulaire = {
                nomModule: moduleNom,
                nomEcran: ecranNom,
                identifiant: identifiant
            };

            // Fonction de fin de chargement de formulaire de saisie
            $scope.finChargementFormulaire = function (aScope) {
                vm.scopeFormulaire = aScope;
            };

            // Mantis 5921 Gestion documents éléménets liés
            $scope.afficherDocumentsLies = ((moduleNom === "Demande") || (moduleNom === "Intervention") ||
                (moduleNom === "Tache") || (moduleNom === "MobileDemande") || (moduleNom === "MobileIntervention") ||
                (moduleNom === "MobileTache"));

            $scope.vueFormulaireDocLie = {
                nomModule: moduleNom,
                nomEcran: ecranNom + "_LIES",
                identifiant: identifiant
            };

            $scope.finChargementFormulaireeDocLie = function (aScope) {
                vm.scopeFormulaireDocLie = aScope;
            };

            // Fonction de sauvegarde de formulaire de saisie
            $scope.sauvegarder = function () {
                $scope.enCours = true;
                // Action de controle de préSauvegarde (contrôle, calcul)
                requetePreSauvegardeFiche.execution($scope, moduleNom,null,
                    vm.scopeFormulaire, idAction)
                    .then(function (retourPreSauvegarde) {
                        $scope.vueFormulaire.sauvegarde().then(
                            function (retourSauvegarde) {
                                // Action postSauvegarde (mise à jour des statuts, de l'historique)
                                requetePostSauvegardeFiche.execution($scope, moduleNom, null,
                                    retourSauvegarde, idAction);
                                $scope.fermer();
                            }, function(erreur){
                                $scope.enCours = false;
                            }
                        );
                    });
            };

            // Fermeture du popup
            $scope.fermer = function () {
                $modalInstance.dismiss();
            };
        }]);