/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.GestionRegroupement', [])

    .constant('fr.operis.moteurV4.utils.GestionRegroupement.LISTERCHAMPSNONVIDESDANSREGROUPEMENT',
        function listerChampsNonVidesDansRegroupement(aScope, aType, aTable) {
                var listeChampsNonVide = [];

                if (aType === "champs") {
                    var champs = aScope.champs[aTable];
                    for (var key in champs) {
                        if (!angular.isNullOrUndefined(champs[key].valeur) && champs[key].descriptions.estVisible && champs[key].valeur != "")
                            listeChampsNonVide.push(key);
                    }
                } else {
                    if (!angular.isNullOrUndefined(aScope.grilles) && !angular.isNullOrUndefined(aScope.grilles[aTable]) && aScope.grilles[aTable].length > 0) {
                        var champs = aScope.grilles[aTable][0];
                        for (var key in champs) {
                            if ( !angular.isNullOrUndefined(champs[key])
                                &&  !angular.isNullOrUndefined(champs[key].valeur)
                                && champs[key].descriptions.estVisible
                                && champs[key].valeur != "")
                                listeChampsNonVide.push(key);
                        }
                    }
                }

                return listeChampsNonVide;
        })

    .provider('fr.operis.moteurV4.utils.GestionRegroupement',
        [
        function () {

            // Liste des regroupements gérés
            var _arrayRegroupementConfigure = {};

            this.configurerRegroupement = function( regroupements ){
                _arrayRegroupementConfigure = regroupements;
            };

            /**
             * Retourne le service représentation
             * @param {injector} $injector
             * @returns {gestionRegroupement}
             */
            this.$get = ['$injector', function ($injector) {
                return $injector.instantiate([
                    'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS',
                    gestionRegroupement
                ]);
            }
            ];

            function gestionRegroupement(SCOPE_CHAMPS){
                /**
                * Fonction d'ouverture de regroupement?
                */
                this.estRegroupementOuvert = function(aScope, nomModule, nomEcran, nomRegroupement, estOuvert ){
                    if ( angular.isNullOrUndefined(nomModule) || angular.isNullOrUndefined(nomEcran) || angular.isNullOrUndefined(nomRegroupement) )
                        return false;
                    if ( !angular.isNullOrUndefined(estOuvert) && estOuvert )
                        return estOuvert;

                    if ( !angular.isNullOrUndefined(_arrayRegroupementConfigure[nomModule])
                        && !angular.isNullOrUndefined(_arrayRegroupementConfigure[nomModule][nomEcran])){
                        return  _arrayRegroupementConfigure[nomModule][nomEcran](aScope,nomRegroupement);
                    }
                }
            }
        }
    ]
);