/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Matthieu Louchet, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteContact', ['fr.operis.moteurV4.communication.OperisWSServer'])
    .service('fr.operis.moteurV4.utils.RequeteContact',
        ['fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.utils.RequeteModuleComplement',
            'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE',
            function (serveur, RequeteModuleComplement, SEPARATEUR_INTERNE) {
                this.getModuleContact = function (aScope, aNomModule, criteres) {

                    var colClefPrimaire = null;
                    return RequeteModuleComplement.getModuleComplement(aScope, aNomModule)
                        .then(function (cplt) {
                            if(cplt.length > 0 && cplt[0].length > 0
                                && !angular.isNullOrUndefined(aScope.champs) && !angular.isNullOrUndefined(aScope.champs[cplt[0][0]])) {

                                colClefPrimaire = aScope.champs[cplt[0][0]].MOD_ENTITE.valeur;
                                return serveur.chargement(aNomModule, aScope, "Operia" + aNomModule + "_Contact", null);
                            }

                            return null;
                        })
                        .then(function (meta) {
                            var aCriteres = {};
                            if(!angular.isNullOrUndefined(criteres) && criteres.length > 0) {
                                criteres = criteres.replace(/;/g, SEPARATEUR_INTERNE);
                                aCriteres[colClefPrimaire] = criteres;
                            }

                            if(meta.length > 0 && meta[0].length > 0) {
                                return serveur.recherche(aNomModule, aScope, "Operia" + aNomModule + "_Contact", meta[0], null, null, aCriteres)
                                    .then(function (data) {
                                        var ret = null;
                                        if(!angular.isNullOrUndefined(data) && data.length > 0 && data[0].length > 0) {
                                            ret = {};
                                            ret["description"] = aScope.descriptions[data[0][0]];
                                            ret["grille"] = aScope.grilles[data[0][0]];
                                        }

                                        return ret;
                                    });
                            }
                        });
                }
            }
        ]
    );