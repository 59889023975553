/**
 * @version 1.0b
 * @copyright 2017 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequetePostSauvegardeActionMetier', [])


    .provider('fr.operis.moteurV4.utils.RequetePostSauvegardeActionMetier', function () {
        var _actionMetier;

        /**
         * Configuration du postSauvegarde des actions métiers
         * @param {Object} aParametre
         * @returns
         */
        this.configuration = function(aConfiguration){
            _actionMetier = aConfiguration;
        };

        /**
         * Retourne le service Parametre.
         * @param {injector} $injector
         * @returns {fr.operis.moteurV4.modele.Parametre}
         */
        this.$get = ['$injector', function ($injector) {
            return $injector.instantiate([
                '$filter',
                '$q',
                'fr.operis.moteurV4.utils.RequeteMultiAppel',
                'fr.operis.moteurV4.utils.Statut',
                'fr.operis.moteurV4.utils.Historique',
                RequetePostSauvegardeActionMetier
            ]);
        }
        ];

        function RequetePostSauvegardeActionMetier($filter, $q, requeteMultiAppel, statut, historique) {


            /**
             * Fonction execute l'action de post sauvegarde d'une action métier
             * @Object {aScope}
             * @String {aModule}
             * @String {aIdAction}
             * @Object {aEntite}
             * @String
             */
            this.execution = function(aScope, aModuleNom, aIdAction, aEntite, aDateStatut, aStatutNom, aStatutCommentaire, aHistoriqueNom, aHistoriqueCommentaire){
                var deferred = $q.defer(), historiqueNom = aStatutNom, historiqueCommentaire = aStatutNom + ' depuis le ' + $filter('date')(new Date(), 'dd-MM-yyyy');

                requeteMultiAppel.setMultiAppel = true;

                if ( !angular.isNullOrUndefined(aStatutNom) )
                    statut.sauvegardeStatut(aModuleNom, aScope, aEntite, aStatutNom, aDateStatut, aStatutCommentaire);
                if ( !angular.isNullOrUndefined(aHistoriqueNom) )
                    historiqueNom = aHistoriqueNom;
                if ( !angular.isNullOrUndefined(aHistoriqueCommentaire) )
                    historiqueCommentaire = aHistoriqueCommentaire;

                historique.sauvegardeHistorique(aModuleNom, aScope, aEntite, aIdAction, historiqueNom, new Date(), historiqueCommentaire)
                    .then(function(){deferred.resolve()},function(){deferred.reject(aEntite)});

                requeteMultiAppel.setMultiAppel = false;


                return deferred.promise;
            }
        };
    });