/**
 * @version 1.0b
 * @copyright 2017 Operis
 * @Author Laurent Dorie - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.ecriture.OpContact', [])
    .directive('opContact', [
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES'
        ,'fr.operis.moteurV4.modele.Modele.SCOPE_LONGUEUR_MAX'
        ,'fr.operis.moteurV4.utils.RequeteReferentiel'
        ,'$document',
        function (serveur,
                  SCOPE_CHAMPS,
                  SCOPE_GRILLES,
                  SCOPE_LONGUEUR_MAX,
                  requetereferentiel,
                  $document
                  ) {
            return {
                restrict: 'E',
                transclude: true,
                replace: true,
                template: function (element, attributs) {

                    var bMobile = serveur.isMobile();
                    var styleMobile = (serveur.isMobile()) ? ' style="padding-left: 0px"' : '';
                    var scopeSource = (attributs.opModel.indexOf('[') !== -1) ? SCOPE_GRILLES : SCOPE_CHAMPS;
                    var id = attributs.opModel;
                    var idAffichageContact = attributs.opModel.replace('[$index]','');
                    var show = (!angular.isNullOrUndefined(attributs.opShow))?attributs.opShow:true;
                    var opModel = scopeSource + '.' + attributs.opModel;
                    var nomModule = attributs.opModuleNom;

                    // Définition des autres champs contact
                    var opModelQualite = opModel.replace("_CONTACTCOMPLET","_TITRE");
                    var opModelNom = opModel.replace("_CONTACTCOMPLET","_NOM");
                    var opModelPrenom = opModel.replace("_CONTACTCOMPLET","_PRENOM");
                    var opModelTelephone = opModel.replace("_CONTACTCOMPLET","_TEL");
                    var opModelGSM= opModel.replace("_CONTACTCOMPLET","_GSM");
                    var OpModelMail = opModel.replace("_CONTACTCOMPLET","_MAIL");
                    var OpModelAdresse = attributs.opModel.replace("_CONTACTCOMPLET","_ADRESSECOMPLETE");
                    var OpModelReferentiel = opModel.replace("_CONTACTCOMPLET","_ID_CONTACT");

                    // Scroll sur clique
                    var scrollClick = (serveur.isMobile() && navigator.userAgent.match(/Android/i)) ? "contact-scroll-on-click " : "";

                    // Contact complet
                    var retour = '<div>'+
                        '<div class="form-group row-bottom-margin has-feedback" ng-show="' + show + '">' +
                             '<label for="\'' + id + '\'" class="col-xs-5 col-sm-3 control-label"> Contact </label>'+
                             '<div class="col-sm-9 col-xs-12"' + styleMobile + '>'+
                                '<div class="col-sm-12 col-xs-12 input-group">' +
                                    '<input ' +
                                    'type="text" ' +
                                    'class="form-control col-sm-10 col-xs-12 op-typeahead" ' +
                                    'ng-change="changeContactComplete(\''+attributs.opModel+'\', $index)" ' +
                                    'id="' + id + '" ' +
                                    'ng-model="' + opModel + '.valeur" ' +
                                    'typeahead-min-length="3" '+
                                    'autocomplete="off" '+
                                    'maxlength="{{::' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                    'typeahead="reference.libelle for reference in rechercheReferentielContact(\''+ nomModule +'\',\'' + attributs.opModel + '\',$index,$viewValue)" ' +
                                    'typeahead-on-select="onTypeAheadReferentielContact($item, $index,\''+attributs.opModel+'\')" ' +
                                    'typeahead-template-url="moteur/composants/ecriture/OpTypeahead.html" ' +
                                    'typeahead-editable="true" '+
                                    scrollClick +
                                    '/>'+
                                    '<a href="" ng-click="$event.stopPropagation();click_relancerRechercheContact(\''+attributs.opModel+'\');"><i ng-show="('+OpModelReferentiel+'.valeur == null && '+opModel+'.valeur.length>0)" class="glyphicon glyphicon-warning-sign form-control-feedback"></i></a>'+
                                    '<span class="input-group-btn"  ng-show="' + !bMobile + '"> ' +
                                        '<button class="btn btn-default" ng-init="initAffichageContact(\'' + idAffichageContact + '\')" ng-click="basculeAffichageContact(\'' + idAffichageContact + '\');$event.stopPropagation();"><i ng-class="{\'fa fa-chevron-down\': contactAffiche(\'' + idAffichageContact + '\'), \'fa fa-chevron-right\': !contactAffiche(\'' + idAffichageContact + '\')}"></i></button>'+
                                    '</span>'+
                                '</div>'+
                             '</div>'+
                          '</div>';

                    // Qualité/Nom/Prénom
                    retour += '<div class="form-group row-bottom-margin" ng-show="contactAffiche(\'' + idAffichageContact + '\') && ' + (show && !bMobile) + '">' +
                        '<label class="col-sm-3 control-label"> Qualité </label>'+
                        '<div class="col-sm-2"' + styleMobile + '>'+
                            '<select ' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'ng-change="changerContact(\''+attributs.opModel+'\',$index)" ' +
                                'data-ng-trim="false" '+
                                'ng-model="' + opModelQualite + '.valeur" ' +
                                'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                'ng-options="entite.COT_TITRE_LIB.valeur as entite.COT_TITRE_LIB.valeur for entite in listes.LST_COT_TITRE"' +
                            '></select>'+
                        '</div>'+
                        '<label class="col-sm-1 control-label"> Nom </label>'+
                        '<div class="col-sm-2"' + styleMobile + '>'+
                            '<input ' +
                            'type="text" ' +
                            'class="form-control" ' +
                            'ng-change="changerContact(\''+attributs.opModel+'\',$index)" ' +
                            'data-ng-trim="false" '+
                            'ng-model="' + opModelNom + '.valeur" ' +
                            'maxlength="{{::' + opModelNom + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                            'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                            scrollClick +
                            '/>'+
                        '</div>'+
                        '<label class="col-sm-1 control-label"> Prénom </label>'+
                        '<div class="col-sm-3"' + styleMobile + '>'+
                            '<input ' +
                            'type="text" ' +
                            'class="form-control" ' +
                            'ng-change="changerContact(\''+attributs.opModel+'\',$index)" ' +
                            'data-ng-trim="false" '+
                            'ng-model="' + opModelPrenom + '.valeur" ' +
                            'maxlength="{{::' + opModelPrenom + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                            'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                            scrollClick +
                            '/>'+
                        '</div>'+
                    '</div>';

                    // Téléphone / GSM
                    retour += '<div class="form-group row-bottom-margin" ng-show="contactAffiche(\'' + idAffichageContact + '\') && ' + (show && !bMobile) + '">' +
                        '<label class="col-sm-3 control-label"> Téléphone </label>'+
                        '<div class="col-sm-2"' + styleMobile + '>'+
                            '<input ' +
                            'type="tel" ' +
                            'ui-mask="{{\'99-99-99-99-99\'}}" ' +
                            'class="form-control" ' +
                            'ng-model="' + opModelTelephone + '.valeur" ' +
                            'maxlength="{{::' + opModelTelephone + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                            'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                            scrollClick +
                            '/>'+
                        '</div>'+
                        '<label class="col-sm-1 control-label"> Mobile </label>'+
                        '<div class="col-sm-2"' + styleMobile + '>'+
                            '<input ' +
                            'type="tel" ' +
                            'class="form-control" ' +
                            'ui-mask="{{\'99-99-99-99-99\'}}" ' +
                            'ng-model="' + opModelGSM + '.valeur" ' +
                            'maxlength="{{::' + opModelGSM + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                            'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                            scrollClick +
                            '/>'+
                        '</div>'+
                        '<label class="col-sm-1 control-label"> Courriel </label>'+
                        '<div class="col-sm-3"' + styleMobile + '>'+
                            '<div class="input-group">' +
                                '<span class="input-group-addon">@</span>' +
                                '<input ' +
                                    'type="email" ' +
                                    'class="form-control" ' +
                                    'data-ng-trim="false" '+
                                    'ng-model="' + OpModelMail + '.valeur" ' +
                                    'maxlength="{{::' + OpModelMail + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                    scrollClick +
                                '/>'+
                            '</div>'+
                        '</div>'+
                        '</div>';


                    // Adresse
                    retour += '<op-adresse op-show="'+ show +'" op-module-nom="'+ nomModule +'" '+
                                    'op-model="' + OpModelAdresse + '" ' + '>' +
                                '</op-adresse><br/>';

                    retour += '</div>';
                    return retour;
                },
                link: function (scope, element) {
                    // Paramètres d'affichage du bloc contact
                    scope.affichageContact = {};

                    scope.contactAffiche = function (idAffichageContact) {
                        if (angular.isNullOrUndefined(scope.affichageContact[idAffichageContact])) {
                            return false;
                        } else {
                            return scope.affichageContact[idAffichageContact].open;
                        }
                    };

                    scope.initAffichageContact = function (idAffichageContact) {
                        if (angular.isNullOrUndefined(scope.affichageContact[idAffichageContact])) {
                            scope.affichageContact[idAffichageContact] = {open: false};
                        }
                    };

                    scope.basculeAffichageContact = function (idAffichageContact) {
                        scope.initAffichageContact(idAffichageContact);
                        scope.affichageContact[idAffichageContact].open = !scope.affichageContact[idAffichageContact].open;
                    };

                    // Fonction de relance de contact
                    if ( angular.isNullOrUndefined(scope.click_relancerRechercheContact)){
                        scope.click_relancerRechercheContact = function(idModele){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');;
                            var nomColonne = descriptionModel[1];

                            var inputs = element.find('input');
                            angular.forEach(inputs, function(input) {
                                if ( input.id == idModele ){
                                    var ngModel = angular.element(input).controller('ngModel');
                                    var old = ngModel.$viewValue;
                                    if ( !angular.isNullOrUndefined (old) && old.length>0)
                                        ngModel.$setViewValue(old.substr(0,old.length-1));
                                }
                            });
                        }
                    }

                    // Fonction de suppression du contact complète
                    if (angular.isNullOrUndefined(scope.changeContactComplete)){
                            scope.changeContactComplete = function(idModele, index){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');
                            var nomColonne = descriptionModel[1];
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][index] : scope[SCOPE_CHAMPS][nomTable];

                            // Définition des autres champs de contact
                            var opModelQualite = nomColonne.replace("_CONTACTCOMPLET","_TITRE");
                            var opModelNom = nomColonne.replace("_CONTACTCOMPLET","_NOM");
                            var opModelPrenom = nomColonne.replace("_CONTACTCOMPLET","_PRENOM");
                            var opModelTelephone = nomColonne.replace("_CONTACTCOMPLET","_TEL");
                            var opModelGSM= nomColonne.replace("_CONTACTCOMPLET","_GSM");
                            var OpModelMail = nomColonne.replace("_CONTACTCOMPLET","_MAIL");
                            var OpModelAdresse = nomColonne.replace("_CONTACTCOMPLET","_ADRESSECOMPLETE");
                            var opModelNomVoie = nomColonne.replace("_CONTACTCOMPLET","_NOMVOIE");
                            var opModelComplement = nomColonne.replace("_CONTACTCOMPLET","_COMPLEMENT");
                            var opModelLocalite = nomColonne.replace("_CONTACTCOMPLET","_LOCALITE");
                            var opModelCodePostal = nomColonne.replace("_CONTACTCOMPLET","_CODEPOSTAL");
                            var opModelBoitePostal = nomColonne.replace("_CONTACTCOMPLET","_BOITEPOSTAL");
                            var OpModelCedex = nomColonne.replace("_CONTACTCOMPLET","_CEDEX");
                            var OpModelGPS = nomColonne.replace("_CONTACTCOMPLET","_COORDGPS");
							var OpModelQuartier = nomColonne.replace("_CONTACTCOMPLET","_QUARTIER");
                            var OpModelReferentiel = nomColonne.replace("_CONTACTCOMPLET","_ID_CONTACT");

                            scopeSource[opModelQualite].valeur = null;
                            scopeSource[opModelNom].valeur = null;
                            scopeSource[opModelPrenom].valeur = null;
                            scopeSource[opModelTelephone].valeur = null;
                            scopeSource[opModelGSM].valeur = null;
                            scopeSource[OpModelMail].valeur = null;
                            scopeSource[OpModelAdresse].valeur = null;
                            scopeSource[opModelNomVoie].valeur = null;
                            scopeSource[opModelComplement].valeur = null;
                            scopeSource[opModelLocalite].valeur = null;
                            scopeSource[opModelCodePostal].valeur = null;
                            scopeSource[opModelBoitePostal].valeur = null;
                            scopeSource[OpModelCedex].valeur = null;
                            scopeSource[OpModelGPS].valeur = null;
							scopeSource[OpModelQuartier].valeur = null;
                            scopeSource[OpModelReferentiel].valeur = null;
                        }
                    }

                    // Fonction de recherche de référentiel externe d'adresse
                    if (angular.isNullOrUndefined(scope.rechercheReferentielContact)){
                        scope.rechercheReferentielContact = function( nomModule, idModele,index, valeurRecherche ){
                            return requetereferentiel.rechercher( scope, nomModule, 'REFERENTIEL_INTERNE_CONTACT', 'REFERENTIEL_INTERNE_PATRIMOINE|LIBELLE', idModele, index, valeurRecherche );
                        }
                    }

                    if (angular.isNullOrUndefined(scope.changerContact)) {
                        scope.changerContact = function(idModele, index){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');;
                            var nomColonne = descriptionModel[1];
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][index] : scope[SCOPE_CHAMPS][nomTable];

                            // Définition des autres champs de contact
                            var opModelQualite = nomColonne.replace("_CONTACTCOMPLET","_TITRE");
                            var opModelNom = nomColonne.replace("_CONTACTCOMPLET","_NOM");
                            var opModelPrenom = nomColonne.replace("_CONTACTCOMPLET","_PRENOM");
                            var OpModelReferentiel = nomColonne.replace("_CONTACTCOMPLET","_ID_CONTACT");

                            scopeSource[nomColonne].valeur = '';
                            if ( !(angular.isNullOrUndefined(scopeSource[nomColonne].valeur)) ){
                                if ( !(angular.isNullOrUndefined(scopeSource[opModelQualite].valeur)) ){
                                    scopeSource[nomColonne].valeur = scopeSource[opModelQualite].valeur;
                                }

                                if ( !(angular.isNullOrUndefined(scopeSource[opModelNom].valeur)) && scopeSource[opModelNom].valeur.length>0  ) {
                                    if ( !angular.isNullOrUndefined(scopeSource[nomColonne].valeur) && scopeSource[nomColonne].valeur.length > 0){
                                        scopeSource[nomColonne].valeur += " ";
                                        scopeSource[nomColonne].valeur += scopeSource[opModelNom].valeur;
                                    }
                                    else
                                        scopeSource[nomColonne].valeur = scopeSource[opModelNom].valeur;
                                }

                                if ( !(angular.isNullOrUndefined(scopeSource[opModelPrenom].valeur)) && scopeSource[opModelPrenom].valeur.length>0 ) {
                                    if ( !angular.isNullOrUndefined(scopeSource[nomColonne].valeur) && scopeSource[nomColonne].valeur.length > 0){
                                        scopeSource[nomColonne].valeur += " ";
                                        scopeSource[nomColonne].valeur += scopeSource[opModelPrenom].valeur;
                                    }
                                    else
                                        scopeSource[nomColonne].valeur = scopeSource[opModelPrenom].valeur;
                                }

                                scopeSource[OpModelReferentiel].valeur = null;
                            }
                        }
                    }

                    // Fonction de mise à jour des champs liés au retour du résultat de la recherche du référentiel
                    if (angular.isNullOrUndefined(scope.onTypeAheadReferentielContact)) {
                        scope.onTypeAheadReferentielContact = function ( $item, $index, idModele ) {
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');
                            var nomColonne = descriptionModel[1];
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][$index] : scope[SCOPE_CHAMPS][nomTable];

                            // Définition des autres champs de contact
                            var opModelQualite = nomColonne.replace("_CONTACTCOMPLET","_TITRE");
                            var opModelNom = nomColonne.replace("_CONTACTCOMPLET","_NOM");
                            var opModelPrenom = nomColonne.replace("_CONTACTCOMPLET","_PRENOM");
                            var opModelTelephone = nomColonne.replace("_CONTACTCOMPLET","_TEL");
                            var opModelGSM= nomColonne.replace("_CONTACTCOMPLET","_GSM");
                            var OpModelMail = nomColonne.replace("_CONTACTCOMPLET","_MAIL");
                            var OpModelAdresse = nomColonne.replace("_CONTACTCOMPLET","_ADRESSECOMPLETE");
                            var opModelNomVoie = nomColonne.replace("_CONTACTCOMPLET","_NOMVOIE");
                            var opModelComplement = nomColonne.replace("_CONTACTCOMPLET","_COMPLEMENT");
                            var opModelLocalite = nomColonne.replace("_CONTACTCOMPLET","_LOCALITE");
                            var opModelCodePostal = nomColonne.replace("_CONTACTCOMPLET","_CODEPOSTAL");
                            var opModelBoitePostal = nomColonne.replace("_CONTACTCOMPLET","_BOITEPOSTAL");
                            var OpModelCedex = nomColonne.replace("_CONTACTCOMPLET","_CEDEX");
                            var OpModelGPS = nomColonne.replace("_CONTACTCOMPLET","_COORDGPS");
							var OpModelQuartier = nomColonne.replace("_CONTACTCOMPLET","_QUARTIER");
                            var OpModelReferentiel = nomColonne.replace("_CONTACTCOMPLET","_ID_CONTACT");

                            scopeSource[nomColonne]["SELECTED"] = $item;
                            scopeSource[opModelQualite].valeur = '';
                            scopeSource[opModelNom].valeur = '';
                            scopeSource[opModelPrenom].valeur = '';
                            scopeSource[opModelTelephone].valeur = '';
                            scopeSource[opModelGSM].valeur = '';
                            scopeSource[OpModelMail].valeur = '';
                            scopeSource[OpModelAdresse].valeur = '';
                            scopeSource[opModelNomVoie].valeur = '';
                            scopeSource[opModelComplement].valeur = '';
                            scopeSource[opModelLocalite].valeur = '';
                            scopeSource[opModelCodePostal].valeur = '';
                            scopeSource[opModelBoitePostal].valeur = '';
                            scopeSource[OpModelCedex].valeur = '';
                            scopeSource[OpModelGPS].valeur = '';
							scopeSource[OpModelQuartier].valeur = '';
                            scopeSource[OpModelReferentiel].valeur = $item.valeur;

                            if (  !angular.isNullOrUndefined ( $item["cot_titre"] ) ){
                                scopeSource[opModelQualite].valeur = $item["cot_titre"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_nom"] )){
                                scopeSource[opModelNom].valeur = $item["cot_nom"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_prenom"] ) ){
                                scopeSource[opModelPrenom].valeur = $item["cot_prenom"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_tel"] ) ){
                                scopeSource[opModelTelephone].valeur = $item["cot_tel"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_gsm"] ) ){
                                scopeSource[opModelGSM].valeur = $item["cot_gsm"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_mail"] ) ){
                                scopeSource[OpModelMail].valeur = $item["cot_mail"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_adressecomplete"] ) ){
                                scopeSource[OpModelAdresse].valeur = $item["cot_adressecomplete"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_nomvoie"] ) ){
                                scopeSource[opModelNomVoie].valeur = $item["cot_nomvoie"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_lieudit"] ) ){
                                scopeSource[opModelComplement].valeur = $item["cot_lieudit"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_localite"] ) ){
                                scopeSource[opModelLocalite].valeur = $item["cot_localite"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_codepostal"] ) ){
                                scopeSource[opModelCodePostal].valeur = $item["cot_codepostal"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_boitepostal"] ) ){
                                scopeSource[opModelBoitePostal].valeur = $item["cot_boitepostal"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_cedex"] ) ){
                                scopeSource[OpModelCedex].valeur = $item["cot_cedex"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_coordgps"] ) ){
                                scopeSource[OpModelGPS].valeur = $item["cot_coordgps"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["cot_quartier"] ) ){
                                scopeSource[OpModelQuartier].valeur = $item["cot_quartier"];
                            }							
                        };
                    }
                }
            };
        }])

        .directive('contactScrollOnClick', ['$ocLazyLoad', '$document', '$window', function($ocLazyLoad, $document, $window) {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    $ocLazyLoad.load({
                        serie: true, files: ['lib/jquery.min.js']
                    }).then(function () {
                        $document.ready(function () {
                            element.on('click', function () {
                                if ($(element).offset().top > ($window.innerHeight * 0.20)) {
                                    var posActuel = $document[0].body.scrollTop;
                                    $("html, body").animate({scrollTop: posActuel + $(element).offset().top - 80}, "slow");
                                }
                            });
                        });
                    });
                }
            }
        }])
;