/**
 * @version 1.0
 * @copyright 2019 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.ConnexionPortail', [])
    .service('fr.operis.moteurV4.utils.ConnexionPortail',
        ['$window','fr.operis.moteurV4.utils.Cookie','fr.operis.moteurV4.communication.OperisWSServer',
        function ($window, cookie, operisWSServer) {
            /** Extraction de l'URL en cours sans les paramètres */
            this.extractUrlPath = function () {
                var url = $window.location.href;
                
                // Nettoyage des caractères en fin d'URL si nécessaire
                if (url.substring(url.length - 2) == '#/') {
                    url = url.substring(0, url.length - 2);
                }
                if (url.substring(url.length - 1) == '/') { // Redirection Jetty
                    url = url.substring(0, url.length - 1);
                }
                
                // Split avant les paramètres si nécessaire
                var index = url.indexOf("?");
                if (index > -1) {
                    var suburl = url.substring(0, index);
                    if (suburl.substring(suburl.length - 1) == '/') { // Redirection Jetty
                        suburl = suburl.substring(0, suburl.length - 1);
                    }
                    return suburl;
                } else {
                    return url;
                }
            };
            
            /** Extraction et encodage des paramètres d'origine et du paramètre state à vide */
            this.encodedStateParams = function () {
                return $window.encodeURIComponent(this.extractOriginalParams() + "&state=");
            };
            
            /** Extraction des paramètres d'origine (sans les paramètres portail)*/
            this.extractOriginalParams = function () {
                var extractedParams = _extractParams();
                extractedParams = _removeParam(extractedParams, "session_state");
                extractedParams = _removeParam(extractedParams, "code");
                extractedParams = _removeParam(extractedParams, "byPortail");
                
                // Traitement spécifique du state si sérialisé
                var indexSans, indexAvec, indexStart, indexEnd;
                indexSans = extractedParams.indexOf("state=");
                indexAvec = extractedParams.indexOf("&state=");
                indexStart = indexAvec > -1 ? indexAvec : indexSans;
                if (indexStart > - 1 ) {
                    indexEnd = extractedParams.indexOf("&", indexStart + 1);
                    indexEnd = (indexEnd > - 1 ? indexEnd : extractedParams.length);
                    var state = extractedParams.substring(indexStart + (indexAvec > -1 ? 7 : 6), indexEnd);
                    
                    var callbackParameters = decodeURIComponent(state);
                    var splitedParams = this.splitParams(callbackParameters);
                    
                    if(splitedParams.hasOwnProperty("state")) {
                        extractedParams += "&" + callbackParameters;
                        extractedParams = _removeParam(extractedParams, "state");
                    }
                }
                // Fin du traitement spécifique
                
                extractedParams = _removeParam(extractedParams, "state");
                return extractedParams;
            };
            
            /** Nettoyage d'un paramètre */
            function _removeParam(params, paramToRemove) {
                var indexSans, indexAvec, indexStart, indexEnd;
                indexSans = params.indexOf(paramToRemove + "=");
                indexAvec = params.indexOf("&" + paramToRemove + "=");
                indexStart = indexAvec > -1 ? indexAvec : indexSans;
                if (indexStart > - 1) {
                    indexEnd = params.indexOf("&", indexStart + 1);
                    indexEnd = (indexEnd > - 1 ? indexEnd : params.length);
                    params = params.substring(0, indexStart) + params.substring(indexEnd);
                    return params;
                } else {
                    return params;
                }
            }
            
            /** Extraction en tableau des paramètres de l'URL en cours */
            this.extractUrlParams = function () {
                return this.splitParams(_extractParams());
            };
            
            /** Extraction en string des paramètres de l'URL en cours */
            function _extractParams() {
                var search = $window.location.search;
                
                if (search.length > 1) {
                    search = search.substring(1);
                }
                
                if (search.substring(search.length - 2) == '#/') {
                    search = search.substring(0, search.length - 2);
                }
                
                return search;
            }
            
            /** Découpage en tableau des paramètres du string en entrée */
            this.splitParams = function (urlParams) {
                var ret = {};
                var params = urlParams.split("&");
                
                var param = null;
                for(var i = 0 ; i < params.length; ++i) {
                    param = params[i].split("=");
                    
                    ret[param[0]] = null;
                    
                    if(param.length > 1)
                        ret[param[0]] = param[1];
                }
                
                return ret;
            };
            
            
            
            /** Appel le rapprochement de compte */
            this.rapprochement = function () {
                cookie.set("rapprochement", true);
                operisWSServer.connexionComplete();
            };
        }
    ]);