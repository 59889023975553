/**
 * @version 1.0
 * @copyright 2017 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.ModuleInfos', [])
    .service('fr.operis.moteurV4.utils.ModuleInfos', [
        function () {
            var _modulesInfos = [];

            /**
             * Fonction qui positionne toutes les valeurs d'informations d'un module
             * @param {String} aModuleNom
             * @param {Object} aModule
             */
            this.setInfos = function(aModuleNom, aModule) {
                this.setInfo(aModuleNom, "IDENT_MODULE", aModule.IDENT_MODULE.valeur);
                this.setInfo(aModuleNom, "MODULELIBELLE", aModule.MODULELIBELLE.valeur);
                this.setInfo(aModuleNom, "MODULEICONE", aModule.MODULEICONE.valeur);
                this.setInfo(aModuleNom, "MOD_TRIGRAMME", aModule.MOD_TRIGRAMME.valeur);
                this.setInfo(aModuleNom, "MOD_TABLEENTITE", aModule.MOD_TABLEENTITE.valeur);
                this.setInfo(aModuleNom, "MOD_ENTITE", aModule.MOD_ENTITE.valeur);
                /*this.setInfo(aModuleNom, "MOD_COULEURPRIMAIRE", aModule.MOD_COULEURPRIMAIRE.valeur);
                this.setInfo(aModuleNom, "MOD_COULEURSECONDAIRE", aModule.MOD_COULEURSECONDAIRE.valeur);*/
                this.setInfo(aModuleNom, "ID_CATEGORIEMODULE", aModule.ID_CATEGORIEMODULE.valeur);
            };

            /**
             * Fonction qui positionne la valeur d'une information d'un module
             * @param {String} aModuleNom
             * @param {String} aInfo
             * @param {String} aValeur
             */
            this.setInfo = function(aModuleNom, aInfo, aValeur) {
                var infos = _modulesInfos[aModuleNom];
                
                if (angular.isNullOrUndefined(infos)) {
                    infos = [];
                    _modulesInfos[aModuleNom] = infos;
                }
                infos[aInfo] = aValeur;
            };
            
            /**
             * Fonction qui retourne la valeur d'une information d'un module
             * @String {aModuleNom}
             * @String {aInfo}
             * @returns La valeur
             */
            this.getInfo = function(aModuleNom, aInfo) {
                var valeur;
                var infos = _modulesInfos[aModuleNom];

                if (!angular.isNullOrUndefined(infos)) {
                    valeur = infos[aInfo];
                }

                return valeur;
            }
        }
    ]
);
