/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Conversion', ['fr.operis.moteurV4.utils.Erreur'])
    .constant('fr.operis.moteurV4.utils.Conversion.TYPE_TEXTE', 'VARCHAR2')
    .constant('fr.operis.moteurV4.utils.Conversion.TYPE_CLOB', 'CLOB')
    .constant('fr.operis.moteurV4.utils.Conversion.TYPE_DATE', 'DATE')
    .constant('fr.operis.moteurV4.utils.Conversion.TYPE_TIME', 'TIME')
    .constant('fr.operis.moteurV4.utils.Conversion.TYPE_DATETIME', 'DATETIME')
    .constant('fr.operis.moteurV4.utils.Conversion.TYPE_NUMERIQUE', 'NUMBER')
    .service('fr.operis.moteurV4.utils.Conversion'
    , ['fr.operis.moteurV4.utils.Erreur'
        , 'fr.operis.moteurV4.utils.Conversion.TYPE_TEXTE'
        , 'fr.operis.moteurV4.utils.Conversion.TYPE_CLOB'
        , 'fr.operis.moteurV4.utils.Conversion.TYPE_DATE'
        , 'fr.operis.moteurV4.utils.Conversion.TYPE_TIME'
        , 'fr.operis.moteurV4.utils.Conversion.TYPE_DATETIME'
        , 'fr.operis.moteurV4.utils.Conversion.TYPE_NUMERIQUE'
        , 'fr.operis.moteurV4.utils.Erreur.CONVERSION_IMPOSSIBLE'
        , function (erreur, TYPE_TEXTE, TYPE_CLOB, TYPE_DATE, TYPE_TIME, TYPE_DATETIME, TYPE_NUMERIQUE, CONVERSION_IMPOSSIBLE) {
            var entierRegExp = /^(\+|-)?[0-9]*$/;
            var numeriqueRegExp = /^(\+|-)?[0-9]*(\.[0-9]+)?$/;

            /**
             * Convertit la valeur fournie en son type.
             * @param {String} aValeur La valeur à convertir
             * @param {String} aType Le type de la valeur
             * @param {Integer} aDecimalesMax Nombre de décimale du type
             * @param {Number} aBorneMin Borne minimum du type
             * @param {Number} aBorneMax Borne maximum du type
             * @returns {String|Date|Boolean|Number|Integer}
             */
            this.versType = function (aValeur, aType, aDecimalesMax, aBorneMin, aBorneMax) {
                // Pour les champs multiples
                if (aValeur.indexOf(String.fromCharCode(243)) !== -1 )
                    aValeur = aValeur.split(String.fromCharCode(243));

                if (aType === TYPE_DATE) {
                    return this.versDate(aValeur);
                } else if (aType === TYPE_TIME) {
                    return this.versTime(aValeur);
                } else if (aType === TYPE_DATETIME) {
                    return this.versDateTime(aValeur);
                } else if (aType === TYPE_NUMERIQUE) {
                    if (aDecimalesMax === 0) {
                        if (aBorneMin === 0 && aBorneMax === 1) {
                            return this.versBooleen(aValeur);
                        } else {
                            return this.versEntier(aValeur);
                        }
                    } else {
                        return this.versNumerique(aValeur);
                    }
                } else {
                    return this.versTexte(aValeur);
                }
            };

            /**
             * Convertit la valeur fournie en string.
             * @param {String} aValeur La valeur à convertir
             * @param {String} aType Le type de la valeur
             * @param {Integer} aLongueurMax Longueur maximum du type
             * @param {Integer} aPartieEntiere Taille de la partie entière du type
             * @param {Integer} aDecimalesMax Nombre de décimale du type
             * @param {Number} aBorneMin Borne minimum du type
             * @param {Number} aBorneMax Borne maximum du type
             * @returns {String} La valeur en chaine de caractères.
             */
            this.versTexte = function (aValeur, aType, aLongueurMax, aPartieEntiere, aDecimalesMax, aBorneMin, aBorneMax) {
                if (angular.isNullOrUndefined(aType)) {
                    if (angular.isNullOrUndefined(aValeur)) {
                        return null;
                    } else {
                        return aValeur;
                    }
                } else if (angular.isNullOrUndefined(aValeur)) {
                    return "";
                } else if (Array.isArray(aValeur)) {
                    var that = this
                    var arrayValeur = aValeur.map(function(aValeur){
                            return that.versTexte(aValeur,aType, aLongueurMax, aPartieEntiere, aDecimalesMax, aBorneMin, aBorneMax);
                        });
                    return arrayValeur;
                } else if (aType === TYPE_CLOB) {
                    return aValeur;
                } else if (aType === TYPE_TEXTE && typeof(aValeur) === "string") {
                    if (aLongueurMax > 0) return aValeur.substr(0, aLongueurMax);
                    else return aValeur;
                } else if (aType === TYPE_DATE && aValeur instanceof Date) {
                    var jour = aValeur.getDate(),
                        mois = aValeur.getMonth() + 1,
                        annee = aValeur.getFullYear();
                    if (jour < 10) jour = '0' + jour;
                    if (mois < 10) mois = '0' + mois;
                    return jour + '/' + mois + '/' + annee;
                } else if (aType === TYPE_TIME && aValeur instanceof Date) {
                    var heures = aValeur.getHours(),
                        minutes = aValeur.getMinutes(),
                        secondes = aValeur.getSeconds();
                    if (heures < 10) heures = '0' + heures;
                    if (minutes < 10) minutes = '0' + minutes;
                    if (secondes < 10) secondes = '0' + secondes;
                    return heures + ':' + minutes + ':' + secondes;
                } else if (aType === TYPE_DATETIME && aValeur instanceof Date) {
                    var jour = aValeur.getDate(),
                        mois = aValeur.getMonth() + 1,
                        annee = aValeur.getFullYear(),
                        heures = aValeur.getHours(),
                        minutes = aValeur.getMinutes(),
                        secondes = aValeur.getSeconds();
                    if (jour < 10) jour = '0' + jour;
                    if (mois < 10) mois = '0' + mois;
                    if (heures < 10) heures = '0' + heures;
                    if (minutes < 10) minutes = '0' + minutes;
                    if (secondes < 10) secondes = '0' + secondes;
                    return jour + '/' + mois + '/' + annee + ' ' + heures + ':' + minutes + ':' + secondes;
                } else if (aType === TYPE_NUMERIQUE && typeof(aValeur) === "boolean") {
                    return aValeur ? "1" : "0";
                } else if (aType === TYPE_NUMERIQUE && typeof(aValeur) === "number") {
                    var valeur = aValeur,
                        decimales = Math.pow(10, aDecimalesMax);
                    if (!angular.isNullOrUndefined(aBorneMin) && typeof(aBorneMin) === "number" && valeur < aBorneMin) valeur = aBorneMin;
                    if (!angular.isNullOrUndefined(aBorneMax) && typeof(aBorneMax) === "number" && valeur > aBorneMax) valeur = aBorneMax;
                    if (decimales > 0) valeur = Math.round(valeur * decimales) / decimales;
                    return valeur.toString();
                } else {
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, aType, "string", aValeur);
                }
            };
            
            /**
             * Convertit la valeur fournie en string en prenant en compte les caractères spéciaux.
             * @param {String} aValeur La valeur à convertir
             * @param {String} aType Le type de la valeur
             * @param {Integer} aLongueurMax Longueur maximum du type
             * @param {Integer} aPartieEntiere Taille de la partie entière du type
             * @param {Integer} aDecimalesMax Nombre de décimale du type
             * @param {Number} aBorneMin Borne minimum du type
             * @param {Number} aBorneMax Borne maximum du type
             * @returns {String} La valeur en chaine de caractères.
             */
            this.versTexteEspaceForXML = function(aValeur, aType, aLongueurMax, aPartieEntiere, aDecimalesMax, aBorneMin, aBorneMax){
                var valeur = this.versTexte(aValeur, aType, aLongueurMax, aPartieEntiere, aDecimalesMax, aBorneMin, aBorneMax);
                if (Array.isArray(valeur)) {     
                    var arrayValeur = valeur.map(function(aValeur){
                        aValeur = aValeur.replace(/&/g,'&amp;');
                        aValeur = aValeur.replace(/</g,'&lt;');
                        aValeur = aValeur.replace(/>/g,'&gt;');
                        aValeur = aValeur.replace(/"/g,'&quot;');
                        aValeur = aValeur.replace(/'/g,'&apos;');
                        return aValeur;
                    });
                    return arrayValeur;
                } else{
                    valeur = valeur.replace(/&/g,'&amp;');
                    valeur = valeur.replace(/</g,'&lt;');
                    valeur = valeur.replace(/>/g,'&gt;');
                    valeur = valeur.replace(/"/g,'&quot;');
                    valeur = valeur.replace(/'/g,'&apos;');
                    return valeur;
                }                               
            };

            /**
             * Convertit la valeur fournie en integer.
             * @param {String} aValeur La valeur à convertir
             * @returns {Integer} Vide si la valeur est vide, sinon l'entier correspondant
             * @throws {Error} Lorsque la valeur à convertir n'est pas un entier
             */
            this.versEntier = function (aValeur) {
                if (angular.isNullOrUndefined(aValeur) || aValeur === "") return null;
                if (typeof(aValeur) === "number") return Math.round(aValeur);
                if (!entierRegExp.test(aValeur))
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "integer", aValeur);
                var ret = parseInt(aValeur);
                if (isNaN(ret)) {
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "integer", aValeur);
                }
                return ret;
            };

            /**
             * Convertit la valeur fournie en chaine de caractère.
             * @see versTexte
             * @param {Integer} aValeur La valeur à convertir
             * @returns {String} La valeur en chaine de caractère.
             */
            this.versTexteDepuisEntier = function (aValeur) {
                return this.versTexte(aValeur, TYPE_NUMERIQUE);
            };

            /**
             * Convertit la valeur fournie en float.
             * @param {String} aValeur La valeur à convertir
             * @returns {Float} Vide si la valeur est vide, sinon le nombre correspondant
             * @throws {Error} Lorsque la valeur à convertir n'est pas un entier
             */
            this.versNumerique = function (aValeur) {
                if (angular.isNullOrUndefined(aValeur) || aValeur === "") return null;
                if (typeof(aValeur) === "number") return aValeur;
                if (!numeriqueRegExp.test(aValeur)) {
                    aValeur = aValeur.replace(/,/, ".");
                    if (!numeriqueRegExp.test(aValeur))
                        throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "number", aValeur);
                }
                var ret = parseFloat(aValeur);
                if (isNaN(ret)) {
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "number", aValeur);
                }
                return ret;
            };

            /**
             * Convertit la valeur fournie en chaine de caractère.
             * @see versTexte
             * @param {Integer} aValeur La valeur à convertir
             * @returns {String} La valeur en chaine de caractère.
             */
            this.versTexteDepuisNumerique = function (aValeur) {
                return this.versTexte(aValeur, TYPE_NUMERIQUE);
            };

            /**
             * Convertit la valeur fournie en boolean.
             * @param {String} aValeur La valeur à convertir
             * @returns {Boolean} Vrai si la valeur vaut 1, faux sinon.
             */
            this.versBooleen = function (aValeur) {
                if (angular.isNullOrUndefined(aValeur) || aValeur === "") return null;
                if (typeof(aValeur) === "boolean") return aValeur;
                return aValeur === "1" ? true : false;
            };

            /**
             * Convertit la valeur fournie en chaine de caractère.
             * @see versTexte
             * @param {Boolean} aValeur La valeur à convertir
             * @returns {String} La valeur en chaine de caractère.
             */
            this.versTexteDepuisBooleen = function (aValeur) {
                return this.versTexte(aValeur, TYPE_NUMERIQUE);
            };

            /**
             * Convertit la valeur fournie en date.
             * @param {String} aValeur La valeur à convertir
             * @returns {Date} Vrai si la valeur vaut 1, faux sinon.
             */
            this.versDate = function (aValeur) {
                if (angular.isNullOrUndefined(aValeur) || aValeur === "") return null;
                if (aValeur instanceof Date) return aValeur;
                var dateSplit = aValeur.split('/');

                if (dateSplit.length !== 3)
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "date", aValeur);

                try {
                    var jour = this.versEntier(dateSplit[0]),
                        mois = this.versEntier(dateSplit[1]),
                        annee = this.versEntier(dateSplit[2]);

                    if (angular.isNullOrUndefined(jour) || angular.isNullOrUndefined(mois) || angular.isNullOrUndefined(annee))
                        throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "date", aValeur);

                    return new Date(annee, mois - 1, jour);
                } catch (error) {
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "date", aValeur);
                }
            };

            /**
             * Convertit la valeur fournie en heure.
             * @param {String} aValeur La valeur à convertir
             * @returns {Date}
             */
            this.versTime = function (aValeur) {
                if (angular.isNullOrUndefined(aValeur) || aValeur === "") return null;
                if (aValeur instanceof Date) return aValeur;

                // Parser la valeur à convertir. Formats attendus HH, HH:MM ou HH:MM:SS
                var timeSplit = aValeur.split(':');

                if (timeSplit.length > 3)
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "time", aValeur);
                else {
                    var n = timeSplit.length;
                    while (n < 3) {
                        n = timeSplit.push[0];
                    }
                }

                // Créer l'objet Date
                try {
                    var jour = 1,
                        mois = 1,
                        annee = 1990, //1970,
                        heures = this.versEntier(timeSplit[0]),
                        minutes = this.versEntier(timeSplit[1]),
                        secondes = this.versEntier(timeSplit[2]);

                    if (angular.isNullOrUndefined(jour) || angular.isNullOrUndefined(mois) || angular.isNullOrUndefined(annee) ||
                        angular.isNullOrUndefined(heures) || angular.isNullOrUndefined(minutes) || angular.isNullOrUndefined(secondes))
                        throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "time", aValeur);

                    return new Date(annee, mois - 1, jour, heures, minutes, secondes, 0);
                } catch (error) {
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "time", aValeur);
                }
            };

            /**
             * Convertit la valeur fournie en date et heure.
             * @param {String} aValeur La valeur à convertir
             * @returns {Date}
             */
            this.versDateTime = function (aValeur) {
                if (angular.isNullOrUndefined(aValeur) || aValeur === "") return null;
                if (aValeur instanceof Date) return aValeur;
                var dateHeure = aValeur.split(' ');
                var dateSplit = dateHeure[0].split('/');

                // Parser la valeur à convertir. Formats attendus JJ/MM/AAAA HH, JJ/MM/AAAA HH:MM ou JJ/MM/AAAA HH:MM:SS
                if (dateSplit.length !== 3)
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "date", aValeur);

                var timeSplit = [];
                var n = 0;
                if (dateHeure.length > 0) {
                    timeSplit = dateHeure[1].split(':');

                    if (timeSplit.length > 3)
                        throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "time", aValeur);
                    else {
                        n = timeSplit.length;
                        while (n < 3) {
                            n = timeSplit.push[0];
                        }
                    }
                } else {
                    while (n < 3) {
                        n = timeSplit.push[0];
                    }
                }

                try {
                    var jour = this.versEntier(dateSplit[0]),
                        mois = this.versEntier(dateSplit[1]),
                        annee = this.versEntier(dateSplit[2]),
                        heures = this.versEntier(timeSplit[0]),
                        minutes = this.versEntier(timeSplit[1]),
                        secondes = this.versEntier(timeSplit[2]);

                    if (angular.isNullOrUndefined(jour) || angular.isNullOrUndefined(mois) || angular.isNullOrUndefined(annee) ||
                        angular.isNullOrUndefined(heures) || angular.isNullOrUndefined(minutes) || angular.isNullOrUndefined(secondes))
                        throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "datetime", aValeur);

                    return new Date(annee, mois - 1, jour, heures, minutes, secondes, 0);
                } catch (error) {
                    throw erreur.creer(CONVERSION_IMPOSSIBLE, this, typeof(aValeur), "datetime", aValeur);
                }
            };
        }
    ]
);
