/**
 * @version 1.0b
 * @Author Brian Kjerulf - Operis
 */
'use strict';

angular.module('fr.operis.moteurV4.utils.RefExterne', [])
    /**
     * Nom de l'écran des références externe
     * @type {String}
     * @value _REFEXTERNE
     */
    .constant('fr.operis.moteurV4.utils.RefExterne.REFEXTERNE', '_REFEXTERNE')

    .service('fr.operis.moteurV4.utils.RefExterne',['$q',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.RefExterne.REFEXTERNE',
    function($q,
            serveur,
             conversion,
             moduleInfos,
             REFEXTERNE
             ){
        /**
         * Fonction qui met à jour les références externes d'une entite
         * @param {String} aNomModule Le nom du module
         * @param {Object} aScope Scope dans lequel les données sont à charger.
         * @param {Object} aIdentifiant Identifiant
         * @param {String} aSource Nom du support d'origine de l'entité
         * @param {String} aRefExterne Référence externe
         * @param {String} aEcranRefExterne Ecran de sauvegarde à utiliser
         * @returns {ng.IPromise<T>}
         */
        this.sauvegardeRefExterne = function(aNomModule, aScope, aIdentifiant, aSource, aRefExterne, aEcranRefExterne){
            var deferred = $q.defer();

            var nomEcranSauvegarde, bMobibilite = serveur.isMobile();
            var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");

            if (bMobibilite)
                nomEcranSauvegarde = trigramme + '_M' + REFEXTERNE;
            else
                nomEcranSauvegarde = trigramme + REFEXTERNE;

            if (!angular.isNullOrUndefined(aEcranRefExterne))
                nomEcranSauvegarde = aEcranRefExterne;

            // Chargement de l'écran des statuts
            serveur.chargement(aNomModule, aScope, nomEcranSauvegarde, aIdentifiant)
                .then(function(retourChargement){

                    // Mise à jour des champs des références externes
                    var tableRefExterne = aScope.champs[retourChargement[0]];
                    tableRefExterne[aScope.descriptions[retourChargement[0]][0].nom].valeur = conversion.versEntier(aIdentifiant[aScope.descriptions[retourChargement[0]][0].nom]);
                    tableRefExterne[aScope.descriptions[retourChargement[0]][1].nom].valeur = aSource;

                    if (!angular.isNullOrUndefined(aRefExterne)) {
                        tableRefExterne[aScope.descriptions[retourChargement[0]][2].nom].valeur = aRefExterne;
                    }

                    // Sauvegarde de l'écran des statuts
                    aScope.sauvegarde[nomEcranSauvegarde]()
                        .then(function(retourSauvegarde){
                            deferred.resolve(retourSauvegarde)
                        })
                })
                .catch(function (erreur) {
                    deferred.reject(erreur);
                });

            return deferred.promise;
        }
    }
    ]);


