/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteVerificationSuppressionFiche', [])

    .provider('fr.operis.moteurV4.utils.RequeteVerificationSuppressionFiche', [
        function () {
            var _configuration={};

            // Configuration des requetes pre traitement
            this.configuration= function( aConfiguration ){
                _configuration = aConfiguration;
            };

            /**
             * Retourne le service représentation
             * @param {injector} $injector
             * @returns {fr.operis.moteurV4.utils.communicationServlet}
             */
            this.$get = ['$injector', function ($injector) {
                return $injector.instantiate([
                    requeteVerificationSuppressionFiche
                ]);
            }
            ];

            function requeteVerificationSuppressionFiche(){
                // Execution des requetes pre traitement
                this.getEcranVerification = function(aNomModule){

                    if ( angular.isNullOrUndefined(aNomModule) )
                        return;

                    if (!angular.isNullOrUndefined(_configuration[aNomModule.toUpperCase()])){
                        return _configuration[aNomModule.toUpperCase()];
                    }
                    else
                        return;
                }
            }


        }
    ]
);