/**
 * @version 1.0b
 * @copyright 2017 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.ActionInfos', [])


    .service('fr.operis.moteurV4.utils.ActionInfos',
        [
        function () {

            // Listes des actions
            var _actionInfos = {};


            /**
             * Fonction qui positionne la valeur d'une information d'un module
             * @param {String} aIdModule
             * @param {String} aValeur
             */
            this.setAction = function(aIdModule, aValeur) {
                if (angular.isNullOrUndefined(_actionInfos[aIdModule])) {
                    _actionInfos[aIdModule] = aValeur;
                }
            };

            /**
             * Fonction qui retourne la valeur d'une information d'un module
             * @String {aIdModule}
             * @String {aIdAction}
             * @returns La valeur
             */
            this.getInfoAction = function(aIdModule, aIdAction) {
                if (!angular.isNullOrUndefined( _actionInfos[aIdModule] )) {
                    for ( var i=0; i<_actionInfos[aIdModule].length; i++){
                        if ( _actionInfos[aIdModule][i].ID_ACTION.valeur === aIdAction ){
                            return _actionInfos[aIdModule][i];
                            break;
                        }
                    }
                }

                return null;
            }

        }
    ]
);