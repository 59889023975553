/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpPopupAjoutElement', ['ui.bootstrap', 'fr.operis.moteurV4.composants.OpTextInput'])
    .constant('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPAVERTISSEMENT', 'avertissement')
    .constant('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPCONFIRMATIONWARNING', 'warning')
    .constant('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPCONFIRMATIONDANGER', 'danger')
    .constant('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPAJOUT', 'ajout')
    .constant('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPSUPPRESSION', 'suppression')
    .constant('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPSUPPRESSION_MULTIPLE', 'suppressionMultiple')
    .controller('fr.operis.moteurV4.composants.OpPopupAjoutElementControleur', ['$scope', '$modalInstance', '$window',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPAVERTISSEMENT',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPCONFIRMATIONWARNING',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPCONFIRMATIONDANGER',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPAJOUT',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPSUPPRESSION',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPSUPPRESSION_MULTIPLE',
        'titre', 'libElement', 'entite', 'descriptions',
        'listes', 'nomTable', 'type', 'listeElements','nomModule', function ($scope, $modalInstance, $window,
             TYPE_POPUPAVERTISSEMENT, TYPE_POPUPCONFIRMATIONWARNING, TYPE_POPUPCONFIRMATIONDANGER, TYPE_POPUPAJOUT,
             TYPE_POPUPSUPPRESSION, TYPE_POPUPSUPPRESSION_MULTIPLE, titre, libElement, entite, descriptions, listes,
             nomTable, type, listeElements, nomModule) {
                $scope.titre = titre;
                $scope.libElement = (!angular.isNullOrUndefined(libElement) && (libElement.trim() !== "")) ? libElement : "cet élément";
                $scope.listeElements = listeElements;
                $scope.entite = entite;
                $scope.descriptions = descriptions;
                $scope.listes = listes;
                $scope.nomTable = nomTable;
                $scope.isAvertissement = (type == TYPE_POPUPAVERTISSEMENT);
                $scope.isConfirmation = ((type == TYPE_POPUPCONFIRMATIONWARNING) || (type == TYPE_POPUPCONFIRMATIONDANGER));
                $scope.typeConfirmation = type;
                $scope.isFenetreAjout = (type == TYPE_POPUPAJOUT);
                $scope.isSuppression = (type == TYPE_POPUPSUPPRESSION);
                $scope.isSuppressionMultiple = (type == TYPE_POPUPSUPPRESSION_MULTIPLE);
                $scope.champs = {};
                $scope.nomModule = nomModule;

                if (!angular.isNullOrUndefined(nomTable)) {
                    $scope.champs[nomTable] = {};

                    for (var i = 0; i < descriptions[nomTable].length; i++) {
                        var nomColonne = descriptions[nomTable][i].nom;

                        if (!angular.isNullOrUndefined(nomColonne) && (!angular.isNullOrUndefined($scope.descriptions[nomTable][nomColonne]))) {
                            $scope.champs[nomTable][nomColonne] = {
                                descriptions: $scope.descriptions[nomTable][nomColonne]
                            };

                            // Le cas échéant, renseigner colonnes du clé primaire à partir de l'entité parent
                            if (($scope.champs[nomTable][nomColonne].descriptions.estClePrimaire) && ($scope.champs[nomTable][nomColonne].descriptions.estCleEtrangere)) {
                                if ((!angular.isNullOrUndefined($scope.entite)) && (!angular.isNullOrUndefined($scope.entite[nomColonne]))) {
                                    if ($scope.entite[nomColonne].descriptions.estClePrimaire) {
                                        $scope.champs[nomTable][nomColonne].valeur = $scope.entite[nomColonne].valeur;
                                    }
                                }
                            }
                        }
                    }
                }

                $scope.confirmer = function () {
                    $modalInstance.close(true);
                };

                $scope.supprimer = function () {
                    $modalInstance.close(true);
                };

                $scope.valider = function () {
                    if (!angular.isNullOrUndefined(nomTable)) {
                        $modalInstance.close($scope.champs[nomTable]);
                    }
                };

                $scope.fermer = function (erreur) {
                    $modalInstance.close();
                };
            }
    ]);

