/**
 * Created by Laurent Dorie on 18/01/17.
 */
"use strict";
angular.module('fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFiche',[])
    .controller('fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFicheCtrl',
        ['$modalInstance','$scope',
            function ($modalInstance,$scope) {
                $scope.$isButtonFermerVisible = false;
                $scope.valider = function (aValidation) {
                    $modalInstance.close(aValidation);
                };
                $scope.annuler = function(){
                    $scope.$dismiss();
                }
            }
        ])
    .service('fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFiche', ['$modal', function ($modal) {
        this.creer = function(){
            var popupOpEnregistrement = $modal.open({
                templateUrl:'moteur/composants/popup/OpPopupEnregistrementFiche.html',
                controller: 'fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFicheCtrl',
                size: 'sm',
                resolve: {}
            });

               return popupOpEnregistrement;
            }
        }
    ]);

