/**
 * Created by Alcer on 01/12/14.
 */
'use strict';

/*
 * Route : 
 * http://server/racine_de_mon_appli/module/page_html/type_recherche/taille_page/numéro_page/champ/critere/valeur/[et|ou]/champ/critere/valeur...
 * http://server/racine_de_mon_appli/module/page_html/id/valeur/id/valeur...
 * 
 */

angular.module('fr.operis.moteurV4.controleurs.ControleurBase', ['fr.operis.moteurV4.communication.OperisWSServer'])
    .service('fr.operis.moteurV4.controleurs.ControleurBase'
    , ['$window', '$q', 'fr.operis.moteurV4.communication.OperisWSServer'
        , function ($window, $q, operisWSServer) {

            var ControleurBase = function () {

                this.chargement = function (aScope, aNomEcran, aIdentifiants) {
                    return operisWSServer.chargement(aScope, aNomEcran, aIdentifiants);
                };
            };

            return new ControleurBase();
        }
    ]);