/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

(function () {

    /**
     * Promesse de la fenêtre de connexion.
     * @type {null}
     * @private
     */
    var _opConnexionPromise = null,
        /**
         * Map des clients pour le serveur Operis.
         * @type {Map<fr.operis.moteurV4.communication.OperisWSServer>}
         * @private
         */
        _operisWSServers = {},
        _operisWSServersToBeInstantiated = {},
        _motdePasseUtilisateur;

    angular.module('fr.operis.moteurV4.communication.OperisWSServer', [
        'ui.bootstrap'
        , 'fr.operis.moteurV4.composants.OpConnexion'
        , 'fr.operis.moteurV4.composants.OpConnexionPortail'
        , 'fr.operis.moteurV4.composants.OpMireAttente'
        , 'fr.operis.moteurV4.utils.RequeteMultiAppel'
        , 'fr.operis.moteurV4.composants.OpPopupAlerte'
        , 'fr.operis.moteurV4.composants.OpPopupDemo'
        , 'fr.operis.moteurV4.modele.Modele'
        , 'fr.operis.moteurV4.utils.Conversion'
        , 'fr.operis.moteurV4.utils.Encryption'
        , 'fr.operis.moteurV4.utils.Erreur'
        , 'fr.operis.moteurV4.utils.MD5'
        , 'fr.operis.moteurV4.utils.XML'])

    /**
     * Configuration du service de Modele.
     */
        .config([
            'fr.operis.moteurV4.modele.ModeleProvider'
            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1'
            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2'
            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3'
            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE'
            , function (ModeleProvider, SEPARATEUR_NIVEAU_1, SEPARATEUR_NIVEAU_2, SEPARATEUR_NIVEAU_3,SEPARATEUR_INTERNE) {
                ModeleProvider.setSeparateurNiveau1(SEPARATEUR_NIVEAU_1);
                ModeleProvider.setSeparateurNiveau2(SEPARATEUR_NIVEAU_2);
                ModeleProvider.setSeparateurNiveau3(SEPARATEUR_NIVEAU_3);
                ModeleProvider.setSeparateurInterne(SEPARATEUR_INTERNE);
            }])


    /**
     * Séparateur interne
     * @type {String}
     * @value ó
     */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE', String.fromCharCode(243))
    /**
     * Séparateur de niveau 3 (plus haut)
     * @type {String}
     * @value ò
     */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3', String.fromCharCode(242))

    /**
     * Séparateur de niveau 2
     * @type {String}
     * @value ñ
     */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2', String.fromCharCode(241))

    /**
     * Séparateur de niveau 1
     * @type {String}
     * @value ð
     */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1', String.fromCharCode(240))

    /**
     * Séparateur de niveau 1 (plus bas) (saisie client)
     * @type {String}
     * @value {OPERISFRAMEWORK_ETH}
     */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1_SAISIE_CLIENT',"{OPERISFRAMEWORK_ETH}")

        /**
         * Séparateur de niveau 2 (saisie client)
         * @type {String}
         * @value {OPERISFRAMEWORK_N_TILDE}
         */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2_SAISIE_CLIENT',"{OPERISFRAMEWORK_N_TILDE}")

        /**
         * Séparateur de niveau 3 (plus haut) (saisie client)
         * @type {String}
         * @value {OPERISFRAMEWORK_O_GRAVE}
         */
        .constant('fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3_SAISIE_CLIENT',"{OPERISFRAMEWORK_O_GRAVE}")



    /**
     * Nom de l'écran BD pour gérer un fichier.
     * @type {String}
     * @value OperisFrameworkFichier
     */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_FICHIER','OperisFrameworkFichier')


        /**
         * Nom de l'écran BD pour les paramètres du module.
         * @type {String}
         * @value OperisFrameworkParametres
         */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_PARAMETRES','OperisFrameworkParametres')


    /**
     * Grille des fichiers.
     * @type {String}
     * @value GES_FICHIER
     */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.TABLE_GES_FICHIER','GES_FICHIER')


    /**
     * Colonne de l'identifiant de fichier.
     * @type {String}
     * @value ID_FICHIER
     */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.CHAMP_ID_FICHIER','ID_FICHIER')

    /**
     * Colonne du nom de fichier.
     * @type {String}
     * @value FICHIERNOM
     */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.CHAMP_NOM_FICHIER','FICHIERNOM')

    /**
     * Colonne du type de fichier.
     * @type {String}
     * @value FICHIERTYPE
     */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.CHAMP_FORMAT_FICHIER','FICHIERTYPE')

    /**
     * Colonne du fichier.
     * @type {String}
     * @value FICHIERVALEUR
     */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.CHAMP_DONNEES_FICHIER','FICHIERVALEUR')

        /**
         * Colonne de clé des paramètres pour remonter la grille.
         * @type {String}
         * @value GRILLE
         */
        .constant ('fr.operis.moteurV4.communication.OperisWSServer.CHAMP_PARAMETRE_GRILLE','GRILLE')

    /**
     * Client pour le serveur du framework.
     *
     * @author Alcer
     */
        .provider('fr.operis.moteurV4.communication.OperisWSServer', ['$injector', function ($injector) {
            var _url = 'services/ServerV4',
                _timeoutHttp = 30000,
                _mobile = false;

            /**
             * Défini l'URL du service.
             * @param {string} aURL L'URL du service.
             */
            this.setURL = function (aURL) {
                _url = aURL;
            };
            
            this.setMobile = function(aMobile) {
                _mobile = aMobile;
            }


            /**
             * Défini le timeout pour les requêtes HTTP du module.
             * @param {number} aTimeoutHttp Le timeout pour les requêtes HTTP
             */
            this.setTimeoutHttp = function (aTimeoutHttp) {
                if (angular.isNumber(aTimeoutHttp)) _timeoutHttp = aTimeoutHttp;
            };

            /**
             * Enregistre le client OperisWSServer pour le module spécifié.
             * @param {aNomModule} aNomModule Le nom du module
             * @param {aLibelleModule} aLibelleModule Le libellé du module
             */
            this.registerModule = function (aNomModule, aLibelleModule) {
                _operisWSServersToBeInstantiated[aNomModule] = {
                    libelleModule:aLibelleModule
                };
            };

            /**
             * Retourne le service OperisWSServer pour le module.
             * @param {injector} $injector
             * @returns {fr.operis.moteurV4.communication.OperisWSServer}
             */
            this.$get = ['$injector', '$q', function ($injector, $q) {
                for (var nomModule in _operisWSServersToBeInstantiated) {
                    _operisWSServers[nomModule] = _operisWSServers[nomModule] || $injector.instantiate([
                            '$window', '$q', '$http', '$timeout', '$log', '$modal', '$location'
                            , 'fr.operis.moteurV4.utils.Cookie'
                            , 'fr.operis.moteurV4.utils.Config'
                            , 'fr.operis.moteurV4.utils.Config.cookie.LOGOUT_STATE'
                            , 'fr.operis.moteurV4.utils.Encryption'
                            , 'fr.operis.moteurV4.utils.Conversion'
                            , 'fr.operis.moteurV4.utils.MD5'
                            , 'fr.operis.moteurV4.utils.XML'
                            , 'fr.operis.moteurV4.composants.OpMireAttente'
                            , 'fr.operis.moteurV4.composants.OpPopupAlerte'
                            , 'fr.operis.moteurV4.utils.RequeteMultiAppel'
                            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3'
                            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2'
                            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1'
                            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1_SAISIE_CLIENT'
                            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2_SAISIE_CLIENT'
                            , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3_SAISIE_CLIENT'
                            ,'fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_FICHIER'
                            ,'fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_PARAMETRES'
                            ,'fr.operis.moteurV4.communication.OperisWSServer.TABLE_GES_FICHIER'
                            ,'fr.operis.moteurV4.communication.OperisWSServer.CHAMP_ID_FICHIER'
                            ,'fr.operis.moteurV4.communication.OperisWSServer.CHAMP_PARAMETRE_GRILLE'
                            , 'fr.operis.moteurV4.modele.Modele'
                            , 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS'
                            , 'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS'
                            , 'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES'
                            , 'fr.operis.moteurV4.modele.Modele.SCOPE_LISTES'
                            , 'fr.operis.moteurV4.utils.Habilitation'
                            , 'fr.operis.moteurV4.utils.Erreur'
                            , 'fr.operis.moteurV4.utils.Erreur.RETOUR_VIDE'
                            , 'fr.operis.moteurV4.utils.Erreur.RETOUR_ILLISIBLE'
                            , 'fr.operis.moteurV4.utils.Erreur.RETOUR_INVALIDE'
                            , 'fr.operis.moteurV4.utils.Erreur.JETON_INCOHERENT'
                            , 'fr.operis.moteurV4.utils.Erreur.FONCTION_INCOHERENTE'
                            , 'fr.operis.moteurV4.utils.Erreur.ERREUR_SERVEUR'
                            , 'fr.operis.moteurV4.utils.Erreur.ERREUR_INTERNE_SERVEUR'
                            , 'fr.operis.moteurV4.utils.Erreur.SERVICE_SUPPRIME'
                            , 'fr.operis.moteurV4.utils.Erreur.TRANSACTION_AVORTEE'
                            , 'fr.operis.moteurV4.utils.Erreur.REQUETE_ANNULEE'
                            , 'fr.operis.moteurV4.utils.Erreur.REQUETE_TIMEOUT'
                            , 'fr.operis.moteurV4.utils.Erreur.REQUETE_INVALIDE'
                            , OperisWSServer
                        ]);
                    _operisWSServers[nomModule].setNomModule(nomModule);
                    _operisWSServers[nomModule].setLibelleModule(_operisWSServersToBeInstantiated[nomModule].libelleModule);
                }

                /**
                 * Enregistre le client OperisWSServer pour le module spécifié.
                 * @param {aNomModule} aNomModule Le nom du module
                 * @param {aLibelleModule} aLibelleModule Le libellé du module
                 */
                var registerModule = function (aNomModule, aLibelleModule) {
                    if ( angular.isNullOrUndefined (_operisWSServersToBeInstantiated[aNomModule])){
                        _operisWSServersToBeInstantiated[aNomModule] = {
                            libelleModule:aLibelleModule
                        };

                        _operisWSServers[aNomModule] = _operisWSServers[aNomModule] || $injector.instantiate([
                                '$window', '$q', '$http', '$timeout', '$log', '$modal', '$location'
                                , 'fr.operis.moteurV4.utils.Cookie'
                                , 'fr.operis.moteurV4.utils.Config'
                                , 'fr.operis.moteurV4.utils.Config.cookie.LOGOUT_STATE'
                                , 'fr.operis.moteurV4.utils.Encryption'
                                , 'fr.operis.moteurV4.utils.Conversion'
                                , 'fr.operis.moteurV4.utils.MD5'
                                , 'fr.operis.moteurV4.utils.XML'
                                , 'fr.operis.moteurV4.composants.OpMireAttente'
                                , 'fr.operis.moteurV4.composants.OpPopupAlerte'
                                , 'fr.operis.moteurV4.utils.RequeteMultiAppel'
                                , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3'
                                , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2'
                                , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1'
                                , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1_SAISIE_CLIENT'
                                , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2_SAISIE_CLIENT'
                                , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_3_SAISIE_CLIENT'
                                ,'fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_FICHIER'
                                ,'fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_PARAMETRES'
                                ,'fr.operis.moteurV4.communication.OperisWSServer.TABLE_GES_FICHIER'
                                ,'fr.operis.moteurV4.communication.OperisWSServer.CHAMP_ID_FICHIER'
                                ,'fr.operis.moteurV4.communication.OperisWSServer.CHAMP_PARAMETRE_GRILLE'
                                , 'fr.operis.moteurV4.modele.Modele'
                                , 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS'
                                , 'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS'
                                , 'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES'
                                , 'fr.operis.moteurV4.modele.Modele.SCOPE_LISTES'
                                , 'fr.operis.moteurV4.utils.Habilitation'
                                , 'fr.operis.moteurV4.utils.Erreur'
                                , 'fr.operis.moteurV4.utils.Erreur.RETOUR_VIDE'
                                , 'fr.operis.moteurV4.utils.Erreur.RETOUR_ILLISIBLE'
                                , 'fr.operis.moteurV4.utils.Erreur.RETOUR_INVALIDE'
                                , 'fr.operis.moteurV4.utils.Erreur.JETON_INCOHERENT'
                                , 'fr.operis.moteurV4.utils.Erreur.FONCTION_INCOHERENTE'
                                , 'fr.operis.moteurV4.utils.Erreur.ERREUR_SERVEUR'
                                , 'fr.operis.moteurV4.utils.Erreur.ERREUR_INTERNE_SERVEUR'
                                , 'fr.operis.moteurV4.utils.Erreur.SERVICE_SUPPRIME'
                                , 'fr.operis.moteurV4.utils.Erreur.TRANSACTION_AVORTEE'
                                , 'fr.operis.moteurV4.utils.Erreur.REQUETE_ANNULEE'
                                , 'fr.operis.moteurV4.utils.Erreur.REQUETE_TIMEOUT'
                                , 'fr.operis.moteurV4.utils.Erreur.REQUETE_INVALIDE'
                                , OperisWSServer
                            ]);

                        _operisWSServers[aNomModule].setNomModule(aNomModule);
                        _operisWSServers[aNomModule].setLibelleModule(_operisWSServersToBeInstantiated[aNomModule].libelleModule);
                    }


                };

                /**
                 * Retourne l'identifiant du module à partir du client du module spécifié.
                 * @param {aNomModule} Le nom du module
                 * @returns {string} L'identifiant du module.
                 */
                var getIdModule = function (aNomModule) {
                        return _operisWSServers[aNomModule].getIdModule();
                };

                /**
                 * Retourne l'identifiant de l'utilisateur à partir du client du module spécifié.
                 * @param {aNomModule} Le nom du module
                 * @returns {string} L'identifiant de l'utilisateur.
                 */
                var getIdUtilisateur = function (aNomModule) {
                    return _operisWSServers[aNomModule].getIdUtilisateur;
                };

                /**
                 * Retourne le login de l'utilisateur à partir du client du module spécifié.
                 * @param {aNomModule} Le nom du module
                 * @returns {string} Le login de l'utilisateur.
                 */
                var getUtilisateur = function (aNomModule) {
                    return _operisWSServers[aNomModule].getUtilisateur();
                };

                /**
                 * Retourne l'identifiant de session à partir du client du module spécifié.
                 * @param {aNomModule} Le nom du module
                 * @returns {string} L'identifiant de session.
                 */
                var getIdSession = function (aNomModule) {
                    return _operisWSServers[aNomModule].getIdSession();
                };

                /**
                 * Retourne les informations de l'utilisateur. Le champs "original" est vide si il ne s'agit pas d'une connexion via portail. Null si aucun utilisateur de connecté. 
                 * @param {aNomModule} Le nom du module
                 * @returns {string} L'identifiant de session.
                 */
                var getUtilisateurInformation = function (aNomModule) {
                    return _operisWSServers[aNomModule].getUtilisateurInformation();
                };
                
                /**
                 * Modifie les informations de l'utilisateur. Le champs "original" doit être NULL si il ne s'agit pas d'une connection portail.
                 * @param {aNomModule} Le nom du module
                 */
                var setUtilisateurInformation = function (aNomModule, utilisateurInformation) {
                    return _operisWSServers[aNomModule].setUtilisateurInformation(utilisateurInformation);
                };

                /**
                 * Retourne l'URL à partir du client du module spécifié.
                 * @param {aNomModule} Le nom du module
                 * @returns {string} L'URL.
                 */
                var getURL = function (aNomModule) {
                    return _operisWSServers[aNomModule].getURL();
                };

                /**
                 * Retourne la version du serveur Operis à partir du client du module spécifié.
                 * @param {aNomModule} Le nom du module
                 * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
                 * @returns {ng.IPromise<T>}
                 */
                var version = function (aNomModule, aTransaction) {
                    return _operisWSServers[aNomModule].version(aTransaction);
                };

                /**
                 * Récupère la liste des portails disponibles.
                 * @returns {ng.IPromise<T>}
                 */
                var portailsComplete = function () {
					// On exécute cette requête sur le premier module trouvé uniquement
                    for (var nomModule in _operisWSServers) {
                        return _operisWSServers[nomModule].portails();
							
						break;
                    }
                };

                /**
                 * Récupère la description du portail.
                 * @param {String} portail Le nom du portail selectionné.
                 * @param {String} callbackURL L'URL de callback qui sera utilisée par le portail.
                 * @param {String} callbackParams Les paramètres de callback qui seront placés dans le state.
                 * @returns {ng.IPromise<T>}
                 */
                var descriptionPortailComplete = function (portail, callbackURL, callbackParams) {
					// On exécute cette requête sur le premier module trouvé uniquement
                    for (var nomModule in _operisWSServers) {
                        return _operisWSServers[nomModule].descriptionPortail(portail, callbackURL, callbackParams);
							
						break;
                    }
                };
				
                /**
                 * Validation de la connexion via portail.
                 * @param {String} portail Le nom du portail selectionné.
                 * @param {String} callbackURL L'URL de callback qui avait été utilisée à la première étape par le portail.
                 * @param {String} callbackParams Les paramètres de callback qui seront placés dans le state.
                 * @param {String} data Les données renvoyées par le portail permettant la validation de la connexion.
                 * @param {Boolean} reconnexion Force la reconnexion. Le serveur tentera une déconnexion avant de ce connecter.
                 * @param {Boolean} rapprochement La validation de connexion sert à rapprocher le compte Framework du compte Portail.
                 * @returns {ng.IPromise<T>}
                 */
                var validateConnexionPortailComplete = function (portail, callbackURL, callbackParams, data, reconnexion, rapprochement, nonce) {
					var deferred = $q.defer(),
                        resultatsAttendus = 0,
                        erreurs = [];
                    for (var nomModule in _operisWSServers) {
                        ++resultatsAttendus;
                        _operisWSServers[nomModule].validateConnexionPortail(
                            portail, callbackURL, callbackParams, data, reconnexion, rapprochement, nonce)
                            .catch(function (erreur) {
                                erreurs.push(erreur);
                            })
                            .finally(function () {
                                if (--resultatsAttendus === 0) {
                                    if (erreurs.length === 0) deferred.resolve();
                                    else deferred.reject(erreurs);
                                }
                            });
                    }
                    return deferred.promise;
                };

                /**
                 * Réalise la connexion aux serveurs Operis pour l'utilisateur concerné.
                 * @param {String} aUtilisateur Le nom de l'utilisateur pour la connexion.
                 * @param {String} aMotdepasse Le mot de passe de l'utilisateur pour la connexion.
                 * @returns {ng.IPromise<T>}
                 */
                var connexionComplete = function (aUtilisateur, aMotdepasse) {
                    var deferred = $q.defer(),
                        resultatsAttendus = 0,
                        erreurs = [];
                    for (var nomModule in _operisWSServers) {
                        ++resultatsAttendus;
                        _operisWSServers[nomModule].connexion(aUtilisateur, aMotdepasse)
                            .catch(function (erreur) {
                                erreurs.push(erreur);
                            })
                            .finally(function () {
                                if (--resultatsAttendus === 0) {
                                    if (erreurs.length === 0) deferred.resolve();
                                    else deferred.reject(erreurs);
                                }
                            });
                    }
                    return deferred.promise;
                };

                /**
                 * Réalise la déconnexion aux serveurs Operis pour l'utilisateur concerné.
                 * @param {String} aUtilisateur Le nom de l'utilisateur.
                 * @param {String} aMotdepasse Le mot de passe de l'utilisateur.
                 * @returns {ng.IPromise<T>}
                 */
                var deconnexionComplete = function (aUtilisateur, aMotdepasse) {
                    var deferred = $q.defer(),
                        resultatsAttendus = 0,
                        erreurs = [];
                    for (var nomModule in _operisWSServers) {
                        ++resultatsAttendus;
                        _operisWSServers[nomModule].deconnexion(aUtilisateur, aMotdepasse)
                            .catch(function (erreur) {
                                erreurs.push(erreur);
                            })
                            .finally(function () {
                                if (--resultatsAttendus === 0) {
                                    if (erreurs.length === 0) deferred.resolve();
                                    else deferred.reject(erreurs);
                                }
                            });
                    }
                    return deferred.promise;
                };

                /**
                 * Récupère la description des portails disponibles.
                 * @param {String} aNomModule Le nom du module.
                 * @param {String} callbackURL L'URL de callback qui sera utilisée par le portail.
                 * @param {String} callbackParams Les paramètres de callback qui seront placés dans le state.
                 * @returns {ng.IPromise<T>}
                 */
                var descriptionPortail = function(aNomModule, callbackURL, callbackParams){
                    return _operisWSServers[aNomModule].descriptionPortail(callbackURL, callbackParams);
                };

                /**
                 * Récupère la description des portails disponibles.
                 * @param {String} aNomModule Le nom du module.
                 * @param {String} portail Le nom du portail.
                 * @param {String} callbackURL L'URL de callback qui avait été utilisée à la première étape par le portail.
                 * @param {String} callbackParams Les paramètres de callback qui seront placés dans le state.
                 * @param {String} data Les données renvoyées par le portail permettant la validation de la connexion.
                 * @param {Boolean} reconnexion Force la reconnexion. Le serveur tentera une déconnexion avant de ce connecter.
                 * @param {Boolean} rapprochement La validation de connexion sert à rapprocher le compte Framework du compte Portail.
                 * @returns {ng.IPromise<T>}
                 */
                var validateConnexionPortail = function(aNomModule, portail, callbackURL, callbackParams, data, reconnexion, rapprochement, nonce){
                    return _operisWSServers[aNomModule].validateConnexionPortail(
                        portail, callbackURL, callbackParams, data, reconnexion, rapprochement, nonce);
                };

                /**
                 * Réalise la connexion aux serveurs Operis pour l'utilisateur concerné.
                 * @param {String} aModule Le nom du module.
                 * @param {String} aUtilisateur Le nom de l'utilisateur.
                 * @param {String} aMotdepasse Le mot de passe de l'utilisateur.
                 * @returns {ng.IPromise<T>}
                 */
                var connexion = function(aNomModule, aUtilisateur, aMotdepasse){
                    return _operisWSServers[aNomModule].connexion(aUtilisateur, aMotdepasse);
                };

                /**
                 * Réalise le chargement d'un écran de données à partir du client du module spécifié.
                 *
                 * @param {aNomModule} Le nom du module
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {Object} aIdentifiants Nom et valeur des colonnes permettant d'identifier un élément unique de la vue.
                 * Si la valeur est omise, on prend la valeur utilisée lors d'un précédent appel à la fonction.
                 * Pour omettre la valeur, il suffit de mettre undefined comme valeur.
                 * Ex : chargement(scope, ecran);
                 * Ex : chargement(scope, ecran, undefined, transaction);
                 * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
                 * @param {String} aDisplay Indique le type d'affichage pour la mire d'attente.
                 * @returns {ng.IPromise<T>}
                 *
                 */
                var chargement = function (aNomModule, aScope, aNomEcran, aIdentifiants, aTransaction, aDisplay) {
                    return _operisWSServers[aNomModule].chargement(aScope, aNomEcran, aIdentifiants, aTransaction, aDisplay);
                };

                /**
                 * Réalise la recherche sur un écran de données à partir du client du module spécifié.
                 *
                 * @param {aNomModule} Le nom du module
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aTablesValeurs Les tables associées à l'écran
                 * @param {type} aTaillePage La taille de la page de données à remonter
                 * @param {type} aNumeroPage Le numéro de la page de données à remonter
                 * @param {Object} aCritere [Optionnal] Les critères de recherche
                 * @returns {ng.IPromise<T>}
                 */
                var recherche = function (aNomModule, aScope, aNomEcran, aTablesValeurs, aTaillePage, aNumeroPage, aCritere) {
                    return _operisWSServers[aNomModule].recherche(aScope, aNomEcran, aTablesValeurs, aTaillePage, aNumeroPage, aCritere);
                };

                /**
                 * Réalise la sauvegarde sur un écran de données à partir du client du module spécifié.
                 *
                 * @param {aNomModule} Le nom du module
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aIdentifiants Les identifiants associés à l'écran
                 * @param {type} aTablesValeurs Les tables associées à l'écran
                 * @param {type} aTablesGrilles Les tables de grilles associées à l'écran
                 * @param {String} aDisplay Indique le type d'affichage pour la mire d'attente.
                 * @returns {ng.IPromise<T>}
                 */
                var sauvegarde = function (aNomModule, aScope, aNomEcran, aIdentifiants, aTablesValeurs, aTablesGrilles, aDisplay) {
                    return _operisWSServers[aNomModule].sauvegarde(aScope, aNomEcran, aIdentifiants, aTablesValeurs, aTablesGrilles, aDisplay);
                };

                /**
                 * Réalise la suppression sur un écran de données.
                 *
                 * @param {aNomModule} Le nom du module
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aIdentifiants Les identifiants associés à l'écran
                 * @returns {ng.IPromise<T>}
                 */
                var suppression = function (aNomModule, aScope, aNomEcran, aIdentifiants) {
                    return _operisWSServers[aNomModule].suppression(aScope, aNomEcran, aIdentifiants);
                };

                /**
                 * Réalise la sauvegarde d'un fichier sur un module
                 *
                 * @param {aNomModule} Le nom du module
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {Object} aFichier Fichier à sauvegarder
                 * @returns {ng.IPromise<T>}
                 */
                var sauvegardeFichier = function (aNomModule, aScope, aFichier) {
                    return _operisWSServers[aNomModule].sauvegardeFichier(aScope, aFichier);
                };

                /**
                * Appel d'une procédure stockée
                *
                * @param {aNomModule} Le nom du module
                * @param {Object} aScope Scope dans lequel les données sont à charger.
                * @param {String} aNomFonction Nom de fonction
                * @param {String} aParam1 Parametre1
                * @param {String} aParam2 Parametre2
                * @param {String} aParam3 Parametre3
                * @param {String} aParam4 Parametre4
                * @returns {ng.IPromise<T>}
                */
                var procedureInterne = function(aNomModule, aScope, aNomFonction, aParam1, aParam2, aParam3, aParam4){
                    return _operisWSServers[aNomModule].procedureInterne(aScope, aNomFonction, aParam1, aParam2, aParam3, aParam4 );
                };

                /**
                 * Supprime le client OperisWSServer pour le module spécifié.
                 * @param {aNomModule} aNomModule Le nom du module
                 */
                var deregisterModule = function (aNomModule) {
                    _operisWSServers[aNomModule].hasBeenDeleted();
                    this.deconnexion();
                    delete _operisWSServers[aNomModule];
                };

                /**
                 * Initialise les paramètres pour un module spécifié.
                 * @param {aNomModule} aNomModule Le nom du module
                 */
                var chargementParametresModule = function (aNomModule) {
                    var deferred = $q.defer();

                    if (!angular.isNullOrUndefined(_operisWSServers[aNomModule])) {
                        _operisWSServers[aNomModule].chargementParametres().then(function (resultat) {
                            deferred.resolve();
                        }).catch(function (erreur) {
                            deferred.reject(erreur);
                        });
                    } else {
                        deferred.reject();
                    }

                    return deferred.promise;
                };

                /**
                 * Lit le parametre en fonction de la catégorie et du code
                 * @param {aNomModule} aNomModule Le nom du module
                 */
                var lireParametre = function(aNomModule, aCategorie, aCode){
                    return _operisWSServers[aNomModule].lireParametre(aCategorie, aCode);
                };
                
                var isMobile = function() {
                    return _mobile;
                };

                var getMultiAppel = function(aNomModule){
                    return _operisWSServers[aNomModule].getMultiAppel();
                };

                var setMultiAppel = function(aNomModule, aScope, aMultiAppel){
                    return _operisWSServers[aNomModule].setMultiAppel(aScope, aMultiAppel);
                };
				
				var deconnexionPortail = function(aNomModule){
					return _operisWSServers[aNomModule].deconnexionPortail();
				};

                return {
                    getIdModule:getIdModule,
                    getIdUtilisateur:getIdUtilisateur,
                    getUtilisateur:getUtilisateur,
                    getIdSession:getIdSession,
                    getURL:getURL,
                    version:version,
                    portailsComplete: portailsComplete,
                    descriptionPortailComplete: descriptionPortailComplete,
                    validateConnexionPortailComplete: validateConnexionPortailComplete,
                    connexionComplete: connexionComplete,
                    deconnexionComplete: deconnexionComplete,
                    connexion:connexion,
					deconnexionPortail:deconnexionPortail,
                    chargement: chargement,
                    recherche: recherche,
                    sauvegarde: sauvegarde,
                    suppression: suppression,
                    sauvegardeFichier: sauvegardeFichier,
                    procedureInterne: procedureInterne,
                    isMobile: isMobile,
                    deregisterModule:deregisterModule,
                    chargementParametresModule: chargementParametresModule,
                    lireParametre:lireParametre,
                    registerModule:registerModule,
                    getMultiAppel:getMultiAppel,
                    setMultiAppel:setMultiAppel,
                    getUtilisateurInformation:getUtilisateurInformation,
                    setUtilisateurInformation:setUtilisateurInformation
                }
            }
            ];

            /**
             * Constante déterminant l'action SOAP du serveur Operis.
             * @type {string}
             * @const
             * @private
             */
            var SOAP_ACTION = 'OperisWSFunction';

            /**
             * Message SOAP envoyé au serveur Operis.
             * @type {string}
             * @const
             * @private
             */
            var MESSAGE_TEMPLATE = '' +
                '<soapenv:Envelope ' +
                'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
                'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
                'xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" ' +
                'xmlns:oper="http://www.appia-sa.fr/Operis" ' +
                'xmlns:soapenc="http://schemas.xmlsoap.org/soap/encoding/">' +
                '<soapenv:Header/>' +
                '<soapenv:Body>' +
                '<oper:OperisWSFunction soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
                '<parametres xsi:type="oper:StringArray" soapenc:arrayType="xsd:string[]">' +
                '{{parametres}}' +
                '</parametres>' +
                '</oper:OperisWSFunction>' +
                '</soapenv:Body>' +
                '</soapenv:Envelope>';

            /**
             * Balise SOAP déterminant une valeur nulle remontée par le serveur Operis.
             * @type {String}
             * @const
             * @private
             */
            var ELEMENT_NUL = '_xsi:nil';

            function OperisWSServer($window, $q, $http, $timeout, $log, $modal, $location, cookie,
                                    $config, LOGOUT_STATE, encryption, conversion, md5, xml, opMireAttente, opPopupAlerte, requeteMultiAppel,
                                    SEPARATEUR_NIVEAU_3, SEPARATEUR_NIVEAU_2, SEPARATEUR_NIVEAU_1,
                                    SEPARATEUR_NIVEAU_1_SAISIE_CLIENT,SEPARATEUR_NIVEAU_2_SAISIE_CLIENT,SEPARATEUR_NIVEAU_3_SAISIE_CLIENT,
                                    ID_VUE_FICHIER,ID_VUE_PARAMETRES,TABLE_GES_FICHIER,CHAMP_ID_FICHIER,CHAMP_PARAMETRE_GRILLE,
                                    modele, SCOPE_DESCRIPTIONS, SCOPE_CHAMPS, SCOPE_GRILLES, SCOPE_LISTES,habilitation,
                                    erreur, RETOUR_VIDE, RETOUR_ILLISIBLE, RETOUR_INVALIDE, JETON_INCOHERENT,
                                    FONCTION_INCOHERENTE, ERREUR_SERVEUR, ERREUR_INTERNE_SERVEUR, SERVICE_SUPPRIME,
                                    TRANSACTION_AVORTEE, REQUETE_ANNULEE, REQUETE_TIMEOUT, REQUETE_INVALIDE) {

                // Variables internes à la classe
                var that = this,
                    _module = 'Nom du module à configurer',
                    _libelleModule = 'Libellé du module à configurer',
                    _utilisateur = null,
                    _idModule = null,
                    _idUtilisateur = null,
                    _idSession = null,
                    _utilisateurInformation = null,
                    _identifiants = null,
                    _parametre = null,
                    _cacheEcrans = {},
                    _deleted = false,
                    _transactions = {};

                /**
                 * Encode l'objet des identifiants en une chaine pour le serveur Operis.
                 * @param {type} aIdentifiants L'objet des identifiants
                 * @returns {String} La chaine pour le serveur Operis
                 * @private
                 */
                function _encoderIdentifiants(aIdentifiants) {
                    var ret = "";

                    if (aIdentifiants !== null) {
                        for (var attribut in aIdentifiants) {
                            if (ret.length > 0)
                                ret = ret + SEPARATEUR_NIVEAU_2;
                            ret = ret + attribut + SEPARATEUR_NIVEAU_1 + aIdentifiants[attribut];
                        }
                    }
                    return ret;
                }

                /**
                 * Décode la chaine du serveur Operis en un l'objet d'identifiants.
                 * @param {String} aIdentifiants La chaine pour le serveur Operis
                 * @returns {Object} L'objet des identifiants
                 * @private
                 */
                function _decoderIdentifiants(aIdentifiants) {
                    var ret = {};

                    if (aIdentifiants !== null) {
                        // On sépare les identifiants
                        var identifiants = aIdentifiants.split(SEPARATEUR_NIVEAU_2);
                        for (var indiceIdentifiant = 0; indiceIdentifiant < identifiants.length; ++indiceIdentifiant) {
                            var id = identifiants[indiceIdentifiant].split(SEPARATEUR_NIVEAU_1);
                            ret[id[0]] = id[1];
                        }
                    }
                    return ret;
                }

                /**
                 * Réalise un appel SOAP au serveur Operis.
                 * @param {String} aNomFonction Le nom de la fonction de l'appel
                 * @param {Array<String>} aParametres Les paramètres de l'appel
                 * @param {Integer} aNbRetours Nombre de retour pour l'appel
                 * @param {fr.operis.moteurV4.communication.InformationsRequete} aInformations Informations sur l'appel
                 * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
                 * @param {String} aDisplay Indique le type d'affichage pour la mire d'attente.
                 * @return {ng.IPromise<T>} Une promesse :
                 *                       resolve : Array of String
                 *                       reject : Erreur
                 *                       notify : N/A
                 * @private
                 */
                function _appel(aNomFonction, aParametres, aNbRetours, aInformations, aTransaction, aDisplay) {
                    var deferred = $q.defer(),
                        promise = deferred.promise,
                        indice;

                    if (that.getDeleted() == true) {
                        deferred.reject(erreur.creer(SERVICE_SUPPRIME, this, _module));
                    } else {
                        // Mise en place des paramètres d'appel
                        var jeton = $window.Math.round($window.Math.random() * 1000000).toString(),
                            parametres = "";
                        parametres += "<xsd:string>" + jeton + "</xsd:string>";
                        parametres += "<xsd:string>" + aNomFonction + "</xsd:string>";
                        for (indice = 0; indice < aParametres.length; ++indice) {                            
                            parametres += "<xsd:string>" + (aParametres[indice] === null ? "" : aParametres[indice]) + "</xsd:string>";
                        }

                        // Mise en place de la transaction d'appel
                        var transaction = {nom: aNomFonction};
                        aTransaction = aTransaction || {};
                        aTransaction.arretSurErreur = aTransaction.arretSurErreur || false;
                        aTransaction.fin = aTransaction.fin || false;

                        if ((aTransaction.nom || "") !== "") {
                            // Construction de l'objet transaction s'il n'existe pas pour ce nom
                            var transactions = that.getTransactions();
                            transactions[aTransaction.nom] = transactions[aTransaction.nom] || {nom: aTransaction.nom};

                            if (aTransaction.fin === true) {
                                // Récupération de la transaction par recopie puis suppression de la transaction de la liste des transactions en cours
                                transaction = angular.copy(transactions[aTransaction.nom]);
                                delete transactions[aTransaction.nom];
                            } else {
                                transaction = transactions[aTransaction.nom];
                            }
                            
                            that.setTransactions(transactions);
                            // Définition du paramètre d'arrêt sur erreur pour la transation
                            parametres += "<xsd:string>" + conversion.versTexteDepuisBooleen(aTransaction.arretSurErreur) + "</xsd:string>";
                        } else {
                            aTransaction.fin = true;
                        }

                        // Mise en place des paramètres de la transaction
                        transaction.requetes = transaction.requetes || [];
                        transaction.requetes.push({
                            informations: aInformations,
                            jeton: jeton,
                            nomFonction: aNomFonction,
                            parametres: parametres,
                            arretTransactionSurErreur: aTransaction.arretSurErreur,
                            nbRetours: aNbRetours,
                            deferred: deferred,
                            promise: promise
                        });

                        // Appel au serveur si la transaction est terminée
                        if (aTransaction.fin === true) {
                            // Création de la promesse d'annulation
                            var annulation = $q.defer();

                            // Création de la requête HTTP
                            var requeteHttp = {
                                url: that.getURL(),
                                config: {
                                    headers: {
                                        'Content-Type': 'text/xml;charset=UTF-8'
                                        , SOAPAction: SOAP_ACTION
                                    }
                                    , cache: false
                                    , withCredentials: false
                                    , timeout: angular.extend(annulation.promise, {timeout: _timeoutHttp})
                                    , responseType: 'text/xml'
                                },
                                data: '',
                                reponse: {
                                    status: null,
                                    data: null
                                }
                            };

                            // Création du timeout pour la requête HTTP
                            var timeout = $timeout(function () {
                                // On rejete toutes les promesses de la transaction
                                for (indice = 0; indice < transaction.requetes.length; ++indice) {
                                    transaction.requetes[indice].deferred.reject(erreur.creer(REQUETE_TIMEOUT, that, transaction.requetes[indice].nomFonction, _timeoutHttp, requeteHttp));
                                    transaction.requetes[indice].deferred = {
                                        reject: angular.noop,
                                        resolve: angular.noop
                                    };
                                }
                                // On annule la requête
                                annulation.resolve();
                            }, _timeoutHttp);

                            // Parcours des requêtes de la transaction
                            parametres = "";
                            for (indice = 0; indice < transaction.requetes.length; ++indice) {
                                var requete = transaction.requetes[indice];
                                // Création de la méthode pour annuler la requête
                                requete.promise.timeout = _timeoutHttp;
                                requete.promise.annuler = (function (requete) {
                                    return function () {
                                        // On rejete la promesse de la requête
                                        requete.deferred.reject(erreur.creer(REQUETE_ANNULEE, that, requete.nomFonction, requeteHttp));
                                        requete.deferred = {reject: angular.noop, resolve: angular.noop};
                                        // On annule la transaction si la transaction ne contient que cette requête
                                        if (transaction.requetes.length === 1)
                                            annulation.resolve();
                                        // On nettoie la variable requete
                                        requete = null;
                                    };
                                })(requete);
                                // Ajout des paramètres de la requête
                                parametres += requete.parametres;
                                // Ajout de la requête à la mire d'attente.
                                opMireAttente.ajouterRequete(requete.promise, requete.informations, aDisplay);
                                // Trace de la requête
                                //$log.info('Transaction ' + transaction.nom + ' : ' + requete.informations.commentaire);
                            }
                            requeteHttp.data = MESSAGE_TEMPLATE.replace(/\{\{parametres\}\}/g, parametres);

                            // Requête HTTP
                            $http.post(requeteHttp.url, requeteHttp.data, requeteHttp.config)
                                .success(function (data, status, headers, config) {
                                    requeteHttp.reponse.status = status;
                                    requeteHttp.reponse.data = data;

                                    if (angular.isNullOrUndefined(data)) {
                                        // On rejete toutes les promesses de la transaction
                                        for (var indice = 0; indice < transaction.requetes.length; ++indice) {
                                            transaction.requetes[indice].deferred.reject(erreur.creer(RETOUR_VIDE, that, transaction.requetes[indice].nomFonction, requeteHttp));
                                        }
                                    } else {
                                        var retours;
                                        try {
                                            retours = xml.toJson(data).Envelope.Body.OperisWSFunctionResponse.OperisWSFunctionReturn || [];
                                        } catch (error) {
                                            // On rejete toutes les promesses de la transaction
                                            for (indice = 0; indice < transaction.requetes.length; ++indice) {
                                                transaction.requetes[indice].deferred.reject(erreur.creer(RETOUR_ILLISIBLE, that, transaction.requetes[indice].nomFonction, error, requeteHttp));
                                            }
                                            return;
                                        }
                                        if (retours.length < 4 || aNbRetours > retours.length) {
                                            // On rejete toutes les promesses de la transaction
                                            for (indice = 0; indice < transaction.requetes.length; ++indice) {
                                                transaction.requetes[indice].deferred.reject(erreur.creer(RETOUR_INVALIDE, that, transaction.requetes[indice].nomFonction, requeteHttp));
                                            }
                                        } else {
                                            // On analyse le retour de chacune des requetes de la transaction
                                            var indiceRetour = 0, arretTransaction = false;
                                            for (var indiceFonction = 0; indiceFonction < transaction.requetes.length; ++indiceFonction) {
                                                var fonction = transaction.requetes[indiceFonction];
                                                if (arretTransaction) {
                                                    // On rejete la promesse
                                                    fonction.deferred.reject(erreur.creer(TRANSACTION_AVORTEE, that, aTransaction.nom, requeteHttp));
                                                } else if ((retours[indiceRetour] || "").toString() !== fonction.jeton) {
                                                    // On rejete la promesse
                                                    fonction.deferred.reject(erreur.creer(JETON_INCOHERENT, that, fonction.nomFonction, fonction.jeton, requeteHttp));
                                                } else if ((retours[indiceRetour + 1] || "").toString() !== fonction.nomFonction) {
                                                    // On rejete la promesse
                                                    fonction.deferred.reject(erreur.creer(FONCTION_INCOHERENTE, that, fonction.nomFonction, requeteHttp));
                                                } else if (retours[indiceRetour + fonction.nbRetours - 2][ELEMENT_NUL] !== "true") {
                                                    arretTransaction = fonction.arretTransactionSurErreur;
                                                    // On rejete la promesse
                                                    fonction.deferred.reject(erreur.creer(ERREUR_SERVEUR, that, aNomFonction, retours[indiceRetour + fonction.nbRetours - 2].toString(), retours[indiceRetour + fonction.nbRetours - 1].toString(), requeteHttp));
                                                } else {
                                                    var valeurs = [];
                                                    for (var indice = 0; indice < fonction.nbRetours; ++indice) {
                                                        var element = retours[indiceRetour + indice];
                                                        if (element[ELEMENT_NUL] === "true") valeurs.push(null);
                                                        else valeurs.push(element.hasOwnProperty('toString') ? element.toString() : "");

                                                    }
                                                    fonction.deferred.resolve(valeurs);
                                                }
                                                indiceRetour += fonction.nbRetours;
                                            }
                                        }
                                    }
                                })
                                .error(function (data, status, headers, config) {
                                    requeteHttp.reponse.status = status;
                                    requeteHttp.reponse.data = data;

                                    // On rejete toutes les promesses de la transaction
                                    for (var indice = 0; indice < transaction.requetes.length; ++indice) {
                                        transaction.requetes[indice].deferred.reject(erreur.creer(status === 0 ? REQUETE_INVALIDE : ERREUR_INTERNE_SERVEUR,
                                            that, transaction.requetes[indice].nomFonction, status, data, requeteHttp));
                                    }
                                })
                                .finally(function () {
                                    // On trace en debug la requête
                                    //$log.debug(angular.toJson(requeteHttp));
                                    // Suppression du timeout
                                    $timeout.cancel(timeout);
                                    // On nettoie tout pour faciliter le travail du ramasse miette
                                    for (var indice = 0; indice < transaction.requetes.length; ++indice) {
                                        transaction.requetes[indice].deferred = null;
                                        transaction.requetes[indice] = null;
                                    }
                                    transaction.requetes = null;
                                    transaction = null;
                                    deferred = null;
                                    requeteHttp = null;
                                });
                        }
                    }

                    // On retourne la promesse
                    return deferred.promise;
                }

                /**
                 * Réalise un appel SOAP connecté au serveur Operis.
                 * @param {Object} aScope
                 * @param {String} aNomFonction Le nom de la fonction de l'appel
                 * @param {Array<String>} aParametres Les paramètres de l'appel
                 * @param {Integer} aNbRetours Nombre de retour pour l'appel
                 * @param {fr.operis.moteurV4.communication.InformationsRequete} aInformations Informations sur l'appel
                 * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
                 * @param {String} aDisplay Indique le type d'affichage pour la mire d'attente.
                 * @returns {ng.IPromise<T>}
                 * @private
                 */
                function _appelConnecte(aScope, aNomFonction, aParametres, aNbRetours, aInformations, aTransaction, aDisplay) {
                    var deferred = $q.defer();
                    that.connexion()
                        .then(function () {
                            aParametres[0] = that.getIdSession();
                            return _appel(aNomFonction, aParametres, aNbRetours, aInformations, aTransaction, aDisplay);
                        })
                        .then(function (resultat) {
                            deferred.resolve(resultat);
                        })
                        .catch(function (erreur) {
                            // La session est invalide, on relance l'appel en nettoyant la session
                            if (erreur.codeServeur === "01009") {
                                that.setIdSession(null);
                                _appelConnecte(aScope, aNomFonction, aParametres, aNbRetours, aInformations, aTransaction, aDisplay)
                                    .then(function (resultat) {
                                        deferred.resolve(resultat);
                                    })
                                    .catch(function (erreur) {
                                        deferred.reject(erreur);
                                    });
                            } else {
                                deferred.reject(erreur);
                            }
                        });
                    return deferred.promise;
                }

                this.requetes = [];

                /**
                 * Retourne la valeur du paramètre. Cherche dans les cookie si non initialisé.
                 * @param aVar La variable à récupérer
                 * @param aKey La clé dans les cookie de la variable à récupérer
                 * @returns {object} La valeur du paramètre.
                 */
                this.getCookieParam = function(aVar, aKey) {
                    if(aVar == null) {
						aVar = cookie.get(that.getNomModule() + "_" + aKey);
                        if(aVar == undefined)
                            aVar = null;
                    }
                    
                    return aVar;
                }
                
                /**
                 * Modifie la valeur du paramètre.
                 * @param aKey La clé dans les cookie de la variable à modifier
                 * @param aValue La valeur à renseigner.
                 * @returns {object} La valeur du paramètre.
                 */
                this.setCookieParam = function(aKey, aValue) {
					cookie.set(that.getNomModule() + "_" + aKey, !angular.isNullOrUndefined(aValue) ? aValue : "");
                    
                   return aValue;
                }
                
                /**
                 * Supprime la valeur du paramètre.
                 * @param aKey La clé dans les cookie de la variable à modifier
                 */
                this.removeCookieParam = function(aKey) {
					cookie.remove(that.getNomModule() + "_" + aKey);
                }

                /**
                 * Retourne la valeur du paramètre. Cherche dans Web Storage si non initialisé.
                 * @param aVar La variable à récupérer
                 * @param aKey La clé dans les cookie de la variable à récupérer
                 * @param aTypeStorage Type de Web Storage à utiliser
                 * @returns {object} La valeur du paramètre.
                 */
                this.getStorageParam = function(aVar, aKey, aTypeStorage) {
                    if(aVar !== null) {
                        if (!angular.isNullOrUndefined(aTypeStorage) && (aTypeStorage === "session")) {
                            aVar = sessionStorage.getItem(that.getNomModule() + "_" + aKey);
                        } else {
                            aVar = localStorage.getItem(that.getNomModule() + "_" + aKey);
                        }

                        if (!angular.isNullOrUndefined(aVar) && typeof aVar === 'string') {
                            try {
                                aVar = $window.atob(aVar);
                                aVar = JSON.parse(aVar);
                            } catch (e) {}
                        }

                        if (aVar == undefined)
                            aVar = null;
                    }

                    return aVar;
                }

                /**
                 * Modifie la valeur du paramètre.
                 * @param aKey La clé dans Web Storage de la variable à modifier
                 * @param aValue La valeur à renseigner.
                 * @param aTypeStorage Type de Web Storage à utiliser
                 * @returns {object} La valeur du paramètre.
                 */
                this.setStorageParam = function(aKey, aValue, aTypeStorage) {
                    var encodedVal = aValue;
                    if(!angular.isNullOrUndefined(aValue)) {
                        try {
                            encodedVal = JSON.stringify(encodedVal);
                            encodedVal = $window.btoa(encodedVal);
                        } catch (e) {}
                    }

                    if (!angular.isNullOrUndefined(aTypeStorage) && (aTypeStorage === "session")) {
                        sessionStorage.setItem(that.getNomModule() + "_" + aKey, !angular.isNullOrUndefined(encodedVal) ? encodedVal : "");
                    } else {
                        localStorage.setItem(that.getNomModule() + "_" + aKey, !angular.isNullOrUndefined(encodedVal) ? encodedVal : "");
                    }

                    return encodedVal;
                }

                /**
                 * Supprime la valeur du paramètre.
                 * @param aKey La clé dans Web Storage de la variable à supprimer
                 * @param aTypeStorage Type de Web Storage à utiliser
                 */
                this.removeStorageParam = function(aKey, aTypeStorage) {
                    if (!angular.isNullOrUndefined(aTypeStorage) && (aTypeStorage === "session")) {
                        sessionStorage.removeItem(that.getNomModule() + "_" + aKey);
                    } else {
                        localStorage.removeItem(that.getNomModule() + "_" + aKey);
                    }
                }
                
                /**
                 * Défini le nom du module.
                 * @param {string} aNomModule Le nom du module
                 */
                this.setNomModule = function (aNomModule) {
                    _module = aNomModule;
                };

                /**
                 * Retourne le nom du module actuel du service.
                 * @returns {string} Le nom du module actuel du service.
                 */
                this.getNomModule = function () {
                    return _module;
                };

                /**
                 * Défini le libellé du module.
                 * @param {string} aLibelleModule Le libellé du module
                 */
                this.setLibelleModule = function (aLibelleModule) {
                    _libelleModule = aLibelleModule;
                };

                /**
                 * Retourne le libellé du module actuel du service.
                 * @returns {string} Le libellé du module actuel du service.
                 */
                this.getLibelleModule = function () {
                    return _libelleModule;
                };

                /**
                 * Retourne l'identifiant du module actuel du service.
                 * @returns {string} L'identifiant du module actuel du service.
                 */
                this.getIdModule = function () {
                    _idModule = that.getCookieParam(_idModule, "id_module");
                    return _idModule;
                };

                /**
                 * Modifie l'identifiant du module actuel du service.
                 * @param aIdModule L'identifiant du module actuel du service.
                 */
                this.setIdModule = function (aIdModule) {
                    _idModule = that.setCookieParam("id_module", aIdModule);
                };

                /**
                 * Retourne l'identifiant de l'utilisateur actuel du service.
                 * @returns {string} L'identifiant de l'utilisateur actuel du service.
                 */
                this.getIdUtilisateur = function () {
                    _idUtilisateur = that.getCookieParam(_idUtilisateur, "id_user");
                    
                    if(isNaN(parseInt(_idUtilisateur)))
                        _idUtilisateur = null;
                    
                    return _idUtilisateur;
                };
                
                /**
                 * Modifie l'identifiant de l'utilisateur actuel du service.
                 * @param aId L'identifiant de l'utilisateur actuel du service.
                 */
                this.setIdUtilisateur = function (aId) {
                    _idUtilisateur = that.setCookieParam("id_user", aId);
                };

                /**
                 * Retourne l'utilisateur actuel du service.
                 * @returns {string} L'utilisateur actuel du service.
                 */
                this.getUtilisateur = function () {
                    _utilisateur = that.getCookieParam(_utilisateur, "user");
                    return _utilisateur;
                };

                /**
                 * Modifie l'utilisateur actuel du service.
                 * @param aUtil L'utilisateur actuel du service.
                 */
                this.setUtilisateur = function (aUtil) {
                    _utilisateur = that.setCookieParam("user", aUtil);
                };

                /**
                 * Retourne l'identifiant de session actuel du service.
                 * @returns {string} L'identifiant de session actuel du service.
                 */
                this.getIdSession = function () {
                    _idSession = that.getCookieParam(_idSession, "id_session");  
                    
                    if(_idSession == "")
                        _idSession = null;
                    
                    return _idSession;
                };

                /**
                 * Modifie l'identifiant de session actuel du service.
                 * @param aSession L'identifiant de session actuel du service.
                 */
                this.setIdSession = function (aSession) {
                    _idSession = that.setCookieParam("id_session", aSession);
                };

                /**
                 * Retourne les informations de l'utilisateur. N'est remplis que lors d'une connexion via portail.
                 * @returns {object} Les informations utilisateur.
                 */
                this.getUtilisateurInformation = function () {
                    if(_utilisateurInformation == null) {
                        var tempUserInfo = that.getCookieParam(_utilisateurInformation, "userinfo");
                        
                        if(!angular.isNullOrUndefined(tempUserInfo) && ((typeof tempUserInfo === 'string' && tempUserInfo.length > 0) || typeof tempUserInfo != 'string'))
                            _utilisateurInformation = typeof tempUserInfo === 'string' ? JSON.parse(tempUserInfo) : tempUserInfo;
                        else
                            _utilisateurInformation = that.utilisateurInformationVide()
                    }
                    return _utilisateurInformation;
                };

                /**
                 * Retourne les informations de l'utilisateur. N'est remplis que lors d'une connexion via portail.
                 * @returns {object} Les informations utilisateur.
                 */
                this.setUtilisateurInformation = function (utilisateurInformation) {
                    _utilisateurInformation = that.setCookieParam("userinfo", utilisateurInformation != null && typeof utilisateurInformation === 'string' ? JSON.parse(utilisateurInformation) : utilisateurInformation);
                };

                /**
                 * Retourne l'identifiant de l'utilisateur actuel du service.
                 * @returns {string} L'identifiant de l'utilisateur actuel du service.
                 */
                this.getIdentifiants = function () {
                    _identifiants = that.getCookieParam(_identifiants, "identifiants");
                    return _identifiants;
                };
                
                /**
                 * Modifie l'identifiant de l'utilisateur actuel du service.
                 * @param aId L'identifiant de l'utilisateur actuel du service.
                 */
                this.setIdentifiants = function (aIds) {
                    _identifiants = that.setCookieParam("identifiants", aIds);
                };

                /**
                 * Retourne les paramètres actuel du service.
                 * @returns {string} Les paramètres actuel du service.
                 */
                this.getParametre = function () {
                    if (!angular.isNullOrUndefined(typeof (Storage))) {
                        _parametre = that.getStorageParam(_parametre, "parametre", "session");
                    } else {
                        _parametre = that.getCookieParam(_parametre, "parametre");
                    }
                    return _parametre;
                };
                
                /**
                 * Modifie les paramètres actuel du service.
                 * @param aParams Les paramètres actuel du service.
                 */
                this.setParametre = function (aParams) {
                    if (!angular.isNullOrUndefined(typeof (Storage))) {
                        _parametre = that.setStorageParam("parametre", aParams, "session");
                    } else {
                        _parametre = that.setCookieParam("parametre", aParams);
                    }
                };

                /**
                 * Retourne les écrans actuel du service.
                 * @returns {string} Les écrans actuel du service.
                 */
                this.getCacheEcrans = function () {
                    _cacheEcrans = that.getCookieParam(_cacheEcrans, "cacheEcrans");
                    return _cacheEcrans;
                };
                
                /**
                 * Modifie les paramètres actuel du service.
                 * @param aParams Les paramètres actuel du service.
                 */
                this.setCacheEcrans = function (aCacheEcrans) {
                    _cacheEcrans = that.setCookieParam("cacheEcrans", aCacheEcrans);
                };

                /**
                 * Retourne l'état de suppression actuel du service.
                 * @returns {string} L'état de suppression actuel du service.
                 */
                this.getDeleted = function () {
                    _deleted = that.getCookieParam(_deleted, "deleted");
                    return _deleted;
                };
                
                /**
                 * Modifie l'état de suppression actuel du service.
                 * @param aParams L'état de suppression actuel du service.
                 */
                this.setDeleted = function (aDeleted) {
                    _deleted = that.setCookieParam("deleted", aDeleted);
                };

                /**
                 * Retourne les transactions actuel du service.
                 * @returns {string} Les transactions actuel du service.
                 */
                this.getTransactions = function () {
                    _transactions = that.getCookieParam(_transactions, "transactions");
                    return _transactions;
                };
                
                /**
                 * Modifie les transactions actuel du service.
                 * @param aTransactions Les transactions actuel du service.
                 */
                this.setTransactions = function (aTransactions) {
                    _transactions = that.setCookieParam("transactions", aTransactions);
                };
                
                /**
                 * Retourne l'URL actuelle du service.
                 * @returns {string} L'URL actuelle du service.
                 */
                this.getURL = function () {
                    return _url;
                };

                /**
                 * Place l'attribut _deleted à vrai.
                 */
                this.hasBeenDeleted = function () {
                    that.setDeleted(true);
                };

                /**
                 * Retourne la version du serveur Operis.
                 * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
                 * @returns {ng.IPromise<T>}
                 */
                this.version = function (aTransaction) {
                    var informations = {
                        libelle: "Version du serveur",
                        commentaire: "Lecture de la version du serveur pour l'application " + this.getLibelleModule(),
                        estModale: false
                    };
                    return _appel("version", [], 5, informations, aTransaction).then(
                        function (resultat) {
                            var index = 2;
                            return resultat[index++];
                        }
                    );
                };

                /**
                 * Réalise la récupération de la liste des portails de connexion via le serveur Operis.
                 * @returns {ng.IPromise<T>}
                 */
				this.portails = function () {
					var informations = {
                        libelle: "Description des portails de connexion disponibles du serveur",
                        commentaire: "Lecture des portails disponibles pour l'application " + this.getLibelleModule(),
                        estModale: false
                    };
                    return _appel("portails", [], 5, informations, null).then(
                        function (resultat) {
                            var index = 2;
							try{
                                var portails = JSON.parse(resultat[index++]);
                                for(var key in portails) {
                                    portails[key] = JSON.parse(portails[key]);
                                }
                                
								return portails;
							} catch(e) {
								return null;
							}
                        }
                    );
				}

                /**
                 * Réalise la récupération des informations d'un portail de connexion via le serveur Operis.
                 * @param {String} portail Le nom du portail.
                 * @param {String} callbackURL L'URL de callback qui sera utilisée par le portail.
                 * @param {String} callbackParams Les paramètres de callback qui seront placés dans le state.
                 * @returns {ng.IPromise<T>}
                 */
				this.descriptionPortail = function (portail, callbackURL, callbackParams) {
					var informations = {
                        libelle: "Description du portail de connexion " + portail,
                        commentaire: "Lecture du portail pour l'application " + this.getLibelleModule(),
                        estModale: false
                    };
                    return _appel("portail", [portail, "description_stricte", callbackURL, this.getNomModule(), callbackParams], 5, informations, null).then(
                        function (resultat) {
                            var index = 2;
                            return JSON.parse(resultat[index++]);
                        }
                    );
				}

                /**
                 * Valide la connexion au portail dans le but de récupérer une session framework.
                 * @param {String} portail Le nom du portail.
                 * @param {String} callbackURL L'URL de callback qui avait été utilisée à la première étape par le portail.
                 * @param {String} callbackParams Les paramètres de callback qui seront placés dans le state.
                 * @param {String} modules Le nom des modules attendu.
                 * @param {String} data Les données renvoyées par le portail permettant la validation de la connexion.
                 * @param {Boolean} reconnexion Force la reconnexion. Le serveur tentera une déconnexion avant de ce connecter.
                 * @param {Boolean} rapprochement La validation de connexion sert à rapprocher le compte Framework du compte Portail.
                 * @returns {ng.IPromise<T>}
                 */
				this.validateConnexionPortail = function (portail, callbackURL, callbackParams, data, reconnexion, rapprochement, nonce) {
					if(!angular.isDefined(reconnexion))
						reconnexion = true;
                    
                    var idUtilisateurAssociation = "";
                    if (rapprochement) {
                        idUtilisateurAssociation = this.getIdUtilisateur();
                    }
					
					var informations = {
                        libelle: "Validation de la connexion via un portail " + portail,
                        commentaire: "Validation de la connexion pour rapprochement de compte ou récupération de session pour le module " + this.getLibelleModule(),
                        estModale: false
                    };
                    return _appel(
                        "portail",
                        [
                            portail,
                            "connexion_stricte",
                            callbackURL,
                            reconnexion,
                            this.getNomModule(),
                            data,
                            callbackParams,
                            idUtilisateurAssociation,
							nonce
                        ],
                        5,
                        informations,
                        null
                    ).then(
                        function (resultat) {
                            var index = 2;
							var ret = JSON.parse(resultat[index++]);
							
                            that.setUtilisateur(ret.login);
                            that.setIdModule(ret.idModule);
                            that.setIdUtilisateur(ret.idUtilisateur);
                            that.setIdSession(ret.idSession);
                            that.setUtilisateurInformation(ret.userInfo);
							// Chargement des paramètres
							return that.chargementParametres()
								.then(function(resultat){
									return;
								});
                        }
                    );
				}
				
                /**
                 * Réalise la connexion au serveur Operis.
                 * Si l'utilisateur n'est pas fourni ou si la connexion n'est pas valide, l'interface de connexion s'affiche.
                 * @param {String} aUtilisateur Le nom de l'utilisateur pour la connexion. S'il est nul, on affiche la mire de connexion.
                 * @param {String} aMotdepasse Le mot de passe de l'utilisateur pour la connexion.
                 * @param {Erreur} aErreur L'erreur de connexion.
                 * @returns {ng.IPromise<T>}
                 */
                this.connexion = function (aUtilisateur, aMotdepasse, aErreur) {
                    var deferred = $q.defer();
                    if (that.getDeleted() == true) {
                        deferred.reject(erreur.creer(SERVICE_SUPPRIME, this, _module));
                    } else if (that.getIdSession() === null) {
                        if ((aUtilisateur || "").length > 0) {
                            // Si un utilisateur est fourni, on tente la connexion
                            aMotdepasse = encryption.encryptageMotDePasse(aUtilisateur, aMotdepasse);
                            var informations = {
                                libelle: "Connexion",
                                commentaire: "Connexion à l'application " + this.getLibelleModule()
                            };
                            _appel("Habil_Connexion", [aUtilisateur, aMotdepasse, _module], 7, informations)
                                .then(function (resultat) {
                                    var index = 2;
                                    
                                    that.setUtilisateur(aUtilisateur);
                                    that.setIdModule(resultat[index++]);
                                    that.setIdUtilisateur(resultat[index++]);
                                    that.setIdSession(resultat[index++]);
                                    that.setUtilisateurInformation(null);
                            
                                    // Chargement des paramètres
                                    that.chargementParametres()
                                        .then(function(resultat){
                                            deferred.resolve();
                                        })
                                        .catch(function (erreur) {
                                            deferred.reject(erreur);
                                        });
                                }.bind(this))
                                .catch(function (erreur) {
                                    if (_opConnexionPromise === null) {
                                        that.setUtilisateur(aUtilisateur);
                                        if ( true && erreur.codeServeur === "01007" ){
                                            // Connexion encore active et on est en reconnexion automatique
                                            // On lance donc une déconnexion suivi d'une connexion de façon automatique.
                                            that.deconnexion(aUtilisateur,aMotdepasse)
                                                .then(function() {
                                                    that.connexion(aUtilisateur, aMotdepasse)
                                                        .then(function () {
                                                            deferred.resolve();
                                                        })
                                                        .catch(function (erreur) {
                                                            deferred.reject(erreur);
                                                        })
                                                    })
                                                .catch(function (erreur) {
                                                    deferred.reject(erreur);
                                                })
                                        }else{
                                            that.connexion(null, null, erreur)
                                                .then(function () {
                                                    deferred.resolve();
                                                })
                                                .catch(function (erreur) {
                                                    deferred.reject(erreur);
                                                });
                                        }


                                    } else {
                                        deferred.reject(erreur);
                                    }
                                });
                        } else {
                            // Sinon on affiche la mire de connexion
                            // Récupération du login utilisateur si présent dans le html
                            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                                if ( key.toLowerCase() == "login" )
                                    that.setUtilisateur(value);
                            });
                            
                            // Si le cookie de déconnexion est renseigné
                            // et qu'il correspond aux paramètres d'URL
                            // on est en fin de déconnexion
                            var logoutState = cookie.get(LOGOUT_STATE);
                            var isLogout = logoutState && $window.location.search.contains("state=" + logoutState);
                            if (!isLogout && _opConnexionPromise === null) {
                                _opConnexionPromise = 'LOADING';
                                _opConnexionPromise = $modal.open({
                                    templateUrl: 'moteur/composants/OpConnexionPortail.html',
                                    controller: 'fr.operis.moteurV4.composants.OpConnexionPortailControleur',
                                    windowClass: 'operis-modal-portail',
                                    backdrop: false,
                                    keyboard: false,
                                    size: 'sm',
                                    resolve: {
                                        utilisateur: function () {
                                            return that.getUtilisateur();
                                        },
                                        erreurConnexion: function () {
                                            return aErreur;
                                        }
                                    }
                                }).result;
                                _opConnexionPromise.finally(function () {
                                    _opConnexionPromise = null;
                                });
                            } else if(isLogout) {
                                // Suppression du cookie
                                cookie.remove(LOGOUT_STATE);
								cookie.remove("nonce");
                            }
                            return _opConnexionPromise;
                        }
                    } else {
                        // S'il y a une session
                        // Si le cookie de rapprochement est renseigné
                        if (cookie.get("rapprochement") && _opConnexionPromise === null) {
                            _opConnexionPromise = 'LOADING';
                            _opConnexionPromise = $modal.open({
                                templateUrl: 'moteur/composants/OpConnexionPortail.html',
                                controller: 'fr.operis.moteurV4.composants.OpConnexionPortailControleur',
                                windowClass: 'operis-modal-portail',
                                backdrop: false,
                                keyboard: false,
                                size: 'sm',
                                resolve: {
                                    utilisateur: function () {
                                        return that.getUtilisateur();
                                    },
                                    erreurConnexion: function () {
                                        return aErreur;
                                    }
                                }
                            }).result;
                            _opConnexionPromise.finally(function () {
                                _opConnexionPromise = null;
                            });
                        } else {
                            // On ne fait rien
                            deferred.resolve();
                        }
                    }
                    return deferred.promise;
                };

                /**
                 * Réalise la déconnexion au serveur Operis.
                 * @param {String|null} aUtilisateur Le nom de l'utilisateur pour la déconnexion.
                 * @param {String|null} aMotdepasse Le mot de passe de l'utilisateur pour la déconnexion.
                 * @returns {ng.IPromise<T>}
                 */
                this.deconnexion = function (aUtilisateur, aMotdepasse) {
                    aUtilisateur = aUtilisateur || that.getUtilisateur();
                    aMotdepasse = encryption.encryptageMotDePasse(aUtilisateur, aMotdepasse);
                    var informations = {
                        libelle: "Déconnexion",
                        commentaire: "Déconnexion de l'application " + this.getLibelleModule()
                    };
                    var promise = _appel("Habil_Deconnexion", [aUtilisateur, aMotdepasse, _module, that.getIdSession()], 4, informations);
                    promise.then(function () {
                        that.setUtilisateur(null);
                        that.setIdModule(null);
                        that.setIdUtilisateur(null);
                        that.setIdSession(null);
                        
                        if(!(that.getUtilisateurInformation().session_id != null && !$config.$config.portail.auto)){
                            var application = $config.$config.libApplication[$window.APPLICATION] ? $config.$config.libApplication[$window.APPLICATION] : $window.APPLICATION;                
                            opPopupAlerte.creer("information", "Vous avez été déconnecté avec succès de l'application \"" + application + "\".", true, false, null, null, "Déconnexion");
                        }
                    });
                    return promise;
                };
                
                this.deconnexionPortail = function (aUserInfo) {
                    aUserInfo = aUserInfo || that.getUtilisateurInformation();
                    
                    if(aUserInfo == null) {
                        console.log("Pas d'information utilisateur pour la déconnexion du portail.")
                    }
                    
                    if(aUserInfo.url_deconnection == null) {
                        console.log("L'url de deconnexion au portail n'a pas été renseignée par le serveur.");
                    }
                    
                    if(aUserInfo.session_id == null) {
                        console.log("L'id session de portail n'a pas été renseigné par le serveur.")
                    }
					
					if ($config.$config.portail.auto != null && $config.$config.portail.auto )
						return;
                    
                    if(aUserInfo != null && aUserInfo.url_deconnection != null && aUserInfo.session_id != null) {
                        that.setUtilisateurInformation(null);
                        
                        // Il est préférable de donner une valeur entière dans les paramètres de l'URL comme le state
                        var state = Math.floor(Math.random() * 1000);
                        // Mise en place du cookie pour la déconnexion
                        cookie.set(LOGOUT_STATE, state);

                        aUserInfo.url_deconnection = aUserInfo.url_deconnection.replace("{session_id}", aUserInfo.session_id);
                        // Construction de l'URL d'origine du site vers laquelle on sera redirigé
                        var indexUrl = window.location.origin + window.location.pathname;

                        var new_url_deconnection = new URL(aUserInfo.url_deconnection);
                        new_url_deconnection.searchParams.set('state', state);
                        new_url_deconnection.searchParams.set('post_logout_redirect_uri', indexUrl);
                         window.location = new_url_deconnection;
                    }
                    ;
                }

                /**
                 * Réalise le chargement des paramètres.
                 *
                 * @returns {ng.IPromise<T>}
                 *
                 */
                this.chargementParametres = function(){
                    var deferred = $q.defer(),
                        parametres = [that.getIdSession(), ID_VUE_PARAMETRES, CHAMP_PARAMETRE_GRILLE + SEPARATEUR_NIVEAU_1 + "1"],
                        informations = {
                            libelle: "Chargement des paramètres",
                            commentaire: "Chargement des paramètres pour l'application " + this.getLibelleModule()
                        };


                    // Appel de l'écran formulaire
                    _appelConnecte( null, "Ges_GetEcranFormulaire", parametres, 10, informations, null)
                        .then(function (resultat) {
                            var index = 4,
                                ecranGrillesEntete = resultat[index++],
                                ecranGrillesValeur = resultat[index++];

                            // Remplissage du dictionnaire des parametres
                            that.setParametre(modele.remplirParametre(ecranGrillesEntete,ecranGrillesValeur));
                            deferred.resolve();
                        })
                        .catch(function (erreur) {
                            deferred.reject(erreur);
                        });

                    return deferred.promise;
                };

                /**
                 * Récupération d'un paramètre de l'application
                 * @param {String}  Catégorie du paramètre
                 * @param {String}  Code du paramètre
                 * @return La valeur du paramètre si défini ou null
                 *
                 */
                this.lireParametre = function(aCategorie, aCode){
                    var codes = that.getParametre()[aCategorie];
                    if ( !angular.isNullOrUndefined(codes))
                        return that.getParametre()[aCategorie][aCode];

                    return null;
                };

                /**
                 * Réalise le chargement d'un écran de données.
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {Object} aIdentifiants Nom et valeur des colonnes permettant d'identifier un élément unique de la vue.
                 * Si la valeur est omise, on prend la valeur utilisée lors d'un précédent appel à la fonction.
                 * Pour omettre la valeur, il suffit de mettre undefined comme valeur.
                 * Ex : chargement(scope, ecran);
                 * Ex : chargement(scope, ecran, undefined, transaction);
                 * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
                 * @param {String} aDisplay Indique le type d'affichage pour la mire d'attente.
                 * @returns {ng.IPromise<T>}
                 *
                 */
                this.chargement = function (aScope, aNomEcran, aIdentifiants, aTransaction, aDisplay) {
                    var that = this;

                    // Gestion des paramètres
                    if (aIdentifiants === undefined) {
                        aIdentifiants = that.getIdentifiants();
                    } else  {
                        aIdentifiants = angular.copy(aIdentifiants);
                        that.setIdentifiants(aIdentifiants);
                    }
                    var deferred = $q.defer(),
                        parametres = [that.getIdSession(), aNomEcran, _encoderIdentifiants(aIdentifiants)],
                        informations = {
                            libelle: "Chargement de l'écran " + aNomEcran,
                            commentaire: "Chargement de l'écran " + aNomEcran + " pour l'application " + this.getLibelleModule()
                        };
                    // Indication de la présence des en-têtes en cache
                    if (!angular.isNullOrUndefined(that.getCacheEcrans()[aNomEcran])) {
                        parametres[1] = parametres[1];// TODO: implémenter le serveur + "@onlydata";
                    }

                    if ( requeteMultiAppel.getMultiAppel() ){
                        var functionRetour = function (resultat){
                            return that.callbackChargement(aScope, aNomEcran, aIdentifiants, resultat, aDisplay);
                        };
                        requeteMultiAppel.add(_module, "Ges_GetEcranFormulaire", parametres, 10, informations, angular.copy(aTransaction), functionRetour);
                        deferred.resolve();
                    }else{
                        // Appel de l'écran formulaire
                        _appelConnecte(aScope, "Ges_GetEcranFormulaire", parametres, 10, informations, angular.copy(aTransaction), aDisplay)
                            .then(function (resultat) {
                                deferred.resolve(that.callbackChargement(aScope, aNomEcran, aIdentifiants, resultat, aDisplay));
                            })
                            .catch(function (erreur) {
                                deferred.reject(erreur);
                            });
                    }



                    return deferred.promise;
                };

                /**
                 * Réalise le traitement d'un retour de chargement d'un écran de données.
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {Object} aIdentifiants Nom et valeur des colonnes permettant d'identifier un élément unique de la vue.
                 * @returns {resultat}
                 *
                 */
                this.callbackChargement = function(aScope, aNomEcran, aIdentifiants, aResultat, aDisplay){

                    // Traitement du retour de l'écran formulaire
                    var index = 2,
                        ecranChampsEntete = aResultat[index++],
                        ecranChampsValeur = aResultat[index++],
                        ecranGrillesEntete = aResultat[index++],
                        ecranGrillesValeur = aResultat[index++],
                        ecranListesEntete = aResultat[index++],
                        ecranListesValeur = aResultat[index++];

                    // Gestion du cache des en-têtes et des listes
                    var cacheEcran = that.getCacheEcrans();
                    cacheEcran[aNomEcran] = cacheEcran[aNomEcran] || {
                            ecranChampsEntete: ecranChampsEntete,
                            ecranGrillesEntete: ecranGrillesEntete,
                            ecranListesEntete: ecranListesEntete,
                            ecranListesValeur: ecranListesValeur
                        };
                    that.setCacheEcrans(cacheEcran);
                    ecranChampsEntete = cacheEcran[aNomEcran].ecranChampsEntete;
                    ecranGrillesEntete = cacheEcran[aNomEcran].ecranGrillesEntete;
                    ecranListesEntete = cacheEcran[aNomEcran].ecranListesEntete;
                    ecranListesValeur = cacheEcran[aNomEcran].ecranListesValeur;

                    if (!angular.isNullOrUndefined(aScope)) {
                        // Remplissage du scope
                        var tablesValeurs = modele.remplirModele(aScope, ecranChampsEntete, ecranChampsValeur, SCOPE_CHAMPS),
                            tablesGrilles = modele.remplirModele(aScope, ecranGrillesEntete, ecranGrillesValeur, SCOPE_GRILLES),
                            tablesListes = modele.remplirModele(aScope, ecranListesEntete, ecranListesValeur, SCOPE_LISTES);

                        // Ajout de la fonction de comparaison du modèle
                        aScope.opComparaison = modele.comparateur;
                        aScope.opComparaisonStricte = modele.comparateurStrict;

                        if (aIdentifiants === null) {
                            // Ajout de la fonction de recherche
                            aScope.recherche = aScope.recherche || {};
                            // Mantis Operia 6195 : Permettre un tri personnalisé lors des recherches
                            aScope.recherche[aNomEcran] = function (aTaillePage, aTri) {
                                if (!angular.isNullOrUndefined(aTri)) {
                                    return that.recherche(aScope, aNomEcran, tablesValeurs, aTaillePage, 1, null, aTri);
                                } else {
                                    return that.recherche(aScope, aNomEcran, tablesValeurs, aTaillePage, 1);
                                }
                            };
                        }

                        // Ajout de la fonction de sauvegarde
                        aScope.sauvegarde = aScope.sauvegarde || {};
                        aScope.sauvegarde[aNomEcran] = function () {
                            return that.sauvegarde(aScope, aNomEcran, aIdentifiants, tablesValeurs, tablesGrilles, aDisplay);
                        };

                        // Ajout de la fonction pour calculer le hash md5 des données
                        aScope.md5 = aScope.md5 || {};
                        aScope.md5[aNomEcran] = function () {
                            return md5.convertir(modele.recupererValeurs(aScope, tablesValeurs, SCOPE_CHAMPS, aIdentifiants) + '/' +
                                modele.recupererValeurs(aScope, tablesGrilles, SCOPE_GRILLES, aIdentifiants));
                        };

                        // Ajout de la fonction pour détecter une modification
                        var md5Initial = aScope.md5[aNomEcran]();
                        aScope.aucuneModification = aScope.aucuneModification || {};
                        aScope.aucuneModification[aNomEcran] = function () {
                            return md5Initial === aScope.md5[aNomEcran]();
                        };
                    } else {
                        $log.warn("Le scope est nul. Aucune donnée est donc retournée.");
                    }

                    var retourChargement = [];
                    retourChargement.push(tablesValeurs);
                    retourChargement.push(tablesGrilles);
                    retourChargement.push(tablesListes);
                    return retourChargement;
                };

                /**
                 * Réalise la recherche sur un écran de données.
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aTablesValeurs Les tables associées à l'écran
                 * @param {type} aTaillePage La taille de la page de données à remonter
                 * @param {type} aNumeroPage Le numéro de la page de données à remonter
                 * @param {Object} aCritere [Optionnal] Les critères de recherche
                 * @param {Object} aTri [Optionnal] Tri personnalisé (remplace le tri paramétré dans la description de la table) [Mantis Operia 6195]
                 * @returns {ng.IPromise<T>}
                 */
                this.recherche = function (aScope, aNomEcran, aTablesValeurs, aTaillePage, aNumeroPage, aCritere, aTri) {
                    var deferred = $q.defer();
                    var aTaillePage = aTaillePage;

                    if(angular.isNullOrUndefined(aCritere))
                        aCritere = "";

                    // Filtre automatique sur les services gestionnaires en ecriture
                    habilitation.setCritereHabilitation(aScope);

                    var enTetes = modele.recupererDescriptions(aScope, aTablesValeurs, aTri), // Mantis Operia 6195
                        criteres = modele.recupererValeurs(aScope, aTablesValeurs, SCOPE_CHAMPS, aCritere),
                        parametres = [that.getIdSession(), aNomEcran, enTetes, criteres, aTaillePage, aNumeroPage],
                        informations = {
                            libelle: "Recherche sur l'écran " + aNomEcran,
                            commentaire: "Recherche sur l'écran " + aNomEcran + " pour l'application " + this.getLibelleModule()
                        };

                    _appelConnecte(aScope, "Ges_GetEcranRecherche", parametres, 7, informations)
                        .then(function (resultat) {
                            var index = 2,
                                ecranGrillesEntete = resultat[index++],
                                ecranGrillesValeur = resultat[index++],
                                tailleTotale = conversion.versEntier(resultat[index++]);

                            var tablesGrilles= modele.remplirModele(aScope, ecranGrillesEntete, ecranGrillesValeur, SCOPE_GRILLES);

                            aScope.recherchePageSuivante = aScope.recherchePageSuivante || {};
                            if (tailleTotale > aTaillePage * aNumeroPage) {
                                aScope.recherchePageSuivante[aNomEcran] = function () {
                                    return that.recherche(aScope, aNomEcran, aTablesValeurs, aTaillePage, aNumeroPage + 1, aCritere, aTri); // Mantis Operia 6195
                                };
                            } else {
                                delete aScope.recherchePageSuivante[aNomEcran];
                            }

                            aScope.recherchePagePrecedente = aScope.recherchePagePrecedente || {};
                            if (aNumeroPage > 1) {
                                aScope.recherchePagePrecedente[aNomEcran] = function () {
                                    return that.recherche(aScope, aNomEcran, aTablesValeurs, aTaillePage, aNumeroPage - 1, aCritere, aTri); // Mantis Operia 6195
                                };
                            } else {
                                delete aScope.recherchePagePrecedente[aNomEcran];
                            }

                            aScope.recherchePage = aScope.recherchePage || {};
                            aScope.recherchePage[aNomEcran] = function (aNumeroPage) {
                                return that.recherche(aScope, aNomEcran, aTablesValeurs, aTaillePage, aNumeroPage, aCritere, aTri); // Mantis Operia 6195
                            };

                            var retourRecherche = [];
                            retourRecherche.push(tablesGrilles);
                            retourRecherche.push(tailleTotale);
                            retourRecherche.push(aNumeroPage);
                            deferred.resolve(retourRecherche);
                        })
                        .catch(function (erreur) {
                            deferred.reject(erreur);
                        });
                    return deferred.promise;
                };

                /**
                 * Réalise la sauvegarde sur un écran de données.
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aIdentifiants Les identifiants associés à l'écran
                 * @param {type} aTablesValeurs Les tables associées à l'écran
                 * @param {type} aTablesGrilles Les tables de grilles associées à l'écran
                 * @param {String} aDisplay Indique le type d'affichage pour la mire d'attente.
                 * @returns {ng.IPromise<T>}
                 */
                this.sauvegarde = function (aScope, aNomEcran, aIdentifiants, aTablesValeurs, aTablesGrilles, aDisplay) {
                    var that = this;
                    var deferred = $q.defer();
                    var valeurs = modele.recupererValeurs(aScope, aTablesValeurs, SCOPE_CHAMPS, aIdentifiants),
                        grilles = modele.recupererValeurs(aScope, aTablesGrilles, SCOPE_GRILLES, aIdentifiants),
                        md5Initial = aScope.md5[aNomEcran](),
                        parametres = [that.getIdSession(), aNomEcran, _encoderIdentifiants(aIdentifiants), valeurs, grilles],
                        informations = {
                            libelle: "Sauvegarde de l'écran " + aNomEcran,
                            commentaire: "Sauvegarde de l'écran " + aNomEcran + " pour l'application " + this.getLibelleModule()
                        };

                    /*if ( requeteMultiAppel.getMultiAppel() ){
                        var functionRetour = function (resultat){
                            return that.callbackSauvegarde(aScope, aNomEcran, aIdentifiants, resultat);
                        };
                        requeteMultiAppel.add(_module, "Ges_SetEcranFormulaire", parametres, 5, informations, angular.copy(aTransaction), functionRetour);
                        deferred.resolve();
                    }else{*/
                        _appelConnecte(aScope, "Ges_SetEcranFormulaire", parametres, 5, informations, null, aDisplay)
                            .then(function (resultat) {
                                deferred.resolve(angular.copy(that.callbackSauvegarde(aScope, aNomEcran, aIdentifiants, resultat)));
                            })
                            .catch(function (erreur) {
                                deferred.reject(erreur);
                            });
                    /*}*/


                    return deferred.promise;
                };

                /**
                 * Réalise le traitement d'un retour de chargement d'un écran de données.
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {Object} aIdentifiants Nom et valeur des colonnes permettant d'identifier un élément unique de la vue.
                 * @returns {resultat}
                 *
                 */
                this.callbackSauvegarde = function(aScope, aNomEcran, aIdentifiants, aResultat){

                    var index = 2;
                    // Mise à jour de l'identifiant courant
                    that.setIdentifiants(_decoderIdentifiants(aResultat[index++]));
                    // Mise à jour de la fonction de détection de modification
                    aScope.aucuneModification[aNomEcran] = function () {
                        return md5Initial === aScope.md5[aNomEcran]();
                    };

                    return that.getIdentifiants();
                };

                /**
                 * Réalise la suppression sur un écran de données.
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aIdentifiants Les identifiants associés à l'écran
                 * @returns {ng.IPromise<T>}
                 */
                this.suppression = function (aScope, aNomEcran, aIdentifiants) {
                    var deferred = $q.defer();
                    var parametres = [that.getIdSession(), aNomEcran, _encoderIdentifiants(aIdentifiants)],
                        informations = {
                            libelle: "Suppression de l'écran " + aNomEcran,
                            commentaire: "Suppression de l'écran " + aNomEcran + " pour l'application " + this.getLibelleModule()
                        };
                    _appelConnecte(aScope, "Ges_SupprEcranFormulaire", parametres, 4, informations)
                        .then(function (resultat) {
                            var index = 2;
                            // Mise à jour de l'identifiant courant
                            that.setIdentifiants(_decoderIdentifiants(resultat[index++]));
                            deferred.resolve(angular.copy(that.getIdentifiants()));
                        })
                        .catch(function (erreur) {
                            deferred.reject(erreur);
                        });
                    return deferred.promise;
                };

                /**
                 * Réalise la sauvegarde de fichier
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomEcran Identifiant de l'écran base de données à charger
                 * @param {type} aIdentifiants Les identifiants associés à l'écran
                 * @returns {ng.IPromise<T>}
                 */
                this.sauvegardeFichier = function (aScope, aFichier){
                    var deferred = $q.defer();
                    var typeFile = aFichier.file.type; // Problématique des types de fichier non reconne
                    if ( typeFile == '' ){
                        var pos = aFichier.file.name.lastIndexOf(".");
                        typeFile = aFichier.file.name.slice(pos + 1);
                    }
                    var parametres = [that.getIdSession(), ID_VUE_FICHIER, CHAMP_ID_FICHIER + SEPARATEUR_NIVEAU_1 + "",
                                        "" + SEPARATEUR_NIVEAU_1 + this.codeSepBDSaisieClient(aFichier.file.name) + SEPARATEUR_NIVEAU_1 + typeFile + SEPARATEUR_NIVEAU_1 + aFichier.valeurFichier
                                            + SEPARATEUR_NIVEAU_1 + aFichier.tableAss + SEPARATEUR_NIVEAU_1 + aFichier.idAction + SEPARATEUR_NIVEAU_1 + aFichier.idModule + SEPARATEUR_NIVEAU_1 + aFichier.idEntite, ""],
                        informations = {
                            libelle: "Sauvegarde de fichier",
                            commentaire: "Sauvegarde de fichier pour l'application " + this.getLibelleModule()
                        };
                    _appelConnecte(aScope, "Ges_SetEcranFormulaire", parametres, 5, informations)
                        .then(function (resultat) {
                            var index = 2;
                            // Mise à jour de l'identifiant courant
                            that.setIdentifiants(_decoderIdentifiants(resultat[index++]));
                            deferred.resolve(angular.copy(that.getIdentifiants()));
                        })
                        .catch(function (erreur) {
                            deferred.reject(erreur);
                        });
                    return deferred.promise;
                };



                /**
                 * Appel d'une procédure stockée
                 *
                 * @param {Object} aScope Scope dans lequel les données sont à charger.
                 * @param {String} aNomFonction Nom de fonction
                 * @param {String} aParam1 Parametre1
                 * @param {String} aParam2 Parametre2
                 * @param {String} aParam3 Parametre3
                 * @param {String} aParam4 Parametre4
                 * @returns {ng.IPromise<T>}
                 */
                this.procedureInterne = function (aScope, aNomFonction, aParam1, aParam2, aParam3, aParam4) {
                    var deferred = $q.defer();

                    if (angular.isNullOrUndefined( aParam3))
                        aParam3 = '';

                    if (angular.isNullOrUndefined( aParam4))
                        aParam4 = '';

                    var parametres = [that.getIdSession(), aParam1, aParam2, aParam3, aParam4],
                        informations = {
                            libelle: "Appel de procédure stockée " + aNomFonction,
                            commentaire: "Appel de procédure stockée " + aNomFonction + " pour l'application " + this.getLibelleModule()
                        };
                    _appelConnecte(aScope, aNomFonction, parametres, 8, informations)
                        .then(function (resultat) {
                            deferred.resolve(resultat.slice(2,6));
                        })
                        .catch(function (erreur) {
                            deferred.reject(erreur);
                        });
                    return deferred.promise;
                };

                /**
                 * Supprime l'instance de l'objet du cache.
                 */
                this.supprimer = function () {
                    delete _operisWSServers[_module];
                    that.hasBeenDeleted();
                    this.deconnexion();
                };

                requeteMultiAppel._multiAppelServeur = function (aNomFonction, aParametres, aNbRetours, aInformations, aTransaction){
                    return _appel(aNomFonction, aParametres, aNbRetours, aInformations, aTransaction);
                };

                /**
                 * Retourne l'état de multi appel
                 */
               this.getMultiAppel = function(){
                    return requeteMultiAppel.getMultiAppel();
                };

                /**
                 * Défini l'état du multi appel.
                 * @param {Object} aScope Scope dans lequel les données sont à traiter.
                 * @param {string} aMultiAppel L'état du multi appel
                 */
               this.setMultiAppel = function(aScope, aMultiAppel){
                    return requeteMultiAppel.setMultiAppel(aScope, aMultiAppel);
                };

                /**
                 * Converti caractéres spéciaux du moteur
                 * @param {string} valeur Valeur à convertir
                 */
               this.codeSepBDSaisieClient = function(valeur) {
                    if (valeur != null) {
                        var ret = valeur;
                        ret = ret.replace(new RegExp(SEPARATEUR_NIVEAU_1, "g"), SEPARATEUR_NIVEAU_1_SAISIE_CLIENT);
                        ret = ret.replace(new RegExp(SEPARATEUR_NIVEAU_2, "g"), SEPARATEUR_NIVEAU_2_SAISIE_CLIENT);
                        ret = ret.replace(new RegExp(SEPARATEUR_NIVEAU_3, "g"), SEPARATEUR_NIVEAU_3_SAISIE_CLIENT);
                        return ret;
                    } else {
                        return null;
                    }
                }
                
                this.utilisateurInformationVide = function () {
                    return {
                        ent_denomination: null,
                        ent_raisonsocial: null,
                        ent_siret: null,
                        ent_type: null,
                        util_adresse: null,
                        util_adresse_divterr: null,
                        util_adresse_pays: null,
                        util_fixe: null,
                        util_fixe_indicatif: null,
                        util_id: null,
                        util_mel: null,
                        util_mobile: null,
                        util_naissance_date: null,
                        util_naissance_dep: null,
                        util_naissance_lieu: null,
                        util_naissance_pays: null,
                        util_nom: null,
                        util_prenom: null,
                        util_type: null,
                        original: null
                    };
                }
            }
        }]);
}());