/**
 * Created by bruneton on 14/04/15.
 */

'use strict';

angular.module('fr.operis.moteurV4.directives.OpGrille', ['fr.operis.moteurV4.composants.cartographie.OpPopupFeature'])
    .directive('opGrille',
    ['$ocLazyLoad', '$compile', '$modal',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.Representation',
        'fr.operis.moteurV4.modele.Modele.SCOPE_NOM_GROUPE',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.operia.services.modulesActifsService',
        function ($ocLazyLoad, $compile, $modal, conversion, representationService, SCOPE_NOM_GROUPE, serveur, modulesActifsService) {
            return {
                restrict: 'E',
                scope: {
                    opModule: '@',
                    opEntite: '@',
                    opTrigramme: '@',
                    opDescription: '=',
                    opDonnees: '=',
					opListe:'=',
                    opEtat:'@',
                    opDetail: '=',
                    opSelection: '=',
                    opMultiSelect:'@',
                    opFiltre: '=',
                    opCategorie: '@',
                    opShowGridFooter: '@',
                    opEnablePagination: '@',
                    opIsGrilleformulaire:'@',
                    opSectionDroiteVisible: '=?',
                    opDesactiverTousSelectionner: '@',
                    opDerniereLigneSelect: '=?',
                    opControlerCsv: '=',
                    opDemandeDetailEntite: '=',
                    opVerticalScroll: '=?', // Mantis 6055 Gérer attribut scroll vertical
                    triRecherche: '&onTriRecherche' // Mantis Operia 6195 : Gestion tri personnalisé
                },
                link: function (scope, element, attributes) {
                    $ocLazyLoad.load({
                        serie: true, files: [
                            'css/ui-grid.min.css',
                            'lib/ui-grid.min.js'
                        ]
                    }).then(function () {

					var columnDefs;
                    var titreLigne = "";

                    /**
                     * Déterminer la représentation en fonction du statut
                     * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
                     * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
                     * @returns {String}
                     */
                    scope.getRepresentationStatutGrille = function(type, valeur) {
                        var rep = {'icon': ''};

                        if ((!angular.isNullOrUndefined(type)) && (!angular.isNullOrUndefined(valeur))) {
                            rep = representationService.getRepresentation('mosaique', type, valeur);
                        };

                        // Retourner la représentation selon le statut
                        return rep.icon;
                    };

                    /**
                     * Déterminer le style de représentation en fonction du statut
                     * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
                     * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
                     * @returns {Object}
                     */
                    scope.getRepresentationStyleGrille = function(type,valeur){
                        return representationService.getRepresentationStyle( type, valeur);
                    };

                    /**
                     * Déterminer le label d'un élément de type liste
                     * @param {valeur} valeur
                     * @param {String} liste
                     * @returns {String}
                     */
                    scope.getListeLabel = function(valeur,liste){
                        if ( angular.isNullOrUndefined( valeur ) || angular.isNullOrUndefined( liste ) )
                            return;

                        var descriptionListe = liste.split('|');
                        if ( angular.isNullOrUndefined (descriptionListe) || descriptionListe.length < 2 )
                            return;
                        var nomListe = descriptionListe[0];
                        descriptionListe = descriptionListe[1].split('#');
                        if ( angular.isNullOrUndefined (descriptionListe) || descriptionListe.length < 2 )
                            return;

                        var nomLabel = descriptionListe[0];
                        var nomValeur = descriptionListe[1];
						if ( !angular.isNullOrUndefined(this.$parent.opListe)){
							 var element = this.$parent.opListe[nomListe].find(function(item){
												return ( item[nomValeur].valeur === valeur);
											});

											if ( !angular.isNullOrUndefined(element))
												return element[nomLabel].valeur;
							}
						else{
                        if (!angular.isNullOrUndefined(this.$parent.$parent.listes) && !angular.isNullOrUndefined(this.$parent.$parent.listes[nomListe])){
                            var element = this.$parent.$parent.listes[nomListe].find(function(item){
                                return ( item[nomValeur].valeur === valeur);
                            });

                            if ( !angular.isNullOrUndefined(element))
                                return element[nomLabel].valeur;
                        }}
                    };


                    scope.grille = {
                        enableRowHeaderSelection: false,
                        enableFullRowSelection : true,
                        multiSelect: angular.isNullOrUndefined(scope.opMultiSelect)?true: scope.$eval(scope.opMultiSelect)
                    };

                    if (!angular.isNullOrUndefined(scope.opShowGridFooter))
                        scope.grille.showGridFooter = scope.opShowGridFooter;

                    var calcGridHeight = '(grille.data.length*30)+33';
                    var heightFooter = (scope.grille.showGridFooter)?32:0;
                    var heightHorizontalScrollbar = 33; // Mantis 6055 Activer scroll horizontal

                    // Gestion de la pagination
                    if (!angular.isNullOrUndefined(scope.opEnablePagination)) {
                        var lignesParPage = 10;

                        if (!isNaN(Number(scope.opEnablePagination))) {
                            lignesParPage = scope.opEnablePagination;
                        }

                        scope.grille.enablePaginationControls = scope.opEnablePagination;

                        if (scope.opEnablePagination) {
                            scope.grille.paginationPageSizes = [lignesParPage, lignesParPage*2, lignesParPage*5, lignesParPage*10];
                            scope.grille.paginationPageSize = lignesParPage;
                            calcGridHeight = '(grille.paginationPageSize*30)+32';
                        }
                    } else {
                        scope.grille.enablePaginationControls = false;
                    }

                    // Activer/désactiver tous sélectionner
                    if ('opDesactiverTousSelectionner' in attributes) {
                        scope.grille.enableSelectAll = false;
                    }

                    // Fonction de filtre
                    scope.grilleFilter = function(renderableRows) {
                        // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
                        var value = scope.opFiltre ? scope.opFiltre.toLowerCase() : "";
                        value = value.replace(/[àáâäæ]/g, "a").replace(/[éèêë]/g, "e").replace(/[ç]/g, "c").replace(/[îï]/g, "i").replace(/[ôö]/g, "o").replace(/[ùû]/g, "u");
                        var matcher = new RegExp(value);
                        renderableRows.forEach(function(row) {
                            var match = false;
                            scope.grille.columnDefs.forEach(function(field) {
                                var fieldEntity = [];

                                if (!angular.isNullOrUndefined(field.field)) {
                                    fieldEntity = field.field.split('.');
                                } else {
                                    fieldEntity.push(field.name);
                                }

                                if (!angular.isNullOrUndefined(row.entity[fieldEntity[0]])){
                                    var valeur = row.entity[fieldEntity[0]].valeur;
                                    var description = row.entity[fieldEntity[0]].descriptions;
                                    valeur = conversion.versTexte(valeur,description.type).toLowerCase();
                                    valeur = valeur.replace(/[àáâäæ]/g, "a").replace(/[éèêë]/g, "e").replace(/[ç]/g, "c").replace(/[îï]/g, "i").replace(/[ôö]/g, "o").replace(/[ùû]/g, "u");
                                    if (valeur.match(matcher)) {
                                        match = true;
                                    }
                                }


                            });
                            if (!match) {
                                row.visible = false;
                            }
                        });
                        return renderableRows;
                    };

                    // Gestion de la sélection
                    scope.grille.onRegisterApi = function(gridApi) {
                        //set gridApi on scope
                        scope.gridApi = gridApi;

                        if (!angular.isNullOrUndefined(gridApi.selection)){
                            gridApi.selection.on.rowSelectionChanged(scope, function(row) {
                                if ( row.entity.DROITACCES<=0){
                                    row.isSelected =false;
                                    return;
                                }

                                if (scope.grille.multiSelect) {
                                    var index = scope.opSelection.indexOf(row.entity);
                                    if (row.isSelected) {
                                        if (index === -1)
                                            scope.opSelection.push(row.entity);

                                        if ('opDerniereLigneSelect' in attributes) {
                                            scope.opDerniereLigneSelect = row.entity;
                                        }
                                    } else {
                                        if (index !== -1) {
                                            scope.opSelection.splice(index, 1);
                                        }
                                    }
                                } else {
                                    scope.opSelection.splice(0, 1);

                                    if (row.isSelected) {
                                        scope.opSelection.push(row.entity)
                                    }
                                }
                            });

                            gridApi.selection.on.rowSelectionChangedBatch(scope, function(rows) {
                                var msg = 'rows changed ' + rows.length;
                                console.log(msg);
                            });
                        }

                        gridApi.grid.registerRowsProcessor(scope.grilleFilter, 200);

                        gridApi.core.on.rowsRendered( scope, function(rows) {
                            if ( !angular.isNullOrUndefined(scope.gridApi.selection) )
                                scope.selectionItems();
                        });
						
                        // Mantis Operia 6195 : Gestion tri personnalisé
                        if ('onTriRecherche' in attributes) {
                            gridApi.core.on.sortChanged(scope, function (grid, sortColumns) {
                                // Récup nouveau tri
                                var tri = [];
                                for (var i = 0; i < sortColumns.length; i++) {
                                    var nomColonne = sortColumns[i].field.split('.')[0];
                                    if (sortColumns[i].sort.direction === "desc") {
                                        tri.push({colonne: nomColonne, tri: 0 - sortColumns[i].sort.priority});
                                    } else {
                                        tri.push({colonne: nomColonne, tri: sortColumns[i].sort.priority});
                                    }
                                }

                                // Actualisation de la recherche selon le nouveau tri
                                scope.triRecherche()(tri);
                            });
                        }						
                    };

                    scope.$watchCollection('opSelection', function(newValue,oldValue) {
                        if ( !angular.isNullOrUndefined(scope.gridApi) && !angular.isNullOrUndefined(scope.gridApi.selection) ){
                            scope.selectionItems();
                        }
                    });

                    /**
                     * Conversion données des colonnes vers texte
                     */
                    if (angular.isNullOrUndefined(scope.convertirVersTexte)) {
                        scope.convertirVersTexte = function (colonne) {
                            if (!angular.isNullOrUndefined(colonne)) {
                                if (colonne.descriptions.typeInteraction === "CHECKBOX") {
                                    if (colonne.valeur == "1") {
                                        return 'Oui';
                                    } else {
                                        return 'Non';
                                    }
                                } else {
                                    return conversion.versTexte(colonne.valeur, colonne.descriptions.type);
                                }
                            }
                        }
                    }

                    /**
                     * Titre de la ligne à partir du champ principal
                     */
                    if (angular.isNullOrUndefined(scope.getTitreLigne)) {
                        scope.getTitreLigne = function (ligne, nomColonne) {
                            return scope.convertirVersTexte(ligne[nomColonne]);
                        }
                    }

                    /**
                     * Fonction de tri selon l'attribut descriptions.ordre
                     */
                    if (angular.isNullOrUndefined(scope.sortColonnes)) {
                        scope.sortColonnes = function (a, b) {
                            if (angular.isNullOrUndefined(a.descriptions)) {
                                return -1;
                            }

                            if (angular.isNullOrUndefined(b.descriptions)) {
                                return 1;
                            }

                            if (a.descriptions.ordre < b.descriptions.ordre) {
                                return -1;
                            }
                            else {
                                return 1;
                            }
                        };
                    }

                    /**
                     * Trier les colonnes de la ligne (affichage accordion pour mobile)
                     */
                    if (angular.isNullOrUndefined(scope.triColonnesLigne)) {
                        scope.triColonnesLigne = function (ligne) {
                            var colonnes = [];

                            for (var champ in ligne) {
                                if (ligne.hasOwnProperty(champ)) {
                                    colonnes.push(ligne[champ]);
                                }
                            }

                            colonnes = colonnes.sort(scope.sortColonnes);
                            return colonnes;
                        }
                    }

                    scope.selectionItems = function(){
                        if (!angular.isNullOrUndefined(scope.gridApi.selection)) {
                            scope.gridApi.selection.clearSelectedRows();

                            if (!angular.isNullOrUndefined(scope.opSelection)) {
                                for (var i = 0; i < scope.opSelection.length; i++) {
                                    scope.gridApi.selection.toggleRowSelection(scope.grille.data[scope.grille.data.indexOf(scope.opSelection[i])]);
                                }
                            }
                        }
                    };

                    // Recupérer texte des bulles d'information
                    if (angular.isNullOrUndefined(scope.getBulleInfo)) {
                        scope.getBulleInfo = function (lien, ligne) {
                            var attrLien = lien.split("|");
                            if ((attrLien.length > 1) && (attrLien[0] === "InfoBulle")) {
                                var cols = attrLien[1].split("#");
                                var texte = "";
                                if (!angular.isNullOrUndefined(ligne)) {
                                    for (var i = 0; i < cols.length; i++) {
                                        if (!angular.isNullOrUndefined(ligne[cols[i]])) {
                                            if (!angular.isNullOrUndefined(ligne[cols[i]].valeur) &&
                                                (scope.convertirVersTexte(ligne[cols[i]]).trim() !== "")) {
                                                if (texte !== "")
                                                    texte += String.fromCharCode(13);

                                                if (!angular.isNullOrUndefined(ligne[cols[i]].descriptions.libelle) &&
                                                    (ligne[cols[i]].descriptions.libelle.trim() !== "")) {
                                                    texte += ligne[cols[i]].descriptions.libelle.trim() + " : ";
                                                }

                                                texte += scope.convertirVersTexte(ligne[cols[i]]).trim();
                                            }
                                        }
                                    }
                                }
                            }

                            return texte;
                        };
                    }

                    scope.$watch('opDescription', function(value) {
                        if (value) {
                            if(scope.opCategorie){
                                scope.grille.enableRowHeaderSelection = false;
                                scope.grille.enableFullRowSelection = true;
                                scope.grille.treeRowHeaderAlwaysVisible = false;
                            }

                            var columnDef;
                            columnDefs = [];
                            var colRepresentationStatut,colRepresentationPriorite;
                            var bGrilleFormulaire = !angular.isNullOrUndefined(attributes.opIsGrilleformulaire) && attributes.opIsGrilleformulaire;
                            for (var i = 0; i < value.length; i++) {
                                var bEstColonneVisible = ( bGrilleFormulaire && value[i].estVisible )
                                        || ( !bGrilleFormulaire && value[i].visible.indexOf('T') != -1 );

                                //if(bEstColonneVisible) {
                                    columnDef = {displayName: value[i].libelle, field: value[i].nom + '.valeur', visible: bEstColonneVisible};

                                    var infoBulle = (!angular.isNullOrUndefined(value[i].action) && (value[i].action.trim() !== '')) ? 'true' : 'false';
                                    if (value[i].estChampPrincipal) {
                                        // Champ principal
                                        var nomModule = scope.opModule;
                                        var nomEcran = scope.opTrigramme +'_FICHE';
                                        var nomsIdentifiants = scope.opEntite;
                                        columnDef.cellTemplate = '<div class="ui-grid-cell-contents">' +
                                            '<i class="fa fa-lock" ng-if="row.entity.DROITACCES<=0"></i>' +
                                            '<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="' + infoBulle + ' && (COL_FIELD != \'\')" ' +
                                            'ng-attr-title="{{::grid.appScope.getBulleInfo(\'' + value[i].action + '\', row.entity)}}">&nbsp;</i>' +
                                            '<a ng-class="{\'linkDisabled\': row.entity.DROITACCES<=0}" href="" style="font-weight: bold;" ' +
                                            'ng-click="$event.stopPropagation();grid.appScope.chargerLien(\'' + nomModule + '\', \'' + nomEcran + '\', \'' + nomsIdentifiants + '\', row.entity)" '+
                                            'title="{{COL_FIELD}}"' +
                                            '>{{COL_FIELD}}</a></div>';
                                    } else if (value[i].lien) {
                                        // Colonne avec un lien
                                        columnDef.cellTemplate = '<div class="ui-grid-cell-contents">' +
                                            '<i class="fa fa-lock" ng-if="row.entity.DROITACCES<=0"></i>' +
                                            '<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="' + infoBulle + ' && (COL_FIELD != \'\')" ' +
                                            'ng-attr-title="{{::grid.appScope.getBulleInfo(\'' + value[i].action + '\', row.entity)}}">&nbsp;</i>' +
                                            '<a ng-class="{\'linkDisabled\': row.entity.DROITACCES<=0}" href="" style="font-weight: bold;" ' +
                                            'ng-click="$event.stopPropagation();grid.appScope.ouvrirDetailEntite(\'' +  value[i].lien + '\', col.field, row.entity)" ' +
                                            'ng-attr-title="{{::COL_FIELD}}"' +
                                            'ng-if="grid.appScope.habilitationLienModule(\'' +  value[i].lien + '\', col.field, row.entity)">{{COL_FIELD}}</a>' + // Mantis 6194 Contrôle des habilitations pour les liens inter-modules
                                            '<span ng-if="!grid.appScope.habilitationLienModule(\'' +  value[i].lien + '\', col.field, row.entity)">{{COL_FIELD}}</span>' +
                                            '</div>';
                                    } else if (infoBulle === 'true') {
                                        // Colonne sans lien mais avec info bulle
                                        columnDef.cellTemplate = '<div class="ui-grid-cell-contents">' +
                                            '<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="' + infoBulle + ' && (COL_FIELD != \'\')" ' +
                                            'ng-attr-title="{{::grid.appScope.getBulleInfo(\'' + value[i].action + '\', row.entity)}}">&nbsp;</i>' +
                                            '<span ng-attr-title="{{::COL_FIELD}}">{{COL_FIELD}}</span></div>';
                                    }


                                    // Colonne de groupement
                                    /*if(scope.opCategorie && value[i].visible.indexOf('G') != -1) {
                                        columnDef.grouping = { groupPriority: 0 };
                                        columnDef.sort = { priority: 0, direction: 'desc' };
                                    }*/

                                    // Type et taille
                                    columnDef.minWidth = (value[i].type =='NUMBER') ? 80 : 100; // Mantis 6055 Définir largeur minimale pour les colonnes
                                    if (value[i].type === 'DATE') {
                                        columnDef.cellFilter = 'date:\'dd/MM/yyyy\'';
                                        columnDef.type = 'date';
                                        columnDef.width= 100;
                                    } else if (value[i].type === 'DATETIME') {
                                        columnDef.cellFilter = 'date:\'dd/MM/yyyy HH:mm\'';
                                        columnDef.type = 'date';
                                        columnDef.width= 120;
                                    } else if (value[i].type =='NUMBER')
                                        columnDef.width = 80;
                                    else if (value[i].representation)
                                        columnDef.width= 100;
                                    else if (value[i].type =='VARCHAR2' && value[i].longueurMax <= 20)
                                        columnDef.width= 100;
                                    else
                                        columnDef.cellTooltip = true;

                                    if ( value[i].typeInteraction === "LIST" ){
                                        var descriptionListe = value[i].refEtrangere.split('|');
                                        if (!angular.isNullOrUndefined(descriptionListe) && descriptionListe.length>1){
                                            descriptionListe = descriptionListe[1].split('#');
                                            if (!angular.isNullOrUndefined(descriptionListe) && descriptionListe.length>1 && descriptionListe[0] != descriptionListe[1]){
                                                if (infoBulle === 'true') {
                                                    columnDef.cellTemplate = '<div class="ui-grid-cell-contents">' +
                                                        '<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="grid.appScope.getListeLabel(row.entity.' + value[i].nom + '.valeur,\'' + value[i].refEtrangere + '\') != \'\'" ' +
                                                        'ng-attr-title="{{::grid.appScope.getBulleInfo(\'' + value[i].action + '\', row.entity)}}">&nbsp;</i>' +
                                                        '<span ng-attr-title="{{::grid.appScope.getListeLabel(row.entity.' + value[i].nom + '.valeur,\'' + value[i].refEtrangere + '\')}}">' +
                                                        '{{grid.appScope.getListeLabel(row.entity.' + value[i].nom + '.valeur,\'' + value[i].refEtrangere + '\')}}</span></div>';
                                                } else {
                                                    columnDef.cellTemplate = '<div class="ui-grid-cell-contents">' +
                                                        '<span ng-attr-title="{{::grid.appScope.getListeLabel(row.entity.' + value[i].nom + '.valeur,\'' + value[i].refEtrangere + '\')}}">' +
                                                        '{{grid.appScope.getListeLabel(row.entity.' + value[i].nom + '.valeur,\'' + value[i].refEtrangere + '\')}}</span></div>';
                                                }
                                            }
                                        }
                                    }


                                    columnDefs.push(columnDef);
                                //} // Fin de if(bEstColonneVisible)

                                // Représentation
                                if ( !angular.isNullOrUndefined(value[i].representation) && value[i].representation.length>0){
                                    if (value[i]['representation']=='STATUT')
                                        colRepresentationStatut = value[i].nom;
                                    else if (value[i]['representation']=='PRIORITE')
                                        colRepresentationPriorite = value[i].nom;
                                }
                            }

                            // Ajout d'une cellule de représentation
                            if ( !angular.isNullOrUndefined(colRepresentationStatut) || !angular.isNullOrUndefined(colRepresentationPriorite) ){
                                columnDef = {name: '', field: colRepresentationStatut + '.valeur',cellTemplate:'<div class="pull-right">',width:0};
                                if (!angular.isNullOrUndefined(colRepresentationStatut) ){
                                    columnDef.width += 80;
                                    columnDef.cellTemplate +='  {{row.entity.'+colRepresentationStatut+'.valeur}}  <i class="fa" ng-class="::grid.appScope.getRepresentationStatutGrille(\'STATUT\', row.entity.'+colRepresentationStatut+'.valeur)" ng-style="::grid.appScope.getRepresentationStyleGrille(\'STATUT\', row.entity.'+colRepresentationStatut+'.valeur)"></i>';
                                }
                                if (!angular.isNullOrUndefined(colRepresentationPriorite) ){
                                    columnDef.width += 80;
                                    columnDef.cellTemplate +='  <i class="fa" ng-class="::grid.appScope.getRepresentationStatutGrille(\'PRIORITE\', row.entity.'+colRepresentationPriorite+'.valeur)" ng-style="::grid.appScope.getRepresentationStyleGrille(\'PRIORITE\', row.entity.'+colRepresentationPriorite+'.valeur)"></i>';
                                }
                                columnDef.cellTemplate += '</div>';
                                columnDef.width = columnDef.width.toString();
                                columnDefs.push(columnDef);
                            }

                            // Ajout du statut dans le cas d'intervention tache
                            if ( !angular.isNullOrUndefined(scope.opEtat)){
                                columnDefs.splice(0,0,{
                                    name: 'a',
                                    displayName : "",
                                    width: 50,
                                    field:'a',
                                    enableFiltering:false,
                                    sortable:false,
                                    cellTemplate: '<div style="text-align:center;" class="ui-grid-cell-contents" >' +
                                    '<i ng-class="::grid.appScope.getRepresentationStatutGrille( \''+scope.opEtat+'\', row.entity)" ' +
                                    'ng-style="::grid.appScope.getRepresentationStyleGrille( \''+scope.opEtat+'\', row.entity)"  ' +
                                    'tooltip-html-unsafe="{{grid.appScope.getRepresentationToolTip( \''+scope.opEtat+'\', row.entity)}}" ' +
                                    'tooltip-append-to-body=\'true\'' +
                                    'aria-hidden="true">' +
                                    '</i>' +
                                    '</div>'
                                })
                            }

                            // Largeur colonnes par défaut
                            scope.grille.minimumColumnSize = 80;

                            // Scroll hroizontal
                            //scope.grille.enableHorizontalScrollbar = 0;
                            scope.grille.enableHorizontalScrollbar = 1; // Mantis 6055 Activer scroll horizontal
                            heightHorizontalScrollbar = (scope.grille.enableHorizontalScrollbar !== 0) ? 33 : 0;

                            // Scroll verticale
                            // Mantis 6055 Gérer attribut scroll vertical
                            if ('opVerticalScroll' in attributes) {
                                scope.grille.enableVerticalScrollbar = 1;
                            } else {
                                scope.grille.enableVerticalScrollbar = 0;
                            }

                            scope.grille.columnDefs = (columnDefs.length>0)?columnDefs:null;
                            scope.grille.data = scope.opDonnees;

                            // Affichage accordion pour mobile
                            if (!angular.isNullOrUndefined(value[0])) {
                                var regroupement = value[0][SCOPE_NOM_GROUPE].split('|');

                                if (regroupement.length == 3) {
                                    // Titre avec formule
                                    var formuleRegroupement = regroupement[2];
                                    titreLigne = formuleRegroupement.replace(/#((.*?))#/g, 'getTitreLigne(ligne,\'' + '$1' + '\')');
                                } else {
                                    // Pas de formule
                                    titreLigne = regroupement[1];
                                }
                            }
                        }
                    });

                    scope.$watch('opDonnees', function(value) {
                        if (value && !angular.isNullOrUndefined(scope.grille.columnDefs) && (scope.grille.columnDefs.length > 0)) {
                            scope.grille.data = scope.opDonnees;
                        }
                    });

                    scope.$watch('opFiltre', function(value) {
                        if (!angular.isNullOrUndefined(scope.gridApi))
                            scope.gridApi.grid.refresh();
                    });

                    scope.chargerLien = function(nomModule,nomEcran, nomsIdentifiants, entiteRecherche) {
                        scope.opSelection.splice(0);
                        scope.opSelection.push(entiteRecherche);
                        //scope.opDetail(nomModule,nomEcran, nomsIdentifiants, entiteRecherche);
                        scope.opDetail(entiteRecherche);
                    };

                    /**
                     * Mantis 6194 Contrôle des habilitations pour les liens inter-modules
                     * @param {String} lien
                     * @param {String} colEntite
                     * @param {Objet} modeleEntite
                     */
                    scope.habilitationLienModule = function(lien, colEntite, modeleEntite) {
                        var module = modulesActifsService.getModule(lien.split('|')[0]);

                        if (!angular.isNullOrUndefined(module)) {
                            // Contrôle valorisation identifiant de l'entité
                            var nomColEntite = colEntite.split('.')[0];

                            if (!angular.isNullOrUndefined(modeleEntite[nomColEntite]) &&
                                !angular.isNullOrUndefined(modeleEntite[nomColEntite].valeur) &&
                                (modeleEntite[nomColEntite].valeur != '')) {
                                var attrLien = lien.split('|');
                                var mapEntite = (attrLien.length > 2) ? attrLien[2] : "";
                                var nomColCle = module.MOD_ENTITE.valeur;
                                var nomCoCleValeur = (mapEntite !== "") ? mapEntite : nomColCle;

                                return !angular.isNullOrUndefined(modeleEntite[nomCoCleValeur].valeur);
                            } else {
                                return false;
                            }
                        } else {
                            return false;
                        }
                    };
					
                    // Ouvrir la synthèse d'une entité dans un nouvel onglet
                    scope.ouvrirDetailEntite = function(lien, colEntite, modeleEntite) {
                        // Traitement du modéle
                        var attrLien = lien.split('|');
                        var nomModule = attrLien[0];
                        var mapEntite = (attrLien.length > 2) ? attrLien[2] : "";
                        var nomColEntite = colEntite.split('.')[0];

                        // Vérification que la valeur de l'entite n'est pas vide
                        if (!angular.isNullOrUndefined(modeleEntite[nomColEntite]) &&
                            !angular.isNullOrUndefined(modeleEntite[nomColEntite].valeur) &&
                            (modeleEntite[nomColEntite].valeur != '')) {

                            // Demande d'ouverture d'activite
                            var module = modulesActifsService.getModule(nomModule);
                            if (!angular.isNullOrUndefined(module)) {
                                var nomColCle = module.MOD_ENTITE.valeur;
                                var nomCoCleValeur = (mapEntite !== "") ? mapEntite : nomColCle;
                                scope.opDemandeDetailEntite(module, nomColCle, modeleEntite[nomCoCleValeur]);
                            }
                        }
                    };

                    // Ouvrir la référence externe SIG d'une entité dans une popup
                    scope.ouvrirRefSigEntite = function(modeleEntite) {
                        var popupFeature = $modal.open({
                            templateUrl: 'moteur/composants/cartographie/OpPopupFeature.html',
                            controller: 'fr.operis.moteurV4.composants.cartographie.OpPopupFeatureControleur',
                            size: 'lg',
                            resolve: {
                                moduleNom: function () {
                                    return scope.opModule;
                                },
                                entite: function () {
                                    return modeleEntite;
                                }
                            }
                        });
                    };

                    /**
                     * Ecouter la bascule de la section droite de l'onglet
                     */
                    if ('opSectionDroiteVisible' in attributes) {
                        scope.$watch('opSectionDroiteVisible', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                if (newValue && !angular.isNullOrUndefined(scope.gridApi)) {
                                    // Adjuster la largeur de la grille suite affichage de la section droite
                                    scope.gridApi.grid.gridWidth = scope.gridApi.grid.canvasWidth / 2;
                                    scope.gridApi.grid.queueRefresh();
                                }
                            }
                        });
                    }

                    if (!angular.isNullOrUndefined(scope.opControlerCsv)) {
                        scope.opControlerCsv.exporterCSV = function(){
                            if (!angular.isNullOrUndefined(scope.gridApi) && !angular.isNullOrUndefined(scope.gridApi.exporter)) {
                                scope.grille.exporterCsvColumnSeparator = ';';
                                scope.grille.exporterOlderExcelCompatibility = true;
                                scope.grille.exporterCsvFilename = scope.opControlerCsv.nomFichier;
                                var export_row_type = 'visible';
                                var export_column_type = 'visible';
                                scope.gridApi.exporter.csvExport(export_row_type, export_column_type);
                            }
                        };
                    }

                    scope.lang='fr';
                    scope.enteteColonnes = !serveur.isMobile();

                    var template;
                    if ( attributes.opIsGrilleformulaire){
                        template = '<table width="100%" style="table-layout: fixed" ng-if="grille.data.length>0">' +
                            '           <thead ng-if="enteteColonnes"><th class="th-op-grille" ng-repeat= "colonne in grille.data.descriptions" ng-if="colonne.estVisible">'+
                            '               {{colonne.libelle}} '+
                            '           </th><thead> '+
                            '           <tbody><tr ng-repeat="item in ::grille.data"> ' +
                            '               <td ng-repeat="champs in triColonnesLigne(item)" class="td-op-grille" ng-if="champs.descriptions.estVisible"> ' +
                            '                   <div>' +
                            '                   <span ng-if="champs.descriptions.lien==\'\'" ng-attr-title="{{convertirVersTexte(champs)}}">' +
                            '                       <i class="fa fa-info-circle" style="color: forestgreen;" ng-if="(champs.descriptions.action!=\'\' && convertirVersTexte(champs)!=\'\')" ' +
                            '                       ng-attr-title="{{::getBulleInfo(champs.descriptions.action, item)}}"></i>' +
                            '                       <a href="" class="fa fa-globe" ng-if="item.REF_NOM==\'SIG\' && champs.descriptions.nom==\'LIBELLE\'" ' +
                            '                        ng-click="$event.stopPropagation();ouvrirRefSigEntite(item)"></a>' +
                            '                       {{convertirVersTexte(champs)}}</span>' +
                            '                   <span ng-if="champs.descriptions.lien!=\'\'">' +
                            '                       <i class="fa fa-lock" ng-if="item.DROITACCES<=0"></i>' +
                            '                       <i class="fa fa-info-circle" style="color: forestgreen;" ng-if="(champs.descriptions.action!=\'\' && convertirVersTexte(champs)!=\'\')" ' +
                            '                       ng-attr-title="{{::getBulleInfo(champs.descriptions.action, item)}}"></i>' +
                            '                       <a href="" class="fa fa-globe" ng-if="item.REF_NOM==\'SIG\' && champs.descriptions.nom==\'LIBELLE\'" ' +
                            '                        ng-click="$event.stopPropagation();ouvrirRefSigEntite(item)"></a>' +
                            '                       <a href="" style="font-weight: bold;" ' +
                            '                        ng-class="{\'linkDisabled\': item.DROITACCES<=0}" ' +
                            '                        ng-click="$event.stopPropagation();ouvrirDetailEntite(champs.descriptions.lien, champs.descriptions.nom, item)" ' +
                            '                        ng-attr-title="{{::convertirVersTexte(champs)}}"' +
                            '                        ng-if="habilitationLienModule(champs.descriptions.lien, champs.descriptions.nom, item)">{{convertirVersTexte(champs)}}</a>' + // Mantis 6194 Contrôle des habilitations pour les liens inter-modules
                            '                       <span ng-if="!habilitationLienModule(champs.descriptions.lien, champs.descriptions.nom, item)">{{convertirVersTexte(champs)}}</span>' +
                            '                   </span>' +
                            '                   </div>' +
                            '               </td>' +
                            '           </tr><tbody>' +
                            '       </table>';
                    }else{
                        // Mantis 6055 Gérer scroll vertical
                        if ('opVerticalScroll' in attributes) {
                            if (!angular.isNullOrUndefined(attributes.opIsGrilleformulaire) && attributes.opIsGrilleformulaire)
                                template = '<div ui-i18n="{{lang}}" style="height: 100%"><div class="hidden-xs" ng-if="grille.columnDefs" ui-grid="grille" ui-grid-auto-resize ui-grid-resize-columns ui-grid-pagination external-scopes="gridScope" style="height: 50vh" ng-cloak></div></div>';
                            else
                                template = '<div ui-i18n="{{lang}}" style="height: 100%"><div class="hidden-xs" ng-if="grille.columnDefs" ui-grid="grille" ui-grid-auto-resize ui-grid-resize-columns ui-grid-selection ui-grid-exporter external-scopes="gridScope" style="height: 50vh" ng-cloak></div></div>';
                        } else {
                            if (!angular.isNullOrUndefined(attributes.opIsGrilleformulaire) && attributes.opIsGrilleformulaire)
                                template = '<div ui-i18n="{{lang}}"><div class="hidden-xs" ng-if="grille.columnDefs" ui-grid="grille" ui-grid-auto-resize ui-grid-resize-columns ui-grid-pagination external-scopes="gridScope" ng-style="{height: ' + calcGridHeight + '+' + heightHorizontalScrollbar + '+' + heightFooter + '+\'px\'} " ng-cloak></div></div>';
                            else {
                                template = '<div ui-i18n="{{lang}}"><div class="hidden-xs" ng-if="grille.columnDefs" ui-grid="grille" ui-grid-auto-resize ui-grid-resize-columns ui-grid-selection ui-grid-exporter external-scopes="gridScope" ng-style="{height: ' + calcGridHeight + '+' + heightHorizontalScrollbar + '+' + heightFooter + '+\'px\'} " ng-cloak></div></div>';
                            }
                        }
                    }


                    // Affichage accordion pour mobile
                    /*var titreLigne = "";

                    if ((!angular.isNullOrUndefined(scope.opDescription)) && (!angular.isNullOrUndefined(scope.opDescription[0]))) {
                        var regroupement = scope.opDescription[0][SCOPE_NOM_GROUPE].split('|');

                        if (regroupement.length == 3) {
                            // Titre avec formule
                            var formuleRegroupement = regroupement[2];
                            titreLigne = formuleRegroupement.replace(/#((.*?))#/g, 'getTitreLigne(ligne,\'' + '$1' + '\')');
                        } else {
                            // Pas de formule
                            titreLigne = regroupement[1];
                        }
                    }

                    template +=
                        '<accordion class="visible-xs" ng-repeat="ligne in grille.data">' +
                            '<accordion-group>'+
                                '<accordion-heading><b>' + titreLigne + '</b></accordion-heading>'+
                                '<div class="panel-body" style="overflow: hidden">'+
                                    '<div class="row" ng-repeat="colonne in triColonnesLigne(ligne)">'+
                                        '<div class="form-group row-bottom-margin" ng-if="colonne.descriptions.estVisible && !colonne.descriptions.estChampPrincipal">' +
                                            '<label class="col-xs-5 control-label">{{colonne.descriptions.libelle}}</label>'+
                                            '<div class="col-xs-6">'+
                                                '<p class="form-control-static operis-padding-topbottom-zero">{{convertirVersTexte(colonne)}}</p>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>' +
                                '</div>' +
                            '</accordion-group>' +
                        '</accordion>';*/

                    element.html(template);
                    $compile(element.contents())(scope);
					});
                }
            };
        }
    ])
    .directive('opGrilleAutoResize',
    ['$timeout', '$injector',
        function ($timeout, $injector) {
            return {
                require: 'uiGrid',
                scope: false,
                link: function ($scope, $elm, $attrs, uiGridCtrl) {
                    var prevGridWidth, prevGridHeight, gridUtil = $injector.get('gridUtil');

                    function getDimensions() {
                        prevGridHeight = gridUtil.elementHeight($elm);
                        prevGridWidth = gridUtil.elementWidth($elm);
                    }

                    // Initialize the dimensions
                    getDimensions();

                    var resizeTimeoutId;
                    function startTimeout() {
                        clearTimeout(resizeTimeoutId);

                        resizeTimeoutId = setTimeout(function () {
                            var newGridHeight = gridUtil.elementHeight($elm);
                            var newGridWidth = gridUtil.elementWidth($elm);

                            if (newGridHeight !== prevGridHeight || newGridWidth !== prevGridWidth) {
                                uiGridCtrl.grid.gridHeight = newGridHeight;
                                uiGridCtrl.grid.gridWidth = newGridWidth;

                                $scope.$apply(function () {
                                    uiGridCtrl.grid.refresh()
                                        .then(function () {
                                            getDimensions();

                                            startTimeout();
                                        });
                                });
                            }
                            else {
                                startTimeout();
                            }
                        }, 250);
                    }

                    startTimeout();

                    $scope.$on('$destroy', function() {
                        clearTimeout(resizeTimeoutId);
                    });

                    /*
                    var width = 0,
                        height = 0,
                        gridUtil = $injector.get('gridUtil');

                    function verifTaille() {
                        if (width !== $elm.prop('offsetWidth') || height !== $elm.prop('offsetHeight')) {
                            // On déclenche un cycle de digest pour rentrer dans le watch
                            $scope.$digest();
                        } else {
                            $timeout(verifTaille, 500, false);
                        }
                    }

                    $timeout(verifTaille, 500, false);

                    $scope.$watch(function () {
                            return $elm.prop('offsetWidth') + ';' + $elm.prop('offsetHeight');
                        },
                        function () {
                            uiGridCtrl.grid.gridHeight = gridUtil.elementHeight($elm);
                            uiGridCtrl.grid.gridWidth = gridUtil.elementWidth($elm);
                            uiGridCtrl.grid.refresh();
                        });
                    */
                }
            };
        }
    ]);