/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

/**
 *  Filtre pour formatter un intervalle.
 *  Toute chaine entre quote sera supprimée si l'un des codes précédents a pour valeur 0.
 *  Toute chaine entre quote aura un 's' d'ajouté pour chaque code précédent qui a une valeur supérieure à 1.
 *  Le format est une chaine de caractères avec les codes suivants :
 *  <ul>
 *    <li>'y': le nombre d'année(s)</li>
 *    <li>'M': le nombre de mois</li>
 *    <li>'d': le nombre de jour(s)</li>
 *    <li>'H': le nombre d'heure(s)</li>
 *    <li>'m': le nombre de minute(s)</li>
 *    <li>'s': le nombre de seconde(s)</li>
 *    <li>'.SSS' ou ',SSS': le nombre de millisecondes sur 3 caractères</li>
 *  </ul>
 */
angular.module('fr.operis.moteurV4.filtres.Intervalle', [])
    .filter('intervalle', function () {
        var MILLISECONDES_VERS_SECONDES = 1000;
        var MILLISECONDES_VERS_MINUTES = MILLISECONDES_VERS_SECONDES * 60;
        var MILLISECONDES_VERS_HEURES = MILLISECONDES_VERS_MINUTES * 60;
        var MILLISECONDES_VERS_JOURS = MILLISECONDES_VERS_HEURES * 24;
        var MILLISECONDES_VERS_MOIS = MILLISECONDES_VERS_JOURS * 30;
        var MILLISECONDES_VERS_ANNEES = MILLISECONDES_VERS_MOIS * 12;
        var FORMATS = [
            {
                format: /y/g, valeur: function (aIntervalle) {
                return Math.floor(aIntervalle / this.multiplicateur);
            }, multiplicateur: MILLISECONDES_VERS_ANNEES
            },
            {
                format: /M/g, valeur: function (aIntervalle) {
                return Math.floor(aIntervalle / this.multiplicateur);
            }, multiplicateur: MILLISECONDES_VERS_MOIS
            },
            {
                format: /d/g, valeur: function (aIntervalle) {
                return Math.floor(aIntervalle / this.multiplicateur);
            }, multiplicateur: MILLISECONDES_VERS_JOURS
            },
            {
                format: /H/g, valeur: function (aIntervalle) {
                return Math.floor(aIntervalle / this.multiplicateur);
            }, multiplicateur: MILLISECONDES_VERS_HEURES
            },
            {
                format: /m/g, valeur: function (aIntervalle) {
                return Math.floor(aIntervalle / this.multiplicateur);
            }, multiplicateur: MILLISECONDES_VERS_MINUTES
            },
            {
                format: /s.SSS/g, valeur: function (aIntervalle) {
                return aIntervalle / this.multiplicateur;
            }, multiplicateur: MILLISECONDES_VERS_SECONDES
            },
            {
                format: /s/g, valeur: function (aIntervalle) {
                return Math.floor(aIntervalle / this.multiplicateur);
            }, multiplicateur: MILLISECONDES_VERS_SECONDES
            },
            {
                format: /(\.|,)SSS/g, valeur: function (aIntervalle) {
                return aIntervalle;
            }, multiplicateur: 1
            }
        ];
        return function (intervalle, format) {
            format = (format || '').split("'");
            intervalle = intervalle || 0;
            for (var indice = 0; indice < FORMATS.length; ++indice) {
                var formatTest = FORMATS[indice],
                    valeur = null;
                for (var indiceFormat = 0; indiceFormat < format.length; indiceFormat += 2) {
                    if (format[indiceFormat].search(formatTest.format) > -1) {
                        // Calcul de la valeur si besoin
                        if (valeur === null) {
                            valeur = formatTest.valeur(intervalle);
                            intervalle -= valeur * formatTest.multiplicateur;
                            if (valeur === 0) valeur = '';
                        }

                        // Suppression du mot suivant
                        // et mise en place du pluriel
                        if (indiceFormat < format.length - 1) {
                            if (valeur === '')
                                format[indiceFormat + 1] = '';
                            else if (valeur > 1)
                                format[indiceFormat + 1] += 's';
                        }

                        // Mise à jour de la valeur
                        format[indiceFormat] = format[indiceFormat].replace(formatTest.format, valeur);
                    }
                }
            }
            return format.join('');
        };
    });