/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteReferentiel', ['fr.operis.moteurV4.communication.OperisWSServer','fr.operis.moteurV4.utils.ReferentielServlet'])

    /**
     * Nom de l'attribut pour la table de recherche
     * @type {String}
     * @value table
     */
    .constant('fr.operis.moteurV4.utils.RequeteReferentiel.TABLE', 'table')

    /**
    * Nom de l'attribut pour l'ecran de recherche
    * @type {String}
    * @value ecran
    */
    .constant('fr.operis.moteurV4.utils.RequeteReferentiel.ECRAN', 'ecran')

    /**
     * Nom de l'attribut pour le nombre de resultat de recherche
     * @type {String}
     * @value 20
     */
    .constant('fr.operis.moteurV4.utils.RequeteReferentiel.TAILLE', 50) //20)

    .service('fr.operis.moteurV4.utils.RequeteReferentiel',
        ['$q','$http','fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.utils.ReferentielServlet',
            'fr.operis.moteurV4.utils.RequeteReferentiel.TABLE',
            'fr.operis.moteurV4.utils.RequeteReferentiel.ECRAN',
            'fr.operis.moteurV4.utils.RequeteReferentiel.TAILLE',
        function ($q, $http, serveur, referentielServlet, TABLE, ECRAN, TAILLE) {

            this._referentiel = {};
            this._servletUrl = window.location.protocol + '//' + window.location.hostname + ':' +
                window.location.port + '' + window.location.pathname.replace("/Application","/") + 'RechercheReferentiel';

            /**
             * Recherche sur le référentiel selon des critères
             * @param {String} aNomModule
             * @param {String} aNomReferentiel
             * @param {String} aParamReferentiel
             * @param {String} aValeurRecherche
             * @param {String} aFiltreRecherche
             * @returns {Array} Resultat de la recherche
             */
            this.rechercher = function(aScope, aNomModule, aNomReferentiel, aParamReferentiel, aIdModele, aIndex, aValeurRecherche, aFiltreRecherche, aValeurRechercheCode ){

                // Recherche interne
                if ( aNomReferentiel.toUpperCase().indexOf("REFERENTIEL_INTERNE_") !== -1 ){
                    // Parametre de recherche de referentiel
                    return this.rechercherInterne(aScope, aNomModule, aNomReferentiel.replace("REFERENTIEL_INTERNE_",""), aParamReferentiel, aIdModele, aIndex, aValeurRecherche, aValeurRechercheCode);
                }else{
                    return this.rechercherExterne(aNomModule, aNomReferentiel, aValeurRecherche, aFiltreRecherche);
                }

            };

            /**
             * Recherche interne sur le référentiel selon des critères
             * @param {String} aNomModule
             * @param {String} aNomReferentiel
             * @param {String} aParamReferentiel
             * @param {String} aValeurRecherche
             * @param {String} aFiltreRecherche
             * @returns {Array} Resultat de la recherche
             */
            this.rechercherInterne = function(aScope, aNomModule, aNomReferentiel, aParamReferentiel, aIdModele, aIndex, aValeurRecherche, aValeurRechercheCode){
                var deferred = $q.defer();

                // Parametre de recherche de referentiel
                // Nom de l'écran et des critères de recherche
                var nomEcran = 'OperiaReferentiel_Recherche' + aNomReferentiel.toUpperCase();
                var nomColCritere = [];

                if (!angular.isNullOrUndefined(aParamReferentiel)){
                    var paramReferentiel = aParamReferentiel.split('|');
                    while (angular.isNullOrUndefined(paramReferentiel)){
                        nomColCritere.push(paramReferentiel.pop());
                    };
                }

                if ( !angular.isNullOrUndefined(aIndex) && aIdModele.indexOf("$index") != -1)
                    aIdModele = aIdModele.replace("$index",aIndex.toString())

                // Chargement intiale du formulaire des critères si non present
                if ( !angular.isNullOrUndefined(aScope[aNomModule + aNomReferentiel])
                    && !angular.isNullOrUndefined(aScope[aNomModule + aNomReferentiel][aIdModele])){

                    // Maj des valeurs d'interrogation
                    this.majCritereRecherche( aNomModule + aNomReferentiel,aIdModele, aScope, aScope[aNomModule + aNomReferentiel][aIdModele], nomColCritere, aValeurRecherche,aValeurRechercheCode );

                    // Lancement de la requête
                    this.executer(aNomModule + aNomReferentiel,aIdModele, aScope)
                        .then(function(retourExecuter){
                            deferred.resolve(retourExecuter);
                        });
                }else{
                    if ( angular.isNullOrUndefined(aScope[aNomModule + aNomReferentiel] ) )
                        aScope[aNomModule + aNomReferentiel] = {};
                    aScope[aNomModule + aNomReferentiel][aIdModele] = {};
                    var that = this;
                    serveur.chargement("Operia", aScope[aNomModule + aNomReferentiel][aIdModele], nomEcran, null)
                        .then(function(retourChargement){
                            if ( angular.isNullOrUndefined(that._referentiel[aNomModule + aNomReferentiel] ) )
                                that._referentiel[aNomModule + aNomReferentiel] = {};

                            that._referentiel[aNomModule + aNomReferentiel][aIdModele] = {TABLE:retourChargement[0][0],
                                ECRAN:nomEcran};

                            // Maj des valeurs d'interrogation
                            that.majCritereRecherche( aNomModule + aNomReferentiel, aIdModele, aScope, aScope[aNomModule + aNomReferentiel][aIdModele], nomColCritere, aValeurRecherche,aValeurRechercheCode );

                            // Lancement de la requête
                            that.executer(aNomModule + aNomReferentiel, aIdModele, aScope)
                                .then(function(retourExecuter){
                                    deferred.resolve(retourExecuter);
                                });
                        })
                }



                return deferred.promise;
            };


            /**
             * Mise à jour des critère de recherche
             * @param {String} aNomReferentielModule
             * @param {String} aScopeFormulaire
             * @param {String} aScopeVueRecherche
             * @param {String} aNomColCritere
             * @param {String} aValeurRecherchePrincipal
             */
            this.majCritereRecherche = function(aNomReferentielModule, aIdModele, aScopeFormulaire, aScopeVueRecherche, aNomColCritere, aValeurRecherchePrincipal, aValeurRechercheCode){
                if ( angular.isNullOrUndefined(this._referentiel[aNomReferentielModule][aIdModele].TABLE))
                    return;

                // Table de recherche
                var tableRecherche = this._referentiel[aNomReferentielModule][aIdModele].TABLE;

                // Critère principale
                aScopeVueRecherche.champs[tableRecherche].RECHERCHE.valeur = aValeurRecherchePrincipal;
                aScopeVueRecherche.champs[tableRecherche].VALEUR.valeur = aValeurRechercheCode;

                // Critères secondaires
                if ( !angular.isNullOrUndefined( aNomColCritere )){
                    for (var i=0;i<aNomColCritere.length;i++){
                        var nomColCritere = aNomColCritere.split(".");
                        if ( !angular.isNullOrUndefined(nomColCritere) &&  nomColCritere.length >= 2 ){
                            var nomTable = nomColCritere[0];
                            var nomColonne = nomColCritere[1];

                            if ( !angular.isNullOrUndefined (aScopeVueRecherche.champs[tableRecherche]["CRITERE" + i.toString()]) )
                                aScopeVueRecherche.champs[tableRecherche]["CRITERE" + i.toString()].valeur
                                    = aScopeFormulaire.champs[nomTable][nomColonne].valeur;
                        }
                    }
                }

            };

            /**
             * Mise à jour des critère de recherche
             * @param {String} aNomReferentielModule
             * @param {String} aScope
             * @returns {Array} Resultat de la recherche
             */
            this.executer = function(aNomReferentielModule,aIdModele, aScope){
                var deferred = $q.defer();

                var nomEcran = this._referentiel[aNomReferentielModule][aIdModele].ECRAN;
                aScope[aNomReferentielModule][aIdModele].recherche[nomEcran](TAILLE)
                    .then(function(retourRecherche){
                        var nomGrilleResultat = retourRecherche[0][0];
                        var grilleResultat =  aScope[aNomReferentielModule][aIdModele].grilles[nomGrilleResultat];
                        var resultat = [];
                        if (!angular.isNullOrUndefined(grilleResultat)){
                            for ( var j=0;j<grilleResultat.length;j++){
                                var item = {};

                                for ( var colNom in grilleResultat[j] ){
                                    item[colNom.toLowerCase().toString()] = grilleResultat[j][colNom].valeur;
                                }

                                resultat.push(item);
                            };
                        }
                        deferred.resolve(resultat);
                    });

                return deferred.promise;
            }

            // Variable pour le retour des recherches selon un/des code(s) Insee
            var _retourRechercheInsee = [];

            /**
             * Recherche référentiel par code Insee
             *
             * @param nomModule Nom du module appelant
             * @param nomReferentiel Nom de référentiel cible pour la recherche
             * @param valeurRecherche Valeur à rechercher
             * @param filtreRecherche Filtre à applique sur les valeurs de retour
             * @param codeInsee Code Insee à cibler
             * @returns {Object} Promesse qui se résout une fois la recherche effectuée
             */
            this.getPromesseRechercheReferentielInsee = function (nomModule, nomReferentiel, valeurRecherche, filtreRecherche, codeInsee) {
                var deferred = $q.defer();

                var paramsAppelServlet = {
                    idSession: serveur.getIdSession(nomModule),
                    texteRecherche: valeurRecherche,
                    referentiel: nomReferentiel,
                    inseeCode: codeInsee
                };

                if (!angular.isNullOrUndefined(filtreRecherche)) {
                    for (var i = 0; i < filtreRecherche.length; i++) {
                        if (!angular.isNullOrUndefined(filtreRecherche[i])) {
                            paramsAppelServlet['filtre' + (i + 1).toString()] = filtreRecherche[i];
                        }
                    }
                }

                $http.get(this._servletUrl, {
                    params: paramsAppelServlet
                }).then(function (response) {
                    if (!angular.isNullOrUndefined(response.data.results)) {
                        _retourRechercheInsee = _retourRechercheInsee.concat(response.data.results);
                    }

                    deferred.resolve(response);
                });

                return deferred.promise;
            };

            /**
             * Réalise la recherche externe sur un référentiel à partir d'une valeur recherchée
             *
             * @param {String} Le nom du référentiel
             * @param {String} La valeur recherchée
             * @returns {Object}
             */
            this.rechercherExterne = function (aNomModule, aNomReferentiel, aValeurRecherche, aFiltreRecherche){
                if ( !angular.isNullOrUndefined ( aNomModule ) && !angular.isNullOrUndefined ( aNomReferentiel )){
                    // Attention : si aNomReferentiel "geolocaliserMoi" alors recherche référentiel "adresse" selon les coordonnées XY et sans filtre selon le code Insee
                    var nomReferentiel = (aNomReferentiel === "geolocaliserMoi") ? "adresse" : aNomReferentiel;
                    var filtreInsee =  null;
                    var i;

                    if ((nomReferentiel !== "adresse") || angular.isNullOrUndefined(filtreInsee) || (filtreInsee === "")) {
                        // Recherche référentiel sans filtre selon code Insee
                        var paramsAppelServlet = {
                            idSession: serveur.getIdSession(aNomModule),
                            texteRecherche: aValeurRecherche,
                            referentiel: nomReferentiel
                        };

                        if (aNomReferentiel === "geolocaliserMoi") {
                            paramsAppelServlet.reverse = "true";
                        }

                        if (!angular.isNullOrUndefined(aFiltreRecherche)) {
                            for (i = 0; i < aFiltreRecherche.length; i++) {
                                if (!angular.isNullOrUndefined(aFiltreRecherche[i])) {
                                    paramsAppelServlet['filtre' + (i + 1).toString()] = aFiltreRecherche[i];
                                }
                            }
                        }

                        return $http.get(this._servletUrl, {
                            params: paramsAppelServlet
                        }).then(function (response){
                            if (!angular.isNullOrUndefined(response.data.results)){
                                return response.data.results.map(function (item){
                                    return item;
                                });
                            }
                        });
                    } else {
                        // Recherche référentiel avec filtre selon code Insee
                        var tabFiltreInsee = filtreInsee.split(';');

                        // Réinit retour de récherche
                        _retourRechercheInsee = [];

                        // Init tableau des promesses (1 promesse par code Insee)
                        var listePromesses  = [];
                        for (i = 0; i < tabFiltreInsee.length; i++) {
                            var promesse = this.getPromesseRechercheReferentielInsee(aNomModule, nomReferentiel, aValeurRecherche, aFiltreRecherche, tabFiltreInsee[i]);
                            listePromesses.push(promesse);
                        }

                        // Retour après tous les recherches ont été effectuées
                        return $q.all(listePromesses).then(function () {
                            var bufRetour = _retourRechercheInsee;

                            for (i = bufRetour.length - 1; i >= 0; i--) {
                                var lib = bufRetour[i].libelle.toUpperCase();
                                if (lib.indexOf(aValeurRecherche.toUpperCase()) === -1) {
                                    bufRetour.splice(i, 1);
                                }
                            }

                            return bufRetour.map(function (item) {
                                return item;
                            });
                        });
                    }
                }
            }
        }
    ]
);