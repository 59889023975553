/**
 * @version 1.0b
 * @Author Brian Kjerulf - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequetePostSuppressionFiche', [])

    .service('fr.operis.moteurV4.utils.RequetePostSuppressionFiche', [
        'fr.operis.moteurV4.communication.OperisWSServer',
        function (serveur) {
            this._configuration={};

            // Configuration des requetes post traitement
            this.configuration = function (aConfiguration) {
                this._configuration = aConfiguration;
            };

            // Execution des requetes post traitement
            this.execution = function (aScope, nomModule, aIdentifiant, aElementLie, aIdAction) {
                if (angular.isNullOrUndefined(nomModule))
                    return;

                if (!angular.isNullOrUndefined(this._configuration[nomModule.toUpperCase()])) {
                    return this._configuration[nomModule.toUpperCase()](aScope, aIdentifiant, aElementLie, aIdAction);
                }
            }
        }
    ]
);