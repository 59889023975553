/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpTextInput', [
        'fr.operis.moteurV4.modele.Modele'
        , 'fr.operis.moteurV4.utils.Conversion'
        , 'fr.operis.moteurV4.communication.OperisWSServer'
        ,'fr.operis.moteurV4.utils.RequeteReferentiel'
        ,'fr.operis.moteurV4.utils.ReferentielServlet'
        ,'fr.operis.moteurV4.utils.RequeteFichier'])
    .directive('opTextinput', [
        '$timeout'
        ,'fr.operis.moteurV4.utils.Conversion'
        ,'fr.operis.moteurV4.utils.RequeteReferentiel'
        ,'fr.operis.moteurV4.utils.ReferentielServlet'
        ,'fr.operis.moteurV4.utils.RequeteFichier'
        ,'fr.operis.moteurV4.utils.GestionActionFormulaire'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_LISTES'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_LONGUEUR_MAX'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_LIBELLE'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTION'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_UNITE'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_BORNE_MIN'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_BORNE_MAX'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_OBLIGATOIRE'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_COMPLEXE'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_REF_ETRANGERE'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_ESTLECTURESEULE'
        , 'fr.operis.moteurV4.utils.Config'
        , 'fr.operis.moteurV4.communication.OperisWSServer'
        , 'fr.operis.moteurV4.utils.Encryption'
        , function (
            $timeout
            ,conversion
            , requetereferentiel
            , referentielServlet
            , requetefichier
            , gestionActionFormulaire
            , SCOPE_DESCRIPTIONS
            , SCOPE_TYPE
            , SCOPE_CHAMPS
            , SCOPE_GRILLES
            , SCOPE_LISTES
            , SCOPE_LONGUEUR_MAX
            , SCOPE_LIBELLE
            , SCOPE_DESCRIPTION
            , SCOPE_UNITE
            , SCOPE_BORNE_MIN
            , SCOPE_BORNE_MAX
            , SCOPE_OBLIGATOIRE
            , SCOPE_TYPE_COMPLEXE
            , SCOPE_REF_ETRANGERE
            , SCOPE_ESTLECTURESEULE
            , $config
            , serveur
            , encryption
        ) {
            return {
                restrict: 'E',
                transclude: true,
                replace: true,
                template: function (element, attributs) {
                    var scopeSource = SCOPE_CHAMPS,
                        scopeListes = SCOPE_LISTES,
                        opModel, opListe, idListe, listeFilter, valeurLabel, valeurListe, groupListe='',
                        retour = "";

                    // Index de la grille
                    if (attributs.opModel.indexOf('[') !== -1) scopeSource = SCOPE_GRILLES;
                    opModel = scopeSource + '.' + attributs.opModel;

                    // Id
                    var id = attributs.opModel;

                    // Nom du module
                    var nomModule = attributs.opModuleNom;

                    // Liste
                    if (!angular.isNullOrUndefined(attributs.opListe)&& attributs.opListe.length>1) {
                        // Récupération de la liste,valeur et filtre
                        opListe = attributs.opListe.split(' in ');
                        idListe = opListe[opListe.length - 1].split(' |filter: ');
                        listeFilter = '';
                        if (idListe.length === 2)
                            listeFilter = ' |filter: ' + idListe[1];
                        idListe = idListe[0];

                        //  Récupération de la valeur
                        if (opListe.length === 1) {
                            valeurLabel = idListe + 'VALEUR';
                        } else {
                            opListe = opListe[0].split(' group by ');
                            if ( opListe.length >1 ){
                                groupListe = ' group by entite.' + opListe[1] + '.valeur';
                            }

                            opListe = opListe[0].split(' as ');
                            valeurListe = opListe[0];
                            if ( opListe.length >1 ){
                                valeurLabel = opListe[1];
                            }else {
                                valeurLabel = opListe[0];
                            }
                        }
                        opListe = '::' + scopeListes + '.' + idListe;
                    }

                    // Disabled
                    var lectureSeule = opModel + '.descriptions.' + SCOPE_ESTLECTURESEULE;

                    // ToolTip
                    var toolTip = opModel + '.descriptions.' + SCOPE_DESCRIPTION;

                    // PlaceHolder
                    var placeholder = opModel + '.descriptions.' + SCOPE_DESCRIPTION;

                    // TypeChamps
                    var typeaheadEditable = '!(' + opModel + '.descriptions.type == \'NUMBER\' )';

                    // Required
                    var required = opModel + '.descriptions.' + SCOPE_OBLIGATOIRE;

                    // Option des modèles
                    var modelOption = '{' +
                        'allowInvalid: true,' +
                        'updateOn: \'default blur\',' +
                        'debounce: {\'default\': 300, \'blur\': 0}' +
                        '}';

                    // change
                    var change = (!angular.isNullOrUndefined(attributs.opChange)) ? 'ng-change="' + attributs.opChange + '" ' : '';
                    if ( opModel.indexOf("ESTPRINCIPAL") !== -1 )
                        change = 'ng-change="onEstPrincipal(\'' + opModel + '\',$index,' + opModel + '.valeur)" ';

                    // Show
                    var show = (!angular.isNullOrUndefined(attributs.opShow))?attributs.opShow:true;

                    // Type
                    var type = (!angular.isNullOrUndefined(attributs.opType))?attributs.opType:'text';
                    
                    // Type Complexe
                    var typeComplexe = (!angular.isNullOrUndefined(attributs.opTypeComplexe))?attributs.opTypeComplexe:undefined;

                    // Lien
                    var bLien = (!angular.isNullOrUndefined(attributs.opLien) && (attributs.opLien.length>0));
                    var nomModuleLien, colonnesInfoLien, mapEntite;
                    if (bLien) {
                        var descriptionLien = attributs.opLien.split('|');
                        nomModuleLien = descriptionLien[0];
                        colonnesInfoLien = descriptionLien[1];
                        mapEntite = ((descriptionLien.length > 2) && !angular.isNullOrUndefined(descriptionLien[2])) ? descriptionLien[2] : "";
                    }

                    // Info bulle
                    var bInfoBulle = (!angular.isNullOrUndefined(attributs.opInfoBulle) && (attributs.opInfoBulle.length>0)) ? 'true' : 'false';
                    var colonnesInfoBulle;
                    if (bInfoBulle === 'true') {
                        var descInfoBulle = attributs.opInfoBulle.split('|');
                        if ((descInfoBulle.length > 1) && (descInfoBulle[0] === "InfoBulle")) {
                            colonnesInfoBulle = descInfoBulle[1];
                        }
                    }

                    // Scroll sur clique
                    var scrollClick = (serveur.isMobile() && navigator.userAgent.match(/Android/i)) ? "scroll-on-click " : "";

                    // Formule
                    var formule = '';
                    if ( !angular.isNullOrUndefined(attributs.opFormule) && attributs.opFormule.length>0)
                        formule = 'op-input-formule="'+attributs.opFormule+'" ';

                    // Formulaire
                    var formulaireNom = !angular.isNullOrUndefined(attributs.opForm)?attributs.opForm:"form";

                    retour += '<div class="form-group row-bottom-margin" ng-show="' + show + '">';

                    if (type === "static") {
                        if (serveur.isMobile()) {
                            retour += '<label for="\'' + id + '\'" class="col-xs-5 col-sm-3 col-md-3 col-lg-3 control-label" style="padding-top: 0px !important;padding-bottom: 0px !important;margin-top: 0px !important;margin-bottom: 0px !important;"> ';
                        } else {
                            retour += '<label for="\'' + id + '\'" class="col-xs-5 col-sm-5 col-md-3 col-lg-3 control-label" style="padding-top: 0px !important;padding-bottom: 0px !important;margin-top: 3px !important;margin-bottom: 3px !important;"> ';
                        }
                    } else {
                        if ((serveur.isMobile()) && (formulaireNom.indexOf("form_RECH") !== -1)) {
                            retour += '<label for="\'' + id + '\'" class="col-xs-7 col-sm-3 col-md-3 col-lg-3 control-label" style="padding-left: 20px !important;text-align: left !important;"> ';
                        } else {
                            retour += '<label for="\'' + id + '\'" class="col-xs-5 col-sm-3 col-md-3 col-lg-3 control-label"> ';
                        }
                    }

                    retour += '{{::' + opModel + '.descriptions.' + SCOPE_LIBELLE + '}}' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.required" style="color: orange;">&nbsp;<i class="fa fa-exclamation-triangle" title="Donnée obligatoire"></i></small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.number" style="color: red;">&nbsp;(le champ doit être un numérique)</small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.time" style="color: red;">&nbsp;(le champ doit être une date valide)</small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.pattern" style="color: red;">&nbsp;(le champ est mal formaté)</small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.minlength" style="color: red;">&nbsp;(Longueur minimum :  ' + opModel + '.descriptions.' + SCOPE_BORNE_MIN + ' )</small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.min" style="color: red;">&nbsp;(Valeur minimum : ' + opModel + '.descriptions.' + SCOPE_BORNE_MIN + ')</small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.max" style="color: red;">&nbsp;(Valeur maximum : ' + opModel + '.descriptions.' + SCOPE_BORNE_MAX + ')</small>' +
                        '<small ng-show="'+formulaireNom+'[\'' + id + '\'].$error.maxlength && warning" style="color: orange;">&nbsp;<br>Le texte saisi sera coupé à ' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + ' caractères.</small>' +
                        '</label>';

                        if ((type !== "static") && (serveur.isMobile())) {
                            retour += '<br>';
                        }

                        // Class
                        if (type === "number") {
                            retour += (serveur.isMobile()) ? '<div class="col-xs-9 col-sm-9" style="padding-left: 0px !important;"><div class="col-xs-6 col-sm-6" style="padding-left: 0px !important;">' : '<div class="col-sm-4">';
                        } else if (type === "date") {
                            retour += (serveur.isMobile()) ? '<div class="col-xs-9 col-sm-9" style="padding-left: 0px !important;">' : '<div class="col-sm-9">';
                        } else if ((type !== "static") && (type !== "datetime")) {
                            retour += (serveur.isMobile()) ? '<div class="col-xs-12 col-sm-12" style="padding-left: 0px !important;">' : '<div class="col-sm-9">';
                        }

                        // Type (année, radio, text, date, ...)
                        if (type === "annee")
                            retour +=
                                '<select class="form-control" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                 change +
                                 formule +
                                'ng-options="year as year for year in ::years">' +
                                '</select>';
                        else if (type === "radio")
                            retour += '<label class="radio-inline" ng-repeat="item in ::' + opModel + '.descriptions.' + SCOPE_REF_ETRANGERE + '.split(\';\')">' +
                            '<input type="radio" value="{{::item}}" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-disabled="' + lectureSeule + '"' +
                             change +
                             formule +
                            '>{{::item}}' +
                            '</label>';
                        else if (type === "range"){
                            retour += '<div>';
                            retour += '<rzslider ' +
                                'rz-slider-model="' + opModel + '.valeur" ' +
                                'rz-slider-options="{floor: 0, ceil: 100, step: 25, minLimit:0, maxLimit:100, showTicks:true}" '+
                                '></rzslider>';
                            retour += '</div>';
                        }
                        else if (type === "time"){
                            retour += '<div>';
                            retour += '<div timepicker ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-attr-readonly-input="' + lectureSeule + '" ' + //'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'op-timepicker-empty="' + opModel + '.valeur" ' +
                                'show-meridian="false" ' +
                                scrollClick +
                                '>' +
                                '</div>';
                            retour += '</div>';
                        }
                        else if (type === "datetime"){
                            if (serveur.isMobile()) {
                                retour += '' +
                                    '<div class="col-xs-12 col-sm-12" style="padding-left: 0px !important; padding-right: 0px !important;">' +
                                        '<div class="input-group">' +
                                            '<div class="col-xs-7 col-sm-7" style="padding-left: 0px !important; padding-right: 5px !important;">' +
                                                '<input ' +
                                                'type="date" ' +
                                                'class="form-control" ' +
                                                'id="' + id + '" ' +
                                                'name="' + id + '" ' +
                                                'ng-model="' + opModel + '.valeur" ' +
                                                'ng-readonly="' + lectureSeule + '" ' +
                                                'ng-required="{{::' + required + '}}" ' +
                                                'title="{{::' + toolTip + '}}" ' +
                                                'placeholder="{{::' + placeholder + '}}" ' +
                                                'ng-model-options="' + modelOption + '" ' +
                                                change +
                                                formule +
                                                '/>' +
                                            '</div>' +
                                            '<div class="col-xs-4 col-sm-4" style="padding-left: 0px !important; padding-right: 0px !important;">' +
                                                '<div timepicker ' +
                                                    'ng-model="' + opModel + '.valeur" ' +
                                                    'ng-attr-readonly-input="' + lectureSeule + '" ' + //'ng-readonly="' + lectureSeule + '" ' +
                                                    'ng-required="{{::' + required + '}}" ' +
                                                    'op-timepicker-empty="' + opModel + '.valeur" ' +
                                                    'show-meridian="false" ' +
                                                    'show-spinners="false" ' +
                                                    scrollClick +
                                                    '>' +
                                                '</div>' +
                                            '</div>' +
                                        '</div>'+
                                    '</div>';
                            } else {
                                retour += '' +
                                    '<div class="col-sm-4">' +
                                        '<div class="input-group">' +
                                            '<input ' +
                                            'type="text" ' +
                                            'class="form-control" ' +
                                            'id="' + id + '" ' +
                                            'name="' + id + '" ' +
                                            'ng-model="' + opModel + '.valeur" ' +
                                            'ng-readonly="' + lectureSeule + '" ' +
                                            'ng-required="{{::' + required + '}}" ' +
                                            'title="{{::' + toolTip + '}}" ' +
                                            'placeholder="{{::' + placeholder + '}}" ' +
                                            'ng-model-options="' + modelOption + '" ' +
                                            'datepicker-popup="dd/MM/yyyy" is-open="datepicker[\'' + attributs.opModel + '\']" datepicker-options="{\'starting-day\':1}" close-text="Fermer" clear-text="Initisaliser" current-text="Aujourd\'hui" ' +
                                            change +
                                            formule +
                                            '/>' +
                                            // Unité Localisation,Image,Date
                                            '<span class="input-group-btn">' +
                                                '<button type="button" class="btn btn-default" ng-disabled="' + lectureSeule + '" ng-click="open($event,\'' + attributs.opModel + '\')"><i class="glyphicon glyphicon-calendar"></i></button>' +
                                            '</span>' +
                                        '</div>' +
                                    '</div>' +
                                    '<div class="col-sm-4">' +
                                        '<div>' +
                                            '<div timepicker ' +
                                            'ng-model="' + opModel + '.valeur" ' +
                                            'ng-attr-readonly-input="' + lectureSeule + '" ' + //'ng-readonly="' + lectureSeule + '" ' +
                                            'ng-required="{{::' + required + '}}" ' +
                                            'op-timepicker-empty="' + opModel + '.valeur" ' +
                                            'show-meridian="false" ' +
                                            'show-spinners="false" ' +
                                            scrollClick +
                                            '>' +
                                            '</div>' +
                                        '</div>' +
                                    '</div>';
                            }
                        }
                        else if (type === "checkbox")
                            retour += '<div class="checkbox">'+
                            '<label> ' +
                            '<input type="checkbox" '+
                            'id="' + id + '" ' +
                            'name="' + id + '" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-true-value="1" ' +
                            'ng-false-value="0" ' +
                            'ng-disabled="' + lectureSeule + '" '+
                             change +
                             formule +
                            '>{{::' + opModel + '.descriptions.'+ SCOPE_REF_ETRANGERE +'}}' +
                            '</label>' +
                            '</div>';
                        else if  (type === "list")
                            if (bLien) {
                                var nomTableListe = opListe.split('|')[0].replace('::listes.', '').trim();
                                retour += '<select class="form-control" ' +
                                    'id="' + id + '" ' +
                                    'name="' + id + '" ' +
                                    'ng-model="' + opModel + '.valeur" ' +
                                    'ng-readonly="' + lectureSeule + '" ' +
                                    'ng-required="{{::' + required + '}}" ' +
                                    'ng-disabled="' + lectureSeule + '" ' +
                                    'op-list-init ' +
                                    'title="{{getBulleInfoListe(listes, \'' + nomTableListe + '\',\'' + colonnesInfoBulle + '\',\'' + valeurListe + '\',' + opModel + '.valeur)}}"' +
                                    change +
                                    formule +
                                    'ng-options="entite.' + valeurListe + '.valeur as entite.' + valeurLabel + '.valeur ' + groupListe + ' for entite in ' + opListe + '">' +
                                    '<option op-option-hidden="{{' + required + '}}" value=""></option>' +
                                    '</select>';
                            } else {
                                retour += ((serveur.isMobile()) && (formulaireNom.indexOf("form_RECH") !== -1)) ? '<div class="col-xs-9 col-sm-9" style="margin-left: 15px">' : '<div>';
                                retour += '<select class="form-control" ' +
                                    'id="' + id + '" ' +
                                    'name="' + id + '" ' +
                                    'ng-model="' + opModel + '.valeur" ' +
                                    'ng-readonly="' + lectureSeule + '" ' +
                                    'ng-required="{{::' + required + '}}" ' +
                                    'ng-disabled="' + lectureSeule + '" ' +
                                    'op-list-init ' +
                                    'title="{{::' + toolTip + '}}" ' +
                                    change +
                                    formule +
                                    'ng-options="entite.' + valeurListe + '.valeur as entite.' + valeurLabel + '.valeur ' + groupListe + ' for entite in ' + opListe + '">' +
                                    '<option op-option-hidden="{{' + required + '}}" value=""></option>' +
                                    '</select>' +
                                    '</div>';
                            }
                        else if  (type === "listmultiple")
                            retour += '<select multiple class="form-control" ' +
                            'id="' + id + '" ' +
                            'name="' + id + '" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-readonly="' + lectureSeule + '" >' +
                            'ng-required="{{::' + required + '}}" ' +
                            'ng-disabled="' + lectureSeule + '" '+
                            'title="{{::' + toolTip + '}}" ' +
                             change +
                            '<option ng-repeat="entite in ' + opListe + listeFilter + '">{{entite.' + valeurLabel + '.valeur}}</option>' +
                            '</select>';
                        else if (type === "text") {
                            if (typeComplexe === 'EMAIL') {
                                retour += '<div class="input-group">' +
                                '<span class="input-group-addon">@</span>' +
                                '<input ' +
                                'type="email" ' +
                                'class="form-control" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'title="{{::' + toolTip + '}}" ' +
                                'placeholder="{{::' + placeholder + '}}" ' +
                                'ng-model-options="' + modelOption + '" ' +
                                'maxlength="{{::' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                'autocomplete="on" '+
                                change +
                                formule +
                                scrollClick +
                                '/>' +
                                '</div>';
                            } else {
                                retour += ((serveur.isMobile()) && (formulaireNom.indexOf("form_RECH") !== -1)) ? '<div class="col-xs-9 col-sm-9" style="margin-left: 15px">' : '<div>';
                                retour += '<input ' +
                                    'type="text" ' +
                                    'class="form-control" ' +
                                    'id="' + id + '" ' +
                                    'name="' + id + '" ' +
                                    'ng-model="' + opModel + '.valeur" ' +
                                    'ng-readonly="' + lectureSeule + '" ' +
                                    'ng-required="{{::' + required + '}}" ' +
                                    'title="{{::' + toolTip + '}}" ' +
                                    'placeholder="{{::' + placeholder + '}}" ' +
                                    'ng-model-options="' + modelOption + '" ' +
                                    'maxlength="{{::' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    change +
                                    formule +
                                    scrollClick +
                                    '/>' +
                                    '</div>';
                            }
                        }
                        else if (type === "datalist"){
                            retour += '<div ng-if="!estDatalistRadio(\'' + id.replace(/\./g, "_") + '\')">' +
                                '<input ' +
                                'list="id_data_list_' + id.replace("[$index]","").replace(/\./g, "_") + '"' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'title="{{::' + toolTip + '}}" ' +
                                'placeholder="{{::' + placeholder + '}}" ' +
                                //'maxlength="{{::' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                //change +
                                'ng-change="changeDatalist(\''+ id +'\',\''+ attributs.opModel +'\')" ' +
                                formule +
                                scrollClick +
                                '/>' +
                                '<datalist id="id_data_list_' + id.replace("[$index]","").replace(/\./g, "_") + '" ng-if="!estDatalistFixe(\'' + id.replace(/\./g, "_") + '\')">' +
                                    '<option ng-repeat="entite in ' + opListe + '" data-id="{{entite.' + valeurListe + '.valeur}}" value="{{entite.' + valeurListe + '.valeur}}">{{entite.' + valeurLabel + '.valeur}}</option>' +
                                '</datalist>'+
                                '<datalist id="id_data_list_' + id.replace("[$index]","").replace(/\./g, "_") + '" ng-if="estDatalistFixe(\'' + id.replace(/\./g, "_") + '\')">' +
                                    '<option ng-repeat="entite in datalistListefixe.' + id.replace("[$index]","").replace(/\./g, "_") + '" data-id="{{entite.valeur}}" value="{{entite.valeur}}">{{entite.libelle}}</option>' +
                                '</datalist>'+
                                '</div>'+
                                '<div ng-if="estDatalistRadio(\'' + id.replace(/\./g, "_") + '\')">' +
                                    '<div class="btn-group">'+
                                        '<label ' +
                                        'class="btn btn-default btn-sm" ' +
                                        'name="datalistRadio_' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'ng-repeat="entite in datalistListefixe.' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'ng-model="datalistRadio_' + id.replace("[$index]","").replace(/\./g, "_") + '.valeur" ' +
                                        'ng-change="changeDatalistRadio(entite.valeur, \'' + attributs.opModel + '\')" ' +
                                        'btn-radio="entite.valeur" ' +
                                        //'uncheckable' +
                                        '>{{entite.libelle}}</label>' +
                                    '</div>' +
                                '</div>';
                        }
                        else if (type === 'typeahead'){
                            retour += '<div ng-if="!estTypeaheadRadio(\'' + id.replace(/\./g, "_") + '\')">' +
                                '<div ng-if="!estTypeaheadFixe(\'' + id.replace(/\./g, "_") + '\')">' +
                                    '<input type="hidden" id="typeaheadListe_' + id.replace("[$index]","").replace(/\./g, "_") + '_hidden" ng-model="' + opModel + '.valeur"/>' +
                                    //'<div class="input-group">' +
                                        '<input ' +
                                        'type="text" ' +
                                        'class="form-control col-sm-10 op-typeahead" ' +
                                        'id="typeaheadListe_' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'name="typeaheadListe_' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        //'ng-model="' + opModel + '.valeur" ' +
                                        'ng-model="typeaheadListe_' + id.replace("[$index]","").replace(/\./g, "_") + '.valeur" ' +
                                        'ng-readonly="' + lectureSeule + '" ' +
                                        'ng-required="{{::' + required + '}}" ' +
                                        'title="{{::' + toolTip + '}}" ' +
                                        'placeholder="{{::' + placeholder + '}}" ' +
                                        'ng-model-options="' + modelOption + '" ' +
                                        'autocomplete="off" ' +
                                        'typeahead-min-length="0" '+
                                        //'empty-typeahead ' +
                                        'typeahead-show-on-focus ' +
                                        'typeahead="entite.' + valeurLabel + '.valeur ' + groupListe + ' for entite in ' + opListe + ' | filter:$viewValue" ' + //:typeaheadComparator" ' +
                                        'typeahead-on-select="onSelectTypeAhead($item, \'' + attributs.opModel + '\', \'' + valeurListe + '\')" ' +
                                        'typeahead-template-url="moteur/composants/ecriture/OpTypeahead.html" ' +
                                        'typeahead-input-formatter="formatLabel($item, $model, \''+ nomModule +'\',\'' + opModel + '\',\''+type+'\', $index)" '+
                                        'typeahead-editable="{{::' + typeaheadEditable + '}}" ' +
                                        'change-type-ahead '+
                                        scrollClick +
                                        '/>' +
                                        //'<span class="input-group-btn">' +
                                        //'<button type="button" class="btn btn-default" ng-disabled="' + lectureSeule + '" ng-click="ouvrirTypeahead(\'typeaheadListe_\', \'' + id.replace("[$index]","").replace(/\./g, "_") + '\')"><span class="caret"></span></button>' +
                                        //'</span>' +
                                    //'</div>' +
                                '</div>'+
                                '<div ng-if="estTypeaheadFixe(\'' + id.replace(/\./g, "_") + '\')">' +
                                    '<input type="hidden" id="typeaheadListeFixe_' + id.replace("[$index]","").replace(/\./g, "_") + '_hidden" ng-model="' + opModel + '.valeur"/>' +
                                    //'<div class="input-group">' +
                                        '<input ' +
                                        'type="text" ' +
                                        'class="form-control col-sm-10 op-typeahead" ' +
                                        'id="typeaheadListeFixe_' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'name="typeaheadListeFixe_' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'ng-model="typeaheadListeFixe_' + id.replace("[$index]","").replace(/\./g, "_") + '.valeur" ' +
                                        'ng-readonly="' + lectureSeule + '" ' +
                                        'ng-required="{{::' + required + '}}" ' +
                                        'title="{{::' + toolTip + '}}" ' +
                                        'placeholder="{{::' + placeholder + '}}" ' +
                                        'ng-model-options="' + modelOption + '" ' +
                                        'autocomplete="off" ' +
                                        'typeahead-min-length="0" '+
                                        //'empty-typeahead ' +
                                        'typeahead-show-on-focus ' +
                                        'typeahead="entite.libelle for entite in typeaheadListefixe.' + id.replace("[$index]","").replace(/\./g, "_") + ' | filter:$viewValue" ' + //:typeaheadComparator" ' +
                                        'typeahead-on-select="onSelectTypeAhead($item, \'' + attributs.opModel + '\', \'' + valeurListe + '\')" ' +
                                        'typeahead-template-url="moteur/composants/ecriture/OpTypeahead.html" ' +
                                        'typeahead-input-formatter="formatLabel($item, $model, \''+ nomModule +'\',\'' + opModel + '\',\''+type+'\', $index)" '+
                                        'typeahead-editable="{{::' + typeaheadEditable + '}}" ' +
                                        'change-type-ahead '+
                                        scrollClick +
                                        '/>' +
                                        //'<span class="input-group-btn">' +
                                        //'<button type="button" class="btn btn-default" ng-disabled="' + lectureSeule + '" ng-click="ouvrirTypeahead(\'typeaheadListeFixe_\', \'' + id.replace("[$index]","").replace(/\./g, "_") + '\')"><span class="caret"></span></button>' +
                                        //'</span>' +
                                    //'</div>' +
                                '</div>'+
                                '</div>'+
                                '<div ng-if="estTypeaheadRadio(\'' + id.replace(/\./g, "_") + '\')">' +
                                    '<div class="btn-group">'+
                                        '<label ' +
                                        'class="btn btn-default btn-sm" ' +
                                        'name="typeaheadRadio_' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'ng-repeat="entite in typeaheadListefixe.' + id.replace("[$index]","").replace(/\./g, "_") + '" ' +
                                        'ng-model="typeaheadRadio_' + id.replace("[$index]","").replace(/\./g, "_") + '.valeur" ' +
                                        'ng-change="changeTypeaheadRadio(entite.valeur, \'' + attributs.opModel + '\')" ' +
                                        'btn-radio="entite.valeur" ' +
                                        //'uncheckable' +
                                        '>{{entite.libelle}}</label>' +
                                    '</div>' +
                                '</div>';
                        }
                        else if (type.indexOf("REFERENTIEL") != -1){
                            // Recherche interne coltypeinteraction = typeahead
                            // Parametre de recherche interne dans colrefetrangere : nomEcran|nomChampsCritere1|nomChampsCritere2
                            // nomChampsCritere1 correspond à CRITERE1 dans la vue de recherche
                            // le champs sur le typeahead est positionné correspond au critere par defaut RECHERCHE
                            // le resultat est dans libelle et code
                            retour += '<div><input ' +
                                'type="text" ' +
                                'class="form-control col-sm-10 op-typeahead" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'title="{{::' + toolTip + '}}" ' +
                                'placeholder="{{::' + placeholder + '}}" ' +
                                'ng-model-options="' + modelOption + '" ' +
                                'autocomplete="off" ' +
                                'typeahead-min-length="3" '+
                                'typeahead="reference.valeur as reference.libelle for reference in rechercheReferentiel(\''+ nomModule +'\',\''+type+'\',' + opModel + '.descriptions.refEtrangere,\'' + opModel + '\',$index,$viewValue,[])" ' +
                                'typeahead-on-select="onTypeAheadReferentiel($item,$model,$label,  \'' + opModel + '\', \''+type+'\', $index)" ' +
                                'typeahead-template-url="moteur/composants/ecriture/OpTypeahead.html" ' +
                                'typeahead-input-formatter="formatLabel($item,$model,\''+ nomModule +'\',\'' + opModel + '\',\''+type+'\',$index)" '+
                                'typeahead-editable="{{::' + typeaheadEditable + '}}" change-type-ahead '+
                                scrollClick +
                                '/>' +
                                '</div>';
                        }
                        else if (type === "tel")
                            retour += '<input ng-init="maskTelephone=\'9999 999 999\'" ' +
                            'type="tel" ' +
                            'class="form-control" ' +
                            'id="' + id + '" ' +
                            'name="' + id + '" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-readonly="' + lectureSeule + '" ' +
                            'ng-required="{{::' + required + '}}" ' +
                            'title="{{::' + toolTip + '}}" ' +
                            'ng-model-options="' + modelOption + '" ' +
                             change +
                            'ui-mask="{{maskTelephone}}" ' +
                            scrollClick +
                            '/>';
                        else if (type === "number") {
                            retour += '<div class="input-group" ><input ' +
                                'type="number" ' +
                                'class="form-control" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'title="{{::' + toolTip + '}}" ' +
                                'placeholder="{{::' + placeholder + '}}" ' +
                                'ng-model-options="' + modelOption + '" ' +
                                change +
                                'min={{::' + opModel + '.descriptions.' + SCOPE_BORNE_MIN + '}} ' +
                                'max={{::' + opModel + '.descriptions.' + SCOPE_BORNE_MAX + '}} ' +
                                formule +
                                scrollClick +
                                '/>' +
                                '<div class="input-group-addon" ng-if="::(' + opModel + '.descriptions.' + SCOPE_UNITE + '.length>0)">' +
                                '{{' + opModel + '.descriptions.' + SCOPE_UNITE + '}}' +
                                '</div>' +
                                '</div>';
                        }
                        else if (type === "date") {
                            if (serveur.isMobile()) {
                                retour += '<div class="input-group">' +
                                '<input ' +
                                'type="date" ' +
                                'class="form-control" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'title="{{::' + toolTip + '}}" ' +
                                'placeholder="{{::' + placeholder + '}}" ' +
                                'ng-model-options="' + modelOption + '" ' +
                                change +
                                formule +
                                '/>' +
                                '</div>';
                            } else {
                                retour += '<div class="input-group">' +
                                '<input ' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'id="' + id + '" ' +
                                'name="' + id + '" ' +
                                'ng-model="' + opModel + '.valeur" ' +
                                'ng-readonly="' + lectureSeule + '" ' +
                                'ng-required="{{::' + required + '}}" ' +
                                'title="{{::' + toolTip + '}}" ' +
                                'placeholder="{{::' + placeholder + '}}" ' +
                                'ng-model-options="' + modelOption + '" ' +
                                'datepicker-popup="dd/MM/yyyy" is-open="datepicker[\'' + attributs.opModel + '\']" datepicker-options="{\'starting-day\':1}" close-text="Fermer" clear-text="Initisaliser" current-text="Aujourd\'hui" ' +
                                    change +
                                    formule +
                                '/>' +
                                    // Unité Localisation,Image,Date
                                '<span class="input-group-btn">' +
                                '<button type="button" class="btn btn-default" ng-disabled="' + lectureSeule + '" ng-click="open($event,\'' + attributs.opModel + '\')"><i class="glyphicon glyphicon-calendar"></i></button>' +
                                '</span>' +
                                '</div>';
                            }
                        }
                        else if (type === "textarea")
                            retour += '<textarea type="text" ' +
                            'class="form-control col-sm-10" ' +
                            'id="' + id + '" ' +
                            'name="' + id + '" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-readonly="' + lectureSeule + '" ' +
                            'ng-required="{{::' + required + '}}" ' +
                            'title="{{::' + toolTip + '}}" ' +
                            'placeholder="{{::' + placeholder + '}}" ' +
                            'ng-model-options="' + modelOption + '" ' +
                             change +
                            'maxlength="{{::' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                            scrollClick +
                            '>' +
                            '</textarea>';
                        else if (type === "fichier")
                            retour += '<label class="btn btn-primary">' +
                            'Parcourir ...' +
                            '<input  ' +
                            'type="file" style="display:none;" ' +
                            'id="' + id + '" ' +
                            'name="' + id + '" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-disabled="' + lectureSeule + '" ' +
                            'ng-required="{{::' + required + '}}" ' +
                            'title="{{::' + toolTip + '}}" ' +
                            'placeholder="Parcourir ..." ' +
                            'ng-model-options="' + modelOption + '" ' +
                             change +
                            'onchange="angular.element(this).scope().handleFileSelect(event)" ' +
                            '/>' +
                            '</label>';
                        else if (type === "password"){
                            retour += '<div>' +
                            '<input ' +
                            'type="password" ' +
                            'class="form-control" ' +
                            'id="' + id + '" ' +
                            'name="' + id + '" ' +
                            'ng-model="' + opModel + '.valeur" ' +
                            'ng-readonly="' + lectureSeule + '" ' +
                            'ng-required="{{::' + required + '}}" ' +
                            'title="{{::' + toolTip + '}}" ' +
                            'placeholder="{{::' + placeholder + '}}" ' +
                            'ng-model-options="' + modelOption + '" ' +
                            'ng-change="modifierMotDePasse(\''+ attributs.opModel +'\')"' +
                            '/>' +
                            '</div>';
                        }
                        else if (type === "static") {
                            if (serveur.isMobile()) {
                                retour += '<div class="commentaire col-xs-6 col-sm-8">' +
                                    '<p class="form-control-static commentaire col-sm-12 operis-padding-topbottom-zero" ng-attr-title="{{::versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions)}}">' +
                                    //'<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="' + bInfoBulle + ' && (versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions) !== \'\')" ' +
                                    //'ng-attr-title="{{::getBulleInfo($parent.champs, ' + opModel + '.descriptions.tablenom,\'' + colonnesInfoBulle + '\')}}">&nbsp;</i>' +
                                    '{{::versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions)}}' +
                                    '</p>' +
                                    '</div>';
                            } else if (!serveur.isMobile() && bLien) {
                                retour += '<div class="col-xs-7 col-sm-7 col-md-9 col-lg-9">' +
                                    '<p class="form-control-static commentaire" style="padding-top: 0px !important;padding-bottom: 0px !important;margin-top: 3px !important;margin-bottom: 3px !important;">' + //'<p class="form-control-static operis-padding-topbottom-zero">' +
                                    '<i class="fa fa-lock" ng-if="$parent.opVueFormulaire.entiteFiche.DROITACCES<=0"></i>' +
                                    '<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="' + bInfoBulle + ' && (versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions) !== \'\')" ' +
                                    'ng-attr-title="{{::getBulleInfo($parent.champs, ' + opModel + '.descriptions.tablenom,\'' + colonnesInfoBulle + '\')}}">&nbsp;</i>' +
                                    '<a ng-class="{\'linkDisabled\': $parent.opVueFormulaire.entiteFiche.DROITACCES<=0}" ' +
                                    'href="" style="font-weight: bold;" ' +
                                    'ng-click="linkButtonOuvrirActivite_click(\'' + nomModuleLien + '\',\'' + mapEntite + '\',\'' + opModel + '\', $index, \'CHAMPS\')" ' +
                                    'ng-if="habilitationLienModule(\'' + nomModuleLien + '\',\'' + mapEntite + '\',\'' + opModel + '\', $index, \'CHAMPS\')">' + // Mantis 6194 Contrôle des habilitations pour les liens inter-modules
                                    '{{::versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions)}}' +
                                    '</a>' +
									'<span ng-if="!habilitationLienModule(\'' + nomModuleLien + '\',\'' + mapEntite + '\',\'' + opModel + '\', $index, \'CHAMPS\')">' +
									'{{::versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions)}}' +
									'</span>' +
                                    '</p>' +
                                    '</div>';
                            } else {
                                retour += '<div class="col-xs-7 col-sm-7 col-md-9 col-lg-9">' + //retour += '<div class="commentaire">' +
                                    '<p class="form-control-static commentaire operis-padding-topbottom-zero" ng-attr-title="{{::versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions)}}">' +
                                    '<i class="fa fa-info-circle" style="color: forestgreen;" ng-if="' + bInfoBulle + ' && (versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions) !== \'\')" ' +
                                    'ng-attr-title="{{::getBulleInfo($parent.champs, ' + opModel + '.descriptions.tablenom,\'' + colonnesInfoBulle + '\')}}">&nbsp;</i>' +
                                    '{{::versTexte(' + opModel + '.valeur ' + ',' + '' + opModel + '.descriptions)}}' +
                                    '</p>' +
                                    '</div>';
                            }
                        }

                    retour += (type === "number") ? '</div></div></div>' : '</div></div>';

                    return retour;
                },
                link: function ($scope, element, attrs) {

                    // Fonction d'année
                    if (angular.isNullOrUndefined($scope.years)){
                        $scope.years = [];
                        for (var i=1900;i<2050;i++){
                            $scope.years.push(i);
                        }
                    }

                    /**
                     * Ecouteur de suppression
                     */
                    $scope.$on('$destroy', function () {
                        if (!angular.isNullOrUndefined( requetereferentiel._referentiel)){
                            for ( var property in requetereferentiel._referentiel){
                                if ( property.indexOf(attrs.opModuleNom) !== -1 ){
                                    for ( var key in requetereferentiel._referentiel[property]){
                                        if ( key.indexOf("_libelleInitial") !== -1 )
                                            delete requetereferentiel._referentiel[property][key];
                                    }
                                }

                            }
                        }

                    });

                    // Gestion des typeahead
                    if (attrs.opType === "typeahead") {
                        // Affichage des boutons radio ?
                        if (angular.isNullOrUndefined($scope.estTypeaheadRadio)) {
                            $scope.estTypeaheadRadio = function (e) {
                                if (angular.isNullOrUndefined($scope.paramTypeahead)) {
                                    return false;
                                } else if (angular.isNullOrUndefined($scope.paramTypeahead[e.replace("[$index]", "")])) {
                                    return false;
                                } else {
                                    return $scope.paramTypeahead[e.replace("[$index]", "")].listeFixeRadio;
                                }
                            };
                        }

                        //La source des données est-elle une liste fixe ?
                        if (angular.isNullOrUndefined($scope.estTypeaheadFixe)) {
                            $scope.estTypeaheadFixe = function (e) {
                                if (angular.isNullOrUndefined($scope.paramTypeahead)) {
                                    return false;
                                } else if (angular.isNullOrUndefined($scope.paramTypeahead[e.replace("[$index]", "")])) {
                                    return false;
                                } else {
                                    return $scope.paramTypeahead[e.replace("[$index]", "")].listeFixe;
                                }
                            };
                        }

                        // Init typeahead
                        var nomTableColonne = attrs.opModel.split('.');
                        var estGrille = (attrs.opModel.indexOf('[$index]') !== -1);

                        var opListe = attrs.opListe.split(' in ');
                        var tableListe = opListe[opListe.length - 1].split(' |')[0];

                        opListe = opListe[0].split(' group by ');
                        opListe = opListe[0].split(' as ');

                        var colonneCle = opListe[0];
                        var colonneLibelle = colonneCle;
                        if (opListe.length > 1) {
                            colonneLibelle = opListe[1];
                        }

                        // Gestion des listes fixes (Clé 1#Valeur 1#Complément 1#Titre 1|Clé 2#Valeur 2#Complément 2#Titre 2|Clé 3#Valeur 3#Complément 3#Titre 3|...)
                        if (angular.isNullOrUndefined($scope.typeaheadListefixe)) {
                            $scope.typeaheadListefixe = [];
                        }

                        var idListeFixe = nomTableColonne[0].replace("[$index]","") + "_" + nomTableColonne[1];
                        if (angular.isNullOrUndefined($scope.typeaheadListefixe[idListeFixe])) {
                            $scope.typeaheadListefixe[idListeFixe] = [];

                            if ((!angular.isNullOrUndefined($scope.listes[tableListe])) && ($scope.listes[tableListe].length === 1)) {
                                var lv = $scope.listes[tableListe][0][colonneCle].valeur.split("|");
                                if (lv[0] === "FIXE") {
                                    for (var k = 1; k < lv.length; k++) {
                                        var objValeur = {};
                                        var valeur = lv[k].split("#");
                                        objValeur.valeur = valeur[0];
                                        objValeur.libelle = valeur[0];

                                        if (valeur.length > 1) {
                                            objValeur.libelle = valeur[1];

                                            if (valeur.length > 2) {
                                                objValeur.complementLibelle = valeur[2];

                                                if (valeur.length > 3) {
                                                    objValeur.titre = valeur[3];
                                                }
                                            }
                                        }

                                        $scope.typeaheadListefixe[idListeFixe].push(objValeur);
                                    }
                                }
                            }
                        }

                        // Paramètres d'affichage du typeahead
                        if (angular.isNullOrUndefined($scope.paramTypeahead)) {
                            $scope.paramTypeahead = [];
                        }

                        if (angular.isNullOrUndefined($scope.paramTypeahead[idListeFixe])) {
                            $scope.paramTypeahead[idListeFixe] = {};

                            if (angular.isNullOrUndefined($scope.paramTypeahead[idListeFixe].listeFixe)) {
                                $scope.paramTypeahead[idListeFixe].listeFixe = false;

                                if ((!angular.isNullOrUndefined($scope.listes[tableListe])) && ($scope.listes[tableListe].length === 1)) {
                                    var listeValeurs = $scope.listes[tableListe][0][colonneCle].valeur.split("|");
                                    $scope.paramTypeahead[idListeFixe].listeFixe = (listeValeurs[0] === "FIXE");
                                }
                            }

                            $scope.paramTypeahead[idListeFixe].listeFixeRadio = ($scope.paramTypeahead[idListeFixe].listeFixe &&
                            ($scope.typeaheadListefixe[idListeFixe].length > 0) && ($scope.typeaheadListefixe[idListeFixe].length < 4));
                        }

                        // Init valeur boutons radio
                        $scope["typeaheadRadio_" + idListeFixe]= {valeur: ""};
                        if ($scope.paramTypeahead[idListeFixe].listeFixe) {
                            for (var l = 0; l < $scope.typeaheadListefixe[idListeFixe].length; l++) {
                                var cle = $scope.typeaheadListefixe[idListeFixe][l].valeur;
                                var lib = $scope.typeaheadListefixe[idListeFixe][l].libelle;

                                if (estGrille) {
                                    var tableGrilles = nomTableColonne[0].split('[');
                                    if (cle == $scope.entite[attrs.opModel.split('.')[1]].valeur) {
                                        $scope["typeaheadRadio_" + idListeFixe] = {valeur: cle};
                                        $scope["typeaheadListeFixe_" + idListeFixe] = {valeur: lib};
                                        break;
                                    }
                                } else {
                                    if (cle == $scope.champs[attrs.opModel.split('.')[0]][attrs.opModel.split('.')[1]].valeur) {
                                        $scope["typeaheadRadio_" + idListeFixe] = {valeur: cle};
                                        $scope["typeaheadListeFixe_" + idListeFixe] = {valeur: lib};
                                        break;
                                    }
                                }
                            }
                        } else {
                            if (!angular.isNullOrUndefined($scope.listes[tableListe])) {
                                for (var j = 0; j < $scope.listes[tableListe].length; j++) {
                                    var cleListe = $scope.listes[tableListe][j][colonneCle].valeur;
                                    var libListe = $scope.listes[tableListe][j][colonneLibelle].valeur;

                                    if (estGrille) {
                                        var nomTable = nomTableColonne[0].split('[');
                                        if (cleListe == $scope.entite[attrs.opModel.split('.')[1]].valeur) {
                                            $scope["typeaheadRadio_" + idListeFixe] = {valeur: cleListe};
                                            $scope["typeaheadListe_" + idListeFixe] = {valeur: libListe};
                                            break;
                                        }
                                    } else {
                                        if (cleListe == $scope.champs[attrs.opModel.split('.')[0]][attrs.opModel.split('.')[1]].valeur) {
                                            $scope["typeaheadRadio_" + idListeFixe] = {valeur: cleListe};
                                            $scope["typeaheadListe_" + idListeFixe] = {valeur: libListe};
                                            break;
                                        }
                                    }
                                }
                            }
                        }

                        // ngChange boutons radio
                        if (angular.isNullOrUndefined($scope.changeTypeaheadRadio)) {
                            $scope.changeTypeaheadRadio = function(valeur, model) {
                                var descriptionModel = model.split('.');

                                if (!angular.isNullOrUndefined($scope.entite)) {
                                    if ($scope.entite[descriptionModel[1]].descriptions.type === "NUMBER") {
                                        $scope.entite[descriptionModel[1]].valeur = Number(valeur);
                                    } else {
                                        $scope.entite[descriptionModel[1]].valeur = valeur;
                                    }
                                } else {
                                    if ($scope.champs[descriptionModel[0]][descriptionModel[1]].descriptions.type === "NUMBER") {
                                        $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = Number(valeur);
                                    } else {
                                        $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = valeur;
                                    }
                                }
                            }
                        }

                        // typeaheadOnSelect
                        if (angular.isNullOrUndefined($scope.onSelectTypeAhead)) {
                            $scope.onSelectTypeAhead = function(item, model, nomChampValeur) {
                                var descriptionModel = model.split('.');

                                if (!angular.isNullOrUndefined(item[nomChampValeur])) {
                                    if (!angular.isNullOrUndefined($scope.entite)) {
                                        if ($scope.entite[descriptionModel[1]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                            $scope.entite[descriptionModel[1]].valeur = Number(item[nomChampValeur].valeur);
                                        } else {
                                            $scope.entite[descriptionModel[1]].valeur = item[nomChampValeur].valeur;
                                        }
                                    } else {
                                        if ($scope.champs[descriptionModel[0]][descriptionModel[1]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                            $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = Number(item[nomChampValeur].valeur);
                                        } else {
                                            $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = item[nomChampValeur].valeur;
                                        }
                                    }
                                } else {
                                    if (!angular.isNullOrUndefined($scope.entite)) {
                                        if ($scope.entite[descriptionModel[1]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                            $scope.entite[descriptionModel[1]].valeur = Number(item.valeur);
                                        } else {
                                            $scope.entite[descriptionModel[1]].valeur = item.valeur;
                                        }
                                    } else {
                                        if ($scope.champs[descriptionModel[0]][descriptionModel[1]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                            $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = Number(item.valeur);
                                        } else {
                                            $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = item.valeur;
                                        }
                                    }
                                }
                            }
                        }

                        // Ouverture liste typeahead
                        /*$scope.typeaheadComparator = function (actualValue, viewValue) {
                            return viewValue === '[$empty$]' || ('' + actualValue).toLowerCase().indexOf(('' + viewValue).toLowerCase()) > -1;
                        };

                        if (angular.isNullOrUndefined($scope.ouvrirTypeahead)) {
                            $scope.ouvrirTypeahead = function (prefixe, id) {
                                $timeout(function () {
                                    // TODO : trouver un moyen de déclencher le typeahead suite première passage ici sans sélection d'élément car dans ce cas val() est déjà à '' et triggerHandler est sans effet
                                    // Vider l'input puis déclencher le typeahead
                                    angular.element(document.querySelectorAll('#' + prefixe + id)).val('').triggerHandler('input');
                                    angular.element(document.querySelectorAll('#' + prefixe + id)).val('').triggerHandler('change'); // for IE
                                });
                            }
                        }*/
                    }

                    // Gestion des list
                    if (attrs.opType === "list") {
                        /**
                         * Gestion bulle d'info
                         */
                        if (angular.isNullOrUndefined($scope.getBulleInfoListe)) {
                            $scope.getBulleInfoListe = function(model, nomTableListe, listeCols, nomColChampListe, valeurEntite) {
                                var cols = listeCols.split("#");
                                var texte = "";
                                if (!angular.isNullOrUndefined(model[nomTableListe])) {
                                    for (var l = 0; l < model[nomTableListe].length; l++) {
                                        if (model[nomTableListe][l][nomColChampListe].valeur === valeurEntite) {
                                            for (var i = 0; i < cols.length; i++) {
                                                if (!angular.isNullOrUndefined(model[nomTableListe][l][cols[i]])) {
                                                    if (!angular.isNullOrUndefined(model[nomTableListe][l][cols[i]].valeur) &&
                                                        ($scope.versTexte(model[nomTableListe][l][cols[i]].valeur, model[nomTableListe][l][cols[i]].descriptions).trim() !== "")) {
                                                        if (texte !== "")
                                                            texte += String.fromCharCode(13);

                                                        if (!angular.isNullOrUndefined(model[nomTableListe][l][cols[i]].descriptions.libelle) &&
                                                            (model[nomTableListe][l][cols[i]].descriptions.libelle.trim() !== "")) {
                                                            texte += model[nomTableListe][l][cols[i]].descriptions.libelle.trim() + " : ";
                                                        }

                                                        texte += $scope.versTexte(model[nomTableListe][l][cols[i]].valeur, model[nomTableListe][l][cols[i]].descriptions).trim();
                                                    }
                                                }
                                            }

                                            break;
                                        }
                                    }
                                }

                                return texte;
                            }
                        }

                        /**
                         * Changement de valeur liste
                         */
                        if (angular.isNullOrUndefined($scope.gererChangeListe)) {
                            $scope.gererChangeListe = function (e) {
                                if (!angular.isNullOrUndefined(e.mapChamps) && (e.mapChamps !== "")) {
                                    // Renseignement des champs liés à l'élément de la liste
                                    //var modele = e.opModel;
                                    //var index = e.index;
                                    var m = e.mapChamps.split('#');
                                    var tableListe = m[0];
                                    var mapColCle = m[1].split(':');
                                    var colCleEntite = mapColCle[0];
                                    var colCleListe = (mapColCle.length === 2) ? mapColCle[1] : colCleEntite;
                                    var mapColsListe = m[2].split(';');

                                    if (!angular.isNullOrUndefined($scope.entite[colCleEntite]) &&
                                        !angular.isNullOrUndefined($scope.listes[tableListe])) {
                                        var valeurCle = $scope.entite[colCleEntite].valeur;

                                        for (var l = 0; l < $scope.listes[tableListe].length; l++) {
                                            if (!angular.isNullOrUndefined($scope.listes[tableListe][l][colCleListe]) &&
                                                ($scope.listes[tableListe][l][colCleListe].valeur === valeurCle)) {
                                                for (var i = 0; i < mapColsListe.length; i++) {
                                                    var colSaisie = mapColsListe[i].split(':')[0];
                                                    var colListe = mapColsListe[i].split(':')[1];

                                                    if (!angular.isNullOrUndefined($scope.entite[colSaisie]) &&
                                                        !angular.isNullOrUndefined($scope.listes[tableListe][l][colListe])) {
                                                        $scope.entite[colSaisie].valeur = $scope.listes[tableListe][l][colListe].valeur;
                                                    }
                                                }

                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Gestion des datalist
                    if (attrs.opType === "datalist") {
                        // Affichage des boutons radio ?
                        if (angular.isNullOrUndefined($scope.estDatalistRadio)) {
                            $scope.estDatalistRadio = function (e) {
                                if (angular.isNullOrUndefined($scope.paramDatalist)) {
                                    return false;
                                } else if (angular.isNullOrUndefined($scope.paramDatalist[e.replace("[$index]", "")])) {
                                    return false;
                                } else {
                                    return $scope.paramDatalist[e.replace("[$index]", "")].listeFixeRadio;
                                }
                            };
                        }

                        //La source des données est-elle une liste fixe ?
                        if (angular.isNullOrUndefined($scope.estDatalistFixe)) {
                            $scope.estDatalistFixe = function (e) {
                                if (angular.isNullOrUndefined($scope.paramDatalist)) {
                                    return false;
                                } else if (angular.isNullOrUndefined($scope.paramDatalist[e.replace("[$index]", "")])) {
                                    return false;
                                } else {
                                    return $scope.paramDatalist[e.replace("[$index]", "")].listeFixe;
                                }
                            };
                        }

                        // ngChange datalist
                        if (angular.isNullOrUndefined($scope.changeDatalist)) {
                            $scope.changeDatalist = function(idElement, model) {
                                var elem = document.getElementById(idElement);
                                var list = elem.getAttribute('list');
                                var options = document.querySelectorAll('#' + list + ' option');
                                var inputValue = elem.value;
                                var descriptionModel = model.split('.');

                                for (var i = 0; i < options.length; i++) {
                                    var option = options[i];
                                    if (option.value === inputValue) {
                                        if (!angular.isNullOrUndefined($scope.entite)) {
                                            if ($scope.entite[descriptionModel[1]].descriptions.type === "NUMBER") {
                                                $scope.entite[descriptionModel[1]].valeur = Number(option.getAttribute('data-id'));
                                            } else {
                                                $scope.entite[descriptionModel[1]].valeur = option.getAttribute('data-id');
                                            }
                                        } else {
                                            if ($scope.champs[descriptionModel[0]][descriptionModel[1]].descriptions.type === "NUMBER") {
                                                $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = Number(option.getAttribute('data-id'));
                                            } else {
                                                $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = option.getAttribute('data-id');
                                            }
                                        }

                                        break;
                                    }
                                }
                            }
                        }

                        // ngChange boutons radio
                        if (angular.isNullOrUndefined($scope.changeDatalistRadio)) {
                            $scope.changeDatalistRadio = function(valeur, model) {
                                var descriptionModel = model.split('.');

                                if (!angular.isNullOrUndefined($scope.entite)) {
                                    if ($scope.entite[descriptionModel[1]].descriptions.type === "NUMBER") {
                                        $scope.entite[descriptionModel[1]].valeur = Number(valeur);
                                    } else {
                                        $scope.entite[descriptionModel[1]].valeur = valeur;
                                    }
                                } else {
                                    if ($scope.champs[descriptionModel[0]][descriptionModel[1]].descriptions.type === "NUMBER") {
                                        $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = Number(valeur);
                                    } else {
                                        $scope.champs[descriptionModel[0]][descriptionModel[1]].valeur = valeur;
                                    }
                                }
                            }
                        }

                        // Init datalist
                        var nomTableColonne = attrs.opModel.split('.');
                        var estGrille = (attrs.opModel.indexOf('[$index]') !== -1);

                        var opListe = attrs.opListe.split(' in ');
                        var tableListe = opListe[opListe.length - 1].split(' |')[0];

                        opListe = opListe[0].split(' group by ');
                        opListe = opListe[0].split(' as ');

                        var colonneCle = opListe[0];
                        var colonneLibelle = colonneCle;
                        if (opListe.length > 1) {
                            colonneLibelle = opListe[1];
                        }

                        // Gestion des listes fixes (Clé 1#Valeur 1|Clé 2#Valeur 2|Clé 3#Valeur 3|...)
                        if (angular.isNullOrUndefined($scope.datalistListefixe)) {
                            $scope.datalistListefixe = [];
                        }

                        var idListeFixe = nomTableColonne[0].replace("[$index]","") + "_" + nomTableColonne[1];
                        if (angular.isNullOrUndefined($scope.datalistListefixe[idListeFixe])) {
                            $scope.datalistListefixe[idListeFixe] = [];

                            if ((!angular.isNullOrUndefined($scope.listes[tableListe])) && ($scope.listes[tableListe].length === 1)) {
                                var lv = $scope.listes[tableListe][0][colonneCle].valeur.split("|");
                                if (lv[0] === "FIXE") {
                                    for (var k = 1; k < lv.length; k++) {
                                        var objValeur = {};
                                        var valeur = lv[k].split("#");
                                        objValeur.valeur = valeur[0];
                                        objValeur.libelle = valeur[0];
                                        if (valeur.length > 1) {
                                            objValeur.libelle = valeur[1];
                                        }

                                        $scope.datalistListefixe[idListeFixe].push(objValeur);
                                    }
                                }
                            }
                        }

                        // Paramètres d'affichage du dataliste
                        if (angular.isNullOrUndefined($scope.paramDatalist)) {
                            $scope.paramDatalist = [];
                        }

                        if (angular.isNullOrUndefined($scope.paramDatalist[idListeFixe])) {
                            $scope.paramDatalist[idListeFixe] = {};

                            if (angular.isNullOrUndefined($scope.paramDatalist[idListeFixe].listeFixe)) {
                                $scope.paramDatalist[idListeFixe].listeFixe = false;

                                if ((!angular.isNullOrUndefined($scope.listes[tableListe])) && ($scope.listes[tableListe].length === 1)) {
                                    var listeValeurs = $scope.listes[tableListe][0][colonneCle].valeur.split("|");
                                    $scope.paramDatalist[idListeFixe].listeFixe = (listeValeurs[0] === "FIXE");
                                }
                            }

                            $scope.paramDatalist[idListeFixe].listeFixeRadio = ($scope.paramDatalist[idListeFixe].listeFixe &&
                                ($scope.datalistListefixe[idListeFixe].length > 0) && ($scope.datalistListefixe[idListeFixe].length < 4));
                        }

                        // Init valeur datalist
                        $scope["datalistRadio_" + idListeFixe] = {valeur: ""};
                        if ($scope.paramDatalist[idListeFixe].listeFixe) {
                            for (var l = 0; l < $scope.datalistListefixe[idListeFixe].length; l++) {
                                var cle = $scope.datalistListefixe[idListeFixe][l].valeur;
                                //var lib = $scope.datalistListefixe[idListeFixe][l].libelle;

                                if (estGrille) {
                                    var tableGrilles = nomTableColonne[0].split('[');
                                    if (cle == $scope.entite[attrs.opModel.split('.')[1]].valeur) {
                                        $scope["datalistRadio_" + idListeFixe] = {valeur: cle};
                                        break;
                                    }
                                } else {
                                    if (cle == $scope.champs[attrs.opModel.split('.')[0]][attrs.opModel.split('.')[1]].valeur) {
                                        $scope["datalistRadio_" + idListeFixe] = {valeur: cle};
                                        break;
                                    }
                                }
                            }
                        } else {
                            if (!angular.isNullOrUndefined($scope.listes[tableListe])) {
                                for (var j = 0; j < $scope.listes[tableListe].length; j++) {
                                    var cleListe = $scope.listes[tableListe][j][colonneCle].valeur;
                                    //var libListe = $scope.listes[tableListe][j][colonneLibelle].valeur;

                                    if (estGrille) {
                                        var nomTable = nomTableColonne[0].split('[');
                                        if (cleListe == $scope.entite[attrs.opModel.split('.')[1]].valeur) {
                                            $scope["datalistRadio_" + idListeFixe] = {valeur: cleListe};
                                            break;
                                        }
                                    } else {
                                        if (cleListe == $scope.champs[attrs.opModel.split('.')[0]][attrs.opModel.split('.')[1]].valeur) {
                                            $scope["datalistRadio_" + idListeFixe] = {valeur: cleListe};
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Fonction de moficiation de mot passe
                    if (angular.isNullOrUndefined($scope.modifierMotDePasse)){
                        $scope.modifierMotDePasse = function(model){
                            // Nom de la table
                            if ( angular.isNullOrUndefined(model) )
                                return;

                            var descriptionModel = model.split('.');
                            if ( !angular.isNullOrUndefined(descriptionModel) && descriptionModel.length>1 ){
                                var tableNom = descriptionModel[0];
                                var colNom = descriptionModel[1];

                                if ( !angular.isNullOrUndefined($scope.descriptions[tableNom])
                                    && !angular.isNullOrUndefined($scope.descriptions[tableNom][colNom] )){
                                    var colclefetrangere = $scope.descriptions[tableNom][colNom].refEtrangere.split('|');
                                    if ( !angular.isNullOrUndefined(colclefetrangere) && colclefetrangere.length>1 ){
                                        var colNomLogin = colclefetrangere [0];
                                        var colNomPassword = colclefetrangere [1];

                                        $scope.champs[tableNom][colNomPassword].valeur =
                                            encryption.encryptageBDMotDePasse($scope.champs[tableNom][colNomLogin].valeur
                                                , $scope.champs[tableNom][colNom].valeur);
                                    }

                                }
                            }
                        }
                    }

                    // Ajout de la fonction d'ouverture du datepicker
                    if (angular.isNullOrUndefined($scope.open)) {
                        $scope.open = function ($event, opened) {
                            $event.preventDefault();
                            $event.stopPropagation();
                            if (typeof($scope.datepicker) === 'undefined') {
                                $scope.datepicker = {};
                            }
                            $scope.datepicker[opened] = true;
                        };
                    }

                    // Ajout de la fonction de récupération de la valeur texte
                    if (angular.isNullOrUndefined($scope.versTexte)) {
                        $scope.versTexte = function (aValeur, aDescription) {
                            if (aDescription.typeInteraction === "CHECKBOX") {
                                if (aValeur == "1") {
                                    return 'Oui';
                                } else {
                                    return 'Non';
                                }
                            } else {
                                return conversion.versTexte(aValeur, aDescription.type);
                            }
                        };
                    }

                    if (angular.isNullOrUndefined($scope.formatLabel)){
                        $scope.formatLabel = function($item, $model, aModuleNom, id, aNomReferentiel, index){
                            if ( !angular.isNullOrUndefined ( $item ) && aNomReferentiel.indexOf("REFERENTIEL_INTERNE_") !== -1 )
                                return $item.libelle;
                            else if ( !angular.isNullOrUndefined ($model) && aNomReferentiel.indexOf("REFERENTIEL_INTERNE_") !== -1){
                                var idModele = id;
                                if ( !angular.isNullOrUndefined (index) )
                                    idModele = id.replace("$index",index.toString());
                                if ( !angular.isNullOrUndefined(requetereferentiel._referentiel[aModuleNom + aNomReferentiel.replace("REFERENTIEL_INTERNE_","")])
                                        && !angular.isNullOrUndefined(requetereferentiel._referentiel[aModuleNom + aNomReferentiel.replace("REFERENTIEL_INTERNE_","")][idModele + "_libelleInitial"])){

                                    return (requetereferentiel._referentiel[aModuleNom + aNomReferentiel.replace("REFERENTIEL_INTERNE_","")][idModele + "_libelleInitial"]);
                                }else{
                                    requetereferentiel.rechercher($scope, aModuleNom, aNomReferentiel, null, id, index, null, null, $model).then(
                                        function (retourRecherche) {
                                            var libelleInitial = angular.isNullOrUndefined(retourRecherche[0]) ? '' : retourRecherche[0].libelle;
                                            requetereferentiel._referentiel[aModuleNom + aNomReferentiel.replace("REFERENTIEL_INTERNE_", "")][idModele + "_libelleInitial"] = libelleInitial;

                                            // Nom modèle
                                            var nomModele = id.replace("champs.","");
                                            nomModele = nomModele.replace("grilles.","");

                                            // Initialiser le modèle
                                            gestionActionFormulaire.setValeur($scope, nomModele, index, -1);
                                            $timeout(function () {
                                                $scope.$apply();
                                                gestionActionFormulaire.setValeur($scope, nomModele, index, $model);
                                            })
                                        })
                                }

                            }
                            else
                                return $model;
                        }
                    }

                    // Fonction de recherche de référentiel interne ou externe
                    if (angular.isNullOrUndefined($scope.rechercheReferentiel)){
						$scope.rechercheReferentiel = function( nomModule, nomReferentiel, parametreReferentiel, idModele,index, valeurRecherche, filtreRecherche ){
                            return requetereferentiel.rechercher( $scope, nomModule, nomReferentiel, parametreReferentiel, idModele, index, valeurRecherche, filtreRecherche );                        }
                    }

                    // Fonction de mise à jour des champs liés au retour du résultat de la recherche du référentiel
                    if (angular.isNullOrUndefined($scope.onTypeAheadReferentiel)) {
                        $scope.onTypeAheadReferentiel = function ($item,$model,$label, id, nomReferentiel, $index) {
                            referentielServlet.onChangeReferentiel($scope, $item, $model,$label, id, nomReferentiel, $index);
                        };
                    }

                    // Ajout de la fonction d'ouverture du sélectionneur de fichier
                    if (angular.isNullOrUndefined($scope.handleFileSelect)) {
                        $scope.handleFileSelect = function (evt) {
                            evt.preventDefault();
                            evt.stopPropagation();

                            requetefichier.ajouterFichier($scope,evt);
                        };
                    }

                    // Recupérer info pour les bulles d'information
                    if (angular.isNullOrUndefined($scope.getBulleInfo)) {
                        $scope.getBulleInfo = function(model, nomTable, listeCols) {
                            var cols = listeCols.split("#");
                            var texte = "";
                            if (!angular.isNullOrUndefined(model[nomTable])) {
                                for (var i = 0; i < cols.length; i++) {
                                    if (!angular.isNullOrUndefined(model[nomTable][cols[i]])) {
                                        if (!angular.isNullOrUndefined(model[nomTable][cols[i]].valeur) &&
                                            ($scope.versTexte(model[nomTable][cols[i]].valeur, model[nomTable][cols[i]].descriptions).trim() !== "")) {
                                            if (texte !== "")
                                                texte += String.fromCharCode(13);

                                            if (!angular.isNullOrUndefined(model[nomTable][cols[i]].descriptions.libelle) &&
                                                (model[nomTable][cols[i]].descriptions.libelle.trim() !== "")) {
                                                texte += model[nomTable][cols[i]].descriptions.libelle.trim() + " : ";
                                            }

                                            texte += $scope.versTexte(model[nomTable][cols[i]].valeur, model[nomTable][cols[i]].descriptions).trim();
                                        }
                                    }
                                }
                            }

                            return texte;
                        }
                    }
                }
            };
        }])

    // L'opInputFormule directive gère la formule dans l'input
    .directive('opListInit', [
        function () {
            return {
                restrict: 'A',
                require: 'ngModel',
                priority: 1003,
                link: function (scope, element, attrs) {
                    var modele = attrs.ngModel.split('.');
                    var scopeSource, valeurModele, nomTable, nomColonne;
                    var descriptionListe,descriptionListeValeur, liste, listeValeur;

                    if ( modele.length > 2 && modele[1].indexOf("[") === -1){
                       scopeSource = modele[0];
                       nomTable = (modele[1].indexOf("[") === -1)? modele[1]: modele[1].replace(/\[\$index\]/g,'');
                       nomColonne = modele[2];
                       valeurModele = scope[scopeSource][modele[1]][nomColonne].valeur;
                    }

                    if ((!angular.isNullOrUndefined(valeurModele)) && (valeurModele !== "")) {
                        // Récupération de la liste
                        if ( !angular.isNullOrUndefined(scope.descriptions[nomTable])){
                            descriptionListe = scope.descriptions[nomTable][nomColonne].refEtrangere.split('|');

                            if ( descriptionListe.length>1 ){
                                liste = descriptionListe[0];
                                descriptionListeValeur = descriptionListe[1].split('#');
                                if ( descriptionListeValeur.length > 1 )
                                    listeValeur = descriptionListeValeur[1];
                                else
                                    listeValeur = descriptionListeValeur[0];
                            }
                        }

                        // Vérification que l'élément est bien présent dans la liste sinon on l'ajoute
                        var bValeurPresent = false;
                        if ( !angular.isNullOrUndefined(liste) && !angular.isNullOrUndefined(listeValeur) && !angular.isNullOrUndefined(scope.listes[liste]) ){
                            for (var i=0; i<scope.listes[liste].length; i++) {
                                if (valeurModele === scope.listes[liste][i][listeValeur].valeur) {
                                    bValeurPresent = true;
                                    break;
                                }
                            }
                        }

                        // Ajout dans la liste si non présente
                        if ( !bValeurPresent && !angular.isNullOrUndefined(scope.listes[liste])){
                            var entite = scope.descriptions[liste].creerObjet();
                            entite[listeValeur].valeur = valeurModele;
                            scope.listes[liste].push(entite);
                        }
                    }
                }
            };
        }
    ])

    /*.directive('opTime',[
        function(){
        return{restrict: 'A',
            require: 'ngModel',
            priority: 1002,
            link: function (scope, element, attrs, ngModel) {

                ngModel.$formatters.push(function (valeur) {
                    if (!angular.isNullOrUndefined(valeur)){
                        var heure, minute, arrayTime = valeur.split(':');
                        if (arrayTime.length === 2 ){
                            heure =  arrayTime[0];
                            minute =  arrayTime[1];
                        }
                        var dateConvertie = new Date(1990,1,1,heure,minute);

                        return dateConvertie;
                    }else
                        return valeur;
                });

                ngModel.$parsers.push(function(valeur){
                    if (!angular.isNullOrUndefined(valeur)){
                        var heure = valeur.getHours().toString(), minute = valeur.getMinutes().toString();
                        if ( valeur.getHours() < 10)
                            heure = '0' + heure;
                        if ( valeur.getMinutes() < 10)
                            minute = '0' + minute;

                        return (heure + ':'  + minute)
                    }
                })
            }
        }
    }])*/

    // L'opInputFormule directive gère la formule dans l'input
    .directive('opInputFormule', ['$window', '$ocLazyLoad','$parse',
        'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE',
        'fr.operis.moteurV4.communication.OperisWSServer','fr.operis.moteurV4.utils.Conversion',
        function ($window, $ocLazyLoad, $parse,separateurInterne,serveur,conversion) {
            return {
                restrict: 'A',
                require: 'ngModel',
                priority: 1002,
                link: function (scope, element, attrs, ngModelController) {
                    $ocLazyLoad.load({
                            serie: true, files: [
                                'lib/moment.min.js',
                                'lib/moment-fr.min.js'
                            ]
                        })
                        .then(function () {
                            var formule = attrs.opInputFormule.split(separateurInterne);
                            if (angular.isNullOrUndefined(formule))
                                return;


                            if ( formule.indexOf("FORMULE_DATEJOUR") !== -1 && ngModelController.$isEmpty(ngModelController.$viewValue)) {
                                // Calcul de la date du jour si le champs est vide
                                var modelGetter = $parse(attrs['ngModel']);
                                var modelSetter = modelGetter.assign;
                                modelSetter(scope, new Date());

                                ngModelController.$setViewValue(ngModelController.$formatters[0](new Date()));
                                ngModelController.$render();
                            } else if ( formule.indexOf("FORMULE_ANNEEJOUR") !== -1 && ngModelController.$isEmpty(ngModelController.$viewValue)) {
                                var modelGetter = $parse(attrs['ngModel']);
                                var modelSetter = modelGetter.assign;
                                modelSetter(scope, (new Date()).getFullYear());

                                //ngModelController.$setViewValue(ngModelController.$formatters[0]((new Date()).getFullYear()));
                                ngModelController.$render();
                            }else if ( formule.indexOf("FORMULE_UTILISATEURINITIALE") !== -1 && ngModelController.$isEmpty(ngModelController.$viewValue)) {
                                var modeleFormule = formule;
                                if ( !angular.isNullOrUndefined(modeleFormule)&&modeleFormule.length==2){
                                    var modelGetter = $parse(attrs['ngModel']);
                                    var modelSetter = modelGetter.assign;
                                    var idUtilisateur = serveur.getIdUtilisateur(modeleFormule[1])();
                                    modelSetter(scope,conversion.versEntier(idUtilisateur));

                                    if ( !angular.isNullOrUndefined(ngModelController.$formatters[0])){
                                        ngModelController.$setViewValue(ngModelController.$formatters[0](modeleFormule[1]));
                                    }

                                    ngModelController.$render();
                                }
                            }
                            else if (formule.indexOf("FORMULE_VALEURINITIALE") !== -1 && (element[0].type === "checkbox")) {
                                // Initialisation input type checkbox (pas de test $isEmpty pour les checkbox car il ne revoie jamais "vide")
                                var modeleFormule = formule;
                                if ( !angular.isNullOrUndefined(modeleFormule)&&modeleFormule.length==2){
                                    var modelGetter = $parse(attrs['ngModel']);
                                    var modelSetter = modelGetter.assign;
                                    modelSetter(scope, ((modeleFormule[1] == "1") || (modeleFormule[1] == "true")));

                                    if ( !angular.isNullOrUndefined(ngModelController.$formatters[0])){
                                        ngModelController.$setViewValue(ngModelController.$formatters[0](modeleFormule[1]));
                                    }
                                    ngModelController.$render();
                                }
                            }
                            else if (formule.indexOf("FORMULE_VALEURINITIALE") !== -1 && ngModelController.$isEmpty(ngModelController.$viewValue)) {
                                // Initialisation inputs autre que les checkbox
                                var modeleFormule = formule;
                                if ( !angular.isNullOrUndefined(modeleFormule)&&modeleFormule.length==2){
                                    var modelGetter = $parse(attrs['ngModel']);
                                    var modelSetter = modelGetter.assign;
                                    modelSetter(scope, modeleFormule[1]);

                                    if ( !angular.isNullOrUndefined(ngModelController.$formatters[0])){
                                        ngModelController.$setViewValue(ngModelController.$formatters[0](modeleFormule[1]));
                                    }
                                    ngModelController.$render();
                                }
                            }
                        });
                }
            };
        }
    ])

    // Cacher l'élément option d'une balise select en fonction d'une valeur donnée
    .directive('opOptionHidden',
        function() {
            return {
                link: function($scope, $element) {
                    $scope.$watch(
                        function () { return $element.attr('op-option-hidden'); },
                        function (newValue) {
                            if(eval(newValue)) {
                                $element.attr('hidden', 'hidden');
                                $element.attr('disabled', 'disabled');
                                $element.attr('style', 'display: none');
                            }
                        }
                    );
            }
        };
    })

    .directive('changeTypeAhead', function(){
        return{
            restrict: 'A',
            require: 'ngModel',
            link: function(scope, element, attr, ngModel){

                ngModel.$parsers.push(function(value){
                    var descriptionModel = attr.ngModel.split('.');
                    if ( angular.isNullOrUndefined(descriptionModel) || descriptionModel.length<3 )
                        return null;

                    var idxNomTable = 1;
                    for (var i = 0; i < descriptionModel.length; i++) {
                        if ((descriptionModel[i].indexOf('champs') !== -1) || (descriptionModel[i].indexOf('grilles') !== -1)) {
                            idxNomTable = i + 1;
                            break;
                        }
                    }

                    var tableNom = descriptionModel[idxNomTable];
                    var colNom = descriptionModel[idxNomTable + 1];
                    var description = (idxNomTable > 1) ? scope[descriptionModel[0]].descriptions[tableNom.replace("[$index]", "")][colNom] : scope.descriptions[tableNom.replace("[$index]", "")][colNom];

                    if (angular.isNullOrUndefined(description))
                        return null;

                    if (description.type === 'NUMBER' && typeof(value) === "string")
                        return null;
                    else
                        return value;
                });



            }
        };
    })

    /*.directive('emptyTypeahead', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                // this parser runs before typeahead's parser
                modelCtrl.$parsers.unshift(function (inputValue) {
                    var value = (inputValue ? inputValue : '[$empty$]'); // replace empty string with '[$empty$]' to bypass typeahead-min-length check
                    modelCtrl.$viewValue = value; // this $viewValue must match the inputValue pass to typehead directive
                    return value;
                });

                // this parser runs after typeahead's parser
                modelCtrl.$parsers.push(function (inputValue) {
                    return inputValue === '[$empty$]' ? '' : inputValue; // set '[$empty$]' back to empty string
                });
            }
        }
    })*/

    .directive('typeaheadShowOnFocus', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                element.bind('focus', function () {
                    ngModel.$setViewValue();
                    element.triggerHandler('input');
                    element.triggerHandler('change');
                });
            }
        };
    })

    .directive('opTimepickerEmpty', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attributes, ngModel) {
                $timeout(function () {
                    var inputs = element.find("input");

                    if (angular.isNullOrUndefined(ngModel.$modelValue)) {
                        inputs.val("");
                    }

                    scope.$watch(attributes.opTimepickerEmpty, function (valeur) {
                        if (angular.isNullOrUndefined(valeur)) {
                            inputs.val("");
                            element.triggerHandler('change');
                        }
                    });
                });
            }
        };
    }])

    .directive('scrollOnClick', ['$ocLazyLoad', '$document', '$window', function($ocLazyLoad, $document, $window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $ocLazyLoad.load({
                    serie: true, files: ['lib/jquery.min.js']
                }).then(function () {
                    $document.ready(function () {
                        element.on('click', function () {
                            if ($(element).offset().top > ($window.innerHeight * 0.20)) {
                                var posActuel = $document[0].body.scrollTop;
                                $("html, body").animate({scrollTop: posActuel + $(element).offset().top - 80}, "slow");
                            }
                        });
                    });
                });
            }
        }
    }])
;