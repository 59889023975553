/**
 * @name OpPopupAjoutTacheMateriau
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheMateriau', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheMateriauControleur',
        ['$scope', '$modalInstance',
            'fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.utils.ModuleInfos',
            'titre', 'vueFormulaire',
            function (scope, modalInstance, serveur, moduleInfos, titre, vueFormulaire) {
                scope.titre = titre;
                scope.vueFormulaire = vueFormulaire;
                scope.couleurPrimaire = moduleInfos.getInfo("Intervention", "MOD_COULEURPRIMAIRE");
                scope.couleurSecondaire = moduleInfos.getInfo("Intervention", "MOD_COULEURSECONDAIRE");

                // Init objet matériau
                scope.materiau = {
                    libMateriau: "",
                    qteMateriau: 1
                };

                // Saisie complète ?
                scope.saisieNOK = function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU)) {
                        return ((angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_LIB.valeur) || (scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_LIB.valeur.trim() === "")) ||
                                (angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_QTE.valeur) || (scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_QTE.valeur < 1)));
                    } else {
                        return true;
                    }
                };

                // Ajouter le matériau
                scope.valider = function () {
                    scope.materiau.libMateriau = scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_LIB.valeur;
                    scope.materiau.qteMateriau = scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_QTE.valeur;
                    modalInstance.close(scope.materiau);
                };

                // Annuler
                scope.fermer = function () {
                    modalInstance.close();
                };

                // Init données de l'écran
                scope.$watch(function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU)) {
                        return 1;
                    } else {
                        return null;
                    }
                }, function (newValue, oldValue) {
                    if (!angular.isNullOrUndefined(newValue) && (newValue !== oldValue)) {
                        scope.vueFormulaire.champs.VL_TAC_AJOUTMATERIAU.TAC_MATERIAU_QTE.valeur = newValue;
                    }
                });

                // Chargement de la liste des matériaux disponibles
                /*scope.listeMateriaux = [];
                serveur.chargement(nomModule, scope, ecranRecherche, null).then(function () {
                    // Lancer la recherche
                    scope.recherche[ecranRecherche](1000).then(function (retourRecherche) {
                        var tableLignes = scope.grilles[retourRecherche[0][0]];
                        for (var i = 0; i < tableLignes.length; i++) {
                            if (scope.listeMateriaux.indexOf(tableLignes[i].TAC_MATERIAU_LIB.valeur) === -1) {
                                scope.listeMateriaux.push(tableLignes[i].TAC_MATERIAU_LIB.valeur);
                            }
                        }
                    });
                });*/
            }
        ]
    );

