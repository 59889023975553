/**
 * Created by Laurent Dorie on 12/01/2017
 */
'use strict';

angular.module('fr.operis.moteurV4.utils.Historique', [])

    /**
     * Nom de l'écran des historiques
     * @type {String}
     * @value _HISTORIQUE
     */
    .constant('fr.operis.moteurV4.utils.Historique.ECRANHISTORIQUE', '_HISTORIQUE')


    .service('fr.operis.moteurV4.utils.Historique',['$q',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.Historique.ECRANHISTORIQUE',
    function($q, serveur, conversion, moduleInfos, ECRANHISTORIQUE ){
        /**
         * Fonction qui met à jour le statut d'une entite
         * @param {String} aNomModule Le nom du module
         * @param {Object} aScope Scope dans lequel les données sont à charger.
         * @param {Object} aIdentifiant Identifiant
         * @param {String} aNom Nom
         * @param {Date}   aDate Date de statut
         * @param {String} aCommentaire Commentaire
         * @returns {ng.IPromise<T>}
         */
        this.sauvegardeHistorique = function(aNomModule, aScope, aIdentifiant, aIdAction, aNom, aDate, aCommentaire, aEcranHistorique){
            var deferred = $q.defer();

            var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");
            var ecranHistorique,bMobibilite = serveur.isMobile();
            if ( bMobibilite )
                ecranHistorique = trigramme + '_M' + ECRANHISTORIQUE;
            else
                ecranHistorique = trigramme + ECRANHISTORIQUE;

            if ( !angular.isNullOrUndefined(aEcranHistorique) )
                ecranHistorique = aEcranHistorique;

            // Chargement de l'écran de l'historique
            serveur.chargement(aNomModule, aScope, ecranHistorique, aIdentifiant)
                .then(function(retourChargement){

                    // Mise à jour des champs de l'historique : nom, date et commentaire
                    var index = 0, tableHistorique = aScope.grilles[retourChargement[1]];
                    var ligneHistorique = aScope.descriptions[retourChargement[1]].creerObjet();
                    ligneHistorique[aScope.descriptions[retourChargement[1]][index].nom].valeur = conversion.versEntier(aIdentifiant[aScope.descriptions[retourChargement[1]][index].nom]);
                    ++index;
                    ligneHistorique[aScope.descriptions[retourChargement[1]][++index].nom].valeur = conversion.versEntier(serveur.getIdUtilisateur(aNomModule)());
                    ligneHistorique[aScope.descriptions[retourChargement[1]][++index].nom].valeur = conversion.versEntier(aIdAction);
                    ligneHistorique[aScope.descriptions[retourChargement[1]][++index].nom].valeur = aNom;
                    ligneHistorique[aScope.descriptions[retourChargement[1]][++index].nom].valeur = aDate;
                    ligneHistorique[aScope.descriptions[retourChargement[1]][++index].nom].valeur = aCommentaire;

                    tableHistorique.push(ligneHistorique);

                    // Sauvegarde de l'écran de l'historique
                    aScope.sauvegarde[ecranHistorique]()
                        .then(function(retourSauvegarde){
                            deferred.resolve(retourSauvegarde)
                        })
                })
                .catch(function (erreur) {
                    deferred.reject(erreur);
                });

            return deferred.promise;
        }
    }
    ]);


