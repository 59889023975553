/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.modele.Modele', ['fr.operis.moteurV4.utils.Conversion'])

/**
 * Nom de l'attribut pour les descriptions
 * @type {String}
 * @value descriptions
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS', 'descriptions')

/**
 * Nom de l'attribut pour les champs
 * @type {String}
 * @value champs
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS', 'champs')

/**
 * Nom de l'attribut pour les grilles
 * @type {String}
 * @value grilles
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES', 'grilles')

/**
 * Nom de l'attribut pour les listes
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_LISTES', 'listes')

/**
 * Nom de l'attribut pour le nom
 * @type {String}
 * @value libelle
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_NOM', 'nom')

/**
 * Nom de l'attribut pour le libellé
 * @type {String}
 * @value libelle
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_LIBELLE', 'libelle')

/**
 * Nom de l'attribut pour la description
 * @type {String}
 * @value description
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTION', 'description')


/**
 * Nom de l'attribut pour obligatoire
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_OBLIGATOIRE', 'estObligatoire')

/**
 * Nom de l'attribut pour clé primaire
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_CLE_PRIMAIRE', 'estClePrimaire')

/**
 * Nom de l'attribut pour clé étrangère
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_CLE_ETRANGERE', 'estCleEtrangere')

/**
 * Nom de l'attribut pour clé étrangère
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_UNITE', 'unite')

/**
 * Nom de l'attribut pour visible
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_VISIBLE', 'estVisible')

/**
 * Nom de l'attribut pour info-bulle
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_INFO_BULLE', 'infoBulle')

/**
 * Nom de l'attribut pour taille maximale
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_LONGUEUR_MAX', 'longueurMax')

/**
 * Nom de l'attribut pour borne min
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_BORNE_MIN', 'borneMin')

/**
 * Nom de l'attribut pour borne max
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_BORNE_MAX', 'borneMax')

/**
 * Nom de l'attribut pour type champ
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_TYPE', 'type')


/**
 * Nom de l'attribut pour nombre maximum de décimales
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_DECIMALES_MAX', 'decimalesMax')


/**
 * Nom de l'attribut pour nombre maximum de décimales
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_REPRESENTATION', 'representation')

/**
 * Nom de l'attribut pour nombre maximum de décimales
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_INTERACTION', 'typeInteraction')


/**
 * Nom de l'attribut pour les types complexes
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_COMPLEXE', 'typeComplexe')


/**
 * Nom de l'attribut pour nombre maximum de partie entière
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_PARTIE_ENTIERE', 'partieEntiere')

/**
 * Nom de l'attribut pour la référence étrangère
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_REF_ETRANGERE', 'refEtrangere')


/**
 * Nom de l'attribut pour la référence du nom du groupe
 * @type {String}
 * @value nomGroupe
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_NOM_GROUPE', 'nomGroupe')



/**
 * Nom de l'attribut de lecture seule
 * @type {String}
 * @value estLectureSeule
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_ESTLECTURESEULE', 'estLectureSeule')

/**
 * Nom de l'attribut de lecture seule
 * @type {String}
 * @value listes
 */
    .constant('fr.operis.moteurV4.modele.Modele.SCOPE_ACTION', 'action')


    /**
     * Grille des paramètres.
     * @type {String}
     * @value VL_PRM_UTILISATEURAPPLIPARAM
     */
    .constant('fr.operis.moteurV4.modele.Modele.TABLE_PARAMETRES', 'VL_PRM_UTILISATEURAPPLIPARAM')

    /**
     * Colonne de la catégorie du paramètre.
     * @type {String}
     * @value PARAMCATEGORIE
     */
    .constant('fr.operis.moteurV4.modele.Modele.CHAMP_PARAMETRE_CATEGORIE', 'PARAMCATEGORIE')


    /**
     * Colonne du code du paramètre.
     * @type {String}
     * @value PARAMCODE
     */
    .constant('fr.operis.moteurV4.modele.Modele.CHAMP_PARAMETRE_CODE', 'PARAMCODE')


    /**
     * Colonne de la valeur du paramètre.
     * @type {String}
     * @value PARAMVALEUR
     */
    .constant('fr.operis.moteurV4.modele.Modele.CHAMP_PARAMETRE_VALEUR', 'PARAMVALEUR')


    .provider('fr.operis.moteurV4.modele.Modele', function () {
        var _separateurNiveau1 = '_separateurNiveau1',
            _separateurNiveau2 = '_separateurNiveau2',
            _separateurNiveau3 = '_separateurNiveau3',
            _separateurInterne = '_separateurInterne';

        /**
         * Défini le séparaeteur de niveau 1.
         * @param {string} aSeparateurNiveau1 Le séparaeteur de niveau 1
         */
        this.setSeparateurNiveau1 = function (aSeparateurNiveau1) {
            _separateurNiveau1 = aSeparateurNiveau1;
        };

        /**
         * Défini le séparaeteur de niveau 2.
         * @param {string} aSeparateurNiveau2 Le séparaeteur de niveau 2
         */
        this.setSeparateurNiveau2 = function (aSeparateurNiveau2) {
            _separateurNiveau2 = aSeparateurNiveau2;
        };

        /**
         * Défini le séparaeteur de niveau 3.
         * @param {string} aSeparateurNiveau3 Le séparaeteur de niveau 3
         */
        this.setSeparateurNiveau3 = function (aSeparateurNiveau3) {
            _separateurNiveau3 = aSeparateurNiveau3;
        };

        /**
         * Défini le séparateur Interne.
         * @param {string} aSeparateurInterne Le séparateur interne
         */
        this.setSeparateurInterne = function (aSeparateurInterne) {
            _separateurInterne = aSeparateurInterne;
        };

        /**
         * Retourne le service Modele.
         * @param {injector} $injector
         * @returns {fr.operis.moteurV4.modele.Modele}
         */
        this.$get = ['$injector', function ($injector) {
            return $injector.instantiate([
                '$log'
                , 'fr.operis.moteurV4.utils.Conversion'
                , 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS'
                , 'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS'
                , 'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES'
                , 'fr.operis.moteurV4.modele.Modele.SCOPE_LISTES'
                , 'fr.operis.moteurV4.modele.Modele.TABLE_PARAMETRES'
                , 'fr.operis.moteurV4.modele.Modele.CHAMP_PARAMETRE_VALEUR'
                , 'fr.operis.moteurV4.modele.Modele.CHAMP_PARAMETRE_CODE'
                , 'fr.operis.moteurV4.modele.Modele.CHAMP_PARAMETRE_CATEGORIE'
                , 'fr.operis.moteurV4.utils.Erreur'
                , 'fr.operis.moteurV4.utils.Erreur.DESCRIPTION_INVALIDE'
                , 'fr.operis.moteurV4.utils.Erreur.DESCRIPTION_TABLE_INVALIDE'
                , 'fr.operis.moteurV4.utils.Erreur.VALEURS_INVALIDES'
                , Modele
            ]);
        }
        ];

        function Modele($log, conversion,
                        SCOPE_DESCRIPTIONS, SCOPE_CHAMPS, SCOPE_GRILLES, SCOPE_LISTES, TABLE_PARAMETRES,
                        CHAMP_PARAMETRE_VALEUR, CHAMP_PARAMETRE_CODE, CHAMP_PARAMETRE_CATEGORIE,
                        erreur, DESCRIPTION_INVALIDE, DESCRIPTION_TABLE_INVALIDE, VALEURS_INVALIDES) {

            /**
             * @typedef EnTete
             */
            /**
             * Description des en-têtes envoyées par le serveur Operis.
             * @type {[]}
             * @const
             * @private
             */
            var DESCRIPTION_ENTETE = [
                {
                    nom: "nom",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Nom de la donnée"
                },
                {
                    nom: "libelle",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Libellé de la donnée"
                },
                {
                    nom: "description",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Description de la donnée"
                },
                {
                    nom: "ordre",
                    conversion: conversion.versEntier,
                    toString: conversion.versTexteDepuisEntier,
                    defaut: -1,
                    commentaire: "Numéro de la donnée pour l'accès par clavier"
                },
                {
                    nom: "type",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Type de la donnée : VARCHAR2, CLOB, DATE, NUMBER."
                },
                {
                    nom: "longueurMax",
                    conversion: conversion.versEntier,
                    toString: conversion.versTexteDepuisEntier,
                    defaut: 0,
                    commentaire: "Taille maximale de la donnée"
                },
                {
                    nom: "partieEntiere",
                    conversion: conversion.versEntier,
                    toString: conversion.versTexteDepuisEntier,
                    defaut: 0,
                    commentaire: "Dans le cas du type 'Number', taille de la partie entière du nombre"
                },
                {
                    nom: "decimalesMax",
                    conversion: conversion.versEntier,
                    toString: conversion.versTexteDepuisEntier,
                    defaut: 0,
                    commentaire: "Dans le cas du type 'Number', taille de la partie décimale du nombre"
                },
                {
                    nom: "estUnique",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: false,
                    commentaire: "Indique si la donnée subit une contrainte d'unicité"
                },
                {
                    nom: "estClePrimaire",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: false,
                    commentaire: "Indique si la donnée fait référence à une clé primaire"
                },
                {
                    nom: "estCleEtrangere",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: false,
                    commentaire: "Indique si la donnée fait référence à une clé étrangère"
                },
                {
                    nom: "unite",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Unité de la donnée"
                },
                {
                    nom: "borneMin",
                    conversion: conversion.versNumerique,
                    toString: conversion.versTexteDepuisNumerique,
                    defaut: 0,
                    commentaire: "Dans le cas du type 'NUMBER', borne minimale de la donnée"
                },
                {
                    nom: "borneMax",
                    conversion: conversion.versNumerique,
                    toString: conversion.versTexteDepuisNumerique,
                    defaut: 0,
                    commentaire: "Dans le cas du type 'NUMBER', borne maximale de la donnée"
                },
                {
                    nom: "estUtilise",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: true,
                    commentaire: "Indique si la donnée est encore utilisée"
                },
                {
                    nom: "estObligatoire",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: false,
                    commentaire: "Indique si la donnée est obligatoire"
                },
                {
                    nom: "estVisible",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: true,
                    commentaire: "Indique si la donnée est visible"
                },
                {
                    nom: "ordreTri",
                    conversion: conversion.versEntier,
                    toString: conversion.versTexteDepuisEntier,
                    defaut: 0,
                    commentaire: "Numéro de la donnée pour le tri"
                },
                {
                    nom: "operateurRecherche",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Opérateur de recherche de la donnée"
                },
                {
                    nom: "estDocument",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: false,
                    commentaire: "Indique si la donnée fait référence à un document"
                },
                {
                    nom: "nomGroupe",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Nom du groupe dans lequel se trouve la donnée"
                },
                {
                    nom: "visible",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Types d'affichage pour lesquels la colonne est visible"
                },
                {
                    nom: "taille",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Taille d'affichage de la colonne"
                },
                {
                    nom: "representation",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Nom du dictionnaire de représentation"
                },
                {
                    nom: "lien",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Lien vers un écran, sous la forme 'Nom de l'écran|Nom de la colonne de clé étrangère'"
                },
                {
                    nom: "estChampPrincipal",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: false,
                    commentaire: "Indique si le champ est le champ principal"
                },
                {
                    nom: "estRegroupement",
                    //conversion: conversion.versBooleen,
                    //toString: conversion.versTexteDepuisBooleen,
                    //defaut: false,
                    //commentaire: "Indique si le champ est un champ de regroupement"
                    conversion: conversion.versEntier,
                    toString: conversion.versTexteDepuisEntier,
                    defaut: 0,
                    commentaire: "Ordre de regroupement (un valeur non zéro indique que le champ est un champ de regroupement)"
                },
                {
                    nom: "typeComplexe",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Type complexe de la donnée : DATEDEB, DATEFIN, ADRESSE, COORDONNEES..."
                },
                {
                    nom: "typeInteraction",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Type d'interaction sur la donnée : RADIO, LISTE..."
                },
                {
                    nom: "refEtrangere",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Référence à une table étrangère notamment pour les listes"
                },
                {
                    nom: "estLectureSeule",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: 0,
                    commentaire: "Indique si le champ est en lecture seule"
                },
                {
                    nom: "action",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: 0,
                    commentaire: "Action"
                },
                {
                    nom: "estCritereRecherche",
                    conversion: conversion.versBooleen,
                    toString: conversion.versTexteDepuisBooleen,
                    defaut: 0,
                    commentaire: "Indique si le champ est un critère de recherche"
                },
                {
                    nom: "statut",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Statut de la description de la donnée"
                },
                {
                    nom: "nomUnivers",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Nom de la donnée dans l'univers"
                },
                {
                    nom: "libelleUnivers",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Libellé de la donnée dans l'univers"
                },
                {
                    nom: "descriptionUnivers",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Description de la donnée dans l'univers"
                },
                {
                    nom: "exemple",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: "Exemple de valeur pour la donnée"
                },
                {
                    nom: "filtreSpecifique",
                    conversion: conversion.versTexte,
                    toString: conversion.versTexte,
                    defaut: "",
                    commentaire: ""
                }
            ];

            /**
             * Remplissage du modèle de données à partir du retour du serveur Operis.
             * @param {type} aScope Le scope à compléter
             * @param {String} aDescriptions
             * @param {String} aValeurs
             * @param {String} aType
             * @returns {String[]} Les tables ajoutées
             */
            this.remplirModele = function (aScope, aDescriptions, aValeurs, aType) {
                // Gestion des paramètres d'entrée
                if (angular.isNullOrUndefined(aScope)) return null;
                if (angular.isNullOrUndefined(aDescriptions)) aDescriptions = '';
                if (angular.isNullOrUndefined(aValeurs)) aValeurs = '';
                if (aDescriptions === '' && aValeurs === '') return null;
                if (angular.isNullOrUndefined(aType)) aType = SCOPE_CHAMPS;
                if (aType !== SCOPE_CHAMPS && aType !== SCOPE_GRILLES && aType !== SCOPE_LISTES) return null;

                // Constructions des objets du modèle si besoin
                if (angular.isNullOrUndefined(aScope[SCOPE_DESCRIPTIONS])) aScope[SCOPE_DESCRIPTIONS] = {};
                if (angular.isNullOrUndefined(aScope[aType])) aScope[aType] = {};

                // Création du tableau des tables retournées
                var tables = [],

                // On sépare les descriptions et les valeurs par table
                    descriptionsTables = aDescriptions.split(_separateurNiveau3),
                    valeursTables = aValeurs.split(_separateurNiveau3);
                for (var indiceTable = 0; indiceTable < descriptionsTables.length; ++indiceTable) {
                    // On sépare les descriptions par colonne et les valeurs par lignes
                    var descriptionsColonnes = descriptionsTables[indiceTable].split(_separateurNiveau2);
                    var valeursLignes = [""];
                    if (!angular.isNullOrUndefined(valeursTables[indiceTable]) )
                        valeursLignes = valeursTables[indiceTable].split(_separateurNiveau2);

                    // On récupère le nom de la table
                    var indiceColonne = 0,
                        table = descriptionsColonnes[indiceColonne++];
                    // Validation du nom de la table
                    if (table === "") {
                        throw erreur.creer(DESCRIPTION_INVALIDE, this, aDescriptions);
                    }
                    // On stocke le nom de la table
                    tables.push(table);

                    if (angular.isNullOrUndefined(aScope[SCOPE_DESCRIPTIONS][table])) {
                        // On stocke la description de la table
                        aScope[SCOPE_DESCRIPTIONS][table] = [];
                        for (; indiceColonne < descriptionsColonnes.length; ++indiceColonne) {
                            // On sépare les descriptions par métadonnées
                            var descriptionsMetaDonnees = descriptionsColonnes[indiceColonne].split(_separateurNiveau1),

                            // On construit l'objet pour les métadonnées de la colonne
                                colonneMetadonnees = {};
                            // Validation des données de la description
                            if (descriptionsMetaDonnees.length !== DESCRIPTION_ENTETE.length) {
                                throw erreur.creer(DESCRIPTION_TABLE_INVALIDE, this, table, descriptionsColonnes[indiceColonne]);
                            }
                            // Construction des métadonnées de la colonne
                            for (var indiceDescription = 0; indiceDescription < DESCRIPTION_ENTETE.length; ++indiceDescription) {
                                var desc = DESCRIPTION_ENTETE[indiceDescription];
                                try {
                                    colonneMetadonnees[desc.nom] = desc.conversion(descriptionsMetaDonnees[indiceDescription]);
                                } catch (error) {
                                    $log.error("La métadonnée " + desc.nom + " associée à la colonne " + colonneMetadonnees.nom + " de la table " + table + " est invalide : " + error);
                                    colonneMetadonnees[desc.nom] = desc.defaut;
                                }
                            }
                            // Validation du nom de la colonne
                            if (colonneMetadonnees.nom === "") {
                                throw erreur.creer(DESCRIPTION_TABLE_INVALIDE, this, table, descriptionsColonnes[indiceColonne]);
                            }
                            // On stocke les métadonnées de la colonne par son nom et son indice
                            if (angular.isNullOrUndefined(aScope[SCOPE_DESCRIPTIONS][table][colonneMetadonnees.nom])) {
                                aScope[SCOPE_DESCRIPTIONS][table][colonneMetadonnees.nom] = colonneMetadonnees;
                            } else {
                                $log.warn("Impossible d'inscrire la colonne " + colonneMetadonnees.nom + " de la table " + table +
                                ". Vérifiez si le nom de la colonne ne correspond pas à un élément de l'objet javasript Array.");
                            }
                            aScope[SCOPE_DESCRIPTIONS][table].push(colonneMetadonnees);
                        }

                        // On ajoute à la description de la table une fonction pour créer un objet.
                        /**
                         * Fonction pour créer un objet pour la table.
                         * @param {Object[]} aValeurs Un tableau de valeurs dans l'ordre des colonnes
                         * @returns {Object} Un objet correspondant à la table.
                         */
                        aScope[SCOPE_DESCRIPTIONS][table].creerObjet = function (aValeurs) {
                            var tableObjet = {};
                            if (!angular.isNullOrUndefined(aValeurs) && aValeurs.length !== this.length) {
                                throw erreur.creer(VALEURS_INVALIDES, this, aValeurs, table);
                            }
                            for (var indiceColonne = 0; indiceColonne < this.length; ++indiceColonne) {
                                var colonne = this[indiceColonne];
                                tableObjet[colonne.nom] = {
                                    descriptions: colonne,
                                    valeur: (angular.isNullOrUndefined(aValeurs) ? null : conversion.versType(aValeurs[indiceColonne], colonne.type,
                                        colonne.decimalesMax, colonne.borneMin, colonne.borneMax)),
                                    toString: function() {
                                        return ''+this.valeur;
                                    }
                                };
                            }
                            return tableObjet;
                        };
                    }

                    // On initialise à vide les valeurs de la table
                    if (aType !== SCOPE_CHAMPS) {
                        aScope[aType][table] = [];
                        aScope[aType][table].descriptions = aScope[SCOPE_DESCRIPTIONS][table];
                    } else {
                        aScope[aType][table] = aScope[SCOPE_DESCRIPTIONS][table].creerObjet();
                    }

                    if ( !angular.isNullOrUndefined(valeursTables[indiceTable]) && valeursTables[indiceTable].length > 0) {
                        // On renseigne les valeurs de la table
                        for (var indiceLigne = 0; indiceLigne < valeursLignes.length; ++indiceLigne) {
                            var valeursColonnes = valeursLignes[indiceLigne].split(_separateurNiveau1),
                                ligne = aScope[SCOPE_DESCRIPTIONS][table].creerObjet(valeursColonnes);
                            if (aType === SCOPE_CHAMPS) {
                                aScope[aType][table] = ligne;
                            } else {
                                aScope[aType][table].push(ligne);
                            }
                        }
                    }
                }
                return tables;
            };

            /**
             * Récupération des descriptions associées aux tables fournies pour le serveur Operis.
             * @param {type} aScope Le scope pour lire les descriptions.
             * @param {type} aTables Les tables dont il faut récupérer les descriptions.
			 * @param {Object} aTri [Optionnal] Tri à utiliser
             * @returns {String} Les descriptions des tables pour le serveur Operis.
             */
            this.recupererDescriptions = function (aScope, aTables, aTri) {
                aScope = aScope || {};
                aTables = aTables || [];
                var descriptionsTables = [];
                for (var indiceTable = 0; indiceTable < aTables.length; ++indiceTable) {
                    var table = aTables[indiceTable];
                    var descriptionsTable = aScope[SCOPE_DESCRIPTIONS][table];

                    var descriptionsColonnes = [table];
                    for (var indiceColonne = 0; indiceColonne < descriptionsTable.length; ++indiceColonne) {
                        var colonne = descriptionsTable[indiceColonne];

                        // Récupération des métadonnées de la colonne
                        var descriptionsMetaDonnees = [];
                        for (var indiceDescription = 0; indiceDescription < DESCRIPTION_ENTETE.length; ++indiceDescription) {
                            var description = DESCRIPTION_ENTETE[indiceDescription];

                            // Mantis Operia 6195 : Gestion tri personnalisé (remplace le tri paramétré dans la description de la table)
                            if ((!angular.isNullOrUndefined(aTri)) && (description.nom === 'ordreTri')) {
                                var tri = 0;
                                for (var i = 0; i < aTri.length; i++) {
                                    if (colonne.nom === aTri[i].colonne) {
                                        tri = aTri[i].tri;
                                        break;
                                    }
                                }

                                descriptionsMetaDonnees.push(description.toString.call(conversion, tri));
                            } else {
                                descriptionsMetaDonnees.push(description.toString.call(conversion, colonne[description.nom]));
                            }
                        }
                        // Ajout des métadonnées de la colonne
                        descriptionsColonnes.push(descriptionsMetaDonnees.join(_separateurNiveau1));
                    }

                    // Ajout des métadonnées de la table
                    descriptionsTables.push(descriptionsColonnes.join(_separateurNiveau2));
                }

                return descriptionsTables.join(_separateurNiveau3);
            };

            /**
             * Récupération des valeurs associées aux tables fournies pour le serveur Operis.
             * @param {type} aScope Le scope pour lire les valeurs
             * @param {type} aTables Les tables dont il faut récupérer les valeurs
             * @param {type} aType Le type des valeurs
             * @param {type} aIdentifiants Identifiant
             * @returns {String} Les valeurs des tables pour le serveur Operis
             */
            this.recupererValeurs = function (aScope, aTables, aType, aIdentifiants) {
                aScope = aScope || {};
                aTables = aTables || [];
                var valeursTables = [];
                for (var indiceTable = 0; indiceTable < aTables.length; ++indiceTable) {
                    var table = aTables[indiceTable],
                        valeursTable = aScope[aType][table],
                        descriptionsTable = aScope[SCOPE_DESCRIPTIONS][table];

                    // Récupération des valeurs de la table
                    var nbLignes;
                    var valeursLignes = [];
                    if (aType === SCOPE_CHAMPS) nbLignes = 1;
                    else nbLignes = valeursTable.length;
                    for (var indiceLigne = 0; indiceLigne < nbLignes; ++indiceLigne) {
                        var valeursTableLigne,
                            valeursColonnes = [];
                        if (aType === SCOPE_CHAMPS) valeursTableLigne = valeursTable;
                        else valeursTableLigne = valeursTable[indiceLigne];
                        for (var indiceColonne = 0; indiceColonne < descriptionsTable.length; ++indiceColonne) {
                            var colonne = descriptionsTable[indiceColonne];
                            var nomChamps = colonne.nom;
                            var valeurTable;
                            if ( Array.isArray(valeursTableLigne[colonne.nom].valeur) )
                                valeurTable = conversion.versTexteEspaceForXML(valeursTableLigne[colonne.nom].valeur, colonne.type).join(_separateurInterne);
                            else
                                valeurTable = conversion.versTexteEspaceForXML(valeursTableLigne[colonne.nom].valeur, colonne.type);

                            // Récupération de la valeur idtentite dans les valeurs si non presentes déjà
                            if ( ( angular.isNullOrUndefined(valeurTable) || valeurTable.length == 0)
                                && !angular.isNullOrUndefined(aIdentifiants)
                                && !angular.isNullOrUndefined(aIdentifiants[nomChamps])){
                                valeurTable = aIdentifiants[nomChamps];
                            }

                            valeursColonnes.push(valeurTable);
                        }
                        valeursLignes.push(valeursColonnes.join(_separateurNiveau1));
                    }

                    // Ajout des valeurs de la table
                    valeursTables.push(valeursLignes.join(_separateurNiveau2));
                }

                return valeursTables.join(_separateurNiveau3);
            };

            /**
             * Fonction de comparaison entre deux valeurs pouvant être issues du modèle
             * @param actual
             * @param expected
             * @returns {boolean}
             */
            this.comparateur = function(actual, expected) {
                actual = ('' + actual).toLowerCase();
                expected = ('' + expected).toLowerCase();
                return actual.indexOf(expected) !== -1;
            };

            /**
             * Fonction de comparaison stricte entre deux valeurs pouvant être issues du modèle
             * @param actual
             * @param expected
             * @returns {boolean}
             */
            this.comparateurStrict = function(actual, expected) {
                if(actual && actual.valeur !== undefined) actual = actual.valeur;
                if(expected && expected.valeur !== undefined) expected = expected.valeur;
                return actual === expected;
            };

            /**
             * Remplissage du modèle de paramètres à partir du retour du serveur Operis.
             * @param {String} aDescriptions
             * @param {String} aValeurs
             * @returns {String[]} Les paramètre ajoutées
             */
            this.remplirParametre = function(aDescriptions,aValeurs){
                // Gestion des paramètres d'entrée
                if (angular.isNullOrUndefined(aDescriptions)) aDescriptions = '';
                if (angular.isNullOrUndefined(aValeurs)) aValeurs = '';
                if (aDescriptions === '' && aValeurs === '') return null;

                // Création du tableau des paramètres retournées
                var parametre = {},

                // On sépare les descriptions et les valeurs par table
                    descriptionsTables = aDescriptions.split(_separateurNiveau3),
                    valeursTables = aValeurs.split(_separateurNiveau3);
                for (var indiceTable = 0; indiceTable < descriptionsTables.length; ++indiceTable) {

                    var descriptionsColonnes = descriptionsTables[indiceTable].split(_separateurNiveau2);
                    var valeursLignes = [""];
                    if (!angular.isNullOrUndefined(valeursTables[indiceTable]) )
                        valeursLignes = valeursTables[indiceTable].split(_separateurNiveau2);

                    // On récupère le nom de la table
                    var indiceColonne = 0,
                        table = descriptionsColonnes[indiceColonne++];
                    // Validation du nom de la table
                    if (table === TABLE_PARAMETRES) {
                        // Recherche des indices des colonnes qui nous intéressent
                        var indiceCategorie = 0, indiceCode = 0, indiceValeur = 0;

                        for (; indiceColonne < descriptionsColonnes.length; ++indiceColonne) {
                            // On sépare les descriptions par métadonnées
                            var descriptionsMetaDonnees = descriptionsColonnes[indiceColonne].split(_separateurNiveau1),

                            // On construit l'objet pour les métadonnées de la colonne
                                colonneMetadonnees = {};
                            // Validation des données de la description
                            if (descriptionsMetaDonnees.length !== DESCRIPTION_ENTETE.length) {
                                throw erreur.creer(DESCRIPTION_TABLE_INVALIDE, this, table, descriptionsColonnes[indiceColonne]);
                            }
                            // Construction des métadonnées de la colonne
                            for (var indiceDescription = 0; indiceDescription < DESCRIPTION_ENTETE.length; ++indiceDescription) {
                                var desc = DESCRIPTION_ENTETE[indiceDescription];
                                try {
                                    colonneMetadonnees[desc.nom] = desc.conversion(descriptionsMetaDonnees[indiceDescription]);
                                } catch (error) {
                                    $log.error("La métadonnée " + desc.nom + " associée à la colonne " + colonneMetadonnees.nom + " de la table " + table + " est invalide : " + error);
                                    colonneMetadonnees[desc.nom] = desc.defaut;
                                }
                            }

                            if ( colonneMetadonnees.nom === CHAMP_PARAMETRE_CATEGORIE){
                                indiceCategorie = indiceColonne-1;
                            }
                            else  if ( colonneMetadonnees.nom === CHAMP_PARAMETRE_CODE){
                                indiceCode = indiceColonne-1;
                            }
                            else  if ( colonneMetadonnees.nom === CHAMP_PARAMETRE_VALEUR){
                                indiceValeur = indiceColonne-1;
                            }
                        }

                        // Lecture et ajout des paramètres
                        if ( !angular.isNullOrUndefined(valeursTables[indiceTable]) && valeursTables[indiceTable].length > 0) {
                            // On renseigne les valeurs de la table
                            for (var indiceLigne = 0; indiceLigne < valeursLignes.length; ++indiceLigne) {
                                var valeursColonnes = valeursLignes[indiceLigne].split(_separateurNiveau1);
                                var categorie = valeursColonnes[indiceCategorie];
                                var code = valeursColonnes[indiceCode];
                                var valeur = valeursColonnes[indiceValeur];

                                if ( angular.isNullOrUndefined(parametre[categorie]) )
                                    parametre[categorie] = {};

                                parametre[categorie][code] = valeur;
                            }
                        }


                    }
                }
                return parametre;
            };
        };
    });