/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpPopupEdition', ['fr.operis.moteurV4.utils.RequeteEdition'])

    // Ecran de recherche des modèles de courriers
    .constant('fr.operis.moteurV4.composants.OpPopupEditionControleur.ECRAN_RECHERCHECOURRIER', 'OPE_RechercheModeleCourrier')

    // Grille de recherche des modèles de courriers
    .constant('fr.operis.moteurV4.composants.OpPopupEditionControleur.VL_OP_MODELE', 'VL_OPE_MODELE')

    //Identifiant de Module
    .constant('fr.operis.moteurV4.composants.OpPopupEditionControleur.ID_MODULE', 'IDENT_MODULE')

    //Identifiant de Modele
    .constant('fr.operis.moteurV4.composants.OpPopupEditionControleur.ID_MODELE', 'ID_MODELE')

    //Entite (Clef nécessaire pour le modèle)
    .constant('fr.operis.moteurV4.composants.OpPopupEditionControleur.MODELECLESPRIMAIRES', 'MODELECLESPRIMAIRES')

    .controller('fr.operis.moteurV4.composants.OpPopupEditionControleur',
    ['$scope'
        , '$modalInstance'
        , '$window'
        , '$modal'
        , 'fr.operis.moteurV4.communication.OperisWSServer'
        , 'fr.operis.moteurV4.utils.Parametre'
        , 'fr.operis.moteurV4.utils.Conversion'
        , 'fr.operis.moteurV4.utils.ModuleInfos'
        , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1'
        , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_2'
        , 'fr.operis.moteurV4.utils.RequeteEdition'
        , 'fr.operis.moteurV4.utils.RequeteFichier'
        , 'fr.operis.moteurV4.composants.OpPopupEditionControleur.ECRAN_RECHERCHECOURRIER'
        , 'fr.operis.moteurV4.composants.OpPopupEditionControleur.VL_OP_MODELE'
        , 'fr.operis.moteurV4.composants.OpPopupEditionControleur.ID_MODULE'
        , 'fr.operis.moteurV4.composants.OpPopupEditionControleur.ID_MODELE'
        , 'fr.operis.moteurV4.composants.OpPopupEditionControleur.MODELECLESPRIMAIRES'
        , 'fr.operis.moteurV4.composants.OpPopupAlerte'
        , 'fr.operis.operia.utils.parametre.EDITION_BIBLIOTHEQUE_DEFAULT'
        , 'fr.operis.operia.utils.parametre.EDITION_FORMAT_DEFAULT'
        , 'moduleNom'
        , 'selection'
        , 'onglet'
        ,function ( $scope
            , $modalInstance
            , $window
            , $modal
            , serveur
            , parametre
            , conversion
            , moduleInfos
            , SEPARATEUR_NIVEAU_1
            , SEPARATEUR_NIVEAU_2
            , requeteEdition
            , requeteFichier
            , ECRAN_RECHERCHECOURRIER
            , VL_OP_MODELE
            , ID_MODULE
            , ID_MODELE
            , MODELECLESPRIMAIRES
            , opPopupAlerte
            , EDITION_BIBLIOTHEQUE_DEFAULT
            , EDITION_FORMAT_DEFAULT
            , moduleNom
            , selection
            , onglet) {
            var vm = this;

            // Initilialisation
            $scope.descriptionRechercheModele = {};
            $scope.donneesRechercheModele = [];
            $scope.selectionRechercheModele = [];
            $scope.selection = selection;
            $scope.onglet = onglet;

            $scope.couleurPrimaire = moduleInfos.getInfo(moduleNom, "MOD_COULEURPRIMAIRE");
            $scope.couleurSecondaire = moduleInfos.getInfo(moduleNom, "MOD_COULEURSECONDAIRE");

            // Mantis Operia 6261 Implémenter "Ouvrir et envoyer" pour les modules autre que Demande
            $scope.btnEnvoyerDispoinble = true; //(moduleNom === "Demande");

            var idModule = serveur.getIdModule(moduleNom);

            // Chargement de la grille des courriers
            serveur.chargement('Operia', $scope, ECRAN_RECHERCHECOURRIER, null)
                .then(function(retourChargement){
                    $scope.champs.VL_OPE_MODELE.IDENT_MODULE.valeur = conversion.versEntier(idModule);

                    // Récupération de la bibliotheque de l'utilisateur
                    $scope.champs.VL_OPE_MODELE.BIBLIOTHEQUENOM.valeur = parametre.lireParametre("Operia",EDITION_BIBLIOTHEQUE_DEFAULT);

                    $scope.recherche[ECRAN_RECHERCHECOURRIER](1000)
                        .then(function (retourRecherche) {
                            $scope.descriptionRechercheModele = $scope.descriptions.VL_OPE_MODELE;
                            $scope.donneesRechercheModele= $scope.grilles.VL_OPE_MODELE;
                            $scope.selectionRechercheModele=[];
                        })
                }, function (retourErreur) {
                    // TODO Gestion de l'erreur
                });

            // Edition PDF et ajout aux documents attachés
            $scope.attacher = function () {
                var idModele, idEntiteListe, libelle, entite, entite2, entite3 = {};

                for (var j = 0; j < selection.length; j++) {

                    for (var i = 0; i < $scope.selectionRechercheModele.length; i++) {
                        idModele = $scope.selectionRechercheModele[i][ID_MODELE].valeur;
                        idEntiteListe = $scope.selectionRechercheModele[i][MODELECLESPRIMAIRES].valeur.split("/");
                        entite = vm.getEntite(selection[j], idEntiteListe);

                        if (angular.isNullOrUndefined(entite)) {
                            opPopupAlerte.creer('warning',"L'entité n'a pas été trouvée. Vérifiez que le document a bien été compilé et attaché à un module.", true, false);
                            return;
                        }

                        requeteEdition.recupererLien(moduleNom, idModele, entite, 'application/pdf')
                            .then(function (responseEdition) {
                                // Mantis 5775 Gérer caractères espaces dans le nom des fichiers
                                var cheminFichier = responseEdition.data.replace(/%20/g, "_");
                                $window.open(cheminFichier, "_blank");
                                entite2 = entite.replace(SEPARATEUR_NIVEAU_1, ',');
                                libelle = responseEdition.data.split("/").slice(-1)[0].replace(/%20/g, "_");

                                return requeteFichier.ajouterFichierDirectURL(moduleNom, $scope, cheminFichier, entite2, onglet.moduleTrigramme + '_DOCUMENT');
                            })
                            .then(function (responseFichier) {
                                entite3[entite2.split(",")[0]] = entite2.split(",")[1];

                                // Chargement de la grille des documents attachés
                                serveur.chargement(moduleNom, $scope, onglet.moduleTrigramme + '_DOCUMENTS', entite3)
                                    .then(function (retourChargement) {
                                        var fichier = $scope.descriptions[onglet.moduleTrigramme +'_DOCUMENT'].creerObjet();
                                        fichier[onglet.moduleEntite].valeur = conversion.versEntier(entite2.split(",")[1]);
                                        fichier["ID_" + onglet.moduleTrigramme + "_DOC_ID_FICHIER"].valeur = conversion.versEntier(responseFichier);
                                        fichier[onglet.moduleTrigramme + "_DOC_LIB"].valeur = libelle;
                                        fichier[onglet.moduleTrigramme + "_DOC_TYPE"].valeur = 'application/pdf';
                                        fichier[onglet.moduleTrigramme + "_DOC_DATE"].valeur = new Date();
                                        fichier[onglet.moduleTrigramme + "_DOC_DESCRIPTION"].valeur = 'Modèle issu d\'une édition';
                                        $scope.grilles[onglet.moduleTrigramme +'_DOCUMENT'].push(fichier);
                                        // Sauvegarde de la grille des documents attachés
                                        return $scope.sauvegarde[onglet.moduleTrigramme + '_DOCUMENTS']();
                                    });
                            })

                            .then(function (resultat) {

                            }, function (err) {
                                // TODO Gestion de l'erreur
                            });
                    }
                }
            };

            // Edition PDF et envoie par mail de courrier
            var cptFile = 0;
            var fichiersEnvoi = "";

            $scope.envoyer = function () {
                var idModele, idEntiteListe, entite;

                cptFile = 0;
                fichiersEnvoi = "";

                for (var i = 0; i < $scope.selectionRechercheModele.length; i++) {
                    idModele = $scope.selectionRechercheModele[i][ID_MODELE].valeur;
                    idEntiteListe = $scope.selectionRechercheModele[i][MODELECLESPRIMAIRES].valeur.split("/");
                    entite = vm.getEntite(selection[i], idEntiteListe);

                    if (angular.isNullOrUndefined(entite)) {
                        opPopupAlerte.creer('warning',"L'entité n'a pas été trouvée. Vérifiez que le document a bien été compilé et attaché à un module.", true, false);
                        return;
                    }

                    var envoi = function (aNomModule, aIdModele, aEntite) {

                        var entiteValue = aEntite.split(SEPARATEUR_NIVEAU_1)[1];

                        return requeteEdition.recupererLien(aNomModule, aIdModele, aEntite, 'application/pdf')
                            .then(function (responseEdition) {
                                // Mantis 5775 Gérer caractères espaces dans le nom des fichiers
                                var cheminFichier = responseEdition.data.replace(/%20/g, "_");
                                $window.open(cheminFichier,"_blank");
                                aEntite = aEntite.replace(SEPARATEUR_NIVEAU_1, ',');

                                return requeteFichier.ajouterFichierDirectURL(aNomModule, $scope, cheminFichier, aEntite, onglet.moduleTrigramme + '_DOCUMENT');
                            })
                            .then(function (responseFichier) {
                                if(fichiersEnvoi.length > 0) {
                                    fichiersEnvoi += ",";
                                }
                                fichiersEnvoi += responseFichier;

                                if(cptFile == $scope.selectionRechercheModele.length - 1) {
                                    // Ouverture du popup d'action
                                    var popupMail = $modal.open({
                                        size: 'xlg',
                                        windowClass: '',
                                        template: '<div class="modal-body"><op-popup-mail title="Envoi de mail" op-nom-module="' + aNomModule + '" op-critere="' + entiteValue + '" op-message="{{message}}"></op-popup-mail></div>',
                                        scope: $scope,
                                        controller: ["$scope", function ($scope) {
                                            $scope.message = {expediteur:"", titre:"", documents: fichiersEnvoi};
                                        }]
                                    });

                                    cptFile = 0;
                                } else {
                                    ++cptFile;
                                }
                            }, function (err) {
                                ++cptFile;
                                opPopupAlerte.creer('error', (!angular.isNullOrUndefined(err.statusText) ? angular.fromJson(err.statusText).libelle : err.data), true, false);
                            });
                    };

                    envoi(moduleNom, idModele, entite);
                }
            };

            // Edition Word ou ODT de courrier
            $scope.editer = function () {
                var idModele, idEntiteListe, entite, format;
                
                format = parametre.lireParametre("Operia",EDITION_FORMAT_DEFAULT);

                for (var i = 0; i < $scope.selectionRechercheModele.length; i++) {
                    idModele = $scope.selectionRechercheModele[i][ID_MODELE].valeur;
                    idEntiteListe = $scope.selectionRechercheModele[i][MODELECLESPRIMAIRES].valeur.split("/");
                    entite = vm.getEntite(selection[i], idEntiteListe);

                    if (angular.isNullOrUndefined(entite)) {
                        opPopupAlerte.creer('warning',"L'entité n'a pas été trouvée. Vérifiez que le document à bien été compilé et attaché à un module.", true, false);
                        return;
                    }

                    requeteEdition.telechargerDocument(moduleNom, idModele, entite, format);
                }
            };

            // Fermeture du popup
            $scope.fermer = function () {
                $modalInstance.dismiss();
            };

            /**
             * Fonction de récupération de l'entite pour un modele
             * @param {Object} item Elément de la sélection en cours
             * @param {Array} listeEntite Liste des champs du clé primaire de l'élément de la sélection
             * @returns {*}
             */
            vm.getEntite = function (item, listeEntite) {
                var entiteOK = true;
                var entite = "";

                for (var i = 0; i < listeEntite.length; i++) {
                    if (listeEntite[i].length > 0) {
                        if (angular.isNullOrUndefined(item[listeEntite[i]])) {
                            // Champ(s) du clef primaire non défini(s) --> renvoyer null
                            entiteOK = false;
                            break;
                        } else {
                            // Format attendu : CLE_1ðValeur clé 1ñCLE_2ðValeur clé 2
                            if (!angular.isNullOrUndefined(entite) && (entite.trim() !== "")) {
                                // Mantis 6073 Edition impossible suite ouverture onglet par lien dans synthèse
                                if (angular.isNullOrUndefined(item[listeEntite[i]].valeur)) {
                                    entite += SEPARATEUR_NIVEAU_2 + listeEntite[i] + SEPARATEUR_NIVEAU_1 + item[listeEntite[i]].toString();
                                } else {
                                    entite += SEPARATEUR_NIVEAU_2 + listeEntite[i] + SEPARATEUR_NIVEAU_1 + item[listeEntite[i]].valeur.toString();
                                }
                            } else {
                                // Mantis 6073 Edition impossible suite ouverture onglet par lien dans synthèse
                                if (angular.isNullOrUndefined(item[listeEntite[i]].valeur)) {
                                    entite = listeEntite[i] + SEPARATEUR_NIVEAU_1 + item[listeEntite[i]].toString();
                                } else {
                                    entite = listeEntite[i] + SEPARATEUR_NIVEAU_1 + item[listeEntite[i]].valeur.toString();
                                }
                            }
                        }
                    }
                }

                // Mantis 5759 : Contrôler que le clef est correctement formatté
                if (entiteOK) {
                    var lstId = entite.trim().split(SEPARATEUR_NIVEAU_2);
                    for (var j = 0; j < lstId.length; j++) {
                        if (lstId[j].split(SEPARATEUR_NIVEAU_1).length !== 2) {
                            // Clef vide ou mal formaté --> renvoyer null
                            entiteOK = false;
                            break;
                        } else {
                            var colClef = lstId[j].split(SEPARATEUR_NIVEAU_1)[0];
                            // Mantis 6073 Edition impossible suite ouverture onglet par lien dans synthèse
                            if ((angular.isNullOrUndefined(item[colClef])) &&
                                (angular.isNullOrUndefined(item[colClef].valeur))) {
                                // Champ clef non renseigné pour l'entité sélectionné --> renvoyer null
                                entiteOK = false;
                                break;
                            }
                        }
                    }
                }

                return (entiteOK) ? entite.trim() : null;
            }
        }]);