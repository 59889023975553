/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.ReferentielServlet', [])
    .provider('fr.operis.moteurV4.utils.ReferentielServlet', function () {
            var _servletParam = {};
            var _servletUrl = window.location.protocol + '//' + window.location.hostname + ':' +
                window.location.port + '' + window.location.pathname.replace("/Application","/") + 'RechercheReferentiel';

            // Variable pour le retour des recherches selon un/des code(s) Insee
            var _retourRechercheInsee = [];

            // Configuration des champs pour la communication des servlets Referentiel
            this.setCommunicationServlet = function(servletListeChamps){
                _servletParam = servletListeChamps;
            };


            /**
             * Retourne le service communicationServlet.
             * @param {injector} $injector
             * @returns {fr.operis.moteurV4.utils.communicationServlet}
             */
            this.$get = ['$injector', function ($injector) {
                return $injector.instantiate([
                    '$http',
                    '$q',
                    'fr.operis.moteurV4.communication.OperisWSServer',
                    'fr.operis.moteurV4.utils.Conversion',
                    servlet
                ]);
            }
            ];

            function servlet($http,q,serveur,conversion) {
                //
                /**
                 * Réalise la récupération de la configuration des champs pour la communication des servlets Referentiel.
                 *
                 * @param {String} Le nom du référentiel
                 * @param {String} Le nom du champs
                 * @returns {Object}
                 */
                this.getParamServlet = function (nomReferentiel, nomChamps){
                    return _servletParam[nomReferentiel][nomChamps];
                };

                /**
                 * Recherche référentiel par code Insee
                 *
                 * @param nomModule Nom du module appelant
                 * @param nomReferentiel Nom de référentiel cible pour la recherche
                 * @param valeurRecherche Valeur à rechercher
                 * @param filtreRecherche Filtre à applique sur les valeurs de retour
                 * @param codeInsee Code Insee à cibler
                 * @returns {Object} Promesse qui se résout une fois la recherche effectuée
                 */
                this.getPromesseRechercheReferentielInsee = function (nomModule, nomReferentiel, valeurRecherche, filtreRecherche, codeInsee) {
                    var deferred = q.defer();

                    var paramsAppelServlet = {
                        idSession: serveur.getIdSession(nomModule),
                        texteRecherche: valeurRecherche,
                        referentiel: nomReferentiel,
                        inseeCode: codeInsee
                    };

                    if (!angular.isNullOrUndefined(filtreRecherche)) {
                        for (var i = 0; i < filtreRecherche.length; i++) {
                            if (!angular.isNullOrUndefined(filtreRecherche[i])) {
                                paramsAppelServlet['filtre' + (i + 1).toString()] = filtreRecherche[i];
                            }
                        }
                    }

                    $http.get(_servletUrl, {
                        params: paramsAppelServlet
                    }).then(function (response) {
                        if (!angular.isNullOrUndefined(response.data.results)) {
                            _retourRechercheInsee = _retourRechercheInsee.concat(response.data.results);
                        }

                        deferred.resolve(response);
                    });

                    return deferred.promise;
                };

                /**
                 * Réalise la recherche sur un référentiel à partir d'une valeur recherchée
                 *
                 * @param {String} Le nom du référentiel
                 * @param {String} La valeur recherchée
                 * @returns {Object}
                 */
                this.rechercheReferentiel = function (nomModule, nomReferentiel, valeurRecherche, filtreRecherche, filtreInsee){
                    if ( !angular.isNullOrUndefined ( nomModule ) && !angular.isNullOrUndefined ( nomReferentiel )) {
                        var i;

                        if (angular.isNullOrUndefined(filtreInsee) || (filtreInsee === "")) {
                            // Recherche référentiel sans filtre selon code Insee
                            var paramsAppelServlet = {
                                idSession: serveur.getIdSession(nomModule),
                                texteRecherche: valeurRecherche,
                                referentiel: nomReferentiel
                            };

                            if (!angular.isNullOrUndefined(filtreRecherche)) {
                                for (i = 0; i < filtreRecherche.length; i++) {
                                    if (!angular.isNullOrUndefined(filtreRecherche[i])) {
                                        paramsAppelServlet['filtre' + (i + 1).toString()] = filtreRecherche[i];
                                    }
                                }
                            }

                            return $http.get(_servletUrl, {
                                params: paramsAppelServlet
                            }).then(function (response) {
                                if (!angular.isNullOrUndefined(response.data.results)) {
                                    return response.data.results.map(function (item){
                                        return item;
                                    });
                                }
                            });
                        } else {
                            // Recherche référentiel avec filtre selon code Insee

                            // Réinit retour de récherche
                            _retourRechercheInsee = [];

                            // Init tableau des promesses (1 promesse par code Insee)
                            var listePromesses  = [];
                            for (i = 0; i < filtreInsee.length; i++) {
                                var promesse = this.getPromesseRechercheReferentielInsee(nomModule, nomReferentiel, valeurRecherche, filtreRecherche, filtreInsee[i]);
                                listePromesses.push(promesse);
                            }

                            // Retour après tous les recherches ont été effectuées
                            return q.all(listePromesses).then(function () {
                                var bufRetour = _retourRechercheInsee;

                                for (i = bufRetour.length - 1; i >= 0; i--) {
                                    var lib = bufRetour[i].libelle.toUpperCase();
                                    if (lib.indexOf(valeurRecherche.toUpperCase()) === -1) {
                                        bufRetour.splice(i, 1);
                                    }
                                }

                                return bufRetour.map(function (item) {
                                    return item;
                                });
                            });
                        }
                    }
                };

                /**
                 * Gère le retour de sélection de champs référentiel
                 *
                 * @param {Object} aScope
                 * @param {Object} item sélectionné
                 * @param {String} L'identifiant du modèle
                 * @param {String} Le nom du référentiel
                 * @param {Integer} L'index
                 */
                this.onChangeReferentiel = function(aScope, $item, $model, $label, id, nomReferentiel, $index){
                    if (angular.isNullOrUndefined($item))
                        return;

                    aScope.$item = $item;
                    aScope.$model = $model;
                    aScope.$label = $label;

                    var idModele = id.replace('[$index]','');
                    idModele = idModele.replace(/^champs./,'');
                    idModele = idModele.replace(/^grilles./,'');
                    var nomTableModele = idModele.split('.')[0];

                    var configurationServletReferentiel = this.getParamServlet( nomReferentiel, idModele);

                    if ( !angular.isNullOrUndefined(configurationServletReferentiel)
                        && !angular.isNullOrUndefined(configurationServletReferentiel.champsBD)
                        && !angular.isNullOrUndefined(configurationServletReferentiel.champsModele)){
                        for (var i=0;i<configurationServletReferentiel.champsModele.length;i++){
                            var typeDonnee;
                            var valeur = $item[configurationServletReferentiel.champsModele[i]];

                            if (!angular.isNullOrUndefined(valeur) && !angular.isNullOrUndefined(configurationServletReferentiel.champsBD[i])){
                                if (!angular.isNullOrUndefined($index)) {
                                    if (!angular.isNullOrUndefined(aScope.entite[configurationServletReferentiel.champsBD[i]])) {
                                        typeDonnee = aScope.entite[configurationServletReferentiel.champsBD[i]].descriptions.type;
                                        aScope.entite[configurationServletReferentiel.champsBD[i]].valeur = conversion.versType(valeur, typeDonnee);
                                    }
                                } else {
                                    if (!angular.isNullOrUndefined(aScope.champs[nomTableModele][configurationServletReferentiel.champsBD[i]])) {
                                        typeDonnee = aScope.champs[nomTableModele][configurationServletReferentiel.champsBD[i]].descriptions.type;
                                        aScope.champs[nomTableModele][configurationServletReferentiel.champsBD[i]].valeur = conversion.versType(valeur, typeDonnee);
                                    }
                                }
                            } else {
                                // Renseignement du code insee à partir du nom de commune
                                if (!angular.isNullOrUndefined(configurationServletReferentiel.listeINSEE) &&
                                    !angular.isNullOrUndefined(configurationServletReferentiel.champCodeINSEE) &&
                                    !angular.isNullOrUndefined(configurationServletReferentiel.modeleCodeINSEE) &&
                                    !angular.isNullOrUndefined(configurationServletReferentiel.champCleINSEE) &&
                                    !angular.isNullOrUndefined(configurationServletReferentiel.modeleCleINSEE)) {
                                    var tableINSEE = configurationServletReferentiel.listeINSEE;
                                    var champCodeINSEE = configurationServletReferentiel.champCodeINSEE;
                                    var modeleCodeINSEE = configurationServletReferentiel.modeleCodeINSEE;
                                    var champCleINSEE = configurationServletReferentiel.champCleINSEE;
                                    var modeleCleINSEE = configurationServletReferentiel.modeleCleINSEE;

                                    if ((configurationServletReferentiel.champsModele[i] === modeleCodeINSEE) && (!angular.isNullOrUndefined($item[modeleCleINSEE]))) {
                                        // Parcourir la table liste pour retrouver le code INSEE à partir du champ clé
                                        var idx = -1;
                                        for (var j = 0; j < aScope.listes[tableINSEE].length; j++) {
                                            if (aScope.listes[tableINSEE][j][champCleINSEE].valeur === $item[modeleCleINSEE]) {
                                                idx = j;
                                                break;
                                            }
                                        }

                                        if (idx !== -1) {
                                            // Code INSEE trouvé
                                            valeur = aScope.listes[tableINSEE][idx][champCodeINSEE].valeur;

                                            if (!angular.isNullOrUndefined($index)) {
                                                typeDonnee = aScope.entite[configurationServletReferentiel.champsBD[i]].descriptions.type;
                                                aScope.entite[configurationServletReferentiel.champsBD[i]].valeur = conversion.versType(valeur, typeDonnee);
                                            } else {
                                                typeDonnee = aScope.champs[nomTableModele][configurationServletReferentiel.champsBD[i]].descriptions.type;
                                                aScope.champs[nomTableModele][configurationServletReferentiel.champsBD[i]].valeur = conversion.versType(valeur, typeDonnee);
                                            }
                                        } else {
                                            // Code INSEE non trouvé : mettre le champ à NULL
                                            if (!angular.isNullOrUndefined($index)) {
                                                aScope.entite[configurationServletReferentiel.champsBD[i]].valeur = null;
                                            } else {
                                                aScope.champs[nomTableModele][configurationServletReferentiel.champsBD[i]].valeur = null;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            };
        }
    );