/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';


angular.module('fr.operis.moteurV4.filtres.FiltreListe', [])
    .filter('filtreListe', function () {
        return function (items, keyName, keyValeur, sortName) {
            var  filtered = [];
            if ( !angular.isNullOrUndefined(items)){
                for (var i = 0; i < items.length; i++) {
                    if ((!angular.isNullOrUndefined(keyValeur)) && (typeof keyValeur === 'object')) {
                        var objetCible = {};
                        for (var property in keyName) {
                            if (keyName.hasOwnProperty(property)) {
                                objetCible[property] = items[i][keyName[property]].valeur;
                            }
                        }

                        if (JSON.stringify(objetCible) == JSON.stringify(keyValeur)) {
                            filtered.push(items[i]);
                        }
                    } else {
                        if (angular.isNullOrUndefined(keyValeur)
                            || keyValeur === ''
                            || items[i][keyName].valeur === keyValeur
                            || angular.isNullOrUndefined(items[i][keyName].valeur)
                            || items[i][keyName].valeur.length == 0)
                            filtered.push(items[i]);
                    }
                }

                if (!angular.isNullOrUndefined(sortName)) {
                    filtered.sort(function (a, b) {
                        // Passage en majuscule en tenant compte des accents
                        var minus = "aàâäæbcçdeéèêëfghiîïjklmnoôöpqrstuùûvwxyz";
                        var majus = "AAAAABCCDEEEEEFGHIIIJKLMNOOOPQRSTUUUVWXYZ";
                        var valeurA = a[sortName].valeur;
                        var valeurB = b[sortName].valeur;
                        var bufA = "";
                        var bufB = "";

                        for (var i = 0 ; i < valeurA.length ; i++)
                        {
                            var carA = valeurA.substr(i, 1);
                            bufA += (minus.indexOf(carA) != -1) ? majus.substr(minus.indexOf(carA), 1) : carA;
                        }

                        for (var j = 0 ; j < valeurB.length ; j++)
                        {
                            var carB = valeurB.substr(j, 1);
                            bufB += (minus.indexOf(carB) != -1) ? majus.substr(minus.indexOf(carB), 1) : carB;
                        }

                        bufA = bufA.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                        bufB = bufB.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");

                        return (bufA > bufB ? 1 : -1); //return (a[sortName] > b[sortName] ? 1 : -1);
                    });
                }

            }

            return filtered;
        };
    })
    .filter('trierListe', function () {
        return function (items, sortName) {
            if (angular.isNullOrUndefined(items))
                return;

            var  filtered = [];
            for (var i = 0; i < items.length; i++) {
                filtered.push(items[i]);
            }

            if (!angular.isNullOrUndefined(sortName)) {
                filtered.sort(function (a, b) {
                    // Passage en majuscule en tenant compte des accents
                    var minus = "aàâäæbcçdeéèêëfghiîïjklmnoôöpqrstuùûvwxyz";
                    var majus = "AAAAABCCDEEEEEFGHIIIJKLMNOOOPQRSTUUUVWXYZ";
                    var valeurA = a[sortName].valeur;
                    var valeurB = b[sortName].valeur;
                    var bufA = "";
                    var bufB = "";

                    for (var i = 0 ; i < valeurA.length ; i++)
                    {
                        var carA = valeurA.substr(i, 1);
                        bufA += (minus.indexOf(carA) != -1) ? majus.substr(minus.indexOf(carA), 1) : carA;
                    }

                    for (var j = 0 ; j < valeurB.length ; j++)
                    {
                        var carB = valeurB.substr(j, 1);
                        bufB += (minus.indexOf(carB) != -1) ? majus.substr(minus.indexOf(carB), 1) : carB;
                    }

                    bufA = bufA.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");
                    bufB = bufB.replace(/[ÀÁÂÄÆ]/g, "A").replace(/[ÉÈÊË]/g, "E").replace(/[Ç]/g, "C").replace(/[ÎÏ]/g, "I").replace(/[ÔÖ]/g, "O").replace(/[ÙÛ]/g, "U");

                    return (bufA > bufB ? 1 : -1); //return (a[sortName] > b[sortName] ? 1 : -1);
                });
            }

            return filtered;
        };
    })
    .filter('filtreGridDropDown',function(){
        return function (valeur, map, keyName, keyValeur){
            for ( var i=0; i<map.length;i++ ){
                if ( !angular.isNullOrUndefined(map[i][keyValeur]) && map[i][keyValeur].valeur === valeur){
                    return map[i][keyName].valeur;
                }
            }

            return null;
        }

    });
