/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteMultiAppel', [])

    .service('fr.operis.moteurV4.utils.RequeteMultiAppel',
        [   'fr.operis.moteurV4.utils.Erreur',
            '$q',
            '$window',
            function (erreur,
                        $q,
                        $window) {

            // Variables internes à la classe
            var that = this,
                _multiAppel = false,
                _multiAppelFonction,
                _multiAppelFonctionCallback = [],
                _multiAppelDonnees = [],
                _multiAppelNbRetours = 0,
                _multiAppelInformation,
                _multiAppelTransaction,
                _multiAppelStopOnError = false;

             this._multiAppelServeur;

            /**
             * Retourne l'état de multi appel
             * @returns {boolean} L'état du multiAppel
             */
            this.getMultiAppel = function (){
                return  _multiAppel;
            };

            /**
             * Défini l'état du multi appel.
             * @param {string} aMultiAppel L'état du multi appel
             */
            this.setMultiAppel = function (aScope, aMultiAppel) {
                var deferred = $q.defer();

                _multiAppel = aMultiAppel;

                if ( !angular.isNullOrUndefined(_multiAppel) && !angular.isNullOrUndefined(this._multiAppelServeur) && !_multiAppel ){
                    this._multiAppelServeur(_multiAppelFonction, _multiAppelDonnees, _multiAppelNbRetours, _multiAppelInformation)
                        .then(function(resultat){
                            // Réccupération du nom de l'écran, de l'identifiant
                            for ( var indice = 0; indice < _multiAppelFonctionCallback.length; indice ++){
                                _multiAppelFonctionCallback[indice](resultat.slice(0 + _multiAppelNbRetours * indice, _multiAppelNbRetours * ( indice + 1) ));
                            }

                            deferred.resolve();
                            that._clearMultiAppel();
                        },function(erreur){
                            deferred.reject(erreur);
                            that._clearMultiAppel();
                        });
                }else
                    deferred.resolve();

                return deferred.promise;
            };

            /**
             * Ajoute un appel.
             *
             * @param aModule Nom du module de l'appel
             * @param aFonction Nom de la fonction de l'appel
             * @param aDonnees Données de l'appel
             * @param {Integer} aNbRetours Nombre de retour pour l'appel
             * @param {fr.operis.moteurV4.communication.Transaction} aTransaction Transaction pour l'appel (vide ou nul si pas de transaction)
             * @param {fr.operis.moteurV4.communication.InformationsRequete} aInformations Informations sur l'appel
             *
             */
            this.add = function( aModule, aFonction, aParametres, aNbRetours, aInformation, aTransaction, aFunctionCallback) {
                if ( !angular.isNullOrUndefined(_multiAppelFonction) && _multiAppelFonction != aFonction )
                    return erreur.creer("7010", this, aModule);

                _multiAppelFonction = aFonction;
                _multiAppelNbRetours = aNbRetours;
                _multiAppelInformation = aInformation;
                _multiAppelTransaction = aTransaction;

                // CallBack
                _multiAppelFonctionCallback.push(aFunctionCallback);

                // Données
                if ( _multiAppelDonnees.length > 0){
                    _multiAppelDonnees.push($window.Math.round($window.Math.random() * 1000000).toString());
                    _multiAppelDonnees.push(_multiAppelFonction);
                }
                _multiAppelDonnees = _multiAppelDonnees.concat(aParametres);
                _multiAppelDonnees.push(_multiAppelStopOnError ? "0" : "1");
            }

            /**
             * Nettoyage des variables de la classe
             */
            this._clearMultiAppel = function(){
                _multiAppelFonction = null;
                _multiAppelNbRetours = 0;
                _multiAppelInformation = null;
                _multiAppelTransaction = null;
                _multiAppelDonnees = [];
                _multiAppelStopOnError = false;
                _multiAppel = false;
            }

        }
    ]
);