/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequetePreSauvegardeFiche', [])

    .service('fr.operis.moteurV4.utils.RequetePreSauvegardeFiche', ['$q','fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Conversion','fr.operis.moteurV4.utils.ReferenceInterne',
        'fr.operis.moteurV4.utils.Habilitation',
        'fr.operis.moteurV4.utils.ModuleInfos',
        function ($q, serveur, conversion, referenceInterne, habilitation, moduleInfos) {
            this._configuration={};

            // Configuration des requetes pre traitement
            this.configuration= function( aConfiguration ){
                this._configuration = aConfiguration;
            };



            // Execution des requetes pre traitement
            this.execution = function(aScope, nomModule, aIdCategorieModule, aScopeFormulaire, aIdAction, aTrigrammeAutre, aTableEntiteAutre){
                var deferred = $q.defer();

                if ( angular.isNullOrUndefined(nomModule) )
                    deferred.resolve();


                if ( aIdAction == 1 ){
                    // Date de création/IdCreateur automatique lors de création
                    if ( !angular.isNullOrUndefined(aScopeFormulaire.champs)){
                        var trigramme = moduleInfos.getInfo(nomModule, "MOD_TRIGRAMME");
                        if ( !angular.isNullOrUndefined(aTrigrammeAutre))
                            trigramme = aTrigrammeAutre;
                        var tableEntite = moduleInfos.getInfo(nomModule, "MOD_TABLEENTITE");
                        if ( !angular.isNullOrUndefined(aTableEntiteAutre))
                            tableEntite = aTableEntiteAutre;
                        for ( var tablenom in aScopeFormulaire.champs){
                            for ( var colNom in aScopeFormulaire.champs[tablenom] ){
                                if ( colNom.indexOf("_DATECREATION")!=-1){
                                    aScopeFormulaire.champs[tablenom][colNom].valeur =  new Date();
                                }else if (colNom.indexOf("ID_CREATEUR")!=-1){
                                    aScopeFormulaire.champs[tablenom][colNom].valeur = conversion.versEntier(serveur.getIdUtilisateur(nomModule)());
                                } else if (colNom.indexOf("_ID_SERVICEGESTCREATEUR")!=-1){
                                    aScopeFormulaire.champs[tablenom][colNom].valeur = habilitation.getIdServiceParDefault();
                                }

                            }
                        }
                    }

                    // Calcul du numéro interne et du nom de référence interne
                    var mobilite = serveur.isMobile()?'_M':'';
                    var nomEcranRechercheNumero = trigramme + mobilite + "_RECHERCHEREFINTERNENUMERO";
                    var nomModule = nomModule, aScopeFormulaire = aScopeFormulaire, aIdAction = aIdAction;
                    var that = this;
                    referenceInterne.rechercheNumeroInterne(nomModule, aScope, nomEcranRechercheNumero, nomModule)
                        .then(function(retourRechercheNumeroInterne){
                            var an = (new Date()).getFullYear();
                            aScopeFormulaire.champs[tableEntite][trigramme + "_REFINTERNENUMERO"].valeur = retourRechercheNumeroInterne;
                            aScopeFormulaire.champs[tableEntite][trigramme +"_REFINTERNENOM"].valeur = trigramme + ' ' + an + '-' +retourRechercheNumeroInterne;

                            if (!angular.isNullOrUndefined(that._configuration[nomModule.toUpperCase()])){
                                that._configuration[nomModule.toUpperCase()](aScope, aScopeFormulaire, aIdAction)
                                    .then(function(resultat){
                                        deferred.resolve(resultat);
                                    },function(erreur){
                                        deferred.reject(erreur);
                                    });

                            }
                            else
                                deferred.resolve();
                        })
                        .catch(function (erreur) {
                            deferred.reject(erreur);
                        });
                }else{
                    if (!angular.isNullOrUndefined(this._configuration[nomModule.toUpperCase()])){
                        return this._configuration[nomModule.toUpperCase()](aScope, aScopeFormulaire, aIdAction);
                    }else if(!angular.isNullOrUndefined(this._configuration[aIdCategorieModule])){
                        return this._configuration[aIdCategorieModule](aScope, aScopeFormulaire, aIdAction);
                    }
                    else
                        deferred.resolve();
                }

                return deferred.promise;
            }
        }
    ]
);