/**
 * @version 1.0
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.popup.OpPopupOptionExportCSV', ['moteur/composants/popup/OpPopupOptionExportCSV.html'])
    .controller('fr.operis.moteurV4.composants.popup.OpPopupOptionExportCSVCtrl',
        [ '$scope',
            '$q',
            'moduleNom',
            'ecranRecherche',
            'criteresRecherche',
            'selectionCourante',
            'pageCourante',
            'nombrePages',
			'taillePage',
            'fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.utils.Conversion',
            'fr.operis.moteurV4.utils.Config',
            function ($scope,
                      $q,
                      moduleNom,
                      ecranRecherche,
                      criteresRecherche,
                      selectionCourante,
                      pageCourante,
                      nombrePages,
					  taillePage,
                      serveur,
                      conversion,
                      config
                      ) {

            $scope.idRadioExportPlage = {plage: "Tout",plagePages:""};
            $scope.placeholder = "1-"+ nombrePages.toString();
            $scope.taillePage = taillePage; // Mantis 6117 : Taille d'une page de recherche personnalisé

            // Fonction d'export des données
            $scope.getgrilleCSV = function(){
                var deferred = $q.defer();

                var grilleCSV = [], pageDebut = 1, pageFin = nombrePages;
                if ( $scope.idRadioExportPlage.plage == "PageActive"){
                   // Export de la selection courante
                    deferred.resolve($scope.genererCSV(selectionCourante));
                }else {
                   // Export de toutes les pages ou d'une plage de pages
                    // Si plage de pages vérification de l'étendue
                    if ( $scope.idRadioExportPlage.plage == "Pages" ){
                        if ( $scope.idRadioExportPlage.plagePages.length<=0){
                            pageDebut = 1, pageFin = nombrePages;
                        }
                        else{
                            if ( !(/^\d+-\d+|^\d+/.test($scope.idRadioExportPlage.plagePages))){
                                deferred.reject();
                                alert("Spécifiez une étendue correcte");
                                return deferred.promise;
                            }
                            else{
                                var arrayPage = $scope.idRadioExportPlage.plagePages.split("-");
                                if ( arrayPage.length > 1 ){
                                    pageDebut = parseInt(arrayPage[0].trim());
                                    pageFin = parseInt(arrayPage[1].trim());
                                    if ( pageDebut > pageFin ){
                                        var pageTemp = pageDebut;
                                        pageDebut = pageFin;
                                        pageFin = pageTemp;
                                    }
                                }else{
                                    pageDebut = parseInt(arrayPage[0].trim());
                                    pageFin = parseInt(arrayPage[0].trim());
                                }

                                // Vérification que les pages existent
                                if ( pageDebut < 1 || pageDebut > nombrePages){
                                    deferred.reject();
                                    alert("Spécifiez une étendue correcte");
                                    return deferred.promise;
                                }
                                if ( pageFin < 1 || pageFin > nombrePages){
                                    deferred.reject();
                                    alert("Spécifiez une étendue correcte");
                                    return deferred.promise;
                                }

                            }
                        }
                    }

                    var donneesRecherche = [];
                    serveur.chargement(moduleNom,$scope,ecranRecherche,null)
                        .then(function(retourChargement){
                            // Mantis 5979 Renseigner les critères de recherche
                            for (var critere in criteresRecherche) {
                                if (criteresRecherche.hasOwnProperty(critere)) {
                                    if (!angular.isNullOrUndefined(criteresRecherche[critere].descriptions) &&
                                        !angular.isNullOrUndefined(criteresRecherche[critere].valeur)) {
                                        if (!angular.isNullOrUndefined($scope.champs[retourChargement[0][0]][criteresRecherche[critere].descriptions.nom])) {
                                            $scope.champs[retourChargement[0][0]][criteresRecherche[critere].descriptions.nom].valeur = criteresRecherche[critere].valeur;
                                        }
                                    }
                                }
                            }

                            $scope.recherche[ecranRecherche]($scope.taillePage)
                                .then(function(retourRecherche){
                                            var promesses = [];
                                            for ( var i = pageDebut; i <= pageFin; i++){
                                                promesses.push($scope.recherchePage[ecranRecherche](i)
                                                    .then(function(retourRecherchePage){
                                                        donneesRecherche = donneesRecherche.concat($scope.grilles[retourRecherche[0][0]]);
                                                    }));
                                            }

                                            $q.all(promesses).then(function (retourRecherche) {
                                                deferred.resolve($scope.genererCSV(donneesRecherche));
                                            });
                            })
                        })
                }

                return deferred.promise;
            }

            // Fonction de génération de CSV à partir d'une grille OPERIS
            $scope.genererCSV = function(aDonnnees){
                var donneesCSV = [];

                for (var i=0;i<aDonnnees.length;i++){
                    var ligne = {};
                    for ( var champsNom in aDonnnees[i]){
                        var champs = aDonnnees[i][champsNom];
                        if ( !angular.isNullOrUndefined(champs.descriptions) && champs.descriptions.visible.indexOf('T') !== -1 ){
                            ligne[champs.descriptions.libelle] = conversion.versTexte(champs.valeur,champs.descriptions.type);
                        }
                    }
                    donneesCSV.push(ligne);
                };
                return donneesCSV;
            }

        }]);