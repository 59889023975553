   /**
 * @version 2.1
 * @copyright 2015 Operis
 * @Author Laure-Hélène Bruneton, Alcer
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.cartographie.OpCartographie', ['ngSanitize', 'fr.operis.moteurV4.utils.Cartographie'])
    .directive('opCartographie',
        ['$ocLazyLoad', '$timeout', '$http',
            'fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.utils.Cartographie',
            'fr.operis.moteurV4.utils.Conversion',
            'fr.operis.moteurV4.utils.Conversion.TYPE_DATE',
            function($ocLazyLoad, $timeout, $http,
                      serveur,
                      utilsCarto,
                      conversion,
                      TYPE_DATE) {
                var currentId = 0;
                return {
                    restrict: 'E',
                    scope: {
                        opModule: '@',
                        opDescription: '=',
                        opDonnees: '=',
                        opSelection: '=',
                        opStyle: '@',
                        opAffichageSectionGauche: '='
                    },
                    template: '' +
                        '<div class="row">' +
                        '  <div class="btn-group" ng-class="cartographieCtrl.classeBarreOutils">' +
                        '    <div class="btn btn-default"' +
                        '        ng-click="cartographieCtrl.etendueInitiale()">' +
                        '      <span class="fa fa-globe fa-fw"></span>' +
                        '    </div>' +
                        '    <div class="btn btn-default"' +
                        '        ng-click="cartographieCtrl.etendueDonnees()">' +
                        '      <span class="fa fa-map-pin fa-fw"></span>' +
                        '    </div>' +
                        '    <div class="btn btn-default" ng-class="{\'active\': cartographieCtrl.outilInfoActif}"' +
                        '        ng-click="cartographieCtrl.switchOutilInfo()">' +
                        '      <span class="fa fa-info fa-fw"></span>' +
                        '    </div>' +
                        '    <div class="btn btn-default" ng-class="{\'active\': cartographieCtrl.outilFiltreActif}"' +
                        '        ng-click="cartographieCtrl.switchOutilFiltre()">' +
                        '      <span class="fa fa-filter fa-fw"></span>' +
                        '    </div>' +
                        '  </div>' +
                        '</div>' +
                        '<div id="map{{cartographieCtrl.id}}" ng-class="cartographieCtrl.classeOpenLayers"></div>' +
                        '<div id="popup{{cartographieCtrl.id}}" class="carto-popup">' +
                        '  <a href="" id="popup-previous{{cartographieCtrl.id}}" class="carto-popup-previous">' +
                        '    <span class="fa fa-caret-left"></span>' +
                        '  </a>' +
                        '  <span id="popup-count{{cartographieCtrl.id}}" class="carto-popup-count"></span>' +
                        '  <span id="popup-title{{cartographieCtrl.id}}" class="carto-popup-title"></span>' +
                        '  <a href="" id="popup-next{{cartographieCtrl.id}}" class="carto-popup-next">' +
                        '    <span class="fa fa-caret-right"></span>' +
                        '  </a>' +
                        '  <a href="" id="popup-closer{{cartographieCtrl.id}}" class="carto-popup-closer"><span class="fa fa-times"></span></a>' +
                        '  <div id="popup-content{{cartographieCtrl.id}}" class="carto-popup-content"></div>' +
                        '</div>',
                    controllerAs: 'cartographieCtrl',
                    controller: ['$scope',
                        function($scope) {
                            var vm = this;
                            
                            // Identifiant de la carte
                            vm.id = ++currentId;

                            // Outils actifs
                            vm.outilFiltreActif = false;
                            vm.outilInfoActif = false;
                            
                            // Classes
                            vm.classeBarreOutils = "carto-barre-outils";
                            vm.classeOpenLayers = "carto-openlayers";
                            
                            if (!angular.isNullOrUndefined($scope.opStyle)) {
                                vm.classeBarreOutils += $scope.opStyle;
                                vm.classeOpenLayers += $scope.opStyle;
                            }
                        }
                    ],
                    link: function(scope, element, attrs, controller) {
                        // Variables globales
                        var context, epsgCode;
                        var markers, markerStyle, markerSelectedStyle;
                        var vectorLayer, wmsLayer, map;
                            
                        // Contexte
                        utilsCarto.chargerContexte(scope.opModule)
                        .then(function(responseContext) {
                            context = responseContext;
                            // Projection
                            return utilsCarto.chargerProjection(context.EPSG);
                        }).then(function(responseEpsgCode) {
                            epsgCode = responseEpsgCode;
                            // OpenLayers
                            return $ocLazyLoad.load(['css/ol.min.css', 'lib/ol.min.js']);
                        })
                        .then(function() {
                            /* MARQUEURS */
                            markers = new ol.Collection([]);

                            markerStyle = new ol.style.Style({
                              image: new ol.style.Icon(/** @type {olx.style.IconOptions} */ ({
                                anchor: [0.5, 0.5],
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'fraction',
                                opacity: 0.90,
                                src: 'img/punaise_0000ff_Centre.png'
                              }))
                            });

                            markerSelectedStyle = new ol.style.Style({
                              image: new ol.style.Icon(/** @type {olx.style.IconOptions} */ ({
                                anchor: [0.5, 0.5],
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'fraction',
                                opacity: 0.90,
                                src: 'img/punaise_0000ff_Centre_Focus.png'
                              }))
                            });

                            // Mantis Operia 6667 : Regroupement marqueurs sur la vue cartographie
                            var clusterSource = new ol.source.Cluster({
                                source: new ol.source.Vector({ features: markers })
                            });

                            vectorLayer = new ol.layer.Vector({
                                /*source: new ol.source.Vector({ features: markers }),
                                style: markerStyle*/
                                source: clusterSource,
                                style: function(feature) {
                                    var nbreFeatures = feature.get('features').length;
                                    if (nbreFeatures > 1) {
                                        var clusterSelected = false;
                                        for (var i = 0; i < nbreFeatures; i++) {
                                            var f = feature.get('features')[i];
                                            if (f.get('selected')) {
                                                clusterSelected = true;
                                                break;
                                            }
                                        }

                                        var imgCluster = new ol.style.Icon({
                                            anchor: [0.5, 0.5],
                                            anchorXUnits: 'fraction',
                                            anchorYUnits: 'fraction',
                                            opacity: 0.90,
                                            src: 'img/groupe_punaise.png'
                                        });

                                        var imgSelectedCluster = new ol.style.Icon({
                                            anchor: [0.5, 0.5],
                                            anchorXUnits: 'fraction',
                                            anchorYUnits: 'fraction',
                                            opacity: 0.90,
                                            src: 'img/groupe_punaise_focus.png'
                                        });

                                        return new ol.style.Style({
                                            /*image: new ol.style.Circle({
                                                radius: 10,
                                                stroke: new ol.style.Stroke({
                                                    color: '#fff'
                                                }),
                                                fill: new ol.style.Fill({
                                                    color: '#00008b'
                                                })
                                            }),*/
                                            image: (clusterSelected) ? imgSelectedCluster : imgCluster,
                                            text: new ol.style.Text({
                                                text: nbreFeatures.toString(),
                                                fill: new ol.style.Fill({
                                                    color: '#fff'
                                                })
                                            })
                                        });
                                    } else {
                                        var marker = feature.get('features')[0];
                                        if (marker.get('selected')) {
                                            return markerSelectedStyle;
                                        } else {
                                            return markerStyle;
                                        }
                                    }
                                }
                            });
                            
                            if (!angular.isNullOrUndefined(context.sourceWMTS)) {
                                return $http.get(context.sourceWMTS.capabilities);
                            }
                        })
                        .then(function(responseHttp) {
                            if (!angular.isNullOrUndefined(responseHttp)) {
                                return responseHttp.data
                            }
                        }, function(reason) {
                            //
                        })
                        .then(function(wmtsCapabilities) {
                            /* COUCHES */
                            var layers = [vectorLayer];
                            // wms
                            if (!angular.isNullOrUndefined(context.sourceWMS)) {
                                wmsLayer = new ol.layer.Image({
                                    source: new ol.source.ImageWMS({
                                        url: context.sourceWMS.url,
                                        params: {'LAYERS': context.sourceWMS.layers}
                                    })
                                });
                                layers.unshift(wmsLayer);
                            }
                            
                            // wmts
                            if (!angular.isNullOrUndefined(wmtsCapabilities)) {
                                var parser = new ol.format.WMTSCapabilities();
                                var result = parser.read(wmtsCapabilities);
                                var options = ol.source.WMTS.optionsFromCapabilities(result, {
                                  layer: context.sourceWMTS.layer,
                                  matrixSet: context.sourceWMTS.matrixSet
                                });

                                var wmtsLayer = new ol.layer.Tile({
                                  source: new ol.source.WMTS(/** @type {!olx.source.WMTSOptions} */ (options))
                                });
                                layers.unshift(wmtsLayer);
                            }

                            // osm par défaut
                            if (layers.length == 1) {
                                var osmLayer = new ol.layer.Tile({
                                  source: new ol.source.OSM()
                                });
                                layers.unshift(osmLayer);
                            }

                            map = new ol.Map({
                                layers: layers,
                                controls: ol.control.defaults().extend([
                                    new ol.control.ScaleLine(),
                                    new ol.control.ZoomSlider(),
                                    new ol.control.FullScreen() // Mantis Operia 6668 : Vue cartographie en plein écran
                                ]),
                                target: document.getElementById('map' + controller.id),
                                view: new ol.View({
                                  projection: 'EPSG:' + epsgCode,
                                  center: [0,0],
                                  zoom: 1
                                })
                            });

                            map.getView().fit(context.initialExtent);

                            /* INTERACTIONS */
                            // Note: when Single-click is used double-clicks won't select features.
                            // This in contrast to Click, where a double-click will both select the feature
                            // and zoom the map (because of the DoubleClickZoom interaction).
                            // Note that Single-click is less responsive than Click because of the delay it uses to detect double-clicks.
                            var popup = document.getElementById('popup' + controller.id);
                            var title = document.getElementById('popup-title' + controller.id);
                            var content = document.getElementById('popup-content' + controller.id);
                            var previous = document.getElementById('popup-previous' + controller.id);
                            var count = document.getElementById('popup-count' + controller.id);
                            var next = document.getElementById('popup-next' + controller.id);
                            var closer = document.getElementById('popup-closer' + controller.id);

                            var overlay = new ol.Overlay({ element: popup });
                            map.addOverlay(overlay);
                            
                            refreshPopup = function(indice, total, titleHTML, contentChild) {
                                count.innerHTML = indice + "/" + total;
                                title.innerHTML = titleHTML;
                                content.innerHTML = "";
                                content.appendChild(contentChild);
                            };

                            closer.onclick = function() {
                              overlay.setPosition(undefined);
                              return false; //Don't follow the href
                            };

                            // display popup on singleclick
                            map.on('singleclick', function(evt) {
                                var feature = map.forEachFeatureAtPixel(evt.pixel,
                                    function(feature) {
                                        // Mantis Operia 6667 : Regroupement marqueurs sur la vue cartographie
                                        var features = feature.get('features');
                                        return features;
                                    });

                                if (feature) {
                                  if (controller.outilInfoActif) {
                                    refreshPopup(1, 1, scope.opModule, feature[0].get('information')); // Mantis Operia 6667 : Regroupement marqueurs sur la vue cartographie
                                    overlay.setPosition(feature[0].getGeometry().getCoordinates());
                                  } else {
                                      // Mantis Operia 6667 : Regroupement marqueurs sur la vue cartographie
                                      var featureSelected = false;
                                      var regroupement = (feature.length > 1);

                                      if (regroupement) {
                                          for (var f = 0; f < feature.length; f++) {
                                              if (feature[f].get('selected')) {
                                                  featureSelected = true;
                                                  break;
                                              }
                                          }
                                      } else {
                                          featureSelected = feature[0].get('selected');
                                      }

                                      for (var i = 0; i < feature.length; i++) {
                                          basculerSelection(feature[i].get('element'), regroupement, featureSelected);
                                      }
                                  }
                                } else if (controller.outilInfoActif) {
                                  var viewResolution = /** @type {number} */ (map.getView().getResolution());
                                  var url = wmsLayer.getSource().getGetFeatureInfoUrl(
                                    evt.coordinate, viewResolution, 'EPSG:' + epsgCode,
                                    {'INFO_FORMAT': context.sourceWMS.infoFormat});
                                  if (url) {
                                    $http.get(url).then(function(response) {                                          
                                      var features = new ol.format.GeoJSON().readFeatures(response.data);
                                      
                                      if (!angular.isNullOrUndefined(features) && features.length > 0) {
                                        var index = 0,
                                            title = response.data["features"][index]["layerName"],
                                            table = utilsCarto.creerTableAttributs(features[index]);
                                        refreshPopup("1",features.length, title, table);
                                        
                                        if (features.length > 1) {                                              
                                          previous.onclick = function() {
                                            index = Math.max(0, --index);
                                            title = response.data["features"][index]["layerName"];
                                            table = utilsCarto.creerTableAttributs(features[index]);
                                            refreshPopup((index + 1),features.length, title, table);
                                            return false; //Don't follow the href
                                          };

                                          next.onclick = function() {
                                            index = Math.min(++index, features.length - 1);
                                            title = response.data["features"][index]["layerName"];
                                            table = utilsCarto.creerTableAttributs(features[index]);
                                            refreshPopup((index + 1),features.length, title, table);
                                            return false; //Don't follow the href
                                          };
                                        }
                                        overlay.setPosition(evt.coordinate);
                                      } else {
                                        overlay.setPosition(undefined);
                                      }
                                    });
                                  }
                                }
                            });

                            // change mouse cursor when over marker
                            map.on('pointermove', function(e) {
                                if (e.dragging) {
                                  return;
                                }
                                var pixel = map.getEventPixel(e.originalEvent);
                                var hit = map.hasFeatureAtPixel(pixel);
                                map.getTarget().style.cursor = hit ? 'pointer' : '';
                            });

                            //-----------------------------------------------------------------
                            // Fonctions carto
                            function resetMarkers() {
                                var points = [];
                                markers.clear();

                                if (colCoordonnees) {
                                    for (var i = 0; i < scope.opDonnees.length; i++) {
                                        var element = scope.opDonnees[i];                                        
                                        var coordonnes = element[colCoordonnees].valeur;
                                        var latlonprojection = coordonnes.split(';');

                                        // Mantis Operia 6667 : Regroupement marqueurs sur la vue cartographie
                                        if ((latlonprojection.length > 2) &&
                                            (!angular.isNullOrUndefined(latlonprojection[0])) &&
                                            (!angular.isNullOrUndefined(latlonprojection[1])) &&
                                            (!angular.isNullOrUndefined(latlonprojection[2])) &&
                                            (latlonprojection[0] != '') &&
                                            (latlonprojection[1] != '') &&
                                            (latlonprojection[2] != '')) {
                                            var message = document.createElement("div");
                                            if (colsChampVisibleCarto.length == 0) {
                                                message.innerHTML = "Aucun champ visible pour la cartographie";
                                            } else {
                                                for (var j = 0; j < colsChampVisibleCarto.length; j++) {
                                                    var valeur = element[colsChampVisibleCarto[j]].valeur;

                                                    if (valeur instanceof Date ) {
                                                        valeur = conversion.versTexte(valeur,TYPE_DATE);
                                                    }
                                                    if (j == 0) {
                                                        message.innerHTML = '<p><b>' + valeur + '</b></p>';
                                                    } else {
                                                        message.innerHTML += '<p>' + valeur + '</p>';
                                                    }
                                                }
                                            }
                                            
                                            var marker = new ol.Feature({
                                                geometry: new ol.geom.Point(
                                                    ol.proj.transform(
                                                        [parseFloat(latlonprojection[0]), parseFloat(latlonprojection[1])],
                                                        'EPSG:' + latlonprojection[2], 'EPSG:' + epsgCode
                                                    )),
                                                element: element,
                                                information: message,
                                                selected: (scope.opSelection.indexOf(element) != -1)
                                            });
                                            
                                            if (marker.get('selected')) {
                                                marker.setStyle(markerSelectedStyle)
                                            }                                            
                                            points.push(marker);
                                        }
                                    }
                                }

                                if (controller.outilFiltreActif) {
                                    points = points.filter(
                                      function(marker) {
                                        return marker.get('selected');
                                    });
                                }
                                markers.extend(points);
                            }
                            
                            //-----------------------------------------------------------------
                            // Fonctions
                            function basculerSelection(element, regroupement, featureSelected) {
                                scope.$apply(
                                  function() {
                                      var index = scope.opSelection.indexOf(element);

                                      // Mantis Operia 6667 : Regroupement marqueurs sur la vue cartographie
                                      if (regroupement) {
                                          if (featureSelected) {
                                              if (index != -1) {
                                                  scope.opSelection.splice(index, 1);
                                              }
                                          } else {
                                              scope.opSelection.push(element);
                                          }
                                      } else {
                                          if (index != -1) {
                                              scope.opSelection.splice(index, 1);
                                          } else {
                                              scope.opSelection.push(element);
                                          }
                                      }
                                });
                            }

                            //-----------------------------------------------------------------
                            // Fonctions du controller

                            controller.switchOutilInfo = function() {
                                controller.outilInfoActif = !controller.outilInfoActif;
                            }

                            controller.switchOutilFiltre = function() {
                                controller.outilFiltreActif = !controller.outilFiltreActif;
                                resetMarkers();
                            }

                            controller.etendueInitiale = function() {
                                map.getView().fit(context.initialExtent);
                            }

                            controller.etendueDonnees = function() {
                                var points = [];
                                var selectedPoints = markers.getArray().filter(
                                  function(marker) {
                                    return marker.get('selected');
                                });
                                
                                if (selectedPoints.length == 1) {
                                    map.getView().setCenter(selectedPoints[0].getGeometry().getCoordinates());
                                    map.getView().setZoom(15);
                                } else {
                                    if (selectedPoints.length > 0) {
                                        points = selectedPoints;
                                    } else {
                                        points = markers.getArray();
                                    }
                                
                                    var coordinates = points.map(
                                      function(marker) {
                                          return marker.getGeometry().getCoordinates();
                                    });
                                    
                                    var extent = ol.extent.boundingExtent(coordinates);
                                    map.getView().fit(extent);
                                }
                            }

                            //-----------------------------------------------------------------
                            // Evénements

                            // Première colonne de type coordonnées lue dans les descriptions
                            var colCoordonnees;
                            // Tableau des colonnes des champs principaux
                            var colsChampVisibleCarto;

                            var unwatchDescription = scope.$watch('opDescription', function(value) {
                                if (!angular.isNullOrUndefined(value)) {
                                    colsChampVisibleCarto = [];
                                    for (var i = 0; i < value.length; i++) {
                                        if (value[i].typeComplexe && value[i].typeComplexe == 'COORDONNEES') {
                                            colCoordonnees = value[i].nom;
                                        }
                                        if (value[i]['visible'].indexOf('C') != -1)
                                            colsChampVisibleCarto.push(value[i]['nom']);
                                    }
                                }
                            });

                            scope.$watch('opDonnees', function(value) {
                                if (!angular.isNullOrUndefined(value)) {
                                    resetMarkers();
                                }
                            });

                            scope.$watchCollection('opSelection', function(value) {
                                if (!angular.isNullOrUndefined(value)) {
                                    resetMarkers();
                                }
                            });
                            
                            scope.$watch('opAffichageSectionGauche', function(value) {
                                if(map && !angular.isNullOrUndefined(value)) {
                                    $timeout(
                                        function() {
                                            map.updateSize();
                                        },
                                        100, false);
                                }
                            });
                        });
                    }
                };
            }
        ]);