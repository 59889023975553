/**
 * @name OpPopupRecurrence
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupRecurrence', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupRecurrenceControleur',
        ['$scope', '$modalInstance', 'fr.operis.moteurV4.communication.OperisWSServer', 'titre', 'recurrence',
            function (scope, modalInstance, serveur, titre, recurrence) {
                scope.titre = titre;

                // Init objet récurrence
                scope.recurrence = recurrence;
                if (angular.isNullOrUndefined(scope.recurrence)) {
                    scope.recurrence = {
                        recType: "Jour",
                        recIntervalle: 1,
                        recDateLimite: null,
                        recLundi: 0,
                        recMardi: 0,
                        recMercredi: 0,
                        recJeudi: 0,
                        recVendredi: 0,
                        recSamedi: 0,
                        recDimanche: 0,
                        recJourDuMois: 1,
                        recMois: 1,
                        recOuvre: 0,
                        recFerie: 0
                    };
                }

                // Fonction d'ouverture du datepicker
                scope.open = function ($event, opened) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    if (typeof(scope.datepicker) === 'undefined') {
                        scope.datepicker = {};
                    }
                    scope.datepicker[opened] = true;
                };

                // Saisie complète ?
                scope.saisieNOK = function () {
                    if (!angular.isNullOrUndefined(scope.recurrence.recType) &&
                        !angular.isNullOrUndefined(scope.recurrence.recIntervalle) &&
                        !angular.isNullOrUndefined(scope.recurrence.recDateLimite)) {
                        if ((scope.recurrence.recType === "Jour") || (scope.recurrence.recType === "Semaine")) {
                            return false;
                        } else if (scope.recurrence.recType === "Mois") {
                            return (angular.isNullOrUndefined(scope.recurrence.recJourDuMois));
                        } else if (scope.recurrence.recType === "Année") {
                            return (angular.isNullOrUndefined(scope.recurrence.recMois));
                        }
                    } else {
                        return true;
                    }
                };

                // Valider
                scope.valider = function () {
                    modalInstance.close(scope.recurrence);
                };

                // Annuler
                scope.fermer = function () {
                    modalInstance.close();
                };
            }
        ]
    );

