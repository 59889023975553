/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Brian Kjerulf, Operis
 */

'use strict';
angular.module('fr.operis.moteurV4.composants.OpChartLine', [])
    .directive('opChartLine', ['$compile', '$timeout', function ($compile, $timeout) {
            return {
                restrict: 'E',
                transclude: false,
                scope:{
                    opChartData: '=',
                    opChartOptions: '=',
                    opChartLegend: '=',
                    opTitre:'@' ,
                    opClick:'&'
                },
                template: ''+
                    '<div class="container-fluid" oc-lazy-load="[\'lib/Chart.min.js\', \'lib/tc-angular-chartjs.min.js\']">'+
                        '<div class="panel panel-default">'+
                            '<div class="panel-heading">'+
                                '<div class="row">'+
                                    '<strong style="padding-left: 10px">{{opTitre}}</strong>'+
                                '</div>'+
                            '</div>'+
                            '<div class="panel-body" style="overflow: hidden">'+
                                '<canvas tc-chartjs-line chart="chart" style="padding-right: 20px" chart-options="opChartOptions" chart-data="opChartData" chart-legend="opChartLegend"></canvas>'+
                                '<div tc-chartjs-legend chart-legend="opChartLegend"></div>'+
                            '</div>'+
                        '</div>'+
                    '</div>',
                    
                link: function postLink(scope, iElement, iAttrs, controller) {
                  var cElt = iElement.children(),
                    width = 0,
                    height = 0;

                    // TODO : verifier l'utilitée de cette fonction
                  function verifTailleLine() {
                    if(width !== cElt.prop('offsetWidth') || height !== cElt.prop('offsetHeight')) {
                        // On d�clenche un cycle de digest pour rentrer dans le watch
                        scope.$digest();
                    } else {
                        $timeout(verifTailleLine, 500, false);
                    }
                  }
                  $timeout(verifTailleLine, 500, false);

                  scope.$watch(function() {
                    return cElt.prop('offsetWidth') + ';' + cElt.prop('offsetHeight');
                  },
                  function(value) {
                    if(scope.chart && value !== '0;0' && isNaN(scope.chart.chart.aspectRatio)) {
                      scope.chart.chart.aspectRatio = 2;
                      scope.chart.resize(function () {
                        scope.chart.reflow();
                        scope.chart.update();
                        scope.chart.draw();
                      });
                    }
                  });
                }
            }
        }]
    );