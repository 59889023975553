/**
 * Created by Laurent Dorie le 27/07/16.
 */
"use strict";
angular.module('fr.operis.moteurV4.conteneurs.OpPopup', ['ui.bootstrap'])

    .controller('opPopupController', ['$scope', function($scope,$modalInstance) {

        if ( angular.isNullOrUndefined($scope.$success))
            $scope.$success = [];

        if ( angular.isNullOrUndefined($scope.$erreurs))
            $scope.$erreurs = [];

        if ( angular.isNullOrUndefined($scope.$info))
            $scope.$info = [];

        $scope.$isButtonFermerVisible = (angular.isNullOrUndefined($scope.$isButtonFermerVisible))? true:$scope.$isButtonFermerVisible;

        // Fonction de fermeture du Popup
        this.fermer = function () {
            if ( !angular.isNullOrUndefined($scope.$dismiss)){
                if ( !angular.isNullOrUndefined($scope.$selectionDonneesOrigine)
                    && !angular.isNullOrUndefined($scope.$selectionDonnees)
                    && $scope.$selectionDonneesOrigine.length != $scope.$selectionDonnees.length )
                    $scope.$close();
                else
                    $scope.$dismiss();
            }

            else if ( !angular.isNullOrUndefined($scope.$parent.$dismiss)){
                if ( !angular.isNullOrUndefined($scope.$parent.$selectionDonneesOrigine)
                    && !angular.isNullOrUndefined($scope.$parent.$selectionDonnees)
                    && $scope.$parent.$selectionDonneesOrigine.length != $scope.$parent.$selectionDonnees.length )
                    $scope.$parent.$close();
                else
                    $scope.$parent.$dismiss();
            }

        };
    }])

    // opPopup directive définie la structure modal d'une popup Operis
    // Title
    .directive('opPopup', ['$compile',function($compile) {
        return {
            controller: 'opPopupController',
            controllerAs: 'opPopupController',
            restrict: 'E',
            replace:true,
            transclude:true,
            template: function (element, attributs){
                var title = attributs.title;
                return '<div>' +
                            '<div class="modal-header">'+
                                '<h4 class="modal-title">'+
                                    ''+title+''+
                                '</h4>'+
                             '</div>'+
                            '<div ng-transclude>'+
                            '</div>'+
                        '</div>';
            },
            link: function (scope,element,attrs) {
                // Ecouteur sur header template
                scope.$watch('headertemplate', function(value) {
                    if (!angular.isNullOrUndefined(value)) {
                        var elem = angular.element(element[0].querySelector(".modal-title"));
                        elem.html('');

                        var header = '<h4 class="modal-title">' + attrs.title + value +'</h4>'
                        elem.append($compile(header)(scope));
                    }
                });
            }
        }
    }])
    // Directive du corps du modal de la popup Operis
    // $erreur pour les message d'erreurs
    // $success pour les message de sucess
    // $info pour les message d'informations
    .directive('opPopupBody', [function() {
        return {
            require: '^?opPopup',
            restrict: 'E',
            replace:true,
            transclude:true,
            template: '<div class="modal-body" >'+
                            '<!-- MESSAGE ALERTE D ERREUR ET DE FIN-->'+
                            '<alert type="danger" ng-repeat="erreur in $erreurs" ng-if="($erreurs.length > 0)" class="h3" close="$erreurs.splice($index, 1)">'+
                                '<i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;{{erreur}}'+
                            '</alert>'+
                            '<alert type="success" ng-repeat="succes in $success" ng-if="($success.length > 0)" class="h3" close="$success.splice($index, 1)">'+
                                '<i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;{{succes}}'+
                            '</alert>'+
                            '<alert type="info" ng-repeat="info in $info" ng-if="($info.length > 0)" class="h3" close="$info.splice($index, 1)">'+
                            '<i class="fa fa-exclamation-circle"></i>&nbsp;&nbsp;{{info}}'+
                            '</alert>'+
                            '<div ng-if="($success.length <= 0)" ng-transclude>' +
                            '</div>'+
                        '</div>'
        }
    }])
    // Directive du pied du modal de la popup Operis
    .directive('opPopupFooter', [function() {
        return {
            require: '^?opPopup',
            restrict: 'E',
            replace:true,
            transclude:true,
            template: '<div class="modal-footer">' +
                '<ng-transclude ng-if="($success.length <= 0)" <= 0 style="padding-right:5px;"></ng-transclude>'+
                '<button title="Fermer" ng-if="$isButtonFermerVisible" class="btn btn-warning" ng-click="fermer()">Fermer</button>'+
            '</div>',
            link:function(scope,element,attrs,opPopupController) {

                // Fonction de fermeture
                scope.fermer = function () {
                    opPopupController.fermer();
                };
            }
        }
    }]);


