/**
 * @version 2.0
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpMosaique', ['fr.operis.moteurV4.composants.OpModalImage'
    , 'fr.operis.moteurV4.utils.Representation'
    ,'fr.operis.moteurV4.communication.OperisWSServer'])




    .filter('filterMosaique',['fr.operis.moteurV4.utils.Conversion',function(conversion){
        return function(items,search,colFiltre){

            // Pas de données
            if (!items)
                return;

            // Pas de filtre
            if (!search){
                return items;
            }

            // 5420: Ne pas tenir compte de la casse ni des accents dans les recherches
            // Element de recherche
            var elementSearch = search.toLowerCase();
            elementSearch = elementSearch.replace(/[àáâäæ]/g, "a").replace(/[éèêë]/g, "e").replace(/[ç]/g, "c").replace(/[îï]/g, "i").replace(/[ôö]/g, "o").replace(/[ùû]/g, "u");

            // Filtre si un element est correct
            return items.filter(function(item){
                var colonneFiltre = colFiltre.split(',');
                var bResultat = false;

                if (!angular.isNullOrUndefined(colonneFiltre)){
                    for (var i=0;i<colonneFiltre.length;i++){
                       var type = item[colonneFiltre[i]].descriptions.type;

                        // Comparaison avec l'élément recherché
                        var valElement = conversion.versTexte(item[colonneFiltre[i]].valeur,type).toLowerCase();
                        valElement = valElement.replace(/[àáâäæ]/g, "a").replace(/[éèêë]/g, "e").replace(/[ç]/g, "c").replace(/[îï]/g, "i").replace(/[ôö]/g, "o").replace(/[ùû]/g, "u");
                        if (valElement.indexOf(elementSearch)!=-1)  //if (conversion.versTexte(item[colonneFiltre[i]].valeur,type).toLowerCase().indexOf(elementSearch)!=-1)
                            return true;
                   }
                }

                return bResultat;
            });
        }
    }])

    .directive('opMosaique',
    ['$ocLazyLoad', '$compile', '$q', '$modal', '$window', '$timeout', 'fr.operis.moteurV4.utils.Representation',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Config',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.Conversion.TYPE_DATE',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.RequeteFichier',
        'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE',
        'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1',
        function ($ocLazyLoad, $compile, $q, $modal, $window, $timeout, representationService,
                  serveur,
                  $config,
                  conversion,
                  TYPE_DATE,
                  moduleInfos,
                  requeteFichier,
                  SEPARATEUR_INTERNE,
                  SEPARATEUR_NIVEAU_1
                  ) {
            return {
                restrict: 'E',
                scope:{
                    opModule: '@',
                    opEntite: '@',
                    opTrigramme: '@',
                    opDescription: '=',
                    opDonnees: '=',
                    opFiltre:'=',
                    opComparaison:'=',
                    opSelection:'=',
                    opSelectionCle:'=?',
                    opDetail: '=',
                    opTaillePage: '@'
                },
                link:function(scope, element, attrs) {
                    $ocLazyLoad.load({
                        serie: true, files: [
                            'lib/intersection-observer.min.js'
                        ]
                    }).then(function () {
                        var colsTitre;
                        var colsCommentaire;
                        var valLien;
						var colsInfoBulle; // Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
						var colsDocAttache; // Mantis Operia 6150 : Signaler les documents attachés

						var colRepresentationStatut;
						var colRepresentationPriorite;
						var colFiltre;

						var templateTitre;
						var templateCommentaire;
						var templateCommentairePied;
						var templateLien;
						var templateInfoBulle; // Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
						var templateDocAttache; // Mantis Operia 6150 : Signaler les documents attachés

						var nombreColonnes;
						var representation;

						var colClePrimaire;

						var urlImageDefault = "";
						if ( !angular.isNullOrUndefined(attrs.opModule))
							urlImageDefault = './img/default/'+ attrs.opModule.toLowerCase() +'.jpg';

						/**
						 * Déterminer la représentation en fonction du statut
						 * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
						 * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
						 * @returns {String}
						 */
						scope.getRepresentationStatut = function(type, valeur) {
							var rep = {'icon': ''};

							if ((!angular.isNullOrUndefined(type)) && (!angular.isNullOrUndefined(valeur))) {
								rep = representationService.getRepresentation('mosaique', type, valeur);
							};

							// Retourner la représentation selon le statut
							return rep.icon;
						};

						/**
						 * Déterminer le style de représentation en fonction du statut
						 * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
						 * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
						 * @returns {Object}
						 */
						scope.getRepresentationStyle = function(type,valeur){
								return representationService.getRepresentationStyle( type, valeur);
						};

						/**
                         * Déterminer la valeur de l'élément suivant le type (date)
                         * @param {valeur} Valeur à convertir
                         * @param {typeValeur} Type de données de la colonne
                         * @returns {String}
                         */
                        scope.getValeur = function(valeur, typeValeur){
                            // Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
                            if (angular.isNullOrUndefined(typeValeur)  && (valeur instanceof Date)) {
                                return conversion.versTexte(valeur, TYPE_DATE);
                            } else if (!angular.isNullOrUndefined(typeValeur)) {
                                return conversion.versTexte(valeur, typeValeur);
                            } else {
                                return valeur;
                            }
                        };

						scope.getValeurTooltip = function(valeur, longueurMin){
							var v = "";
							if (valeur instanceof Date )
								v = conversion.versTexte(valeur,TYPE_DATE);
							else
								v = valeur;

							if (v.length > longueurMin)
								return v;
							else
								return "";
						};
						
						// Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
                        scope.infoBulleRenseigne = function (element) {
                            var estInfoBulle = false;
                            if (!angular.isNullOrUndefined(colsInfoBulle)){
                                for (var i = 0; i < colsInfoBulle.length; i++) {
                                    estInfoBulle = (!angular.isNullOrUndefined(element[colsInfoBulle[i]].valeur) && (element[colsInfoBulle[i]].valeur !== ""));
                                    if (estInfoBulle) {
                                        break;
                                    }
                                }
                            }

                            return estInfoBulle;
                        };
						
						// Mantis Operia 6150 : Signaler les documents attachés
                        scope.estDocAttache = function (element) {
                            var docAttache = false;
                            if (!angular.isNullOrUndefined(colsDocAttache)){
                                for (var i = 0; i < colsDocAttache.length; i++) {
                                    docAttache = (!angular.isNullOrUndefined(element[colsDocAttache[i]].valeur) && (element[colsDocAttache[i]].valeur !== ""));
                                    if (docAttache) {
                                        break;
                                    }
                                }
                            }

                            return docAttache;
                        };

						var getTemplate = function() {

							var filtre = angular.isNullOrUndefined(colFiltre)?'':colFiltre;

                            return '' +
                                '<div class="row">' +
                                '<ul class="list-group opmosaique">' +
                                '    <li class="list-group-item col-sm-' + nombreColonnes + '"' +
                                '        ng-class="{\'active\': opSelection.indexOf(element) != -1}"' +
                                '        ng-style="{\'background-color\': (opSelection.indexOf(element) != -1 ? \'' + moduleInfos.getInfo(scope.opModule, "MOD_COULEURSECONDAIRE") + '\' : \'rgba(255,255,255,.5)\'), \'border-color\': (opSelection.indexOf(element) != -1 ? \'' + moduleInfos.getInfo(scope.opModule, "MOD_COULEURSECONDAIRE") + '\' : \'rgba(255,255,255,.5)\')}"' +
                                (serveur.isMobile() ? templateLien : '        ng-click="basculerSelection(element)"') +
                                '        ng-repeat="element in opDonnees|filterMosaique:opFiltre:\''+filtre.toString()+'\':opComparaison">' +
                                '        <div class="entete">' +
                                '               <i class="fa fa-lock" ng-if="element.DROITACCES<=0"></i>'+
								'               <i class="fa fa-paperclip" ng-if="!serveur.isMobile() && estDocAttache(element)" ng-attr-title="' + templateDocAttache + '"></i>' + // Mantis Operia 6150 : Signaler les documents attachés
								'               <i class="fa fa-info-circle" style="color: forestgreen;" ng-if="!serveur.isMobile() && infoBulleRenseigne(element)" ng-attr-title="' + templateInfoBulle + '"></i>' + // Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
                                '               <a href ng-class="{\'linkDisabled\': element.DROITACCES<=0}" class="titre" ' + templateLien + ' ng-attr-title="' + templateTitre + '"><small>' + templateTitre + '</small></a>' +
                                '        </div>' +
                                '        <div class="contenu">' +
                                '            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 conteneur-vignette">' +
                                '               <img alt="image" class="img-rounded vignette" data-ng-src="{{element.vignette}}" >' +
                                '            </div>'+
                                '            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-9 commentaire">' + templateCommentaire + '</div>' +
                                '        </div>' +
                                '       <div class="pied">' +
                                '            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 commentaire">' + templateCommentairePied + '</div>' +
                                '        </div>' +
                                '    </li>' +
                                '</ul>' +
                                '</div>';
                        };

						function upDateTemplate() {
							// Titre Template
							if ( !angular.isNullOrUndefined(colsTitre)){
								for (var i = 0; i < colsTitre.length; i++) {
									if (i==0)
										templateTitre = '{{getValeur(element.' + colsTitre[i] + '.valeur)}}';
									else
										templateTitre += ' - {{getValeur(element.' + colsTitre[i] + '.valeur)}}';
								}
							}


							// Commentaire Template
							templateCommentaire = '';
							templateCommentairePied = '';
							if (!angular.isNullOrUndefined(colsCommentaire)) {
								for (var j = 0; j < colsCommentaire.length; j++) {
									if (j == 0) {
										templateCommentaire = '<p ng-attr-title="{{getValeurTooltip(element.' + colsCommentaire[j] + '.valeur, 0)}}"><small>{{getValeur(element.' + colsCommentaire[j] + '.valeur)}}</small></p>';
									} else if (j == 1) {
										templateCommentaire += '<p ng-attr-title="{{getValeurTooltip(element.' + colsCommentaire[j] + '.valeur, 0)}}"><b><small>{{getValeur(element.' + colsCommentaire[j] + '.valeur)}}</small></b></p>';
									} else if (j == 2) {
										templateCommentaire += '<p ng-attr-title="{{getValeurTooltip(element.' + colsCommentaire[j] + '.valeur, 0)}}"><small>{{getValeur(element.' + colsCommentaire[j] + '.valeur)}}</small></p>';
									} else {
										templateCommentairePied += '<p ng-attr-title="{{getValeurTooltip(element.' + colsCommentaire[j] + '.valeur, 0)}}"><small>{{getValeur(element.' + colsCommentaire[j] + '.valeur)}}</small><p>';
									}
								}
							}
							
							// Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
                            // Infobulle Template
                            templateInfoBulle = '';
                            if (!angular.isNullOrUndefined(colsInfoBulle)){
                                for (var k = 0; k < colsInfoBulle.length; k++) {
                                    if (k === 0) {
                                        templateInfoBulle = '{{element.' + colsInfoBulle[k] + '.descriptions.libelle.trim()}} : {{getValeur(element.' + colsInfoBulle[k] + '.valeur, element.' + colsInfoBulle[k] + '.descriptions.type)}}';
                                    } else {
                                        templateInfoBulle += String.fromCharCode(13) + '{{element.' + colsInfoBulle[k] + '.descriptions.libelle.trim()}} : {{getValeur(element.' + colsInfoBulle[k] + '.valeur, element.' + colsInfoBulle[k] + '.descriptions.type)}}';
                                    }
                                }
                            }
							
							// Mantis Operia 6150 : Signaler les documents attachés
                            // Documents Attachés Template
                            templateDocAttache = '';
                            if (!angular.isNullOrUndefined(colsDocAttache)){
                                for (var n = 0; n < colsDocAttache.length; n++) {
                                    if (n === 0) {
                                        templateDocAttache = '{{getValeur(element.' + colsDocAttache[n] + '.valeur, element.' + colsDocAttache[n] + '.descriptions.type)}}';
                                    } else {
                                        templateDocAttache += String.fromCharCode(13) + '{{getValeur(element.' + colsDocAttache[n] + '.valeur, element.' + colsDocAttache[n] + '.descriptions.type)}}';
                                    }
                                }
                            }

							// Lien Template
							if (valLien) {
								var split = valLien.split("|");
								var nomModule = split[0];
								var nomEcran = split[1];
								var nomsIdentifiants = split[2];
								//templateLien = 'ng-click="$event.stopPropagation();opSelection.splice(0);basculerSelection(element);opDetail(\'' + nomModule + '\', \'' + nomEcran + '\', \'' + nomsIdentifiants + '\', element)"';
								templateLien = 'ng-click="$event.stopPropagation();opSelection.splice(0);basculerSelection(element);opDetail(element)"';
							}else{
								var nomModule = scope.opModule;
								var nomEcran = scope.opTrigramme +'_FICHE';
								var nomsIdentifiants = scope.opEntite;
								//templateLien = 'ng-click="$event.stopPropagation();opSelection.splice(0);basculerSelection(element);opDetail(\'' + nomModule + '\', \'' + nomEcran + '\', \'' + nomsIdentifiants + '\', element)"';
								templateLien = 'ng-click="$event.stopPropagation();opSelection.splice(0);basculerSelection(element);opDetail(element)"';
							}

							// Nombre de colonnes occupée sur les 12 Bootstrap
							if (watchedWidth > 1199) {
								nombreColonnes = 3;
							} else if (watchedWidth > 599) {
								nombreColonnes = 6;
							} else {
								nombreColonnes = 12;
							}

							element.html(getTemplate());
							$compile(element.contents())(scope);
						}

						var watchedWidth = element.parent()[0].offsetWidth;
						scope.$watch(function () {
							return element.parent()[0].offsetWidth;
						}, function (newVal, oldVal) {
							if(newVal != oldVal && newVal > 0) {
								watchedWidth = element.parent()[0].offsetWidth;
								upDateTemplate(); // Pas de digest car nous sommes déjà dans un cycle
							}
						});

						// Ecouteur sur la description des données
						scope.$watch('opDescription', function(value) {
							if (value && value.length > 0) {
								// Récupération des descriptions
								colsTitre = [];
								colsCommentaire = [];
								colsInfoBulle = [];
								colsDocAttache = [];
								colClePrimaire = [];
								for (var i = 0; i < value.length; i++) {
									if (value[i]['estChampPrincipal'] && value[i]['visible'].indexOf('M') != -1)
										colsTitre.push(value[i]['nom']);
									else if ((value[i]['visible'].indexOf('M') != -1) && (value[i]['action'].indexOf('InfoBulle') != -1)) // Mantis Operia 6412 : Ajouter Date de création de la demande liée et son origine
                                        colsInfoBulle.push(value[i]['nom']);
									else if ((value[i]['visible'].indexOf('M') != -1) && (value[i]['action'].indexOf('DocAttache') != -1)) // Mantis Operia 6150 : Signaler les documents attachés
                                        colsDocAttache.push(value[i]['nom']);
									else if ((value[i]['visible'].indexOf('M') != -1) && value[i]['representation']=='STATUT')
										colRepresentationStatut = value[i]['nom'];
									else if ((value[i]['visible'].indexOf('M') != -1) && value[i]['representation']=='PRIORITE')
										colRepresentationPriorite = value[i]['nom'];
									else if ((value[i]['visible'].indexOf('M') != -1) && value[i]['lien'])
										valLien = value[i]['lien'];
									else if (value[i]['visible'].indexOf('M') != -1)
										colsCommentaire.push(value[i]['nom']);

									// Colonnes de la clé primaire
									if (value[i]['estClePrimaire'])
										colClePrimaire.unshift(value[i]['nom']);
								}

								colsInfoBulle.sort();
								colsDocAttache.sort();
								colsCommentaire.sort();
								colFiltre = colsTitre.concat(colsCommentaire);

								// Mise à jour du template
								upDateTemplate();
							}
						});

						// Clé primaire d'un élément sous forme d'une chaîne
						function getCleElement (colCle, element) {
							var clefPrimaire = "";

							// Clé primaire de l'élément
							for (var i = 0; i < colCle.length; i++) {
								if ((clefPrimaire != "") && (element[colCle[i]].valeur.toString() != "")) {
									clefPrimaire += SEPARATEUR_INTERNE;
								}

								clefPrimaire += colCle[i] + SEPARATEUR_NIVEAU_1 + element[colCle[i]].valeur.toString();
							}

							return clefPrimaire;
						}

						// Modification de la sélection en cours
						scope.basculerSelection = function(element) {
							if ( element.DROITACCES <= 0 )
								return;

							var index = scope.opSelection.indexOf(element);
							var gererSelectionCle = ('opSelectionCle' in attrs);

							if (gererSelectionCle) {
								var clefPrimaire = getCleElement(colClePrimaire, element);
								var indexCle = scope.opSelectionCle.indexOf(clefPrimaire);
							}

							if (index != -1) {
								scope.opSelection.splice(index, 1);

								if ((gererSelectionCle) && (indexCle != -1)) {
									scope.opSelectionCle.splice(index, 1);
								}
							} else {
								scope.opSelection.push(element);

								if ((gererSelectionCle) && (clefPrimaire != "")) {
									scope.opSelectionCle.push(clefPrimaire);
								}
							}
						};

						scope.afficherStreetView = function(element) {
							// Recherche de la coordonnées GPS
							var elementGPS;
							for ( var name in element){
								var item = element[name];
								if ( item.descriptions.typeComplexe === "COORDONNEES" ){
								   elementGPS = item;
								   break;
							   }
							}
							if (!angular.isNullOrUndefined(elementGPS))
								$window.open($config.$config.urlStreetView + '?' + 'size=600x300&heading=151.78&pitch=-0.76&key=AIzaSyDW2Bk9ZK_GJ9oJvIv50yD_1eweD1vt-3c&location='+elementGPS.valeur.split(';').slice(0,2).reverse());
						};

                        /**
                         * Chargement de la vignette pour un élément donné
                         * @param item Elément cible
                         */
                        scope.chargerVignette = function (item) {
                            var deferred = $q.defer();
							
                            var entite = {};
                            entite[moduleInfos.getInfo(scope.opModule, "MOD_ENTITE")]=item[moduleInfos.getInfo(scope.opModule, "MOD_ENTITE")].valeur ;
                            requeteFichier.chargementVignettePrincipale(scope.opModule, scope, entite).then(function (vignette) {
                                // Affichage des vignettes de type image si présente
                                // Sinon image par defaut du module
                                // Ou du type d'application si la vignette n'est pas une image mais une application
                                if ( !angular.isNullOrUndefined( vignette.valeur )){
                                    if ( vignette.type.toUpperCase().indexOf("IMAGE")!=-1 )
                                        item["vignette"] = "data:image/png;base64," + vignette.valeur;
                                    else{
                                        var pos = vignette.nom.lastIndexOf(".");
                                        var fileExtention = vignette.nom.slice(pos + 1);
                                        item["vignette"] = './img/applicationtype/'+ fileExtention +'.svg';
                                    }
                                }
                                else
                                    item["vignette"] = urlImageDefault;

                                deferred.resolve(vignette);
                            }, function (erreur) {
                                    deferred.reject(erreur);
							});
							
                            return deferred.promise;
                        };

                        /**
                         * Chargement des vignettes de la page courante
                         */
                        scope.chargementVignettes = function () {
                            var deferred = $q.defer();

                            var promises = [];
                            var tp = Number(scope.opTaillePage);
                            if (!isNaN(tp) && (tp > 0)) {
                                for (var i = 0; i < tp; i++) {
                                    if (!angular.isNullOrUndefined(scope.opDonnees[i])) {
                                        promises.push(scope.chargerVignette(scope.opDonnees[i]));
                                    }
                                }

                                $q.all(promises).then(function (reponses) {
                                    deferred.resolve();
                                }, function (erreurs) {
                                    deferred.reject(erreurs);
                                });
                            } else {
                                deferred.reject('Taille page mosaïque non définie.');
                            }

                            return deferred.promise;
                        };

                        // Chargement des vignettes
                        scope.$watch('opDonnees', function(value) {
                            if (value && value.length > 0) {
                                scope.opDonnees.map(function (item) {
                                    if (!angular.isNullOrUndefined(item["VALEUR_VIGNETTE"])) {
                                        if (!angular.isNullOrUndefined(item["VALEUR_VIGNETTE"].valeur) &&
                                            (item["VALEUR_VIGNETTE"].valeur !== "")) {
                                            item["vignette"] = "data:image/png;base64," + item["VALEUR_VIGNETTE"].valeur;
                                        } else {
                                            item["vignette"] = urlImageDefault; // Vignette par défaut
                                        }
                                    } else {
                                        item["vignette"] = urlImageDefault; // Vignette par défaut
                                    }
                                });

                                /*scope.opDonnees.map(function (item) {									
                                    item["vignette"] = urlImageDefault; // Init avec vignette par défaut
                                });

                                scope.chargementVignettes();*/
                            }
                        });
                    });
                }
            };
        }
    ]);

