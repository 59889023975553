/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.GestionGrilleElementPrincipal', [])

    .service('fr.operis.moteurV4.utils.GestionGrilleElementPrincipal',
        [
        function () {

            /**
             * Recupération du nom de l'élément principal
             * @param {Object} aScope Scope
             * @param {String} nomTable Nom de la table
             * @returns {String} identite de l'élément principal
             */
            this.getNomElementPrincipal = function(aScope,nomTable){
                var identiteEstPrincipal, descriptions = aScope.grilles[nomTable].descriptions;
                if ( !angular.isNullOrUndefined(descriptions)){
                    for (var i=0;i<descriptions.length;i++){
                        if (descriptions[i].nom.indexOf("ESTPRINCIPAL") != -1){
                            identiteEstPrincipal = aScope.descriptions[nomTable][i].nom;
                        }
                    }
                }

                return identiteEstPrincipal;
            };

            /**
             * Gestion de l'élément principal
             * @param {Object} aScope Scope
             * @param {String} nomTable Nom de la table
             * @param {Integer} index Indice de l'élément à gérer
             */
            this.gestionGrilleElementPrincipal = function (aScope, nomTable, index){
                if ( !angular.isNullOrUndefined( aScope.grilles[nomTable])
                    && !angular.isNullOrUndefined( aScope.grilles[nomTable][index]) ){

                    // Récupération de l'identité du principal
                    var nomPrincipal = this.getNomElementPrincipal(aScope,nomTable);

                    // Si un seul élément, on le coche par défaut
                    if ( aScope.grilles[nomTable].length === 1 ){
                        aScope.grilles[nomTable][index][nomPrincipal].valeur = 1;
                        return;
                    }

                    // Décoche les autres si la valeur est 1
                    if ( !angular.isNullOrUndefined( nomPrincipal )
                        && !angular.isNullOrUndefined( aScope.grilles[nomTable][index][nomPrincipal] ) ){
                        if ( aScope.grilles[nomTable][index][nomPrincipal].valeur == true ){
                            for ( var i=0; i<aScope.grilles[nomTable].length; i++){
                                if ( i != index){
                                    if ( aScope.grilles[nomTable][i][nomPrincipal].valeur == true){
                                        aScope.grilles[nomTable][i][nomPrincipal] = 0;
                                        aScope.grilles[nomTable][index]["vignette"] = 1;
                                    }
                                }
                            }
                        }
                    }

                }
            };

            /**
             * Recupération de l'élément principal
             * @param {Object} aScope Scope
             * @param {String} nomTable Nom de la table
             * @returns {Object} l'élément principal
             */
            this.getElementPrincipal = function (aScope,nomTable){
                if ( !angular.isNullOrUndefined( aScope.grilles[nomTable]) ){

                    // Récupération de l'identité du principal
                    var nomPrincipal = this.getNomElementPrincipal(aScope,nomTable);

                    if ( !angular.isNullOrUndefined( nomPrincipal ) ){
                        for (var i=0;i<aScope.grilles[nomTable].length;i++){
                            if ( aScope.grilles[nomTable][i][nomPrincipal].valeur == true ){
                                return aScope.grilles[nomTable][i];
                            }
                        }
                    }
                }
            };
        }
    ]
);