/**
 * @name OpPopupAjoutOccupation
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupAjoutOccupation', [])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupAjoutOccupationControleur',
        ['$scope', '$modalInstance', 'fr.operis.moteurV4.communication.OperisWSServer', 'titre', 'vueFormulaire',
            function (scope, modalInstance, serveur, titre, vueFormulaire) {
                scope.titre = titre;
                scope.vueFormulaire = vueFormulaire;
                scope.libValider = (scope.vueFormulaire.estAjout) ? "Ajouter" : "Valider";

                // Init objet évènement
                scope.occupation = {};

                // Saisie complète ?
                scope.saisieNOK = function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION)) {
                        return ((angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_OBJET.valeur) || (scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_OBJET.valeur.trim() === "")) ||
                        angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEDEBUT.valeur) ||
                        angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.ID_CONTACT.valeur));
                    } else {
                        return true;
                    }
                };

                // Ajouter l'ocupation
                scope.valider = function () {
                    scope.occupation.idEntete = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.ID_CONTACT.valeur;
                    scope.occupation.refEntete = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_REFINTERNENOM.valeur;
                    scope.occupation.typeRessource = "Intervenant";
                    scope.occupation.idRessource = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.ID_CONTACT.valeur;
                    scope.occupation.libRessource = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.NOM_PRENOM.valeur;
                    scope.occupation.qteRessource = 1;

                    var refObjet = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_OBJET.valeur;
                    scope.occupation.libLigne = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_OBJET.valeur;
                    scope.occupation.descLigne = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DESCRIPTION.valeur;

                    for (var i = 0; i < scope.vueFormulaire.listes.VL_OCC_OBJET.length; i++) {
                        if (scope.vueFormulaire.listes.VL_OCC_OBJET[i].COT_OCC_LIB.valeur === refObjet) {
                            scope.occupation.refLigne = scope.vueFormulaire.listes.VL_OCC_OBJET[i].COT_OCC_CODE.valeur;
                            scope.occupation.libLigne = scope.vueFormulaire.listes.VL_OCC_OBJET[i].COT_OCC_LIB.valeur;
                            break;
                        }
                    }

                    scope.occupation.debut = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEDEBUT.valeur;
                    scope.occupation.fin = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEFIN.valeur;
                    scope.occupation.debutReel = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEDEBUT.valeur;
                    scope.occupation.finReelle = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEFIN.valeur;

                    //scope.occupation.heureDebut = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_HEUREDEBUT.valeur;
                    //scope.occupation.heureFin = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_HEUREFIN.valeur;
                    scope.occupation.dureeJours = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DUREEJOUR.valeur;
                    scope.occupation.dureeHeures = scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DUREEHEURE.valeur;

                    modalInstance.close(scope.occupation);
                };

                // Annuler
                scope.fermer = function () {
                    modalInstance.close();
                };

                // Init données de l'écran
                scope.$watch(function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION)) {
                        return scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.NOM_PRENOM.valeur;
                    } else {
                        return null;
                    }
                }, function (newValue, oldValue) {
                    if (!angular.isNullOrUndefined(newValue) && (newValue !== oldValue)) {
                        if (scope.vueFormulaire.estAjout || angular.isNullOrUndefined(scope.vueFormulaire.occupation)) {
                            // Formulaire vide si ajout
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_OBJET.valeur = null;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DESCRIPTION.valeur = null;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEDEBUT.valeur = null;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEFIN.valeur = null;
                            //scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_HEUREDEBUT.valeur = null;
                            //scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_HEUREFIN.valeur = null;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DUREEJOUR.valeur = null;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DUREEHEURE.valeur = null;
                        } else {
                            // Init formulaire si modification
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_OBJET.valeur = scope.vueFormulaire.occupation.data.element.libLigne;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DESCRIPTION.valeur = scope.vueFormulaire.occupation.data.element.descLigne;

                            if (!angular.isNullOrUndefined(scope.vueFormulaire.occupation.data.element.debut)) {
                                scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEDEBUT.valeur = moment(scope.vueFormulaire.occupation.data.element.debut).toDate();
                            } else {
                                scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEDEBUT.valeur = null;
                            }

                            if (!angular.isNullOrUndefined(scope.vueFormulaire.occupation.data.element.fin)) {
                                scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEFIN.valeur = moment(scope.vueFormulaire.occupation.data.element.fin).toDate();
                            } else {
                                scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DATEFIN.valeur = null;
                            }

                            //scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_HEUREDEBUT.valeur = scope.vueFormulaire.occupation.data.element.heureDebut;
                            //scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_HEUREFIN.valeur = scope.vueFormulaire.occupation.data.element.heureFin;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DUREEJOUR.valeur = scope.vueFormulaire.occupation.data.element.dureeJours;
                            scope.vueFormulaire.champs.VL_OCC_CREEROCCUPATION.COT_OCC_DUREEHEURE.valeur = scope.vueFormulaire.occupation.data.element.dureeHeures;
                        }
                    }
                });
            }
        ]
    );

