/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequetePreChargementFiche', [])

    .service('fr.operis.moteurV4.utils.RequetePreChargementFiche', function () {
            var _configuration={};

            /**
             * Fonction de configuration du service
             * @param {Object} aConfiguration
             * @returns
             */
            this.configuration= function( aConfiguration ){
                _configuration = aConfiguration;
            };


            /**
             * Fonction qui réalise des traitements suite au chargement d'une fiche
             * @param {Object} aScopeFormulaire Scope du formulaire chargé
             * @param {Int} aIdCategorieModule Identifiant de catégorie de module
             * @param {String} aNomModule Nom du module
             * @param {String} aIdAction Action
             * @returns
             */
            this.execution = function (aScopeFormulaire, aNomModule, aIdCategorieModule, aIdAction) {
                if ( !angular.isNullOrUndefined(_configuration[aNomModule])){
                    _configuration[aNomModule](aScopeFormulaire, aIdAction);
                }

                if (  !angular.isNullOrUndefined(_configuration[aIdCategorieModule])){
                    _configuration[aIdCategorieModule](aScopeFormulaire, aIdAction);
                }
            }


        }
);