/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Matthieu Louchet, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteModuleComplement', ['fr.operis.moteurV4.communication.OperisWSServer'])

    .service('fr.operis.moteurV4.utils.RequeteModuleComplement',
        ['fr.operis.moteurV4.communication.OperisWSServer',
            function (serveur) {
                this.getModuleComplement = function (aScope, aNomModule) {
                    return serveur.chargement("Operia", aScope, "OPE_LectureModuleComplement", {IDENT_MODULE: serveur.getIdModule(aNomModule)});
                }
            }
        ]
    );