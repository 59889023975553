/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteInterrogation', ['fr.operis.moteurV4.communication.OperisWSServer'])

    .service('fr.operis.moteurV4.utils.RequeteInterrogation',
        ['fr.operis.moteurV4.communication.OperisWSServer','$q',
        function (serveur,$q) {

            // Association type d'action+identifantModele + valeur avec un ecran BD
            var correspondanceActionEcran = {
                'RECHERCHE_NUMERO_DEMANDE_DEM_NUMERO': 'RechercheDemandeNumeroAnnuel',
                'RECHERCHE_NUMERO_PATRIMOINE_PAT_NUMERO': 'OperiaPatrimoine_Numero',
                'RECHERCHE_NUMERO_CONTRAT_CONT_NUMERO': 'OperiaContrat_Numero'
            };


            /**
             * Fonction de récupération de la valeur
             * @param {Object} aScope Scope
             * @param {String} aIdentifiant Identifiant du modèle
             * @return {String}
             */
            this.getValeur = function (aScope,aIdModele){
                var identifiant = aIdModele.split('.');
                var nomTable = identifiant[0];
                var nomColonne= identifiant[1];

                if ( nomColonne.indexOf("[") == -1 )
                    return aScope.champs[nomTable][nomColonne].valeur;
                else
                    return aScope.grille[nomTable][nomColonne].valeur;
            };

            /**
             * Fonction de récupération du modele
             * @param {Object} aScope Scope
             * @param {String} aIdentifiant Identifiant du modèle
             * @return {Object}
             */
            this.getModele = function (aScope,aIdModele){
                var identifiant = aIdModele.split('.');
                var nomTable = identifiant[0];
                var nomColonne= identifiant[1];

                if ( nomColonne.indexOf("[") == -1 )
                    return aScope.champs[nomTable][nomColonne];
                else
                    return aScope.grille[nomTable][nomColonne];
            };

            /**
             * Execution d'une action d'interrogation
             * @param {Objet} aScope Scope
             * @param {String} ecranNom Nom d'écran à interroger
             */
            this.executer = function(aScope,ecranNom){
                var deferred = $q.defer();

                aScope.recherche[ecranNom](100).then(function(retourInterrogation){
                    deferred.resolve(retourInterrogation);
                });

                return deferred.promise;
            };

            this.setParametreInterrogation = function(aScope, critereChamps, critereValeur){
                var valeur = this.getValeur(aScope,critereValeur);
                var critere = this.getModele(aScope,critereChamps);

                // Parametre pour le calcul d'une année
                if ((critereValeur.indexOf("getFullYear") !== -1) && !angular.isNullOrUndefined(valeur) ){
                        valeur = valeur.getFullYear().toString();
                }
                critere.valeur = valeur;
            };

            /**
             * Fonction de gestion des parametres d'interrogation
             * @param {Object} aScope Scope
             * @param {Object} critereChamps Critère
             * @param {Object} critereValeur Valeurs
             * @return {String}
             */
            this.setParametresInterrogation = function(aScope,aIdEntite,ecranNom, critereChamps, critereValeur){
                for ( var i=0;i<critereChamps.length;i++){
                    this.setParametreInterrogation(aScope,critereChamps[i],critereValeur[i]);

                    // Creation d'un ecouteur de changement de parametres d'interrogations
                    //this.onChangeParemetreInterrogation(aScope, aIdEntite, ecranNom,critereChamps[i], critereValeur[i]);
                }
            };

            /**
             * Fonction d'écoute de changement de parametres d'interrogation
             * @param {Object} aScope Scope
             * @param {Object} critere Critère
             * @param {Object} valeur Valeurs
             * @return {String}
             */
            this.onChangeParemetreInterrogation = function(aScope, aIdEntite, ecranNom, critereChamps, critereValeur){
                var that = this;
                var critere = critereValeur.split('.');
                aScope.$watch("champs."+critere[0] +"." +critere[1]+".valeur",function(newValue, oldValue) {
                    if ( !angular.isNullOrUndefined(newValue) ){
                        if ( angular.isNullOrUndefined(oldValue) || newValue.valueOf() !== oldValue.valueOf())
                        that.setParametreInterrogation(aScope,critereChamps,critereValeur);
                        aIdEntite.rechercheNumero[ecranNom]();
                    }
                });
            };

            /**
             * Gere une action d'interrogation
             * @param {Object} aScope Scope
             * @param {String} aNomModule Nom du module
             * @param {Object} aIdEntite Objet contenant le résultat de la requête d'interrogation
             * @param {String} ecranNom Nom de l'écran à exécuter
             * @param {String} champsResultat Nom du champ de la requête d'interrogation qui contienne le résultat
             * @param {Array} critereChamps Noms des champs critères pour la requête d'interrogation
             * @param {Array} critereValeur Valeurs des champs critères pour la requête d'interrogation
             */
            this.requeteInterrogation = function (aScope, aNomModule, aIdEntite, ecranNom, champsResultat, critereChamps, critereValeur){
                var deferred = $q.defer();

                // Récupération des critères
                if ( angular.isNullOrUndefined(critereChamps) || angular.isNullOrUndefined(critereValeur) )
                    return;

                // Affectation des critères et des valeurs
                if (critereChamps.length != critereValeur.length)
                    return;

                var idEntite = this.getModele(aScope,aIdEntite);

                var that = this;
                serveur.chargement(aNomModule, aScope, ecranNom,null).then(function (retourChargement) {
                    that.setParametresInterrogation(aScope,idEntite,ecranNom,critereChamps, critereValeur);
                    idEntite.rechercheNumero = idEntite.rechercheNumero || {};
                    idEntite.rechercheNumero[ecranNom] = function () {
                        return that.executer(aScope, ecranNom).then(function(retourRequeteInterrogation){
                            if (aScope.grilles[retourRequeteInterrogation[0][0]].length == 0 ){
                                idEntite.valeur = 1;
                            }
                            else if ( aScope.grilles[retourRequeteInterrogation[0][0]].length == 1 ){
                                idEntite.valeur = aScope.grilles[retourRequeteInterrogation[0][0]][0][champsResultat].valeur;
                            }

                            deferred.resolve(retourRequeteInterrogation);
                        });
                    };

                    idEntite.rechercheNumero[ecranNom]();
                });

                return deferred.promise;
            };
        }
    ]
);