/**
 * Created by Laurent Dorie on 12/01/2017
 */
'use strict';

angular.module('fr.operis.moteurV4.utils.Statut', [])
    /**
     * Nom de l'écran des statuts
     * @type {String}
     * @value _STATUT
     */
    .constant('fr.operis.moteurV4.utils.Statut.ECRANSTATUT', '_STATUT')

    .service('fr.operis.moteurV4.utils.Statut',['$q',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.Conversion',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.Statut.ECRANSTATUT',
    function($q,
            serveur,
             conversion,
             moduleInfos,
             ECRANSTATUT
             ){
        /**
         * Fonction qui met à jour le statut d'une entite
         * @param {String} aNomModule Le nom du module
         * @param {Object} aScope Scope dans lequel les données sont à charger.
         * @param {Object} aIdentifiant Identifiant
         * @param {String} aNomStatut Nom
         * @param {Date}   aDateStatut Date de statut
         * @param {String} aCommentaireStatut Commentaire
         * @returns {ng.IPromise<T>}
         */
        this.sauvegardeStatut = function(aNomModule, aScope, aIdentifiant, aNomStatut, aDateStatut, aCommentaireStatut, aEcranStatut){
            var deferred = $q.defer();

            var ecranStatut,bMobibilite = serveur.isMobile();
            var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");

            if ( bMobibilite )
                ecranStatut = trigramme + '_M' + ECRANSTATUT;
            else
                ecranStatut = trigramme + ECRANSTATUT;

            if ( !angular.isNullOrUndefined(aEcranStatut) )
                ecranStatut = aEcranStatut;

            // Chargement de l'écran des statuts
            serveur.chargement(aNomModule, aScope, ecranStatut, aIdentifiant)
                .then(function(retourChargement){

                    // Mise à jour des champs des statuts: nom, date et commentaire
                    var index = 0, tableStatut = aScope.champs[retourChargement[0]];
                    tableStatut[aScope.descriptions[retourChargement[0]][index].nom].valeur = conversion.versEntier(aIdentifiant[aScope.descriptions[retourChargement[0]][index].nom]);
                    tableStatut[aScope.descriptions[retourChargement[0]][++index].nom].valeur = aNomStatut;
                    tableStatut[aScope.descriptions[retourChargement[0]][++index].nom].valeur = aDateStatut;
                    tableStatut[aScope.descriptions[retourChargement[0]][++index].nom].valeur = aCommentaireStatut;

                    // Sauvegarde de l'écran des statuts
                    aScope.sauvegarde[ecranStatut]()
                        .then(function(retourSauvegarde){
                            deferred.resolve(retourSauvegarde)
                        })
                })
                .catch(function (erreur) {
                    deferred.reject(erreur);
                });

            return deferred.promise;
        }
    }
    ]);


