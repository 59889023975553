/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequetePostSauvegardeFiche', [])

    .service('fr.operis.moteurV4.utils.RequetePostSauvegardeFiche', [
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.ActionInfos',
        'fr.operis.moteurV4.utils.RequeteNotificationInterne',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.Habilitation',
        function (serveur, actionmetier, requeteNotificationInterne, moduleInfos, habilitation) {
            this._configuration={};

            // Configuration des requetes post traitement
            this.configuration= function( aConfiguration ){
                this._configuration = aConfiguration;
            };


            // Execution des requetes post traitement
            this.execution = function(aScope, nomModule, aIdCategorieModule, aIdentifiant, aIdAction){
                if ( angular.isNullOrUndefined(nomModule) )
                    return;

                // Vérification paramétrage de notification automatique
                var codeAction = actionmetier.getInfoAction(serveur.getIdModule(nomModule),aIdAction);
                if ( !angular.isNullOrUndefined(codeAction) ){
                    var paramNotif = serveur.lireParametre(nomModule,'NOTIFICATION', codeAction.ACTIONCODE.valeur);
                    if ( !angular.isNullOrUndefined(paramNotif) && paramNotif.indexOf("INT") !== -1 ){
                        //requeteNotificationInterne.creerNotification(aScope, nomModule, aIdentifiant, null, null, true, aIdAction );
                    }
                }

                // Affectation du service par defaut
                if ( aIdAction == 1 ){
                    // Récupération de l'écran des services
                    var trigramme = moduleInfos.getInfo(nomModule, "MOD_TRIGRAMME");
                    var mobilite = serveur.isMobile()?'_M':'';
                    var ecranServiceGestionnaire = trigramme + mobilite + '_SAUVEGARDE_SERVICEGESTIONNAIRE';
                    var that = this;
                    serveur.chargement(nomModule, aScope, ecranServiceGestionnaire, aIdentifiant)
                        .then(function(retourChargement){
                            var tableServiceGestionnaire = trigramme + '_SERVICEGESTIONNAIRE';
                            aScope.champs[tableServiceGestionnaire].ID_SERVICEGESTIONNAIRE.valeur = habilitation.getIdServiceParDefault();
                            aScope.champs[tableServiceGestionnaire][trigramme +"_SER_DATEAFFECTATION"].valeur = new Date();
                            aScope.sauvegarde[ecranServiceGestionnaire]()
                                .then(function(retourSauvegarde){
                                    if (!angular.isNullOrUndefined(that._configuration[nomModule.toUpperCase()])){
                                        return that._configuration[nomModule.toUpperCase()](aScope, aIdentifiant, aIdAction);
                                    }else if (!angular.isNullOrUndefined(that._configuration[aIdCategorieModule])){
                                        return that._configuration[aIdCategorieModule](nomModule, aScope, aIdentifiant, aIdAction);
                                    }
                                });
                        })
                }
                else{
                    if (!angular.isNullOrUndefined(this._configuration[nomModule.toUpperCase()])){
                        return this._configuration[nomModule.toUpperCase()](aScope, aIdentifiant, aIdAction);
                    }

                    if (!angular.isNullOrUndefined(this._configuration[aIdCategorieModule])){
                        return this._configuration[aIdCategorieModule](nomModule, aScope, aIdentifiant, aIdAction);
                    }
                }
            }
        }
    ]
);