'use strict';

angular.module('operiaChat.chat')


    .directive('operiaVideoChat', ["$http", function ($http, operiaConfig) {
        return {
            controller: ['$scope', 'operiaConfig', function ($scope, operiaConfig) {
                $scope.state = "Chargement....";
                $scope.isConnecting = true;
                $scope.isConnected = false;
                $scope.streams = {};

                //TOD externalize in provider
                var STUN = {
                    'url': operiaConfig.stunUrl,
                };

                var TURN = {
                    'url': operiaConfig.turnUrl,
                    'credential': operiaConfig.turnCredential,
                    'username': operiaConfig.turnUsername
                };

                $scope.iceServers =
                    {
                        iceServers: [STUN, TURN]
                    };
                $scope.get_user_media = navigator.getUserMedia.bind(navigator);

                // generic error handler
                $scope.log_error = function (error) {
                    alert("erreur" + error);
                }

                $scope.peer_connection = new RTCPeerConnection($scope.iceServers);

                //Handle ice candidate added to the connection
                $scope.peer_connection.onicecandidate = function (ice_event) {
                    if (ice_event.candidate) {
                        $scope.signaling_server.send(
                            JSON.stringify({
                                token: $scope.call_token,
                                action: "new_ice_candidate",
                                candidate: ice_event.candidate,
                            })
                        );
                    }
                };


                // display remote video streams when they arrive using remote_video <video> MediaElement
                $scope.peer_connection.onaddstream = function (event) {
                    $scope.streams.remote = URL.createObjectURL(event.stream);
                    $scope.isConnecting = false;
                    $scope.isConnected = true;
                    $scope.$apply();

                };

                // setup stream from the local camera 
                $scope.setup_video = function () {
                    $scope.get_user_media(
                        {
                            "audio": true, // request access to local microphone
                            "video": true  // request access to local camera
                        },
                        function (local_stream) { // success callback
                            // display preview from the local camera & microphone using local <video> MediaElement
                            $scope.streams.local = URL.createObjectURL(local_stream);
                            //$scope.connect_stream_to_src(local_stream, document.getElementById("local_video"));
                            // mute local video to prevent feedback
                            // document.getElementById("local_video").muted = true;
                            // add local camera stream to peer_connection ready to be sent to the remote peer
                            $scope.peer_connection.addStream(local_stream);
                            $scope.local_stream_added = true;
                            $scope.$apply();
                        },
                        $scope.log_error
                    );
                };



                $scope.run = function () {

                    // Connection to signaling server
                    $scope.signaling_server = new WebSocket(operiaConfig.urlWs + 'videochat');
                    // Retrieve the call id 
                    $scope.call_token = localStorage.getItem("callId");

                    if (localStorage.getItem("caller") == "true") {

                        // set location.hash to the unique token for this call
                        //document.location.hash = token;

                        $scope.signaling_server.onopen = function () {
                            // setup caller signal handler
                            $scope.signaling_server.onmessage = $scope.caller_signal_handler;

                            // tell the signaling server you have joined the call 
                            $scope.signaling_server.send(
                                JSON.stringify({
                                    token: $scope.call_token,
                                    to: localStorage.getItem("to"),
                                    action: "join",
                                })
                            );
                        };

                        // document.title = "You are the Caller";
                        //document.getElementById("loading_state").style.display = "block";
                        $scope.state = "En attente de votre correspondant...";
                    } else {
                        //Callee

                        $scope.signaling_server.onopen = function () {
                            // setup caller signal handler
                            $scope.signaling_server.onmessage = $scope.callee_signal_handler;

                            // tell the signaling server you have joined the call 
                            $scope.signaling_server.send(
                                JSON.stringify({
                                    token: $scope.call_token,
                                    action: "join",
                                })
                            );

                            // let the caller know you have arrived so they can start the call
                            $scope.signaling_server.send(
                                JSON.stringify({
                                    token: $scope.call_token,
                                    action: "callee_arrived",
                                })
                            );
                        };

                        // document.title = "You are the Callee";
                        // document.getElementById("loading_state").style.display = "block";
                        $scope.state = "Connexion en cours....";


                    }

                    $scope.setup_video();

                };



                // handler to process new descriptions
                $scope.new_description_created = function (description) {
                    $scope.peer_connection.setLocalDescription(
                        description,
                        function () {
                            $scope.signaling_server.send(
                                JSON.stringify({
                                    token: $scope.call_token,
                                    action: "new_description",
                                    sdp: description
                                })
                            );
                        },
                        $scope.log_error
                    );
                };



                // handle signals as a caller
                $scope.caller_signal_handler = function (event) {
                    var signal = JSON.parse(event.data);
                    if (signal.action === "callee_arrived") {
                        $scope.create_offer();
                    } else if (signal.action === "new_ice_candidate") {
                        $scope.peer_connection.addIceCandidate(
                            new RTCIceCandidate(signal.candidate)
                        );
                    } else if (signal.action === "new_description") {
                        $scope.peer_connection.setRemoteDescription(
                            new RTCSessionDescription(signal.sdp),
                            function () {
                                if ($scope.peer_connection.remoteDescription.action == "answer") {
                                    // extend with your own custom answer handling here
                                }
                            },
                            $scope.log_error
                        );
                    } else {
                    }
                };
                //Caller create an offer
                $scope.create_offer = function () {
                    if ($scope.local_stream_added) {
                        $scope.peer_connection.createOffer(
                            $scope.new_description_created,
                            $scope.log_error
                        );
                    } else {
                        setTimeout(function () {
                            $scope.create_offer();
                        }, 1000);
                    }
                };

                // handle signals as a callee
                $scope.callee_signal_handler = function (event) {
                    var signal = JSON.parse(event.data);
                    if (signal.action === "new_ice_candidate") {
                        $scope.peer_connection.addIceCandidate(
                            new RTCIceCandidate(signal.candidate)
                        );
                    } else if (signal.action === "new_description") {
                        $scope.peer_connection.setRemoteDescription(
                            new RTCSessionDescription(signal.sdp),
                            function () {
                                if ($scope.peer_connection.remoteDescription.type == "offer") {
                                    $scope.create_answer();
                                }
                            },
                            $scope.log_error
                        );
                    } else {
                    }
                };

                $scope.create_answer = function () {
                    if ($scope.local_stream_added) {
                        $scope.peer_connection.createAnswer($scope.new_description_created, $scope.log_error);
                    } else {
                        setTimeout(function () {
                            $scope.create_answer();
                        }, 1000);
                    }
                };

                $scope.toggleFullScreen = function ($event) {
                    if (
                        document.fullscreenElement ||
                        document.webkitFullscreenElement ||
                        document.mozFullScreenElement ||
                        document.msFullscreenElement
                    ) {
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) {
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) {
                            document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) {
                            document.msExitFullscreen();
                        }
                    } else {
                        var element = $event.currentTarget;
                        if (element.requestFullscreen) {
                            element.requestFullscreen();
                        } else if (element.mozRequestFullScreen) {
                            element.mozRequestFullScreen();
                        } else if (element.webkitRequestFullscreen) {
                            element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                        } else if (element.msRequestFullscreen) {
                            element.msRequestFullscreen();
                        }
                    }
                };

                $scope.run();

            }],
            // templateUrl: './components/operiaVideo/operiaVideo.html'
            templateUrl: 'moteur/composants/operiaVideo/operiaVideo.html'
        };
    }]);