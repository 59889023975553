/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpFormulaire', ['fr.operis.moteurV4.communication.OperisWSServer',
    'fr.operis.moteurV4.utils.GenerationFormulaire',
    'fr.operis.moteurV4.utils.GestionActionFormulaire',
    'fr.operis.moteurV4.utils.RequeteFichier',
    'fr.operis.moteurV4.modele.Modele',
    'fr.operis.moteurV4.utils.Conversion',
    'fr.operis.moteurV4.composants.OpPopupAjoutElement',
    'fr.operis.moteurV4.composants.OpModalImage'])

    .directive('opFormulaire',
        ['fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1',
            'fr.operis.moteurV4.utils.GenerationFormulaire',
            'fr.operis.moteurV4.utils.GestionActionFormulaire',
            'fr.operis.moteurV4.utils.GestionGrilleElementPrincipal',
            'fr.operis.moteurV4.utils.Conversion',
            'fr.operis.moteurV4.modele.Modele.SCOPE_NOM',
            'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS',
            'fr.operis.moteurV4.modele.Modele.SCOPE_CLE_PRIMAIRE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_CLE_ETRANGERE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_ACTION',
            'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS',
            'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES',
            'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_LONGUEUR_MAX',
            'fr.operis.moteurV4.modele.Modele.SCOPE_LIBELLE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_VISIBLE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_REPRESENTATION',
            'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_INTERACTION',
            'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_COMPLEXE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_REF_ETRANGERE',
            'fr.operis.moteurV4.modele.Modele.SCOPE_NOM_GROUPE',
            '$compile',
            '$http',
            '$q',
            '$modal',
            'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPAJOUT',
            'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPSUPPRESSION',
            'fr.operis.moteurV4.utils.RequeteFichier',
            'fr.operis.moteurV4.utils.RequeteReferentiel',
			'fr.operis.moteurV4.utils.Habilitation',
            'fr.operis.moteurV4.utils.ModuleInfos',
			'fr.operis.operia.utils.ongletsService',
            'fr.operis.moteurV4.utils.Config',
            '$window',
            '$timeout',
            'fr.operis.operia.services.modulesActifsService',
			'fr.operis.moteurV4.composants.OpPopupAlerte',
        function (serveur,
                  SEPARATEUR_NIVEAU_1,
                  generateurFormulaire,
                  gestionnaireActionFormulaire,
                  gestionGrilleElementPrincipal,
                  conversion,
                  SCOPE_NOM,
                  SCOPE_DESCRIPTIONS,
                  SCOPE_CLE_PRIMAIRE,
                  SCOPE_CLE_ETRANGERE,
                  SCOPE_ACTION,
                  SCOPE_CHAMPS,
                  SCOPE_GRILLES,
                  SCOPE_TYPE,
                  SCOPE_LONGUEUR_MAX,
                  SCOPE_LIBELLE,
                  SCOPE_VISIBLE,
                  SCOPE_REPRESENTATION,
                  SCOPE_TYPE_INTERACTION,
                  SCOPE_TYPE_COMPLEXE,
                  SCOPE_REF_ETRANGERE,
                  SCOPE_NOM_GROUPE,
                  $compile,
                  $http,
                  $q,
                  $modal,
                  TYPE_POPUPAJOUT,
                  TYPE_POPUPSUPPRESSION,
                  requetefichier,
                  requetereferentiel,
				  habilitation,
                  moduleInfos,
				  ongletsService,
                  $config,
                  $window,
                  $timeout,
                  modulesActifsService,
				  opPopupAlerte) {
            return {
                restrict: 'E',
                scope:{
                    opName:'@',
                    opVueFormulaire:'=',
                    opTable:'=',
                    opSauvegarde:'=',
                    opIsLecture:'=',
                    opGrillesFormulaire: '=',
                    opListesFormulaire: '=',
                    opIsOpen: '=',
                    isFormulaireRecherche: '=',
                    opOnglet:'=',
                    finchargement:'&',
                    opSectionDroiteVisible: '=?',
                    onDemandeActivite:'&'
                },
                transclude: false,
                replace: false,
                controller: ['$scope','$element','$attrs',
                    function($scope,$element,$attrs) {

                        // Liste des noms d'ecrans, des correspondance actions/ecrans et des vues formulaires
                        $scope.nomModule;
                        $scope.identifiant;
                        $scope.nomEcran = [];
                        $scope.listeEcran = [];
                        $scope.correspondanceActionEcran = {};
                        $scope.vueFormulaire = {};
                        $scope.cloneScope;
                        $scope.champs;
                        $scope.grilles;
                        $scope.opOnglet;
                        $scope.actionsPreSauvegarde = [];

                        $scope.getIdEntite = function(){
                            return $scope.vueFormulaire.identifiant;
                        };


                        /**
                         * Fonction d'ajout de vueFormulaire
                         * @param {string} template template de la vue,
                         * @param {String} nomEcran nom d'écran,
                         * */
                        $scope.ajoutVueFormulaire = function(template,nomEcran){
                            $scope.listeEcran.push(nomEcran);
                            $scope.form = {}
                            $scope.vueFormulaire[nomEcran] = $compile(template)($scope.cloneScope);
                            $element.append($scope.vueFormulaire[nomEcran]);

                            $timeout(function(){
                                if (!angular.isNullOrUndefined($scope.form["form_" + nomEcran]) &&
                                    !angular.isNullOrUndefined($scope.form["form_" + nomEcran].$setPristine)) {
                                    $scope.form["form_" + nomEcran].$setPristine();
                                    $scope.opVueFormulaire.vueModifie = $scope.form["form_" + nomEcran];
                                }
                            },0);
                        };

                        /**
                         * Fonction de suppression de vue formulaire
                         * @param {String} nomEcran nom d'écran
                         * */
                        $scope.suppressionVueFormulaire = function(nomEcran){
                            // Retirer l'écran du formulaire de la liste des noms d'écran
                            if ( $scope.listeEcran.indexOf(nomEcran) !== -1 ){
                                $scope.listeEcran.splice($scope.listeEcran.indexOf(nomEcran),1);
                            }

                            // Retirer l'élément de la DOM
                            var idFormulaire = $scope.vueFormulaire[nomEcran][0].id;
                            return angular.element(document.getElementById(idFormulaire)).remove();
                        };

                        /**
                         * Fonction d'initialisation des vues formulaires
                         * */
                        $scope.viderVueFormulaire = function(){
                            // Suppression du scope formulaire et de ses fils
                            if ( $scope.cloneScope )
                                $scope.cloneScope.$destroy();
                            $scope.cloneScope = $scope.$new();

                            $scope.listeEcran = [];
                            $scope.correspondanceActionEcran = {};
                            $scope.vueFormulaire={};

                            // Suppression des formulaires
                            $element.html('');
                            $compile($element.contents())($scope.cloneScope);
                        };

                        /**
                         * Fonction de chargement de formulaire
                         * @param {ecran} Ecran
                         * @param {identifiant} identifiant
                         */
                        $scope.chargementFormulaire = function(module,ecran,identifiant){
                            serveur.chargement(module,$scope,ecran,identifiant)
                                .then(function (retourChargement){
                                    var nomFormulaire = $scope.opName;
                                    if (angular.isNullOrUndefined(nomFormulaire)) {
                                        nomFormulaire = ecran;
                                    }

                                    // Si ecran de document chargement des vignettes
                                    var trigramme = moduleInfos.getInfo(module, "MOD_TRIGRAMME"), entite = {};

                                    // Mantis 6029 Vignettes non chargés dans Operia mobile
                                    var mobile = (serveur.isMobile()) ? "_M" : "";

                                    if ( ecran == (trigramme + mobile + "_DOCUMENTS" )){
                                        var nomTableDocument = trigramme + "_DOCUMENT" ;
                                        if (!angular.isNullOrUndefined($scope.grilles[nomTableDocument])
                                            && $scope.grilles[nomTableDocument].length > 0) {
                                            $scope.grilles[nomTableDocument].map(function (item) {
                                                requetefichier.chargementVignette( module, $scope, item);
                                            })
                                        }
                                    } else if (ecran.indexOf("DOCUMENTS_LIES") != -1) {
                                        // Mantis 5921 Gestion documents éléments liés
                                        var nomTableDocumentLie = "VL_" + trigramme + "_DOCUMENT_LIE" ;
                                        if (!angular.isNullOrUndefined($scope.grilles[nomTableDocumentLie])
                                            && $scope.grilles[nomTableDocumentLie].length > 0) {
                                            $scope.grilles[nomTableDocumentLie].map(function (item) {
                                                requetefichier.chargementVignette(module, $scope, item);
                                            })
                                        }
                                    }

                                    // Si ecran de fiche saisie ou synthese
                                    if ( ecran == (trigramme + mobile + "_FICHE") ){
                                        requetefichier.chargementVignettePrincipale( module, $scope, $scope.identifiant)
                                            .then(function(vignette){
                                                if ( angular.isNullOrUndefined(vignette.nom))
                                                    $scope["vignettePrincipale"] = './img/default/'+ module.toLowerCase() +'.jpg';
                                                else if ( vignette.type.toUpperCase().indexOf("IMAGE")!=-1 )
                                                    $scope.vignettePrincipale = "data:image/png;base64," + vignette.valeur;
                                                else {
                                                    var pos = vignette.nom.lastIndexOf(".");
                                                    var fileExtention = vignette.nom.slice(pos + 1);
                                                    $scope.vignettePrincipale = './img/applicationtype/'+ fileExtention +'.svg';
                                                }
                                            });
                                    }

                                    var isFormulaireRecherche = angular.isNullOrUndefined($scope.isFormulaireRecherche)?false:$scope.isFormulaireRecherche;

                                    var retourGenerateur = generateurFormulaire.creerTemplate($scope,
                                            $scope.opVueFormulaire.nomModule,
                                            retourChargement,
										    ecran,
                                            nomFormulaire, //$scope.opName,
                                            'gererActionFormulaire',
                                            $scope.opIsLecture,
                                            true,
                                            $scope.opIsOpen,
                                            isFormulaireRecherche
                                        );
                                    $scope.ajoutVueFormulaire(retourGenerateur.template,ecran);
                                    $scope.gererActionFormulaire(retourGenerateur.actions);

                                    // Attacher $scope.descriptions, $scope.champs et $scope.grilles
                                    if (angular.isNullOrUndefined($scope.opVueFormulaire.descriptions)) {
                                        $scope.opVueFormulaire.descriptions = $scope.descriptions;
                                    }

                                    $scope.opVueFormulaire.champs = $scope.champs;
                                    $scope.opVueFormulaire.grilles = $scope.grilles;
                                    $scope.opVueFormulaire.listes = $scope.listes;

                                    if ( !angular.isNullOrUndefined($scope.finchargement) )
                                        $scope.finchargement({scope:$scope});
                                });
                        };

                        $scope.recharger = function(){
                            $scope.viderVueFormulaire();
                            $scope.chargementFormulaire($scope.nomModule, $scope.nomEcran, $scope.identifiant);
                        };

                        $scope.editerCoordonnees = function(model) {
                            if ($window.matchMedia("(max-width: 767px)").matches){
                                $scope.opOnglet.masquerSectionGauche();
                                $scope.opOnglet.afficherSectionDroite();
                            } else {
                                $scope.opOnglet.afficherSectionDroite();
                            }

                            $scope.opOnglet.afficherOutils('cartographie');
                            $scope.opOnglet.modelCarto = model;
                            $scope.opOnglet.editionCarto = true;
                        };

                        /**
                         * Mantis 6194 Contrôle des habilitations pour les liens inter-modules
                         * @param {String} aNomModule
						 * @param {String} aMapEntite
                         * @param {String} aModeleNom
                         * @param {Numeric} aIndex
                         * @param {String} aType
						 * @returns {boolean}
                         */
                        $scope.habilitationLienModule = function(aNomModule, aMapEntite, aModeleNom, aIndex, aType) {
                            var module = modulesActifsService.getModule(aNomModule);
                            if (!angular.isNullOrUndefined(module)) {
                                var type, nomTable, modele, descriptionChamps;

                                if (aType === "CHAMPS") {
                                    descriptionChamps = aModeleNom.split('.');
                                    type = descriptionChamps[0];
                                    nomTable = descriptionChamps[1].replace('[$index]','');

                                    if (type === "grilles")
                                        modele = $scope[type][nomTable][aIndex];
                                    else
                                        modele = $scope[type][nomTable]
                                } else {
                                    modele = aModeleNom;
                                }

                                var nomColCle = module.MOD_ENTITE.valeur;
                                var nomCoCleValeur = (!angular.isNullOrUndefined(aMapEntite) && (aMapEntite !== "")) ? aMapEntite : nomColCle;

                                return !angular.isNullOrUndefined(modele[nomCoCleValeur].valeur);
                            } else {
                                return false;
                            }
                        };

                        /**
                         * Fonction d'ouverture d'entite dans un autre onglet
                         * @param {String} aNomModule
                         * @param {String} aMapEntite
                         * @param {String} aModeleNom
                         * @param {Numeric} aIndex
                         * @param {String} aType
                         */
                        $scope.linkButtonOuvrirActivite_click = function(aNomModule, aMapEntite, aModeleNom, aIndex, aType) {
                            var module = modulesActifsService.getModule(aNomModule);

                            if (!angular.isNullOrUndefined(module)) {
                                var type, nomTable, modele, descriptionChamps;
                                var entite = (aMapEntite !== "") ? module.MOD_ENTITE.valeur + '#' + aMapEntite : module.MOD_ENTITE.valeur;

                                if (aType === "CHAMPS") {
                                    descriptionChamps = aModeleNom.split('.');
                                    type = descriptionChamps[0];
                                    nomTable = descriptionChamps[1].replace('[$index]','');

                                    if (type === "grilles")
                                        modele = $scope[type][nomTable][aIndex];
                                    else
                                        modele = $scope[type][nomTable]
                                } else {
                                    modele = aModeleNom;
                                }

                                // Mantis 6194 Contrôler habilitations pour le module avant son ouverture
                                serveur.chargement("Operia", $scope, "OPE_RechercheUtilisateurServiceGest", null).then(function (retourChargement) {
                                    $scope.recherche["OPE_RechercheUtilisateurServiceGest"](1000).then(function (retourRecherche) {
                                        var servicegestionnaire = {};
                                        for (var indice = 0; indice < $scope.grilles.VL_HABIL_SERVICEGEST_DROIT.length; indice++){
                                            servicegestionnaire[$scope.grilles.VL_HABIL_SERVICEGEST_DROIT[indice].ID_SERVICEGESTIONNAIRE.valeur]
                                                = $scope.grilles.VL_HABIL_SERVICEGEST_DROIT[indice].DROITACCES.valeur;
                                        }

                                        // Initialisation service gestionnaire par défaut
                                        var idServiceParDefaut;
                                        if (!angular.isNullOrUndefined($scope.listes.VL_OPE_HABIL_UTILISATEUSERVGES) && $scope.listes.VL_OPE_HABIL_UTILISATEUSERVGES.length > 0) {
                                            idServiceParDefaut = $scope.listes.VL_OPE_HABIL_UTILISATEUSERVGES[0].IDENT_SERVICEGESTIONNAIRE.valeur;
                                        }

                                        habilitation.initialisationHabilitationServiceGestionnaire(servicegestionnaire, idServiceParDefaut);

                                        // Ajout de l'onglet en mémoire
                                        ongletsService.ajouterOnglet($scope, module, module.MOD_ENTITE.valeur, modele[module.MOD_ENTITE.valeur].valeur, true, true, true).then(function (onglet) {
                                            var trigramme = onglet.moduleTrigramme;
                                            var mobilite = serveur.isMobile()?'_M':'';
                                            var ecranServiceGestionnaire = trigramme + mobilite + '_SAUVEGARDE_SERVICEGESTIONNAIRE';
                                            var identite = {};
                                            identite[module.MOD_ENTITE.valeur] = modele[module.MOD_ENTITE.valeur].valeur;

                                            serveur.chargement(onglet.moduleNom, $scope,  ecranServiceGestionnaire, identite).then(function (retourChargement) {
                                                if (!angular.isNullOrUndefined(retourChargement[0])) {
                                                    var idService = $scope.champs[retourChargement[0][0]].ID_SERVICEGESTIONNAIRE.valeur;
                                                    var droitAcces = (angular.isNullOrUndefined(idService)) ? 1 : habilitation.habilitationServiceGestionnaire()[idService];

                                                    if (droitAcces > 0) {
                                                        // Ouvrir l'onglet
                                                        $scope.onDemandeActivite({idaction: module, entite: entite, modele: modele});
                                                    } else {
                                                        opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + aNomModule.toLowerCase() + ".", true, false);
                                                    }
                                                } else {
                                                    opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + aNomModule.toLowerCase() + ".", true, false);
                                                }
                                            }, function () {
                                                //opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + aNomModule.toLowerCase() + ".", true, false);
                                            });
                                        });
                                    })
                                });
                            } else {
                                opPopupAlerte.creer('warning', "Habilitations insuffisantes pour accèder à la fiche " + aNomModule.toLowerCase() + ".", true, false);
                            }
                        };

                        /**
                         * Fonction permettant de marquer le formulaire comme modifié
                         */
                        $scope.setFormulaireDirty = function() {
                            if (!angular.isNullOrUndefined($scope.opVueFormulaire.vueModifie)) {
                                $scope.opVueFormulaire.vueModifie.$pristine = false;
                            }
                        };

                        /**
                         * Afficher une entité dans un nouvel onglet (suite clique lien dans grille de synthèse)
                         * @param {Object} module Module
                         * @param {String} colCle Nom champ clé pour identifier l'entité à ouvrir
                         * @param {Object} modeleEntite Modèle champ clé de l'entité à ouvrir
                         */
                        $scope.demandeDetailEntiteGrilleSynthese = function (module, colCle, modeleEntite) {
                            $scope.$parent.$parent.ongletCtrl.demandeDetailEntite(module, colCle, modeleEntite);
                        };

                        /**
                         * Fonction de suppression de formulaire
                         * @param {ecran} Ecran
                         */
                        $scope.suppressionFormulaire = function(ecran){
                            $scope.suppressionVueFormulaire(ecran);
                        };

                        /**
                         * Fonction de sauvegarde de fichier de formulaire
                         */
                        $scope.sauvegardeFichierFormulaire = function(){
                            // Sauvegarde des fichiers
                            var deferred = $q.defer();
                            if ( !angular.isNullOrUndefined( $scope.estFormulaireFichier ) ){
                                requetefichier.executerFichier($scope,$scope.nomModule,$scope.identifiant, $scope.getFichiers())
                                    .then(function(reponse){
                                        deferred.resolve(reponse);
                                    },function(erreur){
                                        deferred.reject(erreur);
                                });
                            }
                            else
                                deferred.resolve();
                            return deferred.promise;
                        };

                        /**
                         * Fonction de suppression de fichier
                         */
                        $scope.suppressionFichier = function(){
                            var deferred = $q.defer();

                            // Suppression de fichier
                            if ( !angular.isNullOrUndefined(requetefichier.getFichierSupprimes($scope))){
                                requetefichier.suppressionFichier($scope,$scope.nomModule).then(function(reponse){
                                        deferred.resolve(reponse);
                                    },
                                    function(erreur){
                                        deferred.reject(erreur);
                                    });
                            }
                            else
                                deferred.resolve();

                            return deferred.promise;
                        };

                        /**
                         * Fonction de sauvegarde d'écrans
                         */
                        $scope.sauvegardeEcrans = function(){
                            var deferred = $q.defer();

                            var promises = [];
                            for (var i=0;i<$scope.listeEcran.length;i++){
                                promises.push($scope.sauvegarde[$scope.listeEcran[i]]());

                                if (!angular.isNullOrUndefined($scope.form["form_" + $scope.listeEcran[i]])) {
                                    $scope.form["form_" + $scope.listeEcran[i]].$setPristine();
                                }
                            }
                            $q.all(promises).then(function(reponses){
                                // Vérification si on est en création
                                var bCreation = false;
                                for ( var nomIdentifiant in $scope.identifiant){
                                    if ( $scope.identifiant[nomIdentifiant].toString().length == 0 ){
                                        bCreation = true;
                                        break;
                                    }
                                }

                                if ( !angular.isNullOrUndefined(reponses) && reponses.length>0 && bCreation ){
                                    // dans le cas de création modification de la fonction de sauvegarde pour éviter des doublons
                                    $scope.identifiant = reponses[0];
                                    $scope.opVueFormulaire.identifiant = reponses[0];
                                    $scope.recharger();
                                    deferred.resolve($scope.identifiant);
                                }

                                deferred.resolve($scope.identifiant);
                            },function(erreurs){
                                deferred.reject(erreurs);
                            })


                            return deferred.promise;
                        };


                        /**
                         * Fonction de sauvegarde de formulaire
                         */
                        $scope.sauvegardeFormulaire = function(){
                            var deferred = $q.defer();

                            // Action calcul avant sauvegarde (exemple recherche numéro)
                            if ( $scope.actionsPreSauvegarde.length>0 ){
                                gestionnaireActionFormulaire.gererActionPreSauvegarde(
                                    $scope,
                                    $scope.opVueFormulaire.nomModule,
                                    $scope.actionsPreSauvegarde
                                ).then(function(reponse){
                                        $scope.sauvegardeFormulaireSuite().then(function(reponse){
                                            deferred.resolve(reponse);
                                        },function(erreur){
                                            deferred.reject(erreur);
                                        });
                                    },
                                    function(erreur){
                                        deferred.reject(erreur);
                                    });
                            }
                            else{
                                $scope.sauvegardeFormulaireSuite().then(function(reponse){
                                    deferred.resolve(reponse);
                                },function(erreur){
                                    deferred.reject(erreur);
                                });
                            }

                            return deferred.promise;
                        };

                        /**
                         * Fonction de sauvegarde de formulaire
                         */
                        $scope.sauvegardeFormulaireSuite = function(){
                            var deferred = $q.defer();

                            $scope.suppressionFichier().then(function(reponse){
                                $scope.sauvegardeFichierFormulaire()
                                    .then(function(reponse){
                                        $scope.sauvegardeEcrans().then(function(reponse){
                                            // Suppression des fichiers ajoutées
                                            if ( !angular.isNullOrUndefined( $scope.estFormulaireFichier ) ){
                                                var fichiers = $scope.getFichiers();
                                                for ( var i = 0; i<fichiers.length; i++){
                                                    fichiers[i].valeurFichier = "";
                                                }
                                            }

                                            // Suppression des fichiers supprimés
                                            requetefichier.viderFichierSupprimes($scope);

                                            deferred.resolve(reponse);
                                        },function(erreur){
                                            deferred.reject(erreur);
                                        });
                                    },function(erreur){
                                        deferred.reject(erreur);
                                    });
                            },function(erreur){
                                deferred.reject(erreur);
                            });


                            return deferred.promise;
                        };

                        /**
                         * Fonction de gestion d'actions de formulaire d'écran
                         * @param {actions} Liste d'action action à executer
                         */
                        $scope.gererActionFormulaire= function(actions){
                            if ( !angular.isNullOrUndefined(actions) && actions.length>0 ){
                                var actions = gestionnaireActionFormulaire.gererAction($scope,$scope.opVueFormulaire.nomModule,actions,
                                    $scope.correspondanceActionEcran,
                                    $scope.actionsPreSauvegarde,$attrs.opIsLecture)


                                var ecranChargement = actions.ACHARGER;
                                var ecranSuppression = actions.ASUPPRIMER;

                                // Ecran Formulaire à ajouter
                                for (var i=0;i<ecranChargement.length;i++){
                                    $scope.chargementFormulaire($scope.opVueFormulaire.nomModule,ecranChargement[i]);
                                }

                                // Ecran Formulaire à supprimer
                                for (var i=0;i<ecranSuppression.length;i++){
                                    $scope.suppressionFormulaire(ecranSuppression[i]);
                                }
                            }

                        };

                        /**
                         * Fonction de Ajout de la fonction de gestion d'action "est principal?
                         */
                        $scope.onEstPrincipal = function (aTableNom, aIndex) {
                            gestionGrilleElementPrincipal.gestionGrilleElementPrincipal($scope, aTableNom, aIndex);
                        };
                    }],

                link:function(scope,element,attrs) {
                    /**
                     * Ecouter la bascule de la section droite de l'onglet
                     */
                    if ('opSectionDroiteVisible' in attrs) {
                        scope.droiteVisible = scope.opSectionDroiteVisible;

                        scope.$watch('opSectionDroiteVisible', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                scope.droiteVisible = newValue;
                            }
                        });
                    }

                    scope.$watch('opVueFormulaire', function(value){
                        if (!angular.isNullOrUndefined(value)){
                            requetefichier.viderFichierSupprimes(scope);

                            scope.nomModule = value.nomModule;
                            scope.nomEcran = (attrs.opIsLecture == "true") ? value.nomEcran + 'Synthese' : value.nomEcran;
                            scope.identifiant = value.identifiant;
                            scope.idAction = value.idAction;
                            scope.vignettePrincipale=null;

                            // Création de la fonction de sauvegarde
                            if ( attrs.opIsLecture != "true") {
                                value.sauvegarde = function () {
                                    return scope.sauvegardeFormulaire();
                                };
                                value.recharger = function () {
                                    return scope.recharger();
                                };
                            }

                            // Réiniatialisation des formulaires
                            scope.viderVueFormulaire();

                            // Chargement de l'écran principale
                            scope.chargementFormulaire(scope.nomModule, scope.nomEcran, scope.identifiant);
                        }
                    });

                    scope.$watch('opTable', function(value) {
                        if (!angular.isNullOrUndefined(value)) {
                            requetefichier.viderFichierSupprimes(scope);

                            // Récupération des données
                            scope.champs = scope.$parent.champs;
                            scope.grilles =scope.$parent.grilles;
                            scope.listes = scope.$parent.listes;
                            scope.descriptions = scope.$parent.descriptions;
                            scope.nomModule = scope.$parent.nomModule;

                            var isFormulaireRecherche = angular.isNullOrUndefined(scope.isFormulaireRecherche)?false:$scope.isFormulaireRecherche;
                            var template = generateurFormulaire.creerTemplate(scope,scope.nomModule,[[value]],value,"",null,false,false,true,isFormulaireRecherche).template;
                            element.append($compile(template)(scope));

                            $timeout(function() {
                                if ( !angular.isNullOrUndefined(scope.finchargement) )
                                    scope.finchargement({scope:scope});
                            });

                        }
                    });


                    /**
                     * Fonction d'ajout de fichier au carroussel
                     */
                    scope.ajouterFichier = function (evt, nomTable, idCarousel) {
                        requetefichier.ajouterFichier(scope,scope.nomModule,evt,nomTable,scope.identifiant, idCarousel);
                    };

                    /**
                     * Ouverture de document
                     * @param {String} aDocument
                     */
                    scope.ouvrirDocument = function(aDocument){
                        scope.sauvegardeFormulaire().then(function (retourSauvegarde) {
                            requetefichier.ouvrirFichier(scope.nomModule, scope, aDocument);
                        });
                    };

                    /**
                     * Fonction de suppression de fichier
                     */
                    scope.supprimerFichier = function (nomTable,index) {
                        requetefichier.supprimerFichier(scope,scope.nomModule, nomTable,index);
                    };

                    /**
                     * Fonction de disable de case à cocher de vignette
                     */
                    scope.getDisableVignette = function (nomTable,index) {
                        var trigramme = moduleInfos.getInfo(scope.nomModule, "MOD_TRIGRAMME");
                        var colNomType = trigramme + "_DOC_TYPE";
                        var bResultat = ( scope.grilles[nomTable][index][colNomType].valeur.length<=0 ||
                            angular.isNullOrUndefined( $config.typeVignette)   ||
                            angular.isNullOrUndefined( scope.grilles[nomTable][index][colNomType].valeur ) ||
                            scope.grilles[nomTable][index][colNomType].valeur.length<=0 ||
                            $config.typeVignette.indexOf(scope.grilles[nomTable][index][colNomType].valeur.replace('image/','')) == -1 );

                        return bResultat;
                    }

                    /**
                     * Fonction d'appel de popup d'ajout d'élément
                     */
                    scope.ajouterElement=function(nomTable,openRegroupement) {
                        if (nomTable.indexOf("DOCUMENT") !== -1) {
                            // Document attache
                            $timeout(function(){
                                document.getElementById('id_documentformulaire'+ scope.opName + nomTable).click();
                            })
                        } else {
                            // Ajout de l'élément
                            var nouvelleLigne = scope.grilles[nomTable].descriptions.creerObjet();
                            scope.grilles[nomTable].unshift(nouvelleLigne);
                            $timeout(function() {
                                if ( !angular.isNullOrUndefined(scope.grilles[nomTable].optionGrilles) ){
                                    $timeout(function() {
                                        if ( !angular.isNullOrUndefined(scope.grilles[nomTable].optionGrilles.gridApi)){
                                            scope.grilles[nomTable].optionGrilles.gridApi.expandable.toggleRowExpansion(scope.grilles[nomTable].optionGrilles.data[0]);
                                        }
                                    });
                                }
                                scope.form["form_"+ scope.nomEcran ].$pristine = false;
                                });

                            // Ouverture du regroupement
                            scope.statutRegroupement[openRegroupement] = true;
                        }
                    };

                    /**
                     * Fonction "bidouille" pour afficher une image dans un popup
                     */
                    scope.afficherImg=function(titre) {
                        var popupfficherImg = $modal.open({
                            templateUrl: 'moteur/composants/popup/OpModalImage.html',
                            controller: 'fr.operis.moteurV4.composants.OpModalImageControleur',
                            size:'lg',
                            resolve: {
                                titre:function () {
                                    return titre;
                                }
                            }
                        });

                        popupfficherImg.result;
                    };

                    /**
                     * Fonction d'appel de popup de suppression d'élément
                     */
                    scope.supprimerElement=function(nomTable,titre,entite,index) {
                        var indexASupprimer=index;
                        var popupAjoutElement = $modal.open({
                            templateUrl: 'moteur/composants/OpPopupAjoutElement.html',
                            controller: 'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur',
                            size: 'lg',
                            resolve: {
                                titre: function () {
                                    var lib = (!angular.isNullOrUndefined(titre)) ? titre.replace(/null/g, "").trim() : "";
                                    if (lib === "") {
                                        return 'Supprimer ?';
                                    } else {
                                        return 'Supprimer "' + lib + '" ?';
                                    }
                                },
                                libElement: function () {
                                    return (!angular.isNullOrUndefined(titre)) ? titre.replace(/null/g, "").trim() : "";
                                },
                                entite: function () {
                                    return entite;
                                },
                                descriptions: function () {
                                    return scope.descriptions;
                                },
                                listes: function () {
                                    return scope.listes;
                                },
                                nomTable: function () {
                                    return nomTable;
                                },
                                type: function () {
                                    return TYPE_POPUPSUPPRESSION;
                                },
                                listeElements: null,
                                nomModule: function () {
                                    return scope.nomModule;
                                }
                            }
                        });

                        popupAjoutElement.result.then(function (retourValeur) {
                            if (!angular.isNullOrUndefined(retourValeur)) {
                                scope.grilles[nomTable].splice(indexASupprimer, 1);

                                // Marquer le formulaire comme modifié
                                if (!angular.isNullOrUndefined(scope.opOnglet) &&
                                    !angular.isNullOrUndefined(scope.opOnglet.ongletCtrl) &&
                                    !angular.isNullOrUndefined(scope.opOnglet.ongletCtrl.vueFormulaireFiche.vueModifie)) {
                                    scope.opOnglet.ongletCtrl.vueFormulaireFiche.vueModifie.$pristine = false;
                                }

                                if (!angular.isNullOrUndefined(scope.opVueFormulaire) &&
                                    !angular.isNullOrUndefined(scope.opVueFormulaire.vueModifie)) {
                                    scope.opVueFormulaire.vueModifie.$pristine = false;
                                }
                            }
                        });
                    };

                    /**
                     * Ecouteur de suppression
                     */
                    scope.$on('$destroy', function () {

                    });

                    /**
                     * Ouverture d'un fichier dans une nouvelle fenêtre/onglet
                     */
                    scope.ouvrirFichier = function(url) {
                        $window.open(url);
                    };

                    /**
                     * Conversion d'un champs date en champs texte au format DD/MM/YYYY
                     */
                    scope.dateVersTexte = function (aDate){
                        return conversion.versTexte(aDate,'DATE');
                    };
                }
            };
        }])
;

