/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteFichier', ['fr.operis.moteurV4.communication.OperisWSServer','fr.operis.moteurV4.utils.Conversion','fr.operis.moteurV4.utils.GestionGrilleElementPrincipal'])
    /**
     * Largeur des vignettes
     * @type {Int}
     * @value descriptions
     */
    .constant('fr.operis.moteurV4.utils.RequeteFichier.VIGNETTE_WIDTH', 80)

    /**
     * Hauteur des vignettes
     * @type {Int}
     * @value descriptions
     */
    .constant('fr.operis.moteurV4.utils.RequeteFichier.VIGNETTE_HEIGTH', 60)

    /**
     * Nom de l'attribut pour les descriptions
     * @type {String}
     * @value descriptions
     */
    .constant('fr.operis.moteurV4.utils.RequeteFichier.ID_VUE_VIGNETTREPRINCIPALE', '_VIGNETTEPRINCIPALE')

    .service('fr.operis.moteurV4.utils.RequeteFichier',
        ['fr.operis.moteurV4.communication.OperisWSServer',
            'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_NIVEAU_1',
            'fr.operis.moteurV4.communication.OperisWSServer.ID_VUE_FICHIER',
            'fr.operis.moteurV4.composants.OpPopupAlerte',
            'fr.operis.moteurV4.utils.Conversion',
            'fr.operis.moteurV4.utils.GestionGrilleElementPrincipal',
            'fr.operis.moteurV4.utils.ModuleInfos',
            'fr.operis.moteurV4.utils.Config',
            'fr.operis.moteurV4.utils.RequeteFichier.VIGNETTE_WIDTH',
            'fr.operis.moteurV4.utils.RequeteFichier.VIGNETTE_HEIGTH',
            'fr.operis.moteurV4.utils.RequeteFichier.ID_VUE_VIGNETTREPRINCIPALE',
            '$http',
            '$q',
            '$window',
            '$timeout',
        function (serveur,
                  SEPARATEUR_NIVEAU_1,
                  ID_VUE_FICHIER,
                  opPopupAlerte,
                  conversion,
                  gestionGrilleElementPrincipal,
                  moduleInfos,
                  $config,
                  VIGNETTE_WIDTH,
                  VIGNETTE_HEIGTH,
                  ID_VUE_VIGNETTREPRINCIPALE,
                  $http,
                  $q,
                  $window,
                  $timeout
                ) {


            this.setFichierSupprimes = function(aScope,aIdFichier){
                if (angular.isNullOrUndefined(aScope._fichierSupprimes))
                    aScope._fichierSupprimes = [];

                if ( !angular.isNullOrUndefined(aIdFichier))
                aScope._fichierSupprimes.push(aIdFichier);
            };

            this.getFichierSupprimes = function(aScope){
                return aScope._fichierSupprimes;
            };

            this.viderFichierSupprimes = function(aScope){
                if ( !angular.isNullOrUndefined(aScope._fichierSupprimes) )
                    delete aScope._fichierSupprimes;
            };

            this.suppressionFichier=function(aScope, aNomModule){
                var deferred = $q.defer();
                $q.all(aScope._fichierSupprimes.map(function(id_fichier){
                    return serveur.suppression(aNomModule, aScope, ID_VUE_FICHIER,{ID_FICHIER:id_fichier});
                })).then(function(reponse){
                    deferred.resolve(reponse);
                } ,function(erreur){
                    deferred.reject(erreur);
                });

                return deferred.promise;
            };

            /**
             * Supprimer un document attaché
             * @param {Object} aScope Scope
             * @param {nomTableDocument} Nom de la table de document
             * @param {index} index
             */
            this.supprimerFichier=function(aScope, aNomModule, nomTable,index){
                var nomChampsFichier = "ID_" + moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME") + "_DOC_ID_FICHIER";
                var nomChampsVignette = "ID_" + moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME") + "_DOC_ID_VIGNETTE";
                this.setFichierSupprimes(aScope,aScope.grilles[nomTable][index][nomChampsFichier].valeur);
                this.setFichierSupprimes(aScope,aScope.grilles[nomTable][index][nomChampsVignette].valeur);

                aScope.grilles[nomTable].splice(index,1);
            };

            /**
             * Exécution de la requête
             *
             * @param aScope Scope
             * @param aNomModule Nom du module
             * @param aFichiers Fichiers à sauvegarder
             *
             */
            this.executerFichier = function(aScope, aNomModule, aIdentifiant, aFichiers){
                var deferred = $q.defer();
                var that = this;

                var promise = [];
                for (var i= 0; i<aFichiers.length; i++){
                    if ((!angular.isNullOrUndefined(aFichiers[i].valeurFichier) && aFichiers[i].valeurFichier.length>0)
                        || (!angular.isNullOrUndefined(aFichiers[i].vignette) && aFichiers[i].vignette )){
                        promise.push(this.executerRequeteFichier(aScope, aNomModule, aIdentifiant, aFichiers[i]));
                    }
                };

                $q.all(promise).then(function(reponse){
                    deferred.resolve(reponse);
                } ,function(erreur){
                    deferred.reject(erreur);
                });

                return deferred.promise;
            };

            /**
             * Exécution de la requête
             *
             * @param aNomModule Nom du module
             * @param aFichiers Fichiers à sauvegarder
             *
             */
            this.executerRequeteFichier = function( aScope, aNomModule, aIdentifiant, aFichier){
                var deferred = $q.defer();
                var that = this;

                // Encodage du fichier pour la table document
                aFichier.valeurFichier = aFichier.valeurFichier.substring(aFichier.valeurFichier.indexOf("base64,") + 7);
                serveur.sauvegardeFichier(aNomModule, aScope, aFichier)
                    .then(function(retourSauvegarde){
                        var champsNomFichier = "ID_" + moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME") + "_DOC_ID_FICHIER";
                        aFichier[champsNomFichier].valeur = conversion.versEntier(retourSauvegarde.ID_FICHIER);

                        // Pour la table vignette si le document est principal et est de type image
                        if ( aFichier.file.type.indexOf("image") != -1 ){
                            aFichier.valeurFichier =  aFichier.valeurVignette.substring(aFichier.valeurVignette.indexOf("base64,") + 7);
                            serveur.sauvegardeFichier(aNomModule, aScope, aFichier)
                                .then(function(retourVignette){
                                    var champsNomVignette = "ID_" + moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME") + "_DOC_ID_VIGNETTE";
                                    aFichier[champsNomVignette].valeur = conversion.versEntier(retourVignette.ID_FICHIER);
                                    deferred.resolve();
                                },function(erreur){
                                    deferred.reject(erreur);
                                })
                        }else
                            deferred.resolve();
                    },function(erreur){
                        deferred.reject(erreur);
                    });

                return deferred.promise;
            };

            /**
             * Ajouter un document attaché
             * @param {Object} aScope Scope
             * @param {Evenement} evt evenement du champ modifié
             * @param {nomTableDocument} Nom de la table de document
             */
            this.ajouterFichier = function(aScope, nomModule, evt, nomTableDocument, aIdentifiant, idCarousel){
                var that = this;
                aScope.$apply(function () {
                    var file = evt.target.files[0];
                    if ( angular.isNullOrUndefined(file.size) ||
                        angular.isNullOrUndefined($config.tailleMaxDocument) ||
                        file.size >= ( $config.tailleMaxDocument * 1000) ){
                        opPopupAlerte.creer('error',"Taille du document dépasse la limite autorisée.", true, false);
                        return;
                    }

                    var fileReader = new FileReader();
                    var ctx = document.getElementById('canvasResizeImage').getContext('2d');
                    var img = new Image();

                    fileReader.readAsDataURL(file);
                    fileReader.onloadend = function () {
                        var dataURL = fileReader.result;

                        // Valeur du fichier
                        var fichier = aScope.descriptions[nomTableDocument].creerObjet();
                        fichier.valeurFichier = dataURL;
                        fichier.file = evt.target.files[0];

                        // Nom du fichier, type, description, date
                        var trigramme = moduleInfos.getInfo(nomModule, "MOD_TRIGRAMME");
                        fichier[trigramme + "_DOC_TYPE"].valeur = file.type;
                        fichier[trigramme + "_DOC_LIB"].valeur = file.name;
                        fichier[trigramme + "_DOC_DATE"].valeur = new Date();
                        fichier["tableAss"] = trigramme + "_" + "DOCUMENT";
                        fichier["idAction"] = "";
                        fichier["idModule"] = serveur.getIdModule(nomModule);
                        fichier["idEntite"] = moduleInfos.getInfo(nomModule, "MOD_ENTITE") +"," + aIdentifiant[moduleInfos.getInfo(nomModule, "MOD_ENTITE")];
                        fichier.active = true;
                        aScope.grilles[nomTableDocument].push(fichier);
                        gestionGrilleElementPrincipal.gestionGrilleElementPrincipal(aScope,nomTableDocument,aScope.grilles[nomTableDocument].length-1);
                        aScope.$apply();// Mise à jour des binding

                        // Retaille de l'image si image
                        if ( file.type.toUpperCase().indexOf("IMAGE") != -1 ){
                            img.onload = function() {
                                // setup scaled dimensions
                                var scaled = getScaledDim(img, VIGNETTE_WIDTH, VIGNETTE_HEIGTH);

                                ctx.canvas.width = scaled.width;
                                ctx.canvas.height = scaled.height;

                                ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);

                                var canvas = document.getElementById('canvasResizeImage');
                                fichier["valeurVignette"] = canvas.toDataURL();
                            }
                            img.src = dataURL;
                        }else{
                            var pos = file.name.lastIndexOf(".");
                            var fileExtention = file.name.slice(pos + 1);
                            fichier["valeurVignette"] = './img/applicationtype/'+ fileExtention +'.svg';
                        }

                        // Refresh du carsouel
                        if (!angular.isNullOrUndefined(idCarousel)) {
                            document.getElementById(idCarousel).carousel("next");
                        }
                    };
                });
            };

            function getScaledDim(img, maxWidth, maxHeight) {
                var scaled = {
                    ratio: img.width / img.height,
                    width: img.width,
                    height: img.height
                }
                if (scaled.width > maxWidth) {
                    scaled.width = maxWidth;
                    scaled.height = scaled.width / scaled.ratio;
                }
                if (scaled.height > maxHeight) {
                    scaled.height = maxHeight;
                    scaled.width = scaled.height / scaled.ratio;
                }
                return scaled;
            }

            /**
             * Ouvrir Fichier
             * @param {String} aNomModule Nom du module
             * @param {Object} aScope Scope
             * @param {Object} aDocument Element de la grille document
             */
            this.ouvrirFichier = function (aNomModule, aScope, aDocument){
                var urlImageDefault = './img/default/'+ aNomModule.toLowerCase() +'.jpg';
                if ( !angular.isNullOrUndefined( aDocument ) ) {
                    var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME"), entite = {};
                    //var link = angular.element('<a/>');
                    if ( !angular.isNullOrUndefined( aDocument["ID_" + trigramme + "_DOC_ID_FICHIER" ].valeur )){
                        entite["ID_FICHIER"] = aDocument["ID_" + trigramme + "_DOC_ID_FICHIER" ].valeur;
                        serveur.chargement(aNomModule, aScope, ID_VUE_FICHIER, entite)
                            .then(function (retourChargement) {
                                var valeurFichier = aScope.champs["GES_FICHIER"]["FICHIERVALEUR"].valeur;
                                var typeFichier = aScope.champs["GES_FICHIER"]["FICHIERTYPE"].valeur;
                                var fichierNom = aScope.champs["GES_FICHIER"]["FICHIERNOM"].valeur;
                                if (!angular.isNullOrUndefined(valeurFichier)) {

                                    if (window.navigator.msSaveOrOpenBlob) { // for IE and Edge
                                        var byteCharacters = atob(encodeURI(valeurFichier));
                                        var byteNumbers = new Array(byteCharacters.length);
                                        for (var i = 0; i < byteCharacters.length; i++) {
                                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                                        }
                                        var byteArray = new Uint8Array(byteNumbers);
                                        var blob = new Blob([byteArray], {
                                            type: 'application/octet-stream'
                                        });

                                        window.navigator.msSaveBlob(blob, fichierNom);
                                    }else{
                                        var link = document.createElement('a');
                                        link.href = 'data:application/octet-stream;base64,' + encodeURI(valeurFichier);
                                        link.download = fichierNom;
                                        link.target = '_blank';

                                        //Firefox requires the link to be in the body
                                        document.body.appendChild(link);

                                        //simulate click
                                        link.click();

                                        //remove the link when done
                                        document.body.removeChild(link);
                                    }

                                }
                                else
                                    $window.open(urlImageDefault,"_blank");
                            }, function (erreur) {

                            })
                    }
                    else {
                        // Défaut
                        $window.open(urlImageDefault, "_blank");
                    }
                }

            };

            /**
             * Chargement des vignettes d'une table
             * @param {String} aNomModule Nom du module
             * @param {Object} aScope Scope
             * @param {Object} aDocument Element de la grille document
             */
            this.chargementVignette = function(aNomModule, aScope, aDocument){
                var urlImageDefault = './img/default/'+ aNomModule.toLowerCase() +'.jpg';
                if ( !angular.isNullOrUndefined( aDocument ) ) {
                    var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME"), entite = {};

                    // Affichage des vignettes de type image si présente
                    // Sinon image par defaut du module
                    // Ou du type d'application si la vignette n'est pas une image mais une application
                    if ( !angular.isNullOrUndefined( aDocument["ID_" + trigramme + "_DOC_ID_VIGNETTE" ].valeur )){
                        entite["ID_FICHIER"] = aDocument["ID_" + trigramme + "_DOC_ID_VIGNETTE" ].valeur;
                        serveur.chargement(aNomModule, aScope, ID_VUE_FICHIER, entite)
                            .then(function (retourChargement) {
                                var valeurFichier = aScope.champs["GES_FICHIER"]["FICHIERVALEUR"].valeur;
                                if (!angular.isNullOrUndefined(valeurFichier)) {
                                    aDocument["valeurVignette"] = "data:image/png;base64," + valeurFichier;
                                }
                                else
                                    aDocument["valeurVignette"] = urlImageDefault;
                            }, function (erreur) {

                            })
                    }else if ( !angular.isNullOrUndefined(aDocument[trigramme + "_DOC_LIB"].valeur ) ){
                        var pos = aDocument[trigramme + "_DOC_LIB"].valeur.lastIndexOf(".");
                        var fileExtention = aDocument[trigramme + "_DOC_LIB"].valeur.slice(pos + 1);
                        aDocument["valeurVignette"] = './img/applicationtype/'+ fileExtention +'.svg';
                    }
                    else
                        aDocument["valeurVignette"] = urlImageDefault;
                }
            };

            /**
             * Chargement des vignettes d'une table
             * @param {String} aNomModule Nom du module
             * @param {Object} aScope Scope
             * @param {Object} aEntite Entite
             */
            this.chargementVignettePrincipale = function(aModuleNom, aScope, aEntite){
                var deferred = $q.defer();
                var trigramme = moduleInfos.getInfo(aModuleNom, "MOD_TRIGRAMME"), mobile="";
                if ( serveur.isMobile())
                    mobile = "_M"
                serveur.chargement(aModuleNom, aScope, trigramme + mobile + ID_VUE_VIGNETTREPRINCIPALE, aEntite)
                    .then(function (retourChargement){
                        deferred.resolve({valeur:aScope.champs["VL_" + trigramme + "_VIGNETTEPRINCIPALE"]["FICHIERVALEUR"].valeur
                            , type:aScope.champs["VL_" + trigramme + "_VIGNETTEPRINCIPALE"]["FICHIERTYPE"].valeur
                            , nom:aScope.champs["VL_" + trigramme + "_VIGNETTEPRINCIPALE"]["FICHIERNOM"].valeur});
                    },function(erreur){
                        deferred.reject(erreur);
                    })

                return deferred.promise;
            };


            /**
             * Ajouter un document attaché
             * @param {String} aNomModule Nom du module
             * @param {Object} aScope Scope
             * @param {Object} aLien LienURL
             * @param {Object} aEntite Entite
             * @param {Object} aTableAssocie Table Associe
             */
            this.ajouterFichierDirectURL = function(aNomModule, aScope, aLien, aEntite, aTableAssocie){
                /*var Base64 = {
                    _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
                    encode: function(e) {var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},
                    decode: function(e) {var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9+/=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},
                    _utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},
                    _utf8_decode:function(e){var t="",c1, c2, c3;var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}
                };*/

                var deferred = $q.defer();
                var lien = aLien.split('/');
                var fileNom = "AttacherEnvoyer.pdf";
                if (!angular.isNullOrUndefined(lien) && lien.length > 1) {
                    fileNom = lien[lien.length - 1];
                }

                $http.get(aLien, {headers: {'Content-Type': 'application/pdf'}, responseType: 'blob'}).then(function (result) {
                            var f = result.data;
                            var fileReader = new FileReader();
                            fileReader.readAsDataURL(f);
                            fileReader.onloadend = function () {
                                var valeurFichier = fileReader.result.replace(/data:application\/pdf;base64,/,"");
                                var fichier = {
                                    tableAss: aTableAssocie,
                                    idEntite: aEntite,
                                    idModule: serveur.getIdModule(aNomModule),
                                    idAction: null,
                                    valeurFichier: valeurFichier,
                                    file: {name: fileNom, type: 'application/pdf'}
                                };

                                serveur.sauvegardeFichier(aNomModule, aScope, fichier)
                                    .then(function (response) {
                                        deferred.resolve(response.ID_FICHIER);
                                    }, function (erreur) {
                                        deferred.reject(erreur);
                                    })
                            };
                            /*var fichier = {
                                tableAss: aTableAssocie,
                                idEntite: aEntite,
                                idModule: serveur.getIdModule(aNomModule),
                                idAction: null,
                                valeurFichier: Base64.encode(result.data),
                                file: {name: fileNom, type: 'application/pdf'}
                            };

                            serveur.sauvegardeFichier(aNomModule, aScope, fichier)
                                .then(function (response) {
                                    deferred.resolve(response.ID_FICHIER);
                                }, function (erreur) {
                                    deferred.reject(erreur);
                                })*/
                        }
                );

                return deferred.promise;
            };
        }
    ]
);