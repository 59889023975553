/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Brian Kjerulf - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.ecriture.OpListePerso', [])
    .directive('opListePerso', ['fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS',
        'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES', 'fr.operis.moteurV4.modele.Modele.SCOPE_LISTES',
        'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTIONS', 'fr.operis.moteurV4.modele.Modele.SCOPE_DESCRIPTION',
        'fr.operis.moteurV4.modele.Modele.SCOPE_LIBELLE', 'fr.operis.moteurV4.modele.Modele.SCOPE_OBLIGATOIRE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE_COMPLEXE', 'fr.operis.moteurV4.modele.Modele.SCOPE_REF_ETRANGERE',
        'fr.operis.moteurV4.modele.Modele.SCOPE_ESTLECTURESEULE', 'fr.operis.moteurV4.modele.Modele.SCOPE_TYPE',
        '$timeout', 'fr.operis.moteurV4.communication.OperisWSServer',
        function (SCOPE_CHAMPS, SCOPE_GRILLES, SCOPE_LISTES, SCOPE_DESCRIPTIONS, SCOPE_DESCRIPTION, SCOPE_LIBELLE,
              SCOPE_OBLIGATOIRE, SCOPE_TYPE_COMPLEXE, SCOPE_REF_ETRANGERE, SCOPE_ESTLECTURESEULE, SCOPE_TYPE, $timeout,
              serveur) {
        return {
            restrict: 'E',
            replace: true,
            template: function (element, attributs) {
                // Scope
                var scopeSource = SCOPE_CHAMPS;
                if (attributs.opModel.indexOf('[') !== -1) {
                    scopeSource = SCOPE_GRILLES;
                }

                // Modèle
                var opModelClef = scopeSource + '.' + attributs.opModel;
                var opModelValeur = scopeSource + '.' + attributs.opModel.replace("_CLEF", "_VALEUR");

                // Option des modèles
                var modelOption = '{' +
                    'allowInvalid: true,' +
                    'updateOn: \'default blur\',' +
                    'debounce: {\'default\': 300, \'blur\': 0}' +
                    '}';

                // Liste champ clef (valeur et filtre)
                // Format attendu opListeClef = champClef as champLibellé in tableListe |filter: ...
                var idClef = opModelClef.replace('[$index]', '');
                var valeurListeClef = "";
                var valeurLabelClef = "";
                var groupListeClef = "";
                var lc = attributs.opListeClef.split(' in ');
                var idListeClef = lc[lc.length - 1].split(' |filter: ');

                var listeFilterClef = '';
                if (idListeClef.length === 2)
                    listeFilterClef = ' |filter: ' + idListeClef[1];
                idListeClef = idListeClef[0];

                //  Récupération de la valeur
                lc = lc[0].split(' group by ');
                if (lc.length > 1) {
                    groupListeClef = ' group by entite.' + lc[1] + '.valeur';
                }

                lc = lc[0].split(' as ');
                valeurListeClef = lc[0];
                if (lc.length > 1) {
                    valeurLabelClef = lc[1];
                } else {
                    valeurLabelClef = lc[0];
                }

                lc = '::' + SCOPE_LISTES + '.' + idListeClef;

                // Liste champ valeur (valeur et filtre)
                // Format attendu opListeValeur = champClef as champLibellé in tableListe |filter: ...
                var idValeur = opModelValeur.replace('[$index]', '');
                 /*var valeurListeValeur = "";
                var valeurLabelValeur = "";
                var groupListeValeur = "";
                var lv = attributs.opListeValeur.split(' in ');
                var idListeValeur = lv[lv.length - 1].split(' |filter: ');

                var listeFilterValeur = '';
                if (idListeValeur.length === 2)
                    listeFilterValeur = ' |filter: ' + idListeValeur[1];
                idListeValeur = idListeValeur[0];

                //  Récupération de la valeur
                lv = lv[0].split(' group by ');
                if (lv.length > 1) {
                    groupListeValeur = ' group by entite.' + lv[1] + '.valeur';
                }

                lv = lv[0].split(' as ');
                valeurListeValeur = lv[0];
                if (lv.length > 1) {
                    valeurLabelValeur = lv[1];
                } else {
                    valeurLabelValeur = lv[0];
                }

                lv = '::' + SCOPE_LISTES + '.' + idListeValeur;*/

                // Formulaire
                var formulaireNom = !angular.isNullOrUndefined(attributs.opForm) ? attributs.opForm : "form";

                // Required
                var requiredClef = opModelClef + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_OBLIGATOIRE;
                var requiredValeur = opModelValeur + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_OBLIGATOIRE;

                // Disabled
                var lectureSeuleClef = opModelClef + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_ESTLECTURESEULE;
                var lectureSeuleValeur = opModelValeur + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_ESTLECTURESEULE;

                // ToolTip
                var toolTipClef = opModelClef + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_DESCRIPTION;
                var toolTipValeur = opModelValeur + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_DESCRIPTION;

                // PlaceHolder
                var placeholderClef = opModelClef + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_DESCRIPTION;
                var placeholderValeur = opModelValeur + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_DESCRIPTION;

                // Typeahead éditable
                var typeaheadEditableValeur = 'true'; //'!((' + opModelValeur + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_TYPE +') == \'NUMBER\' )';

                // ngShow
                var show = (!angular.isNullOrUndefined(attributs.opShow)) ? attributs.opShow : true;

                // Scroll sur clique
                var scrollClick = (serveur.isMobile() && navigator.userAgent.match(/Android/i)) ? "liste-perso-scroll-on-click " : "";

                // Racine et libellé
                var templateElement = '<div class="form-group row-bottom-margin" ng-show="' + show + '">' +
                    '<label for="\'' + idClef + '\'" class="col-xs-5 col-sm-3 control-label"> ' +
                    '{{::' + opModelClef + '.' + SCOPE_DESCRIPTIONS + '.' + SCOPE_LIBELLE + '}}' +
                    '<small ng-show="' + formulaireNom + '[\'' + idClef + '\'].$error.required" style="color: orange;">&nbsp;<i class="fa fa-exclamation-triangle" title="Donnée obligatoire"></i></small>' +
                    '</label>';

                // Champ Clef
                templateElement += '<div class="col-sm-3">';
                templateElement += '<select class="form-control" ' +
                    'id="' + idClef + '" ' +
                    'name="' + idClef + '" ' +
                    'ng-model="' + opModelClef + '.valeur" ' +
                    'op-list-init ' +
                    'ng-readonly="' + lectureSeuleClef + '" ' +
                    'ng-required="{{::' + requiredClef + '}}" ' +
                    'title="{{::' + toolTipClef + '}}" ' +
                    'placeholder="{{::' + placeholderClef + '}}" ' +
                    'ng-change="onChangeClef(' + opModelClef + '.valeur, $index)" ' +
                    'ng-options="entite.' + valeurListeClef + '.valeur as entite.' + valeurLabelClef + '.valeur ' + groupListeClef + ' for entite in ' + lc + '">' +
                    '<option op-option-hidden="{{' + requiredClef + '}}" value=""></option>' +
                    '</select>';
                templateElement += '</div>';

                // Champ Valeur
                templateElement += '<div class="col-sm-6" ng-if="estClefRenseigne(' + opModelClef + '.valeur)">' +
                    '<div ng-if="!estRadio(\'' + idValeur.replace(/\./g, "_") + '\')">' +
                        '<div ng-if="typeDatalist(\'' + idValeur.replace(/\./g, "_") + '\')">' +
                            '<input type="hidden" ng-attr-id="{{getIdentifiant(\'id_data_list_\', \'_hidden\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ng-model="' + opModelValeur + '.valeur"/>' +
                            '<input ' +
                            'ng-attr-list="{{getIdentifiant(\'id_data_list_\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}"' +
                            'type="text" ' +
                            'class="form-control" ' +
                            'ng-attr-id="{{getIdentifiant(\'\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ' +
                            'ng-attr-name="{{getIdentifiant(\'\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ' +
                            'ng-model="valeurListe.valeur" ' +
                            'ng-readonly="' + lectureSeuleValeur + '" ' +
                            'ng-required="{{::' + requiredValeur + '}}" ' +
                            'title="{{::' + toolTipValeur + '}}" ' +
                            'placeholder="{{::' + placeholderValeur + '}}" ' +
                            'ng-change="onChangeValeurListe(\'' + idValeur.replace(/\./g, "_") + '\')" ' +
                            scrollClick +
                            '/>' +
                            //'<datalist ng-attr-id="{{getIdentifiant(\'id_data_list_\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ng-if="!estListeFixe(\'' + idValeur.replace(/\./g, "_") + '\')">' +
                            //    '<option ng-repeat="entite in ' + lv + '" data-id="{{entite.' + valeurListeValeur + '.valeur}}" value="{{entite.' + valeurLabelValeur + '.valeur}}"></option>' +
                            //'</datalist>'+
                            '<datalist ng-attr-id="{{getIdentifiant(\'id_data_list_\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}">' + // ng-if="estListeFixe(\'' + idValeur.replace(/\./g, "_") + '\')">' +
                                '<option ng-repeat="entite in getDataListeFixe(\'' + idValeur.replace(/\./g, "_") + '\')" data-id="{{entite.valeur}}" value="{{entite.libelle}}"></option>' +
                            '</datalist>'+
                        '</div>' +
                        '<div ng-if="typeTypeahead(\'' + idValeur.replace(/\./g, "_") + '\')">' +
                            '<input ' +
                            'type="text" ' +
                            'class="form-control op-typeahead" ' +
                            'ng-attr-id="{{getIdentifiant(\'\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ' +
                            'ng-attr-name="{{getIdentifiant(\'\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ' +
                            'ng-model="valeurTypeahead.valeur" ' +
                            'ng-readonly="' + lectureSeuleValeur + '" ' +
                            'ng-required="{{::' + requiredValeur + '}}" ' +
                            'title="{{::' + toolTipValeur + '}}" ' +
                            'placeholder="{{::' + placeholderValeur + '}}" ' +
                            //'ng-model-options="' + modelOption + '" ' +
                            'autocomplete="off" ' +
                            'typeahead-min-length="0" '+
                            //'empty-liste-perso ' +
                            'liste-perso-show-on-focus ' +
                            'typeahead="entite.libelle for entite in getDataListeFixe(\'' + idValeur.replace(/\./g, "_") + '\') | filter:$viewValue" ' + //:typeaheadComparator" ' +
                            'typeahead-focus-on-select="false" ' +
                            'typeahead-on-select="onChangeValeurTypeAhead($item)" ' +
                            'typeahead-template-url="moteur/composants/ecriture/OpTypeahead.html" ' +
                            'typeahead-editable="{{::' + typeaheadEditableValeur + '}}"' +
                            scrollClick +
                            '/>' +
                            //'<span class="input-group-btn">' +
                            //    '<button type="button" class="btn btn-default" ng-disabled="' + lectureSeuleValeur + '" ng-click="ouvrirTypeaheadListePerso(\'' + idValeur.replace(/\./g, "_") + '\')"><span class="caret"></span></button>' +
                            //'</span>' +
                        '</div>' +
                    '</div>'+
                    '<div ng-if="estRadio(\'' + idValeur.replace(/\./g, "_") + '\')">' +
                        '<div class="btn-group">'+
                            '<label ' +
                            'class="btn btn-default btn-sm" ' +
                            'ng-attr-id="{{getIdentifiant(\'\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ' +
                            'ng-attr-name="{{getIdentifiant(\'\', \'\', \'' + idValeur.replace(/\./g, "_") + '\')}}" ' +
                            'ng-repeat="entite in getDataListeFixe(\'' + idValeur.replace(/\./g, "_") + '\')" ' +
                            'ng-model="valeurRadio.valeur" ' +
                            'ng-change="onChangeValeurRadio(entite.valeur)" ' +
                            'btn-radio="entite.valeur" ' +
                            //'uncheckable' +
                            '>{{entite.libelle}}</label>' +
                        '</div>' +
                    '</div>' +
                    '</div>';

                templateElement += '</div>';

                return templateElement;
            },
            link: function (scope, element, attr) {
                /**
                 * Scope source et modèle
                 */
                var scopeSource = SCOPE_CHAMPS;
                if (attr.opModel.indexOf('[') !== -1) {
                    scopeSource = SCOPE_GRILLES;
                }

                var opModelClef = scopeSource + '.' + attr.opModel;
                var opModelValeur = scopeSource + '.' + attr.opModel.replace("_CLEF", "_VALEUR");

                /**
                 * Varaibles pour le champ clef
                 */
                scope.modelClefLibelle = attr.opModel.replace("_CLEF", "");

                // Format attendu opListeClef = champClef as champLibellé in tableListe |filter: ...
                var lc = attr.opListeClef.split(' in ');
                var tableListeClef = lc[lc.length - 1].split(' |')[0];
                lc = lc[0].split(' group by ');
                lc = lc[0].split(' as ');
                var champIdListeClef = lc[0];
                var champLabelListeClef = (lc.length > 1) ? lc[1] : lc[0];

                /**
                 * Varaibles pour le champ valeur en mode "liste fixe"
                 */
                scope.valeurRadio = {valeur: ""};
                scope.valeurListe = {valeur: ""};
                scope.valeurTypeahead = {valeur: ""};

                /**
                 * Description liste (champ valeur)
                 */
                var nomTableColonneClef = opModelClef.split('.');
                var nomTableColonne = opModelValeur.split('.');
                var estGrille = (nomTableColonne[0] === SCOPE_GRILLES);

                var lv = attr.opListeValeur.split(' in ');
                var tableListe = lv[lv.length - 1].split(' |')[0];
                var lvColonneCleFiltre = lv[lv.length - 1].split(' |')[1];
                lvColonneCleFiltre = lvColonneCleFiltre.split(':')[1].trim().replace(/\'/g, "");

                lv = lv[0].split(' group by ');
                lv = lv[0].split(' as ');
                var lvColonneCle = lv[0];

                var lvColonneLibelle = lvColonneCle;
                if (lv.length > 1) {
                    lvColonneLibelle = lv[1];
                }

                /**
                 * Tableau des listes fixes (Clé 1#Valeur 1#Complément 1#Titre 1|Clé 2#Valeur 2#Complément 2#Titre 2|Clé 3#Valeur 3#Complément 3#Titre 3|...)
                 */
                if (angular.isNullOrUndefined(scope.listepersoListefixe)) {
                    scope.listepersoListefixe = [];
                }

                /**
                 * Init paramètres d'affichage champ valeur
                 */
                var idListeFixe = nomTableColonne[0] + "_" + nomTableColonne[1].replace('[$index]', '') + "_" + nomTableColonne[2] + "_" + scope.$id;
                if (angular.isNullOrUndefined(scope.paramListePerso)) {
                    scope.paramListePerso = [];
                }

                if (angular.isNullOrUndefined(scope.paramListePerso[idListeFixe])) {
                    scope.paramListePerso[idListeFixe] = {};
                    scope.paramListePerso[idListeFixe].listeFixe = true;
                    scope.paramListePerso[idListeFixe].listeFixeRadio = false;
                    scope.paramListePerso[idListeFixe].id = scope.$id;

                    var desc;
                    if (estGrille) {
                        desc = scope[scopeSource][nomTableColonne[1].replace('[$index]', '')][SCOPE_DESCRIPTIONS][nomTableColonne[2]];
                    } else {
                        desc = scope[scopeSource][nomTableColonne[1].replace('[$index]', '')][nomTableColonne[2]][SCOPE_DESCRIPTIONS];
                    }

                    scope.paramListePerso[idListeFixe].typeInteraction = desc.typeInteraction;
                }

                /**
                 * Init champ valeur
                 */
                if (angular.isNullOrUndefined(scope.listepersoListefixe[idListeFixe])) {
                    scope.listepersoListefixe[idListeFixe] = [];

                    var clef = (!angular.isNullOrUndefined(scope.entite) && !angular.isNullOrUndefined(scope.entite[attr.opModel.split('.')[1]])) ? scope.entite[attr.opModel.split('.')[1]].valeur : -1;
                    var idx = -1;

                    if (clef !== -1) {
                        for (var i = 0; i < scope.listes[tableListe].length; i++) {
                            if (scope.listes[tableListe][i][lvColonneCleFiltre].valeur === clef) {
                                idx = i;
                                break;
                            }
                        }
                    }

                    if (idx !== -1) {
                        var listeValeurs = scope.listes[tableListe][idx][lvColonneCle].valeur.split("|");

                        if (!angular.isNullOrUndefined(scope.listepersoListefixe[idListeFixe])) {
                            scope.listepersoListefixe[idListeFixe] = [];
                        }

                        for (var k = 0; k < listeValeurs.length; k++) {
                            var objValeur = {};
                            objValeur[nomTableColonneClef[2]] = clef;

                            var valeur = listeValeurs[k].split("#");
                            objValeur.valeur = valeur[0];
                            objValeur.libelle = valeur[0];
                            if (valeur.length > 1) {
                                objValeur.libelle = valeur[1];

                                if (valeur.length > 2) {
                                    objValeur.complementLibelle = valeur[2];

                                    if (valeur.length > 3) {
                                        objValeur.titre = valeur[3];
                                    }
                                }
                            }

                            if (angular.isNullOrUndefined(scope.listepersoListefixe[idListeFixe])) {
                                scope.listepersoListefixe[idListeFixe] = [];
                            }
                            scope.listepersoListefixe[idListeFixe].push(objValeur);
                        }

                        scope.paramListePerso[idListeFixe].listeFixeRadio = ((scope.listepersoListefixe[idListeFixe].length > 0) &&
                        (scope.listepersoListefixe[idListeFixe].length < 4));
                    }
                }

                /**
                 * Init liste champ valeur
                 */
                if (scope.paramListePerso[idListeFixe].listeFixe) {
                    for (var l = 0; l < scope.listepersoListefixe[idListeFixe].length; l++) {
                        var cle = scope.listepersoListefixe[idListeFixe][l].valeur;
                        var lib = scope.listepersoListefixe[idListeFixe][l].libelle;

                        if (estGrille) {
                            var tableGrilles = nomTableColonne[1].split('[');
                            var idxCle = -1;

                            for (var g = 0; g < scope.grilles[tableGrilles[0]].length; g++) {
                                if (scope.grilles[tableGrilles[0]][g][nomTableColonneClef[2]].valeur === scope.listepersoListefixe[idListeFixe][l][nomTableColonneClef[2]]) {
                                    idxCle = g;
                                    break;
                                }
                            }

                            if (idxCle !== -1) {
                                if (cle == scope.grilles[tableGrilles[0]][idxCle][nomTableColonne[2]].valeur) {
                                    scope.valeurRadio.valeur = cle;
                                    scope.valeurListe.valeur = lib;
                                    scope.valeurTypeahead.valeur = lib;
                                    break;
                                }
                            }
                        } else {
                            if (cle == scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur) {
                                scope.valeurRadio.valeur = cle;
                                scope.valeurListe.valeur = lib;
                                scope.valeurTypeahead.valeur = lib;
                                break;
                            }
                        }
                    }
                } /*else {
                    if (!angular.isNullOrUndefined(scope.listes[tableListe])) {
                        for (var j = 0; j < scope.listes[tableListe].length; j++) {
                            var cleListe = scope.listes[tableListe][j][lvColonneCle].valeur;
                            var libListe = scope.listes[tableListe][j][lvColonneLibelle].valeur;

                            if (estGrille) {
                                var nomTable = nomTableColonne[1].split('[');
                                var idxListe = -1;

                                for (var m = 0; m < scope.grilles[nomTable[0]].length; m++) {
                                    if (scope.grilles[nomTable[0]][m][nomTableColonneClef[2]].valeur === scope.listes[tableListe][j][nomTableColonneClef[2]]) {
                                        idxListe = m;
                                        break;
                                    }
                                }

                                if (idxListe !== -1) {
                                    if (cleListe == scope.grilles[nomTable[0]][idxListe][nomTableColonne[2]].valeur) {
                                        scope.valeurRadio.valeur = cleListe;
                                        scope.valeurListe.valeur = libListe;
                                        scope.valeurTypeahead.valeur = libListe;
                                        break;
                                    }
                                }
                            } else {
                                if (cleListe == scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur) {
                                    scope.valeurRadio.valeur = cleListe;
                                    scope.valeurListe.valeur = libListe;
                                    scope.valeurTypeahead.valeur = libListe;
                                    break;
                                }
                            }
                        }
                    }
                }*/

                // Statut renseignement du champ clef
                if (angular.isNullOrUndefined(scope.estClefRenseigne)) {
                    scope.estClefRenseigne = function (clef) {
                        return (!angular.isNullOrUndefined(clef) && (clef !== ""));
                    }
                }

                /**
                 * ngChange champ clef
                 */
                if (angular.isNullOrUndefined(scope.onChangeClef)) {
                    scope.onChangeClef = function (clef, index) {
                        var tableLibelle = scope.modelClefLibelle.split('.')[0].replace("[$index]", "");
                        var champLibelle = scope.modelClefLibelle.split('.')[1];
                        var valeurLibelle = null;
                        scope.clefRenseigne = (!angular.isNullOrUndefined(clef) && (clef !== ""));

                        if (scope.clefRenseigne) {
                            // Libellé du clé sélectionné
                            for (var j = 0; j < scope.listes[tableListeClef].length; j++) {
                                if (scope.listes[tableListeClef][j][champIdListeClef].valeur === clef) {
                                    valeurLibelle = scope.listes[tableListeClef][j][champLabelListeClef].valeur;
                                    break;
                                }
                            }

                            // Index dans la liste des clés qui permetttant de filtrer le champ valeur
                            var idx = -1;
                            for (var i = 0; i < scope.listes[tableListe].length; i++) {
                                if (scope.listes[tableListe][i][lvColonneCleFiltre].valeur === clef) {
                                    valeurLibelle = scope.listes[tableListe][i][champLabelListeClef].valeur;
                                    idx = i;
                                    break;
                                }
                            }

                            if (idx !== -1) {
                                var listeValeurs = scope.listes[tableListe][idx][lvColonneCle].valeur.split("|");

                                if (!angular.isNullOrUndefined(scope.listepersoListefixe[idListeFixe])) {
                                    scope.listepersoListefixe[idListeFixe] = [];
                                }

                                for (var k = 0; k < listeValeurs.length; k++) {
                                    var objValeur = {};
                                    objValeur[nomTableColonneClef[2]] = clef;

                                    var valeur = listeValeurs[k].split("#");
                                    objValeur.valeur = valeur[0];
                                    objValeur.libelle = valeur[0];
                                    if (valeur.length > 1) {
                                        objValeur.libelle = valeur[1];

                                        if (valeur.length > 2) {
                                            objValeur.complementLibelle = valeur[2];

                                            if (valeur.length > 3) {
                                                objValeur.titre = valeur[3];
                                            }
                                        }
                                    }

                                    if (angular.isNullOrUndefined(scope.listepersoListefixe[idListeFixe])) {
                                        scope.listepersoListefixe[idListeFixe] = [];
                                    }
                                    scope.listepersoListefixe[idListeFixe].push(objValeur);
                                }

                                scope.paramListePerso[idListeFixe].listeFixeRadio = ((scope.listepersoListefixe[idListeFixe].length > 0) &&
                                (scope.listepersoListefixe[idListeFixe].length < 4));
                            }

                            // Renseigner champ libellé
                            if (estGrille) {
                                if (!angular.isNullOrUndefined(scope.grilles[tableLibelle][index][champLibelle])) {
                                    scope.grilles[tableLibelle][index][champLibelle].valeur = valeurLibelle;
                                }
                            } else {
                                if (!angular.isNullOrUndefined(scope.champs[tableLibelle][champLibelle])) {
                                    scope.champs[tableLibelle][champLibelle].valeur = valeurLibelle;
                                }
                            }
                        } else {
                            // Reinit champ libellé
                            if (estGrille) {
                                if (!angular.isNullOrUndefined(scope.grilles[tableLibelle][index][champLibelle])) {
                                    scope.grilles[tableLibelle][index][champLibelle].valeur = valeurLibelle;
                                }
                            } else {
                                if (!angular.isNullOrUndefined(scope.champs[tableLibelle][champLibelle])) {
                                    scope.champs[tableLibelle][champLibelle].valeur = valeurLibelle;
                                }
                            }
                        }
                    }
                }

                /**
                 * ngChange champ valeur (datalist)
                 */
                if (angular.isNullOrUndefined(scope.onChangeValeurListe)) {
                    scope.onChangeValeurListe = function(idElement) {
                        var elem = document.getElementById(idElement + "_" + scope.$id);
                        var list = elem.getAttribute('list');
                        var options = document.querySelectorAll('#' + list + ' option');
                        var inputValue = elem.value;

                        for (var i = 0; i < options.length; i++) {
                            var option = options[i];
                            if (option.value === inputValue) {
                                if (!angular.isNullOrUndefined(scope.entite)) {
                                    if (scope.entite[nomTableColonne[2]][SCOPE_DESCRIPTIONS].type === "NUMBER") {
                                        scope.entite[nomTableColonne[2]].valeur = Number(option.getAttribute('data-id'));
                                    } else {
                                        scope.entite[nomTableColonne[2]].valeur = option.getAttribute('data-id');
                                    }
                                } else {
                                    if (scope.champs[nomTableColonne[1]][nomTableColonne[2]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                        scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur = Number(option.getAttribute('data-id'));
                                    } else {
                                        scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur = option.getAttribute('data-id');
                                    }
                                }

                                break;
                            }
                        }
                    }
                }

                /**
                 * Ouverture liste typeahead
                 */
                /*scope.typeaheadComparator = function (actualValue, viewValue) {
                    return viewValue === '[$empty$]' || ('' + actualValue).toLowerCase().indexOf(('' + viewValue).toLowerCase()) > -1;
                };

                if (angular.isNullOrUndefined(scope.ouvrirTypeaheadListePerso)) {
                    scope.ouvrirTypeaheadListePerso = function (id) {
                        $timeout(function () {
                            // TODO : trouver un moyen de déclencher le typeahead suite première passage ici sans sélection d'élément car dans ce cas val() est déjà à '' et triggerHandler est sans effet
                            // Vider l'input puis déclencher le typeahead
                            var idElem = scope.getIdentifiant('', '', id);
                            angular.element(document.querySelectorAll('#' + idElem)).val('').triggerHandler('input');
                            angular.element(document.querySelectorAll('#' + idElem)).val('').triggerHandler('change'); // for IE
                        });
                    }
                }*/

                /**
                 * typeaheadOnSelect champ valeur (typeahead)
                 */
                if (angular.isNullOrUndefined(scope.onChangeValeurTypeAhead)) {
                    scope.onChangeValeurTypeAhead = function(item) {
                        if (!angular.isNullOrUndefined(scope.entite)) {
                            if (scope.entite[nomTableColonne[2]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                scope.entite[nomTableColonne[2]].valeur = Number(item.valeur);
                            } else {
                                scope.entite[nomTableColonne[2]].valeur = item.valeur;
                            }
                        } else {
                            if (scope.champs[nomTableColonne[1]][nomTableColonne[2]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur = Number(item.valeur);
                            } else {
                                scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur = item.valeur;
                            }
                        }


                    }
                }

                /**
                 * ngChange champ valeur (boutons radio)
                 */
                if (angular.isNullOrUndefined(scope.onChangeValeurRadio)) {
                    scope.onChangeValeurRadio = function(valeur) {
                        if (!angular.isNullOrUndefined(scope.entite)) {
                            if (scope.entite[nomTableColonne[2]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                scope.entite[nomTableColonne[2]].valeur = Number(valeur);
                            } else {
                                scope.entite[nomTableColonne[2]].valeur = valeur;
                            }
                        } else {
                            if (scope.champs[nomTableColonne[1]][nomTableColonne[2]][SCOPE_DESCRIPTIONS][SCOPE_TYPE] === "NUMBER") {
                                scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur = Number(valeur);
                            } else {
                                scope.champs[nomTableColonne[1]][nomTableColonne[2]].valeur = valeur;
                            }
                        }
                    }
                }

                /**
                 * Affichage des boutons radio ?
                 */
                if (angular.isNullOrUndefined(scope.estRadio)) {
                    scope.estRadio = function (e) {
                        var nomElem = e + "_" + scope.$id;
                        return scope.paramListePerso[nomElem].listeFixeRadio;
                    };
                }

                /**
                 * La source des données est-elle une liste fixe ?
                 */
                /*if (angular.isNullOrUndefined(scope.estListeFixe)) {
                    scope.estListeFixe = function (e) {
                        var nomElem = e + "_" + scope.$id;
                        return scope.paramListePerso[nomElem].listeFixe;
                    };
                }*/

                /**
                 * Champ valeur datalist ?
                 */
                if (angular.isNullOrUndefined(scope.typeDatalist)) {
                    scope.typeDatalist = function (e) {
                        var nomElem = e + "_" + scope.$id;
                        return (scope.paramListePerso[nomElem].typeInteraction === "DATALIST");
                    };
                }

                /**
                 * Champ valeur typeahead ?
                 */
                if (angular.isNullOrUndefined(scope.typeTypeahead)) {
                    scope.typeTypeahead = function (e) {
                        var nomElem = e + "_" + scope.$id;
                        return (scope.paramListePerso[nomElem].typeInteraction === "TYPEAHEAD");
                    };
                }

                /**
                 * Identifiant unique
                 */
                if (angular.isNullOrUndefined(scope.getIdentifiant)) {
                    scope.getIdentifiant = function (prefixe, suffixe, e) {
                        return prefixe + e + "_" + scope.$id + suffixe;
                    };
                }

                /**
                 * Valeurs des listes fixes
                 */
                if (angular.isNullOrUndefined(scope.getDataListeFixe)) {
                    scope.getDataListeFixe = function (e) {
                        return scope.listepersoListefixe[e + "_" + scope.$id];
                    };
                }
            }
        };
    }])
    /*.directive('emptyListePerso', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {
                // this parser runs before typeahead's parser
                modelCtrl.$parsers.unshift(function (inputValue) {
                    var value = (inputValue ? inputValue : '[$empty$]'); // replace empty string with '[$empty$]' to bypass typeahead-min-length check
                    modelCtrl.$viewValue = value; // this $viewValue must match the inputValue pass to typehead directive
                    return value;
                });

                // this parser runs after typeahead's parser
                modelCtrl.$parsers.push(function (inputValue) {
                    return inputValue === '[$empty$]' ? '' : inputValue; // set '[$empty$]' back to empty string
                });
            }
        }
    })*/
    .directive('listePersoShowOnFocus', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                element.bind('focus', function () {
                    ngModel.$setViewValue();
                    element.triggerHandler('input');
                    element.triggerHandler('change');
                });
            }
        };
    })
    .directive('listePersoScrollOnClick', ['$ocLazyLoad', '$document', '$window', function($ocLazyLoad, $document, $window) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $ocLazyLoad.load({
                    serie: true, files: ['lib/jquery.min.js']
                }).then(function () {
                    $document.ready(function () {
                        element.on('click', function () {
                            if ($(element).offset().top > ($window.innerHeight * 0.20)) {
                                var posActuel = $document[0].body.scrollTop;
                                $("html, body").animate({scrollTop: posActuel + $(element).offset().top - 80}, "slow");
                            }
                        });
                    });
                });
            }
        }
    }]);
