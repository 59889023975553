/**
 * @version 1.0
 * @copyright 2019 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */
'use strict';

angular.module('fr.operis.moteurV4.controleurs.ConnexionAuto', [])
    .controller("fr.operis.moteurV4.controleurs.ConnexionAuto", [
     '$scope',
     'fr.operis.moteurV4.utils.Config',
     'fr.operis.moteurV4.utils.Cookie',
     'fr.operis.moteurV4.utils.ConnexionPortail',
     'fr.operis.moteurV4.communication.OperisWSServer', 
        function ($scope, config, cookie, connexionPortail, operisWSServer) {
            angular.element(document).ready(function () {
                // Analyse des paramètres en entrée
                var params = connexionPortail.extractUrlParams();
                
                if ((config.$config.AppliParamLoaded && config.$config.portail.auto)
                || cookie.get("portail")
                || cookie.get("logoutState")
                || cookie.get("rapprochement")
                || params["byPortail"]) {
                    operisWSServer.connexionComplete();
                }
                
                if (!config.$config.AppliParamLoaded) {
                    $scope.$on('AppliParamLoaded', function () {
                        if (config.$config.AppliParamLoaded && config.$config.portail.auto) {
                            operisWSServer.connexionComplete();
                        }
                    });
                }
            });
        }
    ]);