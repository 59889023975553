/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

/**
 * Teste si une valeur est nulle ou indéfinie.
 * @param {*} aValeur
 * @returns {Boolean} Vrai si la valeur est nulle ou indéfinie.
 */
angular.isNullOrUndefined = function (aValeur) {
    return aValeur === null || aValeur === undefined;
};

/**
 * Module permettant d'inclure tout le moteur facilement dans une application.
 * R�alise la configuration de base des modules utilisés par le moteur.
 */
angular.module('fr.operis.moteurV4', [
        'ngAnimate', 'ngRoute', 'ngSanitize'
        , 'angular.filter'
        , 'fr.operis.moteurV4.communication.OperisWSServer'
        , 'fr.operis.moteurV4.composants.planning.OpPlanning'
        , 'fr.operis.moteurV4.composants.OpChartBar'
        , 'fr.operis.moteurV4.composants.OpChartDoughnut'
        , 'fr.operis.moteurV4.composants.OpChartLine'
        , 'fr.operis.moteurV4.composants.OpChartPie'
        , 'fr.operis.moteurV4.composants.OpFormulaire'
        , 'fr.operis.moteurV4.composants.OpMosaique'
        , 'fr.operis.moteurV4.composants.OpOnglet'
        , 'fr.operis.moteurV4.composants.OpPopupEdition'
        , 'fr.operis.moteurV4.composants.OpTextInput'
        , 'fr.operis.moteurV4.composants.cartographie.OpCartographie'
        , 'fr.operis.moteurV4.composants.ecriture.OpAdresse'
        , 'fr.operis.moteurV4.composants.ecriture.OpContact'
        , 'fr.operis.moteurV4.composants.ecriture.OpListePerso'
        , 'fr.operis.moteurV4.composants.popup.OpPopupActionMetier'
        , 'fr.operis.moteurV4.composants.popup.OpPopupMail'
        , 'fr.operis.moteurV4.composants.popup.OpPopupDocumentsAttaches'
        , 'fr.operis.moteurV4.composants.popup.OpPopupMessagerie'
        , 'fr.operis.moteurV4.composants.popup.OpPopupSuppressionFiche'
        , 'fr.operis.moteurV4.composants.popup.OpPopupEnregistrementFiche'
        , 'fr.operis.moteurV4.composants.popup.OpPopupOptionExportCSV'
        , 'fr.operis.moteurV4.conteneurs.OpPopup'
        , 'fr.operis.moteurV4.controleurs.ConnexionAuto'
        , 'fr.operis.moteurV4.controleurs.ControleurBase'
        , 'fr.operis.moteurV4.controleurs.WarnCookie'
        , 'fr.operis.moteurV4.directives.OpGrille'
        , 'fr.operis.moteurV4.directives.OpGrilleSaisie'
        , 'fr.operis.moteurV4.directives.OpStopEvent'
        , 'fr.operis.moteurV4.filtres.OpOrderBy'
        , 'fr.operis.moteurV4.filtres.FiltreListe'
        , 'fr.operis.moteurV4.utils.Date'
        , 'fr.operis.moteurV4.utils.GestionRegroupement'
        , 'fr.operis.moteurV4.utils.RequeteNotificationInterne'
        , 'fr.operis.moteurV4.utils.RequetePreChargementFiche'
        , 'fr.operis.moteurV4.utils.RequetePreSauvegardeFiche'
        , 'fr.operis.moteurV4.utils.RequetePostSauvegardeFiche'
        , 'fr.operis.moteurV4.utils.RequetePreSuppressionFiche'
		, 'fr.operis.moteurV4.utils.RequetePostSuppressionFiche'
        , 'fr.operis.moteurV4.utils.RequetePostSauvegardeActionMetier'
        , 'fr.operis.moteurV4.utils.RequeteVerificationSuppressionFiche'
        , 'fr.operis.moteurV4.utils.Habilitation'
        , 'fr.operis.moteurV4.utils.Messagerie'
        , 'fr.operis.moteurV4.utils.RequeteModuleComplement'
        , 'fr.operis.moteurV4.utils.RequeteContact'
        , 'fr.operis.moteurV4.utils.UtilisateurContexteIHM'
        , 'fr.operis.moteurV4.utils.Config'
        , 'fr.operis.moteurV4.utils.Planning'
        , 'fr.operis.moteurV4.utils.Parametre'
        , 'fr.operis.moteurV4.utils.Statut'
        , 'fr.operis.moteurV4.utils.RefExterne'
        , 'fr.operis.moteurV4.utils.Historique'
        , 'fr.operis.moteurV4.utils.ModuleInfos'
        , 'fr.operis.moteurV4.utils.ActionInfos'
        , 'fr.operis.moteurV4.utils.Version'
        , 'fr.operis.moteurV4.utils.ReferenceInterne'
        , 'fr.operis.moteurV4.utils.ConnexionPortail'
        , 'fr.operis.moteurV4.utils.Geocodage'
        , 'moteur/composants/OpConnexion.html'
        , 'moteur/composants/OpConnexionPortail.html'
        , 'moteur/composants/OpMireAttente.html'
        , 'moteur/composants/OpPopupAjoutElement.html'
        , 'moteur/composants/cartographie/OpPopupFeature.html'
        , 'moteur/composants/ecriture/OpTypeahead.html'
        , 'moteur/composants/planning/OpPopupAfficherRessources.html'
        , 'moteur/composants/planning/OpPopupAjoutOccupation.html'
        , 'moteur/composants/planning/OpPopupAjoutTache.html'
        , 'moteur/composants/planning/OpPopupAjoutTacheDetaillee.html'
        , 'moteur/composants/planning/OpPopupAjoutTacheMateriau.html'
        , 'moteur/composants/planning/OpPopupAvancementTache.html'
        , 'moteur/composants/planning/OpPopupDetailEvenement.html'
        , 'moteur/composants/planning/OpPopupRecurrence.html'
        , 'moteur/composants/popup/OpModalImage.html'
        , 'moteur/composants/popup/OpPopupAlerte.html'
        , 'moteur/composants/popup/OpPopupDocumentsAttaches.html'
        , 'moteur/composants/popup/OpPopupEdition.html'
        , 'moteur/composants/popup/OpPopupEnregistrementFiche.html'
        , 'moteur/composants/popup/OpPopupMessagerie.html'
        , 'moteur/composants/popup/OpPopupSuppressionFiche.html'
        , 'operiaChat.chat'
        , 'rzModule'
        , 'ui.mask'
    ])
    .config(['$logProvider', '$httpProvider'
        , function ($logProvider, $httpProvider) {
            $logProvider.debugEnabled(window.location.hash === '#debug');
            $httpProvider.useApplyAsync(true);
        }
    ]);