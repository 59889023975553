/**
 * @version 1.0
 * @copyright 2019 Operis
 * @Author Laure-Hélène Bruneton, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Geocodage', [])
    .service('fr.operis.moteurV4.utils.Geocodage'
        , ['$q', '$http', 'fr.operis.moteurV4.utils.XML',
            function ($q, $http, xml) {
                // API URL
                var apiUrl = 'https://wxs.ign.fr/{{key}}/geoportail/ols?xls=';
                
                // Enumérations
                var countryCodes = {
                    StreetAddress : 'StreetAddress',
                    CadastralParcel: 'CadastralParcel'
                };
                
                var placeTypes = {
                    Commune : 'Commune',
                    Departement : 'Departement',
                    INSEE : 'INSEE'
                };
                
                /** Templates - Début */
                var FREEFORMADDRESS_TEMPLATE = '' +
                    '<freeFormAddress>{{address}}</freeFormAddress>';
                
                var BUILDING_TEMPLATE = '' +
                    '<Building number="{{number}}"/>';
                
                var STREETADDRESS_TEMPLATE = '' +
                    '<StreetAddress>' +
                    '  {{building}}' +
                    '  <Street>{{street}}</Street>' +
                    '</StreetAddress>';
                
                var PLACE_TEMPLATE = '' +
                    '<Place type="{{type}}">{{place}}</Place>';
                
                var POSTALCODE_TEMPLATE = '' +
                    '<PostalCode>{{postalCode}}</PostalCode>';
                
                var ENVELOPE_TEMPLATE = '' +
                    '<gml:envelope>' +
                    '  <gml:pos>{{latMin}} {{lonMin}}</gml:pos>' +
                    '  <gml:pos>{{latMax}} {{lonMax}}</gml:pos>' +
                    '</gml:envelope>';
                
                var CIRCLE_TEMPLATE = '' +
                    '<gml:CircleByCenterPoint>' +
                    '  <gml:pos>{{latitude}} {{longitude}}</gml:pos>' +
                    '  <gml:radius>{{radius}}</gml:radius>' +
                    '</gml:CircleByCenterPoint>';
                
                var REQUEST_TEMPLATE = '' +
                    '<Request requestID="1" version="1.2" methodName="LocationUtilityService">' +
                    '  <GeocodeRequest returnFreeForm="false">' +
                    '    <Address countryCode="{{countryCode}}">' +
                    '      {{address}}' +
                    '      {{filter}}' +
                    '    </Address>' +
                    '  </GeocodeRequest>' +
                    '</Request>';
                
                var REVERSE_REQUEST_TEMPLATE = '' +
                    '<Request requestID="abc" version="1.2" methodName="ReverseGeocodeRequest" maximumResponses="{{maxResponses}}">' +
                    '  <ReverseGeocodeRequest>' +
                    '    <ReverseGeocodePreference>{{preference}}</ReverseGeocodePreference>' +
                    '    <Position>' +
                    '      <gml:Point>' +
                    '        <gml:pos>{{latitude}} {{longitude}}</gml:pos>' +
                    '      </gml:Point>' +
                    '      {{circle}}' +
                    '    </Position>' +
                    '  </ReverseGeocodeRequest>' +
                    '</Request>';
                
                var XLS_TEMPLATE = '' +
                    '<?xml version="1.0" encoding="UTF-8"?>' +
                    '<XLS version="1.2"' +
                    '  xmlns:xls="http://www.opengis.net/xls"' +
                    '  xmlns:gml="http://www.opengis.net/gml"' +
                    '  xmlns="http://www.opengis.net/xls"' +
                    '  xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"' +
                    '  xsi:schemaLocation="http://www.opengis.net/xls http://schemas.opengis.net/ols/1.2/olsAll.xsd">' +
                    '    <RequestHeader/>' +
                    '    {{request}}' +
                    '</XLS>';
                /** Templates - Fin */
                
                /** Fonctions de remplacement - Début */
                var replaceRequest = function(aCountryCode, aAddress, aFilter) {
                    var request = REQUEST_TEMPLATE.replace(/\{\{countryCode\}\}/g, aCountryCode);
                    request = request.replace(/\{\{address\}\}/g, aAddress);
                    request = request.replace(/\{\{filter\}\}/g, aFilter);
                    return request;
                };
                
                var replaceReverseRequest = function(aPreference, aLatitude, aLongitude, aCircle, aMaxResponses) {
                    if (!aMaxResponses) { aMaxResponses = 10; }
                    var request = REVERSE_REQUEST_TEMPLATE.replace(/\{\{maxResponses\}\}/g, aMaxResponses);
                    request = request.replace(/\{\{preference\}\}/g, aPreference);
                    request = request.replace(/\{\{latitude\}\}/g, aLatitude);
                    request = request.replace(/\{\{longitude\}\}/g, aLongitude);
                    request = request.replace(/\{\{circle\}\}/g, aCircle);
                    return request;
                };
                
                var replaceAddress = function(aAddress) {
                     // freeForm et street sont exclusifs
                    var address = '';
                    if (aAddress) {
                        if (aAddress.freeForm) {
                            address = FREEFORMADDRESS_TEMPLATE.replace(/\{\{address\}\}/g, aAddress.freeForm);
                        } else if (aAddress.street) {
                            var building = '';
                            if (aAddress.number) {
                                building = BUILDING_TEMPLATE.replace(/\{\{number\}\}/g, aAddress.number);
                            }
                            
                            address = STREETADDRESS_TEMPLATE.replace(/\{\{building\}\}/g, building);
                            address = address.replace(/\{\{street\}\}/g, aAddress.street);
                        
                            // Municipality
                            if (aAddress.municipality) {
                                var municipality = PLACE_TEMPLATE.replace(/\{\{type\}\}/g, 'Municipality');
                                municipality = municipality.replace(/\{\{place\}\}/g, aAddress.municipality);
                                address += municipality;
                            }
                            // PostalCode
                            if (aAddress.postalCode) {
                                var postalCode = POSTALCODE_TEMPLATE.replace(/\{\{postalCode\}\}/g, aAddress.postalCode);
                                address += postalCode;
                            }
                        }
                    }
                    return address;
                };
                
                var replaceFilter = function(aFilter) {
                    // Les filtres peuvent se cumuler
                    var filter = '';
                    if (aFilter) {
                        // Envelope
                        if (aFilter.envelope) {
                            var envelope = ENVELOPE_TEMPLATE.replace(/\{\{latMin\}\}/g, aFilter.envelope.latMin);
                            envelope = envelope.replace(/\{\{lonMin\}\}/g, aFilter.envelope.lonMin);
                            envelope = envelope.replace(/\{\{latMax\}\}/g, aFilter.envelope.latMax);
                            envelope = envelope.replace(/\{\{lonMax\}\}/g, aFilter.envelope.lonMax);
                            filter += envelope;
                        }
                        // Places
                        if (aFilter.places) {
                            var places = '';
                            for (var indice in aFilter.places) {
                                var elem = aFilter.places[indice];
                                var place = PLACE_TEMPLATE.replace(/\{\{type\}\}/g, elem.type);
                                place = place.replace(/\{\{place\}\}/g, elem.place);
                                places += place;
                            }
                            filter += places;
                        }
                    }
                    return filter;
                };
                
                var replaceCircle = function(aLatitude, aLongitude, aRadius) {
                    var circle = '';
                    if (aRadius) {
                        circle = CIRCLE_TEMPLATE.replace(/\{\{latitude\}\}/g, aLatitude);
                        circle = circle.replace(/\{\{longitude\}\}/g, aLongitude);
                        circle = circle.replace(/\{\{radius\}\}/g, aRadius);
                    }
                    return circle;
                };
                /** Fonctions de remplacement - Fin */
                
                // Fonction d'envoi de la requête
                var sendRequest = function(aKey, aXls, aCallback) {
                    var deferred = $q.defer();
                    
                    // Requête HTTP
                    var keyUrl = apiUrl.replace(/\{\{key\}\}/g, aKey);
                    $http.get(keyUrl + encodeURI(aXls))
                    .then(
                        function(response) { 
                            var geocodedAddress = aCallback(response);
                            deferred.resolve(geocodedAddress);
                        }/*,
                        function(reason) { 
                            onError(reason);
                            deferred.resolve(reason);
                        }*/
                    );
                    
                    return deferred.promise;
                };
                
                // Fonction de traitement du résultat de géocodage
                var onGeocode = function(aResponse) {
                    var geocodeResponseList = xml.toJson(aResponse.data).XLS.Response.GeocodeResponse.GeocodeResponseList;
                    var numberOfGeocodedAddresses = geocodeResponseList._numberOfGeocodedAddresses;
                    
                    var result = {numberOfGeocodedAddresses: numberOfGeocodedAddresses};
                    
                    var geocodedAddress;
                    if (numberOfGeocodedAddresses == 1) {
                        geocodedAddress = geocodeResponseList.GeocodedAddress;
                    } else if (numberOfGeocodedAddresses > 1) {
                        geocodedAddress = geocodeResponseList.GeocodedAddress[0];
                    }
                    
                    if (geocodedAddress) {
                        result.position = geocodedAddress.Point.pos.__text;
                        result.accuracy = geocodedAddress.GeocodeMatchCode._accuracy;
                        result.matchType = geocodedAddress.GeocodeMatchCode._matchType; // undefined pour CadastralParcel
                    }
                    return result;
                };
                
                // Fonction de traitement du résultat de géocodage inverse à l'adresse
                var onReverseGeocodeStreetAddress = function(aResponse) {
                    var reverseGeocodedLocations = xml.toJson(aResponse.data).XLS.Response.ReverseGeocodeResponse.ReverseGeocodedLocation;
                    
                    var result = [];
                    for (var indice in reverseGeocodedLocations) {
                        var reverseGeocodedLocation = reverseGeocodedLocations[indice];
                    
                        var loc = {};
                        loc.address = extractAddress(reverseGeocodedLocation.Address);
                        loc.accuracy = reverseGeocodedLocation.ExtendedGeocodeMatchCode.__text;
                        loc.distance = reverseGeocodedLocation.SearchCentreDistance._value;
                        result.push(loc);
                    }
                    return result;
                };
                
                var extractAddress = function(aAddress) {
                    var address = '';
                    
                    if (aAddress) {
                        if (aAddress.StreetAddress) {
                            if (aAddress.StreetAddress.Building) {
                                address += aAddress.StreetAddress.Building._number + ' ';
                            }
                            address += aAddress.StreetAddress.Street + ' ';
                        }
                        if (aAddress.PostalCode) {
                            address += aAddress.PostalCode;
                        }
                    }
                    
                    return address;
                }
                
                // Fonction de traitement du résultat de géocodage inverse à la référence cadastrale
                var onReverseGeocodeCadastralParcel = function(aResponse) {
                    var reverseGeocodedLocations = xml.toJson(aResponse.data).XLS.Response.ReverseGeocodeResponse.ReverseGeocodedLocation;
                    
                    var result = [];
                    for (var indice in reverseGeocodedLocations) {
                        var reverseGeocodedLocation = reverseGeocodedLocations[indice];
                        
                        var loc = {};
                        loc.cadastralParcel = reverseGeocodedLocation.Address.StreetAddress.Street;
                        loc.distance = reverseGeocodedLocation.SearchCentreDistance._value;
                        result.push(loc);
                    }
                    return result;
                };
                
                // Fonction de traitement du résultat en erreur
                /*var onError = function(aReason) {
                    console.log('error');
                    console.log(aReason);
                }*/
                
                /** Fonctions du service */
                // Géocodage
                this.streetAddress = function (aKey, aAddress, aFilter) {
                    var address = replaceAddress(aAddress);
                    var filter = replaceFilter(aFilter);
                    
                    var request = replaceRequest(countryCodes.StreetAddress, address, filter);
                    var xls = XLS_TEMPLATE.replace(/\{\{request\}\}/g, request);
                    
                    return sendRequest(aKey, xls, onGeocode);
                };
                
                this.cadastralParcel = function (aKey, aCadastralParcel) {
                    var address = FREEFORMADDRESS_TEMPLATE.replace(/\{\{address\}\}/g, aCadastralParcel);
                    var filter = '';
                    
                    var request = replaceRequest(countryCodes.CadastralParcel, address, filter);
                    var xls = XLS_TEMPLATE.replace(/\{\{request\}\}/g, request);
                    
                    return sendRequest(aKey, xls, onGeocode);
                };
                
                // Géocodage Inverse
                this.reverseStreetAddress = function (aKey, aLatitude, aLongitude, aRadius, aMaxResponses) {
                    var circle = replaceCircle(aLatitude, aLongitude, aRadius);
                    var request = replaceReverseRequest(countryCodes.StreetAddress, aLatitude, aLongitude, circle, aMaxResponses);
                    var xls = XLS_TEMPLATE.replace(/\{\{request\}\}/g, request);
                    
                    return sendRequest(aKey, xls, onReverseGeocodeStreetAddress);
                };
                
                this.reverseCadastralParcel = function (aKey, aLatitude, aLongitude, aRadius, aMaxResponses) {
                    var circle = replaceCircle(aLatitude, aLongitude, aRadius);
                    var request = replaceReverseRequest(countryCodes.CadastralParcel, aLatitude, aLongitude, circle, aMaxResponses);
                    var xls = XLS_TEMPLATE.replace(/\{\{request\}\}/g, request);
                    
                    return sendRequest(aKey, xls, onReverseGeocodeCadastralParcel);
                };
            }
        ]
    );
