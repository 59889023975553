angular.module("moteur/composants/operiaVideo/operiaVideo.html", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("moteur/composants/operiaVideo/operiaVideo.html",
    "<style>#loading_state {\n" +
    "        position: absolute;\n" +
    "        top: 45%;\n" +
    "        left: 0px;\n" +
    "        width: 100%;\n" +
    "        font-size: 20px;\n" +
    "        text-align: center;\n" +
    "    }\n" +
    "    \n" +
    "    #local_video {\n" +
    "        position: absolute;\n" +
    "        top: 10px;\n" +
    "        left: 10px;\n" +
    "        height: 120px;\n" +
    "        background: #333333;\n" +
    "    }\n" +
    "    \n" +
    "    #remote_video {\n" +
    "        position: absolute;\n" +
    "        top: 0px;\n" +
    "        left: 0px;\n" +
    "        width: 100%;\n" +
    "        height: 100%;\n" +
    "        background: #999999;\n" +
    "    }\n" +
    "    \n" +
    "    .hidden {\n" +
    "        display: none;\n" +
    "    }\n" +
    "    \n" +
    "    @media screen and (max-width: 600px) {\n" +
    "        #local_video {\n" +
    "            height: 50px;\n" +
    "        }\n" +
    "    }</style><div id=\"loading_state\" ng-show=\"isConnecting\"><span>{{state}}</span></div><div id=\"open_call_state\" ng-click=\"toggleFullScreen($event)\"><video ng-class=\"{hidden: !isConnected}\" ng-src=\"{{streams.remote}}\" witdh=\"100px\" heigth=\"100px\" autoplay id=\"remote_video\"></video><video ng-class=\"{hidden: !isConnected}\" ng-src=\"{{streams.local}}\" witdh=\"100px\" heigth=\"100px\" autoplay muted id=\"local_video\"></video></div>");
}]);
