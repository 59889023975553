/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequetePreSuppressionFiche', [])

    .service('fr.operis.moteurV4.utils.RequetePreSuppressionFiche', ['$q',
        function ($q) {
            this._configuration={};

            // Configuration des requetes pre traitement
            this.configuration= function( aConfiguration ){
                this._configuration = aConfiguration;
            };

            // Execution des requetes pre traitement
            this.execution = function(aScope, aNomModule, aIdentifiant){
                var deferred = $q.defer();

                if ( angular.isNullOrUndefined(aNomModule) )
                    deferred.resolve();

                if (!angular.isNullOrUndefined(this._configuration[aNomModule.toUpperCase()])){
                    return this._configuration[aNomModule.toUpperCase()](aScope, aNomModule, aIdentifiant);
                }
                else
                    deferred.resolve();

                return deferred.promise;
            }
        }
    ]
);