/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

/**
 * Définit le type d'un objet Erreur.
 * @typedef {Object} fr.operis.moteurV4.utils.Erreur
 * @property {String} nom - Le nom de l'erreur.
 * @property {String} code - Le code de l'erreur.
 * @property {String} libelle - Le libellé de l'erreur.
 * @property {String} description - La description de l'erreur.
 * @property {String} codeServeur - Le code serveur de l'erreur si présent.
 * @property {String} libelleServeur - Le libellé serveur de l'erreur si présent.
 * @property {Object} referent - L'objet référent de l'erreur.
 * @property {Array} arguments - La liste des arguments liés à l'erreur.
 */

angular.module('fr.operis.moteurV4.utils.Erreur', []);

(function () {
    var erreurs = [
        {
            nom: "RETOUR_VIDE",
            code: "7001",
            libelle: "Le serveur n'a pas renvoyé de données.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur n'a pas renvoyé de donnée."
        },
        {
            nom: "RETOUR_ILLISIBLE",
            code: "7002",
            libelle: "Les données sont illisibles.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur a renvoyé des données que le client n'est pas capable d'interpréter."
        },
        {
            nom: "RETOUR_INVALIDE",
            code: "7003",
            libelle: "Les données sont invalides.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur a renvoyé des données invalides."
        },
        {
            nom: "JETON_INCOHERENT",
            code: "7004",
            libelle: "Le jeton n'est pas cohérent.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur a renvoyé un jeton incohérent par rapport à l'appel."
        },
        {
            nom: "FONCTION_INCOHERENTE",
            code: "7005",
            libelle: "La fonction n'est pas cohérente.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur a renvoyé le même libellé de fonction."
        },
        {
            nom: "ERREUR_SERVEUR",
            code: "7006",
            libelle: "Le serveur a renvoyé une erreur(code OPERIS {1}).",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur a renvoyé une erreur.",
            codeServeur: "{1}",
            libelleServeur: "{2}"
        },
        {
            nom: "ERREUR_INTERNE_SERVEUR",
            code: "7007",
            libelle: "Le serveur a renvoyé une erreur (code HTTP {1}).",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur a renvoyé une erreur d'exécution interne.",
            codeServeur: "{1}",
            libelleServeur: "{2}"
        },
        {
            nom: "SERVICE_SUPPRIME",
            code: "7008",
            libelle: "Le service {0} est marqué comme supprimé.",
            description: "L'appel du service {0} n'a pas abouti car le service a été supprimé."
        },
        {
            nom: "CONVERSION_IMPOSSIBLE",
            code: "7009",
            libelle: "La conversion est impossible.",
            description: "La conversion de la donnée depuis le type {0} et vers le type {1} est impossible pour l'objet {2}."
        },
        {
            nom: "TRANSACTION_AVORTEE",
            code: "7010",
            libelle: "La transaction {0} a été arrêtée à cause d'une erreur dans l'une des fonctions.",
            description: "La transaction {0} a été arrêtée à cause d'une erreur dans l'une des fonctions."
        },
        {
            nom: "DESCRIPTION_INVALIDE",
            code: "7011",
            libelle: "La description est invalide.",
            description: "La description des données renvoyée par le serveur n'est pas valide : {0}."
        },
        {
            nom: "DESCRIPTION_TABLE_INVALIDE",
            code: "7012",
            libelle: "La description de la table est invalide.",
            description: "La description des données renvoyée par le serveur pour la table {0} n'est pas valide : {1}."
        },
        {
            nom: "VALEURS_INVALIDES",
            code: "7013",
            libelle: "Les valeurs ne sont pas valides.",
            description: "Les données renvoyées par le serveur pour la table {1} ne sont pas valides : {0}."
        },
        {
            nom: "REQUETE_ANNULEE",
            code: "7014",
            libelle: "La requête a été annulée.",
            description: "La requête {0} a été annulée par l'utilisateur."
        },
        {
            nom: "REQUETE_TIMEOUT",
            code: "7015",
            libelle: "Le serveur n'a pas répondu après {1} secondes.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur n'a pas répondu après {1} secondes."
        },
        {
            nom: "REQUETE_INVALIDE",
            code: "7016",
            libelle: "Le serveur n'a pas été trouvé.",
            description: "L'appel de la fonction {0} n'a pas abouti car le serveur n'a été trouvé."
        },
        {
            nom: "PORTAILS_DESCRIPTION_INVALIDE",
            code: "7017",
            libelle: "Une erreur interne au serveur est survenue.",
            description: "La description des portails a renvoyé une erreur : {0}"
        },
        {
            nom: "PORTAIL_DESCRIPTION_INVALIDE",
            code: "7018",
            libelle: "Une erreur interne au serveur est survenue.",
            description: "La description du portail {0} a renvoyé une erreur : {1}"
        },
        {
            nom: "PORTAIL_UNKNOWN",
            code: "7019",
            libelle: "Le portail {0} n'est pas disponible.",
            description: "Le portail {0} n'a pas été décrit par le serveur."
        },
        {
            nom: "PORTAIL_AUTHORIZE_ERROR",
            code: "7020",
            libelle: "La réponse du portail contient une erreur.",
            description: "Libellé de l'erreur : {0}; Description de l'erreur : {1}"
        },
        {
            nom: "PORTAIL_UNVALID_STATE",
            code: "7021",
            libelle: "La réponse du portail n'est pas celle attendue.",
            description: "UID de la requête absent ou incohérent. Champs : {0}"
        },
        {
            nom: "PORTAIL_EMPTY_ANSWER",
            code: "7022",
            libelle: "Le portail {0} n'a pas renvoyé d'informations.",
            description: "Le portail {0} n'a pas renvoyé les informations attendues. Champs : {1}"
        }
    ];

    var module = angular.module('fr.operis.moteurV4.utils.Erreur');
    for (var i = 0; i < erreurs.length; ++i) {
        module.constant('fr.operis.moteurV4.utils.Erreur.' + erreurs[i].nom, erreurs[i].code);
    }

    module.controller('fr.operis.moteurV4.utils.Erreur.OpErreurControleur',
        ['$scope', '$modalInstance','message',
            function ($scope, $modalInstance, message ) {
                $scope.message=message;

                $scope.ok = function () {
                    $modalInstance.close();
                };
            }]);

    module.service('fr.operis.moteurV4.utils.Erreur', ['$log','$modal', function ($log,$modal) {
        /**
         * Création d'un objet erreur.
         * @param {String} aType Le type de l'erreur. S'il n'est pas trouvé, la fonction renvoie nul.
         * @param {Object} aReferent L'objet référent de l'erreur
         * @param {String} aParametres Les paramètres de l'erreur (autant que nécessaire)
         * @returns {fr.operis.moteurV4.utils.Erreur}
         */
        this.creer = function (aType, aReferent, aParametres) {
            var erreur = null;
            for (var i = 0; i < erreurs.length; ++i) {
                if (erreurs[i].code === aType) {
                    erreur = angular.copy(erreurs[i]);
                    break;
                }
            }
            if (!angular.isNullOrUndefined(erreur)) {
                erreur.referent = aReferent;
                erreur.arguments = [];
                for (var i = 2; i < arguments.length; ++i) {
                    var regExp = new RegExp("\\{" + (i - 2) + "\\}", "g");
                    var valeur = arguments[i];
                    if (angular.isNullOrUndefined(valeur)) valeur = '';

                    // On stocke l'argument
                    erreur.arguments.push(valeur);

                    // On convertit la valeur en chaine
                    if (typeof(valeur) !== "string") valeur = angular.toJson(valeur);

                    // On remplace les libellés de l'erreur
                    erreur.libelle = erreur.libelle || '';
                    erreur.libelle = erreur.libelle.replace(regExp, valeur);

                    erreur.description = erreur.description || '';
                    erreur.description = erreur.description.replace(regExp, valeur);

                    erreur.codeServeur = erreur.codeServeur || '';
                    erreur.codeServeur = erreur.codeServeur.replace(regExp, valeur);

                    erreur.libelleServeur = erreur.libelleServeur || '';
                    erreur.libelleServeur = erreur.libelleServeur.replace(regExp, valeur);
                }
                erreur.toString = function () {
                    return "Erreur : " +
                        "\n\tnom : " + erreur.nom +
                        "\n\tcode : " + erreur.code +
                        "\n\tlibelle : " + erreur.libelle +
                        "\n\tdescription : " + erreur.description +
                        "\n\tcodeServeur : " + erreur.codeServeur +
                        "\n\tlibelleServeur : " + erreur.libelleServeur;
                };
            }
            $log.debug("Création d'un objet " + erreur);

            if (  parseInt(erreur.codeServeur)< 1001 || parseInt(erreur.codeServeur)>1009 ){
                var popupOpErreur = $modal.open({
                    template: '<div class="modal-body">'+
                                    '<div>'+
                                        '<alert type="{{erreur.type}}">{{message}}</alert>'+
                                    '</div>'+
                                    '<form>'+
                                    '<div class="text-right">'+
                                    '<button class="btn btn-primary" type="button" ng-click="ok()">OK</button>'+
                                    '</div>'+
                                    '</form>'+
                                    '</div>'
                    ,
                    controller: 'fr.operis.moteurV4.utils.Erreur.OpErreurControleur',
                    size: 'sm',
                    resolve: {
                        message: function () {
                            return erreur.codeServeur + " : " + erreur.libelleServeur;
                        }
                    }
                });
            }

            return erreur;
        };
    }]);
})();
