/**
 * @version 1.0
 * @copyright 2016 Operis
 * @Author Laure-Hélène Bruneton, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Date', [])
    .service('fr.operis.moteurV4.utils.Date'
        , ['$ocLazyLoad',
            function ($ocLazyLoad) {

                /**
                 * Charge la librairie moment et s'en sert pour formater la date de maintenant.
                 * @param {String} aFormat Le format à afficher
                 * @param {Object} aRetour L'object dans lequel écrire la date selon le format.
                 */
                this.maintenantFormat = function (aFormat, aRetour) {
                    $ocLazyLoad.load({
                            serie: true, files: [
                                'lib/moment.min.js',
                                'lib/moment-fr.min.js'
                            ]
                        })
                        .then(function () {
                            aRetour.maintenantFormat = moment().format(aFormat);
                        })
                };
            }
        ]
    );
