/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.GestionActionFormulaire', ['fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.RequeteInterrogation',
        'fr.operis.moteurV4.utils.RequeteReferentiel'])

    /**
     * Nom de l'action de chargement d'écran secondaire
     * @type {String}
     * @value CHARGEMENT_SECONDAIRE
     */
    .constant('fr.operis.moteurV4.utils.GestionActionFormulaire.CHARGEMENT_SECONDAIRE', 'CHARGEMENT_SECONDAIRE')

    /**
     * Nom de l'action de recherche de numéro
     * @type {String}
     * @value RECHERCHE_NUMERO
     */
    .constant('fr.operis.moteurV4.utils.GestionActionFormulaire.RECHERCHE_NUMERO', 'RECHERCHE_NUMERO')

    /**
     * Nom de l'action d'aggrégation
     * @type {String}
     * @value AGGREGATION
     */
    .constant('fr.operis.moteurV4.utils.GestionActionFormulaire.AGGREGATION', 'AGGREGATION')

    /**
     * Nom de l'action d'aggrégation
     * @type {String}
     * @value AGGREGATION
     */
    .constant('fr.operis.moteurV4.utils.GestionActionFormulaire.AGGREGATIONTYPES', {
        sum: 'AGGREGATIONSUM',
        count: 'AGGREGATIONCOUNT',
        avg: 'AGGREGATIONAVG',
        min: 'AGGREGATIONMIN',
        max: 'AGGREGATIONMAX'
    })

    /**
     * Separateur action
     * @type {String}
     * @value |
     */
    .constant('fr.operis.moteurV4.utils.GestionActionFormulaire.separateurActionNiveau1', '|')

    .constant('fr.operis.moteurV4.utils.GestionActionFormulaire.separateurActionNiveau2', '#')

    .provider('fr.operis.moteurV4.utils.GestionActionFormulaire',
            function ( ) {

                // Association type d'action+identifantModele + valeur avec un ecran BD
                var _correspondanceActionEcran = {
                    'CHARGEMENT_SECONDAIRE_ANNUAIRE_ANN_NATURE_GROUPE': 'OperiaAnnuaire_Groupe',
                    'CHARGEMENT_SECONDAIRE_ANNUAIRE_ANN_NATURE_PERSONNE_PHYSIQUE': 'OperiaAnnuaire_PersonnePhysique',
                    'CHARGEMENT_SECONDAIRE_ANNUAIRE_ANN_NATURE_PERSONNE_MORALE': 'OperiaAnnuaire_PersonneMorale',
                    'CHARGEMENT_SECONDAIRE_ANNUAIRE_ANN_NATURE_ENTREPRISE': 'OperiaAnnuaire_Entreprise',
                    'CHARGEMENT_SECONDAIRE_PATRIMOINE_PAT_CATEGORIE_BATI_ERP': 'OperiaPatrimoine_ERP',
                    'CHARGEMENT_SECONDAIRE_PATRIMOINE_PAT_CATEGORIE_BATI_IGH': 'OperiaPatrimoine_IGH',
                    'CHARGEMENT_SECONDAIRE_PAT_BATIMENT_PATBAT_TYPE_BATI_ERP': 'OperiaPatrimoine_ERP',
                    'CHARGEMENT_SECONDAIRE_PAT_BATIMENT_PATBAT_TYPE_BATI_IGH': 'OperiaPatrimoine_IGH',
                    'CHARGEMENT_SECONDAIRE_PATRIMOINE_PAT_CATEG_BATI': 'OperiaPatrimoine_Batiment',
                    'CHARGEMENT_SECONDAIRE_PATRIMOINE_PAT_TYPE_BATI': 'OperiaPatrimoine_Bati',
                    'CHARGEMENT_SECONDAIRE_PATRIMOINE_PAT_CATEG_ESPACE_VERT': 'OperiaPatrimoine_EspaceVert',
                    'CHARGEMENT_SECONDAIRE_PAT_ESPACEVERT_PATEV_TYPE_ESPACE_ARBORE': 'OperiaPatrimoine_EspaceArbore',
                    'CHARGEMENT_SECONDAIRE_PATRIMOINE_PAT_CATEG_SITE': 'OperiaPatrimoine_Site',
                    'CHARGEMENT_SECONDAIRE_INT_MODELE_INT_MOD_ISPERIODIQUE_1': 'InterventionModelePeriodique',
                    'CHARGEMENT_SECONDAIRE_ANNUAIRE_ANN_ESTUTILISATEUR_1': 'OperiaUtilisateur'
                };

                // Configuration de la correspondance action ecran
                this.correspondanceActionEcran = function( aCorrespondance){
                    _correspondanceActionEcran = aCorrespondance;
                };

                /**
                 * Retourne le service communicationServlet.
                 * @param {injector} $injector
                 * @returns {fr.operis.moteurV4.utils.communicationServlet}
                 */
                this.$get = ['$injector', function ($injector) {
                    return $injector.instantiate([
                        'fr.operis.moteurV4.communication.OperisWSServer'
                        , 'fr.operis.moteurV4.communication.OperisWSServer.SEPARATEUR_INTERNE'
                        , 'fr.operis.moteurV4.utils.Conversion.TYPE_DATE'
                        , 'fr.operis.moteurV4.utils.RequeteInterrogation'
                        , 'fr.operis.moteurV4.utils.GestionActionFormulaire.separateurActionNiveau2'
                        , 'fr.operis.moteurV4.utils.GestionActionFormulaire.CHARGEMENT_SECONDAIRE'
                        , 'fr.operis.moteurV4.utils.GestionActionFormulaire.RECHERCHE_NUMERO'
                        , 'fr.operis.moteurV4.utils.GestionActionFormulaire.AGGREGATION'
                        , 'fr.operis.moteurV4.utils.GestionActionFormulaire.AGGREGATIONTYPES'
                        , '$q',
                        gestionActionFormulaire
                    ]);
                }
                ];

                function gestionActionFormulaire(serveur
                    , SEPARATEUR_INTERNE
                    , TYPE_DATE
                    , RequeteInterrogation
                    , separateurActionNiveau2
                    , CHARGEMENT_SECONDAIRE
                    , RECHERCHE_NUMERO
                    , AGGREGATION
                    , AGGREGATIONTYPES
                    , $q){
                    this.conversionValeur = function (valeur) {
                        var retourValeur;

                        if (!isNaN(valeur)) {
                            retourValeur = valeur.toString().replace(/[é,ê,è]/g, 'e');
                            retourValeur = retourValeur.toString().replace(/[à,â]/g, 'a');
                        } else {
                            retourValeur = valeur.replace(/[é,ê,è]/g, 'e');
                            retourValeur = retourValeur.replace(/[à,â]/g, 'a');
                        }

                        if (typeof(valeur) === "number")
                            return valeur.toString();

                        return retourValeur.toUpperCase().replace(/ /g, '_');
                    };

                    /**
                     * Fonction de vérification du type d'une table
                     * @param {Object} aScope Scope
                     * @param {String} aNomTable Nom de Table
                     * @return {Boolean} true/false
                     */
                    this.isTableChamps = function (aScope, aNomTable) {
                        var bResultat = false;

                        if (!angular.isNullOrUndefined(aScope.champs)) {
                            for (var nomTable in aScope.champs) {
                                if (nomTable === aNomTable)
                                    return true;
                            }
                        }

                        return bResultat;
                    };

                    /**
                     * Fonction de vérification du type d'une table
                     * @param {Object} aScope Scope
                     * @param {String} aNomTable Nom de Table
                     * @return {Boolean} true/false
                     */
                    this.isTableGrille = function (aScope, aNomTable) {
                        var bResultat = false;

                        if (!angular.isNullOrUndefined(aScope.grilles)) {
                            for (var nomTable in aScope.grilles) {
                                if (nomTable === aNomTable)
                                    return true;
                            }
                        }

                        return bResultat;
                    };

                    /**
                     * Fonction de récupération de la valeur de l'idModele
                     * @param {Object} aScope Scope
                     * @param {String} aIdentifiant Identifiant du modèle
                     * @return {String} Valeur
                     */
                    this.getValeur = function (aScope, aIdModele, index) {
                        var identifiant = aIdModele.split('.');
                        var nomTable = identifiant[0];
                        var nomColonne = identifiant[1];

                        if (nomTable.indexOf("[") == -1)
                            return aScope.champs[nomTable][nomColonne].valeur;
                        else {
                            nomTable = nomTable.replace('[$index]', '');
                            return aScope.grilles[nomTable][index][nomColonne].valeur;
                        }

                        return valeur;
                    };

                    /**
                     * Fonction de set de la valeur de l'idModele
                     * @param {Object} aScope Scope
                     * @param {String} aIdentifiant Identifiant du modèle
                     * @return {String} Valeur
                     */
                    this.setValeur = function (aScope, aIdModele, index, valeur) {
                        var identifiant = aIdModele.split('.');
                        var nomTable = identifiant[0];
                        var nomColonne = identifiant[1];

                        if (nomTable.indexOf("[") == -1)
                            aScope.champs[nomTable][nomColonne].valeur = valeur;
                        else {
                            nomTable = nomTable.replace('[$index]', '');
                            aScope.grilles[nomTable][index][nomColonne].valeur = valeur;
                        }
                    };



                    /**
                     * Fonction de récupération du nom de l'écran
                     * @param {Object} aScope Scope
                     * @param {String} action action
                     * @param {String} aIdentifiant Identifiant du modèle
                     * @return {Objet} Nom de l'écran et nom de l'action
                     */
                    this.getEcranNom = function (aAction, aScope, aIdModele) {
                        // Sélection de l'écran suivant l'action et la valeur du composant si action secondaire
                        var action = aAction;
                        var nomAction,ecran,nomEcran = [];
                        var arrayIdModele = aIdModele.split('.');
                        var nomTable = arrayIdModele[0];
                        var nomColonne = arrayIdModele[1];

                        if (aAction === CHARGEMENT_SECONDAIRE) {
                            var valeur = this.getValeur(aScope, aIdModele);
                            if (!angular.isNullOrUndefined(valeur)) {
                                if (!angular.isNullOrUndefined(_correspondanceActionEcran[nomTable + '_' + nomColonne])
                                    &&!angular.isNullOrUndefined(_correspondanceActionEcran[nomTable + '_' + nomColonne][valeur])) {
                                    ecran = _correspondanceActionEcran[nomTable + '_' + nomColonne][valeur];
                                    nomEcran.push(ecran);
                                }
                            }
                        }

                        return nomEcran;
                    };

                    /**
                     * Gere une action du formulaire
                     * @param {Objet} aScope Scope
                     * @param {Array} aAction Liste d'action à traiter
                     * @param {Array} actionsFormulaire Liste d'action (action/ecran) en cours d'un formulaire
                     */
                    this.gererAction = function (aScope, aNomModule, aAction, actionsFormulaire, actionsPreSauvegarde, isFormulaireSynthese) {
                        var ecranASupprimer = [];
                        var ecranACharger = [];
                        var grillesAggregate = {};

                        for (var iAction = 0; iAction < aAction.length; iAction++) {
                            var idModele = aAction[iAction].opModel;
                            var action = aAction[iAction].opAction.split(SEPARATEUR_INTERNE);
                            var index = aAction[iAction].index;
                            var nomAction = action[0] + '_' + idModele;
                            var ecranNom = this.getEcranNom(action[0], aScope, idModele);

                            if (nomAction.indexOf(CHARGEMENT_SECONDAIRE) != -1) {
                                // Ecran de chargement
                                if (!angular.isNullOrUndefined(actionsFormulaire[nomAction])) {
                                    var ecranEnCours = actionsFormulaire[nomAction].ecranNom;

                                    // Récupération des écrans/formulaire à supprimer
                                    for (var i = 0; i < ecranEnCours.length; i++) {
                                        var bEcranASupprimer = true;
                                        for (var j = 0; j < ecranNom.length; j++) {
                                            if (ecranEnCours[i] === ecranNom[j])
                                                bEcranASupprimer = false
                                        }
                                        if (bEcranASupprimer)
                                            ecranASupprimer.push(ecranEnCours[i]);
                                    }

                                    // Récupération des écrans/formulaire à charger
                                    for (var i = 0; i < ecranNom.length; i++) {
                                        var bEcranAjouter = true;
                                        for (var j = 0; j < ecranEnCours.length; j++) {
                                            if (ecranEnCours[j] === ecranNom[i])
                                                bEcranAjouter = false
                                        }
                                        if (bEcranAjouter)
                                            ecranACharger.push(ecranNom[i]);
                                    }
                                }
                                else {
                                    for (var i = 0; i < ecranNom.length; i++) {
                                        ecranACharger.push(ecranNom[i]);
                                    }
                                }

                                actionsFormulaire[nomAction] = {ecranNom: ecranNom};
                            }
                        }
                        return {
                            ACHARGER: ecranACharger,
                            ASUPPRIMER: ecranASupprimer
                        };
                    };


                    /**
                     * Gere l'action de gestion de 'est principal?'
                     * @param {Objet} aScope Scope
                     * @param aIdModele
                     * @param aIndex
                     */
                    this.gererEstPrincipal = function (aScope, aIdModele, aIndex) {
                        var identifiant = aIdModele.split('.');
                        var nomTable, nomColonne;
                        if (!angular.isNullOrUndefined(identifiant) && identifiant.length > 2) {
                            nomTable = identifiant[1];
                            nomColonne = identifiant[2];

                            // Parcours de la grille pour décocher la valeur principale
                            if (!angular.isNullOrUndefined(nomTable) && !angular.isNullOrUndefined(nomColonne)) {
                                var $index = aIndex;
                                if (nomTable.indexOf('[$index]') !== -1)
                                    nomTable = nomTable.replace('[$index]', "");
                                else
                                    $index = aScope.$parent.$parent.$index;

                                var grille = aScope.grilles[nomTable];
                                if (!angular.isNullOrUndefined(grille) && !angular.isNullOrUndefined($index)) {
                                    for (var i = 0; i < grille.length; i++) {
                                        if (i != $index && grille[i][nomColonne].valeur == 1)
                                            grille[i][nomColonne].valeur = 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    );