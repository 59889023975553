/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Brian Kjerulf, Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpModalImage',['ui.bootstrap'])
    .controller('fr.operis.moteurV4.composants.OpModalImageControleur',
    ['$scope', '$modalInstance', '$window', 'titre',
        function ($scope, $modalInstance, $window, titre) {
            $scope.titre = titre;
            $scope.monImage = "images/" + titre + ".jpg";
            $scope.altImage = titre + ".jpg";

            $scope.fermer = function (erreur) {
                $modalInstance.close();
            };
        }
    ]);

