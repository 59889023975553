/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Encryption', ['fr.operis.moteurV4.utils.Compression'])
    .service('fr.operis.moteurV4.utils.Encryption'
    , ['$window', 'fr.operis.moteurV4.utils.Compression'
        , function ($window, compression) {

            /**
             * Clé de cryptage pour le mot de passe
             * @type Array
             * @const
             */
            var ENCRYPTION_KEY = [15, 24, 32, 1, 48, 46, 75, 95];

            /**
             * Nombre d'itération sur la clé de cryptage pour le mot de passe
             * @type int
             */
            var ENCRYPTION_NB_ITERATION = 1000;

            /**
             * Convertit une chaine de caractères en un tableau d'octets UTF-8
             * @param {String} s La chaine de caractère à convertir
             * @returns {Array}
             */
            function toUTF8Array(s) {
                var position = -1,
                    len = s.length,
                    chr, buffer = [];
                if (/^[\x00-\x7f]*$/.test(s)) while (++position < len)
                    buffer.push(s.charCodeAt(position));
                else while (++position < len) {
                    chr = s.charCodeAt(position);
                    if (chr < 128)
                        buffer.push(chr);
                    else if (chr < 2048)
                        buffer.push((chr >> 6) | 192, (chr & 63) | 128);
                    else
                        buffer.push((chr >> 12) | 224, ((chr >> 6) & 63) | 128, (chr & 63) | 128);
                }
                return buffer;
            }

			function Uint8ToString(u8a){
				var CHUNK_SZ = 0x8000;
				var c = [];
				for (var i=0; i < u8a.length; i+=CHUNK_SZ) {
					c.push(String.fromCharCode.apply(null, u8a.subarray(i, i+CHUNK_SZ)));
				}
				return c.join("");
			}

            /**
             * Encryptage du mot de passe avec le login
             *
             * @param {String} aLogin Login correspondant au mot de passe à encrypter
             * @param {String} aMotDePasse Mot de passe à encrypter
             * @return {String} Le résultat de l'encryptage
             *
             */
            this.encryptageMotDePasse = function (aLogin, aMotDePasse) {
                var pwd, pwdArray, curIndex = 0, i = 0, j, tmp, ret;

                // On détermine la chaine à chiffrer
                pwd = toUTF8Array(aLogin.toLowerCase() + "/" + aMotDePasse + "/" + $window.Math.random().toFixed(10));

                // On compresse la chaine
                pwdArray = compression.deflate(pwd);

                // On effectue des permutations sur ce tableau
                for (; i < ENCRYPTION_NB_ITERATION; ++i) {
                    for (j = 0; j < ENCRYPTION_KEY.length - 1; ++j) {
                        curIndex += ENCRYPTION_KEY[j];

                        tmp = pwdArray[curIndex % pwdArray.length];
                        pwdArray[curIndex % pwdArray.length] = pwdArray[ENCRYPTION_KEY[j + 1] % pwdArray.length];
                        pwdArray[ENCRYPTION_KEY[j + 1] % pwdArray.length] = tmp;
                    }
                }

                // On transforme le résultat en base 64
                ret = btoa(String.fromCharCode.apply(null, pwdArray));

                return ret;
            };

            /**
             * Encryptage du mot de passe avec le login (mode BD)
             *
             * @param aLogin Login correspondant au mot de passe à encrypter
             * @param aMotDePasse MotDePasse à encrypter
             * @return Le résultat de l'encryptage
             *
             */
            this.encryptageBDMotDePasse = function encryptageBDMotDePasse(aLogin, aMotDePasse) {
                var loginpwd = aLogin.toLowerCase() + "/" + aMotDePasse;
                var uInt8 = new Uint8Array(loginpwd.length);
                for(var i=loginpwd.length; i--; )
                    uInt8[i] = loginpwd.charCodeAt(i);

                var pwdFlex = new Uint8Array(uInt8.length + 2);
                pwdFlex[0] = (uInt8.length >>> 8);
                pwdFlex[1] =  uInt8.length;
                for(var i=2 ; i < pwdFlex.length ; ++i) {
                    pwdFlex[i] = uInt8[i-2];
                }
                return sha256(pwdFlex);
            }
			
					
			/**
			 * Décryptage du mot de passe avec le login
			 * 
			 * @param aLogin Login correspondant au mot de passe à décrypter
			 * @param aMotDePasse MotDePasse à décrypter
			 * @return Le résultat du décryptage
			 * 
			 */
			this.decryptageMotDePasse = function (aLogin, aMotDePasse) {
				if(aLogin == null || aMotDePasse == null || aMotDePasse.length == 0)
					return "";
				
				var i = 0, j = 0;				
				
				// On décode en base 64
				var b64 = atob(aMotDePasse);
				
                var b = new Uint8Array(b64.length);
                for(var i=b64.length; i--; )
                    b[i] = b64.charCodeAt(i);
				
				if(b == null) return "";
				
				// On effectue des permutations sur ce tableau
				var curIndex = 0;
				for(i = 0; i < ENCRYPTION_NB_ITERATION ; ++i) {
					for(j = 0 ; j < ENCRYPTION_KEY.length - 1 ; ++j) {
						curIndex += ENCRYPTION_KEY[j];
					}
				}
				
				for(i = 0 ; i < ENCRYPTION_NB_ITERATION ; ++i) {
					for(j = ENCRYPTION_KEY.length - 2 ; j > -1 ; --j) {
						var tmp = b[curIndex % b.length];
						b[curIndex % b.length] = b[ENCRYPTION_KEY[j+1] % b.length];
						b[ENCRYPTION_KEY[j+1] % b.length] = tmp;
						
						curIndex -= ENCRYPTION_KEY[j];
					}
				}
				
				// On décompresse le tableau
				var results = Uint8ToString(compression.inflate(b)).split("/");        
				
				if(results.length != 3) {
					return "";
				}
				
				if(aLogin.toLowerCase() != results[0].toLowerCase()) {
					return "";
				}
				
				return results[1];
			};
        }
    ]
);