/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteEdition', ['fr.operis.moteurV4.communication.OperisWSServer'])

    .service('fr.operis.moteurV4.utils.RequeteEdition',
        ['$window','$http','$q','fr.operis.moteurV4.communication.OperisWSServer',
        function ($window,$http,$q,serveur) {

            // URL de la servlet
            this._servletUrl = window.location.protocol + '//' + window.location.hostname + ':' +
                window.location.port + window.location.pathname.replace("/Application","") + 'OperisOffice/Document';

            // Appel de la servlet et ouverture du résultat PDF
            this.ouvrirPDF = function(nomModule, idModele, entite){
                var idSession = serveur.getIdSession(nomModule);
                var url = this._servletUrl + '?idSession=' + idSession + '&idModele=' + idModele + '&idEntite=' + entite + '&methode=fusion&format=application/pdf&ouvrir';

                $window.open(url,"_blank");
            };
            
            // Appel de la servlet et téléchargement du résultat au format demandé
            this.telechargerDocument = function(nomModule, idModele, entite, format){
                var idSession = serveur.getIdSession(nomModule);
                var url = this._servletUrl + '?idSession=' + idSession + '&idModele=' + idModele + '&idEntite=' + entite + '&format=' + format + '&methode=fusion';

                $window.open(url,"_blank");
            };

            // Appel de la servlet et récupération du lien vers le résultat au format demandé
            this.recupererLien = function(nomModule, idModele, entite, format){
                var deferred = $q.defer();
                
                var paramsAppelServlet = {
                    idSession: serveur.getIdSession(nomModule),
                    idModele: idModele,
                    idEntite: entite,
                    format: format,
                    methode: 'fusion'
                };
                
                $http.get(this._servletUrl+ '?conserver', {
                    params: paramsAppelServlet
                }).then(function (response) {
                    deferred.resolve(response);
                });

                return deferred.promise;
            };
        }
    ]
);