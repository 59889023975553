/**
 * Created by Laurent Dorie on 12/01/2017
 */
'use strict';

angular.module('fr.operis.moteurV4.utils.RequeteNotificationInterne', [])
    /**
     * Nom de la table de configuration des notifications
     * @type {String}
     * @value OPE_NOTIFICATIONINTERNE
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.OPE_NOTIFICATIONINTERNE', 'OPE_NOTIFICATIONINTERNE')

    /**
     * Nom de l'écran de configuration des notifications
     * @type {String}
     * @value OPE_RechercheParametrageNotification
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.OPE_ECRANPARAMETRENOTIFICATION', 'OPE_RechercheParametrageNotification')

    /**
     * Nom de l'écran de l'entité
     * @type {String}
     * @value _RECHERCHEGENERALE
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.ECRANRECHERCHEGENERALE', '_RECHERCHEGENERALE')


    /**
     * Nom de l'écran des notifications
     * @type {String}
     * @value _RECHERCHENOTIFICATION
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.ECRANRECHERCHENOTIFICATION', '_RECHERCHENOTIFICATION')

    /**
     * Nom de la vue de recherche des notifications
     * @type {String}
     * @value _RECHERCHE_NOTIFICATION
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.VUERECHERCHENOTIFICATION', '_RECHERCHE_NOTIFICATION')

    /**
     * Nom de l'écran des notifications
     * @type {String}
     * @value _NOTIFICATION
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.ECRANNOTIFICATION', '_NOTIFICATIONINTERNE')


    /**
     * Nom de la table des notifications
     * @type {String}
     * @value _NOTIFICATIONINTERNE
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.TABLEENOTIFICATION', '_NOTIFICATIONINTERNE')


    /**
     * Nom d'identifiant du champs d'identifiant primaire de la table des notifications
     * @type {String}
     * @value _ID_NOTIFICATIONINTERNE
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDSECONDAIRE', '_ID_NOTIFICATIONINTERNE')

    /**
     * Nom du champs d'état de la table des notifications
     * @type {String}
     * @value _NOT_ETAT_NOTIF
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSETAT', '_NOT_ETAT_NOTIF')

    /**
     * Nom du champs de titre de la table des notifications
     * @type {String}
     * @value _NOT_TITRE
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSTITRE', '_NOT_TITRE')

    /**
     * Nom du champs de contenu de la table des notifications
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSCONTENU', '_NOT_CONTENU')

    /**
     * Nom du champs d'état de la table des notifications
     * @type {String}
     * @value _NOT_ETAT_NOTIF
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSDATECREATION', '_NOT_DATECREATION')

    /**
     * Nom du champs de titre de la table des notifications
     * @type {String}
     * @value _NOT_TITRE
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSDATEENVOIE', '_NOT_DATEENVOI')

    /**
     * Nom du champs de contenu de la table des notifications
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDEXPEDITEUR', '_NOT_ID_EXPEDITEUR')

    /**
     * Nom du champs de contenu de la table des notifications
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDDESTINATAIRE', '_NOT_ID_DESTINATAIRE')

    /**
     * Nom du champs de contenu de la table des notifications
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDSERVICEDESTINATAIRE', '_NOT_ID_SERVICEDESTINATAIRE')

    /**
     * Nom du champs de contenu de la table des notifications
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSAUTOMATIQUE', '_NOT_ID_AUTOMATIQUE')

    /**
     * Nom du champs de contenu de la table des entité
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.GRIL_REFINT', '_GRIL_REFINT')

    /**
     * Nom  de la table des entité
     * @type {String}
     * @value _NOT_CONTENU
     */
    .constant('fr.operis.moteurV4.utils.requeteNotificationInterne.TABLEENTITE', '_RECHERCHE_GNL')


    /**
     * Service de notifications internes
     */
    .service('fr.operis.moteurV4.utils.RequeteNotificationInterne',[
        '$modal', '$q', 'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.OPE_NOTIFICATIONINTERNE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.OPE_ECRANPARAMETRENOTIFICATION',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.ECRANRECHERCHEGENERALE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.ECRANRECHERCHENOTIFICATION',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.VUERECHERCHENOTIFICATION',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.ECRANNOTIFICATION',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.TABLEENOTIFICATION',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDSECONDAIRE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSETAT',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSTITRE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSCONTENU',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSDATECREATION',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSDATEENVOIE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDEXPEDITEUR',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDDESTINATAIRE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSIDSERVICEDESTINATAIRE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.CHAMPSAUTOMATIQUE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.TABLEENTITE',
        'fr.operis.moteurV4.utils.requeteNotificationInterne.GRIL_REFINT',
        function ($modal, $q, serveur,
                  moduleInfos,
                  OPE_NOTIFICATIONINTERNE,
                  OPE_ECRANPARAMETRENOTIFICATION,
                  ECRANRECHERCHEGENERALE,
                  ECRANRECHERCHENOTIFICATION,
                  VUERECHERCHENOTIFICATION,
                  ECRANNOTIFICATION,
                  TABLEENOTIFICATION,
                  CHAMPSIDSECONDAIRE,
                  CHAMPSETAT,
                  CHAMPSTITRE,
                  CHAMPSCONTENU,
                  CHAMPSDATECREATION,
                  CHAMPSDATEENVOIE,
                  CHAMPSIDEXPEDITEUR,
                  CHAMPSIDDESTINATAIRE,
                  CHAMPSIDSERVICEDESTINATAIRE,
                  CHAMPSAUTOMATIQUE,
                  TABLEENTITE,
                  GRIL_REFINT) {

            var _notificationAutomatique;

            /**
             * Fonction de recherche des notifications internes
             * @param {Object} aScope Scope
             * @param {String} aNomModule Nom du module
             * @param {Boolean} aEtat Etat de notification (0: non lue, 1 lue)
             * @return
             */
            this.recherche = function (aNombreItemPerPage, aScope, aNomModule, aEtat, aEntite, aOccurence, aIdExpediteur){
                var deferred = $q.defer();

                var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");
                var entiteNom = moduleInfos.getInfo(aNomModule, "MOD_ENTITE");
                var ecranNom = trigramme + ECRANRECHERCHENOTIFICATION;
                var tableRecherche = 'VL_' + trigramme + VUERECHERCHENOTIFICATION;

                serveur.chargement(aNomModule, aScope, ecranNom,null).then(function (retourChargement) {
                    // Etat non lu de la notification
                    if (!angular.isNullOrUndefined(aEtat) && aEtat )
                        aScope.champs[tableRecherche].ETAT.valeur = 0;

                    // Entite
                    if (!angular.isNullOrUndefined(aEntite)  )
                        aScope.champs[tableRecherche][entiteNom].valeur = aEntite[entiteNom];

                    // Création de la recherche de page de notification
                    aScope.recherchePageNotification = function (aNumeroPage){
                        return aScope.recherchePage[ecranNom](aNumeroPage);
                    };

                    // Occurence
                    if ( !angular.isNullOrUndefined(aOccurence)&& aOccurence.length>0){
                        aScope.champs[tableRecherche].TITRE.valeur = aOccurence;
                        aScope.champs[tableRecherche].CONTENU.valeur = aOccurence;
                    };

                    // Expediteur
                    if ( !angular.isNullOrUndefined(aIdExpediteur)){
                        aScope.champs[tableRecherche].ID_EXPEDITEUR.valeur = aIdExpediteur;
                    }else{
                        aScope.champs[tableRecherche].ID_DESTINATAIRE.valeur = parseInt(serveur.getIdUtilisateur(aNomModule)());
                    }

                    // Recherche
                    aScope.recherche[ecranNom](aNombreItemPerPage)
                        .then(function(retourRecherche){
                            deferred.resolve(retourRecherche);
                        }, function(erreur){$q.reject(erreur)});
                }, function(erreur){deferred.reject(erreur)});

                return deferred.promise;
            };

            /**
             * Fonction de marquer comme lu/non lue des notification
             * @param {Object} aScope Scope
             * @param {String} aNomModule Nom du module
             * @param {Object} aNotification Notification
             * @param {Boolean} aEtat Etat de notification (0: non lue, 1 lue)
             * @return
             */
            this.marquerLuNotification = function (aScope, aNomModule, aNotification, aEtat){
                var deferred = $q.defer();

                var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");
                var entite = moduleInfos.getInfo(aNomModule, "MOD_ENTITE");
                var nomEntiteSecondaire = trigramme + CHAMPSIDSECONDAIRE;
                var ecranNom = trigramme + ECRANNOTIFICATION;
                var tableNom = trigramme + TABLEENOTIFICATION;
                var champsEtatNom = trigramme + CHAMPSETAT;
                var entiteNotification = {};
                entiteNotification[entite] = aNotification[entite].valeur;
                entiteNotification[nomEntiteSecondaire] = aNotification[nomEntiteSecondaire].valeur;

                serveur.chargement(aNomModule, aScope, ecranNom, entiteNotification).then(function (retourChargement) {
                    aScope.champs[tableNom][champsEtatNom].valeur = (aEtat)?1:0;
                    aScope.sauvegarde[ecranNom]()
                        .then(function(retourSauvegarde){deferred.resolve(aNotification)}
                            ,function(erreur){deferred.reject(erreur)})

                },function(erreur){deferred.reject(erreur)})
                return deferred.promise;
            };

            /**
             * Mantis 5819 : Préparer une notification pour enregistrement ultérieure
             * @param {Object} aScope Scope de la notification
             * @param {String} aNomModule Nom du module concerné
             * @param {Number} aEntite Id de l'entité concerné par la notification
             * @param {String} aTitre Titre de la notification
             * @param {String} aContenu Text de la notification
             * @param {Boolean} aAutomatique Notification générée de manière automatique ?
             * @param {Number} aIdDestintaire Id du destinataire ciblé par la notification
             * @param {Number} aIdServiceDestinaire Id du service destinataire ciblé par la notification
             * @return {*}
             */
            this.preparerNotification = function (aScope, aNomModule, aEntite, aTitre, aContenu, aAutomatique, aIdDestintaire, aIdServiceDestinaire) {
                var deferred = $q.defer();

                var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");
                var entite = moduleInfos.getInfo(aNomModule, "MOD_ENTITE");
                var nomEntiteSecondaire = trigramme + CHAMPSIDSECONDAIRE;
                var ecranNom = trigramme + ECRANNOTIFICATION;

                var identifiant = {};
                identifiant[entite] = aEntite[entite];
                identifiant[nomEntiteSecondaire] = "";

                var ecranEntite = trigramme + ECRANRECHERCHEGENERALE;

                serveur.chargement(aNomModule, aScope, ecranEntite, identifiant).then(function (retourChargement) {
                    serveur.chargement(aNomModule, aScope, ecranNom, identifiant).then(function (retourChargement) {
                        var tableNom = trigramme + TABLEENOTIFICATION;
                        var champsEtatNom = trigramme + CHAMPSETAT;
                        var champsTitreNom = trigramme + CHAMPSTITRE;
                        var champsContenuNom = trigramme + CHAMPSCONTENU;
                        var champsDateCreationNom = trigramme + CHAMPSDATECREATION;
                        var champsDateEnvoieNom = trigramme + CHAMPSDATEENVOIE;
                        var champsIdExpediteurNom = trigramme + CHAMPSIDEXPEDITEUR;
                        var champsIdDestinataire = trigramme + CHAMPSIDDESTINATAIRE;
                        var champsIdServiceDestinataire = trigramme + CHAMPSIDSERVICEDESTINATAIRE;
                        var champsAutomtiqueNom = trigramme + CHAMPSAUTOMATIQUE;

                        aScope.champs[tableNom][champsEtatNom].valeur = 0;
                        aScope.champs[tableNom][champsTitreNom].valeur = '['+ aNomModule + ']' + aTitre;
                        aScope.champs[tableNom][champsContenuNom].valeur = aContenu;
                        aScope.champs[tableNom][champsDateCreationNom].valeur = new Date();
                        aScope.champs[tableNom][champsDateEnvoieNom].valeur = new Date();
                        aScope.champs[tableNom][champsIdExpediteurNom].valeur = parseInt(serveur.getIdUtilisateur(aNomModule)());
                        aScope.champs[tableNom][champsAutomtiqueNom].valeur = aAutomatique? 1: 0 ;
                        aScope.champs[tableNom][champsIdDestinataire].valeur = aIdDestintaire ;
                        aScope.champs[tableNom][champsIdServiceDestinataire].valeur = aIdServiceDestinaire ;

                        deferred.resolve({ecranNom: ecranNom, tableNom: tableNom, identifiant: identifiant, notification: aScope.champs[tableNom]});
                    }, function(erreur) {
                        deferred.reject(erreur)
                    })
                }, function(erreur){
                    deferred.reject(erreur)
                });

                return deferred.promise;
            };

            /**
             * Mantis 5819 : Envoyer une notification déjà préparée
             * @param {Object} aScope Scope de la notification à envoyer
             * @param {String} aNomModule Module concerné
             * @param {Object} aNotification La notification à envoyer
             */
            this.envoyerNotification = function (aScope, aNomModule, aNotification) {
                var deferred = $q.defer();

                serveur.chargement(aNomModule, aScope, aNotification.ecranNom, aNotification.identifiant).then(function (retourChargement) {
                    aScope.champs[aNotification.tableNom] = aNotification.notification;

                    aScope.sauvegarde[aNotification.ecranNom]().then(function (retourSauvegarde) {
                        deferred.resolve()
                    }, function (erreurSauvegarde) {
                        deferred.reject(erreurSauvegarde)
                    });
                }, function (erreurChargement) {
                    deferred.reject(erreurChargement)
                });

                return deferred.promise;
            };

            /**
             * Fonction de création de notification
             * @param {Object} aScope Scope
             * @param {String} aNomModule Nom du module
             * @param {Object} aEntite Entite
             * @return
             */
            this.ajouterNotification = function (aScope, aNomModule, aEntite, aTitre, aContenu, aAutomatique, aIdDestintaire, aIdServiceDestinaire) {
                var deferred = $q.defer();

                var trigramme = moduleInfos.getInfo(aNomModule, "MOD_TRIGRAMME");
                var entite = moduleInfos.getInfo(aNomModule, "MOD_ENTITE");
                var nomEntiteSecondaire = trigramme + CHAMPSIDSECONDAIRE;
                var ecranNom = trigramme + ECRANNOTIFICATION;
                var tableNom = trigramme + TABLEENOTIFICATION;
                var champsEtatNom = trigramme + CHAMPSETAT;
                var champsTitreNom = trigramme + CHAMPSTITRE;
                var champsContenuNom = trigramme + CHAMPSCONTENU;
                var champsDateCreationNom = trigramme + CHAMPSDATECREATION;
                var champsDateEnvoieNom = trigramme + CHAMPSDATEENVOIE;
                var champsIdExpediteurNom = trigramme + CHAMPSIDEXPEDITEUR;
                var champsIdDestinataire = trigramme + CHAMPSIDDESTINATAIRE;
                var champsIdServiceDestinataire = trigramme + CHAMPSIDSERVICEDESTINATAIRE;
                var champsAutomtiqueNom = trigramme + CHAMPSAUTOMATIQUE;

                var identifiant = {};
                identifiant[entite] = aEntite[entite];
                identifiant[nomEntiteSecondaire] = "";

                var ecranEntite = trigramme + ECRANRECHERCHEGENERALE;
                serveur.chargement(aNomModule, aScope, ecranEntite, identifiant).then(function(retourChargement){
                    serveur.chargement(aNomModule, aScope, ecranNom, identifiant).then(function (retourChargement) {
                        var referenceNom = trigramme + GRIL_REFINT;
                        var tableEntiteNom = 'VL_' + trigramme + TABLEENTITE;
                        var reference = aScope.champs[tableEntiteNom][referenceNom].valeur;

                        aScope.champs[tableNom][champsEtatNom].valeur = 0;
                        aScope.champs[tableNom][champsTitreNom].valeur = '['+ aNomModule + ']' + aTitre;
                        aScope.champs[tableNom][champsContenuNom].valeur = aContenu;
                        aScope.champs[tableNom][champsDateCreationNom].valeur = new Date();
                        aScope.champs[tableNom][champsDateEnvoieNom].valeur = new Date();
                        aScope.champs[tableNom][champsIdExpediteurNom].valeur = parseInt(serveur.getIdUtilisateur(aNomModule)()) ;
                        aScope.champs[tableNom][champsAutomtiqueNom].valeur = aAutomatique? 1: 0 ;
                        aScope.champs[tableNom][champsIdDestinataire].valeur = aIdDestintaire ;
                        aScope.champs[tableNom][champsIdServiceDestinataire].valeur = aIdServiceDestinaire ;

                        aScope.sauvegarde[ecranNom]()
                            .then(function(retourSauvegarde){deferred.resolve()}
                                ,function(erreur){deferred.reject(erreur)})

                    },function(erreur){deferred.reject(erreur)})
                },function(erreur){deferred.reject(erreur)});

                return deferred.promise;
            };

            /**
             * Fonction de création de notification
             * @param {Object} aScope Scope
             * @param {String} aNomModule Nom du module
             * @param {String} aIdAction Identifiant de l'action
             * @return
             */
            this.getParametrageNotificiation = function(aScope, aNomModule, aIdAction){
                var deferred = $q.defer();

                serveur.chargement( "Operia", aScope, OPE_ECRANPARAMETRENOTIFICATION, null)
                    .then(function(retourChargement){
                        aScope.champs[OPE_NOTIFICATIONINTERNE].ID_ACTION.valeur = aIdAction;
                        aScope.champs[OPE_NOTIFICATIONINTERNE].IDENT_MODULE.valeur = parseInt(serveur.getIdModule(aNomModule));
                        aScope.recherche[OPE_ECRANPARAMETRENOTIFICATION](1)
                            .then(function(retourRecherche){
                                if ( !angular.isNullOrUndefined( aScope.grilles[OPE_NOTIFICATIONINTERNE] ) && aScope.grilles[OPE_NOTIFICATIONINTERNE].length > 0 ){
                                    var configuration = aScope.grilles[OPE_NOTIFICATIONINTERNE][0];
                                    deferred.resolve( {
                                        TITRE:configuration.TITRE.valeur,
                                        CONTENU:configuration.CONTENU.valeur
                                    });
                                }else{deferred.reject('Aucune table de notification')}
                            },function(erreur){deferred.reject(erreur)})
                    },function(erreur){deferred.reject(erreur)})


                return deferred.promise;
            }
        }]);


