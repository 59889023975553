/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Brian Kjerulf, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Planning', [])
    .provider('fr.operis.moteurV4.utils.Planning', [function () {

        var configPlanning;

        // Configuration des parametres du planning
        this.setParametrePlanning = function(parametre){
            configPlanning = parametre;
        };

        /**
         * Retourne le service Planning.
         * @param {injector} $injector
         * @returns {fr.operis.moteurV4.utils.Planning}
         */
        this.$get = ['$injector', function ($injector) {
            return $injector.instantiate([
                planning
            ]);
        }
        ];

        function planning() {
            /**
             * Fonction qui retourne la valeur d'un paramètre
             * @param nomModule Nom du module
             * @param nomParametre Nom du paramètre
             * @returns {*}
             */
            this.lireParametrePlanning = function(nomModule, codeAction, nomParametre) {
                if (!angular.isNullOrUndefined(nomModule) && !angular.isNullOrUndefined(codeAction)) {
                    if (!angular.isNullOrUndefined(configPlanning[nomModule.toUpperCase() + "_" + codeAction.toUpperCase()])) {
                        if (!angular.isNullOrUndefined(nomParametre)) {
                            return configPlanning[nomModule.toUpperCase() + "_" + codeAction.toUpperCase()][nomParametre];
                        } else {
                            return configPlanning[nomModule.toUpperCase() + "_" + codeAction.toUpperCase()];
                        }
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            }


            // Passage en majuscule en tenant compte des accents
            function majuscule(texte)
            {
                var minus = "aàâäbcçdeéèêëfghiîïjklmnoôöpqrstuùûvwxyz";
                var majus = "AAAABCCDEEEEEFGHIIIJKLMNOOOPQRSTUUUVWXYZ";
                var entree = texte;
                var sortie = "";

                for (var i = 0 ; i < entree.length ; i++)
                {
                    var car = entree.substr(i, 1);
                    sortie += (minus.indexOf(car) != -1) ? majus.substr(minus.indexOf(car), 1) : car;
                }

                return sortie;
            }

            // Mantis 6160 Gestion exceptions affichage actions
            this.desactiverActionSelonRecherche = function (nomModule, actionCode, nomEcranRecherche) {
                if ((nomModule === "Intervention") && ((actionCode === "PLANIFIER") || (actionCode === "GERERRECURRENCE"))) {
                    return (nomEcranRecherche === "RECH_INT_MODELE");
                } else {
                    return false;
                }
            };

            // Type de planning selon module et action
            this.getTypePlanning = function (nomModule, actionCode) {
                if (nomModule === "Intervention") {
                    if (actionCode === "GERERTACHE") {
                        return "tache";
                    } else if (actionCode === "GERERRECURRENCE") {
                        return "recurrence";
                    } else if (actionCode === "PLANIFIER") {
                        return "recherche";
                    }
                } else if (nomModule === "Tache") {
                    if (actionCode === "GERERTACHE") {
                        return "tache";
                    } else if (actionCode === "PLANIFIER") {
                        return "recherche";
                    }
                } else if (nomModule === "Contact") {
                    if (actionCode === "GERERDISPONIBILITE") {
                        return "contact";
                    } else if (actionCode === "PLANIFIER") {
                        return "recherche";
                    }
                } else {
                    return null;
                }
            };

            // Nom de table principale de la fiche selon module et action
            this.getNomTableFiche = function (nomModule, actionCode) {
                if (nomModule === "Intervention") {
                    if (actionCode === "GERERTACHE") {
                        return "INTERVENTION";
                    } else if (actionCode === "GERERRECURRENCE") {
                        return "INTERVENTION";
                    }
                } else if (nomModule === "Tache") {
                    if (actionCode === "GERERTACHE") {
                        return "TACHE";
                    }
                } else if (nomModule === "Contact") {
                    if (actionCode === "GERERDISPONIBILITE") {
                        return "CONTACT";
                    }
                } else {
                    return null;
                }
            };

            // Nom de champ principale selon module et action
            this.getNomChampPrincipal = function (nomModule, actionCode) {
                if (nomModule === "Intervention") {
                    if (actionCode === "GERERTACHE") {
                        return "INT_OBJET";
                    } else if (actionCode === "GERERRECURRENCE") {
                        return "INT_OBJET";
                    }
                } else if (nomModule === "Tache") {
                    if (actionCode === "GERERTACHE") {
                        return "TAC_OBJET";
                    }
                } else if (nomModule === "Contact") {
                    if (actionCode === "GERERDISPONIBILITE") {
                        return "NOM_COMPLET";
                    }
                } else {
                    return null;
                }
            };

            // Actions metiers par type de planning
            this.getActionsPlanning = function () {
                return [
                    {typePlanning: "tache", actionCode: "CREERTACHESIMPLE", actionLibelle: "Créer tâche simple", actionCommentaire:"créer une tâche simple", actionIcone:"actions/op_action_creer.svg", actionType: 1},
                    {typePlanning: "tache", actionCode: "CREERTACHEDETAILLEE", actionLibelle: "Créer tâche détaillée", actionCommentaire:"créer une tâche détaillée", actionIcone:"actions/op_action_creerdetail.svg", actionType: 1},
                    {typePlanning: "tache", actionCode: "MODIFIERTACHE", actionLibelle: "Modifier", actionCommentaire:"modifier la tâche courante", actionIcone:"actions/op_action_modifier.svg", actionType: 5},
                    {typePlanning: "tache", actionCode: "AVANCEMENTTACHE", actionLibelle: "Avancement", actionCommentaire:"gérer l'avancement de la tâche courante", actionIcone:"actions/op_action_realiser.svg", actionType: 5},
                    {typePlanning: "tache", actionCode: "REACTIVERTACHE", actionLibelle: "Réactiver", actionCommentaire:"réactiver les tâches sélectionnées", actionIcone:"actions/op_action_reactiver.svg", actionType: 7},
                    {typePlanning: "tache", actionCode: "SUPPRIMERTACHE", actionLibelle: "Supprimer", actionCommentaire:"supprimer les tâches sélectionnées", actionIcone:"actions/op_action_supprimer.svg", actionType: 6},
                    {typePlanning: "recurrence", actionCode: "GERERPERIODICITE", actionLibelle: "Gérer la périodicité", actionCommentaire:"gérer la périodicité d'une intervention récurrente", actionIcone:"actions/op_action_gererperiodicite.svg", actionType: 1},
                    {typePlanning: "contact", actionCode: "CREEROCCSIMPLE", actionLibelle: "Ajout indisponibilité", actionCommentaire:"ajouter une indisponibilité", actionIcone:"actions/op_action_creer.svg", actionType: 8},
                    {typePlanning: "contact", actionCode: "MODIFIEROCC", actionLibelle: "Modifier", actionCommentaire:"modifier l'indisponibilité courante", actionIcone:"actions/op_action_modifier.svg", actionType: 5},
                    {typePlanning: "contact", actionCode: "SUPPRIMEROCC", actionLibelle: "Supprimer", actionCommentaire:"supprimer les indisponibilités sélectionnées", actionIcone:"actions/op_action_supprimer.svg", actionType: 6}
                ];
            };
        }


}]);