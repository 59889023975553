/**
 * Created by Matthieu Louchet le 30/01/17.
 */
"use strict";
angular.module('fr.operis.moteurV4.composants.popup.OpPopupMail', [])
    // opPopupActionMetier directive définie la structure modal d'une popup Operis d'action métier d'une fiche ou du classuer
    // Title
    .constant ('fr.operis.moteurV4.composants.popup.OpPopupMail.CHAMPS_GRILLE_DEST_VISIBLE', ["COT_TYPE", "COT_TITRE", "COT_NOM", "COT_PRENOM", "COT_MAIL"])
    .directive('opPopupMail', ['fr.operis.moteurV4.utils.Messagerie',
        'fr.operis.moteurV4.utils.RequeteContact',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.composants.OpPopupAlerte',
        function(Messagerie, RequeteContact, moduleInfos, serveur, opPopupAlerte) {
            return {
                restrict: 'E',
                replace: true,
                transclude: false,
                scope: {
                    opNomModule: '@',
                    opCritere: '@',
                    opMessage: '@'
                },
                template: function (element, attributs) {
                    var title = attributs.title;
                    if(angular.isNullOrUndefined(attributs.opNomModule)) {
                        title += " : " + attributs.opNomModule;
                    }

                    return '<div><op-popup title="' + title + '" ng-style="{\'background-color\': couleurPrimaire}">' +
                        '<op-popup-body>' +
                            '<label class="control-label" for="inputExpediteur">Expéditeur</label>' +
                            '<input class="form-control" type="text" id="inputExpediteur" ng-model="$opMessage.expediteur" autocomplete="off" title="Vous pouvez saisir l\'adresse mail de l\'expéditeur ou laisser ce champs vide."/>' +
                            '<label class="control-label" for="inputDestinataires" ng-if="$displayDestinataire">Destinataire(s) connu(s)</label>' +
                            '<op-grille id="inputDestinataires" ' +
                            'op-description="$description" ' +
                            'op-donnees="$grille" ' +
                            'op-selection="$selection" ng-if="$displayDestinataire">' + // Mantis Operia 6261
                            '</op-grille>' +
                            '<label class="control-label" for="inputAutreDestinataires" ng-if="$displayDestinataire">Autres destinataires</label>' +
                            '<label class="control-label" for="inputAutreDestinataires" ng-if="!$displayDestinataire">Destinataire(s)</label>' +
                            '<input class="form-control" type="text" id="inputAutreDestinataires" ng-model="$opMessage.destExterne" autocomplete="off" title="Vous pouvez saisir les adresses mail de destinataires joint par \';\'."/>' +
                            '<label class="control-label" for="inputObjetMessage">Objet du message</label>' +
                            '<input class="form-control" type="text" id="inputObjetMessage" ng-model="$opMessage.titre" autocomplete="off" title="Saisissez le titre du message."/>' +
                            '<label class="control-label" for="inputCorpsMessage">Corps du message</label>' +
                            '<textarea class="form-control" type="text" id="inputCorpsMessage" ng-model="$opMessage.corps" autocomplete="off" title="Saisissez le corps du message."/>' +
                        '</op-popup-body>' +
                        '<op-popup-footer>' +
                            '<button title="Envoyer" class="btn btn-primary" ng-click="envoyer()">Envoyer</button>' +
                        '</op-popup-footer>' +
                        '</op-popup></div>';
                },
                link: function (scope, element, attrs) {
                    scope.$description = null;
                    scope.$grille = null;
                    scope.$selection = null;
                    scope.couleurPrimaire = moduleInfos.getInfo(scope.opNomModule, "MOD_COULEURPRIMAIRE");

                    if (!angular.isNullOrUndefined(scope.opMessage)) {
                        scope.$opMessage = angular.fromJson(scope.opMessage);
                    }

                    if(angular.isNullOrUndefined(scope.opCritere)) {
                        scope.opCritere = "";
                    }

                    scope.$displayDestinataire = false;
                    var idModule = "";
                    if(!angular.isNullOrUndefined(scope.opNomModule) && scope.opNomModule.length > 0) {
                        idModule = serveur.getIdModule(scope.opNomModule);

                        // Mantis Operia 6261
                        if ((scope.opNomModule === "Demande") || (scope.opNomModule === "Intervention") || 
                            (scope.opNomModule === "Tache") || (scope.opNomModule === "Contrat")) {
							RequeteContact.getModuleContact(scope, scope.opNomModule, scope.opCritere)
								.then(function (ret){
									if(angular.isNullOrUndefined(ret)) {
										scope.$displayDestinataire = false;
										return null;
									}

									scope.$description = ret.description;
									scope.$grille = ret.grille;
									scope.$selection = [];
									scope.$displayDestinataire = true;
								}, function () {
									scope.$displayDestinataire = false;
								});
						}
                    } else {
                        scope.$displayDestinataire = false;
                    }

                    scope.envoyer = function () {
                        var dests = "";
                        var corps = " ";
                        var titre = " ";

                        for(var i = 0 ; i < scope.$selection.length ; ++i) {

                            if ((!angular.isNullOrUndefined(scope.$selection[i].ID_CONTACT) && !angular.isNullOrUndefined(scope.$selection[i].ID_CONTACT.valeur) && scope.$selection[i].ID_CONTACT.valeur.length > 0)
                                || (!angular.isNullOrUndefined(scope.$selection[i].COT_MAIL) && !angular.isNullOrUndefined(scope.$selection[i].COT_MAIL.valeur) && scope.$selection[i].COT_MAIL.valeur.length > 0)) {
                                if (dests.length > 0) {
                                    dests += ";";
                                }

                                if (!angular.isNullOrUndefined(scope.$selection[i].ID_CONTACT.valeur)) {
                                    dests += scope.$selection[i].ID_CONTACT.valeur;
                                } else {
                                    dests += scope.$selection[i].COT_MAIL.valeur;
                                }
                            }
                        }

                        if (!angular.isNullOrUndefined(dests) && dests.length > 0
                            && !angular.isNullOrUndefined(scope.$opMessage.destExterne) && scope.$opMessage.destExterne.length > 0)
                            dests += ";";
                        else if (!angular.isNullOrUndefined(scope.$opMessage.destExterne) && scope.$opMessage.destExterne.length > 0)
                            dests = "";

                        if(!angular.isNullOrUndefined(scope.$opMessage.destExterne)) {
                            dests += scope.$opMessage.destExterne;
                        }

                        if(dests.length == 0) {
                            opPopupAlerte.creer('error',"Aucun destinataire n'a été sélectionné ou renseigné.", true, false);
                            return;
                        }

                        var warnText = "";
                        if(angular.isNullOrUndefined(scope.$opMessage.expediteur) || scope.$opMessage.expediteur.length == 0) {
                            scope.$opMessage.expediteur = "";
                            warnText = "Expéditeur";
                        }

                        if(angular.isNullOrUndefined(scope.$opMessage.titre) || scope.$opMessage.titre.length == 0) {
                            if(warnText.length > 0)
                                warnText += ", ";
                            warnText += "Titre";
                        } else {
                            titre = scope.$opMessage.titre;
                        }

                        // Mantis Operia 6261
                        if(!angular.isNullOrUndefined(scope.$opMessage.corps) && scope.$opMessage.corps.length > 0) {
                            corps = scope.$opMessage.corps;
                        }

                        if(angular.isNullOrUndefined(scope.$opMessage.idEntite)) {
                            scope.$opMessage.idEntite = "";
                        }

                        if(angular.isNullOrUndefined(scope.$opMessage.nature)) {
                            scope.$opMessage.nature = "";
                        }

                        if(angular.isNullOrUndefined(scope.$opMessage.priorite)) {
                            scope.$opMessage.priorite = "";
                        }

                        if(angular.isNullOrUndefined(scope.$opMessage.confidentialite)) {
                            scope.$opMessage.confidentialite = "";
                        }

                        if(angular.isNullOrUndefined(scope.$opMessage.documents)) {
                            scope.$opMessage.documents = "";
                        }

                        var envoiDiffere = false;
                        if(warnText.length > 0) {
                            envoiDiffere = true;
                            var popupEnd = null;
                            var popupAlerte = opPopupAlerte.creer('info',"Le(s) champ(s) suivant n'a(ont) pas été renseigné(s), cliquez sur OK pour continuer. " + warnText, true, true);
                            popupAlerte.result.then(function () {
                                    return Messagerie.envoieMessage(dests, titre, corps, idModule, scope.$opMessage.idEntite, scope.$opMessage.nature, scope.$opMessage.priorite, scope.$opMessage.confidentialite, scope.$opMessage.documents);
                                })
                                .then(function (response) {
                                    popupEnd = opPopupAlerte.creer('success', response.data.libelle, true, false);
                                    popupEnd.result.then(function () {
                                        if (!angular.isNullOrUndefined(scope.opPopupController))
                                            scope.opPopupController.fermer();
                                    });
                                }, function (err) {
                                    var text = !angular.isNullOrUndefined(err.statusText) ? angular.fromJson(err.statusText).libelle : err.data;
                                    popupEnd = opPopupAlerte.creer('danger', text, true, false);
                                });
                        }

                        if(!envoiDiffere) {
                            Messagerie.envoieMessage(dests, titre, corps, idModule, scope.$opMessage.idEntite, scope.$opMessage.nature, scope.$opMessage.priorite, scope.$opMessage.confidentialite, scope.$opMessage.documents)
                                .then(function (response) {
                                    popupEnd = opPopupAlerte.creer('success', response.data.libelle, true, false);
                                    popupEnd.result.then(function () {
                                        if (!angular.isNullOrUndefined(scope.opPopupController))
                                            scope.opPopupController.fermer();
                                    });
                                }, function (err) {
                                    var text = !angular.isNullOrUndefined(err.statusText) ? angular.fromJson(err.statusText).libelle : err.data;
                                    popupEnd = opPopupAlerte.creer('danger', text, true, false);
                                });
                        }
                    };
                }
            }
        }
    ]);