/**
 * Created by bruneton on 14/04/15.
 */

'use strict';

angular.module('fr.operis.moteurV4.directives.OpGrilleSaisie', ['moteur/directives/template/OpGrilleSaisie/expandableRowTemplate.html'])
    .directive('opGrilleSaisie',
    ['$q', '$timeout', '$modal','fr.operis.moteurV4.utils.Conversion','fr.operis.moteurV4.utils.Representation',
        'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur.TYPE_POPUPSUPPRESSION',
        function ($q,$timeout,$modal,conversion,representationService,TYPE_POPUPSUPPRESSION) {
            return {
                restrict: 'E',
                transclude:true,
                replace:true,
                template: function (element, attributs) {
                    var template = '';
                    if ( angular.isNullOrUndefined(attributs.opDisabledExpandableRow )){
                        // Grille Expandable sous forme formulaire
                        template ='<div oc-lazy-load="[\'css/ui-grid.min.css\', \'lib/ui-grid.min.js\']" >'+
                                    '<div ui-grid="gridOptions" '+
                                        'ui-grid-pinning '+
                                        'ui-grid-expandable '+
                                        'ui-grid-resize-columns '+
                                        'ui-grid-auto-resize '+
                                        'ng-style="{height: (gridOptions.data.length*30) + (nbreItemOuvert*gridOptions.expandableRowHeight) + 110 +\'px\'}"></div>'+
                                    '</div>';
                    }else{
                        // Grille saisie en ligne
                        template ='<div oc-lazy-load="[\'css/ui-grid.min.css\', \'lib/ui-grid.min.js\']" >'+
                            '<div ui-grid="gridOptions" '+
                            'ui-grid-pinning '+
                            'ui-grid-resize-columns '+
                            'ui-grid-edit ' +
                            'ui-grid-row-edit '+
                            'ui-grid-auto-resize '+
                            'ng-style="{height: (gridOptions.data.length*30) + 110 +\'px\'}"></div>'+
                        '</div>';
                    }
                    return template;
                },
                scope: {
                    opGrilleOptions: '=',
                    opFormulaire: '=',
                    opEtat:'@',
                    opSectionDroiteVisible: '=?',
                    onDemandeActivite:'&'
                },
                link: function (scope, element, attrs) {


                    // Fonction de récupération de type
                    var getType = function(description){
                        if ( description.type === 'DATE')
                            return 'date';
                        else if (description.type === 'NUMBER')
                            return 'number';
                        else
                            return 'string';
                    };

                    // Fonction de suppression de ligne
                    scope.deleteRow = function(row) {
                        var index = scope.gridOptions.data.indexOf(row.entity);

                        // Message de confirmation de suppression
                        var confirmationSuppression = $modal.open({
                            templateUrl: 'moteur/composants/OpPopupAjoutElement.html',
                            controller: 'fr.operis.moteurV4.composants.OpPopupAjoutElementControleur',
                            size: 'sm',
                            resolve: {
                                titre: function () {
                                    return "Suppression";
                                },
                                libElement: function () {
                                    return "cet élément";
                                },
                                listeElements: null,
                                entite: null,
                                descriptions: null,
                                nomTable: null,
                                listes: null,
                                type: function () {
                                    return TYPE_POPUPSUPPRESSION;
                                },
                                nomModule:function(){
                                    return scope.nomModule;
                                }
                            }
                        });

                        confirmationSuppression.result.then(function (retourConfirmation) {
                            if (!angular.isNullOrUndefined(retourConfirmation)) {
                                if (retourConfirmation) {
                                    scope.gridOptions.data.splice(index, 1);
                                    scope.opFormulaire.$pristine = false;
                                }
                            }
                        })
                    };

                    // Fonction de modification de ligne
                    scope.expandableRow = function(row) {
                        scope.gridApi.expandable.toggleRowExpansion(row.entity);
                    };

                    /**
                     * Déterminer la représentation en fonction du statut
                     * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
                     * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
                     * @returns {String}
                     */
                    scope.getRepresentationClass = function(type, row) {
                         return representationService.getRepresentation('mosaique', type, row ).icon;
                    };

                    /**
                     * Déterminer le style de représentation en fonction du statut
                     * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
                     * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
                     * @returns {Object}
                     */
                    scope.getRepresentationStyle = function(type,row){
                        return representationService.getRepresentationStyle( type, row );
                    };

                    /**
                     * Déterminer le tooltip de représentation en fonction du statut
                     * @param {Object} type Type d'entite à représenter ('PRIORITE', 'STATUT', etc.)
                     * @param {Object} valeur Valeur de l'entité à représenter ('Urgent', 'Enregistrée', 'Attribuée', etc.)
                     * @returns {Object}
                     */
                    scope.getRepresentationToolTip= function(type,row){
                        return representationService.getRepresentationToolTip( type, row );
                    };

                    scope.getListeLabel = function(valeur,liste){
                        if ( angular.isNullOrUndefined( valeur ) || angular.isNullOrUndefined( liste ) )
                            return;

                        var descriptionListe = liste.split('|');
                        if ( angular.isNullOrUndefined (descriptionListe) || descriptionListe.length < 2 )
                            return;
                        var nomListe = descriptionListe[0];
                        descriptionListe = descriptionListe[1].split('#');
                        if ( angular.isNullOrUndefined (descriptionListe) || descriptionListe.length < 2 )
                            return;

                        var nomLabel = descriptionListe[0];
                        var nomValeur = descriptionListe[1];
                        if (!angular.isNullOrUndefined(this.$parent.listes) && !angular.isNullOrUndefined(this.$parent.listes[nomListe])){
                            var element = this.$parent.listes[nomListe].find(function(item){
                                return ( item[nomValeur].valeur === valeur);
                            });

                            if ( !angular.isNullOrUndefined(element))
                                return element[nomLabel].valeur;
                        }
                    };

                    /**
                     * Fonction d'ouverture d'entite dans un autre onglet
                     * @param {Object} aModule
                     * @param {String} aEntite
                     * @param {String} aModeleNom
                     */
                    scope.demanderActivite = function(aScope, aModule, aEntite, aModeleNom){
                        scope.onDemandeActivite({idaction:aModule,entite:aEntite,modele: aModeleNom,index:null,type:'GRILLE'} );
                    };


                    scope.nbreItemOuvert = 0;


                    // Parametres de la grille
                    if ( angular.isNullOrUndefined(attrs.opDisabledExpandableRow)){
                        scope.gridOptions={
                            columnDefs:[],
                            enableExpandableRowHeader: false,
                            expandableRowTemplate:'moteur/directives/template/OpGrilleSaisie/expandableRowTemplate.html',
                            expandableRowScope:{
                                grilles:[],
                                liste:[],
                                description:[],
                                expandableRow:scope.expandableRow,
                                demanderActivite:scope.demanderActivite
                            },
                            expandableRowHeight:50,
                            onRegisterApi: function (gridApi) {
                                scope.gridApi = gridApi;
                                gridApi.expandable.on.rowExpandedStateChanged(scope, function (row) {
                                    if (row.isExpanded) {
                                        scope.nbreItemOuvert++;
                                    }
                                    else {
                                        scope.nbreItemOuvert--;
                                    }
                                });
                            },
                            data:[],
                            enableFiltering:true,
                            enableGridMenu: true
                        };
                    }else{
                        scope.gridOptions={
                            columnDefs:[],
                            data:[],
                            enableFiltering:true,
                            enableGridMenu: true,
                            enableCellEdit:true,
                            onRegisterApi: function (gridApi) {
                                scope.gridApi = gridApi;
                                gridApi.rowEdit.on.saveRow(scope, scope.saveRow);
                            }
                        };
                    }

                    // Save for each row
                    scope.saveRow = function (rowEntity) {
                        var promise = $q.defer();
                        scope.gridApi.rowEdit.setSavePromise(rowEntity, promise.promise);
                        promise.resolve();
                    };


                    /**
                     * Ecouter la bascule de la section droite de l'onglet
                     */
                    if ('opSectionDroiteVisible' in attrs) {
                        scope.$watch('opSectionDroiteVisible', function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                if (newValue && !angular.isNullOrUndefined(scope.gridApi)) {
                                    // Adjuster la largeur de la grille suite affichage de la section droite
                                    scope.gridApi.grid.gridWidth = scope.gridApi.grid.canvasWidth / 2;
                                    scope.gridApi.grid.queueRefresh();
                                }
                            }
                        });
                    }

                    // Ecouteur sur les parametres de la grille
                    scope.$watch('opGrilleOptions', function(value) {
                        if (value) {

                        // Définition des colonnes (sur nom de groupe)
                        if ( !angular.isNullOrUndefined( value.nomTable )
                            && !angular.isNullOrUndefined(scope.$parent.descriptions[value.nomTable])){
                            var descriptionsColonne = []
                                ,column = {}
                                ,columnDef=[]
                                ,descriptions = scope.$parent.descriptions[value.nomTable]
                                ,description,refEtrangere;

                            if ( !angular.isNullOrUndefined( value.descriptionsColonne ))
                                descriptionsColonne = value.descriptionsColonne;
                            else{
                                var regroupementnom = descriptions[0]["nomGroupe"];
                                if ( !angular.isNullOrUndefined ( regroupementnom ) )
                                    regroupementnom = regroupementnom.split('|');
                                if ( !angular.isNullOrUndefined ( regroupementnom[2] )){
                                    descriptionsColonne = regroupementnom[2].split('#');
                                }
                            }

                            if ( descriptionsColonne.length > 0 ){
                                for (var i=0;i<descriptionsColonne.length;i++){
                                    if (!angular.isNullOrUndefined(scope.$parent.descriptions[value.nomTable][descriptionsColonne[i]])){
                                        description = scope.$parent.descriptions[value.nomTable][descriptionsColonne[i]];

                                        // Caractéristiques générales
                                        column = {
                                            name: description.libelle,
                                            field: description.nom + '.valeur',
                                            type: getType (description),// 'string,boolean,number,date,object'
                                            enableFiltering:true,
                                            headerTooltip:true,
                                            cellTooltip: true,
                                            enableCellEdit: ( !description.estLectureSeule)
                                        };

                                        // Date
                                        if ( description.type === "DATE"){
                                            column.cellFilter = 'date:\'dd/MM/yyyy\'';
                                            column.width = 120;
                                        }

                                        // Type interaction liste avec valeur != label
                                        if ( description.typeInteraction === "LIST" ){
                                            var descriptionListe = description.refEtrangere.split('|');
                                            if (!angular.isNullOrUndefined(descriptionListe) && descriptionListe.length>1){
                                                var listeNom = descriptionListe[0];
                                                var filterValeur, filterKey;
                                                if ( descriptionListe.length>4 ){
                                                    filterKey = descriptionListe[4];
                                                    filterValeur = descriptionListe[3];
                                                }

                                                descriptionListe = descriptionListe[1].split('#');
                                                if ( angular.isNullOrUndefined(attrs.opDisabledExpandableRow)){
                                                    if (!angular.isNullOrUndefined(descriptionListe) && descriptionListe.length>1 && descriptionListe[0] != descriptionListe[1]){
                                                        column.cellTemplate = '<div>{{grid.appScope.getListeLabel(row.entity.'+description.nom+'.valeur,\''+description.refEtrangere+'\')}}</div>';
                                                    }
                                                }else{
                                                    var valeur = descriptionListe[0], label = descriptionListe[0];
                                                    if ( descriptionListe.length>0 && descriptionListe[0] != descriptionListe[1] )
                                                        valeur = descriptionListe[1];

                                                    column.enableCellEditOnFocus=true;
                                                    column.editableCellTemplate='<div><form name="inputForm"><select ng-class="\'colt\' + col.uid" ui-grid-edit-dropdown ng-model="MODEL_COL_FIELD"' +
                                                        ' ng-options="field[editDropdownIdLabel].valeur as field[editDropdownValueLabel].valeur CUSTOM_FILTERS for field in editDropdownOptionsArray"></select></form></div>';
                                                    column.editDropdownValueLabel = label;
                                                    column.editDropdownIdLabel = valeur;
                                                    column.editDropdownOptionsArray =  scope.$parent.listes[listeNom];
                                                    column.editDropdownOptionsFunction = function(rowEntity,colDef){
                                                        return scope.$parent.listes[listeNom].filter(function(item){
                                                            if ( item[filterKey].valeur === rowEntity[filterValeur].valeur)
                                                                return true;

                                                        });
                                                    };
                                                    column.cellFilter =  'filtreGridDropDown:row.grid.appScope.$parent.listes.'+listeNom+':"'+label+'":"'+valeur+'"';
                                                    column.editType = 'dropdown';
                                                }

                                            }
                                        }

                                        // Ajout du bouton de lien si lien non vide
                                        if ( !angular.isNullOrUndefined(description.lien) && description.lien.length>0 ){
                                            var idActionLien,entiteLien,descriptionLien = description.lien.split('|');
                                            idActionLien = descriptionLien[0];
                                            entiteLien = descriptionLien[1];

                                            column.cellTemplate = '' +
                                                '<div class="ui-grid-cell-contents" >' +
                                                    '<a href="" style="font-weight: bold;" title="{{COL_FIELD}}" ng-click="$event.stopPropagation();grid.appScope.demanderActivite(\''+idActionLien+'\',\''+entiteLien+'\',row.entity, null, \'GRILLES\')">{{COL_FIELD}}</a>'+
                                                '</div>';
                                        }


                                        columnDef.push( column );
                                    }
                                }



                                // Ajout du statut dans le cas d'intervention tache
                                if ( !angular.isNullOrUndefined(scope.opEtat)){
                                    columnDef.splice(0,0,{
                                        name: 'a',
                                        displayName : "",
                                        width: 50,
                                        field:'a',
                                        enableFiltering:false,
                                        sortable:false,
                                        cellTemplate: '<div style="text-align:center;" class="ui-grid-cell-contents" >' +
                                                          '<i ng-class="grid.appScope.getRepresentationClass( \''+scope.opEtat+'\', row.entity)" ' +
                                                                'ng-style="grid.appScope.getRepresentationStyle( \''+scope.opEtat+'\', row.entity)"  ' +
                                                                'tooltip-html-unsafe="{{grid.appScope.getRepresentationToolTip( \''+scope.opEtat+'\', row.entity)}}" ' +
                                                                'tooltip-append-to-body=\'true\'' +
                                                                'aria-hidden="true">' +
                                                            '</i>' +
                                                       '</div>'
                                    })
                                }
                                scope.gridOptions.columnDefs =  columnDef;

                                // Ajout des boutons de modification et suppression dans le cas expandable
                                if ( angular.isNullOrUndefined(attrs.opDisabledExpandableRow)){
                                    scope.gridOptions.columnDefs.push({
                                        name: 'b',
                                        displayName : "",
                                        width: 100,
                                        field:'b',
                                        enableFiltering:false,
                                        sortable:false,
                                        cellTemplate: '<div style="text-align:right;" class="ui-grid-cell-contents" >' +
                                        '<div class="btn-group" style="text-align:right;" >' +
                                        '<button class="btn btn-xs btn-success" ng-show="!row.isExpanded" ng-click="$event.stopPropagation();grid.appScope.expandableRow(row)" href=""><i class="fa fa-pencil"  aria-hidden="true"></i></button>' +
                                        '<button class="btn btn-xs btn-danger" ng-click="$event.stopPropagation();grid.appScope.deleteRow(row)" href=""><i class="fa fa-trash"  aria-hidden="true"></i></button>'+
                                        '</div>' +
                                        '</div>'
                                    });
                                }

                            }

                        }

                        // Données
                        if ( !angular.isNullOrUndefined( value.data )){
                            scope.gridOptions.data = scope.$parent.grilles[value.nomTable];
                            $timeout(function() {
                                scope.$parent.grilles[value.nomTable].optionGrilles["gridApi"] = scope.gridApi;
                            })
                            // Ecouteur sur la longueur de la grille
                            /*scope.$watch('gridOptions.data.length', function(newValue,oldValue) {
                                if ( (newValue > oldValue ) || ( newValue == 2 &&  oldValue == 2 && angular.isNullOrUndefined(scope.gridOptions.data[0].$$hashKey) ) ){
                                    scope.gridApi.core.refresh();
                                    $timeout(function() {
                                        scope.expandableRow({entity: scope.gridOptions.data[0]});
                                        scope.opFormulaire.$pristine = false;
                                    });
                                }
                            });*/
                        }

                        // Définition de l'expansion de la grille
                        if ( angular.isNullOrUndefined(attrs.opDisabledExpandableRow)) {
                            scope.gridOptions.expandableRowScope.grilles = scope.gridOptions.data;
                            scope.gridOptions.expandableRowScope.descriptions = {};
                            scope.gridOptions.expandableRowScope.descriptions[value.nomTable] = descriptions;
                            scope.gridOptions.expandableRowScope.listes = scope.$parent.listes;
                            scope.gridOptions.expandableRowScope.nomTable = value.nomTable;
                            scope.gridOptions.expandableRowScope.nomModule = scope.$parent.nomModule;
                            scope.gridOptions.expandableRowScope.onglet = scope.$parent.opOnglet;
                            scope.gridOptions.expandableRowScope.opFormulaire = scope.opFormulaire;
                        }

                        // Hauteur de l'expandable
                        if ( angular.isNullOrUndefined(attrs.opDisabledExpandableRow)){
                            var nombreItem = 0;
                            for (var i=0;i<descriptions.length;i++){
                                if ( descriptions[i].estVisible )
                                    nombreItem++;
                            }
                            scope.gridOptions.expandableRowHeight = (50*nombreItem) + 10;
                        }

                    }
                    });
                }
            };
        }
    ])
    .controller('expandableRowTemplateCtrl',['$scope',
        function($scope){

        // Définition de table pour le opFormulaire
        var nomTable = $scope.nomTable;$scope.champs = { };
        $scope.champs[$scope.nomTable] = $scope.grilles[$scope.$index];
        $scope.grilles[$scope.nomTable] = $scope.grilles;

        // Création de liste principale temporaire
        $scope.listes["LST_FRAMEWORKOPERISTEMP"] = [];
        function isPresentValeur (valeur,array) {
            for (var i = 0; i < array.length;  i++) {
                if (array[i].VALEUR.valeur === valeur)
                    return true;
            }
            return false;
        }

        // Cas particulier pour les tâches des interventions
        if ( !angular.isNullOrUndefined( $scope.descriptions["INT_TACHE_RESSOURCE"])){

            // Création d'une liste de tache
            for (var i = 0; i < $scope.grilles.length; i++) {
                if (!angular.isNullOrUndefined($scope.grilles[i]["INT_TAC_RES_NOM"])) {
                    var valeur = $scope.grilles[i]["INT_TAC_RES_NOM"].valeur;
                    if (!isPresentValeur(valeur, $scope.listes["LST_FRAMEWORKOPERISTEMP"])) {
                        $scope.listes["LST_FRAMEWORKOPERISTEMP"].push({
                            VALEUR: {
                                valeur: valeur
                            }
                        });
                    }
                }
            }

            if ( !angular.isNullOrUndefined( $scope.descriptions["INT_TACHE_RESSOURCE"]["INT_TAC_RES_OBJET"])){
                $scope.descriptions["INT_TACHE_RESSOURCE"]["INT_TAC_RES_OBJET"].refEtrangere = "LST_FRAMEWORKOPERISTEMP|VALEUR";
                $scope.descriptions["INT_TACHE_RESSOURCE"]["INT_TAC_RES_OBJET"].typeInteraction = "LIST";
            }

        }

        //$scope.descriptions = descriptionsTemp;
        $scope.table = nomTable;

        // Fonction de fin de chargement du formulaire
        $scope.finChargementFormulaire = function(aScopeFormulaire){
            $scope["scopeFormulaire"] = aScopeFormulaire;
        };

        // Fonction de fermetude ligne
        $scope.closeRow = function ()  {
            if ( !$scope["scopeFormulaire"].form["form_" + nomTable].$pristine )
                $scope.$parent.opFormulaire.$pristine  = false;
            $scope.expandableRow($scope.$parent.row);
        };
    }
    ]);
;