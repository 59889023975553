/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

(function () {
    var pako;

    angular.module('fr.operis.moteurV4.utils.Compression', [])
        .service('fr.operis.moteurV4.utils.Compression'
        , [
            function () {
                // Récupération de la librairie pako si ce n'est pas encore fait
                if (angular.isNullOrUndefined(pako)) {
                    pako = window.pako;
                    if (angular.isNullOrUndefined(pako))
                        throw "Le service de compression n'a pas pu s'initialiser. Vérifiez que la librairie 'pako' est bien chargée !";

                    delete window.pako;
                }

                /**
                 * Compresse une valeur.
                 * @param {String|byte[]|UInt8Array} aValeur
                 * @returns {UInt8Array} La valeur compressée avec l'algorithme deflate
                 */
                this.deflate = function (aValeur) {
                    return pako.deflate(aValeur);
                };

                /**
                 * Décompresse une valeur.
                 * @param {String|byte[]|UInt8Array} aValeur
                 * @returns {UInt8Array} La valeur décompressée avec l'algorithme inflate
                 */
                this.inflate = function (aValeur) {
                    return pako.inflate(aValeur);
                };
            }
        ]
    );
})();