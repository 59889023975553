/**
 * @name OpPopupAjoutTacheDetaillee
 *
 * @version 1.0b
 * @copyright 2017 Operis
 * @author Brian Kjerulf, Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheDetaillee',
    ['fr.operis.moteurV4.composants.planning.OpPopupAfficherRessources',
     'fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheMateriau'])
    .controller('fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheDetailleeControleur', ['$scope',
        '$modalInstance', '$modal', '$q', 'uiGridConstants',
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'titre', 'premiereTache', 'vueFormulaire',
        function (scope, modalInstance, modal, q, uiGridConstants, serveur, moduleInfos, titre, premiereTache, vueFormulaire) {
                scope.titre = titre;
                scope.vueFormulaire = vueFormulaire;
                scope.libBtnValider = "Ajouter";
                scope.estAjout = true;
                if (!angular.isNullOrUndefined(scope.vueFormulaire.tache)) {
                    scope.libBtnValider = "Valider";
                    scope.estAjout = scope.vueFormulaire.tache.data.element.estAjout;
                }
                scope.couleurPrimaire = moduleInfos.getInfo("Intervention", "MOD_COULEURPRIMAIRE");
                scope.couleurSecondaire = moduleInfos.getInfo("Intervention", "MOD_COULEURSECONDAIRE");

                // Gestion groupes des accordions
                scope.accordionGroupes = {
                    intervenantsIsOpen: false,
                    materielsIsOpen: false,
                    materiauxIsOpen: false
                };

                // Config grille intervenants
                scope.titreIntervenants = "Intervenants";
                scope.gridOptionsIntervenants = {
                    enableRowHeaderSelection: false,
                    enableFullRowSelection: true,
                    enableSelectAll: true,
                    multiSelect: true,
                    enableHorizontalScrollbar : uiGridConstants.scrollbars.WHEN_NEEDED,
                    columnDefs: [
                        {name: 'id', displayName: 'Id', field: 'id', visible: false},
                        {name: 'type', displayName: 'Type', field: 'type', visible: true},
                        {name: 'libelle', displayName: 'Intervenant', field: 'libelle', visible: true},
                        {name: 'quantite', displayName: 'Quantité', field: 'quantite', visible: false}
                    ],
                    onRegisterApi: function(gridApi) {
                        scope.gridApiIntervenants = gridApi;
                    },
                    data: []
                };

                // Config grille matériels
                scope.titreMateriels = "Matériels";
                scope.gridOptionsMateriels = {
                    enableRowHeaderSelection: false,
                    enableFullRowSelection: true,
                    enableSelectAll: true,
                    multiSelect: true,
                    enableHorizontalScrollbar : uiGridConstants.scrollbars.WHEN_NEEDED,
                    columnDefs: [
                        {name: 'id', displayName: 'Id', field: 'id', visible: false},
                        {name: 'type', displayName: 'Type', field: 'type', visible: false},
                        {name: 'libelle', displayName: 'Matériel', field: 'libelle', visible: true},
                        {name: 'quantite', displayName: 'Quantité', field: 'quantite', visible: false}
                    ],
                    onRegisterApi: function(gridApi) {
                        scope.gridApiMateriels = gridApi;
                    },
                    data: []
                };

                // Config grille matériaux
                scope.titreMateriaux = "Matériaux";
                scope.gridOptionsMateriaux = {
                    enableRowHeaderSelection: false,
                    enableFullRowSelection: true,
                    enableSelectAll: true,
                    multiSelect: true,
                    enableHorizontalScrollbar : uiGridConstants.scrollbars.WHEN_NEEDED,
                    columnDefs: [
                        {name: 'id', displayName: 'Id', field: 'id', visible: false},
                        {name: 'type', displayName: 'Type', field: 'type', visible: false},
                        {name: 'libelle', displayName: 'Matériau', field: 'libelle', visible: true},
                        {name: 'quantite', displayName: 'Quantité', field: 'quantite', visible: true}
                    ],
                    onRegisterApi: function(gridApi) {
                        scope.gridApiMateriaux = gridApi;
                    },
                    data: []
                };

                // Saisie complète ?
                scope.saisieNOK = function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE)) {
                        return (angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur) ||
                                (scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur.trim() === ""));
                    } else {
                        return true;
                    }
                };

                /**
                 * Mise à jour le titre du groupe intervenants
                 */
                var majTitreIntervenants = function () {
                    scope.titreIntervenants = "Intervenants";
                    if (scope.gridOptionsIntervenants.data.length > 0) {
                        scope.titreIntervenants += " (" + scope.gridOptionsIntervenants.data.length.toString() + ")";
                    }
                };

                /**
                 * Mise à jour le titre du groupe matériels
                 */
                var majTitreMateriels = function () {
                    scope.titreMateriels = "Matériels";
                    if (scope.gridOptionsMateriels.data.length > 0) {
                        scope.titreMateriels += " (" + scope.gridOptionsMateriels.data.length.toString() + ")";
                    }
                };

                /**
                 * Mise à jour le titre du groupe matériaux
                 */
                var majTitreMateriaux = function () {
                    scope.titreMateriaux = "Matériaux";
                    if (scope.gridOptionsMateriaux.data.length > 0) {
                        scope.titreMateriaux += " (" + scope.gridOptionsMateriaux.data.length.toString() + ")";
                    }
                };

                /**
                 * Initialiser les données de la tâche
                 * @param tache {Object} Données de la tâche en cours
                 */
                var chargerTache = function (tache) {
                    var deferred = q.defer();

                    if (!angular.isNullOrUndefined(tache.data) && !angular.isNullOrUndefined(tache.data.element)) {
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur = tache.data.element.libLigne; //tache.data.element.refLigne + " " + tache.data.element.libLigne;
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DESCRIPTION.valeur = tache.data.element.descLigne;

                        if (!angular.isNullOrUndefined(tache.data.element.debut)) {
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT_PREV.valeur = moment(tache.data.element.debut).toDate();
                        }

                        if (!angular.isNullOrUndefined(tache.data.element.fin)) {
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN_PREV.valeur = moment(tache.data.element.fin).toDate();
                        }

                        if (!angular.isNullOrUndefined(tache.data.element.debutReel)) {
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT.valeur = moment(tache.data.element.debutReel).toDate();
                        }

                        if (!angular.isNullOrUndefined(tache.data.element.finReelle)) {
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN.valeur = moment(tache.data.element.finReelle).toDate();
                        }

                        //scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREDEBUT.valeur = tache.data.element.heureDebut;
                        //scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREFIN.valeur = tache.data.element.heureFin;
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEJOUR.valeur = tache.data.element.dureeJours;
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEHEURE.valeur = tache.data.element.dureeHeures;
                        scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_AVANCEMENT.valeur = tache.data.element.avancement;
                    }

                    deferred.resolve();

                    return deferred.promise;
                };

                /**
                 * Initialiser la grille des intervenats
                 * @param ressources {Object} Tableau des ressources
                 */
                var initGrilleIntervenants = function (ressources) {
                    var deferred = q.defer();

                    var intervenants = [];

                    for (var i = 0; i < ressources.length; i++) {
                        if (ressources[i].typeRessource === "Intervenant") {
                            // Type de ressource
                            var typeRes = ressources[i].typeRessource;
                            for (var j= 0; j < scope.vueFormulaire.listeRessources.length; j++) {
                                if (scope.vueFormulaire.listeRessources[j].id === ressources[i].idRessource) {
                                    typeRes = scope.vueFormulaire.listeRessources[j].typeRessource;
                                    break;
                                }
                            }

                            intervenants.push({
                                id: ressources[i].idRessource,
                                type: typeRes,
                                libelle: ressources[i].libRessource,
                                quantite: 1
                            });
                        }
                    }

                    scope.gridOptionsIntervenants.data = intervenants;
                    majTitreIntervenants();

                    deferred.resolve();

                    return deferred.promise;
                };

                /**
                 * Initialiser la grille des matériels
                 * @param ressources {Object} Tableau des ressources
                 */
                var initGrilleMateriels = function (ressources) {
                    var deferred = q.defer();

                    var materiels = [];

                    for (var i = 0; i < ressources.length; i++) {
                        if (ressources[i].typeRessource === "Matériel") {
                            // Type de matériel
                            var typeRes = "";
                            for (var j= 0; j < scope.vueFormulaire.listeRessources.length; j++) {
                                if (scope.vueFormulaire.listeRessources[j].id === ressources[i].idRessource) {
                                    typeRes = scope.vueFormulaire.listeRessources[j].competence;
                                    break;
                                }
                            }

                            materiels.push({
                                id: ressources[i].idRessource,
                                type: typeRes,
                                libelle: ressources[i].libRessource,
                                quantite: 1
                            });
                        }
                    }

                    scope.gridOptionsMateriels.data = materiels;
                    majTitreMateriels();

                    deferred.resolve();

                    return deferred.promise;
                };

                /**
                 * Initialiser la grille des matériaux
                 * @param ressources {Object} Tableau des ressources
                 */
                var initGrilleMateriaux = function (ressources) {
                    var deferred = q.defer();

                    var materiaux = [];

                    for (var i = 0; i < ressources.length; i++) {
                        if ((ressources[i].typeRessource === "Matériau") && (ressources[i].libRessource !== "")) {
                            materiaux.push({
                                id: null,
                                type: "Matériau",
                                libelle: ressources[i].libRessource,
                                quantite: ressources[i].qteRessource
                            });
                        }
                    }

                    scope.gridOptionsMateriaux.data = materiaux;
                    majTitreMateriaux();

                    deferred.resolve();

                    return deferred.promise;
                };

                /**
                 * Initialiser les données des ressources de la tâche
                 * @param ressources {Array} Données de la tâche en cours
                 */
                var chargerRessources = function (ressources) {
                    var deferred = q.defer();

                    initGrilleIntervenants(ressources).then(function () {
                        initGrilleMateriels(ressources).then(function () {
                            initGrilleMateriaux(ressources).then(function () {
                                deferred.resolve();
                            });
                        });
                    });

                    return deferred.promise;
                };

                /**
                 * Ajout intervenant(s)
                 */
                scope.ajouterIntervenant = function () {
                    // Lancer fênetre modal pour ajouter des intervenants
                    var modalIntervenants = modal.open({
                        templateUrl: 'moteur/composants/planning/OpPopupAfficherRessources.html',
                        controller: 'fr.operis.moteurV4.composants.planning.OpPopupAfficherRessourcesControleur',
                        size: 'lg',
                        resolve: {
                            titre: function () {
                                return "Ajouter des intervenants";
                            },
                            listeRessources: function (){
                                return scope.vueFormulaire.listeRessources;
                            },
                            listeRessourcesAffectees: function (){
                                var affectees = [];

                                for (var i = 0; i < scope.gridOptionsIntervenants.data.length; i++) {
                                    affectees.push({
                                        id: scope.gridOptionsIntervenants.data[i].id,
                                        typeRessource: scope.gridOptionsIntervenants.data[i].type
                                    });
                                }

                                return affectees;
                            },
                            filtreRessources: function (){
                                scope.vueFormulaire.filtreRessources.typeRessource = "Individu";
                                return scope.vueFormulaire.filtreRessources;
                            }
                        }
                    });

                    modalIntervenants.result.then(function (listeRessources) {
                        if (!angular.isNullOrUndefined(listeRessources)) {
                            // Mettre à jour la liste des intervenants
                            var intervenants = scope.gridOptionsIntervenants.data;
                            for (var i = 0; i < listeRessources.length; i++) {
                                var idxLigne = -1;
                                for (var j = 0; j < intervenants.length; j++) {
                                    if ((intervenants[j].id === listeRessources[i].id) &&
                                        (intervenants[j].type === listeRessources[i].typeRessource) &&
                                        (intervenants[j].libelle === listeRessources[i].libRessource)) {
                                        idxLigne = j;
                                        break;
                                    }
                                }

                                if (idxLigne === -1) {
                                    intervenants.push({
                                        id: listeRessources[i].id,
                                        type: listeRessources[i].typeRessource,
                                        libelle: listeRessources[i].libRessource,
                                        quantite: 1
                                    });
                                }
                            }

                            scope.gridOptionsIntervenants.data = intervenants;
                            majTitreIntervenants();

                            if (!scope.accordionGroupes.intervenantsIsOpen) {
                                scope.accordionGroupes.intervenantsIsOpen = (scope.gridOptionsIntervenants.data.length > 0);
                            }
                        }
                    });
                };

                /**
                 * Ajout matériel(s)
                 */
                scope.ajouterMateriel = function () {
                    // Lancer fênetre modal pour ajouter des matériels
                    var modalMateriels = modal.open({
                        templateUrl: 'moteur/composants/planning/OpPopupAfficherRessources.html',
                        controller: 'fr.operis.moteurV4.composants.planning.OpPopupAfficherRessourcesControleur',
                        size: 'lg',
                        resolve: {
                            titre: function () {
                                return "Ajouter des matériels";
                            },
                            listeRessources: function (){
                                return scope.vueFormulaire.listeRessources;
                            },
                            listeRessourcesAffectees: function (){
                                var affectees = [];

                                for (var i = 0; i < scope.gridOptionsMateriels.data.length; i++) {
                                    affectees.push({
                                        id: scope.gridOptionsMateriels.data[i].id,
                                        typeRessource: "Matériel"
                                    });
                                }

                                return affectees;
                            },
                            filtreRessources: function (){
                                scope.vueFormulaire.filtreRessources.typeRessource = "Matériel";
                                return scope.vueFormulaire.filtreRessources;
                            }
                        }
                    });

                    modalMateriels.result.then(function (listeRessources) {
                        if (!angular.isNullOrUndefined(listeRessources)) {
                            // Mettre à jour la liste des materiels
                            var materiels = scope.gridOptionsMateriels.data;
                            for (var i = 0; i < listeRessources.length; i++) {
                                var idxLigne = -1;
                                for (var j = 0; j < materiels.length; j++) {
                                    if ((materiels[j].id === listeRessources[i].id) &&
                                        (materiels[j].type === listeRessources[i].typeRessource) &&
                                        (materiels[j].libelle === listeRessources[i].libRessource)) {
                                        idxLigne = j;
                                        break;
                                    }
                                }

                                if (idxLigne === -1) {
                                    materiels.push({
                                        id: listeRessources[i].id,
                                        type: listeRessources[i].typeRessource,
                                        libelle: listeRessources[i].libRessource,
                                        quantite: 1
                                    });
                                }
                            }

                            scope.gridOptionsMateriels.data = materiels;
                            majTitreMateriels();

                            if (!scope.accordionGroupes.materielsIsOpen) {
                                scope.accordionGroupes.materielsIsOpen = (scope.gridOptionsMateriels.data.length > 0);
                            }
                        }
                    });
                };

                /**
                 * Ajout matériau
                 */
                scope.ajouterMateriau = function () {
                    // Lancer fênetre modal pour l'ajout d'un matériau
                    var modalMateriau = modal.open({
                        templateUrl: 'moteur/composants/planning/OpPopupAjoutTacheMateriau.html',
                        controller: 'fr.operis.moteurV4.composants.planning.OpPopupAjoutTacheMateriauControleur',
                        size: 'lg',
                        resolve: {
                            titre: function () {
                                return "Ajout matériau";
                            },
                            vueFormulaire: function () {
                                return {
                                    nomModule: "Intervention",
                                    nomEcran: "AJOUTMATERIAU",
                                    identifiant: {ID_TACHEMATERIAU: ""}
                                };
                            }
                        }
                    });

                    modalMateriau.result.then(function (materiau) {
                        if (!angular.isNullOrUndefined(materiau)) {
                            // Mettre à jour la liste des materiaux
                            var materiaux = scope.gridOptionsMateriaux.data;
                            materiaux.push({
                                id: 0,
                                type: "Matériau",
                                libelle: materiau.libMateriau,
                                quantite: materiau.qteMateriau
                            });

                            scope.gridOptionsMateriaux.data = materiaux;
                            majTitreMateriaux();

                            if (!scope.accordionGroupes.materiauxIsOpen) {
                                scope.accordionGroupes.materiauxIsOpen = (scope.gridOptionsMateriaux.data.length > 0);
                            }
                        }
                    });
                };

                /**
                 * Supprimer intervenant(s)
                 */
                scope.supprimerIntervenant = function () {
                    if (!scope.accordionGroupes.intervenantsIsOpen) {
                        scope.accordionGroupes.intervenantsIsOpen = true;
                    } else {
                        var intervenants = scope.gridOptionsIntervenants.data;
                        var lignes = scope.gridApiIntervenants.selection.getSelectedRows();

                        for (var i = 0; i < lignes.length; i++) {
                            for (var j = intervenants.length - 1; j >= 0; j--) {
                                if ((intervenants[j].id === lignes[i].id) && (intervenants[j].type === lignes[i].type)) {
                                    intervenants.splice(j, 1);
                                    break;
                                }
                            }
                        }

                        scope.gridOptionsIntervenants.data = intervenants;
                        majTitreIntervenants();
                    }
                };

                /**
                 * Supprimer matériel(s)
                 */
                scope.supprimerMateriel = function () {
                    if (!scope.accordionGroupes.materielsIsOpen) {
                        scope.accordionGroupes.materielsIsOpen = true;
                    } else {
                        var materiels = scope.gridOptionsMateriels.data;
                        var lignes = scope.gridApiMateriels.selection.getSelectedRows();

                        for (var i = 0; i < lignes.length; i++) {
                            for (var j = materiels.length - 1; j >= 0; j--) {
                                if ((materiels[j].id === lignes[i].id) && (materiels[j].type === lignes[i].type)) {
                                    materiels.splice(j, 1);
                                    break;
                                }
                            }
                        }

                        scope.gridOptionsMateriels.data = materiels;
                        majTitreMateriels();
                    }
                };

                /**
                 * Supprimer matériau(x)
                 */
                scope.supprimerMateriaux = function () {
                    if (!scope.accordionGroupes.materiauxIsOpen) {
                        scope.accordionGroupes.materiauxIsOpen = true;
                    } else {
                        var materiaux = scope.gridOptionsMateriaux.data;
                        var lignes = scope.gridApiMateriaux.selection.getSelectedRows();

                        for (var i = 0; i < lignes.length; i++) {
                            for (var j = materiaux.length - 1; j >= 0; j--) {
                                if ((materiaux[j].libelle === lignes[i].libelle) && (materiaux[j].quantite === lignes[i].quantite)) {
                                    materiaux.splice(j, 1);
                                    break;
                                }
                            }
                        }

                        scope.gridOptionsMateriaux.data = materiaux;
                        majTitreMateriaux();
                    }
                };

                /**
                 * Valder les modifications et fermer l'écran
                 */
                scope.valider = function () {
                    var lignesTache = [];
                    var tache = {};
                    var i, j;

                    // Ajout tâche sans ressources
                    if ((scope.gridOptionsIntervenants.data.length === 0) &&
                        (scope.gridOptionsMateriels.data.length === 0) &&
                        (scope.gridOptionsMateriaux.data.length === 0)) {
                        tache.idEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.ID_INTERVENTION.valeur;
                        tache.refEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.REFERENCE_INTERVENTION.valeur;
                        tache.adresseEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_ADRESSECOMPLETE.valeur;

                        if (!scope.estAjout) {
                            tache.refInterne = scope.vueFormulaire.tache.refInterne;
                            tache.numeroInterne = scope.vueFormulaire.tache.numeroInterne;
                            tache.dateCreation = scope.vueFormulaire.tache.dateCreation;
                        }

                        var refObjet = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                        tache.libLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                        tache.descLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DESCRIPTION.valeur;

                        for (j = 0; j < scope.vueFormulaire.listes.VL_TAC_OBJET.length; j++) {
                            if (scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur === refObjet) {
                                tache.refLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_CODE.valeur;
                                tache.libLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur;
                                break;
                            }
                        }

                        tache.debut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT_PREV.valeur;
                        tache.fin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN_PREV.valeur;

                        tache.debutReel = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT.valeur;
                        tache.finReelle = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN.valeur;

                        //tache.heureDebut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREDEBUT.valeur;
                        //tache.heureFin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREFIN.valeur;
                        tache.dureeJours = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEJOUR.valeur;
                        tache.dureeHeures = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEHEURE.valeur;
                        tache.avancement = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_AVANCEMENT.valeur;

                        tache.estAjout = scope.estAjout;

                        lignesTache.push(tache);
                    } else {
                        // Ajout lignes intervenants
                        for (i = 0; i < scope.gridOptionsIntervenants.data.length; i++) {
                            tache = {};

                            tache.idEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.ID_INTERVENTION.valeur;
                            tache.refEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.REFERENCE_INTERVENTION.valeur;
                            tache.adresseEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_ADRESSECOMPLETE.valeur;

                            if (!scope.estAjout) {
                                tache.refInterne = scope.vueFormulaire.tache.refInterne;
                                tache.numeroInterne = scope.vueFormulaire.tache.numeroInterne;
                                tache.dateCreation = scope.vueFormulaire.tache.dateCreation;
                            }

                            var refObjet = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                            tache.libLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                            tache.descLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DESCRIPTION.valeur;

                            for (j = 0; j < scope.vueFormulaire.listes.VL_TAC_OBJET.length; j++) {
                                if (scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur === refObjet) {
                                    tache.refLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_CODE.valeur;
                                    tache.libLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur;
                                    break;
                                }
                            }

                            tache.debut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT_PREV.valeur;
                            tache.fin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN_PREV.valeur;

                            tache.debutReel = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT.valeur;
                            tache.finReelle = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN.valeur;

                            //tache.heureDebut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREDEBUT.valeur;
                            //tache.heureFin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREFIN.valeur;
                            tache.dureeJours = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEJOUR.valeur;
                            tache.dureeHeures = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEHEURE.valeur;
                            tache.avancement = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_AVANCEMENT.valeur;

                            tache.typeRessource = "Intervenant";
                            tache.idRessource = scope.gridOptionsIntervenants.data[i].id;
                            tache.libRessource = scope.gridOptionsIntervenants.data[i].libelle;
                            tache.qteRessource = 1;

                            tache.estAjout = scope.estAjout;

                            lignesTache.push(tache);
                        }

                        // Ajout lignes matériels
                        for (i = 0; i < scope.gridOptionsMateriels.data.length; i++) {
                            tache = {};

                            tache.idEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.ID_INTERVENTION.valeur;
                            tache.refEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.REFERENCE_INTERVENTION.valeur;
                            tache.adresseEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_ADRESSECOMPLETE.valeur;

                            if (!scope.estAjout) {
                                tache.refInterne = scope.vueFormulaire.tache.refInterne;
                                tache.numeroInterne = scope.vueFormulaire.tache.numeroInterne;
                                tache.dateCreation = scope.vueFormulaire.tache.dateCreation;
                            }

                            var refObjet = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                            tache.libLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                            tache.descLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DESCRIPTION.valeur;

                            for (j = 0; j < scope.vueFormulaire.listes.VL_TAC_OBJET.length; j++) {
                                if (scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur === refObjet) {
                                    tache.refLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_CODE.valeur;
                                    tache.libLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur;
                                    break;
                                }
                            }

                            tache.debut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT_PREV.valeur;
                            tache.fin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN_PREV.valeur;

                            tache.debutReel = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT.valeur;
                            tache.finReelle = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN.valeur;

                            //tache.heureDebut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREDEBUT.valeur;
                            //tache.heureFin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREFIN.valeur;
                            tache.dureeJours = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEJOUR.valeur;
                            tache.dureeHeures = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEHEURE.valeur;
                            tache.avancement = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_AVANCEMENT.valeur;

                            tache.typeRessource = "Matériel";
                            tache.idRessource = scope.gridOptionsMateriels.data[i].id;
                            tache.libRessource = scope.gridOptionsMateriels.data[i].libelle;
                            tache.qteRessource = 1;

                            tache.estAjout = scope.estAjout;

                            lignesTache.push(tache);
                        }

                        // Ajout lignes matériaux
                        for (i = 0; i < scope.gridOptionsMateriaux.data.length; i++) {
                            tache = {};

                            tache.idEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.ID_INTERVENTION.valeur;
                            tache.refEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.REFERENCE_INTERVENTION.valeur;
                            tache.adresseEntete = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_ADRESSECOMPLETE.valeur;

                            if (!scope.estAjout) {
                                tache.refInterne = scope.vueFormulaire.tache.refInterne;
                                tache.numeroInterne = scope.vueFormulaire.tache.numeroInterne;
                                tache.dateCreation = scope.vueFormulaire.tache.dateCreation;
                            }

                            var refObjet = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                            tache.libLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur;
                            tache.descLigne = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DESCRIPTION.valeur;

                            for (j = 0; j < scope.vueFormulaire.listes.VL_TAC_OBJET.length; j++) {
                                if (scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur === refObjet) {
                                    tache.refLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_CODE.valeur;
                                    tache.libLigne = scope.vueFormulaire.listes.VL_TAC_OBJET[j].TAC_OBJET_LIB.valeur;
                                    break;
                                }
                            }

                            tache.debut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT_PREV.valeur;
                            tache.fin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN_PREV.valeur;

                            tache.debutReel = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT.valeur;
                            tache.finReelle = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN.valeur;

                            //tache.heureDebut = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREDEBUT.valeur;
                            //tache.heureFin = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_HEUREFIN.valeur;
                            tache.dureeJours = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEJOUR.valeur;
                            tache.dureeHeures = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEHEURE.valeur;
                            tache.avancement = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_AVANCEMENT.valeur;

                            tache.typeRessource = "Matériau";
                            tache.idRessource = scope.gridOptionsMateriaux.data[i].id;
                            tache.libRessource = scope.gridOptionsMateriaux.data[i].libelle;
                            tache.qteRessource = (angular.isNullOrUndefined(scope.gridOptionsMateriaux.data[i].quantite)) ? 1 : scope.gridOptionsMateriaux.data[i].quantite;

                            tache.estAjout = scope.estAjout;

                            lignesTache.push(tache);
                        }
                    }

                    modalInstance.close(lignesTache);
                };

                // Annuler
                scope.fermer = function () {
                    modalInstance.close();
                };

                // Init données de l'écran
                scope.$watch(function () {
                    if (!angular.isNullOrUndefined(scope.vueFormulaire.champs) && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE)) {
                        return scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.REFERENCE_INTERVENTION.valeur;
                    } else {
                        return null;
                    }
                }, function (newValue, oldValue) {
                    if (!angular.isNullOrUndefined(newValue) && (newValue !== oldValue)) {
                        if (!angular.isNullOrUndefined(scope.vueFormulaire.tache)) {
                            // Init données de la tâche si appel pour modification
                            chargerTache(scope.vueFormulaire.tache).then(function () {
                                // Init données des ressources
                                if (!angular.isNullOrUndefined(scope.vueFormulaire.ressources)) {
                                    chargerRessources(scope.vueFormulaire.ressources);
                                }
                            });
                        } else {
                            // Démarrer avec un formulaire vide si appel pour ajout
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_OBJET.valeur = null;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT.valeur = null;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN.valeur = null;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEJOUR.valeur = null;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DUREEHEURE.valeur = null;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_AVANCEMENT.valeur = null;

                            // Mantis 6216 Dupliquer la description de l'intervention dans la description de la tâche lors de la création
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DESCRIPTION.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_OBJETDESCRIPTION.valeur;

                            // Mantis 6173 Reprendre les dates prévisionnelles
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEDEBUT_PREV.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_DATEDEBUT_PREV.valeur;
                            scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.TAC_DATEFIN_PREV.valeur = scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_DATEFIN_PREV.valeur;

                            // Mantis 6178 Reprendre l'intervenant si première tâche de l'intervention
                            if (premiereTache && !angular.isNullOrUndefined(scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_ID_INTERVENANT.valeur)) {
                                if (angular.isNullOrUndefined(scope.vueFormulaire.ressources)) {
                                    scope.vueFormulaire.ressources = [];
                                }

                                var intervenant = {
                                    idRessource: scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.INT_ID_INTERVENANT.valeur,
                                    typeRessource: "Intervenant",
                                    libRessource: scope.vueFormulaire.champs.VL_TAC_CREERTACHEDETAILLEE.LIB_CONTACT.valeur
                                };

                                scope.vueFormulaire.ressources.push(intervenant);
                                initGrilleIntervenants(scope.vueFormulaire.ressources).then(function () {
                                    if (!scope.accordionGroupes.intervenantsIsOpen) {
                                        scope.accordionGroupes.intervenantsIsOpen = (scope.gridOptionsIntervenants.data.length > 0);
                                    }
                                });
                            }
                        }
                    }
                });
            }
        ]
    );

