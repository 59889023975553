/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.ecriture.OpAdresse', [])
    .directive('opAdresse', [
        'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.moteurV4.modele.Modele.SCOPE_CHAMPS'
        , 'fr.operis.moteurV4.modele.Modele.SCOPE_GRILLES'
        ,'fr.operis.moteurV4.modele.Modele.SCOPE_LONGUEUR_MAX'
        ,'fr.operis.moteurV4.utils.RequeteReferentiel'
        ,'$timeout'
        ,'$window',
        function (serveur,
                  SCOPE_CHAMPS,
                  SCOPE_GRILLES,
                  SCOPE_LONGUEUR_MAX,
                  requetereferentiel,
                  $timeout,
                  $window ) {
            return {
                restrict: 'E',
                transclude: true,
                replace: true,
                template: function (element, attributs) {

                    var bMobile = serveur.isMobile();
                    var styleMobile = (serveur.isMobile()) ? ' style="padding-left: 0px"' : '';
                    var scopeSource = (attributs.opModel.indexOf('[') !== -1) ? SCOPE_GRILLES : SCOPE_CHAMPS;
                    var id = attributs.opModel;
                    var idAffichageAdresse = attributs.opModel.replace('[$index]','');
                    var show = (!angular.isNullOrUndefined(attributs.opShow))?attributs.opShow:true;
                    var opModel = scopeSource + '.' + attributs.opModel;
                    var nomModule = attributs.opModuleNom;

                    // Définition des autres champs d'adresse
                    var opModelNomVoie = opModel.replace("_ADRESSECOMPLETE","_NOMVOIE");
                    var opModelComplement = opModel.replace("_ADRESSECOMPLETE","_COMPLEMENT");
                    var opModelLocalite = opModel.replace("_ADRESSECOMPLETE","_LOCALITE");
                    var opModelCodePostal = opModel.replace("_ADRESSECOMPLETE","_CODEPOSTAL");
                    var opModelBoitePostale = opModel.replace("_ADRESSECOMPLETE","_BOITEPOSTAL");
                    var OpModelCedex = opModel.replace("_ADRESSECOMPLETE","_CEDEX");
                    var OpModelCoordGPS = opModel.replace("_ADRESSECOMPLETE","_COORDGPS");
                    var OpModelEstSaisie = opModel.replace("_ADRESSECOMPLETE","_ESTADRESSESAISIE");
                    var opModelQuartier = opModel.replace("_ADRESSECOMPLETE","_QUARTIER");

                    // Scroll sur clique
                    var scrollClick = (serveur.isMobile() && navigator.userAgent.match(/Android/i)) ? "adresse-scroll-on-click " : "";

                    // Adresse complete
                    var retour = '<div>'+
                        '<div class="form-group row-bottom-margin has-feedback" ng-show="' + show + '">' +
                             '<label for="\'' + id + '\'" class="col-xs-5 col-sm-3 control-label"> {{::' + opModel + '.descriptions.libelle}} </label>'+
                             '<div class="col-sm-9"' + styleMobile + '>'+
                                '<div class="col-sm-12 input-group">' +
                                '<input ' +
                                    'type="text" ' +
                                    'class="form-control col-sm-10 op-typeahead" ' +
                                    'ng-change="changeAdresseComplete(\''+attributs.opModel+'\',$index)" ' +
                                    'id="' + id + '" ' +
                                    'ng-model="' + opModel + '.valeur" ' +
                                    'typeahead-min-length="3" '+
                                    'autocomplete="off" '+
                                    'maxlength="{{::' + opModel + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                    'typeahead="reference.libelle for reference in rechercheReferentielAdresse(\''+ nomModule +'\',\'adresse\',\'\',\'' + attributs.opModel + '\',$index,$viewValue)" ' +
                                    'typeahead-on-select="onTypeAheadReferentielAdresse(\''+attributs.opModel+'\',$item, $index);setFormulaireDirty();" ' +
                                    'typeahead-template-url="moteur/composants/ecriture/OpTypeahead.html" ' +
                                    'typeahead-editable="false" '+
                                    scrollClick +
                                    '/>'+
                                    '<a href="" ng-click="$event.stopPropagation();click_relancerRecherche(\''+attributs.opModel+'\');"><i ng-show="'+OpModelEstSaisie+'.valeur" class="glyphicon glyphicon-warning-sign form-control-feedback"></i></a>'+
                                    '<span class="input-group-btn" ng-show="'+bMobile+'"> ' +
                                        '<button class="btn btn-default" ng-click="geolocaliserMoi(\''+attributs.opModel+'\');setFormulaireDirty();"> <i class="fa fa-location-arrow"></i> </button>'+
                                    '</span>'+
                                    '<span class="input-group-btn"> ' +
                                        '<button class="btn btn-default" ng-init="initAffichageAdresse(\'' + idAffichageAdresse + '\')" ng-click="basculeAffichageAdresse(\'' + idAffichageAdresse + '\');$event.stopPropagation();"><i ng-class="{\'fa fa-chevron-down\': adresseAffichee(\'' + idAffichageAdresse + '\'), \'fa fa-chevron-right\': !adresseAffichee(\'' + idAffichageAdresse + '\')}"></i></button>'+
                                    '</span>'+
                                '</div>'+
                             '</div>'+
                        '</div>';

                    // Nom Voie
                    retour += '<div class="form-group row-bottom-margin" ng-show="adresseAffichee(\'' + idAffichageAdresse + '\') && ' + (show) + '">' +
                        '<label class="col-xs-5 col-sm-3 control-label"> Voie </label>'+
                        '<div class="col-sm-9"' + styleMobile + '>'+
                            '<input ' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'ng-change="changerAdresse(\''+attributs.opModel+'\',$index)" ' +
                                'data-ng-trim="false" '+
                                'ng-model="' + opModelNomVoie + '.valeur" ' +
                                'maxlength="{{::' + opModelNomVoie + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                scrollClick +
                            '/>'+
                        '</div>'+
                    '</div>';

                    // Complement
                    retour += '<div class="form-group row-bottom-margin" ng-show="adresseAffichee(\'' + idAffichageAdresse + '\') && ' + (show) + '">' +
                                '<label class="col-xs-5 col-sm-3 control-label"> Complément </label>'+
                                '<div class="col-sm-5"' + styleMobile + '>'+
                                '<input ' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'ng-model="' + opModelComplement + '.valeur" ' +
                                'maxlength="{{::' + opModelComplement + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                scrollClick +
                                '/>'+
                                '</div>'+
                                '<label class="col-xs-5 col-sm-1 control-label"> Quartier </label>'+
                                '<div class="col-sm-3"' + styleMobile + '>'+
                                '<select class="form-control" ' +
                                    'ng-model="' + opModelQuartier + '.valeur" ' +
                                        'ng-options="entite.OPE_LIB.valeur as entite.OPE_LIB.valeur for entite in listes.LST_OPE_QUARTIER">' +
                                    '<option op-option-hidden="false" value=""></option>' +
                                 '</select>'+
                                '</div>'+
                        '</div>';

                    // Code Postale + Localite
                    retour += '<div class="form-group row-bottom-margin" ng-show="adresseAffichee(\'' + idAffichageAdresse + '\') && ' + (show ) + '">' +
                                '<label class="col-sm-3 control-label"> Code postal </label>'+
                                '<div class="col-sm-2"' + styleMobile + '>'+
                                '<input ' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'ng-change="changerAdresse(\''+attributs.opModel+'\',$index)" ' +
                                'data-ng-trim="false" '+
                                'ng-model="' + opModelCodePostal + '.valeur" ' +
                                'maxlength="{{::' + opModelCodePostal + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                scrollClick +
                                '/>'+
                                '</div>'+
                                '<label class="col-sm-1 control-label"> Localité </label>'+
                                '<div class="col-sm-6"' + styleMobile + '>'+
                                '<input ' +
                                'type="text" ' +
                                'class="form-control" ' +
                                'ng-change="changerAdresse(\''+attributs.opModel+'\',$index)" ' +
                                'data-ng-trim="false" '+
                                'ng-model="' + opModelLocalite + '.valeur" ' +
                                'maxlength="{{::' + opModelLocalite + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                scrollClick +
                                '/>'+
                                '</div>'+
                        '</div>';

                    // Boite Postale + Cedex + GPS
                    retour += '<div class="form-group row-bottom-margin" ng-show="adresseAffichee(\'' + idAffichageAdresse + '\') && ' + (show) + '">' +
                                '<label class="col-sm-3 control-label"> Boîte postale </label>'+
                                '<div class="col-sm-2"' + styleMobile + '>'+
                                    '<input ' +
                                    'type="text" ' +
                                    'class="form-control" ' +
                                    'ng-model="' + opModelBoitePostale + '.valeur" ' +
                                    'maxlength="{{::' + opModelBoitePostale + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                    scrollClick +
                                    '/>'+
                                '</div>'+
                                '<label class="col-sm-1 control-label"> Cedex </label>'+
                                '<div class="col-sm-1"' + styleMobile + '>'+
                                    '<input ' +
                                    'type="text" ' +
                                    'class="form-control" ' +
                                    'ng-model="' + OpModelCedex + '.valeur" ' +
                                    'maxlength="{{::' + OpModelCedex + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                    scrollClick +
                                    '/>'+
                                '</div>'+
                                '<label class="col-sm-1 control-label"> GPS </label>'+
                                '<div class="col-sm-4"' + styleMobile + '>'+
                                    '<input ' +
                                    'type="text" ' +
                                    'class="form-control" ' +
                                    'ng-model="' + OpModelCoordGPS + '.valeur" ' +
                                    'maxlength="{{::' + OpModelCoordGPS + '.descriptions.' + SCOPE_LONGUEUR_MAX + '}}" ' +
                                    'ng-model-options="{allowInvalid: true,updateOn: \'default blur\',debounce: {\'default\': 300, \'blur\': 0}}" ' +
                                    scrollClick +
                                    '/>'+
                                '</div>'+
                            '</div>';


                    retour += '</div>';
                    return retour;
                },
                link: function (scope, element, attrs) {
                    // Paramètres d'affichage du bloc adresse
                    scope.affichageAdresse = {};

                    scope.adresseAffichee = function (idAffichageAdresse) {
                        if (angular.isNullOrUndefined(scope.affichageAdresse[idAffichageAdresse])) {
                            return false;
                        } else {
                            return scope.affichageAdresse[idAffichageAdresse].open;
                        }
                    };

                    scope.initAffichageAdresse = function (idAffichageAdresse) {
                        if (angular.isNullOrUndefined(scope.affichageAdresse[idAffichageAdresse])) {
                            scope.affichageAdresse[idAffichageAdresse] = {open: false};
                        }
                    };

                    scope.basculeAffichageAdresse = function (idAffichageAdresse) {
                        scope.initAffichageAdresse(idAffichageAdresse);
                        scope.affichageAdresse[idAffichageAdresse].open = !scope.affichageAdresse[idAffichageAdresse].open;
                    };

                    // Fonction de relance d'adresse
                    if ( angular.isNullOrUndefined(scope.click_relancerRecherche)){
                        scope.click_relancerRecherche = function(idModele){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');;
                            var nomColonne = descriptionModel[1];

                            var inputs = element.find('input');
                            angular.forEach(inputs, function(input) {
                                if ( input.id == idModele ){
                                    var ngModel = angular.element(input).controller('ngModel');
                                    var old = ngModel.$viewValue;
                                    if ( !angular.isNullOrUndefined (old) && old.length>0)
                                        ngModel.$setViewValue(old.substr(0,old.length-1));
                                }
                            });
                        }
                    }

                    // Fonction de suppression de l'adresse complète
                    if (angular.isNullOrUndefined(scope.changeAdresseComplete)){
                            scope.changeAdresseComplete = function(idModele,index){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');
                            var nomColonne = descriptionModel[1];
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][index] : scope[SCOPE_CHAMPS][nomTable];

                            // Définition des autres champs d'adresse
                            var opModelNomVoie = nomColonne.replace("_ADRESSECOMPLETE","_NOMVOIE");
                            var opModelLocalite = nomColonne.replace("_ADRESSECOMPLETE","_LOCALITE");
                            var opModelCodePostal = nomColonne.replace("_ADRESSECOMPLETE","_CODEPOSTAL");
                            var OpModelEstSaisie = nomColonne.replace("_ADRESSECOMPLETE","_ESTADRESSESAISIE");
                            var OpModelCoordGPS = nomColonne.replace("_ADRESSECOMPLETE","_COORDGPS");
                            var opModelComplement = nomColonne.replace("_ADRESSECOMPLETE","_COMPLEMENT");
                            var opModelBoitePostale = nomColonne.replace("_ADRESSECOMPLETE","_BOITEPOSTAL");
                            var OpModelCedex = nomColonne.replace("_ADRESSECOMPLETE","_CEDEX");
                            var OpModelCedexQuartier = nomColonne.replace("_ADRESSECOMPLETE","_QUARTIER");

                            scopeSource[opModelNomVoie].valeur = null;
                            scopeSource[opModelLocalite].valeur = null;
                            scopeSource[opModelCodePostal].valeur = null;
                            scopeSource[OpModelEstSaisie].valeur = null;
                            scopeSource[OpModelCoordGPS].valeur = null;
                            scopeSource[opModelComplement].valeur = null;
                            scopeSource[opModelBoitePostale].valeur = null;
                            scopeSource[OpModelCedex].valeur = null;
                            scopeSource[OpModelCedexQuartier].valeur = null;
                        }
                    }

                    // Fonction de recherche de référentiel externe d'adresse
                    if (angular.isNullOrUndefined(scope.rechercheReferentielAdresse)){
                        scope.rechercheReferentielAdresse = function( nomModule, nomReferentiel, parametreReferentiel, idModele,index, valeurRecherche ){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');
                            var nomColonne = descriptionModel[1];
                            var OpModelEstSaisie = nomColonne.replace("_ADRESSECOMPLETE","_ESTADRESSESAISIE");
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][index] : scope[SCOPE_CHAMPS][nomTable];
                            scopeSource[OpModelEstSaisie].valeur = false;

                            return requetereferentiel.rechercher( scope, nomModule, nomReferentiel, parametreReferentiel, idModele, index, valeurRecherche );
                        }
                    }

                    if (angular.isNullOrUndefined(scope.changerAdresse)) {
                        scope.changerAdresse = function(idModele, index){
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');;
                            var nomColonne = descriptionModel[1];
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][index] : scope[SCOPE_CHAMPS][nomTable];

                            // Définition des autres champs d'adresse
                            var opModelNomVoie = nomColonne.replace("_ADRESSECOMPLETE","_NOMVOIE");
                            var opModelLocalite = nomColonne.replace("_ADRESSECOMPLETE","_LOCALITE");
                            var opModelCodePostal = nomColonne.replace("_ADRESSECOMPLETE","_CODEPOSTAL");
                            var OpModelEstSaisie = nomColonne.replace("_ADRESSECOMPLETE","_ESTADRESSESAISIE");
                            var OpModelCoordGPS = nomColonne.replace("_ADRESSECOMPLETE","_COORDGPS");

                            scopeSource[nomColonne].valeur = '';
                            if ( !(angular.isNullOrUndefined(scopeSource[nomColonne].valeur)) ){
                                if ( !(angular.isNullOrUndefined(scopeSource[opModelNomVoie].valeur)) ){
                                    scopeSource[nomColonne].valeur = scopeSource[opModelNomVoie].valeur;
                                }

                                if ( !(angular.isNullOrUndefined(scopeSource[opModelCodePostal].valeur)) && scopeSource[opModelCodePostal].valeur.length>0  ) {
                                    if ( !angular.isNullOrUndefined(scopeSource[nomColonne].valeur) && scopeSource[nomColonne].valeur.length > 0){
                                        scopeSource[nomColonne].valeur += " ";
                                        scopeSource[nomColonne].valeur += scopeSource[opModelCodePostal].valeur;
                                    }
                                    else
                                        scopeSource[nomColonne].valeur = scopeSource[opModelCodePostal].valeur;

                                }

                                if ( !(angular.isNullOrUndefined(scopeSource[opModelLocalite].valeur)) && scopeSource[opModelLocalite].valeur.length>0 ) {
                                    if ( !angular.isNullOrUndefined(scopeSource[nomColonne].valeur) && scopeSource[nomColonne].valeur.length > 0){
                                        scopeSource[nomColonne].valeur += " ";
                                        scopeSource[nomColonne].valeur += scopeSource[opModelLocalite].valeur;
                                    }
                                    else
                                        scopeSource[nomColonne].valeur = scopeSource[opModelLocalite].valeur;
                                }

                                scopeSource[OpModelEstSaisie].valeur = true;
                                scopeSource[OpModelCoordGPS].valeur = null;
                            }
                        }
                    }

                    // Fonction de mise à jour des champs liés au retour du résultat de la recherche du référentiel
                    if (angular.isNullOrUndefined(scope.onTypeAheadReferentielAdresse)) {
                        scope.onTypeAheadReferentielAdresse = function ( idModele, $item, $index ) {
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');
                            var nomColonne = descriptionModel[1];
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][$index] : scope[SCOPE_CHAMPS][nomTable];

                            // Définition des autres champs d'adresse
                            var opModelNomVoie = nomColonne.replace("_ADRESSECOMPLETE","_NOMVOIE");
                            var opModelLocalite = nomColonne.replace("_ADRESSECOMPLETE","_LOCALITE");
                            var opModelCodePostal = nomColonne.replace("_ADRESSECOMPLETE","_CODEPOSTAL");
                            var OpModelCoordGPS = nomColonne.replace("_ADRESSECOMPLETE","_COORDGPS");

                            scopeSource[opModelNomVoie].valeur = '';
                            scopeSource[opModelCodePostal].valeur = '';
                            scopeSource[opModelLocalite].valeur = '';

                            if (  !angular.isNullOrUndefined ( $item["adresse"] ) ){
                                    scopeSource[opModelNomVoie].valeur = $item["adresse"] + " ";
                            }

                            if ( !angular.isNullOrUndefined ( $item["codepostal"] )){
                                scopeSource[opModelCodePostal].valeur = $item["codepostal"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["commune"] ) ){
                                scopeSource[opModelLocalite].valeur = $item["commune"];
                            }

                            if ( !angular.isNullOrUndefined ( $item["coordgps"] ) ){
                                scopeSource[OpModelCoordGPS].valeur = $item["coordgps"];
                            }

                        };
                    }

                    // Fonction de géolocalication
                    if (angular.isNullOrUndefined(scope.geolocaliserMoi)) {
                        scope.geolocaliserMoi = function (idModele) {
                            var descriptionModel = idModele.split('.');
                            if ( angular.isNullOrUndefined (descriptionModel)
                                || descriptionModel.length!=2 )
                                return;

                            var nomTable = descriptionModel[0].replace('[$index]','');
                            var nomColonne = descriptionModel[1];

                            // Définition des autres champs d'adresse
                            var opModelNomVoie = nomColonne.replace("_ADRESSECOMPLETE","_NOMVOIE");
                            var opModelLocalite = nomColonne.replace("_ADRESSECOMPLETE","_LOCALITE");
                            var opModelCodePostal = nomColonne.replace("_ADRESSECOMPLETE","_CODEPOSTAL");
                            var opModelCoordGPS = nomColonne.replace("_ADRESSECOMPLETE","_COORDGPS");
                            var scopeSource = (idModele.indexOf('[') !== -1) ? scope[SCOPE_GRILLES][nomTable][$index] : scope[SCOPE_CHAMPS][nomTable];

                            scopeSource[nomColonne].valeur = '';
                            scopeSource[opModelNomVoie].valeur = '';
                            scopeSource[opModelCodePostal].valeur = '';
                            scopeSource[opModelLocalite].valeur = '';
                            scopeSource[opModelCoordGPS].valeur = '';

                            if ($window.navigator.geolocation) {
                                $window.navigator.geolocation.getCurrentPosition(function (position) {
                                    // Si champ adresse alors récupérer l'adresse selon les coordonnées XY
                                    var rechercheXY = "lon=" + position.coords.longitude + "&lat=" + position.coords.latitude;

                                    requetereferentiel.rechercher(scope, scope.nomModule, "geolocaliserMoi", null, null, null, rechercheXY).then(
                                        function (retourRecherche) {
                                            if (!angular.isNullOrUndefined(retourRecherche) && (retourRecherche.length > 0)) {
                                                if (  !angular.isNullOrUndefined ( retourRecherche[0]["adresse"] ) ){
                                                    scopeSource[opModelNomVoie].valeur = retourRecherche[0]["adresse"] + " ";
                                                }

                                                if ( !angular.isNullOrUndefined ( retourRecherche[0]["codepostal"] )){
                                                    scopeSource[opModelCodePostal].valeur = retourRecherche[0]["codepostal"];
                                                }

                                                if ( !angular.isNullOrUndefined ( retourRecherche[0]["commune"] ) ){
                                                    scopeSource[opModelLocalite].valeur = retourRecherche[0]["commune"];
                                                }

                                                if ( !angular.isNullOrUndefined ( retourRecherche[0]["coordgps"] ) ){
                                                    scopeSource[opModelCoordGPS].valeur = retourRecherche[0]["coordgps"];
                                                }

                                                scopeSource[nomColonne].valeur = retourRecherche[0].libelle;
                                            }
                                        });
                                });
                            }
                        };
                    }
                }
            };
        }])

        .directive('adresseScrollOnClick', ['$ocLazyLoad', '$document', '$window', function($ocLazyLoad, $document, $window) {
            return {
                restrict: 'A',
                link: function (scope, element) {
                    $ocLazyLoad.load({
                        serie: true, files: ['lib/jquery.min.js']
                    }).then(function () {
                        $document.ready(function () {
                            element.on('click', function () {
                                if ($(element).offset().top > ($window.innerHeight * 0.20)) {
                                    var posActuel = $document[0].body.scrollTop;
                                    $("html, body").animate({scrollTop: posActuel + $(element).offset().top - 80}, "slow");
                                }
                            });
                        });
                    });
                }
            }
        }])
;