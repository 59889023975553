/**
 * @name opPopupFeature
 *
 * @version 1.0
 * @copyright 2018 Operis
 * @author Laure-Hélène Bruneton, Alcer - Operis
 *
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.cartographie.OpPopupFeature', [])
    .controller('fr.operis.moteurV4.composants.cartographie.OpPopupFeatureControleur',
        ['$scope', '$modalInstance', '$ocLazyLoad',
            'fr.operis.moteurV4.utils.Cartographie',
            'moduleNom', 'entite',
            function ($scope, $modalInstance, $ocLazyLoad,
                      utilsCarto, moduleNom, entite) {
                var context, epsgCode;

                // Contexte
                utilsCarto.chargerContexte(moduleNom)
                .then(function(responseContext) {
                    context = responseContext;
                    // Projection
                    return utilsCarto.chargerProjection(context.EPSG);
                }).then(function(responseEpsgCode) {
                    epsgCode = responseEpsgCode;
                    // OpenLayers
                    return $ocLazyLoad.load('lib/ol.min.js');
                })
                .then(function() {
                    // generate a GetFeature request
                    var featureRequest = new ol.format.WFS().writeGetFeature({
                        featureNS: context.sourceWFS.featureNS,
                        featurePrefix: context.sourceWFS.featurePrefix,
                        featureTypes: [entite.REF_CODE.valeur],
                        outputFormat: context.sourceWFS.format,
                        filter: ol.format.filter.equalTo(context.sourceWFS.idProperty, entite.REF_ID.valeur)
                    });

                    // then post the request and add the received features to the popup body
                    fetch(context.sourceWFS.url, {
                        method: 'POST',
                        body: new XMLSerializer().serializeToString(featureRequest)
                    }).then(function(response) {
                        return response.text();
                    }).then(function(text) {

                        var doc;
                        if (typeof DOMParser != "undefined") {
                            var parser = new DOMParser();
                            doc = parser.parseFromString(text, "text/xml");
                        }

                        var oldNode = doc.children[0].children[1],
                            newNode = doc.createElementNS("http://www.opengis.net/gml", "gml:featureMember");

                        newNode.appendChild(oldNode.firstChild);
                        doc.children[0].replaceChild(newNode, oldNode);

                        var feature = new ol.format.GML3().readFeatures(doc.children[0].children[1]);
                        
                        var content = document.getElementById('popup-feature');
                        content.appendChild(utilsCarto.creerTableAttributs(feature));
                        
                        $scope.$apply(function() { $scope.titre = entite.LIBELLE.valeur; });
                    });
                });
                
                $scope.titre = "Chargement en cours...";

                // Fermer
                $scope.fermer = function () {
                    $modalInstance.close("fermer");
                };
            }
        ]
    );

