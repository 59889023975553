/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Habilitation', [])

    /**
     * Nom de l'attribut pour IDENT_SERVGESTIONECRITURE
     * @type {String}
     * @value IDENT_SERVGESTIONECRITURE
     */
    .constant('fr.operis.moteurV4.utils.Habilitation.IDENT_SERVGESTIONECRITURE', 'IDENT_SERVGESTIONECRITURE')


    .service('fr.operis.moteurV4.utils.Habilitation',
        ['fr.operis.moteurV4.utils.Habilitation.IDENT_SERVGESTIONECRITURE',
        function (IDENT_SERVGESTIONECRITURE) {

            // Listes des habilitations au niveau des services gestionnaires
            var _habilitationServiceGestionnaire;
            var _idServiceGestionnaireParDefault;

            //
            /**
             * Initialisation des habilitations au niveau des services gestionnaires
             * @param {Array}  Liste des droits d'accès par service gestionnaire
             * @param {Array}  Identifiant du srvice gestionnaire par défault
             *
             */
            this.initialisationHabilitationServiceGestionnaire = function( aHabilitationServiceGestionnaire, aIdServiceGestionnaireParDefault ) {
                _habilitationServiceGestionnaire = aHabilitationServiceGestionnaire;

                // Service gestionnaire par défault
                _idServiceGestionnaireParDefault = aIdServiceGestionnaireParDefault;
            };

            /**
             * Récupération du droit d'un service gestionnaire
             * @return Droit d'acces null,0,1,2
             *
             */
            this.habilitationServiceGestionnaire = function (){
                return _habilitationServiceGestionnaire;
            };

            /**
             * Récupération des services gestionnaires en ecriture
             * @return Array
             *
             */
            this.getServiceGestionnaireEcriture = function(){
                var serviceGestionnaireEcriture = [-1];

                for (var service in _habilitationServiceGestionnaire){
                    if ( _habilitationServiceGestionnaire[service] === 2 )
                        serviceGestionnaireEcriture.push(parseInt(service));
                }

                return serviceGestionnaireEcriture;
            }

            /**
             * Récupération du service par défault
             * @return Array
             *
             */
            this.getIdServiceParDefault= function(){
                return _idServiceGestionnaireParDefault;
            };

            /**
             * Ajout du critère des services gestionnaires en ecriture si le champs IDENT_SERVGESTIONECRITURE est présent
             * @return Array
             *
             */
            this.setCritereHabilitation = function (aScope){
                if ( !angular.isNullOrUndefined(aScope.champs)){
                    for ( var tableNom in aScope.champs){
                        if ( !angular.isNullOrUndefined (aScope.champs[tableNom][IDENT_SERVGESTIONECRITURE]))
                            aScope.champs[tableNom][IDENT_SERVGESTIONECRITURE].valeur = this.getServiceGestionnaireEcriture();
                    }
                }
            }

        }
    ]
);