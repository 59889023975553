/**
 * Created by Laurent Dorie le 27/07/16.
 */
"use strict";
angular.module('fr.operis.moteurV4.composants.popup.OpPopupActionMetier', [])
    // opPopupActionMetier directive définie la structure modal d'une popup Operis d'action métier d'une fiche ou du classuer
    // Title
    .directive('opPopupActionMetier',
        ['$q',
         'fr.operis.moteurV4.communication.OperisWSServer',
        'fr.operis.operia.utils.verificationSelection',
        'fr.operis.moteurV4.utils.ModuleInfos',
        'fr.operis.moteurV4.utils.Historique',
        'fr.operis.moteurV4.utils.ActionInfos',
        'fr.operis.moteurV4.utils.RequeteNotificationInterne',
        'fr.operis.moteurV4.utils.Messagerie',
        function($q, serveur, verificationSelection, moduleInfos, historique, actionInfos, requeteNotificationInterne, messagerie) {
        return {
            restrict: 'E',
            replace:true,
            transclude:true,
            template: function (element, attributs){
                var title = attributs.title;
                return '<div><op-popup title="'+ title +'" ng-style="{\'background-color\': couleurPrimaire}">'+
                           '<op-popup-body>' +
                                '<!--GRILLE DE RECHERCHE-->'+
                                '<div class="panel panel-default" ng-show="$selectionVisible" >'+
                                    '<div class="panel-heading">'+
                                        '<b>Eléments à traiter ({{$selection.length}} sur {{$selectionOrigine.length}})</b>'+
                                        '<a class="pull-right" ng-if="$etendreVisible" ><i class="fa fa-filter" ng-click="$event.stopPropagation();etendreSelection();" aria-hidden="true"></i></a>' +
                                    '</div>'+
                                    '<div class="panel-body" style="overflow-x:hidden;padding-left:0px;padding-right:0px;padding-top:0px;padding-bottom:0px">'+
                                        '<op-grille '+
                                            'op-description="$selectionDescription" '+
                                            'op-donnees="$selectionDonnees" '+
                                            'op-selection="$selection" > '+
                                        '</op-grille>'+
                                    '</div>'+
                                '</div>'+

                                '<!-- FORMULAIRE DE SAISIE-->'+
                                '<div ng-show="$formulaireVisible">'+
                                    '<op-formulaire op-vue-formulaire="vueFormulaire" finchargement="finChargementFormulaire(scope)" op-is-open="true">' +
                                    '</op-formulaire>'+
                                '</div>'+

                                '<!--FORMULAIRE PERSONNALISE -->'+
                                '<div ng-transclude >'+
                                '</div>'+
                            '</op-popup-body>'+
                            '<op-popup-footer ng-if="!$btnEnregFermerMobile" >'+
								'<button ng-repeat="actionBouton in $actionBoutons" title="{{actionBouton}}" ng-disabled="($selection == null || $selection.length === 0 || $bFormulaireCharge == false || $bVerificationValidation == false || ( $noFormulaire == false && $bFormulaireCharge == true && !vueFormulaire.vueModifie.$valid))" class="btn btn-primary" ng-click="$click_actionBouton(actionBouton)">{{actionBouton}}</button>'+
                                '<button title="'+title+'" ng-disabled="($selection == null || $selection.length === 0 || $bFormulaireCharge == false || $bVerificationValidation == false || ( $noFormulaire == false && $bFormulaireCharge == true && !vueFormulaire.vueModifie.$valid))" class="btn btn-primary" ng-click="valider()">'+title+'</button>'+
                            '</op-popup-footer>'+
                            '<op-popup-footer ng-if="$btnEnregFermerMobile">'+
                                '<div class="barre-fonctions-mobile-popup">'+
                                    '<button title="Enregistrer" class="fa fa-lg btn-primary" ng-class="{\'bouton-desactive\': ($selection == null || $selection.length === 0 || $bFormulaireCharge == false || $bVerificationValidation == false || ($noFormulaire == false && $bFormulaireCharge == true && !vueFormulaire.vueModifie.$valid))}" ng-disabled="($selection == null || $selection.length === 0 || $bFormulaireCharge == false || $bVerificationValidation == false || ($noFormulaire == false && $bFormulaireCharge == true && !vueFormulaire.vueModifie.$valid))" ng-click="valider()" ng-if="!$estErreurAucuneDonnee"><span class="fa fa-floppy-o"></span></button>'+
                                    '<button title="Fermer" class="fa fa-lg action-mobile-fermeture" ng-click="fermer()"><span class="fa fa-times"></span></button>'+
                                '</div>'+
                            '</op-popup-footer>'+
                        '</op-popup></div>';
            },
            link: function (scope, element, attrs){
                // Paramètre généraux
                if (angular.isNullOrUndefined(scope.$selectionVisible) )
                    scope.$selectionVisible = true;
                if (angular.isNullOrUndefined(scope.$formulaireVisible) )
                    scope.$formulaireVisible = true;
                if (angular.isNullOrUndefined(scope.$btnEnregFermerMobile) )
                    scope.$btnEnregFermerMobile = false;

                scope.$noFormulaire = !angular.isNullOrUndefined(attrs.noFormulaire);
                scope.$bFormulaireCharge = !angular.isNullOrUndefined(attrs.noFormulaire);
                scope.$etendreVisible = !angular.isNullOrUndefined(attrs.etendreVisible);
                scope.$bVerificationValidation = false;
                scope.$moduleNom = attrs.actionModule;
                scope.$ecranVerification = attrs.ecranVerification;
                scope.$droitService = angular.isNullOrUndefined(scope.$droitService)?2:scope.$droitService;
                scope.$erreurAucuneDonnee = angular.isNullOrUndefined(attrs.erreurAucuneDonnee)?"Aucune donnée à traiter":attrs.erreurAucuneDonnee;
                scope.$estErreurAucuneDonnee = false;
                scope.couleurPrimaire = moduleInfos.getInfo(scope.$moduleNom, "MOD_COULEURPRIMAIRE");
                scope.$identifiantNom = moduleInfos.getInfo(scope.$moduleNom, "MOD_ENTITE");

                // Fonction de fin de chargement de formulaire de saisie
                scope.finChargementFormulaire = function (aScope){
                    scope.$bFormulaireCharge = true;
                    scope.$scopeFormulaire = aScope;

                    if ( !angular.isNullOrUndefined( scope.$finChargementFormulaire ) )
                        scope.$finChargementFormulaire ( aScope );
                };

                // Grille de selection avec vérification
                if ( !angular.isNullOrUndefined(scope.$moduleNom) && !angular.isNullOrUndefined(scope.$selectionOrigine)){
                    verificationSelection.execution( scope.$moduleNom, scope, scope.$ecranVerification, scope.$selectionOrigine, scope.$droitService, false )
                        .then(function(retourVerification){
                            if ( !angular.isNullOrUndefined(retourVerification.description)){
                                scope.$selectionDescription =  retourVerification.description;
                            }

                            scope.$selectionDonneesOrigine = angular.copy( retourVerification.donnees);
                            scope.$selectionDonnees = retourVerification.donnees;
                            scope.$selection = scope.$selectionDonnees.slice();
                            scope.$bVerificationValidation= true;

                            // Ecouteur sur la grille de données pour fermer si plus aucune action
                            if ( scope.$selectionDonnees.length>0){
                                scope.$watch('$selectionDonnees.length', function (newValue,OldValue) {
                                    if ( !angular.isNullOrUndefined(newValue) && newValue == 0 ){
                                        scope.$close();
                                    }
                                });
                            }
                            else{
                                // Selection et formulaire non visible
                                scope.$selectionVisible = false;
                                scope.$formulaireVisible = false;
                                scope.$erreur = [];
                                scope.$erreurs.push(scope.$erreurAucuneDonnee);
                                scope.$estErreurAucuneDonnee = true;
                            }


                            if ( !angular.isNullOrUndefined( scope.$finVerificationValidation ) )
                                scope.$finVerificationValidation();

                        },function(erreur){

                        });
                }

                // Formulaire de saisie
                if ( !angular.isNullOrUndefined(attrs.actionEcran)
                    && !angular.isNullOrUndefined(scope.$moduleNom)
                    && !angular.isNullOrUndefined(attrs.actionEntite)){
                    var identifiant = {};
                    identifiant[attrs.actionEntite] = "";

                    scope.vueFormulaire ={
                        nomModule:scope.$moduleNom,
                        nomEcran:attrs.actionEcran,
                        identifiant: identifiant
                    };
                }

                // Fonction d'extension de sélection
                scope.etendreSelection = function(){
                    if ( !angular.isNullOrUndefined(scope.$ecranVerification)){
                        verificationSelection.execution( scope.$moduleNom, scope, scope.$ecranVerification, null, scope.$droitService, true )
                            .then(function(retourVerification){
                                if ( !angular.isNullOrUndefined(retourVerification.description)){
                                    scope.$selectionDescription =  retourVerification.description;
                                }
                                scope.$selectionDonnees = retourVerification.donnees;

                                scope.$selection = scope.$selectionDonnees.slice();
                                scope.$bVerificationValidation= true;

                                if ( !angular.isNullOrUndefined( scope.$finVerificationValidation ) )
                                    scope.$finVerificationValidation();

                            },function(erreur){

                            });
                    }
                };

                // Fonction de validation de la selection
                scope.$valider = function(){
                    // Parcours de la sélection
                    var promesse = $q.defer();

                    var promises = [];
                    for (var i=0;i<scope.$selection.length;i++){
                        promises.push(scope.$validationSelection(scope.$selection[i]));
                    }

                    // Traitement du résultat
                    scope.$erreurs = [];
                    $q.all(promises)
                        .then(function (reponses) {
                            for (var i=0; i<reponses.length; i++){
                                if ( reponses[i].length>1){
                                    scope.$erreurs.push("Erreur sur l'entité " + reponses[i][0][scope.$identifiantNom].valeur);
                                }
                                else
                                    reponses[i][0]["statut"] = "SUCCES";
                            }
                    })
                        .catch(function(erreurs){
                            promesse.reject(erreurs);
                            scope.suiteValidationSelection();
                    })
                        .finally(function(){
                            promesse.resolve();
                            scope.suiteValidationSelection();
                    })

                    return promesse.promise;
                };

                // Fonction de suite de validation pour la validation de l'historique et des notifications
                scope.suiteValidationSelection = function(){

                    // Mise à jour de l'historique
                    for ( var i = scope.$selection.length - 1; i >= 0;i--){

                        if ( scope.$selection[i]["statut"] == "SUCCES" ) {
                            var identifiant = {};
                            identifiant[scope.$identifiantNom] = scope.$selection[i][scope.$identifiantNom].valeur;

                            if (!angular.isNullOrUndefined(scope.$historique) && !angular.isNullOrUndefined(scope.$historique.nom) ){
                                historique.sauvegardeHistorique(scope.$moduleNom, scope, identifiant,
                                    scope.$action.ID_ACTION.valeur, scope.$historique.nom, new Date(), scope.$historique.commentaire, null)
                            }
                        }
                    }

                    // Notification automatique
                    // Vérification paramétrage de notification automatique
                    var codeAction = actionInfos.getInfoAction(serveur.getIdModule(scope.$moduleNom), scope.$action.ID_ACTION.valeur);
                    if (!angular.isNullOrUndefined(codeAction)) {
                        var paramNotif = serveur.lireParametre(scope.$moduleNom, 'NOTIFICATION', codeAction.ACTIONCODE.valeur);

                        // Récupération de la configuration de paramétrage automatique
                        if (!angular.isNullOrUndefined(paramNotif)){
                            var contenu, titre;
                            requeteNotificationInterne.getParametrageNotificiation(scope, scope.$moduleNom, scope.$action.ID_ACTION.valeur)
                                .then(function(retourChargement){
                                    titre = retourChargement.TITRE, contenu = retourChargement.CONTENU;

                                    // Notification interne
                                    if (!angular.isNullOrUndefined(paramNotif) && paramNotif.indexOf("INT") !== -1) {
                                        for (var i = scope.$selection.length - 1; i >= 0; i--) {
                                            // Référence
                                            var trigramme = moduleInfos.getInfo(scope.$moduleNom, "MOD_TRIGRAMME");
                                            var reference = "[" + scope.$selection[i][trigramme + "_VIGN_L1"].valeur + "] ";;
                                            if ( !angular.isNullOrUndefined(scope.$notificationAutomatique )){
                                                var identifiant = {};
                                                identifiant[scope.$identifiantNom] = scope.$selection[i][scope.$identifiantNom].valeur;
                                                requeteNotificationInterne.ajouterNotification(scope, scope.$moduleNom, identifiant,
                                                    reference + titre, contenu, true,  scope.$notificationAutomatique.id_destinataire, scope.$notificationAutomatique.id_servicedestinataire, scope.$action.ID_ACTION.valeur);
                                            }
                                        }
                                    }

                                    // Messagerie
                                    if (!angular.isNullOrUndefined(paramNotif) && paramNotif.indexOf("MAIL") !== -1) {
                                        for (var i = scope.$selection.length - 1; i >= 0; i--) {
                                            if ( !angular.isNullOrUndefined(scope.$notificationAutomatique )
                                                    && !angular.isNullOrUndefined(scope.$notificationAutomatique.id_destinataire)){
                                                var identifiant = {};
                                                identifiant[scope.$identifiantNom] = scope.$selection[i][scope.$identifiantNom].valeur;
                                                var idModule = serveur.getIdModule(scope.$moduleNom);
                                                messagerie.envoieMessage(scope.$notificationAutomatique.id_destinataire, reference + titre, contenu, idModule, identifiant, "", "", "", "");
                                            }
                                        }
                                    }

                                    scope.suppressionSelection();
                                },function(erreurChargement){

                                })
                        }
                        else
                            scope.suppressionSelection();
                    }else
                        scope.suppressionSelection();



                }

                // Fonction de suppression
                scope.suppressionSelection = function(){
                    // Suppression des éléments validés de la grille
                    for ( var i = scope.$selectionDonnees.length - 1; i >= 0;i--){
                        if ( scope.$selectionDonnees[i]["statut"] == "SUCCES" )
                            scope.$selectionDonnees.splice(i,1);
                    }
                    // Suppression des éléments validés de la sélection
                    for ( var i = scope.$selection.length - 1; i >= 0;i--){
                        if ( scope.$selection[i]["statut"] == "SUCCES" )
                            scope.$selection.splice(i,1);
                    }
                }
            }
        }
    }]);


