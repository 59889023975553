/**
 * @version 1.0b
 * @copyright 2016 Operis
 * @Author Laurent Dorie, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.utils.Parametre', [])


    .provider('fr.operis.moteurV4.utils.Parametre', function () {
        var _parametre;

        /**
         * Configuration des parametres
         * @param {Object} aParametre
         * @returns
         */
        this.setParametre = function(aParametre){
            _parametre = aParametre;
        };

        /**
         * Retourne le service Parametre.
         * @param {injector} $injector
         * @returns {fr.operis.moteurV4.modele.Parametre}
         */
        this.$get = ['$injector', function ($injector) {
            return $injector.instantiate([
                'fr.operis.moteurV4.communication.OperisWSServer',
                Parametre
            ]);
        }
        ];

        function Parametre(serveur) {


            /**
             * Fonction qui retourne la valeur d'un paramètre applicatif
             * @String {aModule}
             * @String {aParametre}
             * @String
             */
            this.lireParametre = function(aModule, aParametre){
                var valeur;
                var objetParametre=_parametre[aParametre];

                if ( !angular.isNullOrUndefined(objetParametre) ){
                    valeur = serveur.lireParametre(aModule,objetParametre.categorie, objetParametre.code);
                    if ( angular.isNullOrUndefined( valeur ) )
                        valeur = objetParametre.valeurDefaut;
                }

                return valeur;
            }
        };
    });