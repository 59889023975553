/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

'use strict';

angular.module('fr.operis.moteurV4.directives.OpSimpleFormat', [])
    .directive('opSimpleformat', [
        function () {
            return {
                restrict: 'E',
                scope: true,
                template: "<p ng-repeat='lignes in paragraphes track by $index'>" +
                "<span ng-repeat='ligne in lignes track by $index' style='white-space:pre;word-wrap:break-word'>" +
                "<br ng-hide='$first'/>" +
                "{{ligne}}" +
                "</span>" +
                "</p>",
                link: function (scope, element, attributes) {
                    scope.$watch(attributes.texte, function (texte) {
                        scope.paragraphes = [];
                        texte = texte || '';
                        texte = texte.toString();
                        // Prise en compte de tous les marqueurs de saut de ligne
                        texte.replace(/\r\n|\r/g, '\n');
                        texte.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
                        // Récupération des paragraphes
                        var paragraphes = texte.split('\n\n');
                        for (var indice = 0; indice < paragraphes.length; ++indice) {
                            // Récupération des lignes
                            scope.paragraphes.push(paragraphes[indice].split('\n'));
                        }
                    });
                }
            }
        }
    ]);