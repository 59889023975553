/**
 * @version 1.0b
 * @copyright 2015 Operis
 * @Author Pierre-Emmanuel Balageas, Alcer - Operis
 */

/**
 * Définit les informations d'une requête.
 * @typedef {Object} fr.operis.moteurV4.composants.OpMireAttente.Requete
 * @property {Number} id - Identifiant interne de la requête.
 * @property {String} libelle - Libellé de la requête.
 * @property {String} commentaire - Commentaire de la requête.
 * @property {boolean} modale - Indique le caractère modal de la requête (valeur par défaut : true).
 * @property {Date} debut - Date de début de la requête.
 * @property {Date} fin - Date de fin de la requête.
 * @property {Number} progression - Progression de la requête.
 * @property {Number} duree - Durée de la requête.
 * @property {ng.Promise} dureePromise - Promesse sur le calcul à intervalles réguliers de la durée de la requête.
 * @property {String} statut - Statut de la requête.
 * @property {boolean} ouverte - Indique si la requête affiche ses informations.
 * @property {Function} pressePapier - Copie la requête dans le presse papier.
 * @property {Function} annuler - Annule la requête si elle est en cours, la supprime de la liste sinon.
 */

'use strict';

angular.module('fr.operis.moteurV4.composants.OpMireAttente', [
    'ui.bootstrap',
    'ngAnimate',
    'fr.operis.moteurV4.directives.OpSimpleFormat',
    'fr.operis.moteurV4.filtres.Intervalle'])
    .constant('fr.operis.moteurV4.composants.OpMireAttente.DISPLAY_FULL', 'full')
    .constant('fr.operis.moteurV4.composants.OpMireAttente.DISPLAY_PARTIAL', 'partial')
    .constant('fr.operis.moteurV4.composants.OpMireAttente.DISPLAY_NONE', 'none')
    .directive('opMireattente',
    function () {
        return {
            restrict: 'EA',
            replace: true,
            templateUrl: 'moteur/composants/OpMireAttente.html'
        };
    })
    .service('fr.operis.moteurV4.composants.OpMireAttente', [
        '$rootElement'
        , '$rootScope'
        , '$compile'
        , '$interval'
        , '$timeout'
        , '$window'
        , 'fr.operis.moteurV4.composants.OpMireAttente.DISPLAY_FULL'
        , 'fr.operis.moteurV4.composants.OpMireAttente.DISPLAY_PARTIAL'
        , 'fr.operis.moteurV4.composants.OpMireAttente.DISPLAY_NONE'
        , function ($rootElement, $rootScope, $compile, $interval, $timeout, $window, DISPLAY_FULL, DISPLAY_PARTIAL, DISPLAY_NONE) {
            var scope = $rootScope.$new(true),
                element = $compile('<div op-mireattente></div>')(scope),
                id = 0;

            // Ajout de la mire d'attente à l'application
            if ($rootElement[0].localName === "html") {
                angular.element(document.body).append(element);
            } else {
                $rootElement.append(element);
            }

            // Requêtes de la mire d'attente
            scope.requetes = [];
            // Nombre de requêtes en cours d'exécution
            scope.nbRequetesEnCours = 0;
            // Nombre de requêtes modale en cours d'exécution
            scope.nbRequetesEnCoursModales = 0;
            // Type d'affichage de la mire
            scope.displayType = DISPLAY_FULL;
            
            // Vrai si la mire d'attente est ouverte
            scope.ouverte = false;
            // Méthode pour ouvrir ou fermer la mire d'attente
            scope.switch = function () {
                this.ouverte = !this.ouverte;
                nettoyer();
            };
            // Valeur par défaut du max des barres de progression
            scope.progressionMax = 100;

            /**
             * Fonction pour nettoyer les requêtes terminées si la mire n'est pas ouverte
             * @returns {void}
             */
            var nettoyer = function () {
                if (!scope.ouverte) {
                    var requetes = [];
                    for (var indice = 0; indice < scope.requetes.length; ++indice) {
                        if (scope.requetes[indice].fin === undefined) requetes.push(scope.requetes[indice]);
                    }
                    scope.requetes = requetes;
                }
            };

            this.definirAffichage = function (display) {
                if(!display || (display != DISPLAY_FULL && display != DISPLAY_PARTIAL && display != DISPLAY_NONE)) {
                    scope.displayType = DISPLAY_FULL;
                    return;
                }
                
                scope.displayType = display;
            }
            
            /**
             * Ajoute une requête à la mire d'attente.
             * @param {ng.IPromise<T>} aPromise La promesse correspondant à la requête
             * @param {fr.operis.moteurV4.communication.InformationsRequete} aRequeteInformations Les informations de la requête
             * @returns {void}
             */
            this.ajouterRequete = function (aPromise, aRequeteInformations, aDisplay) {
                
                this.definirAffichage(aDisplay);
                
                ++scope.nbRequetesEnCours;

                var requete = {
                    id: ++id,
                    libelle: aRequeteInformations.libelle || ('Requête n°' + id),
                    commentaire: aRequeteInformations.commentaire || '',
                    modale: aRequeteInformations.estModale === false ? false : true,
                    debut: new Date(),
                    progression: 0,
                    progressionTotale: 0,
                    duree: 0,
                    dureePromise: $interval(function () {
                        requete.duree = ((new Date()).getTime() - requete.debut.getTime());
                        requete.progression = (requete.duree / aPromise.timeout) * scope.progressionMax;
                    }, 1000),
                    statut: 'info',
                    ouverte: false,
                    pressePapier: function () {
                        $window.prompt("Copier le texte : Ctrl+C, Entrer", angular.toJson(this.erreur));
                    },
                    annuler: function () {
                        if (this.fin === undefined) {
                            this.fin = new Date();
                            this.statut = 'warning';
                            aPromise.annuler();
                        } else {
                            for (var indice = 0; indice < scope.requetes.length; ++indice) {
                                if (scope.requetes[indice].id === this.id) {
                                    scope.requetes.splice(indice, 1);
                                    return;
                                }
                            }
                        }
                    }
                };
                if (requete.modale) ++scope.nbRequetesEnCoursModales;

                aPromise.then(function () {
                    if (requete.fin === undefined) requete.statut = 'success';
                })
                    .catch(function (erreur) {
                        if (requete.fin === undefined) requete.statut = 'danger';
                        requete.erreur = erreur;
                    })
                    .finally(function () {
                        aPromise = null;
                        requete.fin = new Date();
                        requete.duree = requete.fin.getTime() - requete.debut.getTime();
                        requete.progression = scope.progressionMax;
                        $interval.cancel(requete.dureePromise);
                        --scope.nbRequetesEnCours;
                        if (requete.modale) --scope.nbRequetesEnCoursModales;
                        scope.$evalAsync(nettoyer);
                    });
                scope.requetes.push(requete);
            };
        }]);